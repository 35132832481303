import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { Observable, BehaviorSubject, Subject, of } from 'rxjs';
import { distinctUntilChanged, switchMap, catchError, map } from 'rxjs/operators';
import { HttpResponseHelper, AppConfig } from '@caloptima/portal-foundation';
import { OAuthService } from 'angular-oauth2-oidc';
import { PortalConfig } from '../portal-config';
import { MemberInfoRequest } from './models/requests/member-info-request';
import { Claim } from './models/claim';
import { ClaimRequest } from './models/requests/claim-request';
import { PagedResponse } from './models/responses/paged-response';
import { SessionService } from './session.service';
import { ClaimEobSummary } from './models/claim-eob-summary';
import { EobCode } from './models/eob-code';
import { UiUtility } from '../utils/ui-utility';
import { ClaimLine } from './models/claim-line';
import { ClaimLineEoc } from './models/claim-line-eoc';
import { EocCode } from './models/eoc-code';
import { ClaimReconSearchRequest } from './models/requests/claim-recon-search-request';
import { ProviderDetail } from './models/provider-detail';
import { AppealCreateResponse, ProviderDisputeResolution, ProviderDisputeResolutionLetter, ProviderDisputeResolutionSubmission } from './models/claim-dispute';
import { ProviderClaimsDisputeRequest } from './models/requests/provider-claims-dispute-request';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {

  private claimResultColumns: any = [
    { field: 'claimStatus', header: 'Status', width: '10%', class: 'claim-result-data-left', classheader: 'claim-result-header-left', sort: true },
    { field: 'claimNumber', header: 'Claim Number', width: '12%', class: 'claim-result-data', classheader: 'claim-result-header', sort: true },
    { field: 'memberName', header: 'Member Name', width: '17%', class: 'claim-result-data', classheader: 'claim-result-header', sort: true },
    { field: 'memberCIN', header: 'CIN', width: '8%', class: 'claim-result-data', classheader: 'claim-result-header', sort: true },
    { field: 'dateOfService', header: 'DOS', width: '6%', class: 'claim-result-data', classheader: 'claim-result-header', sort: true },
    { field: 'serviceProviderName', header: 'Service Provider', class: 'claim-result-data', classheader: 'claim-result-header', sort: true },
    { field: 'billedAmt', header: 'Billed/Payable', class: 'claim-result-data', width: '12%', classheader: 'claim-result-header-alt', sort: true },
    { field: 'lobName', header: 'LOB', width: '8%', class: 'claim-result-data', classheader: 'claim-result-header', sort: true },
    { field: '', header: 'Check', width: '6%', class: 'claim-result-data', classheader: 'claim-result-header-center', sort: false },
    { field: '', header: '', width: '4%', class: 'claim-result-data-right', classheader: 'claim-result-header-right', sort: false },
  ];

  private baseProviderMemberServiceUrl: string;
  private getMemberClaimUrl: string;
  private downloadClaimsUrl: string;
  private getProviderClaimByClaimNumberUrl: string;
  private getProviderClaimsByCriteriaUrl: string;
  private getChangeHealthCareLinkUrl: string;
  private getClaimReconUrl: string;
  private getClaimReconProvidersUrl: string;
  private downloadClaimReconUrl: string;
  private downloadDisputeCorresspondanceUrl: string;
  private searchProviderDisputesUrl: string;
  private getProviderDisputeUrl: string;
  private submitProviderDisputesUrl: string;  

  private claimsResponse: PagedResponse<Claim> = null;
  private disputesResponse: PagedResponse<ProviderDisputeResolution> = null;
  private claimRequest: ClaimRequest = null;
  private selectedClaim: Claim = null;
  public claimPath: string;
  private selectedClaimSubject$ = new BehaviorSubject<Claim>(this.selectedClaim);
  public selectedClaimChanged$ = this.selectedClaimSubject$.asObservable()
    .pipe(
      distinctUntilChanged()
    );
  private claimsSubject$ = new BehaviorSubject<PagedResponse<Claim>>(this.claimsResponse);
  public claimsChanged$ = this.claimsSubject$.asObservable()
    .pipe(
      distinctUntilChanged()
    );

  private disputesSubject$ = new BehaviorSubject<PagedResponse<ProviderDisputeResolution>>(this.disputesResponse);
  public disputeChanged$ = this.disputesSubject$.asObservable()
    .pipe(
      distinctUntilChanged()
    );    
  
  constructor(
    private authService: OAuthService,
    private sessionService: SessionService,
    private http: HttpClient,
    private portalConfig: PortalConfig,
    private appConfig: AppConfig) {
    this.baseProviderMemberServiceUrl = appConfig.getConfig('BaseProviderServicesApiUrl');
    if (this.baseProviderMemberServiceUrl == null) {
      const config$ = appConfig.subscribe(() => {
        this.baseProviderMemberServiceUrl = appConfig.getConfig('BaseProviderServicesApiUrl');
        this.checkUrls();
        config$.unsubscribe();
      });
    }
  }

  private checkUrls(): void {
    if (this.getMemberClaimUrl == null) {
      const baseUrl = this.baseProviderMemberServiceUrl + 'api/claim/';
      this.downloadClaimsUrl = baseUrl + 'downloadclaimlookup';
      this.getMemberClaimUrl = baseUrl + 'getProviderClaimsByClaimNumber';
      this.getProviderClaimByClaimNumberUrl = baseUrl + 'getProviderClaimByClaimNumber';
      this.getProviderClaimsByCriteriaUrl = baseUrl + 'getProviderClaimsByCriteria';
      this.getClaimReconUrl = baseUrl + 'getClaimRecon';
      this.downloadClaimReconUrl = baseUrl + 'downloadClaimRecon';
      this.getClaimReconProvidersUrl = baseUrl + 'getClaimReconProviders';
      this.getChangeHealthCareLinkUrl = baseUrl + 'getChangeHealthCareLink/';
      this.searchProviderDisputesUrl = baseUrl + 'searchProviderDisputes';
      this.getProviderDisputeUrl = baseUrl + 'getProviderDispute/';
      this.submitProviderDisputesUrl = baseUrl + 'submitProviderDisputes';
      this.downloadDisputeCorresspondanceUrl = baseUrl + 'downloaddisputecorrespondance';
    }
  }

  public setSelectedClaim(claim: Claim) {
    this.selectedClaim = claim;
    this.selectedClaimSubject$.next(claim);
  }

  public clearSelectedClaim(): void {
    this.selectedClaim = null;
    this.selectedClaimSubject$.next(this.selectedClaim);
  }

  public setClaims(claimsPagedResponse: PagedResponse<Claim>) {
    this.claimsResponse = claimsPagedResponse;
    this.claimsSubject$.next(claimsPagedResponse);
  }

  public clearClaims(): void {
    this.claimsResponse = null;
    this.claimsSubject$.next(this.claimsResponse);
  }

  public setDisputes(disputePagedResponse: PagedResponse<ProviderDisputeResolution>) {
    this.disputesResponse = disputePagedResponse;
    this.disputesSubject$.next(this.disputesResponse);
  }

  public clearDisputes(): void {
    this.disputesResponse = null;
    this.disputesSubject$.next(this.disputesResponse);
  }  

  public getClaimByCin(cin: string): Observable<Claim[]> {
    try {
      this.checkUrls();
      const request = new ClaimRequest();
      request.cin = cin;
      request.providerCollectionId = this.sessionService.currentPermission.providerCollectionID;
      return this.http.post<Claim[]>(this.getMemberClaimUrl, request, httpOptions)
        .pipe(
          map(data => {
            const sortedData = data.sort((a, b): number => {
              if (a.dateOfService < b.dateOfService) {
                return 1;
              }
              if (a.dateOfService > b.dateOfService) {
                return -1;
              }
              return 0;
            });
            return sortedData;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public getClaimByClaimNumber(claimNumber: string, currentUser: string): Observable<Claim> {
    try {
      this.checkUrls();
      const claimRequest = new ClaimRequest();
      claimRequest.claimNumber = claimNumber;
      claimRequest.username = currentUser;
      claimRequest.providerCollectionId = this.sessionService.currentPermission.providerCollectionID;
      return this.http.post<Claim>(this.getProviderClaimByClaimNumberUrl, claimRequest, httpOptions)
        .pipe(
          map(data => {

            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public getProviderDispute(appealId:string, currentUser: string): Observable<ProviderDisputeResolution>{

    try {
      this.checkUrls();
      const reqUrl = `${this.getProviderDisputeUrl}${appealId}/${currentUser}/`;      
      return this.http.get<ProviderDisputeResolution>(reqUrl,httpOptions)
        .pipe(
          map(data => {
            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public searchProviderDisputes(claimNumber: string, appealId: string, memberCin: string, currentUser:string, tin: string,startDate: Date, endDate: Date, appealStatus: string): Observable<PagedResponse<ProviderDisputeResolution>>
  {
    try {
      this.checkUrls();
      let claimDisputeRequest:ProviderClaimsDisputeRequest = new ProviderClaimsDisputeRequest();
      claimDisputeRequest.claimNumber = claimNumber;
      claimDisputeRequest.appealId = appealId;
      claimDisputeRequest.memberId = memberCin;
      claimDisputeRequest.username = currentUser;
      claimDisputeRequest.providerCollectionId = this.sessionService.currentPermission.providerCollectionID;
      claimDisputeRequest.tin = tin;
      claimDisputeRequest.startDate = startDate;
      claimDisputeRequest.endDate = endDate;
      claimDisputeRequest.appealStatus = appealStatus;
      return this.http.post<PagedResponse<ProviderDisputeResolution>>(this.searchProviderDisputesUrl, claimDisputeRequest, httpOptions)
        .pipe(
          map(data => {
            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }  

  public submitProviderDisputes(providerDisputeResolutionSubmission:ProviderDisputeResolutionSubmission): Observable<AppealCreateResponse> {
    try {
      this.checkUrls();
      return this.http.post<AppealCreateResponse>(this.submitProviderDisputesUrl, providerDisputeResolutionSubmission, httpOptions)
        .pipe(
          map(data => {
            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }  
  
  // Get providers for claim recons
  public getClaimReconciliationProviders(request: ClaimReconSearchRequest): Observable<ProviderDetail[]> {
    this.checkUrls();
    try {
      return this.http.post<ProviderDetail[]>(this.getClaimReconProvidersUrl, request, httpOptions)
        .pipe(
          map(data => {
            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // Log error
      return null;
    }
  }

  public downloadClaimRecon(request: ClaimReconSearchRequest): Observable<Blob> {
    this.checkUrls();
    try {
      return this.http.post(this.downloadClaimReconUrl, request, {
          responseType: 'blob'
      });
    }
    catch (ex) {
      // Log error
      return null;
    }
  }


  public downloadDisputeCorresspondance(docId: ProviderDisputeResolutionLetter): Observable<Blob> {
    this.checkUrls();
    try {
      return this.http.post(this.downloadDisputeCorresspondanceUrl, docId, {
          responseType: 'blob'
      });
    }
    catch (ex) {
      // Log error
      return null;
    }
  }

  public getClaimBySearchOptionsByRequest(request): Observable<Claim[]> {
    try {
      this.checkUrls();
      this.claimRequest = new ClaimRequest();
      this.claimRequest = request;
      this.claimRequest.providerCollectionId = this.sessionService.currentPermission.providerCollectionID;
      // Need to retrieve list of provider ids for external
      return this.http.post<PagedResponse<Claim>>(this.getProviderClaimsByCriteriaUrl, request, httpOptions)
        .pipe(
          map(data => {
            data.items.forEach(value => {
              const testDate = new Date(value.datePaid);
              if (value.datePaid && testDate.getFullYear() === 1753) {
                value.datePaid = null;
              }
            });
            this.setClaims(data);
            return data.items;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          }));
    }
    catch (ex) {
      // log error
      throw ex;
    }

  }

  
  public downloadClaimBySearchOptionsByRequest(): Observable<Blob> {
    this.checkUrls();

    return this.http.post(this.downloadClaimsUrl, this.getClaimRequest(), {
        responseType: 'blob'
    });
  }

  public getRAChangeHealthCareLink(claim: Claim, currentUser : string): Observable<string>{

    try {
      this.checkUrls();
      const datePaid = new Date(claim.datePaid).toISOString();
      const reqUrl = `${this.getChangeHealthCareLinkUrl}${claim.claimNumber}/${claim.memberCIN}/${currentUser}/${datePaid}/`;      
      return this.http.get<any>(reqUrl,httpOptions)
        .pipe(
          map(data => {
            return data.message.toString();
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }


  public getClaimRequest(): ClaimRequest {
    return this.claimRequest;
  }

  public getClaimResultColumns(deviceType: string): any[] {

    if (deviceType === 'xl' || deviceType === 'large') {
      return this.claimResultColumns.slice();
    }
    else if (deviceType === 'tablet-landscape') {
      return this.filterColumns(this.claimResultColumns, ['Billed/Payable', 'Service Provider']);
    }
    else if (deviceType === 'tablet-portrait') {
      return this.filterColumns(this.claimResultColumns, ['CIN', 'Billed/Payable', 'Service Provider', 'LOB', 'Check']);
    }
    else if (deviceType === 'small') {
      return this.filterColumns(this.claimResultColumns, ['Member Name', 'CIN', 'DOS', 'Billed/Payable', 'Service Provider', 'LOB', 'Check', '']);
    }
  }

  public mapClaimEobSummary(claimDetail: Claim) {
    const claimEobSummary: ClaimEobSummary = {
      claimNumber: claimDetail.claimNumber,
      totalBilledAmount: claimDetail.claimLines.map(item => item.claimLineBilledAmt).reduce((prev, next) => prev + next),
      totalAllowedAmount:  claimDetail.claimLines.map(item => item.claimLineAllowedAmt).reduce((prev, next) => prev + next),
      totalDisallowedAmount:  claimDetail.claimLines.map(item => item.claimLineDisallowedAmt).reduce((prev, next) => prev + next),
      totalCoinsuranceAmount:  claimDetail.claimLines.map(item => item.claimLineCoinsuranceAmt).reduce((prev, next) => prev + next),
      totalCopayAmount:  claimDetail.claimLines.map(item => item.claimLineCopayAmt).reduce((prev, next) => prev + next),
      totalDeductibleAmount:  claimDetail.claimLines.map(item => item.claimLineDeductibleAmt).reduce((prev, next) => prev + next),
      totalPaidAmount:  claimDetail.claimLines.map(item => item.claimLinePaidAmt).reduce((prev, next) => prev + next),
      interestPaidAmount:  claimDetail.interestPaidAmt
    };

    return claimEobSummary;
  }

  public mapClaimEobCodeList(claimDetail: Claim) {
    let eobCodeList: EobCode[] = [];
    claimDetail.claimLines.forEach(c => {
      if (c.eobCategory !== 'undefined' && c.eobCode.trim() !== '' && !eobCodeList.find(e => e.code === c.eobCode)) {
        eobCodeList.push({ code: c.eobCode, description: !UiUtility.isNullUndefinedOrEmpty(c.eobLongDesc.trim()) ? c.eobLongDesc : (!UiUtility.isNullUndefinedOrEmpty(c.eobDesc.trim()) ? c.eobDesc : "NA")});
      }
    });
    return eobCodeList;
  }

  public mapClaimEocCodeList(claimDetail: Claim) {
    let eocCodeList: EocCode[] = [];
    claimDetail.claimLines.forEach(c => {
      c.claimLineEocs.forEach(eoc => {
        if (!eocCodeList.find(e => e.code === eoc.eocCode)) {
          eocCodeList.push({ code: eoc.eocCode, description: !UiUtility.isNullUndefinedOrEmpty(eoc.eocLongDesc.trim()) ? eoc.eocLongDesc : (!UiUtility.isNullUndefinedOrEmpty(eoc.eocDesc.trim()) ? eoc.eocDesc : "NA")});
        }
      })
    });
    
    return eocCodeList;
  }

  private filterColumns(columns: any[], removeColumns: any[]) {
    return columns.filter(item => {
      return removeColumns.indexOf(item.header) === -1;
    });
  }

  public mapClaimData(claim: Claim) {
    if (claim && claim.claimLines && claim.claimLines.length > 0) {
      claim.claimLines.forEach(c => {
        c.serviceProcedureName = c.serviceProcedure
                                  ? (c.serviceProcedure.procedureCode + " - " + c.serviceProcedure.procedureDesc)
                                  : "";
        c.eocDisplayDesc = this.getExplanationCodeDesc(c);
      })
    }
  }

  private getExplanationCodeDesc(claimLine: ClaimLine) {
    let explanationCodeDesc: string = "";
    if (!UiUtility.isNullUndefinedOrEmpty(claimLine.claimLineEocs) && claimLine.claimLineEocs.length > 0) {
      claimLine.claimLineEocs.forEach(c => {
        explanationCodeDesc = explanationCodeDesc + (explanationCodeDesc !== "" ? ", " : "") + c.eocCode;
      })
    }
    return explanationCodeDesc;
  }

}
