import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild, AfterViewInit, Input } from '@angular/core';
import { CollectionService } from '../../../services/collection.service';
import { FileUpload } from 'primeng/fileupload';
import { MemberAWPStatus } from '../../../services/models/awp-detail';
import { BusyService } from '@caloptima/portal-foundation';
import { FileService } from '../../../services/file.service';

@Component({
  selector: 'hif-member-data-dialog',
  templateUrl: './hif-member-data-dialog.component.html',
  styleUrls: ['./hif-member-data-dialog.component.scss']
})
export class HIFMemberDataDialogComponent implements OnInit, OnDestroy {

  @Input() membercin : string;
  @Output() cancelMemberHifData = new EventEmitter();
  
  constructor(
    private collectionService: CollectionService,
    private busyService: BusyService,
    private fileService: FileService ) {
  }

  ngOnInit() {

  }

  ngOnDestroy() {    
  }

  ngAfterViewInit() {
    
  }

  public get display():boolean {
    return true;
  }

  public get caption(): string {
    return "Health Information Form (HIF) Survey Results";
  }

  public set display(value:boolean) {
    this.onCancel();
  }

  public onCancel() {
    this.cancelMemberHifData.emit();
  }
}
