import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-ref-submission-cancel-dialog',
  templateUrl: './ref-submission-cancel-dialog.component.html',
  styleUrls: ['./ref-submission-cancel-dialog.component.scss']
})
export class RefSubmissionCancelDialogComponent implements OnInit {

  public labelHeader: string = 'Discard content?';
  
  @Input() displayType: string = '';
  @Output() referralCancellation = new EventEmitter<boolean>();

  public get display(): boolean {
    return true;
  }

  public set display(value: boolean) {
    this.onClose();
  }

  constructor() { }

  ngOnInit() {
    if (this.displayType === 'adt') {
      this.labelHeader = 'Discard ADT?';
    }
    else if (this.displayType === 'referral') {
      this.labelHeader = 'Discard Referral?';
    }
  }

  public onContinue() {
    this.referralCancellation.emit(false);
  }

  public onCancel() {
    this.referralCancellation.emit(true);
  }

  public onClose() {
    this.onContinue();
  }
}
