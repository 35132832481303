import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, AfterViewChecked } from '@angular/core';
import { Subscription } from 'rxjs';

import { SelectItem } from 'primeng/api';
import { UiUtility } from '../../../../utils/ui-utility';
import { UserProfile } from '@caloptima/authentication';
import { PortalCode } from '../../../../services/models/portal-code';
import { PortalUser } from '../../../../services/models/portal-user';
import { CodeService } from '../../../../services/code.service';

@Component({
  selector: 'app-user-position-and-authorized-dialog',
  templateUrl: './user-position-and-authorized-dialog.component.html',
  styleUrls: ['./user-position-and-authorized-dialog.component.scss']
})
export class UserPositionAndAuthorizedDialogComponent implements OnInit, OnDestroy, AfterViewChecked  {
  private userPositions$: Subscription;
  public positionSelection: SelectItem[] = [];
  public selectedPosition: any;
  public positionOtherName: string;
  public reasonForPermissionChange: string;
  public displayOtherPositionName = false;
  public displayReasonForChange:boolean = false;
  public saveBtnDisabled = true;
  public authorizedByName = '';

  @Output() onPositionSelected = new EventEmitter<any>();
  @Input() displayAuthorizedBy = false;
  @Input() displayPositionSelection = false;
  @Input() isPcpRole = false;
  @Input() providerPositionId: number;
  @Input() providerPositionOther: string;
  @Input() user:PortalUser;

  constructor(private codeService: CodeService) { }

  ngOnInit() {
    this.displayReasonForChange = this.user.userProviderCollectionXrefID !== 0;

    if (this.displayPositionSelection) {
      this.userPositions$ = this.codeService.getUserPositions().subscribe(result => {
        if (result.length > 0) {
          result.forEach(item => {
            this.positionSelection.push({
              label: item.positionDisplayName, value: { id: item.providerPositionID, name: item.positionDesc, original: item }
            });
          });

          if (this.isPcpRole) {
            const pcpPosition = result.find(p => p.positionCode === 'MD');
            if (pcpPosition) {
              this.selectedPosition = {
                'id': pcpPosition.providerPositionID,
                'name': pcpPosition.positionDisplayName,
                'original': pcpPosition
              }
            }
          } else if (this.providerPositionId) {
            const currentPosition = result.find(p => p.providerPositionID === this.providerPositionId);
            this.selectedPosition = {
              'id': currentPosition.providerPositionID,
              'name': currentPosition.positionDisplayName,
              'original': currentPosition
            }
            if (this.isOtherPosition(currentPosition.providerPositionID)) {
              this.displayOtherPositionName = true;
              this.positionOtherName = this.providerPositionOther;
            }
          }
        }
      });
    }
  }

  ngAfterViewChecked() {
    this.isValidForm();
  }

  ngOnDestroy(): void {
    if (this.userPositions$) {
      this.userPositions$.unsubscribe();
    }
  }

  public onCancel() {
    this.onPositionSelected.emit(null);
  }

  public onSubmit() {
    this.onPositionSelected.emit(
      { 'selectedPosition' : this.selectedPosition,
        'authorizedByName' : this.authorizedByName.trim(),
        'positionOtherName' : this.positionOtherName,
        'reasonforpermissionchange' : this.reasonForPermissionChange
      });
  }

  public onSelectPosition() {
    this.isValidForm();
    this.displayOtherPositionName = this.isOtherPosition(this.selectedPosition.id);
  }

  public onKeyPress() {
    this.isValidForm();
  }

  public onClose() {
    this.onPositionSelected.emit(null);
  }

  public isValidForm() {
    if (this.displayAuthorizedBy && this.displayPositionSelection) {
      this.saveBtnDisabled = !(((this.selectedPosition && this.selectedPosition.id > 0 && !this.displayOtherPositionName)
                              || (this.displayOtherPositionName && !UiUtility.isNullUndefinedOrEmpty(this.positionOtherName)))
                              && this.authorizedByName.length > 3);
    }
    else if (this.displayAuthorizedBy) {
      this.saveBtnDisabled = !(this.authorizedByName.length > 3);
    }
    else {
      this.saveBtnDisabled = !((this.selectedPosition && this.selectedPosition.id > 0 && !this.displayOtherPositionName) ||
                               (this.displayOtherPositionName && !UiUtility.isNullUndefinedOrEmpty(this.positionOtherName)));
    }
  }

  private isOtherPosition(positionId: number) {
    const currentPosition = this.positionSelection.find(p => p.value.id === positionId);
    return currentPosition && currentPosition.label === 'Other';
  }
}
