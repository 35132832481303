import { Component, OnInit, Input, Output, EventEmitter, TemplateRef, OnDestroy } from '@angular/core';
import { AuthenticationFactor, AuthenticationService } from '@caloptima/authentication';
import { Messages } from '../../app.messages';
import { UserService } from '../../services/user.service';
import { Subscription } from 'rxjs';
import { ErrorStatusModel } from '../../services/models/login.model';

@Component({
  selector: 'verify-identity-passcode',
  templateUrl: './verify-identity-passcode.component.html',
  styleUrls: ['./verify-identity-passcode.component.scss']
})
export class VerifyIdentityPasscodeComponent implements OnInit, OnDestroy {

  private selectedFactor: AuthenticationFactor;
  public answerQuestions: boolean;
  public choiceValue: string;
  public passcode: string;
  public verifyPasscodeFormStyle: any;

  @Input() footerTemplate: TemplateRef<any>;
  @Input() errorStatus: ErrorStatusModel;
  @Input() codeExpired: boolean;
  @Input() expirationTimeRemaining: string;
  @Input() componentUsing: string;
  @Output() secondFactorPassCodeEntered = new EventEmitter();
  @Output() resendCodeEmitted = new EventEmitter();
  @Output() backToVerificationEmitted = new EventEmitter();
  @Output() validPasscode = new EventEmitter();

  public answerQuestionsSubscription$: Subscription;
  public selectedFactorSubscription$: Subscription;
  public errorSubscription$: Subscription;
  public passcodeSubscription$: Subscription;
  public subjectAlertMessage: string;
  public contentAlertMessage: string;
  public showPasscodeResentMessage: boolean = false;

  constructor( public messages: Messages, private authService: AuthenticationService, private userService: UserService) { }

  ngOnInit() {
    this.resetCode();

    this.answerQuestionsSubscription$ = this.userService.answerQuestionsSubject$.subscribe(
      answerQuestions => {this.answerQuestions = answerQuestions; }
    );

    this.selectedFactorSubscription$ = this.userService.selectedFactorSubject$.subscribe(
      selectedFactor => {
        this.selectedFactor = selectedFactor;
        this.choiceValue = this.selectedFactor && this.selectedFactor.value;
      }
    );

    this.errorSubscription$ = this.userService.errorMessageSubject$.subscribe(
      errorObject => {this.errorStatus = errorObject; }
    );

    this.passcodeSubscription$ = this.userService.selectedFactorPasscodeSubject$.subscribe(
      passcode => {
        this.passcode = passcode;
      }
    );
    this.verifyPasscodeFormStyle = (this.componentUsing === 'profile-change-password' || this.componentUsing === 'profile-security-question') ? { 'width': '100%' } : { 'width': '32%' };
  }

  ngOnDestroy() {
    if (this.answerQuestionsSubscription$) {
      this.userService.clearAnswerQuestion();
    }
    if (this.selectedFactorSubscription$) {
      this.userService.clearSelectedFactor();
    }
    if (this.passcodeSubscription$) {
      this.userService.clearPasscode();
    }
  }

  emitDoFactors() {
    this.userService.setPasscode(this.passcode);
    this.clearAlertMessage();
    this.secondFactorPassCodeEntered.emit();
  }

  emitValidPassCode(validPasscode: boolean) {
    this.validPasscode.emit(validPasscode);
  }

  reSendCode() {
    this.showPasscodeResentMessage = true;
    this.passcodeText();
    this.resendCodeEmitted.emit();
  }

  backToVerification() {
    this.resetCode();
    this.backToVerificationEmitted.emit();
  }

  public passcodeText(): string {
    let passcodeText: string;
    this.clearAlertMessage();

    if (this.selectedFactor == null) {
      passcodeText = '';
    } else if (this.selectedFactor.factorType === 'Call') {
      this.subjectAlertMessage = 'Passcode Sent!';
      this.contentAlertMessage = 'A new passcode was sent to your phone.  Be sure to check your voicemail and call-blocking settings.  If you’re still having trouble, please contact customer support at 714-246-8500.';
      passcodeText = this.messages.PhonePasscode;
    } else if (this.selectedFactor.factorType === 'SMS') {
      this.subjectAlertMessage = 'Passcode Sent!';
      this.contentAlertMessage = 'A new passcode was sent to you via text message.  If you’re still having trouble, please contact customer support at 714-246-8500.';
      passcodeText = this.messages.PhonePasscode;
    } else if (this.selectedFactor.factorType === 'Email') {
      this.subjectAlertMessage = 'Passcode Sent!';
      this.contentAlertMessage = 'A new passcode was sent to your email.  Be sure to check your spam folder.  If you’re still having trouble, please contact customer support at 714-246-8500.';
      passcodeText = this.messages.EmailPasscode;
    } else {
      passcodeText = 'Unknown passcode ' + this.selectedFactor.factorType;
    }
    return passcodeText;
  }

  resetCode() {
    this.clearAlertMessage();
    this.showPasscodeResentMessage = false;
    this.userService.clearPasscode();
  }

  public hasChoice(): boolean {
    return this.answerQuestions || this.selectedFactor != null;
  }

  private clearAlertMessage() {
    this.subjectAlertMessage = '';
    this.contentAlertMessage = '';
  }
}
