<p-dialog header="{{caption}}" [(visible)]="display" [modal]="true" (onHide)="onCancel()" width="800" [draggable]="false">
    <!--
    <div>
        <p>attestation-bh-aba-referrals-dialog works!</p>
        <div>
            {{data}}
        </div>        
    </div>    
    -->
    <div class="bh">
        <div class="bh-header">
            <app-alert-info [subjectMessage]="'Attestation Instructions:'" [contentMessage]="'Input the Direct Services Hours and Assessment.  Expand instructions for definitions.'" [uniqueID]="'bh-header-alert'"></app-alert-info>
        </div>
        <div class="bh-memberheader">
            Member Details
        </div>    
        <div class="bh-memberdetails">
            <div class="bh-memberdetailsheader">
                <div class="bh-memberdetailsheader-lblmname">Member name</div>
                <div class="bh-memberdetailsheader-lblmid">Member ID</div>
                <div class="bh-memberdetailsheader-lblrid">Referral ID</div>
                <div class="bh-memberdetailsheader-lblmauthperiod">Authorization Period</div>
            </div>
            <div class="bh-memberdetailsvalues">
                <div class="bh-memberdetailsvalues-mname">{{bhabareferral.memberFirstName}} {{bhabareferral.memberLastName}}</div>
                <div class="bh-memberdetailsvalues-mid">{{bhabareferral.memberCIN}}</div>
                <div class="bh-memberdetailsvalues-rid">{{bhabareferral.referralId}}</div>
                <div class="bh-memberdetailsvalues-mauthperiod">{{bhabareferral.startDate}} - {{bhabareferral.endDate}}</div>
            </div>            
        </div>     
        <div class="bh-dsheader">
            Direct Services Attestation
        </div>         
        <div class="bh-dsdetails">
            <div class="bh-dsdetailsheader">
                <div class="bh-dsdetailsheader-lblsvc">
                    Services
                </div>                
                <div class="bh-dsdetailsheader-lbldsa">
                    Direct Services Authorized (Units)
                </div>
                <div class="bh-dsdetailsheader-lbldsc">
                    <span title="If units represent 15 minutes, multiply the number of hours worked by 4 to calculate the correct number of units.">Direct Services Completed (Units)</span>
                </div>
                <div class="bh-dsdetailsheader-lblper">
                    % of Direct Services Completed
                </div>                                
            </div>
            <div class="bh-dsdetailsvalues">
                <div class="bh-dsdetailsvalues-row" *ngFor="let ds of bhReferral2">
                    <div class="bh-dsdetailsvalues-row-dsvc">
                        <input class="form-control" type="text" readonly required="false"  name="dsvc{{ds.autH_SVC_IDN}}" id="dsvc{{ds.autH_SVC_IDN}}id" [value]="ds.procedurE_DESC">               
                    </div>                    
                    <div class="bh-dsdetailsvalues-row-dsa">
                        <input                             
                            class="form-control" 
                            type="text" readonly required="false" 
                            name="dsa{{ds.autH_SVC_IDN}}" 
                            id="dsa{{ds.autH_SVC_IDN}}id"
                            [value]="ds.assigneD_UNITS">               
                    </div>
                    <div class="bh-dsdetailsvalues-row-dsc">
                        <input
                            title="If units represent 15 minutes, multiply the number of hours worked by 4 to calculate the correct number of units."  
                            class="form-control" 
                            maxlength="19" 
                            type="number" required="true"  
                            name="dsc{{ds.autH_SVC_IDN}}" 
                            id="dsc{{ds.autH_SVC_IDN}}id"
                            pattern="{{numberPattern}}" 
                            [(ngModel)]="ds.directServiceCompleted" 
                            (change)="onDirectServiceCompleted(ds)"
                            (keyup)="onDirectServiceCompleted(ds)">                           
                    </div>
                    <div class="bh-dsdetailsvalues-row-per">
                        <input class="form-control" maxlength="19" readonly type="text" required="false"  name="per{{ds.autH_SVC_IDN}}" id="per{{ds.autH_SVC_IDN}}id"  [value]="ds.directServicePercentage">   
                    </div>
                </div>
            </div>  
            <p-panel style="margin-top: 5px;" header="Instructions" [toggleable]="true" [collapsed]="'true'">
                <div class="bh-dsdetailsinstruction">
                    <div class="bh-dsdetailsinstruction-row" *ngFor="let inst of resources.instruction1">
                        <div class="bh-dsdetailsinstruction-text1">
                            {{inst.resourceDesc}}
                        </div>
                        <div class="bh-dsdetailsinstruction-text2">
                            {{inst.resourceValue}}
                        </div>                        
                    </div>
                </div>
            </p-panel>
        </div>         
        <div class="bh-commentheader" *ngIf="reasonRequired == true">
            <span style="font-weight: normal;">Reason&nbsp;</span>
            <i class="fa fa-question-circle-o"
                pTooltip="{{tooltip1}}"
                tooltipPosition="left"
                tooltipStyleClass="blue-theme"
                [escape]="false">
            </i>
        </div>         
        <div class="bh-commentdetails" *ngIf="reasonRequired == true">
            <div class="bh-commentdetails-reasonlbl">
                Select the reason
            </div>
            <div class="bh-commentdetails-reasonvalue">
                <p-dropdown name="txtreasonname" id="txtreasonid" [options]="reasonList" [(ngModel)]="bhabareferral.reason">
                </p-dropdown>
            </div>
            <div class="bh-commentdetails-comment">
                <textarea class="form-control" type="text"required="false" placeholder="Input comments here" name="txtcomments" id="txtcomments" [(ngModel)]="bhabareferral.comment" maxlength="500" rows="2" cols="89"></textarea>
            </div>
        </div>         
        
        <div class="bh-ahheader">
            Treatment Goal Advancement Attestation
        </div>         
        <div class="bh-ahdetails">
            <div class="bh-ahdetailsheader">
                <div class="bh-ahdetailsheader-lbldsa"># of Proposed Goals</div>
                <div class="bh-ahdetailsheader-lbldsc"># of Advanced Goals</div>
                <div class="bh-ahdetailsheader-lblper">Treatment Progress Rate</div>
            </div>
            <div class="bh-ahdetailsvalues">
                <div class="bh-ahdetailsvalues-aha">
                    <input class="form-control" 
                    type="number" 
                    required="true"  
                    name="noofassessmentgoal" 
                    id="noofassessmentgoal" 
                    pattern="{{numberPattern}}" 
                    [(ngModel)]="bhabareferral.assessmentGoals"                     
                    (change)="updateAssessmentRate()"
                    (keyup)="updateAssessmentRate()">               
                </div>
                <div class="bh-ahdetailsvalues-ahc">
                    <input class="form-control" 
                    type="number" 
                    required="true"  
                    name="goalsinprogress" 
                    id="goalsinprogress" 
                    pattern="{{numberPattern}}" 
                    [(ngModel)]="bhabareferral.assessmentGoalsCompleted" 
                    (change)="updateAssessmentRate()"
                    (keyup)="updateAssessmentRate()">               
                </div>
                <div class="bh-ahdetailsvalues-ahper">
                    <input class="form-control" type="text" readonly required="false"  name="assessmentrate" id="assessmentrate" [value]="bhabareferral.assessmentGoalsRate">               
                </div>
            </div>
            <p-panel style="margin-top: 5px;" header="Instructions" [toggleable]="true" [collapsed]="'true'">
                <div class="bh-ahdetailsinstruction">
                    <div class="bh-ahdetailsinstruction-row" *ngFor="let inst of resources.instruction2">
                        <div class="bh-ahdetailsinstruction-text1">
                            {{inst.resourceDesc}}
                        </div>
                        <div class="bh-ahdetailsinstruction-text2">
                            {{inst.resourceValue}}
                        </div>                        
                    </div>
                </div>
            </p-panel>
        </div>            
    </div>
    <p-footer>
        <div class="bh-footer" *ngIf="hasErrorDateofService == true">
            <app-alert-danger [subjectMessage]="'Error : '" [contentMessage]="'Direct Services Completed (Units) required.'" [uniqueID]="'ErrorDateofService'"></app-alert-danger>
        </div>                
        <div class="bh-footer" *ngIf="hasErrorReason == true && reasonRequired == true">
            <app-alert-danger [subjectMessage]="'Error : '" [contentMessage]="'Unforseen Circumstances reason require explanation.'" [uniqueID]="'ErrorReason'"></app-alert-danger>
        </div>                
        <div class="bh-footer" *ngIf="hasErrorProposedGoals == true">
            <app-alert-danger [subjectMessage]="'Error : '" [contentMessage]="'# of Proposed Goals required.'" [uniqueID]="'ErrorProposedGoals'"></app-alert-danger>
        </div>                
        <div class="bh-footer" *ngIf="hasErrorCompletedGoals == true">
            <app-alert-danger [subjectMessage]="'Error : '" [contentMessage]="'# of Advanced Goals required.'" [uniqueID]="'ErrorCompletedGoals'"></app-alert-danger>
        </div>                                
        <div>
          <button type="button" class="btn btn-secondary btn-cancel" (click)="onCancel()">Maybe Later</button>
          <button type="button" class="btn btn-primary btn-create" (click)="onAttest()" [disabled]="bhabareferral.attestationDate != null">Attest</button>      
        </div>
      </p-footer>    
</p-dialog>
