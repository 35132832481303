import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { PortalComponent } from "./portal.component";
import { AuthGuard } from "@caloptima/authentication";
import { ResetPasswordGuard } from "../layout/navigation/reset-password-guard/reset-password.guard";
import { UrlGuard } from "../layout/navigation/url-guard/url.guard";
import { TrainingGuard } from "../layout/navigation/training-guard/training.guard";
import { VerifyGuard } from "../layout/navigation/verify-guard/verify.guard";
import { ResetPasswordComponent } from "../reset-password/reset-password.component";

const routes: Routes = [
  {
    path: "",
    component: PortalComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "dashboard",
        loadChildren: async (): Promise<typeof import("../../app/dashboard/dashboard.module").DashboardModule> =>
          {
            const m = await import("./../dashboard/dashboard.module");
            return m.DashboardModule;
          },
        canActivate: [VerifyGuard, TrainingGuard, ResetPasswordGuard],
      },
      {
        path: "resetpassword",
        component: ResetPasswordComponent,
        canActivate: [TrainingGuard],
      },
      {
        path: "member",
        loadChildren: async (): Promise<typeof import("../../app/member-lookup/member-lookup.module").MemberLookupModule> =>
          {
            const m = await import("./../member-lookup/member-lookup.module");
            return m.MemberLookupModule;
          },
        canActivate: [AuthGuard, UrlGuard, VerifyGuard, TrainingGuard, ResetPasswordGuard],
        data: { permissionCodes: ["PRVELR"] },
      },
      {
        path: "claims",
        loadChildren: async (): Promise<typeof import("../../app/claims/claims.module").ClaimsModule> =>
          {
            const m = await import("./../claims/claims.module");
            return m.ClaimsModule;
          },
        canActivate: [AuthGuard, UrlGuard, VerifyGuard, TrainingGuard, ResetPasswordGuard],
        data: { permissionCodes: ["PRVCLR"] },
      },
      {
        path: "referrals",
        loadChildren: () =>import("./../referrals/referrals.module").then((m) => m.ReferralsModule),
        canActivate: [AuthGuard,UrlGuard,VerifyGuard,TrainingGuard,ResetPasswordGuard,],
        data: { permissionCodes: ["PRVRER", "PRVREM"] },
      },
      {
        path: "trainings",
        loadChildren: () => import("./../trainings/trainings.module").then((m) => m.TrainingsModule),
        canActivate: [AuthGuard, UrlGuard, ResetPasswordGuard],
        data: { permissionCodes: ["PRVCHR","PRVRER","PRVRPR","PRVREM","PRVLAR","PRVPHR","PRVCLR","PRVELR","PRVADM"] },
      },
      {
        path: "my-profile",
        loadChildren: () => import("./../my-profile/my-profile.module").then((m) => m.MyProfileModule),
        canActivate: [AuthGuard, UrlGuard],
        data: { permissionCodes: ["PRVCHR","PRVRER","PRVRPR","PRVREM","PRVLAR","PRVPHR","PRVCLR","PRVELR","PRVADM"] },
      },
      {
        path: "reports",
        loadChildren: () => import("./../reports/reports.module").then((m) => m.ReportsModule),
        canActivate: [AuthGuard, UrlGuard],
        data: { permissionCodes: ["PRVRPR","PRVBRM","PRVBRV"] },
      },
      {
        path: "user-admin",
        loadChildren: () => import("./../user-admin/user-admin.module").then((m) => m.UserAdminModule),
        canActivate: [AuthGuard, TrainingGuard, ResetPasswordGuard],
        data: { permissionCodes: ["PRVADM", "ENTADM"] },
      },
      {
        path: "user-admin/collections",
        loadChildren: () => import("./../collection-manager/collection-manager.module").then((m) => m.CollectionManagerModule),
        canActivate: [AuthGuard, TrainingGuard, ResetPasswordGuard],
        data: { permissionCodes: ["PRVADM", "ENTADM"] },
      },
      {
        path: "user-admin/collections/verify", // temporary path for new collection list page
        loadChildren: () => import("./../collection-manager/collection-manager.module").then((m) => m.CollectionManagerModule),
        canActivate: [AuthGuard, TrainingGuard, ResetPasswordGuard],
        data: { permissionCodes: ["PRVADM", "ENTADM"] },
      },
      {
        path: "user-admin/caloptima-user-manager",
        loadChildren: () => import("./../user-admin/user-admin.module").then((m) => m.UserAdminModule),
        canActivate: [AuthGuard, TrainingGuard, ResetPasswordGuard],
        data: { permissionCodes: ["ENTADM"] },
      },
      {
        path: "",
        loadChildren: () =>
          import("./../dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
        canActivate: [VerifyGuard, TrainingGuard, ResetPasswordGuard],
        pathMatch: "full",
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PortalRoutingModule {}
