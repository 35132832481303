<div class="container">
    <div class="text-center">
        <h1>Almost Done</h1>
        <p>This is all the information we need to get started.<br/>Click "Submit" to send your information for review.</p>
    </div>
    <div class="submit-section flex-center">
        <button class="btn btn-primary-registration submit-button" [disabled]="isClicked" id="btnRegister" (click)="onSubmit(); isClicked = true;">SUBMIT</button>
    </div>
    <div class="text-center">
        <a class="submit btn back-button btn-secondary-registration" id="btnBack" value="PREV" tabindex="3" (click)="onPrevious()">Back</a>
    </div>
</div>
