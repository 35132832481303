export class LoginErrorModel {
  constructor (
    public loginFailedStrongPartErrorMessage: string,
    public loginFailedNormalPartErrorMessage: string,
    public loginFailedLinkPartErrorMessage: string
  ) { }
}

export class ErrorStatusModel {
  constructor (
    public statusCode: number,
    public statusMessage: string
  ) { }
}
