import { Component, OnInit, OnDestroy, EventEmitter, Output, Input, OnChanges} from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';
import { PhonePipe } from '@caloptima/portal-ui-components';
import { Medication } from 'src/app/services/models/medication';
import { MemberInfoService } from 'src/app/services/member-info.service';

@Component({
  selector: 'app-member-medications',
  templateUrl: './member-medications.component.html',
  styleUrls: ['./member-medications.component.scss']
})
export class MemberMedicationsComponent implements OnInit, OnDestroy, OnChanges{
  public totalMedications = 0;
  public member$: Subscription;
  public medications: Medication[];
  public originalmedications: Medication[];
  // sort fields
  public sortColumn = 'fillDate';
  public sortAscending = true;
  public totalMembers = 0;
  public sortOptions: SelectItem[];
  public sortField: string;
  public sortOrder: number;
  public sortKey: SelectItem;
  public gender: string;
  public isUserAssocToMemberPcp: boolean;

  @Output() medicationsChange = new EventEmitter();
  @Input() isLoading: boolean;
  @Input() tabName: boolean;

    constructor(private memberInfoService: MemberInfoService) {}

 ngOnChanges(){
    //reset the sortField when using the member quick search
    this.sortField = null;
 }

  ngOnInit() {
    this.sortField = "fillDate";
    this.sortOrder = -1;
    this.sortOptions = [
      { label: 'Date', value: 'fillDate' },
      { label: 'Label Name', value: 'drugName' },
      { label: 'Prescriber Name', value:'prescriberName' }
    ];
    this.sortKey = this.sortOptions[0];

    this.initializeData();
  }

  ngOnDestroy() {
    if (this.member$) {
      this.member$.unsubscribe();
    }
  }

  public onSortChange(event) {
    const value = event.value.value;
    if (value === 'fillDate') {
      this.sortOrder = -1;
    }
    else {
      this.sortOrder = 1;
    }
    this.sortField = value;

    this.onSort();
  }

  public onSortInit(event){
    this.sortField = this.sortKey.value;
    this.sortOrder = event.sortOrder;
  }


  public sortClass(column: string): string {
    let cls: string;
    if (column === this.sortColumn) {
      cls = this.sortAscending ? 'fa fa-chevron-up' : 'fa fa-chevron-down';
    }
    else {
      cls = 'fa fa-chevron-up';
    }
    return cls;
  }

  public getPharmacyAddress(item: Medication) {
      let phone = new PhonePipe().transform(item.pharmacyAddress.phone, null);
      return item.pharmacyAddress.address1 + item.pharmacyAddress.address2 + '\n' + item.pharmacyAddress.city + ' ' + item.pharmacyAddress.state + ' ' + item.pharmacyAddress.zipCode.substring(0, 5) + '\n' + phone;
  }

  private initializeData() {
    this.member$ = this.memberInfoService.selectedMemberChanged$.pipe(
      map((data) => {
        this.memberInfoService.getMemberMedications(data.cin, data.grgrCk).subscribe(result => {
          this.isUserAssocToMemberPcp = data.isUserAssocToMemberPcp;
          this.medications = result;
          this.originalmedications = result.slice(0);

          if (this.medications && this.medications.length > 0) {
            this.totalMedications = this.medications.length;
          }

          this.onSort();
          this.medicationsChange.emit(true);
        }, error => {
          this.totalMedications = 0;
          this.medications = [];
          this.medicationsChange.emit(true);
        });
        return data;
      })
    ).subscribe();
  }

  public onSort(): void {
    var temp:Medication[] = this.originalmedications.slice(0);

    temp.sort((data1, data2) => {
      var value1 = data1[this.sortField];
      var value2 = data2[this.sortField];

      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (this.sortField.trim().toUpperCase() == "FILLDATE") {
        let val1:Date = new Date(value1);
        let val2:Date = new Date(value2);
        result = (val1 < val2) ? -1 : (val1 > val2) ? 1 : 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        let val1:number = Number(value1);
        let val2:number = Number(value2);
        result = (val1 < val2) ? -1 : (val1 > val2) ? 1 : 0;
      }

      return (this.sortOrder * result);
    });

    this.medications = temp;
    this.sortAscending = this.sortOrder == 1;
    this.sortColumn = this.sortField;
  }
}
