import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { KnowledgeBase, UserProfile } from '@caloptima/authentication';

@Component({
  selector: 'create-security-questions',
  templateUrl: './create-security-questions.component.html',
  styleUrls: ['./create-security-questions.component.scss']
})
export class CreateSecurityQuestionsComponent implements OnInit {
  public questions: string[] = ['What city were you born in?', 'What was your favorite childhood game?'];
  public answers: string[] = ['', ''];
  public displayHeader = true;
  public hideAnswersChecked = false;

  @Input() public footerTemplate: TemplateRef<any>;
  @Input() userProfile: UserProfile;
  @Input() public componentUsing: string;
  @Output() valid = new EventEmitter<KnowledgeBase[]>();

  constructor() { }

  ngOnInit() {
    if (this.userProfile.knowledgeBase){
      let retainQuestions = [];
      let retainAnswers = [];
      retainQuestions.push(this.userProfile.knowledgeBase[0].question, this.userProfile.knowledgeBase[1].question);
      retainAnswers.push(this.userProfile.knowledgeBase[0].answer, this.userProfile.knowledgeBase[1].answer);
      this.questions = retainQuestions;
      this.answers = retainAnswers;
    }
    if (this.componentUsing === 'profile-security-question') {
      this.displayHeader = false;
    }
    this.onHideAnswers({ checked: false });
  }

  public onChange(): void {
    let knowledgeBase: KnowledgeBase[];
    if (this.answers[0].length > 0 && this.answers[1].length > 0) {
      knowledgeBase = [];
      knowledgeBase.push({ question: this.questions[0], answer: this.answers[0] });
      knowledgeBase.push({ question: this.questions[1], answer: this.answers[1] });
    }
    else {
      knowledgeBase = null;
    }
    this.valid.emit(knowledgeBase);
  }

  public onHideAnswers(e) {
    document.getElementById('txtAnswer1').setAttribute('type', e.checked ? "password" : "text");
    document.getElementById('txtAnswer2').setAttribute('type', e.checked ? "password" : "text");
  }
}
