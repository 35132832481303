import { MemberEligibilityStatus } from './member-eligibility-status';
import { SelectItem } from 'primeng/api';

export class MemberStatusMap {
  public static statusItems: SelectItem[] = [
    { value: MemberEligibilityStatus.Eligible, label: 'ELIGIBLE', title: 'ELIGIBLE', icon: 'fa fa-user-circle fa-lg member-eligible', styleClass:  'member-eligible'},
    { value: MemberEligibilityStatus.NotEligible, label: 'INELIGIBLE', title: 'INELIGIBLE', icon: 'fa fa-user-circle fa-lg member-not-eligible', styleClass: 'member-not-eligible' },
    { value: MemberEligibilityStatus.SharedCost, label: 'INELIGIBLE DUE TO SHARE OF COST', title: 'INELIGIBLE DUE TO SHARE OF COST', icon: 'fa fa-user-circle fa-lg member-shared-cost', styleClass: 'member-shared-cost' }
  ];

  public static checkStatusItems: SelectItem[] = [
    { value: MemberEligibilityStatus.Eligible, label: 'ELIGIBLE', icon: 'fa fa-user-circle fa-lg member-eligible' },
    { value: MemberEligibilityStatus.NotEligible, label: 'INELIGIBLE', icon: 'fa fa-user-circle fa-lg member-not-eligible' },
    { value: MemberEligibilityStatus.SharedCost, label: 'INELIGIBLE DUE TO SHARE OF COST', icon: 'fa fa-user-circle fa-lg member-shared-cost' }
  ];

  public static mapCheckStatustoIcon(status: MemberEligibilityStatus): string {
    let cssClass = '';
    MemberStatusMap.checkStatusItems.forEach(item => {
      if (item.value === status) {
        cssClass = item.icon;
      }
    });

    return cssClass;
  }

  public static mapCheckStatusToSelectItem(status: MemberEligibilityStatus): SelectItem {
    let item: SelectItem = null;
    MemberStatusMap.checkStatusItems.forEach(x => {
      if (x.value === status) {
        item = x;
      }
    });

    return item;
  }

  public static mapStatustoIcon(status: MemberEligibilityStatus): string {
    let cssClass = '';
    MemberStatusMap.statusItems.forEach(item => {
      if (item.value === status) {
        cssClass = item.icon;
      }
    });

    return cssClass;
  }

  public static mapStatusToSelectItem(status: MemberEligibilityStatus): SelectItem {
    let item: SelectItem = null;
    MemberStatusMap.statusItems.forEach(x => {
      if (x.value === status) {
        item = x;
      }
    });

    return item;
  }

}
