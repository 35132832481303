import { Component, OnInit, Input, Output, TemplateRef, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { RegistrationState } from '../../../services/models/registration-state';
import { RegistrationService } from '../../../services/registration-service';
import { Messages } from '../../../app.messages';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { UiUtility } from '../../../utils/ui-utility';
import { UserProfile } from '@caloptima/authentication';

@Component({
  selector: 'end-user-license',
  styleUrls: ['./eula.component.scss'],
  templateUrl: './eula.component.html'
})
export class EulaComponent implements OnInit {
  public disableAccept: boolean;
  public disableDecline = false;
  public declined: boolean;
  public declineMessage: string;
  public eula: SafeHtml;

  private lastScrollTop: number = 0;
  private eulaVersion = 0;
  private scaleFactor: number;
  private termsOfUseText = 'TOS';
  public isTermsOfUse: boolean;
  @Input() registrationState: RegistrationState;
  @Input() footerTemplate: TemplateRef<any>;
  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();
  @Output() accept = new EventEmitter<number>();

  constructor(
    private registrationService: RegistrationService,
    private messages: Messages,
    private sanitizer: DomSanitizer,
    private router: Router) {
    this.disableAccept = true;
    this.declineMessage = messages.DeclinedMessage;
    this.eula = messages.DefaultEulaContent;

  }

  ngOnInit() {
    this.scaleFactor = window.outerWidth / window.innerWidth;
    let path = this.router.url;
    if (UiUtility.isNullUndefinedOrEmpty(path) == false) {
      this.isTermsOfUse = path.toLocaleUpperCase().includes(this.termsOfUseText.toLocaleUpperCase());
      if (this.isTermsOfUse == true) {
        this.registrationState = new RegistrationState();
        this.registrationState.profile = new UserProfile();
        this.registrationState.profile.email = "ViewOnly";
        this.registrationState.profile.userID = "ViewOnly";
      }
    }

    this.registrationService
      .getEndUserLicense(this.registrationState.profile.email, this.registrationState.profile.userID)
      .subscribe(
        result => {
          this.eula = this.sanitizer.bypassSecurityTrustHtml(result.content);
          this.eulaVersion = result.version;
              // check for prior acceptance
          if ((this.registrationState.acceptedEulaVersion && this.registrationState.acceptedEulaVersion >= this.eulaVersion) ||
              this.isTermsOfUse === true) {
            this.setAcceptedButtonStates();
          }
        },
        error => {
          // log error
        }
      );
  }

  private setAcceptedButtonStates() {
    this.declined = false;
    this.disableDecline = true;
    this.disableAccept = true;
  }

  onScroll(e: any) {
    const ctl = e.currentTarget;
    if (this.disableAccept) {
      if (ctl.scrollTop < this.lastScrollTop) {
          // upscroll: do nothing
          return;
      } 
      this.lastScrollTop = ctl.scrollTop <= 0 ? 0 : ctl.scrollTop;
      if (ctl.scrollTop + ctl.offsetHeight >= ctl.scrollHeight) {
        this.disableAccept = false;
      }
    }
  }

  onDeclineTerms() {
    this.declined = true;
    this.accept.emit(null);
  }

  onAcceptTerms() {
    this.setAcceptedButtonStates();
    this.accept.emit(this.eulaVersion);
  }

  onSubmit() {
    this.next.emit();
  }
}
