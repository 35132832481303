
import { Component, OnInit, Provider, Inject, numberAttribute, ViewChild } from '@angular/core';
import { UserProfile } from '@caloptima/authentication';
import { SelectItem } from 'primeng/api';
import { DropDownObject } from '../../services/models/drop-down-object.interface';
import { ProviderDetail, ProviderDetailWithBHI } from '../../services/models/provider-detail';
import { ReportsService } from '../../services/reports.service';
import { SessionService } from '../../services/session.service';
import { saveAs } from 'file-saver';
import { DatePipe, DOCUMENT } from '@angular/common';
import { ReportType } from '../../services/models/reportype';
import { UiUtility } from '../../utils/ui-utility';
import { BHIMeasurementPeriod } from 'src/app/services/models/responses/bhi-measurement-period';
import { AttestationDetail } from 'src/app/services/models/attestation-detail';
import { AttestationStatus } from 'src/app/services/models/attestationstatus';
import { ReportsProviderSearchComponent } from 'src/app/app-components/reports-provider-search/reports-provider-search.component';
import { ControlContainer } from '@angular/forms';
import { Constants } from 'src/app/app.constants';
import { ReportsMemberSearchComponent } from 'src/app/app-components/reports-member-search/reports-member-search.component';
import { AlertWarningComponent } from 'src/app/app-components/alerts/alert-warning/alert-warning.component';


@Component({
  selector: 'app-reports-bhi',
  templateUrl: './reports-bhi.component.html',
  styleUrls: ['./reports-bhi.component.scss']
})
export class ReportsBHIComponent implements OnInit {
  public providers:ProviderDetailWithBHI[] = [];
  public displayProviders:ProviderDetailWithBHI[] = [];
  public displayPrescribers:ProviderDetailWithBHI[] = [];
  public selectedCalProviderID: string;
  public selectedProvider: ProviderDetailWithBHI;
  private sortColumn: string;
  private sortAscending: boolean;
  public totalRecords: number;
  public measurementPeriods: BHIMeasurementPeriod[];
  public searchProviderId: string;
  public searchProviderName: string;
  public overAllCheckUnCheckValue: boolean;
  public showAttestation: boolean;
  public userFullName: string;
  public optionValue: string = "1";

  @ViewChild('reportsprovidersearchcomponent') reportsprovidersearchcomponent: ReportsProviderSearchComponent
  @ViewChild('reportsprescribersearchcomponent') reportsprescribersearchcomponent: ReportsMemberSearchComponent

  constructor(
    private sessionService: SessionService,
    private reportsService: ReportsService,
    private datePipe: DatePipe,
    @Inject(DOCUMENT) private document: Document, private constants: Constants) {
      this.sortColumn = 'name';
      this.sortAscending = true;
      this.searchProviderId = '';
      this.searchProviderName = '';
      this.selectedCalProviderID = '';
  }

  ngOnInit() {

    if(this.sessionService.currentPermission.isEnterpriseAdmin || this.sessionService.currentPermission.isEnterpriseUser) {
      //  Nothing. 
    }
    else {
      this.reportsService.getProvidersForBHI(this.sessionService.currentPermission.providerCollectionID).subscribe(result=>{

        this.providers = result;
  
        this.totalRecords = this.providers.length;
  
        if(this.providers)
          this.displayProviders = this.providers.slice();
  
        let param: any = {
          "field": "name",
          "order": true
        }
        this.onSort(param)
      });
    }
  }

  public sortClass(column: string): string {
    let cls: string;
    if (column == this.sortColumn) {
      cls = this.sortAscending ? "fa fa-chevron-up" : "fa fa-chevron-down";
    }
    else {
      cls = "fa fa-chevron-up";
    }
    return cls;
  }

  public onPage(e: any) {
    let s = JSON.stringify(e);
  }

  public onSort(e: any): void {
    this.displayProviders.sort((data1, data2) => {
      let value1 = data1[e.field];
      let value2 = data2[e.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (e.order * result);
    });
    this.sortAscending = e.order == true;
    this.sortColumn = e.field;
  }

  public selectOption($event) {
    if(this.optionValue == "1") {
      this.reportsprovidersearchcomponent.setLabel("Provider Name", "Provider ID", "Provider TIN", "Search for Provider");
      this.displayProviders = this.providers.slice();
      this.onProviderSelected(null);

      let param: any = {
        "field": "name",
        "order": true
      }
      this.onSort(param)
    }
    else if(this.optionValue == "2") {
      this.reportsprescribersearchcomponent.setLabel("", "Prescriber NPI", "Search for Prescriber");
      this.displayPrescribers = [];
      this.onProviderSelected(null);
      
    }
  }

  public onProviderSelected(provider: ProviderDetailWithBHI): void {
    this.selectedProvider = provider;

    if(this.selectedProvider) {
      this.selectedCalProviderID = provider.calProviderID;

      this.measurementPeriods = [];
      this.reportsService.getBHIMeasurementPeriods(this.selectedCalProviderID).subscribe(result=>{
        this.measurementPeriods = result;
  
        if(this.measurementPeriods.length > 0) 
        {
          this.measurementPeriods.forEach(x=>{
            
            var year:string = x.measurementPeriod.substring(0,4);
            var month:string = x.measurementPeriod.substring(4,6);
            var periodDate: Date = new Date(parseInt(year), parseInt(month) -1, 1);
            const monthNames = ["Jan", "Feb", "Mar", "Apr",
                          "May", "Jun", "Jul", "Aug",
                          "Sep", "Oct", "Nov", "Dec"];
  
            x.measurementPeriodDisplay = monthNames[parseInt(month)-1] + " " + year;
  
            if(!x.attestationStatus) {
              if(x.attestationDate) {
                x.attestationStatus ="Attested";
              }
              else {  
                var currentDate = new Date()
                var diff = (currentDate.valueOf() - periodDate.valueOf())/(1000*60*60*24);
    
                if(diff >= 60) {
                  x.attestationStatus = "Overdue";
                }
                else {
                  x.attestationStatus = "Pending";
                }
              }
            }
          });
  
          UiUtility.scrollDown(this.document);
        }      
      });
    }
    else  {
      this.selectedCalProviderID = "";
    }
  }

  public get displayText(): string {
    if(this.selectedCalProviderID) {

      let providerDetail: ProviderDetail = this.providers.find(x=> x.calProviderID === this.selectedCalProviderID);

      if(providerDetail) {
        let s = providerDetail.name;
        return s;
      }
      else {
        return ""
      }
    }
    else {
      return "";
    }
  }

  public get enableBuildReport() : boolean {

    if(this.measurementPeriods) {
      var found = this.measurementPeriods.find(x=>x.selected);
      if(found && this.selectedCalProviderID !== '')
        return true;
    }
    
    return false;
  }

  public onSubmitAttestation($event){
    this.download();
  }

  public get isInternalUser(): boolean {
    return this.sessionService.isEnterpriseAdmin || this.sessionService.isEnterpriseUser;
  }

  public download(){

    var items:string[] = [];

    this.measurementPeriods.forEach(x=>{
      if(x.selected) {
          items.push(x.measurementPeriod);
        };
    });    

    this.reportsService.downloadbhimembers(this.selectedCalProviderID, items).subscribe(result=>{
      var fileName:string = (items.length == 1 ? (this.selectedCalProviderID + "_" + items[0]) : this.selectedCalProviderID) + ".csv";
      this.saveAsFile(result, fileName, 'csv');
      this.showAttestation = false;
      this.onProviderSelected(this.selectedProvider);
    });
  }

  public onCancelAttestation(): void {
    this.showAttestation = false;
  }

  public onBuildReport() : void {
    if(this.isInternalUser) {
      this.download();
    }
    else 
    {
      this.showAttestation = true;
    }
  }

  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob([buffer], { type: fileType });
    saveAs(data, fileName);
  }

  public get overAllCheckUnCheck() : boolean {
    return this.overAllCheckUnCheckValue;
  }

  public set overAllCheckUnCheck(value:boolean) {

    if(this.measurementPeriods) {
      this.measurementPeriods.forEach(item =>{
          item.selected = value;
      });
    }

    this.overAllCheckUnCheckValue = value;
  }

  public onSearch(search) : void {
    this.selectedCalProviderID = '';

    if (search && (search.providerName || search.providerId || search.providerTin)) {

      if(this.sessionService.currentPermission.isEnterpriseAdmin || this.sessionService.currentPermission.isEnterpriseUser) {

        this.reportsService.searchProvidersForBHI(search.providerId, search.providerName, search.providerTin).subscribe(result => {
          this.providers = result;
          this.displayProviders = this.providers.slice();
          });
      }
      else {
          if (!UiUtility.isNullUndefinedOrEmpty(search.providerName)) {
            this.displayProviders = this.providers
              .filter(p => p.name.toLowerCase().indexOf(search.providerName.trim().toLowerCase()) >= 0);
          }
          else if (!UiUtility.isNullUndefinedOrEmpty(search.providerId)) {
            this.displayProviders = this.providers
              .filter(p => p.calProviderID.toLowerCase().indexOf(search.providerId.trim().toLowerCase()) >= 0);
          }
          else if (!UiUtility.isNullUndefinedOrEmpty(search.providerTin)) {
            this.displayProviders = this.providers
              .filter(p => p.taxId.toLowerCase().indexOf(search.providerTin.trim().toLowerCase()) >= 0);
          }        
      }
    }
    else {
      this.displayProviders = this.providers.slice();
    }
  }

  public onSearchPrescriber(search) : void {
    this.selectedCalProviderID = '';
    if (search && (search.memberId)) {

      if(this.sessionService.currentPermission.isEnterpriseAdmin || this.sessionService.currentPermission.isEnterpriseUser) {

        this.reportsService.searchPrescribersForBHI(search.memberId).subscribe(result => {
          this.providers = result;
          this.displayPrescribers = this.providers.slice();
          });
      }
      else {
          this.displayPrescribers = [];
          this.providers.forEach(provider=>{

            var candidate: boolean = false;
            provider.bhiPrescriberNPI = "";
            provider.bhiPrescriberTaxID = "";

            provider.bhiPrescriberList.forEach(prescriber=>{              
              if(!UiUtility.isNullOrUndefined(search.memberId) && 
                  prescriber.prescriberNPI.toLowerCase().indexOf(search.memberId.toLowerCase()) >= 0) {
                    candidate = true;
                    provider.bhiPrescriberNPI += prescriber.prescriberNPI + this.constants.stringSeparator;
                    provider.bhiPrescriberTaxID += prescriber.prescriberTaxID + this.constants.stringSeparator;
              }        
            });

            if(candidate){        
              provider.bhiPrescriberTaxID = UiUtility.trimLastFromEnd(provider.bhiPrescriberTaxID);
              provider.bhiPrescriberNPI = UiUtility.trimLastFromEnd(provider.bhiPrescriberNPI);

              this.displayPrescribers.push(provider);
            }              
          });
      }
    }
    else {
      this.displayPrescribers = [];
    }
  }  
}
