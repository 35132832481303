<p-dialog [(visible)]="display" [style]="{width: '42rem'}" [baseZIndex]="10000" [modal]="true" [closeOnEscape]="false"
  [draggable]="false" [resizable]="false" [closable]="false">
  <ng-template pTemplate="header">
    <h1><span>Welcome to the Provider Portal</span></h1>
  </ng-template>
  <div class="organization-body">
    <div class="multi-org-message"> <h1>We see you have multiple offices associated to your profile. Which organization do you want to be your default? </h1></div>
    <div class="user-org-select-info-text"> Don't worry, you can always change this in your profile. </div>

    <div class="user-org-select-header-text">
      <label for="providerSpecialty">Choose an Organization</label>
    </div>
    <div class="filter-organization">
      <p-dropdown name="drpProviderSpecialty" id="drpProviderSpecialty" [options]="userOrganizations" [optionLabel]="userorganizationlabel"
        [(ngModel)]="selectedUserOrganization">
      </p-dropdown>
    </div>
    <button type="button" id="btnSelectOrganization" class="btn-provider btn btn-primary" [disabled]="isOrgSelected()" (click)="updateDefaultOrgSelection()">
      MAKE DEFAULT
    </button>
  </div>
</p-dialog>
