import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { SessionService } from '../../../services/session.service';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UrlGuard  {
  constructor(
    private sessionService: SessionService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let allowed: boolean = true;

    const perms = this.sessionService.permissionsChanged()
      .pipe(
        switchMap(x => {
          if (x) {
            if (route.data != null && route.data["permissionCodes"] != null) {
              allowed = this.sessionService.hasPermission(route.data["permissionCodes"]);
            }
          }
          return of(allowed);
        })
      );

    return perms;
  }
}
