import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-reports-member-search',
  templateUrl: './reports-member-search.component.html',
  styleUrls: ['./reports-member-search.component.scss']
})
export class ReportsMemberSearchComponent implements OnInit {
  public memberName: string;
  public memberId: string;
  public searchLabel: string = "Search for Members";
  public idLabel: string = "Member ID";
  public instructionLabel: string ="CIN, MEDS ID, or MBI accepted";
  @Output() onMemberSearch: EventEmitter<any> = new EventEmitter(null);

  constructor() { }

  ngOnInit() {
  }

  public findMembers() {
    this.onMemberSearch.emit({ memberName: this.memberName, memberId: this.memberId });
  }

  public resetSearchFilters() {
    this.memberName = '';
    this.memberId = '';
  }

  public setLabel(instructionlabel: string, id: string, search: string ){
    this.instructionLabel = instructionlabel;
    this.idLabel = id;
    this.searchLabel = search;
  }
}
