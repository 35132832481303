import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import {
  AuthenticationService,
  AuthenticationFactor,
  AuthenticationServiceStatus,
  AuthHelper,
  UserProfile,
  AuthProfile,
  AccountStatus,
  KnowledgeBase} from '@caloptima/authentication';

import { RegistrationService } from '../../services/registration-service';
import { BusyService } from '../../services/busy.service';
import { PortalConfig } from '../../portal-config';
import { Messages } from '../../app.messages';
import { UserService } from '../../services/user.service';
import { Observable, Subscription, timer } from 'rxjs';
import { ErrorStatusModel } from '../../services/models/login.model';
import { VerifyIdentityModel } from '../../services/models/verify-identity.model';
import { AuthenticationFactorChoice } from '../../services/models/authentication-factor-choice.model';
import { ServiceUtility } from '../../services/service-utility';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

  @Input() componentUsing: string;
  @Input() emailAddress: string;
  @Output() submitChange = new EventEmitter();

  private userProfile: UserProfile;
  private password: string;
  private emailCodeValid: boolean;
  private isBusy: boolean;
  private isPasswordValid: boolean;

  public passcode: string;
  public codeExpired: boolean
  public expirationTimeRemaining: string;
  public verifyIdentityModel: VerifyIdentityModel = new VerifyIdentityModel();
  public choices: AuthenticationFactorChoice[];
  public answers: string[];
  public kb: KnowledgeBase[];
  private isValidPasscodeFormat: boolean;
  private selectedFactor: AuthenticationFactor;
  private timerSubscription$: Subscription;
  private selectedFactorSubscription$: Subscription;
  private passcodeSubscription$: Subscription;
  private minutesRemaining: number;
  private timer: Observable<number>;
  private errorStatus: ErrorStatusModel;

  public stepNumber: number;
  public notRegistered: boolean;
  public notSetup: boolean;
  public isApproved: boolean;
  public isActive: boolean;
  public isLocked: boolean;
  public emailSendFailed: boolean;
  public passwordSet: boolean;
  public isSameAsOldPassword: boolean;
  public isReset: boolean;
  public verificationCode: string;
  public redirectPage: string;

  constructor(
    private authService: AuthenticationService,
    private registrationService: RegistrationService,
    private userService: UserService,
    private router: Router,
    private busy: BusyService,
    private config: PortalConfig,
    public messages: Messages
  ) {
    this.emailAddress = '';
    this.notRegistered = false;
    this.notSetup = false;
    this.isActive = false;
    this.isLocked = false;
    this.isApproved = false;
    this.emailSendFailed = false;
    this.emailCodeValid = false;
    this.isBusy = false;
    this.stepNumber = 1;
    this.passwordSet = false;
    this.isSameAsOldPassword = false;
  }

  ngOnInit() {
    if (this.componentUsing === 'reset-password') {
      this.isReset = true;
      this.stepNumber = 1;
      this.onNext();
    }

    this.verifyIdentityModel.TwoFactorOptionsDescription = 'To protect your account, we need to verify your identity. You will receive a temporary code using the delivery method you select below.';

    this.selectedFactorSubscription$ = this.userService.selectedFactorSubject$.subscribe(
      selectedFactor => {this.selectedFactor = selectedFactor; }
    );

    this.passcodeSubscription$ = this.userService.selectedFactorPasscodeSubject$.subscribe(
      passcode => {
        this.passcode = passcode;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.selectedFactorSubscription$ != null) {
      this.selectedFactorSubscription$.unsubscribe();
    }
    if (this.passcodeSubscription$ != null) {
      this.passcodeSubscription$.unsubscribe();
    }
    if (this.timerSubscription$ != null) {
      this.timerSubscription$.unsubscribe();
    }
  }

  public onNext(): void {
    if (this.stepNumber === 1) {
      // Look up the email address
      this.busy.emit(true);

      const sub = this.authService.findUsers(null, this.emailAddress, this.config.ClientId, 1, 1)
      .subscribe(users => {
        this.busy.emit(false);
        this.userProfile = users[0];
        // Check the user status
        this.notRegistered = users == null || users.length == 0;
        this.notSetup = this.userProfile.accountStatus == AccountStatus.Submitted
          || this.userProfile.accountStatus == AccountStatus.Pending
          || this.userProfile.accountStatus == AccountStatus.Deactivated
          || this.userProfile.accountStatus == AccountStatus.Inactive;
        this.isApproved = this.userProfile.accountStatus === AccountStatus.PasswordSetupNeeded;
        this.isActive = this.userProfile.accountStatus === AccountStatus.Active;
        this.isLocked = this.userProfile.accountStatus === AccountStatus.Locked;

        if (this.isActive) {
          this.getFactors();
          this.stepNumber++;
        }
        else if (this.isLocked && this.componentUsing === 'forgot-password') {
          this.router.navigateByUrl(
            this.router.createUrlTree(
              ['user/checkstatus'], { queryParams: { account: 'locked', email: this.userProfile.email } }
            )
          );
        }

        if (sub != null) {
          sub.unsubscribe();
        }
      });
    } else if (this.stepNumber === 2) {
      this.stepNumber++;
    } else if (this.stepNumber === 3) {
      this.stepNumber++;
    }
  }

  public onSetup() {
    if (this.isApproved) {
      this.router.navigateByUrl(
        this.router.createUrlTree(
          ['user/setupPassword'], { queryParams: { user: this.userProfile.userID } }
        )
      );
    }
  }

  public onPrevious(): void {
    this.stepNumber--;
  }

  public onPasswordValid(event): void {
    this.isPasswordValid = event;
  }

  public onPasswordChange(password: string): void {
    this.password = password;
  }

  public validPasscode(validPasscode: boolean) {
    this.isValidPasscodeFormat = validPasscode;
  }

  public onSave(): void {
    const authProfile: AuthProfile = new AuthProfile();
    authProfile.clientId = this.config.ClientId;
    authProfile.userProfile = this.userProfile;
    authProfile.userProfile.password = this.password;
    authProfile.verificationCode = this.verificationCode;

    const sub = this.authService.updateUser(authProfile).subscribe(() => {
      // set email for prepopulating in login
      this.userService.setEmail(authProfile.userProfile.email);
      this.passwordSet = true;
      this.isSameAsOldPassword = false;
      this.busy.emit(true);
      this.submitChange.emit(
        {
          isSuccess: true
        }
      );
      this.busy.emit(false);
      if (sub != null) {
        sub.unsubscribe();
      }
    },
        (err) => {
          if(err.error  && err.error ==="Your password cannot be reset at this time, please notify an administrator."){
            this.isSameAsOldPassword = true;
            this.passwordSet = false;
          }
          this.busy.emit(false);
          this.submitChange.emit(
            {
              isSuccess: false
            }
          );
          if (sub != null) {
            sub.unsubscribe();
          }
        });
  }

  public onEmailChange() {
    this.notRegistered = false;
    this.notSetup = false;
  }

  public isInvalid(): boolean {
    let invalid = false;
    if (this.stepNumber === 1) {
      invalid = this.emailAddress === '' || this.notRegistered || this.notSetup;
    } else if (this.stepNumber === 3) {
      invalid = !this.emailCodeValid;
    } else if (this.stepNumber === 4) {
       invalid = !this.isPasswordValid || this.passwordSet;
    }
    return invalid;
  }

  public backToVerification() {
    this.userService.clearSelectedFactor();
    this.codeExpired = false;
    this.stepNumber--;
    this.getFactors();
  }

  public reSendCode() {
    this.getFactors();
    this.DoFactors(true);
  }

  public DoFactors(resendCode = false) {
    this.busy.emit(true);
    const responses: AuthenticationFactor[] = [];

    if (this.selectedFactor != null) {
      responses.push({ factorType: this.selectedFactor.factorType, factorID: this.selectedFactor.factorID, value: this.passcode });
    }

    const sub = this.authService.authenticateFactors(responses).subscribe((resp) => {
      if (resp.status === AuthenticationServiceStatus.NoError) {
        this.stepNumber = 4;
      } else if (resp.status === AuthenticationServiceStatus.OnetimePasscodeRequired) {
        this.userService.clearPasscode();
        if (resendCode) {
          this.clearErrorMessage()
        }
        else {
          this.onNext();
        }
      } else if (resp.status === AuthenticationServiceStatus.NotallFactorsAuthenticated) {
        this.setErrorMessage(resp.status);
        this.userService.clearPasscode();
      }
      this.busy.emit(false);
      this.startTimer();
      if (sub != null) {
        sub.unsubscribe();
      }
    }, (error) => {
      this.setErrorMessage(error.status, error.error);
      this.busy.emit(false);
      this.userService.clearPasscode();
    });
  }

  public getFactors() {
    this.busy.emit(true);
    this.authService.getFactors().subscribe({
      next: (response)  => {
        this.choices = [];
        this.answers = [];

        for (let i = 0; i < response.length; i++) {
          if (response[i].factorType !== 'Question') {
            this.choices.push(new AuthenticationFactorChoice(
              response[i].factorType,
              response[i].factorID,
              response[i].value,
              ServiceUtility.getFactorsChoiceDislaySequence(response[i].factorType)));
          }
        }
        this.choices.sort((a, b) => {
          return a.sequence - b.sequence;
        });
        this.busy.emit(false);
      },
      error: (err) => {
        this.busy.emit(false);
      }
    });
  }

  startTimer() {
    if (this.timerSubscription$ != null) {
      this.timerSubscription$.unsubscribe();
    }
    this.minutesRemaining = 15;
    this.expirationTimeRemaining = this.minutesRemaining + ':00' + this.messages.MinutesLabel;
    var secondsRemaining = this.minutesRemaining * 60;
    this.timerSubscription$ = timer(1000, 1000).subscribe(t => {
      if (--secondsRemaining > 0) {
        this.codeExpired = false;
        var label = secondsRemaining < 60 ? this.messages.SecondsLabel : this.messages.MinutesLabel;
        var secondsPart = (secondsRemaining % 60).toString();
        if (secondsPart.length == 1) {
          secondsPart = '0' + secondsPart;
        }
        this.expirationTimeRemaining = Math.floor(secondsRemaining / 60) + ':' + secondsPart + label;
      }
      else {
        this.codeExpired = true;
        this.timerSubscription$.unsubscribe();
        this.setErrorMessage(13);
      }
    });
  }

  private setErrorMessage(statusCode: number = null, statusMessage: string = null) {
    this.errorStatus = new ErrorStatusModel(statusCode, statusMessage);
    this.userService.setErrorMessage(this.errorStatus);
  }

  private clearErrorMessage() {
    this.errorStatus = null;
    this.userService.clearErrorMessage();
  }
}
