<div class="reports-pdc-report-container">
  <div class="reports-pdc-report-type">
    <app-reports-type [reportType]="'pdc'"></app-reports-type>
  </div>
  <div class="report-pdc-body-container">
    <p-table tableStyleClass="table-data" 
        [value]="healthPlans" 
        [paginator]="true" 
        [rows]="10"
        [scrollable]="true" 
        scrollHeight="550px"
        (onSort)="onSort($event)" 
        [styleClass]="'p-datatable-striped p-datatable-lg'">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="{{col.field}}" width="{{col.width}}" class="{{col.classHeader}}" *ngFor="let col of columns">
            <span class="p-column-title">{{col.header}}</span>
            <div *ngIf="col.sort" class="{{sortClass(col.field)}}">
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td width="{{col.width}}" class="{{col.classContent}}" *ngFor="let col of columns">
            <div *ngIf="col.header === 'Action'">
              <div>
                <button type="button" class="download fa fa-download" title="Get Latest" (click)="onGetLatestExcel(row)">
                  <span>Get Latest</span>
                </button>
              </div>
            </div>
            <div *ngIf="col.header !== 'Action'">
              <div>
                <span>{{row[col.field]}}</span>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="report-message" *ngIf="displayMessage">
    <span class="no-results-message">No results found.</span>
  </div>
</div>
