import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { CollectionService } from '../../../services/collection.service';
import { CollectionListItem } from '../../../services/models/collection-list-item';
import { CollectionType } from '../../../services/models/collection-type';

@Component({
  selector: 'add-collection-dialog',
  templateUrl: './add-collection-dialog.component.html',
  styleUrls: ['./add-collection-dialog.component.scss']
})
export class AddCollectionDialogComponent implements OnInit, OnDestroy {
  @Output() collectionAdded = new EventEmitter();
  @Output() cancelAdd = new EventEmitter();
  private validate$: Subscription;
  public collectionName: string;
  public validationError:boolean;
  public validationMessage:string;

  constructor(
    private collectionService: CollectionService
  ) {
    this.validate$ = null;
    this.collectionName = '';
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.validate$ != null)
      this.validate$.unsubscribe();
  }
  public get display(): boolean {
    return true;
  }

  public set display(value: boolean) {
    this.onCancel();
  }

  public onCreate(): void {
    let item: CollectionListItem = new CollectionListItem();
    item.name = this.collectionName;
    item.collectionType = CollectionType.MultipleTIN;
    this.validate$ = this.collectionService.validateCollection(item).subscribe(
      message => {
      if (message != null) {
        this.validationError = true;
        this.validationMessage = message;
        this.collectionService.setCurrentCollection(null);
      }
      else {
        this.validationError = false;
        this.validationMessage = null;
        this.collectionService.clearAnyPreviousCaches();
        this.collectionService.setCurrentCollection(item);
        this.collectionService.beginEditCollection();
        this.collectionAdded.emit();
      }
    }
    );
  }

  public onCancel(): void {
    this.cancelAdd.emit();
  }
}
