<div class="p-grid p-grid-responsive p-fluid">
  <div class="p-grid-row">
    <div class="p-grid-col-12" *ngIf="cities && cities.length > 0">
      <p-dropdown [options]="cities" [(ngModel)]="selectedCity" optionLabel="name" [filter]="true" filterBy="name" [showClear]="true" placeholder="Select a City" (onChange)="onChange()">
        <ng-template pTemplate="selectedItem" let-selectedOption>
            <div class="flex align-items-center gap-2">
                <div>{{ selectedOption.name }}</div>
            </div>
        </ng-template>
        <ng-template let-city pTemplate="item">
            <div class="flex align-items-center gap-2">
                <div>{{ city.name }}</div>
            </div>
        </ng-template>
      </p-dropdown>

      <div *ngIf="CityDD && CityDD.invalid && (CityDD.dirty || CityDD.touched)" class="label-sub-text alert-error">
          Required
      </div>
    </div>
  </div>
</div>
