import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdentifierGuard  {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      // Generate random identifier
      const identifier = Math.random().toString();
      const datetime = new Date().getTime().toString();
      const result = datetime + identifier;
      // Save identifier to sesssion storage
      sessionStorage.setItem('identifier', result);
      return true;
  }

}


