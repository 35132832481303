import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';
import { wellnessSurvey } from 'src/app/services/models/wellnessSurvey';

@Component({
  selector: 'report-wellness-survey',
  templateUrl: './report-wellness-survey.component.html',
  styleUrls: ['./report-wellness-survey.component.scss']
})
export class ReportWellnessSurveyComponent implements OnInit {
  @Input() wellnessSurveys: wellnessSurvey[] = [];
  @Output() onItemChange = new EventEmitter<any[]>();

  constructor() {

  }

  ngOnInit() {
   
  }

  public optionList(item: wellnessSurvey): string[] {
    return item.options;
  }

  public checkvalue($event, wellnessSurvey:wellnessSurvey){
  
    wellnessSurvey.answer = "";
    $event.checked.forEach(x=>{
      wellnessSurvey.answer += x + "|";
    });

    if(wellnessSurvey.answer.trim().toUpperCase().includes("NONE OF THE ABOVE")){
      wellnessSurvey.answer = "None of the above";
      wellnessSurvey.checkedAnswer = [];
      wellnessSurvey.checkedAnswer.push("None of the above");
    }

    if(wellnessSurvey.answer.endsWith("|"))
      wellnessSurvey.answer = wellnessSurvey.answer.slice(0, wellnessSurvey.answer.length-1);

    this.onItemChange.emit();
  }

  public onChangeType(type, event) {
    this.onItemChange.emit();
  }    
}


