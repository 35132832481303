export class PagerClasses {
  public static cssClasses = {
    sortAscending: 'fa fa-chevron-up',
    sortDescending: 'fa fa-chevron-down',
    pagerLeftArrow: 'fa fa-chevron-left',
    pagerRightArrow: 'fa fa-chevron-right',
    pagerPrevious: 'fa fa-lg fa-angle-double-left',
    pagerNext: 'fa fa-lg fa-angle-double-right'
  };
}
