import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LookupRequest } from '../../services/models/requests/lookup-request';

@Component({
  selector: 'app-lookup-number',
  templateUrl: './lookup-number.component.html',
  styleUrls: ['./lookup-number.component.scss']
})
export class LookupNumberComponent implements OnInit {
  public claim: string = '';
  public code: string = '';
  public adt: string = '';
  public showErrorMessage = false;
  public showCustomDate = false;
  public showValidClaimNumberMessage = false;
  public typeClaim: string = 'Claim';
  public typeReferral: string = 'Referral';
  public typeADT: string = 'ADT';
  @Input() lookupTitle = '';
  @Input() lookupError = '';
  @Input() isType = '';
  @Input() findButtonTitle = 'Find';
  @Input() currentUser = '';
  @Output() request = new EventEmitter<LookupRequest>();
  @Output() clearRequest = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
    this.clearClaimsSearch();
  }

  public clearClaimsSearch() {
    this.claim = '';
    this.code = '';
    this.adt = '';
    this.showErrorMessage = false;
    this.showCustomDate = false;
    this.showValidClaimNumberMessage = false;
    this.clearRequest.emit(true);
  }

  public clearMessagesReferral(o, form: NgForm) {
    o.code = o.code.replace(/([^0-9A-Za-z])/g, '');
    if (o && (!o.code || o.claim.length === 0)) {
      if (o.code.length === 0) {
        form.resetForm();
        this.clearClaimsSearch();
      }
    }

    this.showValidClaimNumberMessage = false;
  }

  public clearMessagesADT(o, form: NgForm) {
    o.adt = o.adt.replace(/([^0-9A-Za-z])/g, '');
    if (o && (!o.adt || o.adt.length === 0)) {
      if (o.adt.length === 0) {
        form.resetForm();
        this.clearClaimsSearch();
      }
    }

    this.showValidClaimNumberMessage = false;
  }

  public clearMessagesClaim(o, form: NgForm) {
    o.claim = o.claim.replace(/([^0-9A-Za-z])/g, '');
    if (o && (!o.claim || o.claim.length === 0)) {
      if (o.claim.length === 0) {
        form.resetForm();
        this.clearClaimsSearch();
      }      
    }

    this.showValidClaimNumberMessage = false;
  }


  public disableClaimsButton(): boolean {
    if (this.isType === this.typeClaim) {
      return this.showValidClaimNumberMessage || this.claim.length === 0;
    }
    else if (this.isType === this.typeReferral) {
      return this.showValidClaimNumberMessage || this.code.length === 0;
    }
    else if (this.isType === this.typeADT) {
      return this.showValidClaimNumberMessage || this.adt.length === 0;      
    }
  }

  public findClaims(form: NgForm) {
    this.showErrorMessage = false;
    const request: LookupRequest = new LookupRequest();

    if (this.isType === this.typeClaim) {

      if (this.claim && this.claim.length < 10 && this.claim.length > 0) {
        this.showValidClaimNumberMessage = true;
        return;
      }
      else {
        this.showValidClaimNumberMessage = false;
      }

      request.claimNumber = this.claim;
    }
    else if (this.isType === this.typeReferral) {

      if (this.code && this.code.length < 5 && this.code.length > 0) {
        this.showValidClaimNumberMessage = true;
        return;
      }
      else {
        this.showValidClaimNumberMessage = false;
      }

      request.referralCode = this.code;
    }
    else if (this.isType === this.typeADT) {

      if (this.adt && this.adt.length < 5 && this.adt.length > 0) {
        this.showValidClaimNumberMessage = true;
        return;
      }
      else {
        this.showValidClaimNumberMessage = false;
      }

      request.adtId = this.adt;
    }    

    request.username = this.currentUser;
    request.sortAscending = false;
    request.sortColumn = 'dateOfService';
    this.request.emit(request);
  }
}

