<div class="wellness-survey">
    <div class="wellness-survey-header">
        <h1>Social Determinants of Health Questionnaire</h1>
    </div>
    <div class="wellness-survey-table">
        <div class="wellness-survey-row" *ngFor="let wellnessSurvey of wellnessSurveys">
            <div class="wellness-survey-row-question">
                <b>{{wellnessSurvey.question}}</b>
            </div>
            <div class="wellness-survey-row-answer">
                <div *ngIf="wellnessSurvey.type === 'R'">
                    <div *ngIf="wellnessSurvey.align === 'H'">
                        <span *ngFor="let item of wellnessSurvey.options">
                            <p-radioButton 
                                name="answer{{wellnessSurvey.id}}radio" 
                                id="answer{{wellnessSurvey.id}}radio"
                                value="{{item}}" 
                                label="{{item}}"
                                (onClick)="onChangeType(type,$event)"
                                [(ngModel)]="wellnessSurvey.answer">
                            </p-radioButton>
                        </span>
                    </div>
                    <div *ngIf="wellnessSurvey.align === 'V'">
                        <div *ngFor="let item of wellnessSurvey.options">
                            <p-radioButton 
                                name="answer{{wellnessSurvey.id}}radio" 
                                id="answer{{wellnessSurvey.id}}radio"
                                value="{{item}}" 
                                label="{{item}}"
                                (onClick)="onChangeType(type,$event)"
                                [(ngModel)]="wellnessSurvey.answer">
                            </p-radioButton>
                        </div>        
                    </div>            
                </div>
                <div *ngIf="wellnessSurvey.type === 'C'">
                    <div *ngIf="wellnessSurvey.align === 'H'">
                        <span *ngFor="let item of wellnessSurvey.options">
                            <p-checkbox 
                                name="answer{{wellnessSurvey.id}}check"
                                id="answer{{wellnessSurvey.id}}check"                   
                                value="{{item}}"
                                label="{{item}}"
                                [(ngModel)]="wellnessSurvey.checkedAnswer"
                                (onChange)="checkvalue($event, wellnessSurvey)">
                            </p-checkbox>
                        </span>
                    </div>
                    <div *ngIf="wellnessSurvey.align === 'V'">
                        <div *ngFor="let item of wellnessSurvey.options">
                            <p-checkbox 
                                name="answer{{wellnessSurvey.id}}check"
                                id="answer{{wellnessSurvey.id}}check"                   
                                value="{{item}}"
                                label="{{item}}"
                                [(ngModel)]="wellnessSurvey.checkedAnswer"
                                (onChange)="checkvalue($event, wellnessSurvey)">
                            </p-checkbox>
                        </div>
                    </div>                
                </div>
            </div>        
        </div>
    </div>
</div>