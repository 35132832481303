import { DatePipe, DOCUMENT } from "@angular/common";
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { BusyService } from "@caloptima/portal-foundation";
import { saveAs } from "file-saver";
import { map, Subscription } from "rxjs";
import { MemberInfoService } from "src/app/services/member-info.service";
import { ReportMemberDetails } from "src/app/services/models/reportmemberdetails";
import { ReportType } from "src/app/services/models/reportype";
import { MemberWithHifDownloadRequest } from "src/app/services/models/requests/member-with-hif-download-request";
import { ScriptQuestionAnswer } from "src/app/services/models/script-question-answer";
import { ReportsService } from "src/app/services/reports.service";
import { SessionService } from "src/app/services/session.service";

@Component({
    selector: 'app-member-hif',
    templateUrl: './member-hif.component.html',
    styleUrls: ['./member-hif.component.scss']
  })

  export class MemberHifComponent implements OnInit, OnDestroy {
    private member$: Subscription;
    private membercin : string;

    public displayHif:ScriptQuestionAnswer[] = [];
    public displayErrorMessage = false;
    public headerErrorMessage: string;
    public contentErrorMessage: string;
    public hifFound: boolean = true;    
    public member: ReportMemberDetails;
    @Output() hifChange = new EventEmitter();

    constructor(
      private reportsService: ReportsService,
      private sessionService: SessionService,
      private memberInfoService: MemberInfoService,
      private datePipe: DatePipe,
      private busyService: BusyService,      
      @Inject(DOCUMENT) private document: Document) {
    }

    ngOnDestroy(): void {
      if (this.member$) {
        this.member$.unsubscribe();
      }
    }
    
    ngOnInit(): void {
      this.member$ = this.memberInfoService.selectedMemberChanged$.pipe(
        map((data) => {
          var collectionId = this.sessionService.currentPermission.providerCollectionID;
          this.membercin = data.cin;
          this.reportsService.searchMembersForReportsHIF(ReportType.HIF, collectionId, '', this.membercin).subscribe(result=> {
            if(result.length > 0) {
              this.member = result[0];
              this.reportsService.getMemberHifData(this.membercin).subscribe(results=>{
                this.displayHif = results;
                if(this.displayHif.length == 0)
                  this.hifFound = false;
              });
            } else {
              this.displayHif = [];
              this.member = null;
            }
            this.hifChange.emit(true);
          }, error => {
            this.displayHif = [];
            this.member = null;
            this.hifChange.emit(true);
          });
        }))
        .subscribe();
    }
    
    public onPrintToPdf() {
      const request:MemberWithHifDownloadRequest  = {
        memberCin: this.membercin,
        username: "",
        collectionId: this.sessionService.currentPermission.providerCollectionID,
        isExternalUser: true,
        providerIds: [],
        pageNumber: 0,
        pageSize: 0
      };
  
      this.reportsService.downloadMemberHifDetailPdf(request).subscribe(
        result => {
          const data: Blob = new Blob([result], { type: 'text/pdf' });
          saveAs(data, 'MemberHif-' + this.membercin + '.pdf');
          this.busyService.emit(false);
        },
        () => {
          this.busyService.emit(false);
          this.setErrorMessage('download');
        })
    }

    private setErrorMessage(errorType: string) {
      this.displayErrorMessage = true;
      if (errorType === 'download') {
        this.headerErrorMessage = "Failed to Download Member Hif";
        this.contentErrorMessage = "We're sorry! We are unable to process this request. Please try again later.";
      } else {
        this.headerErrorMessage = "Error";
        this.contentErrorMessage = "We're sorry! There was an error while processing this request. Please try again later.";
      }
    }
  }
