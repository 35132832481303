<div class="user-footer">
  <footer class="registration-footer" id="registration-footer">
    <div class = "registration-footer-text">
      If you need further assistance, please contact Provider Relations at 714-246-8600
    </div>
    <!-- <a class="link-forgot" id="lnkForgotPassword" href="" routerLink="/user/forgotPassword">Forgot Password</a> -->
    <a class="link-login" id="lnklogin" href="" routerLink="/login">Login </a>
  </footer>
</div>

