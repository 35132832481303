<div class="container">
  <div class="text-center">
    <h1>Register New User</h1>
    <p>Enter your information below to get started.</p>
    <app-alert-info
      [subjectMessage]="''"
      [contentMessage]="'Please note: Your Provider Office must complete and return the CalOptima Health Provider Portal Access Agreement before your account can be approved.'">
    </app-alert-info>
  </div>
  <!-- <div class="information-processing" *ngIf="pleaseWait">Please wait while we process your request</div> -->
  <form (ngSubmit)="register()" #registerForm="ngForm" autocomplete="off">
    <div class="row">
      <div class="col-md-6">
        <label>
          First name
        </label>
        <input type="text" class="form-control" required id="firstName" name="firstName"
          #firstName="ngModel" [(ngModel)]="profile.firstName"
          [ngClass]="{'invalid-input': profileInvalid && (profile.firstName==null || profile.firstName=='') }"
          autocomplete="none" />
      </div>
      <div class="col-md-6">
        <label>
          Last name
        </label>
        <input type="text" class="form-control" required id="lastName" name="lastName" #lastName="ngModel"
          [(ngModel)]="profile.lastName"
          [ngClass]="{'invalid-input': profileInvalid && (profile.lastName==null || profile.lastName=='') }"
          autocomplete="none" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label>
          Address
        </label>
        <input type="text" class="form-control" required id="address1" name="address1" #address1="ngModel"
          [(ngModel)]="address.address1"
          [ngClass]="{'invalid-input': profileInvalid && (address.address1==null || address.address1=='') }"
          autocomplete="none" />
      </div>
      <div class="col-md-6">
        <label>
          Suite / Apt
        </label>
        <input type="text" class="form-control" id="address2" name="address2" #address2="ngModel"
          [(ngModel)]="address.address2" autocomplete="none" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label>
          City
        </label>
        <input type="text" class="form-control" required id="city" name="city" #city="ngModel"
          [(ngModel)]="address.city"
          [ngClass]="{'invalid-input': profileInvalid && (address.city==null || address.city=='') }"
          autocomplete="none" />
      </div>
      <div class="col-md-6">
        <label>
          State
        </label>
        <select class="form-control" id="state" name="state" #state="ngModel" [(ngModel)]="address.state"
          [ngClass]="{'invalid-input': profileInvalid && (address.state == null || address.state=='')}">
          <option selected>--- Select ---</option>
          <option *ngFor="let state of stateService.getStates()" [ngValue]="state.abbreviation">{{state.name}}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label>
          Zip
        </label>
        <p-inputMask type="text" styleClass="form-control" required id="zip" name="zip" #zip="ngModel" [(ngModel)]="address.zip"
          [mask]="zipMask" placeholder="_____"
          [ngClass]="{'invalid-input': profileInvalid && (address.zip==null || address.zip=='') }" autocomplete="none" ></p-inputMask>
      </div>
      <div class="col-md-6">
        <label>
          Mobile number
        </label>
        <p-inputMask type="text" styleClass="form-control" required id="mobilePhone" name="mobilePhone" #mobilePhone="ngModel"
          [mask]="phoneMask" placeholder="___-___-____"
          [(ngModel)]="profile.mobilePhone"
          [ngClass]="{'invalid-input': profileInvalid && (profile.mobilePhone==null || profile.mobilePhone=='') } "
          autocomplete="none"></p-inputMask>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label>
          Email
        </label>
        <input type="email" class="form-control" required id="email" name="email" #email="ngModel"
          [(ngModel)]="profile.email" [pattern]="emailRegex" email
          [ngClass]="{'invalid-input': alreadyRegistered || (profileInvalid && (profile.email==null || profile.email=='')) }"
          autocomplete="none" />
        <div class="alert-error" *ngIf="!email.valid && email.touched">Please enter a valid email address.</div>
        <div class="alert-error" *ngIf="alreadyRegistered">Entered email already exists, please sign in or try using a
          different email</div>
      </div>
      <div class="col-md-6">
        <label>
          Position
        </label>
        <select class="form-control" id="position" name="position" #position="ngModel" (change)="onUserPositionChanged()"
          [ngClass]="{'invalid-input': profileInvalid && userSelectedPosition.providerPositionID === 0}"
          [(ngModel)]="userSelectedPosition.providerPositionID" *ngIf="userPositionLists.length">
          <option *ngFor="let pos of userPositionLists" [ngValue]="pos.providerPositionID"
            [selected]="pos.providerPositionID === userSelectedPosition.providerPositionID">
            {{pos.positionDisplayName}}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        &nbsp;
      </div>
      <div class="col-md-6" *ngIf="displayOtherPositionName">
        <label>
          Position Name
        </label>
        <input type="text" class="form-control" placeholder="*Position name" name="otherPositionName" maxlength="100"
          [ngClass]="{'invalid-input': profileInvalid && displayOtherPositionName && (userSelectedPosition.positionDisplayName === '' || userSelectedPosition.positionDisplayName == null)}"
          id="otherPositionName" required #positionName="ngModel" [(ngModel)]="userSelectedPosition.positionDisplayName" autocomplete="off"/>
        <div class="alert-error" *ngIf="positionName.invalid && positionName.touched">Please enter a position name.</div>
      </div>
    </div>
    <div class="row" *ngIf="profileInvalid">
      <div class="col-md-12">
        <div class="alert alert-danger text-center">
          One or more fields are missing required values. Please try again.
        </div>
      </div>
    </div>
    <please-wait-dialog [display]="pleaseWait" *ngIf="pleaseWait"></please-wait-dialog>
    <div class="row">
      <div class="col-md-6 col-xs-6"></div>
      <div class="col-md-6 col-xs-6">
        <div class="pull-right">
          <button class="next-button btn btn-primary-registration" type="submit" id="btnNext" value="NEXT">Next<span
              class="fa fa-chevron-right"></span></button>
        </div>
      </div>
    </div>
  </form>
</div>
