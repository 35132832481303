<p-dialog
  [modal]="true" [closeOnEscape]="false" [resizable]="true"
  [(visible)]="display" [style]="{width: '38rem'}" [responsive]="false"
  header="Change Security Questions" class="user-change-security-dialog"
  (onShow)="onShow()" (onHide)="onClose()">
  <div class="change-security-container">
    <div class="change-security-body">
      <div class="verify-email" *ngIf="stepNumber === 1 || stepNumber === 2">
        <verify-identity *ngIf="stepNumber == 1"
          (secondFactorComplete)="DoFactors()"
          [questions]="null"
          [componentUsing] = "componentUsing"
          [verifyIdentityModel]="verifyIdentityModel"
          [choices]="choices"
          [answers]=""
          [errorStatus]="errorStatus">
        </verify-identity>

        <verify-identity-passcode *ngIf="stepNumber === 2"
          (secondFactorPassCodeEntered)="DoFactors()"
          (validPasscode)="validPasscode($event)"
          [expirationTimeRemaining]="expirationTimeRemaining"
          [componentUsing] = "componentUsing"
          [errorStatus]="errorStatus"
          [codeExpired]="codeExpired"
          (resendCodeEmitted)="reSendCode()"
          (backToVerificationEmitted)="backToVerification()">
        </verify-identity-passcode>
      </div>
      <div class="create-security-questions" *ngIf="stepNumber === 3">
        <create-security-questions
          (valid)="onSecurityQuestionsValid($event)"
          [footerTemplate]="securityQuestions"
          [componentUsing]="componentUsing"
          [userProfile]="userProfile">
          <ng-template #securityQuestions>
          </ng-template>
        </create-security-questions>
      </div>
    </div>
    <div class="change-security-actions" *ngIf="stepNumber === 3">
      <div class="edit-user-footer">
        <button type="button" class="btn btn-primary btn-cancel" (click)="onCancel()">Cancel</button>
        <button type="button" class="btn btn-primary btn-save" (click)="onSaveQuestions()" [disabled]="disabledSaveButton">Save</button>
      </div>
    </div>
  </div>
</p-dialog>
