import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BHABAReferral2, BHABAReferralAttestationWithServices } from 'src/app/services/models/bh-aba-referral';
import { BHABAReferralAttestation } from 'src/app/services/models/bh-aba-referral-attestation';
import { ReportsService } from 'src/app/services/reports.service';
import { SessionService } from 'src/app/services/session.service';
import { InstructionItem } from './instruction-item';
import { ResourcesService } from 'src/app/services/resources.service';
import { PortalResource } from 'src/app/services/models/portal-resource';
import { forkJoin } from 'rxjs';
import { BHABAReferralAttestationPortalResources } from 'src/app/services/models/bh-aba-referral-attestation-portal-resources';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-attestation-bh-aba-referrals-dialog',
  templateUrl: './attestation-bh-aba-referrals-dialog.component.html',
  styleUrl: './attestation-bh-aba-referrals-dialog.component.scss'
})
export class AttestationBhAbaReferralsDialogComponent {

  @Input() bhabareferral: BHABAReferralAttestation;
  @Output() cancelAttestation = new EventEmitter();
  @Output() submitAttestation = new EventEmitter();
  public comment: string = "";
  public bhReferral2: BHABAReferral2[];
  public resources: BHABAReferralAttestationPortalResources;  
  public reasonList: SelectItem[] = [];
  public reasonRequired: boolean = false;
  public hasErrorDateofService: boolean = false;
  public hasErrorReason: boolean = false;
  public hasErrorProposedGoals: boolean = false;
  public hasErrorCompletedGoals: boolean = false;
  public errorMessage: string = 'no';
  public numberPattern: string ="^[0-9]{1,7}$";

  constructor(private reportService: ReportsService, private resourceService: ResourcesService, private sessionService: SessionService){
    
  }

  public ngOnInit(){

    this.reasonList.push(
      { label: 'Family Request for Reduced Hours or Service Pause', value: 'Family Request for Reduced Hours or Service Pause' },
      { label: 'Agency Staffing Issues', value: 'Agency Staffing Issues' },
      { label: 'Staff Cancellations', value: 'Staff Cancellations' },
      { label: 'Service Termination During Authorization Period', value: 'Service Termination During Authorization Period' },
      { label: 'Mid-Authorization Increase Request (Unable to Accommodate)', value: 'Mid-Authorization Increase Request (Unable to Accommodate)' },
      { label: 'Client Absences or Frequent Cancellations', value: 'Client Absences or Frequent Cancellations' },
      { label: 'Unforeseen Circumstances', value: 'Unforeseen Circumstances' },
      { label: 'Select One', value: null }
    )  

    if(this.bhabareferral.assessmentGoals && this.bhabareferral.assessmentGoalsCompleted)
      this.bhabareferral.assessmentGoalsRate = isNaN(this.bhabareferral.assessmentGoals) || isNaN(this.bhabareferral.assessmentGoalsCompleted) ? 0 : Math.round((this.bhabareferral.assessmentGoalsCompleted / this.bhabareferral.assessmentGoals ) * 100);
    else
      this.bhabareferral.assessmentGoalsRate = 0;

      forkJoin([
        this.resourceService.getBHABAReferralAttestationResources(),   
        this.reportService.getBHABAReferrals2(this.bhabareferral.referralId, this.bhabareferral.memberCIN, this.bhabareferral.calProviderId)
      ]).subscribe(
        (result)=>{
          if(result && result.length > 1) {
            this.resources = result[0];
            this.bhReferral2 = result[1];
            
            this.resources.instruction2.forEach(x=>{
              if(x.resourceDesc.trim().startsWith("-")) {
                x.resourceDesc = "\u00A0\u00A0\u00A0\u00A0" + x.resourceDesc;
              }
              if(x.resourceDesc.trim().startsWith("--")) {
                x.resourceDesc = "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0" + x.resourceDesc;
              }              
            })

            this.bhReferral2.forEach(x=>{
              x.directServicePercentage = Math.round((x.directServiceCompleted / x.assigneD_UNITS ) * 100);
            });           
            
            this.updateReasonRequired();
          }
      });
  }

  public get display():boolean {
    return true;
  }

  public set display(value:boolean) {
    this.onCancel();
  }
  
  public get caption(): string {
      return "BH ABA P4V Attestation";
  }

  public onCancel() {
    this.cancelAttestation.emit();
  }

  public get tooltip1(): string {
    return 'Indicate rationale if authorized hours for either direct service (i.e., H2019 or H2014) fell below 80% utilization';
  }

  public performValidation(): void{

    this.hasErrorDateofService = false;
    this.hasErrorReason = false;
    this.hasErrorProposedGoals = false;
    this.hasErrorCompletedGoals = false;

    this.errorMessage = '';

    this.bhReferral2.forEach(x=>{
      if(x.directServiceCompleted == null || x.directServiceCompleted.toString().trim() == '') {
        this.hasErrorDateofService = true;
        this.errorMessage = 'Date of serives completed required.';        
      }
    });

    if(this.reasonRequired) {
      if(this.bhabareferral.reason && this.bhabareferral.reason.trim() == 'Unforeseen Circumstances' && (!this.bhabareferral.comment || this.bhabareferral.comment.trim() == '')){
        this.hasErrorReason = true;
        this.errorMessage = 'Unforseen Circumstances reason required explaination.';
      }
    }

    if(this.bhabareferral.assessmentGoals == null || this.bhabareferral.assessmentGoals.toString() == ''){
      this.hasErrorProposedGoals = true;
      this.errorMessage = '# of Proposed Goals required.';
    }

    if(this.bhabareferral.assessmentGoalsCompleted == null || this.bhabareferral.assessmentGoalsCompleted.toString() == '') {
      this.hasErrorCompletedGoals = true;
      this.errorMessage = '# of Advanced Goals.';
    }
  }

  public onAttest() {
    
    this.performValidation();
    if(this.hasErrorDateofService || this.hasErrorReason || this.hasErrorProposedGoals || this.hasErrorCompletedGoals) {
      return;
    }


    let data:BHABAReferralAttestationWithServices = new BHABAReferralAttestationWithServices();
    data.calProviderId = this.bhabareferral.calProviderId;
    data.memberCIN = this.bhabareferral.memberCIN;
    data.referralId = this.bhabareferral.referralId;
    data.assessmentGoals = this.bhabareferral.assessmentGoals;
    data.assessmentGoalsCompleted = this.bhabareferral.assessmentGoalsCompleted;
    data.assessmentGoalsRate = this.bhabareferral.assessmentGoalsRate;
    data.reason = this.bhabareferral.reason;
    data.comment = this.bhabareferral.comment;
    data.attestationBy = this.sessionService.currentPermission.secureAuthUserName;
    data.services = this.bhReferral2;

    this.reportService.ApplyBHABAReferralAttestation(data).subscribe(x=>{
      this.submitAttestation.emit();
    });    
  }

  public onDirectServiceCompleted(item: BHABAReferral2) {
    item.directServiceCompleted = Math.round(item.directServiceCompleted)
    item.directServicePercentage = Math.round((item.directServiceCompleted/item.assigneD_UNITS)*100);
    this.updateReasonRequired();
  }

  public updateAssessmentRate() {
    if(this.bhabareferral.assessmentGoalsCompleted && this.bhabareferral.assessmentGoals) {
      this.bhabareferral.assessmentGoalsCompleted = Math.round(this.bhabareferral.assessmentGoalsCompleted);
      this.bhabareferral.assessmentGoals = Math.round(this.bhabareferral.assessmentGoals);
      this.bhabareferral.assessmentGoalsRate = Math.round((this.bhabareferral.assessmentGoalsCompleted/this.bhabareferral.assessmentGoals)*100);
    }
    else {
      this.bhabareferral.assessmentGoalsRate = 0;
    }
  }

  public updateReasonRequired()  {
    this.reasonRequired = false;

    if(this.bhReferral2) {
      var item = this.bhReferral2.find(x=> x.directServicePercentage < 80)
      if(item)
        this.reasonRequired = true;
    }
  }
}
