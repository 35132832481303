<p-dialog [style]="{width: '300px', height:'300px'}" header="{{caption}}" [(visible)]="display" [modal]="true" (onHide)="cancelClick()" [draggable]="false">
    <div>
        <h5>{{label}}</h5>        
    </div>
    <div>
        <p-calendar 
            appendTo="body" 
            [style]="{'width':'100%'}" 
            [inputStyle]="{'width':'100%'}" 
            [(ngModel)]="date" 
            [showIcon]="true" 
            [showOnFocus]="false" 
            inputId="buttondisplay"
            [maxDate]="maxDate"
            (onSelect)="onSelect()"
        />
    </div> 
    <p-footer>
        <div>
          <button type="button" class="btn btn-primary btn-create" (click)="continueClick()">Continue</button>
          <button type="button" class="btn btn-secondary btn-cancel" (click)="cancelClick()">Cancel</button>
        </div>
        <div *ngIf="showError">
            <app-alert-danger uniqueID='fail' [subjectMessage]="'Error.'" [contentMessage]="errormessage"></app-alert-danger>
        </div> 
      </p-footer>   
</p-dialog>
