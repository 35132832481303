<wait-cursor [show]="isBusy || isHttpBusy"></wait-cursor>
<div class="app-content" [ngClass]="{'hide-sidebar': !showSidebar || !isLoggedIn}">
  <div *ngIf="isLoggedIn" class="header">
    <app-header></app-header>
  </div>
  <div *ngIf="showSidebar && isLoggedIn" class="sidebar">
    <app-navigator></app-navigator>
  </div>
  <div class="app-body">
    <router-outlet></router-outlet>
  </div>
</div>
<app-small-screen-viewer></app-small-screen-viewer>
<app-browser-refresh-dialog
  *ngIf="displayhBrowserRefresDialog"
  (onUpdated)="onRefreshUpdated($event)">
</app-browser-refresh-dialog>
