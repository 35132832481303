<div class="container">
    <div class="text-center" [ngClass]="{'view-only-header': isTermsOfUse}">
        <h1 >Terms & Conditions</h1>
        <p *ngIf="!isTermsOfUse">Please read the agreement below before clicking the "Accept" button.</p>
    </div>
    <p-dialog header="Decline" [(visible)]="declined" [modal]="true" class="declined-dialog">
        {{declineMessage}}
        <p-footer>
            <button type="button" id="btnCloseDeclinePopup" class="btn btn-secondary" pbutton icon="pi pi-close" (click)="declined=false" label="OK">OK</button>
        </p-footer>
    </p-dialog>
    <form name="eula" #eulaForm="ngForm" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-md-10 eula auto-margins" tabindex="1" id="txtEndUseLicense" [innerHtml]="eula"
            (scroll)="onScroll($event)" [ngClass]="{'view-only-eula-height' : isTermsOfUse, 'eula': !isTermsOfUse}">

            </div>
        </div>
        <div class="row eula-footer" *ngIf="!isTermsOfUse">
            <div class="col-md-6 col-6">
                <div class="decline pull-right"  [ngClass]="{'decline-disable': disableDecline, 'decline': !disableDecline}">
                    <button type="button" class="btn" tabindex="3" id="btnDecline" (click)="onDeclineTerms()" [disabled]="disableDecline">Decline</button>
                </div>
            </div>
            <div class="col-md-6 col-6">
                <div class="" >
                <button type="button" tabindex="2" id="btnAccept" class="btn btn-secondary" (click)="onAcceptTerms()" [disabled]="disableAccept">Accept</button>
                </div>
            </div>
        </div>
        <ng-template *ngTemplateOutlet="footerTemplate"></ng-template>
    </form>
</div>
