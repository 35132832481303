import { Component, OnInit } from '@angular/core';
import { CollectionService } from '../../services/collection.service';
import { ProviderDetail } from '../../services/models/provider-detail';
import { ReportsService } from '../../services/reports.service';
import { SessionService } from '../../services/session.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-reports-pcp-member-iha',
  templateUrl: './reports-pcp-member-iha.component.html',
  styleUrls: ['./reports-pcp-member-iha.component.scss']
})
export class ReportsPcpMemberIHAComponent implements OnInit {

  public providers:ProviderDetail[] = [];
  public selected: ProviderDetail[] = [];
  private sortColumn: string;
  private sortAscending: boolean;
  private enableBuildReport: boolean;
  private threshold: number = 25000;
  public showThresholdAlert: boolean = false;

  constructor(
    private sessionService: SessionService, 
    private collectionService: CollectionService,
    private reportsService: ReportsService

    ) {

      this.sortColumn = 'name';
      this.sortAscending = true;    
      this.enableBuildReport = true;
  }

  ngOnInit() {
    
    this.reportsService.getProvidersForIHA(this.sessionService.currentPermission.providerCollectionID).subscribe(result=>{
      this.providers = result;

      let param: any = {
        "field": "name",
        "order": 1
      }
      this.onSort(param)

      this.selected = result;
    })
  }

  public onBuildReport() {

    this.enableBuildReport = false;

    let providerIds: string[] = [];
    this.selected.forEach(x=> {
      providerIds.push(x.calProviderID);
    });

    this.reportsService.downloadIHADueMembersUsingProviderIds(providerIds).subscribe(result => {
      this.saveAsFile(result, 'iha-duemembers.csv', 'text/csv');
      this.enableBuildReport = true;
    })
  }

  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob([buffer], { type: fileType });
    saveAs(data, fileName);
  }

  public sortClass(column: string): string {
    let cls: string;
    if (column == this.sortColumn) {
      cls = this.sortAscending ? "fa fa-chevron-up" : "fa fa-chevron-down";
    }
    else {
      cls = "fa fa-chevron-up";
    }
    return cls;
  }

  public onSort(e: any): void {
    this.providers.sort((data1, data2) => {
      let value1 = data1[e.field];
      let value2 = data2[e.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (e.order * result);
    });
    this.sortAscending = e.order == 1;
    this.sortColumn = e.field;
  }

  public get IsEnterpriseUser() : boolean {
    return (this.sessionService.isEnterpriseAdmin || this.sessionService.isEnterpriseUser);
  }

  public onSearch(search) : void {

    if(this.sessionService.currentPermission.isEnterpriseAdmin || this.sessionService.currentPermission.isEnterpriseUser) {
      this.reportsService.searchProvidersForIHA(search.providerId, search.providerName, search.providerTin).subscribe(result => {
        this.providers = result;
       });
    }          
  }

  public get totalCount() : number{
    this.showThresholdAlert = false;
    
    let totalCount: number = 0;
    this.selected.forEach(x=>{
      totalCount += x.pcpMemberCount
    });

    if(totalCount > this.threshold)
      this.showThresholdAlert = true;

    return totalCount;
  }
}
