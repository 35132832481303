import { Component, OnInit, Output, EventEmitter, Provider } from '@angular/core';
import { ProviderDetail } from 'src/app/services/models/provider-detail';
import { ReportType } from 'src/app/services/models/reportype';
import { ReportsService } from 'src/app/services/reports.service';
import { SessionService } from 'src/app/services/session.service';
import { UiUtility } from 'src/app/utils/ui-utility';


@Component({
  selector: 'app-reports-roster-provider',
  templateUrl: './reports-roster-provider.component.html',
  styleUrl: './reports-roster-provider.component.scss'
})
export class ReportsRosterProviderComponent implements OnInit {

  @Output() selectedprovider = new EventEmitter<ProviderDetail>();

  public providers: ProviderDetail[] = [];
  public displayedProviders: ProviderDetail[] = [];
  public rowsPerPage: number = 10;
  public pageNumber: number = 1;
  public animationState: string = '';

  private sortColumn: string;
  private sortAscending: boolean;
  
  public columns: any = [
    { field: '', header: '', width: '5%', classHeader: 'provider-reports-header', classContent: 'provider-reports-content-alt1', sort: false },
    { field: 'name', header: 'Name', width: '40%', classHeader: 'provider-reports-header', classContent: 'provider-reports-content', sort: true },
    { field: 'taxId', header: 'TIN', width: '25%', classHeader: 'provider-reports-header-alt1', classContent: 'provider-reports-content-alt1', sort: true },
    { field: 'calProviderID', header: 'Provider ID', width: '25%', classHeader: 'provider-reports-header-alt1', classContent: 'provider-reports-content-alt1', sort: true }
  ];

  constructor(private sessionService: SessionService, private reportsService: ReportsService) {

  }

  public ngOnInit(): void  {
    
    this.sortColumn = 'name';

    if(this.sessionService.currentPermission.isEnterpriseAdmin || this.sessionService.currentPermission.isEnterpriseUser) {
      //  Do nothing.
    }
    else {
      this.reportsService.getProvidersForPCPMemberRoster(this.sessionService.currentPermission.providerCollectionID).subscribe(result => {
        this.providers = result;

        this.pageNumber = 1;
        this.onProviderSearch(null);

        let param: any = {
          "field": "name",
          "order": 1
        }
        this.onSort(param)
      });
    }
  }

  public sortClass(column: string): string {
    let cls: string;
    if (column == this.sortColumn) {
      cls = this.sortAscending ? "fa fa-chevron-up" : "fa fa-chevron-down";
    }
    else {
      cls = "fa fa-chevron-up";
    }
    return cls;
  }

  public onPage(e: any) {
    let s = JSON.stringify(e);
  }

  public onSort(e: any): void {
    this.providers.sort((data1, data2) => {
      let value1 = data1[e.field];
      let value2 = data2[e.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (e.order * result);
    });
    this.sortAscending = e.order == 1;
    this.sortColumn = e.field;
  }

  public onProviderSelected(provider: ProviderDetail) {
    this.selectedprovider.emit(provider);
  }

  public onProviderSearch(search) {
    if (search && (search.providerName || search.providerId || search.providerTin)) {

      if(this.sessionService.currentPermission.isEnterpriseAdmin || this.sessionService.currentPermission.isEnterpriseUser) {
        this.reportsService.searchProvidersForPCPMemberRoster(search.providerId, search.providerName, search.providerTin).subscribe(result => {
          this.providers = result;
          this.displayedProviders = this.providers.slice();
        });
      }
      else {
        if (!UiUtility.isNullUndefinedOrEmpty(search.providerName)) {
          this.displayedProviders = this.providers
            .filter(p => p.name.toLowerCase().indexOf(search.providerName.trim().toLowerCase()) >= 0);
        }
        else if (!UiUtility.isNullUndefinedOrEmpty(search.providerId)) {
          this.displayedProviders = this.providers
            .filter(p => p.calProviderID.toLowerCase().indexOf(search.providerId.trim().toLowerCase()) >= 0);
        }
        else if (!UiUtility.isNullUndefinedOrEmpty(search.providerTin)) {
          this.displayedProviders = this.providers
            .filter(p => p.taxId.toLowerCase().indexOf(search.providerTin.trim().toLowerCase()) >= 0);
        }        
      }
    }
    else {
      this.displayedProviders = this.providers.slice();
    }
  }
}
