import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-save-changes-alert-dialog',
  templateUrl: './save-changes-alert-dialog.component.html',
  styleUrls: ['./save-changes-alert-dialog.component.scss']
})
export class SaveChangesAlertDialogComponent implements OnInit {

  @Input() display:boolean = false;
  @Output() onUpdated = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  public onYes(): void {
    this.display = false;
    this.onUpdated.emit('save');
  }

  public onLeave(): void {
    this.display = false;
    this.onUpdated.emit('leave');
  }

  public onCancel(): void {
    this.display = false;
    this.onUpdated.emit('cancel');
  }
}
