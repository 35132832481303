import { QueryablePagedRequest } from './queryable-paged-request';

export class ClaimRequest extends QueryablePagedRequest {
  claimNumber: string;
  startDate: Date;
  endDate: Date;
  dateType: string;
  status: string[]; //maybe number or enum
  providerId: string;
  dateFilter: string;
  username: string;
  cin: string;
  providerCollectionId: number;
}
