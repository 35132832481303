<app-user-header></app-user-header>
<div class="login-container">
  <wait-cursor [show]="isBusy"></wait-cursor>
  <form name="credentials" class="container login-form" (ngSubmit)="DoLogin()" #userForm="ngForm" *ngIf="!hasFactors()"
    autocomplete="off">
    <div data-label="h2" class="controlspacer">
        <h1>Welcome back!</h1>
    </div>
    <login-errrors [errorStatus]="errorStatus" *ngIf="errorStatus!==null"></login-errrors>
    <div class="controlspacer">
        <label>
          Email address
        </label>
        <input type="email" value="" class="form-control" [ngClass]="{'invalid-input': userNameMissing}"
          placeholder="Email" id="userName" name="userName" [(ngModel)]="userName" #email="ngModel" required
          tabindex="1" (blur)="emailHandler($event)" autocomplete="off" />
        <div class="alert-error" *ngIf="userNameMissing">
          Email required
        </div>
    </div>
    <div class="controlspacer">
        <div>
          <label>
            Password
          </label>
         <div class="pull-right"><a  id="lnkForgotPassword" routerLink="/user/forgotPassword">Forgot?</a></div>
        </div>
        <input type="password" class="form-control" placeholder="Password" name="Password" id="txtPassword"
          [ngClass]="{'invalid-input': passwordMissing}" [(ngModel)]="password" #pass="ngModel" required tabindex="2"
          autocomplete="off" />
        <div class="alert-error" *ngIf="passwordMissing">
          Password required
        </div>
    </div>
    <div class="remember-this-device controlspacer">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" [(ngModel)]="rememberThisDevice"  name="rememberThisDevice" id="rememberThisDevice">
          <label class="custom-control-label" for="rememberThisDevice">
            <span class="custom-control-description">Remember this device</span>
          </label>
          <div class="remember-this-device-icon">
            <i class="fa fa-question-circle" pTooltip="{{messages.RememberThisDevice}}" tooltipPosition="right"  tooltipStyleClass="remember-this-device-tooltip"></i>
          </div>
        </div>
    </div>
    <div class="align-center login-row controlspacer">
        <button class="btn btn-primary-registration btn-lg login-button" id="btnLogin" tabindex="4">LOG IN</button>
    </div>
    <div class="align-center div-login-register controlspacer">
        <button class="btn btn-secondary-registration btn-lg login-button" id="btnRegister" routerLink="/user/registration">REGISTER AS NEW USER</button>
    </div>
    <div class="align-center" style="margin-bottom: 10px;">
        <a routerLink="/user/checkstatus" id="lnkRegister">Already registered? Check status</a>
    </div>
    <div>
      <app-disclaimer-links [isSmallFont]=true></app-disclaimer-links>
    </div>
  </form>

  <verify-identity (secondFactorComplete)="DoFactors()" *ngIf="hasFactors() && !canShowPasscode()" [questions]="questions"
  [choices]="choices" [answers]="answers" [errorStatus]="errorStatus" [footerTemplate]="" [verifyIdentityModel]="verifyIdentityModel"></verify-identity>

  <verify-identity-passcode (secondFactorPassCodeEntered)="DoFactors()" *ngIf="hasFactors() && canShowPasscode()"
  [expirationTimeRemaining]="expirationTimeRemaining" [errorStatus]="errorStatus"  [codeExpired]="codeExpired"
   (resendCodeEmitted)="reSendCode()" (backToVerificationEmitted)="backToVerification()" >
  </verify-identity-passcode>
</div>
