import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router'
import { AppConfig } from '@caloptima/portal-foundation';
import { UserAdminService } from '../../services/user-admin.service';
import { Constants } from '../../app.constants';
import { SessionService } from '../../services/session.service';
import { UiUtility } from '../../utils/ui-utility';

@Component({
  selector: 'app-logged-out',
  templateUrl: './logged-out.component.html',
  styleUrls: ['./logged-out.component.scss']
})
export class LoggedOutComponent implements OnInit, AfterViewInit {
  public isEnterpriseUser: boolean;
  public loginUrl: string;

  constructor(
    private adminService: UserAdminService,
    private sessionService: SessionService,
    private router: Router,
    private config: AppConfig,
    private constants: Constants
  ) {
    this.loginUrl = '/';
  }

  private getPortalConnect(): boolean {
    let found: boolean = false;
    let portalConnect: string = this.config.getConfig('PortalConnectUrl');
    if (portalConnect != null && portalConnect.length > 0) {
      this.loginUrl = portalConnect;
      found = true;
    }
    return found;
  }

  ngOnInit() {
    this.isEnterpriseUser = this.sessionService.isEnterpriseUser;
    if (this.isEnterpriseUser) {
      if (!this.getPortalConnect()) {
        this.config.subscribe(cfg => {
          this.getPortalConnect();
        });
      }
    }

    this.sessionService.end();
  }

  ngAfterViewInit(): void {
    this.checkUnSupportedBrowser();
  }

  onLoginAgain(): void {
    this.router.navigate([this.loginUrl]);
  }

  private checkUnSupportedBrowser() {
    const browserName = UiUtility.getSupportedBrowserName();
    if (browserName === 'other') {
      window.location.href = '/';
    }
  }
}
