import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { AppConfig, HttpResponseHelper, SessionTimerSitecoreService } from '@caloptima/portal-foundation';
import { map, catchError, distinctUntilChanged } from 'rxjs/operators';
import { UserPortalEventRequest } from './models/requests/user-portal-event-request';
import { SessionService } from '../services/session.service';
import { TrainingContent } from './models/training-content';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

export enum HipaaTrainingType {
  LoaTraining = 0,
  StaffTraining = 1
}

@Injectable({
  providedIn: 'root'
})
export class TrainingsService {
  private baseProviderMemberServiceUrl: string;
  private updateHipaaTrainingEventUrl: string;
  private getHipaaTrainingSiteCoreContentsUrl: string;
  private currentUser: string;
  private hipaaTrainingResponse: TrainingContent[] = [];
  private hipaaTrainingSubject$ = new BehaviorSubject<TrainingContent[]>(this.hipaaTrainingResponse);
  public hipaaTrainingChanged$ = this.hipaaTrainingSubject$.asObservable()
    .pipe(
      distinctUntilChanged()
    );

  private hipaaTrainingContents: TrainingContent[] = [
    { id: 1, sequence: 1, createdDate: new Date("08-01-2020"), key: 'hipaa-overview', name: 'Overview', content: ''},
    { id: 2, sequence: 2, createdDate: new Date("08-01-2020"), key: 'hipaa-definitions', name: 'Definitions', content: ''},
    { id: 3, sequence: 3, createdDate: new Date("08-01-2020"), key: 'hipaa-definitions-continued', name: 'Definitions', content: ''},
    { id: 4, sequence: 4, createdDate: new Date("08-01-2020"), key: 'hipaa-use-of-the-provider-portal', name: 'Use of the Provider Portal', content: ''},
    { id: 5, sequence: 5, createdDate: new Date("08-01-2020"), key: 'hipaa-misuse-of-the-provider-portal', name: 'Misuse of the Provider Portal', content: ''},
    { id: 6, sequence: 6, createdDate: new Date("08-01-2020"), key: 'hipaa-local-office-administrator-training', name: 'Local Office Administrator Training', content: ''},
    { id: 7, sequence: 7, createdDate: new Date("08-01-2020"), key: 'hipaa-granting-access', name: 'Granting Access', content: ''},
    { id: 8, sequence: 8, createdDate: new Date("08-01-2020"), key: 'hipaa-managing-access', name: 'Managing Access', content: ''},
    { id: 9, sequence: 9, createdDate: new Date("08-01-2020"), key: 'hipaa-terminating-access', name: 'Terminating Access', content: '' },
    { id: 10, sequence: 10, createdDate: new Date("08-01-2020"), key: 'hipaa-incident-response', name: 'Incident Response', content: '' },
    { id: 11, sequence: 11, createdDate: new Date("08-01-2020"), key: 'hipaa-additional-information', name: 'Additional Information and Resources', content: ''},
    { id: 12, sequence: 12, createdDate: new Date("08-01-2020"), key: 'hipaa-our-mission', name: 'Our Mission', content: ''}
  ];

  constructor(
    private sessionService: SessionService,
    private authService: OAuthService,
    private http: HttpClient,
    private appConfig: AppConfig) {
    this.baseProviderMemberServiceUrl = appConfig.getConfig('BaseProviderServicesApiUrl');
    if (this.baseProviderMemberServiceUrl == null) {
      const config$ = appConfig.subscribe(() => {
        this.baseProviderMemberServiceUrl = appConfig.getConfig('BaseProviderServicesApiUrl');
        this.checkUrls();
        config$.unsubscribe();
      });
    }

    const claims = this.authService.getIdentityClaims();
    if (claims) {
      this.currentUser = claims['sub'];
    }
  }

  private checkUrls(): void {
    if (this.updateHipaaTrainingEventUrl == null) {
      const baseUrl = this.baseProviderMemberServiceUrl + 'api/admin/';
      this.updateHipaaTrainingEventUrl = baseUrl + 'updateHipaaTrainingEvent';
    }
    if (this.getHipaaTrainingSiteCoreContentsUrl == null) {
      const baseUrl = this.baseProviderMemberServiceUrl + 'api/admin/';
      this.getHipaaTrainingSiteCoreContentsUrl = baseUrl + 'getHipaaTrainingContents';
    }
  }

  public setHipaaTrainings(hipaaTrainingResponse: TrainingContent[]) {
    this.hipaaTrainingResponse = hipaaTrainingResponse;
    this.hipaaTrainingSubject$.next(hipaaTrainingResponse);
  }

  public clearHipaaTrainings(): void {
    this.hipaaTrainingResponse = null;
    this.hipaaTrainingSubject$.next(this.hipaaTrainingResponse);
  }

  public getHippaTrainingDetailContent(key: string) {
    const contentObj = this.hipaaTrainingContents.find(t => t.key === key);
    if (contentObj) {
      return contentObj.content;
    }
    else {
      return '';
    }
  }

  public completeHipaaTrainingEvent(): Observable<boolean> {
    try {
      this.checkUrls();
      const userPortalEventRequest = new UserPortalEventRequest();
      userPortalEventRequest.completionDate = new Date();
      userPortalEventRequest.username = this.currentUser;

      return this.http.post<boolean>(this.updateHipaaTrainingEventUrl, userPortalEventRequest, httpOptions)
        .pipe(
          map(data => {
            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public getHipaaTrainingSiteCoreContents(trainingType: HipaaTrainingType): Observable<TrainingContent[]> {
    try {
      this.checkUrls();

      return this.http.post<TrainingContent[]>(this.getHipaaTrainingSiteCoreContentsUrl, {}, httpOptions)
        .pipe(
          map(data => {
            const trainingSiteCoreContents = data;
            let filteredTrainingContents: TrainingContent[] = [];

            if (trainingType === HipaaTrainingType.StaffTraining) {
              // staff only take few training pages
              const hipaaTrainingForStaffLists = [
                'hipaa-overview','hipaa-definitions','hipaa-definitions-continued',
                'hipaa-use-of-the-provider-portal','hipaa-misuse-of-the-provider-portal',
                'hipaa-additional-information','hipaa-our-mission','hipaa-incident-response'
              ];

              // staff takes less trainings
              const filteredTrainings = this.hipaaTrainingContents.filter(item => {
                return hipaaTrainingForStaffLists.indexOf(item.key) > -1;
              });

              filteredTrainingContents = filteredTrainings;
            }
            else {
              // loa take all training pages
              filteredTrainingContents =  this.hipaaTrainingContents;
            }

            // map sitecore contents
            filteredTrainingContents.forEach(t => {
              //support older version of sitecore (data from provider service sitecore) for v2 and v3+
              const contentObj = trainingSiteCoreContents[t.key]; 
              if (contentObj) {
                t.content = contentObj.Content;
                //t.createdDate = new Date(contentObj.EffectiveDate);
              }
            })

            this.setHipaaTrainings(filteredTrainingContents);
            return filteredTrainingContents;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }
}
