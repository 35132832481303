import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-choose-date-dialog',
  templateUrl: './choose-date-dialog.component.html',
  styleUrl: './choose-date-dialog.component.scss'
})
export class ChooseDateDialogComponent implements OnInit {
  @Output() onCancel = new EventEmitter();
  @Output() onContinue = new EventEmitter<Date>();
  @Input() caption:string;
  @Input() label: string;
  

  public display: boolean = true;
  public date: Date;
  public maxDate: Date = new Date();
  public showError: boolean = false;
  public errormessage: string = "";
  
  ngOnInit(): void {

    if(!this.caption)
      this.caption = "Date";

    if(!this.label)
      this.label = "Choose Date";
  }

  public continueClick() {
    if(this.date) {
      this.display = false;
      this.onContinue.emit(this.date);
    }
    else {
      this.errormessage = "Date not selected."
      this.showError = true;

    }
  }

  public onSelect() {
    this.errormessage = "";
    this.showError = false;
  }

  public cancelClick() {
    this.display = false;
    this.onCancel.emit(false);
  }
}
