import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AuthHelper } from '@caloptima/authentication';
import { SessionService } from '../../services/session.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SmallScreenPageConfig } from '../../services/models/smallscreen-page-config';
import { AppConfig } from '@caloptima/portal-foundation';
import { RouterHistoryService } from '../../services/router-history.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-small-screen-viewer',
  templateUrl: './small-screen-viewer.component.html',
  styleUrls: ['./small-screen-viewer.component.scss']
})
export class SmallScreenViewerComponent implements OnInit {
  private responsiveSub$: Subscription;
  private configLoadSub$: Subscription;
  private routeChangeSub$: Subscription;
  private activatedRouteChangeSub$: Subscription;
  public loggedIn = false;
  public loggedOut = false;
  public displaySmallerScreen = false;
  private pageConfig: SmallScreenPageConfig;
  private currentPage: string;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private authHelper: AuthHelper,
    private oauth: OAuthService,
    private sessionService: SessionService,
    private routerHistoryService: RouterHistoryService,
    private activatedRoute: ActivatedRoute,
    private appConfig: AppConfig
  ) {}

  ngOnInit() {
    this.configLoadSub$  = this.appConfig.subscribe(() => {
      this.pageConfig = this.appConfig.getConfig(
        'SmallScreenPage'
      ) as SmallScreenPageConfig;

      // when route changed
      this.routeChangeSub$ = this.router.events.pipe(
        filter(event=> event instanceof NavigationEnd)
      )
      .subscribe(e => {
        this.checkToDislaySmallerScreen();
        if (this.oauth.getIdentityClaims() === null && window.location.hash.indexOf('logout') === -1) {
          this.routerHistoryService.checkUnSupportedBrowser();
        }

        if (this.currentPage !== 'logout') {
          this.loggedOut = false;
        }
      });

      // when initial load
      this.activatedRouteChangeSub$ = this.activatedRoute.params.subscribe((val: any) => {
        this.checkToDislaySmallerScreen();
      });
    });
  }

  ngOnDestroy(): void {
    if (this.responsiveSub$) {
      this.responsiveSub$.unsubscribe();
    }
    if (this.configLoadSub$) {
      this.configLoadSub$.unsubscribe();
    }
    if (this.routeChangeSub$) {
      this.routeChangeSub$.unsubscribe();
    }
    if (this.activatedRouteChangeSub$) {
      this.activatedRouteChangeSub$.unsubscribe();
    }
  }

  detectWindowSize() {
    this.responsiveSub$ = this.breakpointObserver
    .observe(['(max-width: 767px)'])
    .subscribe((state: BreakpointState) => {
      this.displaySmallerScreen = state.breakpoints['(max-width: 767px)'];
      this.setLoggedIn();
      this.setDisplayAppContentBody(false);
    });
  }

  onLogout() {
    this.sessionService.end();
    this.setLoggedIn();
    this.loggedOut = true;
  }

  private setLoggedIn() {
    this.loggedIn = this.authHelper.tokenNotExpired();
  }

  private checkToDislaySmallerScreen() {
    this.currentPage = this.activatedRoute.snapshot.firstChild ? this.activatedRoute.snapshot.firstChild.routeConfig.path : '';
    const excludePage = this.currentPage && this.currentPage !== '' ? this.pageConfig.excludePages.indexOf(this.currentPage) >= 0 : false;
    if (this.pageConfig.enableSmallScreenPage && !excludePage) {
      this.detectWindowSize();
    } else {
      this.displaySmallerScreen = false;
      this.setDisplayAppContentBody();
    }
  }

  private setDisplayAppContentBody(setToDisplay = true) {
    const appBody = window.document.getElementsByClassName('app-content');
    if (setToDisplay) {
      appBody[0].setAttribute('style', 'display:grid');
    } else {
      appBody[0].setAttribute('style', this.displaySmallerScreen ? 'display:none' : 'display:grid');
    }
  }
}
