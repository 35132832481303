import { ReportType } from "../reportype";

export class WidgetRequest {
  username: string;
  isLOA: boolean;
  CollectionId: number;
  CollectionName: string;
  CurrentUserPermissionCodes: string[]
  ReportTypes: ReportType[]
}
