<p-dialog [(visible)]="display" [modal]="true" [responsive]="true" [style]="{width: '350px', minWidth: '200px'}"
  [closable]="false" [minY]="70" [maximizable]="false" (onHide)="onHide($event)" [baseZIndex]="10000"
  [transitionOptions]="'250ms'">
  <ng-template pTemplate="header">
    <div>
      Are you still there?
    </div>
  </ng-template>
  <p>
    Due to inactivity, you will be logged out in {{countdown}} seconds
  </p>
  <ng-template pTemplate="footer">
    <button type="button" class="btn btn-outline-search btn-cancel" (click)="onLogOutUser()" label="Log Out">
      Log Out
    </button>
    <button type="button" class="btn btn-primary btn-continue" (click)="onContinueSession()" label="Continue">
      Continue
    </button>
  </ng-template>
</p-dialog>