import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';
import { MemberInfoService } from 'src/app/services/member-info.service';
import { Condition } from 'src/app/services/models/condition';

@Component({
  selector: 'app-member-conditions',
  templateUrl: './member-conditions.component.html',
  styleUrls: ['./member-conditions.component.scss']
})
export class MemberConditionsComponent implements OnInit, OnDestroy, OnChanges {

  constructor(private memberInfoService: MemberInfoService) { }

  public member$: Subscription;
  public conditions: Condition[];
  public totalConditions = 0;
  public sortOptions: SelectItem[];
  public sortField: string;
  public sortOrder: number;
  public sortKey: SelectItem;
  public isUserAssocToMemberPcp: boolean;

  @Output() conditionsChange = new EventEmitter();
  @Input() isLoading: boolean;
  @Input() tabName: boolean;

  ngOnChanges(){
    this.sortField = null;
  }

  ngOnInit() {
    this.sortField = null;
    this.sortOptions = [
      {label: 'Most Recent Encounter', value: 'latestDiagnosisDate'},
      {label: 'ICD Code Description', value: 'healthDiagnosis.diagnosisDesc'},
      {label: 'Most Recent Physician', value: 'latestServiceProvider.providerName'},
      {label: 'Original Encounter', value: 'diagnosisDate'},
      {label: 'Original Physician', value: 'serviceProvider.providerName'}
    ]
    this.sortKey = this.sortOptions[0];

    this.initializeData();
  }

  ngOnDestroy() {
    if (this.member$) {
      this.member$.unsubscribe();
    }
  }

  public onSortChange(event){
    const value = event.value.value;
    if (value === 'latestDiagnosisDate' || value === 'diagnosisDate') {
      this.sortOrder = -1;
    }
    else{this.sortOrder = 1;}
    this.sortField = value;
  }

  public onSortInit(){
    this.sortField = this.sortKey.value;
    if(this.sortField === 'latestDiagnosisDate' || this.sortField === 'diagnosisDate'){
      this.sortOrder = -1;
    }
    else{this.sortOrder = 1;}
  }

  private initializeData() {
    this.member$ = this.memberInfoService.selectedMemberChanged$.pipe(
      map((data) => {
        this.memberInfoService.getMemberConditions(data.cin, data.grgrCk).subscribe(result => {
          this.isUserAssocToMemberPcp = data.isUserAssocToMemberPcp;
          this.conditions = result;
          if (this.conditions && this.conditions.length > 0) {
            this.totalConditions = this.conditions.length;
          }
          else{
            this.totalConditions = 0;
          }
          this.conditionsChange.emit(true);
        }, error => {
          this.totalConditions = 0;
          this.conditions = [];
          this.conditionsChange.emit(true);
        });
        return data;
      })
    ).subscribe();
  }
}
