/*
** Component to display header component (header)
** the OAuth handler library to redirect to IdP with correct URL parameters
*/

import { Component, OnInit, HostListener, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, Subscription } from 'rxjs';
import { PortalUser } from '../../services/models/portal-user';
import { SignalRClientService } from '../../services/signalr-client.service';
import { SessionService } from '../../services/session.service';
import { UserAdminService } from '../../services/user-admin.service';
import { Constants } from '../../app.constants';
import { UserOrganization } from '../../services/models/user-organization';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, OnDestroy {
  message: string;
  home: string;
  healthNetwork: string;
  lineOfBusiness: string;
  eligibility: string;
  doctor: string;
  DefaultLogo: string;
  private showTasks: boolean;
  private newUserRequestSub$: Subscription;
  private organizationsSubscription$ : Subscription;
  private userId: number;

  public users: PortalUser[];
  public userOrganizations: UserOrganization[] = [];
  public selectedUserOrganization: UserOrganization;
  public hasNoCollection = false;
  public displayMyProfile: boolean;

  // Constructor inject OAuth service handler and constants service
  constructor(
    public constants: Constants,
    private authService: OAuthService,
    private router: Router,
    private signalR: SignalRClientService,
    private adminService: UserAdminService,
    private session: SessionService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.users = null;
    this.showTasks = false;
  }

  ngOnInit() {
    if (this.session.hasCollectionAdmin) {
      this.newUserRequestSub$ = this.signalR.newUserRequest().subscribe(requests => {
        this.users = requests;
      });
      this.signalR.connect();
    }
    if (!this.session.isEnterpriseAdmin) {
      this.organizationsSubscription$ = this.session.organizationsChanged$
      .subscribe(result => {
        this.userOrganizations = [];
        if (result) {
          result.forEach(org => {
            this.userOrganizations.push({providerCollectionId: org.providerCollectionID, collectionName: org.collectionName});
          });
          this.selectedUserOrganization = this.userOrganizations.find(c => result.findIndex(r => r.defaultProviderCollectionID === c.providerCollectionId) >= 0);
          if (!this.selectedUserOrganization && this.userOrganizations.length) {
            this.selectedUserOrganization = this.userOrganizations[0];
          }

          this.userId = result[0].userID;

          if (this.session.isDefaultCollectionJustSet) {
            this.setOrganizationFadeInAndOut();
            this.session.isDefaultCollectionJustSet = false;
          }
        }
      });
    }
    this.displayMyProfile = !(this.session.isEnterpriseAdmin || this.session.isEnterpriseUser);
  }

  ngOnDestroy(): void {
    if (this.newUserRequestSub$) {
      this.newUserRequestSub$.unsubscribe();
    }
    if (this.organizationsSubscription$) {
      this.organizationsSubscription$.unsubscribe();
    }
  }

  // Logout via IdP
  public logOut() {
    this.session.end();
  }

  public onShowTasks(): void {
    if (this.showTasks) {
      this.showTasks = false;
    }
    else if (this.users != null) {
      this.showTasks = true;
    }
  }

  // Redirect to SecureAuth (IdP) Self Service realm
  public redirectToUserProfile() {
    window.location.href = this.constants.UserProfileUrl;
  }

  public onSelected(user: PortalUser, popOver: any, event: any): void {
    if (popOver && event) {
      popOver.toggle(event);
    }
    this.adminService.selectedUser = user;
    this.router.navigate(["/user-admin/collections/" + user.providerCollectionID]);
  }

  // Use OAuth Handler to retrieve name from identity token
  public get name() {
    try {
      const claims = this.authService.getIdentityClaims();
      if (!claims) {
        return null;
      }

      return claims['given_name'] + this.constants.SpaceString + claims['family_name'];
    }
    catch (e) {
      return this.constants.EmptyString;
    }
  }

  // Use OAuth2 to check if access token has expired
  public get expired() {
    try {
      return !this.authService.hasValidAccessToken();
    }
    catch (e) {
      return true;
    }
  }

  public myProfileClick(popOver: any, event: any) {
    if (popOver && event) {
      popOver.hide();
    }
    this.router.navigate(['/my-profile'], {skipLocationChange: false})
      .then(() => this.router.navigate(['/my-profile']));
  }

  public onOrganizationChanged(event) {
    this.selectedUserOrganization = this.userOrganizations.find(o => o.providerCollectionId === event.value.providerCollectionId);
    const currCollectionPermission = this.session.permissions.find(p => p.providerCollectionID === this.selectedUserOrganization.providerCollectionId);
    if (currCollectionPermission) {
      this.session.setCurrentPermission(currCollectionPermission);
      this.router.navigate(['/']);
    }
  }

  public repositionOverlayPanel(className: string) {
    let overlay = this.document.getElementsByClassName(className);
    if (overlay && overlay[0]) {
      const el = overlay[0] as HTMLElement;
      if (el) {
        const top = parseInt(el.style.top);
        el.style.top = top + 10 + 'px';

        let childEl = el.firstChild as HTMLElement;
        childEl.style.padding = '0';
    
        const overlayArrowLeft: string = el.style.getPropertyValue('--overlayArrowLeft');
        el.style.setProperty('--overlayArrowLeft',(parseInt(overlayArrowLeft.replace('px', '')) - 20) + 'px');
      }
    }
  }

  private setOrganizationFadeInAndOut() {
    const el = document.getElementById("ddOrganization");
    if (el) {
      el.classList.add('fadeitOrgDropdown');
      setTimeout(() => {
        el.classList.remove('fadeitOrgDropdown');
      }, 2000);
    }
  }
}
