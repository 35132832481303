import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { AuthenticationService, UserProfile, AuthProfile, AccountStatus } from '@caloptima/authentication';
import { RegistrationState } from '../../../services/models/registration-state';
import { RegistrationService } from '../../../services/registration-service';
import { PortalConfig } from '../../../portal-config';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'submit',
  templateUrl: './submit.component.html',
  styleUrls: ['./submit.component.scss']
})
export class SubmitComponent implements OnInit {
  @Input('registrationState') registrationState: RegistrationState;
  @Output() busy = new EventEmitter();
  @Output() previous = new EventEmitter();
  @Output() next = new EventEmitter();

  public isClicked: boolean = false;

  constructor(
    private registrationService: RegistrationService,
    private authService: AuthenticationService,
    private portalConfig: PortalConfig
  ) { }

  ngOnInit() {}

  onPrevious() {
    this.previous.emit();
  }

  public processRegistration() {
    return this.registrationService
      .registerProvider(
        this.registrationState.profile.userID,
        this.registrationState.provider.calProviderID,
        this.registrationState.provider.collectionID,
        this.registrationState.provider.providerAddressType,
        this.registrationState.userPosition.providerPositionID,
        this.registrationState.userPosition.positionDisplayName
      )
      .pipe(
        concatMap(r => {
          return this.registrationService
            .acceptEndUserLicense(
              this.registrationState.profile.userID,
              this.registrationState.acceptedEulaVersion
            );
        })
        ,concatMap(r => {
          let authProfile: AuthProfile = new AuthProfile();
          authProfile.clientId = this.portalConfig.ClientId;
          authProfile.userProfile = this.registrationState.profile;
          authProfile.userProfile.accountStatus = AccountStatus.Submitted;
          return this.authService.updateUser(authProfile);
        })
      )
      .subscribe(
        result => {
          this.next.emit();
          const name: string = this.registrationState.profile.firstName + " " +this.registrationState.profile.lastName;
          this.registrationService.sendCompletionEmail(this.registrationState.profile.email,name).subscribe({
            next: (result) => {
              this.busy.emit(false);
            },
            error: (err) => {
              this.busy.emit(false);
          }
        })
        },
        error => {
          //       Add logging
        }
      );
  }

  onSubmit() {
    // Call the service to submit the information to the server (userName, CalProviderID, collection ID)
    this.busy.emit(true);
    if (!this.isClicked) {
      this.processRegistration();
    }
  }
}
