
<div class="collection-list-container">
  <div class="collection-list-header">
    <i class="fa-icon fa fa-clone" aria-hidden="true"></i>
    <span class="title">Collection Manager</span>
  </div>
  <div class="collection-list-body">
    <div class="collection-list-search">
      <div class="buttons">
        <button type="button" *ngIf="showAddCollectionButton" class="btn btn-primary btn-add-collection"  title="New Collection" (click)="onAddCollection()">
          <i class="fa fa-plus" aria-hidden="true"></i>
          New Collection
        </button>
      </div>
      <div class="input-group text-search">
        <div class="p-inputgroup">
          <input type="text"
            placeholder="Search"
            class="collection-input text-muted"
            [(ngModel)]="collectionSearchText"
            (keypress)="onSearchKeyPress($event)"
            name="collectionSearchInput">
          <span class="p-inputgroup-addon" (click)="onSearchClick()"><i class="fa fa-search"></i></span>
        </div>
      </div>
    </div>
    <div class="collection-list-result">
      <p-table
        [value]="collections"
        [paginator]="false"
        styleClass="p-datatable-striped p-datatable-lg"
        selectionMode="single"
        [scrollable]="true"
        [resizableColumns]="false"
        [lazy]="true"
        (onLazyLoad)="lazyLoadData($event)">
        <ng-template pTemplate="header">
          <th pSortableColumn="{{col.field}}"
              width="{{col.width}}"
              class="{{col.classHeader}}"
              *ngFor="let col of columns">
            <span>{{col.header}}</span>
            <div *ngIf="col.sort" class="{{sortClass(col.field)}}">
            </div>
          </th>
        </ng-template>
        <ng-template pTemplate="body" let-collection let-rowIndex="rowIndex">
          <tr>
            <td width="{{col.width}}"  *ngFor="let col of columns" class="{{col.classContent}}">
              <span class="p-column-title">{{ col.header }}</span>

              <div *ngIf="col.key === 'spacerStart' || col.key === 'spacerEnd'">
              </div>

              <div *ngIf="col.header === 'Collection name'">
                <span>
                  <a href="javascript:void(0)"
                    title={{collection[col.key]}}
                    (click)="onSelectCollectionName(collection)">
                    {{collection[col.key]}}
                  </a>
                </span>
              </div>

              <div *ngIf="col.header === 'Last verified by'">
                <span>{{collection[col.key]}}</span>
              </div>

              <div *ngIf="col.header === 'Last verified on'">
                <span>{{collection[col.key] | date : 'MM/dd/yy'}}</span>
              </div>

              <div *ngIf="col.header === 'Days left to verify' && collection.forVerifyCount > 0">
                <span class="days-left-to-verify">{{collection[col.key]}}</span>
                <button type="button" class="btn btn-primary verify-button" [ngClass]="getVerifyButtonClass(collection)" title="Verify Users" (click)="onVerifyUser(collection)">
                  Verify Users
                </button>
              </div>
              <div *ngIf="col.header === 'Days left to verify' && collection.forVerifyCount === 0">
                <span class="no-users-to-verify">NA</span>
              </div>

              <div *ngIf="col.key === 'externalLink'">
                <button type="button" class="fa fa-external-link collection-detail-button" title="Collection Detail" (click)="onShowCollectionDetails(collection)"></button>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="summary" let-columns>
          <app-paginator
            [totalRecords]="totalCollections"
            resultLabel="results"
            [rowsPerPage]="rowsPerPage"
            [pageNumber]="pageNumber"
            (onPage)="onPage($event)">
          </app-paginator>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
<add-collection-dialog #addCollection *ngIf="showAddCollection" (collectionAdded)="onCollectionAdded($event)" (cancelAdd)="onCancelAddCollection()"></add-collection-dialog>
