<p-dialog #dlgEditUser class="remove-user" header="Removal Reason" [(visible)]="display" [modal]="true" (onHide)="onCancel(separationDateModel)" (onShow)="onShow(separationDateModel)">
    <div class="form-group">
        <div class="row">
            <div class="col-md-12 left-align">
                <label>Separation Date</label>
                <div>
                    <input class="form-control input-date" type="Date" name="separationDate" id="separationDate" placeholder="MM/DD/YYYY"
                        #separationDateModel="ngModel" [(ngModel)]="separationDate" 
                        max="{{today | date: 'yyyy-MM-dd'}}"
                        pattern="^((0|1)\d{1})\/((0|1|2)[0-9]|(3)[0,1])\/((19|20)\d{2})|^((0|1)\d{1})((0|1|2)\d{1})((19|20)\d{2})|^((19|20)\d{2})-(1(0|1|2)|0[1-9])-((0|1|2)[0-9]|(3)[0,1])|^((19|20)\d{2})(1(0|1|2)|0[1-9])((0|1|2)[0-9]|(3)[0,1])"
                        required
                        futureDateValidator>
                        <div class="text-danger" *ngIf="separationDateModel.touched && separationDateModel.invalid && (separationDateModel.errors !== null && !separationDateModel.errors.isFutureDate)">
                            Please enter a valid date
                        </div>
                        <div class="text-danger" *ngIf="separationDateModel.touched && (separationDateModel.errors !== null && separationDateModel.errors.isFutureDate) ">
                            This date cannot be in the future
                        </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 left-align">
                <label for="txtRemovalReason">Enter a removal reason below:</label>
                <div class="removal-reason">
                    <textarea id="txtRemovalReason" [(ngModel)]="removalReason" #removalReasonTxt="ngModel" maxlength="500"></textarea>
                </div>
            </div>
        </div>
    </div>
    <p-footer class="edit-user-footer">
        <button type="button" class="btn btn-primary btn-remove" (click)="onRemove()" [disabled]="separationDateModel.invalid || invalid()">Remove</button>
        <button type="button" class="btn btn-secondary btn-cancel" (click)="onCancel(separationDateModel)">Cancel</button>
    </p-footer>
</p-dialog>
