<div class="collection-manager">
    <as-split [direction]="'horizontal'">
        <as-split-area size="30">
            <div class="list">
                <div class="list-header">
                    <div class="search-box">
                        <input type="search" class="" placeholder="Search" name="searchName" id="txtSearchName" (keypress)="onSearchKeyPress($event)" [(ngModel)]="collectionSearchText"
                        #searchText="ngModel" [disabled]="isModal" (search)="search()" (mouseup)="onMouseUp($event)"/>
                    </div>
                    <div class="header-icons" *ngIf="session.isEnterpriseAdmin">
                        <a class="header-icon fa fa-plus-circle" (click)="onAddCollection()"></a>
                    </div>
                </div>
                <div class="list-data">
                    <p-table 
                        #collectionList
                        [value]="collections" 
                        [paginator]="false"
                        [lazy]="true" 
                        (onLazyLoad)="onLoad($event)"
                        selectionMode="single" 
                        [(selection)]="selectedCollection"
                        (onRowSelect)="onSelect($event)">
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="name">Name<div class="{{sortClass()}}"></div></th>
                            </tr>
                        </ng-template>
                        <ng-template let-row pTemplate="body">
                            <tr [pSelectableRow]="row">
                                <td>
                                    <div class="collection-row" (mouseenter)="onCollectionRowEnter(row)" (mouseleave)="onCollectionRowExit(row)">
                                        <div class="collection-type">
                                            <i class="fa fa-hospital-o" *ngIf="row.collectionType!=1"></i>
                                            <i class="fa fa-users" *ngIf="row.collectionType==1"></i>
                                        </div>
                                        <div class="collection-name">
                                            {{row.name}}
                                        </div>
                                        <div class="collection-menu" *ngIf="canEnableMenu(row)">
                                            <div class="fa fa-ellipsis-v" (click)="onCollectionMenu($event, row)">
                                                <div class="context-menu" *ngIf="canShowMenu(row)">
                                                    <div class="context-menu-item" (click)="onRenameCollection($event, row)">Rename</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="summary">
                            <app-paginator [totalRecords]="totalCollections" (onPage)="findCollections($event)"></app-paginator>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </as-split-area>
        <as-split-area size="70">
            <app-collection-detail #detail></app-collection-detail>
        </as-split-area>
    </as-split>
</div>
<p-confirmDialog  acceptIcon="none" rejectIcon="none" acceptButtonStyleClass="btn accept-button" rejectButtonStyleClass="btn reject-button"></p-confirmDialog>
<add-collection-dialog #addCollection *ngIf="showAddCollection" (collectionAdded)="onCollectionAdded($event)" (cancelAdd)="onCancelAddCollection()"></add-collection-dialog>
<rename-collection-dialog #renameCollection *ngIf="showRenameCollection" (collectionRename)="onCollectionRenamed($event)" (cancelRename)="onCancelRenameCollection()"></rename-collection-dialog>
