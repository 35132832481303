<div class="reports-payforvalue-report-type">
    <app-reports-type [reportType]="'bhpayforvalue'"></app-reports-type>
</div>
<div class="reports-payforvalue-container">
  <div class="report-alert">
    <app-alert-info subjectMessage=" " uniqueID="reportsbhpayforvalue" contentMessage="Narrow your search using the known TIN."></app-alert-info>
  </div>
  <div class="report-search">
    <app-reports-tin-search [dialogType]="2" (onProviderSearch)="onSearch($event)"></app-reports-tin-search>
  </div>
  <div class="report-parameter">
    <p-table tableStyleClass="table-data" #providerList
    [value]="displaytaxes"
    [paginator]="true"
    [rows]="10"
    [scrollable]="true"
    scrollHeight="400px"
    (onSort)="onSort($event)"
    [styleClass]="'p-datatable-striped p-datatable-lg'">
      <ng-template pTemplate="header">
        <tr>
          <th width="10%"></th>
          <th width="45%" pSortableColumn="providerTaxName">Name<div class="{{sortClass('providerTaxName')}}"></div></th>
          <th width="45%" pSortableColumn="providerTaxId" class="header-provider-column">TIN<div class="{{sortClass('providerTaxId')}}"></div></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td width="10%" class="check-column">
            <p-radioButton *ngIf='row.providerTaxId != ""' name="taxid" [inputId]="row" [value]="row.providerTaxId" [(ngModel)]="selectedtaxid" (click)="onTaxSelected(row)"></p-radioButton>
          </td>
          <td width="45%">
            <div>{{row.providerTaxName}}</div>
          </td>
          <td width="45%" class="provider-column">
            <div>{{row.providerTaxId}}</div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="report-measurementlabel" *ngIf="selectedtaxid && selectedtaxid != ''">
    <label>Measurement Period for {{displayText}}</label>
  </div>
  <div class="report-filelist" *ngIf="selectedtaxid && selectedtaxid != '' && fileList && fileList.length > 0">
    <p-dropdown name="taxfiles" id="taxfiles" [options]="fileList" [(ngModel)]="selectedFile">
    </p-dropdown>
    <button class="btn btn-primary" type="button" (click)="onBuildReport()" [disabled]='!enableBuildReport'>Download</button>
  </div>
</div>
