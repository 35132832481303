import { Injectable } from '@angular/core';
import { TrayItem } from './models/tray-item';
import { UserType } from './models/portal-user';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PortalNavigationService {
  private trayItems: TrayItem[] = [
    {
      name: 'Dashboard',
      mouseDown: false,
      routerLink: '/dashboard',
      upImage: '../../../assets/images/dashboard-up.png',
      selectedImage: '../../../assets/images/dashboard-selected.png',
      trayImage: '../../../assets/images/dashboard-title-icon.png'
    },
    {
      name: 'Members',
      mouseDown: false,
      routerLink: '/member/search',
      upImage: '../../../assets/images/eligibility-up-alt.png',
      selectedImage: '../../../assets/images/eligibility-selected-alt.png',
      trayImage: '../../../assets/images/emembers-title-icon.png',
      permissionCodes: ['PRVELR']
    }
    ,
    {
      name: 'Roster',
      mouseDown: false,
      routerLink: '/reports/roster',
      upImage: '../../../assets/images/roster-up.png',
      selectedImage: '../../../assets/images/roster-selected.png',
      trayImage: '../../../assets/images/roster-up.png',
      permissionCodes: ['ENTADM','PRVBRV','PRVBRM']
    },
    {
      name: 'Claims',
      mouseDown: false,
      routerLink: '/claims',
      upImage: '../../../assets/images/claims-up.png',
      selectedImage: '../../../assets/images/claims-selected.png',
      trayImage: '../../../assets/images/claims-title-icon.png',
      permissionCodes: ['PRVCLR']
    },
    {
       name: 'Referrals',
       mouseDown: false,
       routerLink: '/referrals/dashboard',
       upImage: '../../../assets/images/referrals-up.png',
       selectedImage: '../../../assets/images/referrals-selected.png',
       trayImage: '../../../assets/images/referrals-title-icon.png',
       permissionCodes: ['PRVRER', 'PRVREM']
    },
    {
      name: 'Reports',
      mouseDown: false,
      routerLink: '/reports',
      upImage: '../../../assets/images/reports-up.png',
      selectedImage: '../../../assets/images/reports-selected.png',
      trayImage: '../../../assets/images/reports-title-icon.png',
      permissionCodes: ['PRVRPR']
    },
    {
      name: 'Admin',
      userType: UserType.EnterpriseAdmin,
      mouseDown: false,
      routerLink: 'user-admin',
      upImage: '../../../assets/images/admin-up.png',
      selectedImage: '../../../assets/images/admin-selected.png',
      trayImage: '../../../assets/images/admin-title-icon.png',
      contextMenuClass: 'admin-menu',
      permissionCodes: ['ENTADM', 'PRVADM'],
      menuItems: [
        {
          name: 'Provider Users',
          routerLink: '/user-admin/user-list/external',
          permissionCodes: ['ENTADM']
        },
        // {
        //   name: 'CalOptima Users',
        //   routerLink: '/user-admin/user-list/internal',
        //   permissionCodes: ['ENTADM']
        // },
        // {
        //   name: 'Create New User',
        //   permissionCodes: ['ENTADM']
        // },
        {
          name: 'Collection Manager',
          routerLink: '/user-admin/collections',
          permissionCodes: ['ENTADM']
        },
        {
          name: 'Verify Collections',
          routerLink: '/user-admin/collections/verify',
          permissionCodes: ['ENTADM', 'PRVADM']
        },
        {
          name: 'CalOptima Health User Manager',
          routerLink: '/user-admin/caloptima-user-manager',
          permissionCodes: ['ENTADM']
        },
        // {
        //   name: 'Configure System',
        //   permissionCodes: ['ENTADM']
        // }
      ]
    }
  ];

  private trayItem = this.trayItems[0];
  private currentTrayItem$ = new BehaviorSubject<TrayItem>(this.trayItem);
  public selectedTrayItem$ = this.currentTrayItem$.asObservable();
  private sidebarState = true;
  private displaySidebar$ = new BehaviorSubject<boolean>(this.sidebarState);
  public showSidebar$ = this.displaySidebar$.asObservable();

  constructor() { }

  public setShowSidebar(state: boolean) {
    this.sidebarState = state;
    this.displaySidebar$.next(state);
  }

  public setSelectedTrayItem(trayItem: TrayItem) {
    if (trayItem) {
      this.trayItem = trayItem;
      this.currentTrayItem$.next(trayItem);
    }
  }

  public getNavigationItems(): TrayItem[] {
    return this.trayItems;
  }
}
