import { BHABAReferralAttestation } from "./bh-aba-referral-attestation";

export class BHABAReferral {
    public autH_SVC_IDN: number;
    public procedurE_CD: string;
    public procedurE_DESC: string;
    public assigneD_UNITS: number;
    public starT_DATE: Date;
    public enD_DATE: Date;
}

export class BHABAReferral2 extends BHABAReferral {
    public attestationId: number;
    public attestationServiceId: number;
    public directServiceCompleted: number;
    public directServicePercentage: number;
}

export class BHABAReferralAttestationWithServices extends  BHABAReferralAttestation {
    public services: BHABAReferral2[] = []
}