import { Component, OnInit, Input, OnChanges, Output, EventEmitter, OnDestroy} from '@angular/core';
import { SelectItem } from 'primeng/api';
import { MemberReferral } from '../../../../services/models/member-referral';
import { Subscription } from 'rxjs';
import { MemberInfoService } from '../../../../services/member-info.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-member-referrals',
  templateUrl: './member-referrals.component.html',
  styleUrls: ['./member-referrals.component.scss']
})


export class MemberReferralsComponent implements OnInit, OnChanges, OnDestroy{
  @Input() tabName: boolean;
  @Input() isLoading: boolean;
  @Output() referralsChange = new EventEmitter();

  public sortOptions: SelectItem[];
  public sortField: string;
  public sortOrder: number;
  public sortKey: SelectItem;
  public member$: Subscription;
  public referrals: MemberReferral[];
  public totalReferrals = 0;


  constructor(
    private memberInfoService: MemberInfoService
  ) { }

  ngOnChanges(){
    //reset the sortField when using the member quick search
    this.sortField = null;
  }

  ngOnInit() {
    this.sortField = null;
    this.sortOptions = [
      {label: 'Most recent referrals', value: 'dateReceived'},
      {label: 'Status', value: 'referralStatus'},
      {label: 'Referral code', value: 'referralId'},
      {label: 'Referred provider', value: 'referredProvider.providerName'}
    ];
    this.sortKey = this.sortOptions[0];

    this.member$ = this.memberInfoService.selectedMemberChanged$.pipe(
    map((data) => {
      this.memberInfoService.getMemberReferral(data.cin, data.grgrCk, data.pcpInfo.pcpId).subscribe(result => {
        this.sortField = null;
        this.referrals = result;
        if (this.referrals && this.referrals.length > 0) {
          this.totalReferrals = this.referrals.length;
        }
        else{
          this.totalReferrals = 0;
        }
        this.referralsChange.emit(true);
      }, error => {
        this.referrals = [];
        this.totalReferrals = 0;
        this.referralsChange.emit(true);
      });
    })
   ).subscribe();
  }

  ngOnDestroy(){
    if (this.member$) {
      this.member$.unsubscribe();
    }
  }

  public onSortChange(event) {
    const value = event.value.value;
    if (value === 'dateReceived') {
      this.sortOrder = -1;
    }
    else{this.sortOrder = 1;}
    this.sortField = value;
  }

  public onSortInit(event){
    this.sortField = this.sortKey.value;
    this.sortOrder = event.sortOrder;
  }

  public onExpanded(event, ref: MemberReferral): void{
    ref.isExpanded = true;
  }

  public onClosed(event, ref: MemberReferral): void{
    ref.isExpanded = false;
  }
}
