import { Component, OnInit, Output, EventEmitter, Input, AfterContentChecked, OnChanges } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ProviderDetail } from '../../../services/models/provider-detail';
import { CollectionType } from '../../../services/models/collection-type';
import { CollectionService } from '../../../services/collection.service';
import { PagerClasses } from '../../../services/models/pager-classes';
import { Messages } from '../../../app.messages';
import { ProviderTaxDetails } from '../../../services/models/provider-tax-details';
import { ProviderGroupDetails } from '../../../services/models/provider-group-details';
import { AlertDangerComponent } from '../../alerts/alert-danger/alert-danger.component';

@Component({
  selector: 'add-provider-dialog',
  templateUrl: './add-provider-dialog.component.html',
  styleUrls: ['./add-provider-dialog.component.scss']
})
export class AddProviderDialogComponent implements OnInit {
  @Input() collectionTypeInput:any;
  @Input() collectionId: number;
  @Output() addProviders = new EventEmitter();
  @Output() addTins = new EventEmitter();
  @Output() addGroups = new EventEmitter();
  @Output() cancelAdd = new EventEmitter();
  
  public validationError: boolean;
  public validationMessage: string;

  public providerName: string;
  public cssClasses = PagerClasses.cssClasses;

  public providers: ProviderDetail[];
  public selected: ProviderDetail[];

  public providerTaxes: ProviderTaxDetails[];
  public selectedProviderTaxes: ProviderTaxDetails[];

  public providerGroups: ProviderGroupDetails[];
  public selectedProviderGroups: ProviderGroupDetails[];

  public display: boolean;  
  public searchLabel: string;
  public header: string;
  public errror: boolean;

  constructor(
    private collectionService: CollectionService,
    private confirm: ConfirmationService,
    private messages: Messages
  )
  {
    this.validationError = false;
    this.validationMessage = '';
  }

  ngOnInit() {
    this.display = false;
    this.providerName = "";

    this.selected = [];    
    this.providers = [];

    this.selectedProviderGroups = [];
    this.providerGroups = [];

    this.selectedProviderTaxes = [];
    this.providerTaxes = [];

    this.validationError = false;
    this.validationMessage = '';
  }

  public get collectionType(): CollectionType {

    if(this.collectionTypeInput == 1)
      return CollectionType.Group;
    else if ( this.collectionTypeInput == 2)
      return CollectionType.TIN;
    else if( this.collectionTypeInput == 3)
      return CollectionType.Manual
    else if( this.collectionTypeInput == 4)
      return CollectionType.MultipleTIN
    else if( this.collectionTypeInput == 5)
      return CollectionType.MultipleGroupId
  }

  private setUIProperties(): void {
    if(this.collectionType === CollectionType.Manual) {
      this.searchLabel = "Enter a TIN, Group ID, or Provider Name.";
      this.header = "Add Provider";
    }
    else if(this.collectionType === CollectionType.TIN || this.collectionType === CollectionType.MultipleTIN) {
      this.searchLabel = "Enter a Provider TIN";
      this.header = "Add Provider"
    }      
    else if(this.collectionType === CollectionType.Group || this.collectionType === CollectionType.MultipleGroupId) {
      this.searchLabel = "Enter a Group ID";
      this.header = "Add Provider"; 
    }      
  }

  private close() {
    this.display = false;
    this.providerName = "";

    this.selected = [];
    this.providers = [];

    this.providerTaxes = [];
    this.selectedProviderTaxes = [];

    this.providerGroups = [];
    this.selectedProviderGroups = [];    
  }

  public show(): void {
    this.display = true;
    this.providerName = "";

    this.selected = [];
    this.providers = [];  
    
    this.providerGroups = [];
    this.selectedProviderGroups = [];

    this.providerTaxes = [];
    this.selectedProviderTaxes = [];   
    
    this.setUIProperties();
  }

  public onSearch(): void {

    this.validationError = false;
    this.validationMessage = '';
    this.selectedProviderGroups = [];
    this.selectedProviderTaxes = [];

    if(this.collectionType === CollectionType.Manual) {
      let sub = this.collectionService.findCollectionProviders(CollectionType.Manual, this.providerName, 20).subscribe(results => {
        this.providers = results;
        sub.unsubscribe();
      });
    }
    else if(this.collectionType === CollectionType.TIN || this.collectionType === CollectionType.MultipleTIN) {
      let sub = this.collectionService.findProviderTaxDetails(this.providerName).subscribe(result => {
        this.providerTaxes = result;
        sub.unsubscribe();
      });
    }
    else if(this.collectionType === CollectionType.Group || this.collectionType === CollectionType.MultipleGroupId) {
      let sub = this.collectionService.findProviderGroupDetails(this.providerName).subscribe(result => {
        this.providerGroups = result;
        sub.unsubscribe();
      });
    }
  }

  public onAddProviders(): void {        

    this.validationError = false;
    this.validationMessage = '';

    if(this.collectionType === CollectionType.Manual) {

      let selectedItems: ProviderDetail[] = [];
      this.selected.forEach(x=> {
        if(!x.collectionID || x.collectionID == 0)
          selectedItems.push(x);
      })

      this.addProviders.emit(selectedItems.slice());
      this.selected = [];
      this.close();
    }
    else if(this.collectionType === CollectionType.TIN || this.collectionType === CollectionType.MultipleTIN) {

      let selectedTinKeys: string[] = [];
      let selectedItems: ProviderTaxDetails[] = [];
      this.selectedProviderTaxes.forEach(x => {
        if(!x.providerCollectionId || x.providerCollectionId === 0) {
          if (x.affiliatedProviderCount > 0) {
            selectedTinKeys.push(x.providerTaxId);
            selectedItems.push(x);
          }
          else {
            this.validationError = true;
            this.validationMessage = "No associated Provider for selected TIN : " + x.providerTaxName;
            return;
          }
        }
      });

      if(!this.validationError) {
        let sub = this.collectionService.findMultipleCollectionProviders(CollectionType.TIN, selectedTinKeys, null).subscribe(results => {
          sub.unsubscribe();
            this.addTins.emit(selectedItems);
            this.addProviders.emit(results);        
            this.selectedProviderTaxes = [];
            this.close();
        });
      }
    }
    else if(this.collectionType === CollectionType.Group || this.collectionType === CollectionType.MultipleGroupId) {

      let selectedGroupKeys: string[] = [];
      let selectedItems: ProviderGroupDetails[] = [];
      this.selectedProviderGroups.forEach(x => {
        if(!x.providerCollectionId || x.providerCollectionId === 0) {
          if (x.affiliatedProviderCount > 0) {
            selectedGroupKeys.push(x.providerGroupId);
            selectedItems.push(x);
          }
          else {
            this.validationError = true;
            this.validationMessage = "No associated Provider for selected Group : " + x.providerGroupName;
            return;
          }
        }
      });

      if(!this.validationError) {
          let sub = this.collectionService.findMultipleCollectionProviders(CollectionType.Group, selectedGroupKeys, null).subscribe(results => {
            sub.unsubscribe();
              this.addGroups.emit(selectedItems);
              this.addProviders.emit(results);
              this.selectedProviderGroups = [];
              this.close();
          });
        }
    }
  }

  public onCancel(): void {

    this.validationError = false;
    this.validationMessage = '';

    if (this.selected.length > 0) {
      this.confirm.confirm({
        message: this.messages.MessageProvidersSelectedNotAdded,
        acceptLabel: this.messages.ButtonYes,
        rejectLabel: this.messages.ButtonNo,
        accept: () => {
          this.onAddProviders();
        },
        reject: () => {
          this.close();
        }
     })
    }
  }

  public get searchPattern(): string {
    if(this.collectionType === CollectionType.TIN || this.collectionType === CollectionType.MultipleTIN)
      return "^[0-9]{9}$";
    else if(this.collectionType === CollectionType.Group || this.collectionType === CollectionType.MultipleGroupId)
      return "^[A-Za-z0-9]{4,13}$"
    else
      return "";
  }

  public get searchLength() : number {
    if(this.collectionType === CollectionType.TIN || this.collectionType === CollectionType.MultipleTIN)
      return 9;
    else if(this.collectionType === CollectionType.Group || this.collectionType === CollectionType.MultipleGroupId)
      return 13;
    else
      return 500;
  }

  public onKeyDown(e: KeyboardEvent): void {
    if (e.key === 'Enter' && this.canEnableSearch) {
      this.onSearch();
    }
  }

  public get canEnableSearch() : boolean {

    if(!this.providerName) 
      return false;

    if(this.collectionType == CollectionType.TIN || this.collectionType == CollectionType.MultipleTIN) {

      var regEx: RegExp = new RegExp(this.searchPattern);
      if(this.providerName.length != 9 || !String(this.providerName).match(regEx))
        return false;
    }
    else if(this.collectionType == CollectionType.Group || this.collectionType == CollectionType.MultipleGroupId) {

      var regEx: RegExp = new RegExp(this.searchPattern);      
      if(this.providerName.length < 4 || this.providerName.length > 13 || !String(this.providerName).match(regEx))
        return false;
    }
    else if(this.collectionType == CollectionType.Manual) {

      if(this.providerName.length < 2)
        return false;
    }
    else {
      if(this.providerName.length == 0)
        return false;
    }

    return true;
  }

  public get canEnableAdd() : boolean {

    let found:boolean = false;

    if(this.collectionType == CollectionType.TIN || this.collectionType == CollectionType.MultipleTIN) {
        this.selectedProviderTaxes.forEach(x=>{
          if(!x.providerCollectionId || x.providerCollectionId === 0 || x.providerCollectionId === this.collectionId) {
              found = true;
          }
        });
    }
    else if(this.collectionType == CollectionType.Group || this.collectionType == CollectionType.MultipleGroupId) {

      this.selectedProviderGroups.forEach(x=>{
        if(!x.providerCollectionId || x.providerCollectionId === 0 || x.providerCollectionId === this.collectionId) {
            found = true;
        }
      });
    }
    else if(this.collectionType == CollectionType.Manual) {

      this.selected.forEach(x=>{
        if(!x.collectionID || x.collectionID === 0 || x.collectionID === this.collectionId) {
          found = true;
        }
      });
    }

    return found;
  }
}
