
import { Component, OnInit } from '@angular/core';
import { EmailVerificationParameters } from '../../services/models/email-verification-parameters';
import { Router } from '@angular/router';
import { Constants } from '../../app.constants';

@Component({
  selector: 'forgot-password',
  styleUrls: ['./forgot-password.component.scss'],
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit {

  public componentUsing: string;
  public emailAddress: string;

  constructor(
      private constants: Constants,
      private router: Router
      ) {}

  ngOnInit() {
    this.emailAddress = '';
    this.componentUsing = 'forgot-password';
  }

  onPasswordSubmitChange(passwordChange): void {
    if (passwordChange.isSuccess) {
      setTimeout(() => {
          this.router.navigate(['/' + this.constants.LoginUrl]);
        }, 5000);
    }
  }
}
