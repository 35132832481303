<div class="search-filters">
    <div class="filter-provider-name">
        <input class="form-control" type="text" name="txtProviderName" placeholder="Enter Provider Name"  
            id="txtProviderName" [(ngModel)]="providerName" maxlength="50">
    </div>
    <div class="filter-provider-id">
        <input class="form-control" type="text"required="false" placeholder="Enter Provider ID" 
            name="txtProviderId" id="txtProviderId" [(ngModel)]="providerId" maxlength="12" pattern="^\w{4,12}$">
    </div>
    <div class="filter-provider-tin">
        <input class="form-control" type="text"required="false" placeholder="Enter TIN" 
            name="txtProviderTin" id="txtProviderTin" [(ngModel)]="providerTin"
            maxlength="9" pattern="^\w{4,12}$">
    </div>

    <div class="search-provider-button">
        <button id="btnSearchForProvider" class="btn-provider btn btn-primary" type="submit" (click)="searchProviders()"
            [disabled]="providerName.trim().length === 0 && providerId.trim().length === 0 && providerTin.trim().length === 0" >
            Search
        </button>
        <button id="btnSearchForProvider" class="btn-provider-reset btn btn-primary" type="reset" (click)="resetProviderSearch()">
            Reset
        </button>
    </div>
</div>
