<div class="training-attestation-container">
  <div class="training-attestation-container-reporttype">
    <app-attestation-type [attestationType]="'providertrainingattestation'"></app-attestation-type>
  </div>
  <div class="training-attestation-alert">
    <app-alert-info subjectMessage=" " uniqueID="providertrainingattestation" contentMessage="Narrow your search using the known provider or TIN."></app-alert-info>
  </div>
  <div class="training-attestation-container-main">
    <p-tabView styleClass="training-attestation-tabview-styles" [activeIndex]="activeindex" #tabView>
      <p-tabPanel [header]="getTabName(0)">
        <ng-template pTemplate="header">
          {{getTabName(0)}}
        </ng-template>
        <ng-template pTemplate="content">
          <app-attestation-provider-training-individual></app-attestation-provider-training-individual>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel *ngIf="canViewTab(1)" [header]="getTabName(1)">
        <ng-template pTemplate="header">
          {{getTabName(1)}}
        </ng-template>
        <ng-template pTemplate="content">
          <app-attestation-provider-training-annual></app-attestation-provider-training-annual>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
<app-continue-dialog key="continueDialog"></app-continue-dialog>
