<p-dialog 
    header="{{caption}}" 
    [(visible)]="display" 
    [modal]="true" 
    [resizable]="true" 
    (onHide)="cancelDialog()" 
    [style]="{width: '1200px', height:'1500px'}">
    <app-member-roster #memberrostercomponent [member]="member" (onRosterChange)="onRosterChange()" (onSaveSuccess)="onSaveSuccess($event)" (onSaveFailed)="onSaveFailed($event)"></app-member-roster>
    <p-footer>    
        <div>
            <button type="button" class="btn btn-primary btn-create" (click)="updateDialog()">Add</button>
            <button type="button" class="btn btn-secondary btn-cancel" (click)="cancelDialog()">Close</button>     
        </div>        
        <div *ngIf="showSuccess">
            <app-alert-success uniqueID='success' [subjectMessage]="'Success.'" [contentMessage]="successMessage"></app-alert-success>
        </div>        
        <div *ngIf="showError">
            <app-alert-danger uniqueID='fail' [subjectMessage]="'Error.'" [contentMessage]="failureMessage"></app-alert-danger>
        </div>    
    </p-footer>
</p-dialog>

