import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwtHelperService } from '@auth0/angular-jwt'
import { timer } from 'rxjs';
import { SessionService } from '../../../services/session.service';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'app-session-timeout-dialog',
  templateUrl: './session-timeout-dialog.component.html',
  styleUrls: ['./session-timeout-dialog.component.scss']
})
export class SessionTimeoutDialogComponent implements OnInit {
  private timeToShowFirstDialog: number = 900000; // in ms
  private timeToShowSecondDialog: number = 60000; // in ms
  private timeIntervalFirstRefresh: number = 30000; // in ms
  private timeIntervalSecondRefresh: number = 10000; // in ms
  private timeToDisplayFirstDialog = 15;
  private timeToDisplaySecondDialog = 1;
  private firstDialogOpened = false;
  private secondDialogOpened = false;
  public checkSessionTimeout: boolean = true;

  @Input() displayTimeoutReminder: string;
  @Input() display: boolean;
  @Output() onClose = new EventEmitter();

  constructor(
    private authService: OAuthService, 
    private jwtHelper: JwtHelperService,
    private sessionService: SessionService
  ) {
  }

  ngOnInit() {}

  public getSessionTimeoutReminder() {
    var token = this.authService.getAccessToken();
    if (!this.jwtHelper.isTokenExpired(token)) {
        const timeLeftForexpiration =  this.jwtHelper.getTokenExpirationDate(token).getTime() - Date.now();
        if (!this.firstDialogOpened &&
            timeLeftForexpiration <= this.timeToShowFirstDialog +  this.timeIntervalFirstRefresh && 
            timeLeftForexpiration >= this.timeToShowFirstDialog -  this.timeIntervalFirstRefresh) {
            //first dialog timer
            this.firstDialogOpened = true;
            return this.timeToDisplayFirstDialog;
        } else if (!this.secondDialogOpened && 
                    timeLeftForexpiration <= this.timeToShowSecondDialog +  this.timeIntervalSecondRefresh) {
            //2nd dialog timer
            this.onClose.emit();
            this.secondDialogOpened = true;
            return this.timeToDisplaySecondDialog;
        }
    }
    else {
      this.onClose.emit();
      this.sessionService.end();
    }
  }

  refreshTimerObservable() {
    return timer(this.getTimerRefreshInterval());
  }

  onContinue() {
    this.onClose.emit();
  }

  private getTimerRefreshInterval() {
    var token = this.authService.getAccessToken();
    const timeLeftForexpiration = this.jwtHelper.getTokenExpirationDate(token).getTime() - Date.now();
    if (timeLeftForexpiration <= this.timeToShowFirstDialog -  this.timeIntervalFirstRefresh) {
        return this.timeIntervalSecondRefresh;
    }

    return this.timeIntervalFirstRefresh;
  }
}
