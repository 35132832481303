export enum ReportType {
    P4V = 1,            //  Pay For Value
    BLS = 2,            //  Blood Lead Screening
    PCPDocAttest = 3,   //  PCP Document Attestation
    DHCS_BLS = 4,       //  DHCS Blooed Lead Screening
    P4V_Manual = 5,     //  Pay for Value - Manual
    BHP4V = 6,          //  BH - Pay for Value.
    AWV = 7,            //  Annual Wellness Program.
    BHI = 8,            //  BHI
    HIF = 9,            //  Member HIF.
    PTA = 10,            //  Provider Training Attestation (Report Dashboard Widget only)
    PDC = 11            // 
}
