<div class="reports-bloodlead-report-type">
    <app-attestation-type [attestationType]="'attestationbloodleadscreening'"></app-attestation-type>
</div>
<div class="reports-bloodlead-container">
  <div class="report-alert">
    <app-alert-info  uniqueID="attestationbloodleadscreening" subjectMessage="" contentMessage="Narrow your search using the known provider name or ID."></app-alert-info>
  </div>
  <div class="report-search">
    <app-reports-provider-search (onProviderSearch)="onSearch($event)"></app-reports-provider-search>
  </div>
  <div class="report-parameter">
    <p-table tableStyleClass="table-data" #providerList
    [value]="displayProviders"
    [paginator]="true"
    [rows]="10"
    [scrollable]="true"
    scrollHeight="400px"
    (onSort)="onSort($event)">
      <ng-template pTemplate="header">
        <tr>
          <th width="5%"></th>
          <th width="35%" pSortableColumn="name">Name<div class="{{sortClass('name')}}"></div></th>
          <th width="20%" pSortableColumn="calProviderID" class="header-provider-column">Provider ID<div class="{{sortClass('calProviderID')}}"></div></th>
          <th width="20%" pSortableColumn="taxId" class="header-provider-column">TIN<div class="{{sortClass('taxId')}}"></div></th>
          <th width="20%" pSortableColumn="attestationStatus">Attestation Required<div class="{{sortClass('attestationStatus')}}"></div></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td width="5%" class="check-column">
            <p-radioButton name='providerid' id='{{row.calProviderID}}' value='{{row.calProviderID}}' [(ngModel)]="selectedCalProviderID" (click)="onProviderSelected(row)"></p-radioButton>
          </td>
          <td width="35%">
            <div>{{row.name}}</div>
          </td>
          <td width="20%" class="provider-column">
            <div>{{row.calProviderID}}</div>
          </td>
          <td width="20%" class="provider-column">
            <div>{{row.taxId}}</div>
          </td>
          <td width="20%">
            <div *ngIf="row.attestationStatus == 1" style="color:green">{{attestationStatusDisplay(row.attestationStatus)}}</div>
            <div *ngIf="row.attestationStatus == 2" style="color:orange">{{attestationStatusDisplay(row.attestationStatus)}}</div>            
            <div *ngIf="row.attestationStatus == 3" style="color:red">{{attestationStatusDisplay(row.attestationStatus)}}</div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="report-measurementlabel" *ngIf="selectedProviderID != ''">
    <label>Measurement Period for {{displayText}}</label>
  </div>
  <div class="report-filelist" *ngIf="selectedProviderID != ''">
    <p-table tableStyleClass="table-data" #providerList
    [value]="attestationList"
    [paginator]="true"
    [rows]="10"
    [scrollable]="true"
    scrollHeight="400px"
    (onSort)="onSort($event)">
      <ng-template pTemplate="header">
        <tr>
          <th width="5%">
            <p-checkbox *ngIf="anyAttestationAvailableToSelect && visibleAttest" name='selectunselectall' [(ngModel)]="overAllCheckUnCheck" title="" binary="true"></p-checkbox>
          </th>
          <th width="30%" pSortableColumn="measurementPeriodDisplay">Measurement Period<div class="{{sortClass('measurementPeriodDisplay')}}"></div></th>
          <th width="30%" pSortableColumn="reportPeriod">Reporting Period<div class="{{sortClass('reportPeriod')}}"></div></th>
          <th width="20%" pSortableColumn="attestationStatus" class="header-provider-column">Status<div class="{{sortClass('attestationStatus')}}"></div></th>
          <th width="15%" pSortableColumn="attestationDueDate">Due Date<div class="{{sortClass('attestationDueDate')}}"></div></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td width="5%" class="check-column">
            <p-checkbox *ngIf="row.attestationStatus !== 1 && visibleAttest" name='{{row.attestationId}}' [(ngModel)]="row.hasChecked" title="" binary="true"></p-checkbox>
          </td>
          <td width="30%">
            <div>{{row.measurementPeriodDisplay}}</div>
          </td>
          <td width="30%">
            <div>{{row.reportPeriodDisplay}}</div>
          </td>          
          <td width="20%" class="provider-column">
            <div *ngIf="row.attestationStatus == 1" style="color:green">{{attestationStatusDisplay(row.attestationStatus)}}</div>
            <div *ngIf="row.attestationStatus == 2" style="color:orange">{{attestationStatusDisplay(row.attestationStatus)}}</div>            
            <div *ngIf="row.attestationStatus == 3" style="color:red">{{attestationStatusDisplay(row.attestationStatus)}}</div>
          </td>
          <td width="15%">
            <div>{{row.attestationDueDate | date : 'MM-dd-yyyy'}}</div>
          </td>
        </tr>
      </ng-template>
    </p-table>
    
  </div>
  <div class="report-alert1" *ngIf="visibleAttest && anyAttestationAvailableToSelect">
    <app-alert-info uniqueID="alert1" subjectMessage="" contentMessage="Did you know? Check multiple boxes to complete BLS attestations at once."></app-alert-info>
  </div>  
  <div class="report-button" *ngIf="visibleAttest">
    <button class="btn btn-primary" type="button" (click)="onAttest()" [disabled]='!enableAttest'>Attest</button>    
  </div>
</div>
<attestation-dialog #attestation *ngIf="showAttestation" [fullname]='userFullName' (submitAttestation)="onSubmitAttestation($event)" (cancelAttestation)="onCancelAttestation()"></attestation-dialog>