import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthHelper } from '@caloptima/authentication';


@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard  {

  constructor(private oauth: OAuthService, private authHelper: AuthHelper) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // debugger;
    // const oauthCheck = this.oauth.hasValidAccessToken();
    // console.log('oauthCheck', oauthCheck);
    const validToken = this.authHelper.tokenNotExpired();
    console.log('authHelperCheck', validToken);
    return !validToken;
  }

}
