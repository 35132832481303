<div class="reports-type">
  <app-reports-type [reportType]="'home'"></app-reports-type>
</div>
<div class="ribbon-wrapper">
  <div class="ribbon-header"></div>
  <div class="ribbon">
      <div class="icon-wrapper">

          <!-- IMAGE FILE //-->
          <img src="../../../assets/images/icon-report.png">

          <!-- FONTAWESOME //-->
          <!-- i class="fa fa-file-alt icon-treatment"></i //-->

      </div>
      <div class="landing-title">
Let's take a look at that report.
      </div>
      <div class="landing-verbiage">
Select from the many report types provided via the select menu above.
      </div>
  </div>
</div>
