import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { RegistrationService } from '../../../services/registration-service';
import { UserProfile, KnowledgeBase, AuthenticationService, AuthenticationFactor, AuthenticationServiceStatus } from '@caloptima/authentication';
import { UserStatusItem } from '../../../user/check-status/check-status.component';
import { EmailVerificationParameters } from '../../../services/models/email-verification-parameters';
import { PortalConfig } from '../../../portal-config';
import { VerifyIdentityModel } from '../../../services/models/verify-identity.model';
import { AuthenticationFactorChoice } from '../../../services/models/authentication-factor-choice.model';
import { Observable, Subscription, timer } from 'rxjs';
import { ErrorStatusModel } from '../../../services/models/login.model';
import { UserService } from '../../../services/user.service';
import { BusyService } from '@caloptima/portal-foundation';
import { Messages } from '../../../app.messages';
import { ServiceUtility } from '../../../services/service-utility';


@Component({
  selector: 'app-user-change-security-dialog',
  templateUrl: './user-change-security-dialog.component.html',
  styleUrls: ['./user-change-security-dialog.component.scss']
})
export class UserChangeSecurityDialogComponent implements OnInit, OnDestroy {
  public verificationParams = new EmailVerificationParameters();
  public componentUsing = 'profile-security-question';
  public passwordSet = false;
  public disabledSaveButton = true;
  public passcode: string;
  public codeExpired: boolean
  public expirationTimeRemaining: string;
  public verifyIdentityModel: VerifyIdentityModel = new VerifyIdentityModel();
  public choices: AuthenticationFactorChoice[];
  public answers: string[];
  public kb: KnowledgeBase[];
  public stepNumber: number = 0;

  private isValidPasscodeFormat: boolean;
  private selectedFactor: AuthenticationFactor;
  private timerSubscription$: Subscription;
  private selectedFactorSubscription$: Subscription;
  private passcodeSubscription$: Subscription;
  private minutesRemaining: number;
  private timer: Observable<number>;
  private errorStatus: ErrorStatusModel;
  private codeValid: boolean;

  private changeSecurityErrors = [
    { id: 0, label: 'There was a problem. Call 714-246-8600 to report.' },
    { id: 1, label: '' }
  ];
  private errorMessage: string;
  private knowledgeBase: KnowledgeBase[] = [];

  @Input() userProfile: UserProfile;
  @Input() display:boolean = false;
  @Output() userSecurityUpdated = new EventEmitter<boolean>();

  constructor(
    private portalConfig: PortalConfig,
    private registrationService: RegistrationService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private authService: AuthenticationService,
    private busy: BusyService,
    private messages: Messages,
    private el: ElementRef
  ) { }

  ngOnInit() {
    this.verifyIdentityModel.TwoFactorOptionsDescription = 'To protect your account, we need to verify your identity. You will receive a temporary code using the delivery method you select below.';
  }

  ngOnDestroy(): void {
    if (this.selectedFactorSubscription$ != null) {
      this.selectedFactorSubscription$.unsubscribe();
    }
    if (this.passcodeSubscription$ != null) {
      this.passcodeSubscription$.unsubscribe();
    }
    if (this.timerSubscription$ != null) {
      this.timerSubscription$.unsubscribe();
    }
  }

  public onCancel() {
    this.onClose();
  }

  public onClose() {
    this.stepNumber = 0;
    this.ngOnDestroy();
    this.userSecurityUpdated.emit(false);
  }

  public onShow() {
    this.initialize();
    setTimeout(() =>{
      const dialogEl =  this.el.nativeElement.querySelector('div[role="dialog"]');
      const boxTop = (window.innerHeight) / 2 - (dialogEl.offsetHeight / 2);
      dialogEl.style.top = boxTop + "px";
    },10);
  }

  public onNext() {
    this.stepNumber++;
    if (this.stepNumber === 1) {
      this.getFactors();
    }
  }

  public onEmailValid() {
  }

  public validPasscode(validPasscode: boolean) {
    this.isValidPasscodeFormat = validPasscode;
  }

  public onSecurityQuestionsValid(knowledgeBase: KnowledgeBase[]): void {
    this.knowledgeBase = knowledgeBase;
    this.disabledSaveButton = this.knowledgeBase ? false : true;
  }

  public onSaveQuestions() {
    this.userProfile.knowledgeBase = this.knowledgeBase;
    this.userProfile.password = null;
    this.authenticationService.updateSelectedUserProfile({
      clientId: this.portalConfig.ClientId,
      userProfile: this.userProfile,
      address: null,
      verificationCode: null
    })
    .subscribe(() => {
        this.stepNumber = 0;
        this.userSecurityUpdated.emit(true);
      },
      (error) => {
        this.stepNumber = 0;
        this.userSecurityUpdated.emit(false);
      }
    );
  }

  private setErrorMessage(statusCode: number = null, statusMessage: string = null) {
    this.errorStatus = new ErrorStatusModel(statusCode, statusMessage);
    this.userService.setErrorMessage(this.errorStatus);
  }

  public backToVerification() {
    this.userService.clearSelectedFactor();
    this.codeExpired = false;
    this.stepNumber--;
    this.getFactors();
  }

  public reSendCode() {
    this.getFactors();
    this.DoFactors(true);
  }

  public DoFactors(resendCode = false) {
    this.busy.emit(true);
    const responses: AuthenticationFactor[] = [];

    if (this.selectedFactor != null) {
      responses.push({ factorType: this.selectedFactor.factorType, factorID: this.selectedFactor.factorID, value: this.passcode });
    }

    const sub = this.authService.authenticateFactors(responses).subscribe((resp) => {
      if (resp.status === AuthenticationServiceStatus.NoError) {
        this.codeValid = true;
        this.onNext();
      } else if (resp.status === AuthenticationServiceStatus.OnetimePasscodeRequired) {
        this.userService.clearPasscode();
        if (resendCode) {
          this.clearErrorMessage()
        }
        else {
          this.onNext();
        }
      } else if (resp.status === AuthenticationServiceStatus.NotallFactorsAuthenticated) {
        this.setErrorMessage(resp.status);
        this.userService.clearPasscode();
      }
      this.busy.emit(false);
      this.startTimer();
      if (sub != null) {
        sub.unsubscribe();
      }
    }, (error) => {
      this.setErrorMessage(error.status);
      this.busy.emit(false);
      this.userService.clearPasscode();
    });
  }

  public getFactors() {
    this.busy.emit(true);
    this.authService.getFactors().subscribe({
      next: (response)  => {
        this.choices = [];
        this.answers = [];

        for (let i = 0; i < response.length; i++) {
          if (response[i].factorType !== 'Question') {
            this.choices.push(new AuthenticationFactorChoice(
              response[i].factorType,
              response[i].factorID,
              response[i].value,
              ServiceUtility.getFactorsChoiceDislaySequence(response[i].factorType)));
          }
        }
        this.choices.sort((a, b) => {
          return a.sequence - b.sequence;
        });
        this.busy.emit(false);
      },
      error: (err) => {
        this.busy.emit(false);
      }
    });
  }

  startTimer() {
    if (this.timerSubscription$ != null) {
      this.timerSubscription$.unsubscribe();
    }
    this.minutesRemaining = 15;
    this.expirationTimeRemaining = this.minutesRemaining + ':00' + this.messages.MinutesLabel;
    var secondsRemaining = this.minutesRemaining * 60;
    this.timerSubscription$ = timer(1000, 1000).subscribe(t => {
      if (--secondsRemaining > 0) {
        this.codeExpired = false;
        var label = secondsRemaining < 60 ? this.messages.SecondsLabel : this.messages.MinutesLabel;
        var secondsPart = (secondsRemaining % 60).toString();
        if (secondsPart.length == 1) {
          secondsPart = '0' + secondsPart;
        }
        this.expirationTimeRemaining = Math.floor(secondsRemaining / 60) + ':' + secondsPart + label;
      }
      else {
        this.codeExpired = true;
        this.timerSubscription$.unsubscribe();
        this.setErrorMessage(13);
      }
    });
  }

  private clearErrorMessage() {
    this.errorStatus = null;
    this.userService.clearErrorMessage();
  }

  private initialize() {
    this.selectedFactorSubscription$ = this.userService.selectedFactorSubject$.subscribe(
      selectedFactor => {this.selectedFactor = selectedFactor; }
    );

    this.passcodeSubscription$ = this.userService.selectedFactorPasscodeSubject$.subscribe(
      passcode => {
        this.passcode = passcode;
      }
    );
    this.onNext();
  }
}
