<div class="collection-detail">
    <div class="content" *ngIf="collectionItem != null">
        <div class="header">
            <h4>
                {{collectionItem.name}}
            </h4>
            <div class="fa fa-times-circle-o" (click)="onClose()"></div>
        </div>
        <p-tabView class="detail" #collectionTabs [activeIndex]="activeTabIndex">
            <p-tabPanel header="Providers" leftIcon="fa fa-user-md" tooltip="Providers" style="width: 150px;">
                <collection-manager-providers #compProviders></collection-manager-providers>
            </p-tabPanel>
            <p-tabPanel header="Users" leftIcon="fa fa-user"  style="width: 150px;" tooltip="Users" [disabled]="!compProviders.collectionValid || collectionItem.id == null">
                <collection-manager-users #compUsers></collection-manager-users>
            </p-tabPanel>
        </p-tabView>
    </div>
    <div class="unselected flex-center" *ngIf="collectionItem == null">
        {{messages.MesssgeNoCollection}}
    </div>
</div>
<p-toast position="bottom-center"></p-toast>

