import { UserCollectionPermission } from "../user-collection-permission";

export class AddInternalUserRequest{
  public userName: string;
  public secureAuthUserName: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public userStatus: string;
  public networkUserId: string;
  public userPermissionId: number;
  public roleId: number;
  public segmentCode: string;
  public isActive: boolean;
  public adminUserId: string;
  public addUser: boolean;
  public isAdmin: boolean;
  public userPermissions: UserCollectionPermission[];
  public notesText: string;
}
