import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { PortalMenuItem } from '../../shared/portal-menu-item';


@Component({
  selector: 'app-reports-roster-menu',
  templateUrl: './reports-roster-menu.component.html',
  styleUrl: './reports-roster-menu.component.scss'
})
export class ReportsRosterMenuComponent implements OnInit {

  @Output() itemclicked = new EventEmitter<any>();
  @Input() tag: any;
  @Input() menuItems: PortalMenuItem[];

  public visible: boolean = false;


  public onView() {
    this.visible = !this.visible
  }  

  ngOnInit(): void {
    
  }  

  public get menuCount() : number{
    return this.menuItems.length;
  }

  itemclick(item: PortalMenuItem) {

    var itemreturn: any = {
      "item": item,
      "tag": this.tag
    }

    this.itemclicked.emit(itemreturn);
  }
}
