import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChange,
  OnChanges,
  ViewChild} from '@angular/core';
import { NgModel } from '@angular/forms';
import { CityService } from '../../services/city.service';
import { City } from '../interfaces/city.interface';

@Component({
  selector: 'app-city-lookup',
  templateUrl: './city-lookup.component.html',
  styleUrls: ['./city-lookup.component.scss'],
  // viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})

export class CityLookupComponent implements OnInit, OnChanges {
  @ViewChild('CityDD', {static: true}) cityDD: NgModel;
  cities: City[] = null;
  selectedCity: City = null;
  @Output() citySelected = new EventEmitter<string>();
  @Input() clear: boolean = false;

  constructor(public cityService: CityService) {
   }

  ngOnInit() {
    this.cityService.cities$.subscribe(
      res => {
        this.cities = res;
      }
    );
  }

  onChange() {   
    if (this.selectedCity === null) {
      this.citySelected.emit(null);
    }
    else {
      this.citySelected.emit(this.selectedCity.name);
    }
  }

  ngOnChanges(changes: {[property:string]: SimpleChange}) {
    this.clearLookup();
  }

  public clearLookup() {
    this.selectedCity = null;
    
    if(this.cityDD && this.cityDD.control)
      this.cityDD.control.reset();
  }

}

