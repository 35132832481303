import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, Subject } from 'rxjs';
import { AppConfig } from '@caloptima/portal-foundation';
import { PortalUser, UserType, UserStatus } from './models/portal-user';
import { HubConnection, HubConnectionBuilder, ILogger, LogLevel } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';

export class MyLogger implements ILogger {
  log(logLevel: LogLevel, message: string) {
      // Use `message` and `logLevel` to record the log message to your own system
      console.log('logging', message);
  }
}

@Injectable({
  providedIn: 'root'
})
export class SignalRClientService {

  private hubConnection: HubConnection;
  private newUserRequestSubject$: Subject<PortalUser[]>;

  constructor(
    private configuration: AppConfig,
    private oAuthService: OAuthService
  ) {}

  public connect() {
    let url: string = this.configuration.getConfig('BaseProviderServicesApiUrl');
    if (url[url.length - 1] == '/') {
      url = url.substr(0, url.length - 1);
    }

    this.hubConnection = new HubConnectionBuilder()
      .withUrl(url + '/signalr', { accessTokenFactory: () => this.oAuthService.getAccessToken() })
      .configureLogging(signalR.LogLevel.Error)
      .withAutomaticReconnect()
      .build();

    this.startConnection();
    this.hubConnection.onclose(async () => {
        await this.startConnection();
    });
  }

  public async startConnection() {
    await this.hubConnection.start()
      .then(() => {
        const user = this.oAuthService.getIdentityClaims();
        const email: string = user['email'];
        this.hubConnection.invoke('Connect', email.toLowerCase());
        this.registerSignalEvent();
      })
      .catch(err => {
        console.log("Failed to start signalr connection!" + err);

        setTimeout(function () {
          this.startConnection();
        }, 3000);
      });
  }

  public registerSignalEvent() {
    this.hubConnection.on("newuserrequests", (data: PortalUser[]) => {
      let users: PortalUser[] = [];
      data.forEach(affiliation => {
        affiliation.userID = affiliation.secureAuthUserName;
        affiliation.createDate = new Date(affiliation.statusDate);
        affiliation.suspenseId = affiliation.userSuspenseID;
        affiliation.status =  UserStatus.Pending;
        affiliation.statusDate = '';
        affiliation.notesText = '';
        affiliation.isEnterpriseUserFlag =  false;
        affiliation.isVerified =  false;
        affiliation.hasVerificationChange =  false;
      });
      this.newUserRequestSubject$.next(data);
    });
  }
  
  public disconnect() {
    if (this.hubConnection != null) {
      this.hubConnection.stop();
      this.hubConnection = null;
    }
  }

  public newUserRequest(): Observable<PortalUser[]> {
    this.newUserRequestSubject$ = new Subject<PortalUser[]>();
    return this.newUserRequestSubject$.asObservable();
  }
}
