<div *ngIf="displaySmallerScreen">
  <div class="small-screen-header">
    <div class="appImage">
      <img src="./assets/images/CalOptima Logo - Provider Portal Reverse.png" class="logo img-responsive" alt="logo" />
    </div>
  </div>
  <div class="container small-screen-container">
    <div class="row welcome">
      <div class="col-md-12 displayCenter">
        <h1>Welcome to the Provider Portal</h1>
      </div>
    </div>
    <div class="row" *ngIf="loggedOut">
      <div class="col-md-12 displayCenter">
        <div class="alert text-center alert-success">
          <i class="fa fa-check-circle"></i>
          <strong> Success!</strong> You have been logged out.
        </div>
      </div>
    </div>
    <div class="row displayCenter">
      <div class="col-md-12 choose-message">
        <p><strong>For best experience, choose a larger device such as a tablet or desktop. </strong></p>
      </div>
    </div>
    <div class="row displayCenter">
      <div class="col-md-12 donot-support-message">
        <p>We currently do not support screen sizes smaller than 768x1024.</p>
      </div>
    </div>
    <div class="row login" *ngIf="loggedIn">
      <div class="col-md-12 displayCenter">
        <div class="loggedOn-message">
          <h3>You are currently logged onto the Portal.</h3>
        </div>
      </div>
      <div class="col-md-12 displayCenter">
        <div class="clickOn-message">
          <p>Click on logout to end your session.</p>
        </div>
      </div>
      <div class="col-md-12 displayCenter">
        <div>
          <button id="btnLogout" class="btn btn-primary" (click)="onLogout()">Logout</button>
        </div>
      </div>
    </div>
  </div>
</div>
