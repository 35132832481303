<p-dialog class="save-changes-alert" header="Save"  [style]="{width: '32rem'}"  [(visible)]="display" [modal]="true" (onHide)="onCancel()">
  <div class="save-changes-alert-container">
      <p>Do you want to save your changes before closing?</p>
  </div>
  <p-footer class="save-changes-alert-footer">
    <div class="buttons">
      <button type="button" class="btn btn-primary btn-leave" (click)="onLeave()">Leave Without Saving</button>
      <button type="button" class="btn btn-primary btn-yes" (click)="onYes()">Yes</button>
    </div>
  </p-footer>
</p-dialog>
