import { NgModule } from '@angular/core';
import { RouterModule, provideRoutes, Routes } from '@angular/router';
import { ReportsBloodleadScreeningComponent } from './reports-bloodlead-screening/reports-bloodlead-screening.component';
import { ReportsHomeComponent } from './reports-home/reports-home.component';
import { ReportsMemberEligibilityRosterComponent } from './reports-member-eligibility-roster/reports-member-eligibility-roster.component';
import { ReportsPayForValueComponent } from './reports-pay-for-value/reports-pay-for-value.component';
import { ReportsPcpMemberRosterComponent } from './reports-pcp-member-roster/reports-pcp-member-roster.component';
import { ReportsPcpAttestationComponent } from './reports-pcp-attestation/reports-pcp-attestation.component';
import { ReportsDHCSBloodleadScreeningComponent } from './reports-dhcs-bloodlead-screening/reports-dhcs-bloodlead-screening.component';
import { ReportsPayForValueManualComponent } from './reports-pay-for-value-manual/reports-pay-for-value-manual.component';
import { AttestationHomeComponent } from './attestation/attestation-home/attestation-home.component';
import { AttestationBloodleadScreeningComponent } from './attestation/attestation-bloodlead-screening/attestation-bloodlead-screening.component';
import { ReportsBHPayForValueComponent } from './reports-BH-pay-for-value/reports-bh-pay-for-value.component';
import { ReportsAWPComponent } from './reports-awp/reports-awp.component';
import { ReportsHIFComponent } from './reports-hif/reports-hif.component';
import { ReportsPcpMemberIHAComponent } from './reports-pcp-member-iha/reports-pcp-member-iha.component';
import { ReportsBHIComponent } from './reports-bhi/reports-bhi.component';
import { ReportsBHABAReferralsComponent } from './reports-bhaba-referrals/reports-bhaba-referrals.component';
import { AttestationProviderTrainingComponent } from './attestation/attestation-provider-training/attestation-provider-training.component';
import { AttestationBhAbaReferralsProviderComponent } from './attestation/attestation-bh-aba-referrals/attestation-bh-aba-referrals-provider/attestation-bh-aba-referrals-provider.component';
import { AttestationBhAbaReferralsComponent } from './attestation/attestation-bh-aba-referrals/attestation-bh-aba-referrals.component';
import { ReportsPdcComponent } from './reports-pdc/reports-pdc.component';
import { ReportsProviderMemberRosterComponent } from './reports-provider-member-roster/reports-provider-member-roster.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/reports/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: ReportsHomeComponent
  },
  {
    path: 'pcpmembershiproster',
    component: ReportsPcpMemberRosterComponent
  },
  {
    path: 'membereligibilityroster',
    component: ReportsMemberEligibilityRosterComponent
  },
  {
    path: 'payforvalue',
    component: ReportsPayForValueComponent
  },
  {
    path: 'bhpayforvalue',
    component: ReportsBHPayForValueComponent
  },  
  {
    path: 'bloodleadscreening',
    component: ReportsBloodleadScreeningComponent
  },
  {
    path: 'pcpattestation',
    component: ReportsPcpAttestationComponent
  },
  {
    path: 'dhcsbloodleadscreening',
    component: ReportsDHCSBloodleadScreeningComponent
  },
  {
    path: 'payforvaluemanual',
    component: ReportsPayForValueManualComponent
  },
  {
    path: 'attestationhome',
    component: AttestationHomeComponent,
  },
  {
    path: 'attestationbloodleadscreening',
    component: AttestationBloodleadScreeningComponent
  },
  {
    path: 'providertrainingattestation',
    component: AttestationProviderTrainingComponent
  },
  {
    path: 'bhabareferralattestation',
    component: AttestationBhAbaReferralsComponent
  },
  {
    path: 'awp',
    component: ReportsAWPComponent
  },
  {
    path: 'hif',
    component: ReportsHIFComponent
  },
  {
    path: 'iha',
    component: ReportsPcpMemberIHAComponent
  },
  {
    path: 'bhi',
    component: ReportsBHIComponent
  },
  {
    path: 'bhaba',
    component: ReportsBHABAReferralsComponent
  },
  {
    path: 'pdc',
    component: ReportsPdcComponent
  },
  {
    path: 'roster',
    component: ReportsProviderMemberRosterComponent
  }
];

export const routedComponents = [
  ReportsHomeComponent
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
