import { Component, OnInit } from '@angular/core';
import { Messages } from '../../../../../app.messages';

@Component({
  selector: 'app-member-custom-messages-nonpcp',
  templateUrl: './member-custom-messages-nonpcp.component.html',
  styleUrls: ['./member-custom-messages-nonpcp.component.scss']
})
export class MemberCustomMessagesNonpcpComponent implements OnInit {
  public userNotAssociatedToMemberPcpMessage: string;

  constructor(private messages: Messages) { }

  ngOnInit() {
    this.userNotAssociatedToMemberPcpMessage = this.messages.UserNotAssociatedToMemberPcp;
  }

}
