import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-attestation-home',
  templateUrl: './attestation-home.component.html',
  styleUrls: ['./attestation-home.component.scss']
})
export class AttestationHomeComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
  }
}
