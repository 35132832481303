<div class="create-password">
    <div class="flex-left header">
        <div class="row">
          <h1>{{passwordChangeHeader}}</h1>
        </div>
        <div class="row">
            <h5 *ngIf="isReset">Enter a new password below.</h5>
            <h5 *ngIf="!isReset">Enter a password below.</h5>
        </div>
    </div>
    <div class="form-group">
        <form #setPasswordForm="ngForm" autocomplete="off">
            <div class="row flex-center">
                <div class="alert-message alert-success" *ngIf="passwordSet && displaySuccessUpdate">
                    <i class="fa fa-check-circle"></i>
                        Password successfully changed. You will be redirected to the {{redirectPage}}
                </div>
                <div class="alert-message alert-danger" *ngIf="isSameAsOldPassword">
                  <i class="fa fa-exclamation-triangle"></i>
                  Cannot use previous password.
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" *ngIf="isReset">
                    <label>New Password</label>
                    <input type="password" class="form-control" required id="txtPassword" name="txtPassword"
                      placeholder="New Password*" #txtPassword="ngModel" [(ngModel)]="password" (keyup)="validatePassword()"
                      (ngModelChange)="change($event)" autocomplete="off"/>
                    <div class="alert-error" *ngIf="txtPassword.touched && password.length == 0">Cannot be left blank.</div>
                </div>
                <div class="col-md-12" *ngIf="!isReset">
                    <label>Password</label>
                    <input type="password" class="form-control" required id="txtPassword" name="txtPassword"
                      placeholder="Password*" #txtPassword="ngModel" [(ngModel)]="password" (keyup)="validatePassword()"
                      (ngModelChange)="change($event)" autocomplete="off"/>
                    <div class="alert-error" *ngIf="txtPassword.touched && password.length == 0">Cannot be left blank.</div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <label>Confirm Password</label>
                    <input type="password" class="form-control" required id="txtPasswordCompare" name="txtPasswordCompare"
                      placeholder="Confirm password*" #txtPasswordCompare="ngModel" [(ngModel)]="passwordCompare"
                      (keyup)="validateBothPasswords()" autocomplete="off" />
                    <div class="alert-error" *ngIf="txtPasswordCompare.touched && passwordCompare.length == 0">Cannot be left blank.</div>
                    <div class="alert-error" *ngIf="(passwordCompare.length > 0) && (passwordCompare !== password)">Passwords do not match</div>
                    <div class="alert-error" *ngIf="password.length > 0 && (passwordCompare === password) && !userNameMatch">The username and password cannot be the same</div>
                </div>
            </div>
            <div class="row">
              <div class="col-md-6 show-password-container">
                  <p-checkbox name="chkShowPassword" tabindex="5" [(ngModel)]="hidePasswordChecked" binary="true" label="Hide Password" (onChange)="onHidePassword($event)"></p-checkbox>
              </div>
            </div>
            <p class="password-requirements">{{messages.SpecialPhraseComplexity}}</p>
            <div class="password-information">
              <ul>
                <li class="complexity" [ngClass]="{'valid': numberChars > 0}"><i class="fa fa-check-circle"></i><div class="text">Number (0-9)</div></li>
                <li class="complexity" [ngClass]="{'valid': symbolChars > 0}"><i class="fa fa-check-circle"></i><div class="text">Symbol (!, &#64;, #, $, %, *, etc.)</div></li>
                <li class="complexity" [ngClass]="{'valid': uppercaseChars > 0}"><i class="fa fa-check-circle"></i><div class="text">Uppercase English letter (A-Z)</div></li>
                <li class="complexity" [ngClass]="{'valid': lowercaseChars > 0}"><i class="fa fa-check-circle"></i><div class="text">Lowercase English letter (a-z)</div></li>
                <li class="complexity" [ngClass]="{'valid': containsSpace == false}"><i class="fa fa-check-circle"></i><div class="text">Does not contain space</div></li>
                <li class="complexity" [ngClass]="{'valid': passwordMinLength == true}"><i class="fa fa-check-circle"></i><div class="text">Password minimum length (8)</div></li>
              </ul>
            </div>
            <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
        </form>
    </div>
</div>
