<div class="list-footer">
    <div class="list-row-count text-muted" *ngIf="pageCount > 0">{{totalRecords}} {{resultLabel}}</div>
    <div class="list-pager" *ngIf="pageCount > 1">
        <button class="list-button btn-text" (click)="gotoPage(1)" [disabled]="pageNumber === 1">First</button>
        <button class="list-button" [ngClass]="cssClasses.pagerLeftArrow" (click)="gotoPage(pageNumber - 1)" [disabled]="pageNumber === 1"></button>
        <div class="list-pager-count">{{pageNumber}} of {{pageCount}}</div>
        <button class="list-button" [ngClass]="cssClasses.pagerRightArrow" (click)="gotoPage(pageNumber + 1)" [disabled]="pageNumber === pageCount"></button>
        <button class="list-button btn-text" (click)="gotoPage(pageCount)" [disabled]="pageNumber === pageCount">Last</button>
    </div>
</div>
