import { ModulePermission } from '../module-permission';
import { UserCollectionRequest } from './user-collection-request';
import { UserCollectionPermission } from '../user-collection-permission';

export class UpdatePermissionsRequest extends UserCollectionRequest {
  public isAdmin: boolean;
  public approve: boolean;
  public add: boolean;
  public userCollectionPermissions: UserCollectionPermission[];
  public roleId: number;
  public AuthorizedName: string;
  public providerPositionId: number;
  public providerPositionOther: string;
  public override userName: string;
  public notes:string;
}
