import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-reports-tin-search',
  templateUrl: './reports-tin-search.component.html',
  styleUrls: ['./reports-tin-search.component.scss']
})
export class ReportsTinSearchComponent implements OnInit {
  public providerId: string;
  @Input() dialogType: number;
  @Output() onProviderSearch: EventEmitter<any> = new EventEmitter(null);

  constructor() { }

  ngOnInit() {
  }

  public findProviders() {
    this.onProviderSearch.emit({ providerId: this.providerId });
  }

  public resetSearchFilters() {
    this.providerId = '';
  }

  public get nameLabel(): string {
    if(this.dialogType && this.dialogType == 2)
      return "Tax Name or Office Name";
    else
      return "Provider Name";
  }
  public get idLabel(): string {
    if(this.dialogType && this.dialogType == 2)
      return "TIN";
    else
      return "Provider ID";
  }  
  public get requiredName() : boolean {
    if(this.dialogType == 2)
      return false;
    else
      return true;
  }
}
