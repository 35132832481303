
<p-dialog
  [(visible)]="display" [style]="{width: '32rem'}"
  [modal]="true" [closeOnEscape]="false" [draggable]="false" [resizable]="false"  [closable]="true"
  header="Edit Info" class="user-edit"
  (onShow)="onShow()" (onHide)="onClose()">
  <form name="userEditForm"
        #userEditForm="ngForm"
        (ngSubmit)="saveUser()"
        autocomplete="off"
        *ngIf="user && userAddress">
    <div class="user-edit-body">
      <div class="address-label">
        Address:
      </div>
      <div class="address-value">
        <input type="text" class="form-control" name="address1" id="txtAddress1"
              [(ngModel)]="userAddress.address1" #userAddress1="ngModel" required />
        <div *ngIf="userAddress1.invalid && userAddress1.touched" class="address-error alert-error">
          <div *ngIf="userAddress1.errors">
            Required
          </div>
        </div>
      </div>
      <div class="suite-label">
        Suite/Apt:
      </div>
      <div class="suite-value">
        <input type="text" class="form-control" name="address2" id="txtUserAddress2"
            [(ngModel)]="userAddress.address2" #userAddress2="ngModel" />
      </div>
      <div class="city-label">
        City:
      </div>
      <div class="city-value">
        <input type="text" class="form-control" name="city" id="txtUserCity"
            [(ngModel)]="userAddress.city" #userCity="ngModel" required />
        <div *ngIf="userCity.invalid && userCity.touched" class="city-error alert-error">
          <div *ngIf="userCity.errors">
            Required
          </div>
        </div>
      </div>
      <div class="state-label">
        State:
      </div>
      <div class="state-value">
        <input type="text" class="form-control" name="state" id="txtUserState" maxlength="2"
            [(ngModel)]="userAddress.state" #userState="ngModel" required />
        <div *ngIf="userState.invalid && userState.touched" class="state-error alert-error">
          <div *ngIf="userState.errors">
            Required
          </div>
        </div>
      </div>
      <div class="zip-label">
        Zip:
      </div>
      <div class="zip-value">
        <p-inputMask type="text" styleClass="form-control" name="zip" id="txtUserZip"
            [(ngModel)]="userAddress.zip" #userZip="ngModel" required
            [mask]="zipMask" placeholder="_____"></p-inputMask>
        <div *ngIf="userZip.invalid && userZip.touched" class="zip-error alert-error">
          <div *ngIf="userZip.errors">
            Invalid Zip Code
          </div>
        </div>
      </div>
      <div class="phone-label">
        Phone:
      </div>
      <div class="phone-value">
        <p-inputMask type="text" styleClass="form-control" name="mobilePhone" id="txtUserMobilePhone"
            [(ngModel)]="user.mobilePhone" #userMobilePhone="ngModel" required
            [mask]="phoneMask" placeholder="___-___-____"></p-inputMask>
        <div *ngIf="userMobilePhone.invalid && userMobilePhone.touched" class="phone-error alert-error">
          <div *ngIf="userMobilePhone.errors">
            Invalid Phone Number
          </div>
        </div>
      </div>
    </div>
    <div class="edit-user-footer">
      <button type="button" class="btn btn-primary btn-cancel" (click)="onCancel()">Cancel</button>
      <button type="submit" class="btn btn-primary btn-save" [disabled]="disabledSaveButton()">Save</button>
    </div>
  </form>
</p-dialog>
