<div>
    <div *ngIf="menuCount > 1">
        <a href="javascript:void(0);" (click)="onView()">...</a>
    </div>
    <div *ngIf="visible || menuCount == 1">
        <div *ngFor="let item of menuItems">
            <div>
                <a href="javascript:void(0);" (click)="itemclick(item)">{{item.label}}</a>
            </div>
        </div>    
    </div>
</div>

