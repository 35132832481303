
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { UserProfile } from '@caloptima/authentication';
import { ReportsService } from '../../../services/reports.service';
import { SessionService } from '../../../services/session.service';
import { saveAs } from 'file-saver';
import { DatePipe, DOCUMENT } from '@angular/common';
import { ReportType } from '../../../services/models/reportype';
import { ReportsMemberSearchComponent } from '../../../app-components/reports-member-search/reports-member-search.component';
import { UiUtility } from '../../../utils/ui-utility';
import { ReportMemberDetails } from '../../../services/models/reportmemberdetails';
import { MemberAWPStatus } from '../../../services/models/awp-detail';
import { WellnessDetails } from '../../../services/models/wellness-details';
import { BusyService } from '../../../services/busy.service';


@Component({
  selector: 'app-hif-member',
  templateUrl: './hif-member.component.html',
  styleUrls: ['./hif-member.component.scss']
})
export class HIFMemberComponent implements OnInit {

  public userProfile: UserProfile;
  public members:ReportMemberDetails[] = [];
  public displayMembers:ReportMemberDetails[] = [];
  public selectedMember: ReportMemberDetails;
  private sortColumn: string;
  private sortAscending: boolean;
  public totalRecords: number;
  public awpList: MemberAWPStatus[];
  public searchCin: string;
  public searchName: string;
  public collectionId: number;
  public calProviderId: string;
  public providerTaxId: string;
  public showHif: boolean;
  public userFullName: string;
  public wellnessDetails: WellnessDetails;
  public selectedMemberAWPStatus:MemberAWPStatus;
  public selectedCin: string;
    

  @ViewChild('memberSearch') memberSearch: ReportsMemberSearchComponent;

  constructor(
    private reportsService: ReportsService,
    private sessionService: SessionService,
    private datePipe: DatePipe,
    private busyService: BusyService,
    @Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit() {
    this.collectionId = this.sessionService.currentPermission.providerCollectionID;
    this.userFullName = '';

    this.initialize();
  }

  ngAfterViewInit(): void {

  }

  public setProviderId(selectedCalProviderID: string, selectedProviderTaxId: string) {
    this.awpList = [];
    this.calProviderId = selectedCalProviderID;
    this.providerTaxId = selectedProviderTaxId;
    this.initialize(true);
  }

  public initialize(resetComponent = false) {
    this.sortColumn = 'memberName';
    this.sortAscending = true;
    this.selectedCin = '';
    this.searchCin = '';
    this.searchName = '';
    this.selectedMember = null;
    this.displayMembers = [];
    
    if (resetComponent) {
      this.memberSearch.resetSearchFilters();
    }

    if(!UiUtility.isNullUndefinedOrEmpty(this.calProviderId)) {

      this.reportsService.getMembersForReportsHIF(this.collectionId, this.calProviderId, this.providerTaxId, ReportType.HIF).subscribe(result=>{

        this.members = result;

        this.totalRecords = this.members.length;

        if(this.members)
          this.displayMembers = this.members.slice();

        let param: any = {
          "field": "memberName",
          "order": 1
        }
        this.onSort(param)
      });
    }
  }

  public sortClass(column: string): string {
    let cls: string;
    if (column == this.sortColumn) {
      cls = this.sortAscending ? "fa fa-chevron-up" : "fa fa-chevron-down";
    }
    else {
      cls = "fa fa-chevron-up";
    }
    return cls;
  }

  public onPage(e: any) {
    let s = JSON.stringify(e);
  }

  public onSort(e: any): void {
    this.displayMembers.sort((data1, data2) => {
      let value1 = data1[e.field];
      let value2 = data2[e.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (e.order * result);
    });
    this.sortAscending = e.order == 1;
    this.sortColumn = e.field;
  }

  public get displayText(): string {
    if(this.selectedMember && this.selectedMember.cin) {

      let memeberDetail: ReportMemberDetails = this.members.find(x=> x.cin === this.selectedMember.cin);

      if(memeberDetail) {
        let s = memeberDetail.memberName;
        return s;
      }
      else {
        return ""
      }
    }
    else {
      return "";
    }
  }

  public get enableBuildReport() : boolean {

    return this.selectedMember != null;
  }

  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob([buffer], { type: fileType });
    saveAs(data, fileName);
  }

  public onMemberSearch(search) : void {

    this.selectedMember = null;
    this.awpList = [];

    if(!UiUtility.isNullUndefinedOrEmpty(this.calProviderId)) {
      if(search.memberName === '' && search.memberId === '') {
        this.displayMembers = this.members.slice();
      }
      else {
        this.displayMembers = [];
        this.members.forEach(x => {

          let include: boolean = false;

          if(search.memberName !== '') {
            include = x.memberName.trim().toUpperCase().includes(search.memberName.trim().toUpperCase());
          }
          if(!include && search.memberId !== '') {
            include = x.cin == search.memberId;
          }
          if(!include && search.memberId !== '') {
            include = x.mbi == search.memberId;
          }
          if(!include && search.memberId !== '') {
            include = x.medsId == search.memberId;
          }                 
          console.log(include + "," + search.memberId);
          if(include){
            this.displayMembers.push(x);
          }
        });
      }
    }
    else {
      this.reportsService.searchMembersForReportsHIF(ReportType.HIF, this.collectionId, search.memberName, search.memberId).subscribe(result=>{

        this.members = result;

        this.totalRecords = this.members.length;

        if(this.members)
          this.displayMembers = this.members.slice();

          let param: any = {
            "field": "memberName",
            "order": 1
          }
          this.onSort(param)
      });

    }
  }

  public viewhif(reportMemberDetails:ReportMemberDetails) : void {
    this.selectedMember = reportMemberDetails;
    this.showHif = true;
  }

  public onDownloadList() : void {

    this.reportsService.downloadHifMemberList([this.calProviderId]).subscribe(result => {
      this.saveAsFile(result, 'hif-members-list.csv', 'text/csv');
    })
  }

  public onCancelMemberHifData() {
    this.showHif = false;
  }
}
