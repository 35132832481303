import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'please-wait-dialog',
  templateUrl: './please-wait-dialog.component.html',
  styleUrls: ['./please-wait-dialog.component.scss']
})
export class PleaseWaitDialogComponent implements OnInit {
  // public display: boolean = false;
  @Input() display: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  public showDialog() {
    this.display = true;
  }

}
