export class MemberPcprosterDownloadRequest {
  public username: string;
  public collectionId: number;
  public providerIds: string[];
  public pageNumber: number;
  public pageSize: number;
}

export class BHIDownloadRequest {
  public username: string;
  public calProviderId: string;
  public measurementPeriod: string[] = [];
}

export class BHABAReferralDownloadRequest {
  public username: string;
  public pageNumber: number;
  public pageSize: number;
  public providerIds: string[];
  public startDate: Date;
  public endDate: Date;
}