
<div class="attestation-provider-training-container">
  <div class="report-search">
    <app-reports-provider-search (onProviderSearch)="onSearch($event)"></app-reports-provider-search>
  </div>
  <div class="report-error" *ngIf="errorMessage.length">
    {{errorMessage}}
  </div>
  <div class="report-result">
    <p-table tableStyleClass="table-data" #providerList
    [value]="displayProviders"
    [paginator]="true"
    [rows]="10"
    [scrollable]="true"
    scrollHeight="325px"
    [styleClass]="'p-datatable-striped p-datatable-lg'">
      <ng-template pTemplate="header">
        <tr>
          <th width="50px">&nbsp;</th>
          <th width="35%" pSortableColumn="name">Name</th>
          <th width="15%" pSortableColumn="calProviderID" class="header-provider-column">Provider ID</th>
          <th width="15%" pSortableColumn="taxId" class="header-provider-column">TIN</th>
          <th width="15%" pSortableColumn="trainingDueDate" class="header-provider-column">Due Date</th>
          <th width="15%" pSortableColumn="trainingAttestationStatusDisplay">Attestation Status</th>
          <th width="20px">&nbsp;</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td width="50px" class="check-column">
            <p-radioButton name="providerid" [inputId]="row.calProviderID" [value]="row.calProviderID" [(ngModel)]="selectedCalProviderID" (click)="onProviderSelected(row, true)" *ngIf="row.trainingAttestationStatusDisplay !== 'Submitted' && !isEnterpriseUser"></p-radioButton>
          </td>
          <td width="35%">
            <div *ngIf="row.trainingAttestationStatusDisplay !== 'Submitted' && !isEnterpriseUser">{{row.name}}</div>
            <div *ngIf="row.trainingAttestationStatusDisplay === 'Submitted' || isEnterpriseUser"><a href="javascript:void(0);" (click)="onProviderSelected(row)">{{row.name}}</a></div>
          </td>
          <td width="15%" class="provider-column">
            <div>{{row.calProviderID}}</div>
          </td>
          <td width="15%" class="provider-column">
            <div>{{row.taxId}}</div>
          </td>
          <td width="15%" class="provider-column">
            <div>{{row.trainingDueDate}}</div>
          </td>
          <td width="15%" class="provider-column">
            <div *ngIf="row.trainingAttestationStatusDisplay === 'Verified' || row.trainingAttestationStatusDisplay === 'Submitted'" style="color:green">{{row.trainingAttestationStatusDisplay}}</div>
            <div *ngIf="row.trainingAttestationStatusDisplay === 'Pending'" style="color:orange">{{row.trainingAttestationStatusDisplay}}</div>            
            <div *ngIf="row.trainingAttestationStatusDisplay === 'Past Due'" style="color:red">{{row.trainingAttestationStatusDisplay}}</div>
          </td>
          <td width="20px" class="provider-column">
            <button type="button" class="view-button fa fa-search" title="View" (click)="onProviderSelected(row)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="report-message" *ngIf="displayMessage">
      <span class="no-results-message">No results found.</span>
  </div>
</div>
<app-attestation-provider-training-dialog
  *ngIf="displayProviderAttestationTraining"
  [providerDetail] = "selectedProvider"
  (onAttestationSubmission)="onProcessAttestation($event)">
</app-attestation-provider-training-dialog>
