<div class="reports-bhi-report-type">
    <app-reports-type [reportType]="'bhi'"></app-reports-type>
</div>
<div class="reports-bhi-container">
  <div class="report-alert">
    <app-alert-info subjectMessage=" " uniqueID="reportsbhi" contentMessage="Narrow your search using the known provider name or ID."></app-alert-info>
  </div>
  <div class="report-option">
      <div class="report-option-provider">
        <p-radioButton name="optionname" [inputId]="'optionid'" [value]="'1'" [(ngModel)]="optionValue" (click)="selectOption('1')"/>
      </div>
      <div class="report-option-provider-lbl">
        Search by Provider
      </div>      
      <div class="report-option-prescriber">
        <p-radioButton name="optionname" [inputId]="'optionid'" [value]="'2'" [(ngModel)]="optionValue" (click)="selectOption('2')"/>
      </div>
      <div class="report-option-prescriber-lbl">
        Search by Prescriber
      </div>            
  </div>
  <div class="report-search" *ngIf="optionValue == '1'">
    <app-reports-provider-search #reportsprovidersearchcomponent (onProviderSearch)="onSearch($event)"></app-reports-provider-search>
  </div>
  <div class="report-parameter" *ngIf="optionValue == '1'">
    <p-table tableStyleClass="table-data" #providerList
    [value]="displayProviders"
    [paginator]="true"
    [rows]="10"
    [scrollable]="true"
    scrollHeight="400px"
    (onSort)="onSort($event)"
    [styleClass]="'p-datatable-striped p-datatable-lg'"
    >
      <ng-template pTemplate="header">
        <tr>
          <th width="5%"></th>
          <th width="20%" pSortableColumn="name">Name<div class="{{sortClass('name')}}"></div></th>
          <th width="20%" pSortableColumn="calProviderID" class="header-provider-column">Provider ID<div class="{{sortClass('calProviderID')}}"></div></th>
          <th width="15%" pSortableColumn="groupName" class="header-medicalgroup-column">Medical Group<div class="{{sortClass('groupName')}}"></div></th>          
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td width="5%" class="check-column">
            <p-radioButton name="providerid" [inputId]="row.calProviderID" [value]="row.calProviderID" [(ngModel)]="selectedCalProviderID" (click)="onProviderSelected(row)"></p-radioButton>
          </td>
          <td width="20%">
            <div>{{row.name}}</div>
          </td>
          <td width="20%" class="provider-column">
            <div>{{row.calProviderID}}</div>
          </td>
          <td width="15%" class="medicalgroup-column">
            <div>{{row.groupName}}</div>
          </td>                
        </tr>
      </ng-template>
    </p-table>
  </div>    
  <div class="report-search" *ngIf="optionValue == '2'">
    <app-reports-member-search #reportsprescribersearchcomponent (onMemberSearch)="onSearchPrescriber($event)"></app-reports-member-search>
  </div>    
  <div class="report-parameter" *ngIf="optionValue == '2'">
    <p-table tableStyleClass="table-data" #providerList
    [value]="displayPrescribers"
    [paginator]="true"
    [rows]="10"
    [scrollable]="true"
    scrollHeight="400px"
    (onSort)="onSort($event)"
    [styleClass]="'p-datatable-striped p-datatable-lg'"
    >
      <ng-template pTemplate="header">
        <tr>
          <th width="5%"></th>
          <th width="20%" pSortableColumn="name">Provider Name<div class="{{sortClass('name')}}"></div></th>
          <th width="20%" pSortableColumn="calProviderID" class="header-provider-column">Provider ID<div class="{{sortClass('calProviderID')}}"></div></th>          
          <th width="20%" pSortableColumn="bhiPrescriberTaxID" class="header-prescribertaxid-column">Prescriber Tax ID<div class="{{sortClass('bhiPrescriberTaxID')}}"></div></th>
          <th width="20%" pSortableColumn="bhiPrescriberNPI" class="header-prescribernpi-column">Prescriber NPI<div class="{{sortClass('bhiPrescriberNPI')}}"></div></th>                    
          <th width="15%" pSortableColumn="groupName" class="header-medicalgroup-column">Medical Group<div class="{{sortClass('groupName')}}"></div></th>          
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td width="5%" class="check-column">
            <p-radioButton name="providerid" [inputId]="row.calProviderID" [value]="row.calProviderID" [(ngModel)]="selectedCalProviderID" (click)="onProviderSelected(row)"></p-radioButton>
          </td>
          <td width="20%">
            <div>{{row.name}}</div>
          </td>
          <td width="20%" class="provider-column">
            <div>{{row.calProviderID}}</div>
          </td>          
          <td width="20%" class="prescribertaxid-column">
            <div pTooltip="{{row.bhiPrescriberTaxID}}.">
              {{row.bhiPrescriberTaxID.length > 40 ? row.bhiPrescriberTaxID.substring(0,40) + '...' : row.bhiPrescriberTaxID}}
            </div>
          </td>
          <td width="20%" class="prescribernpi-column">
            <div pTooltip="{{row.bhiPrescriberNPI}}.">
              {{row.bhiPrescriberNPI.length > 40 ? row.bhiPrescriberNPI.substring(0,40) + '...' : row.bhiPrescriberNPI}}
            </div>
          </td>                                        
          <td width="15%" class="medicalgroup-column">
            <div>{{row.groupName}}</div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>  
  <div class="report-measurementlabel" *ngIf="selectedCalProviderID != ''">
    <label>Measurement Period for {{displayText}}</label>
  </div>
  <div class="report-filelist" *ngIf="selectedCalProviderID && selectedCalProviderID != ''">
    <p-table tableStyleClass="table-data" #providerList
    [value]="measurementPeriods"
    [paginator]="true"
    [rows]="10"
    [scrollable]="true"
    scrollHeight="400px"
    (onSort)="onSort($event)">
      <ng-template pTemplate="header">
        <tr>
          <th width="10%">
            <p-checkbox name='selectunselectall' [(ngModel)]="overAllCheckUnCheck" title="" binary="true"></p-checkbox>
          </th>
          <th width="30%">Measurement Period</th>
          <th width="30%">Status</th>
          <th width="30%">Received Date</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td width="10%" class="check-column">
            <div>
              <p-checkbox name='{{row.MeasurementPeriod}}' [(ngModel)]="row.selected" title="" binary="true"></p-checkbox>
            </div>
          </td>
          <td width="30%">{{row.measurementPeriodDisplay}}</td>
          <td width="30%">
            <div *ngIf="row.attestationStatus == 'Attested'" style="color:green">{{row.attestationStatus}}</div>
            <div *ngIf="row.attestationStatus == 'Pending'" style="color:orange">{{row.attestationStatus}}</div>            
            <div *ngIf="row.attestationStatus == 'Past Due'" style="color:red">{{row.attestationStatus}}</div>
          </td>
          <td width="30%">{{row.attestationDate}}</td>
        </tr>
      </ng-template>
    </p-table>        
  </div>  
  <div class="report-footer" *ngIf="selectedCalProviderID && selectedCalProviderID != ''">
    <button class="btn btn-primary" type="button" (click)="onBuildReport()" [disabled]='!enableBuildReport'>Download</button>
  </div>
  <!--
  <div class="report-filelist" *ngIf="selectedCalProviderID != '' && fileList && fileList.length > 0">
    <p-dropdown name="providerfiles" id="providerfiles" [options]="fileList" [(ngModel)]="selectedFile">
    </p-dropdown>
    <button class="btn btn-primary" type="button" (click)="onBuildReport()" [disabled]='!enableBuildReport'>Download</button>
  </div>
  -->
</div>
<attestationbhi-dialog #attestation *ngIf="showAttestation" [measurementPeriods]="measurementPeriods" [selectedProvider]="selectedProvider" (submitAttestation)="onSubmitAttestation($event)" (cancelAttestation)="onCancelAttestation()"></attestationbhi-dialog>