<div class="reports-awp-member">
  <div class="report-search">
    <app-reports-member-search (onMemberSearch)="onMemberSearch($event)" #memberSearch></app-reports-member-search>
  </div>
  <div class="report-parameter">
    <p-table
      [value]="displayMembers"
      [paginator]="true"
      [rows]="10"
      [scrollable]="true"
      scrollHeight="400px"
      (onSort)="onSort($event)"
      [(first)]="first"
      [styleClass]="'p-datatable-striped p-datatable-lg'"
      >
      <ng-template pTemplate="header">
        <tr>
          <th width="5%"></th>
          <th width="35%" pSortableColumn="memberName">Name<div class="{{sortClass('memberName')}}"></div></th>
          <th width="20%" pSortableColumn="cin">Member ID<div class="{{sortClass('cin')}}"></div></th>
          <th width="20%" pSortableColumn="providerId">Provider ID<div class="{{sortClass('providerId')}}"></div></th>
          <th width="20%" pSortableColumn="status">Current Year Status<div class="{{sortClass('status')}}"></div></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td width="5%" class="check-column">
            <p-radioButton name="cin" [inputId]="row.cin" [value]="row" [(ngModel)]="selectedCin" (click)="onMemberSelected(row)"></p-radioButton>
          </td>
          <td width="35%">
            <div>{{row.memberName}}</div>
          </td>
          <td width="20%">
            <div>{{row.cin}}</div>
          </td>
          <td width="20%">
            <div>{{row.providerId}}</div>
          </td>
          <td width="20%">
            <div *ngIf="row.status == 'Submitted'" style="color:green">{{row.status}}</div>
            <div *ngIf="row.status == 'Pending'" style="color:red">{{row.status}}</div>                                    
          </td>          
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="report-measurementlabel" *ngIf="selectedMember != null">
    <label>Measurement Period for {{displayText}}</label>
  </div>
  <div class="report-filelist" *ngIf="selectedMember != null">
    <p-table tableStyleClass="table-data" #providerList
    [value]="awpList"
    [paginator]="true"
    [rows]="10"
    [scrollable]="true"
    scrollHeight="400px"
    (onSort)="onSort($event)">
      <ng-template pTemplate="header">
        <tr>
          <th width="15%">Period</th>
          <th width="15%">Signed</th>
          <th width="15%">Status</th>
          <th width="20%">Unsigned</th>
          <th width="15%">Action</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td width="15%"><div>{{row.period}}</div></td>
          <td width="15%"><div>{{row.signed ? "Yes" : "No"}}</div></td>
          <td width="15%">
            <div *ngIf="row.status == 'Submitted'" style="color:green">{{row.status}}</div>
            <div *ngIf="row.status == 'Pending'" style="color:red">{{pendingCaption(row)}}</div>            
          </td>
          <td width="20%"><div><a href='javascript:void(0);' (click)="download(row)">{{downloadCaption(row)}}</a></div></td>
          <td width="15%"><div><a href='javascript:void(0);' (click)="upload(row)">{{uploadCaption(row)}}</a></div></td>
        </tr>
      </ng-template>
    </p-table>    
  </div>
</div>
 <report-wellness-attachment-dialog #attachment *ngIf="showAttachment && selectedMember && selectedMemberAWPStatus && canShowAttachmentDialog" 
  [selectedMemberAWPStatus]="selectedMemberAWPStatus" 
  [selectedMember]="selectedMember"  
  (applyWellness)="onApplyWellness()" 
  (cancelWellness)="onCancelWellness()">
</report-wellness-attachment-dialog>
<report-wellness-details-dialog #attachment *ngIf="showAttachment && selectedMember && selectedMemberAWPStatus && canShowDetailsDialog"  
  [selectedMemberAWPStatus]="selectedMemberAWPStatus"
  [selectedMember]="selectedMember"
  [selectedProvider]="selectedProvider"
  (applyWellness)="onApplyWellness()" 
  (cancelWellness)="onCancelWellness()">
</report-wellness-details-dialog>
