<div class="user-prompt-container">
    <div class="header-icon">
        <img src="./assets/images/triangle-exclamation-icon.png" width="80" height="64">
    </div>
    <span class="header">User Role Will Change</span>
    <p class="content">If you change your user role you will lose the ability to modify your permissions.</p>
    <div class="buttons">
        <button type="button" id="btnPromptLOAContinue" class="btn btn-primary btn-agree" (click)="onContinue()">Continue</button>
    </div>
</div>
  