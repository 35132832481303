import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { HttpResponseHelper, AppConfig } from '@caloptima/portal-foundation';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, BehaviorSubject, ReplaySubject, Subject, of } from 'rxjs';

import { UserTypeMap } from './models/user-type-map';
import { UserStatus, UserType, PortalUser } from './models/portal-user';
import { UserPermission, UserPermissionsAggregate } from './models/user-permission';
import { UserAffiliationSearchRequest } from './models/requests/user-affiliation-search-request';
import { UserAffiliation } from './models/user-affiliation';
import { UserAffiliationDetailRequest } from './models/requests/user-affiliation-detail-request';
import { UserAffiliationDetail } from './models/responses/user-affiliation-detail';
import { PagedResponse } from './models/responses/paged-response';
import { PortalConfig } from '../portal-config';
import { UserExternalRequest } from './models/requests/user-external-request';
import { ProviderUserAffiliation } from './models/provider-user-affiliation';
import { UserCollections } from './models/responses/user-collections';
import { UserRequest } from './models/user-request';
import { FindUsersRequest } from '@caloptima/authentication';
import { publishReplay, refCount, catchError, map, distinctUntilChanged, take } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { UserAccountActions } from './models/requests/user-account-actions';
import { InternalUserRequest } from './models/requests/internal-user-request';
import { InternalUserDetails } from './models/internal-user-details';
import { UserRoleRequest } from './models/requests/user-role-request';
import { UserRolesAndPermissions } from './models/user-roles-and-permissions';
import { DefaultOrganizationRequest } from './models/requests/default-organization-request';
import { UserInfo } from './models/user-info';
import { UserCollectionPermission } from './models/user-collection-permission';
import { UpdatePermissionsRequest } from './models/requests/update-permissions-request';
import { AddInternalUserRequest } from './models/requests/add-internal-user-request';
import { UiUtility } from '../utils/ui-utility';
import { Alert } from './models/alert';
import { UserNotification } from './models/user-notification';
import { WidgetRequest } from './models/requests/widget-request';
import { WidgetData } from './models/widget-data';
import { DashboardContent } from './models/dashboard-content';
import { DashboardPlaceholderContent } from './models/dashboard-placeholder-content';
import { ReportType } from './models/reportype';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

export enum SortColumn {
  Name,
  CollectionName,
  StatusDate
};

@Injectable({
  providedIn: 'root'
})
export class UserAdminService {
  private baseProviderServiceUrl: string;
  private findUserPermissionsUrl: string;
  private findUserAffiliationsUrl: string;
  private findProviderUserAffiliationsUrl: string;
  private getUserAffiliationDetailUrl: string;
  private getExternalUsersUrl: string;
  private getUserCollectionsUrl: string;
  private getWidgetDataUrl: string;
  private getDashboardContentsUrl: string;
  private updateUserAccountActionsUrl: string;
  private getUserRoleAndPermissionsUrl: string;
  private updateDefaultUserProviderCollectionUrl: string;
  private updateInternalUserPermissionsUrl: string
  private searchInternalUserUrl: string;
  private getAlertUrl: string;
  private collectionID?: number;
  private isEnterpriseAdminFlag: boolean;
  private getInternalUserDetailsUrl: string;
  private internalUserRequest: InternalUserRequest = new InternalUserRequest();;
  private selectedUserItem: PortalUser = null;
  private selectedUserSubject$ = new BehaviorSubject<PortalUser>(this.selectedUserItem);
  private currentUser: string;
  public selectedUserChanged$ = this.selectedUserSubject$.asObservable();
  private isUserActionCompleted: boolean = false;
  private userActionCompletedSubject$ = new BehaviorSubject<boolean>(this.isUserActionCompleted);
  public userActionCompletedChanged$ = this.userActionCompletedSubject$.asObservable(); userActionCompleted;
  private internalUserDetails: PagedResponse<InternalUserDetails> = null;
  private internalUserDetailsSubject$ = new BehaviorSubject<PagedResponse<InternalUserDetails>>(this.internalUserDetails);
  public internalUserDetailsChanged$ = this.internalUserDetailsSubject$.asObservable()
    .pipe(
      distinctUntilChanged()
  );
  private dashboardContentSubject$ = new ReplaySubject<DashboardContent[]>();
  public dashboardContentChanged(): Observable<DashboardContent[]> {
    return this.dashboardContentSubject$.asObservable().pipe(take(1));
  }

  constructor(
    private authService: OAuthService,
    private http: HttpClient,
    private portalConfig: PortalConfig,
    private appConfig: AppConfig,
    private datePipe: DatePipe
  ) {
    this.collectionID = null;
    this.baseProviderServiceUrl = appConfig.getConfig('BaseProviderServicesApiUrl');
    if (this.baseProviderServiceUrl == null) {
      let config$ = appConfig.subscribe(() => {
        this.baseProviderServiceUrl = appConfig.getConfig(
          'BaseProviderServicesApiUrl'
        );
        config$.unsubscribe();
      });
    }
    this.getCurrentUserFromClaim();
  }

  private getCurrentUserFromClaim(): string {
    const claims = this.authService.getIdentityClaims();
    if (claims) {
      this.currentUser = claims['sub'];
    }

    return this.currentUser;
  }

  private checkUrls(): void {
    if (this.findUserPermissionsUrl == null) {
      const baseUrl = this.baseProviderServiceUrl + 'api/admin/';
      this.findUserPermissionsUrl = baseUrl + 'findUserPermissions';
      this.findUserAffiliationsUrl = baseUrl + 'findUserAffiliations';
      this.findProviderUserAffiliationsUrl = baseUrl + 'findProviderUserAffiliations';
      this.getUserAffiliationDetailUrl = baseUrl + 'getUseraffiliationDetail';
      this.getExternalUsersUrl = baseUrl + 'getExternalUsers';
      this.getUserCollectionsUrl = baseUrl + 'getUserCollections';
      this.updateUserAccountActionsUrl = baseUrl + 'updateUserAccountActions';
      this.getInternalUserDetailsUrl = baseUrl + 'getInternalUserDetails';
      this.getUserRoleAndPermissionsUrl = baseUrl + 'getUserRoleAndPermissions';
      this.updateDefaultUserProviderCollectionUrl = baseUrl + 'updateDefaultUserProviderCollection';
      this.searchInternalUserUrl = baseUrl + 'searchInternalUser';
      this.updateInternalUserPermissionsUrl = baseUrl + 'updateInternalUserPermissions';
      this.getAlertUrl = baseUrl + 'getAlerts';
      this.getWidgetDataUrl = baseUrl + 'getWidgetData';
      this.getDashboardContentsUrl = baseUrl + 'getDashboardContents';
    }
  }

  private mapAffiliationToPortalUser(response: PagedResponse<UserAffiliation>): PagedResponse<PortalUser> {
    let result: PagedResponse<PortalUser> = new PagedResponse<PortalUser>();
    result.totalItems = response.totalItems;
    result.items = [];
    response.items.forEach(affiliation => {
      let assignedEmails = affiliation.assignedToEmail.split(';');
      let assignees = affiliation.assignedToUserName.split(';');
      let user: PortalUser = new PortalUser();
      user.assignedtoEmail = assignedEmails.map(x => x.trim());
      user.assignedToUserName = assignees.map(x => x.trim());
      user.collectionName = affiliation.collectionName;
      user.createDate = new Date(affiliation.statusDate);
      user.email = affiliation.email;
      user.name = affiliation.name;
      user.providerCollectionID = affiliation.providerCollectionID;
      user.status = UserStatus[affiliation.accountStatus];
      user.suspenseId = affiliation.userSuspenseID;
      user.userID = affiliation.secureAuthUserName;
      user.userType = UserTypeMap.mapUserType(affiliation.isAdminFlag, affiliation.isEnterpriseUserFlag);
      result.items.push(user);
    });
    return result;
  }

  private mapSortColumn(sortColumn?: SortColumn): string {
    let value: string = 'Name';
    if (sortColumn != null) {
      if (sortColumn == SortColumn.CollectionName) {
        value = 'CollectionName';
      }
      else if (sortColumn == SortColumn.StatusDate) {
        value = 'StatusDate'
      }
    }
    return value;
  }

  public searchInternalUser(
    searchText: string,
    excludePortalUsers: boolean = true,
    excludeInactiveUsers: boolean = true ): Observable<UserInfo[]> {
    let request = new InternalUserRequest();
    request.searchText = searchText;
    request.ExcludeInactiveUsers = excludeInactiveUsers;
    request.ExcludePortalUsers = excludePortalUsers;
    this.checkUrls();
    try {
      return this.http.post<UserInfo[]>(this.searchInternalUserUrl, request, httpOptions)
      .pipe(
        publishReplay(1),
        refCount(),
        catchError(error => HttpResponseHelper.handleError(error))
      )
    }
    catch (ex) {
      // log error
    }
  }

  public findUsers(
    searchText: string,
    userType?: UserType,
    userStatus?: UserStatus,
    pageNumber?: number,
    pageSize?: number,
    sortColumn?: SortColumn,
    sortAscending?: boolean): Observable<PagedResponse<PortalUser>> {
    this.checkUrls();
    try {
      let request: UserAffiliationSearchRequest = new UserAffiliationSearchRequest();
      if (!this.isEnterpriseAdminFlag) {
        request.isEnterpriseUserFlag = false;
      }
      else if (userType != null) {
        if (userType === UserType.InternalUser) {
          request.isEnterpriseUserFlag = true;
        }
        else if (userType == UserType.ExternalUser) {
          request.isEnterpriseUserFlag = false;
        }
      }
      request.providerCollectionID = this.collectionID;
      request.accountStatus = UserStatus[userStatus];
      request.searchText = searchText;
      request.sortColumn = this.mapSortColumn(sortColumn);
      request.sortAscending = sortAscending;
      request.pageNumber = pageNumber;
      request.pageSize = pageSize;
      return this.http.post<PagedResponse<UserAffiliation>>(this.findUserAffiliationsUrl, request, httpOptions)
      .pipe(
        publishReplay(1),
        refCount(),
        map(response => this.mapAffiliationToPortalUser(response)),
        catchError(error => HttpResponseHelper.handleError(error))
      );
    }
    catch (ex) {
      // log error
    }
  }

  public findUserPermissions(): Observable<UserPermissionsAggregate> {
    this.checkUrls();
    try {
      let claims = this.authService.getIdentityClaims();
      if (!claims) {
        return null;
      }
      let url = this.findUserPermissionsUrl + "?request=" + claims['sub'];
      return this.http
        .get<UserPermissionsAggregate>(url, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        )
        .pipe(result => {
          let sub$ = result.subscribe(result => {
            if (result && result.permissions && result.permissions.length == 1) {
              if (result.permissions[0].providerCollectionID != 0) {
                this.collectionID = result.permissions[0].providerCollectionID;
              }
            }
            sub$.unsubscribe();
          });
          return result;
        })
    } catch (ex) {
      // log error
      return null;
    }
  }

  public getUserRoleAndPermissions(providerCollectionID: number, userID: string, isInternalUser: boolean): Observable<UserRolesAndPermissions> {
    this.checkUrls();
    try {
      let request: UserRoleRequest = new UserRoleRequest();
      request.username = this.currentUser;
      request.isInternalUser = isInternalUser;
      request.userID = userID;
      if (isInternalUser) {
        request.providerCollectionID = '0';
      }
      else {
        request.providerCollectionID = providerCollectionID.toString();
      }

      return this.http
        .post<UserRolesAndPermissions>(this.getUserRoleAndPermissionsUrl, request, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    } catch (ex) {
      // log error
      return null;
    }
  }

  public getUserAffiliationDetail(name: string, suspenseId: number): Observable<UserAffiliationDetail> {
    this.checkUrls();
    try {
      let request: UserAffiliationDetailRequest = new UserAffiliationDetailRequest();
      request.secureAuthUserName = name;
      request.suspenseId = suspenseId;
      return this.http
        .post<UserAffiliationDetail>(this.getUserAffiliationDetailUrl, request, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    } catch (ex) {
      // log error
      return null;
    }
  }

  public getProviderUsers(userRequest: UserExternalRequest): Observable<PagedResponse<PortalUser>> {
    this.checkUrls();
    try {
      return this.http
        .post<PagedResponse<PortalUser>>(this.getExternalUsersUrl, userRequest, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    } catch (ex) {
      // log error
      return null;
    }

  }

  public set selectedUser(user: PortalUser) {
    this.selectedUserItem = user;
    this.selectedUserSubject$.next(user);
  }

  public get selectedUser(): PortalUser {
    return this.selectedUserItem;
  }

  public findProviderUserAffiliations(
    searchText: string,
    userType?: UserType,
    userStatus?: UserStatus,
    pageNumber?: number,
    pageSize?: number,
    sortColumn?: SortColumn,
    sortAscending?: boolean): Observable<PagedResponse<ProviderUserAffiliation>> {
    this.checkUrls();
    try {
      let request: UserAffiliationSearchRequest = new UserAffiliationSearchRequest();
      if (!this.isEnterpriseAdminFlag) {
        request.isEnterpriseUserFlag = false;
      }
      else if (userType != null) {
        if (userType === UserType.InternalUser) {
          request.isEnterpriseUserFlag = true;
        }
        else if (userType == UserType.ExternalUser) {
          request.isEnterpriseUserFlag = false;
        }
      }
      request.accountStatus = UserStatus[userStatus];
      request.searchText = searchText;
      request.sortColumn = this.mapSortColumn(sortColumn);
      request.sortAscending = sortAscending;
      request.pageNumber = pageNumber;
      request.pageSize = pageSize;
      return this.http.post<PagedResponse<ProviderUserAffiliation>>(this.findProviderUserAffiliationsUrl, request, httpOptions)
      .pipe(
        publishReplay(1),
        refCount(),
        catchError(error => HttpResponseHelper.handleError(error))
      );
    }
    catch (ex) {
      // log error
    }
  }

  public mapProviderUserAffiliationToPortalUser(affiliation: ProviderUserAffiliation): PortalUser {
    // let result: PagedResponse<PortalUser> = new PagedResponse<PortalUser>();

      const user: PortalUser = new PortalUser();
      user.collectionName = affiliation.collectionName;
      user.createDate = new Date(affiliation.statusDate);
      user.email = affiliation.email;
      user.internalName = affiliation.secureAuthUserName;
      user.name = affiliation.name;
      user.providerCollectionID = affiliation.providerCollectionID;
      user.status = UserStatus[affiliation.accountStatus];
      user.suspenseId = affiliation.userSuspenseID;
      user.userID = affiliation.userID.toString();
      user.userType = UserTypeMap.mapUserType(affiliation.isAdminFlag, affiliation.isEnterpriseUserFlag);
      user.isEnterpriseUserFlag =affiliation.isEnterpriseUserFlag;
      user.statusDate = affiliation.accountStatusDate !== null ? this.datePipe.transform(affiliation.accountStatusDate, 'MM/dd/yyyy') : '';
      user.notesText = affiliation.notesText;

      return user;
  }

  public getUserCollections(userName: string): Observable<UserCollections> {
    this.checkUrls();
    try {
      let request: UserRequest = new UserRequest();
      request.secureAuthUserName = userName;
      return this.http.post<UserCollections>(this.getUserCollectionsUrl, request, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    }
    catch (ex) {
      // log error
    }

  }

  public getWidgetData(secureAuthUserName: string, collectionId: number, collectionName: string, currentPermissionCodes: string[], reportTypes: ReportType[]): Observable<WidgetData> {
    this.checkUrls();
    let request = new WidgetRequest();
    request.username = secureAuthUserName;
    request.CollectionId = collectionId;
    request.CollectionName = collectionName;
    request.CurrentUserPermissionCodes = currentPermissionCodes;
    request.ReportTypes = reportTypes;

    try {
      return this.http.post<WidgetData>(this.getWidgetDataUrl, request, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    }
    catch (ex) {
      // log error
    }
  }

  public getDashboardContents(): Observable<DashboardContent[]> {
    try {
      this.checkUrls();

      return this.http.post<DashboardContent[]>(this.getDashboardContentsUrl, {}, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          map(data => {
            this.dashboardContentSubject$.next(data);
            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public UpdateUserAccountActions(request: UserAccountActions): Observable<object> {
        this.checkUrls();
        try {
          return this.http.post<UserCollections>(this.updateUserAccountActionsUrl, request, httpOptions)
            .pipe(
              catchError(error => HttpResponseHelper.handleError(error))
            );
        }
        catch (ex) {
          // log error
        }
  }

  public updateUserActionCompleted(userActionCompleted: boolean):void {
    this.userActionCompleted = userActionCompleted;
    this.userActionCompletedSubject$.next(userActionCompleted);
  }


  public getInternalUserDetails(request: InternalUserRequest): Observable<PagedResponse<InternalUserDetails>> {
    this.checkUrls();
    try {
      this.internalUserRequest = request;

      return this.http.post<PagedResponse<InternalUserDetails>>(this.getInternalUserDetailsUrl, request, httpOptions)
        .pipe(
          map(data => {
            this.setInternalUserDetails(data);
            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          }));
    }
    catch (ex) {
      // log error
    }
  }

  public UpdateDefaultUserProviderCollection(request: DefaultOrganizationRequest): Observable<number> {
    this.checkUrls();
    try {

      return this.http.post<PagedResponse<InternalUserDetails>>(this.updateDefaultUserProviderCollectionUrl, request, httpOptions)
        .pipe(
          map(data => {
            return 1;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          }));
    }
    catch (ex) {
      // log error
    }
  }

  public getInternalUserRequest(): InternalUserRequest {
    return this.internalUserRequest;
  }

  public setInternalUserDetails(internalUserDetails: PagedResponse<InternalUserDetails>) {
    this.internalUserDetails = internalUserDetails;
    this.internalUserDetailsSubject$.next(internalUserDetails);
  }

  public clearInternalUserDetails() {
    this.internalUserDetails = null;
    this.internalUserDetailsSubject$.next(this.internalUserDetails);
  }

  public getAlerts(): Observable<Alert[]> {
    const claims = this.authService.getIdentityClaims();
    if (!claims) {
      return null;
    }
    return this.http.get<Alert[]>(this.getAlertUrl + '?request=' + claims['sub'], httpOptions)
    .pipe(
      publishReplay(1)
      , refCount()
      , catchError(error => HttpResponseHelper.handleError(error) ));
  }

   public updateInternalUserPermissions(
                                status: UserStatus,
                                userInfo: UserInfo,
                                permissions: UserCollectionPermission[],
                                roleID: number,
                                addUser: boolean,
                                notesText: string): Observable<object> {
    this.checkUrls();
    try {
      if (UiUtility.isNullUndefinedOrEmpty(this.currentUser)) {
        this.currentUser = this.getCurrentUserFromClaim();
      }
      const request: AddInternalUserRequest = new AddInternalUserRequest();
      request.userName = this.currentUser;
      request.secureAuthUserName = userInfo.userName;
      request.userPermissions = permissions;
      request.userStatus = status.toString();
      request.roleId = roleID;
      request.addUser = addUser;
      request.email = userInfo.email;
      request.firstName = userInfo.firstName;
      request.lastName = userInfo.lastName;
      request.isAdmin = true;
      request.notesText = notesText;

      return this.http
        .post(this.updateInternalUserPermissionsUrl, request, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    }
    catch (ex) {
      // Log error
      return null;
    }
  }
}
