<div class="eligibility-details">
  <div class="eligibility-header">
    <div class="header-margin">
      <i class="fa fa-user-circle fa-2x" aria-hidden="true" [ngClass]="mapCheckStatusToIcon()"
      [title]="mapCheckStatusToTooltip()"></i>
    </div>
    <div class="eligibility-check">
      <h4>Member is currently {{mapCheckStatusToTooltip() | lowercase | titlecase}}</h4>
    </div>
  </div>
  <div class="eligibility-history-button">
    <button type="button" class="btn btn-primary" (click)="showDialog()">
      <i class="fa fa-history fa-lg" aria-hidden="true"></i>
      Eligibility History
    </button>
  </div>
  <hr>
  <div class="space"></div>
  <div class="lob-label">Line of business:</div>
  <div class="lob-value">{{memberEligibility.lineOfBusinessDesc}}</div>
  <div class="hn-label">Health network:</div>
  <div class="hn-value">{{memberEligibility.healthNetworkPlanDesc}}</div>
  <div class="eligibility-date-label">Eligibility date:</div>
  <div class="eligibility-date-value">
    {{getDateRange(memberEligibility.healthNetworkPlanEffectiveDate, memberEligibility.healthNetworkPlanTermDate)}}
  </div>
  <div class="other-hn-label">Other health care insurance:</div>
  <div class="other-hn-value">{{memberEligibility.otherInsuranceName || "None"}}</div>
  <div class="pcp-id-label">PCP ID:</div>
  <div class="pcp-id-value">{{memberEligibility.pcpId}}</div>
  <div class="pcp-name-label">PCP name:</div>
  <div class="pcp-name-value">{{memberEligibility.pcpName}}</div>
  <div class="aid-category-label">AID category:</div>
  <div class="aid-category-value">{{memberEligibility.medicaidCatergoryName}}</div>
  <div class="aid-code-label">AID code:</div>
  <div class="aid-code-value">{{memberEligibility.medicaidDesc}}</div>
  <div class="ccs-label" *ngIf="memberEligibility.ccsInd">CCS:</div>
  <div class="css-value" *ngIf="memberEligibility.ccsInd">CCS Member from: {{getDateRange(memberEligibility.ccsEffectiveDate, memberEligibility.ccsTermDate)}}</div>
</div>
<p-dialog class="eligibility-history-dialog"
          header="Eligibility History"
          [responsive]="true"
          [(visible)]="display"
          [modal]="true"
          [style]="{'width':'825px', 'minWidth':'825px', position:'absolute'}">
    <app-member-eligibility-history [memberEligibilityList]="memberEligibilityList" [totalHistories] = "totalHistories"></app-member-eligibility-history>
</p-dialog>

