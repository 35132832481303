import { Component, inject } from '@angular/core';
import { OAuthService} from 'angular-oauth2-oidc';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  private oAuthService = inject(OAuthService);

  public get showFooter(): boolean {
    return this.oAuthService.hasValidAccessToken();
  }
}
