import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PortalUser } from '../../../services/models/portal-user';
import { BusyService } from '../../../services/busy.service';
import { CollectionService } from '../../../services/collection.service';
import { NgForm } from '@angular/forms';
import { SessionService } from '../../../services/session.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'remove-user-dialog',
  templateUrl: './remove-user-dialog.component.html',
  styleUrls: ['./remove-user-dialog.component.scss']
})
export class RemoveUserDialogComponent implements OnInit {
  public removalReason: string;
  public separationDate: Date = null;
  public today = new Date();

  @Input() public display: boolean;
  @Input() public user: PortalUser;
  @Output() userUpdated = new EventEmitter();

  constructor(
    private busy: BusyService,
    private collectionService: CollectionService,
    private sessionService: SessionService,
    private oAuthService: OAuthService
  ) {
    this.user = null;
    this.removalReason = null;
  }

  ngOnInit() {
  }

  public onShow(removeUserForm: NgForm): void {
    this.removalReason = '';
    this.separationDate = null;
    removeUserForm.reset();
  }

  public invalid(): boolean {
    return this.removalReason === '';
  }

  public onRemove(): void {
    this.busy.emit(true);
    const sub = this.collectionService.removeUserFromCollection(this.collectionService.selectedCollectionItem, this.user, this.removalReason, this.separationDate).subscribe(() => {
      sub.unsubscribe();
      const claims = this.oAuthService.getIdentityClaims();
      if (this.user.email === claims['email']) {
        this.sessionService.start();
      }
      this.userUpdated.emit({ action: 'Remove' });
      this.busy.emit(false);
    });
  }

  public onCancel(removeUserForm: NgForm): void {
    removeUserForm.reset();
    this.display = false;
    this.userUpdated.emit({ action: 'Cancel' });
  }
}
