import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationFactor } from '@caloptima/authentication';
import { ErrorStatusModel } from '../services/models/login.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public emailSubject$ = new BehaviorSubject('');
  public answerQuestionsSubject$ = new BehaviorSubject(false);
  public selectedFactorSubject$ = new BehaviorSubject(null);
  public selectedFactorPasscodeSubject$ = new BehaviorSubject(null);
  public errorMessageSubject$ = new BehaviorSubject(null);

  constructor() { }

  public clearEmail() {
    this.emailSubject$.next('');
  }

  public setEmail(email:string) {
    this.emailSubject$.next(email);
  }

  public clearSelectedFactor() {
    this.selectedFactorSubject$.next(null);
  }

  public setSelectedFactor(selectedFactor:AuthenticationFactor) {
    this.selectedFactorSubject$.next(selectedFactor);
  }

  public clearAnswerQuestion() {
    this.answerQuestionsSubject$.next(false);
  }

  public setAnswerQuestion() {
    this.answerQuestionsSubject$.next(true);
  }

  public clearPasscode() {
    this.selectedFactorPasscodeSubject$.next(null);
  }

  public setPasscode(passcode:string) {
    this.selectedFactorPasscodeSubject$.next(passcode);
  }

  public clearErrorMessage() {
    this.errorMessageSubject$.next(null);
  }

  public setErrorMessage(errorStatus:ErrorStatusModel) {
    this.errorMessageSubject$.next(errorStatus);
  }
}
