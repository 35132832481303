<div>
  <app-reports-type [reportType]="'iha'"></app-reports-type>
</div>
<div class="reports-pcpmembershipiha">
  <div class="report-alert" *ngIf="IsEnterpriseUser">
    <app-alert-info subjectMessage=" " uniqueID="reportsiha" contentMessage="Narrow your search using the known provider name or ID."></app-alert-info>
  </div>
  <div class="report-search" *ngIf="IsEnterpriseUser">
    <app-reports-provider-search (onProviderSearch)="onSearch($event)"></app-reports-provider-search>
  </div>      
  <div class="report-parameter">
    <p-table tableStyleClass="table-data" #providerList [value]="providers" [paginator]="false"
      selectionMode="multiple" [(selection)]="selected" [scrollable]="true" scrollHeight="315px" (onSort)="onSort($event)"  [styleClass]="'p-datatable-striped p-datatable-lg'">
      <ng-template pTemplate="header">
        <tr>
          <th width="10%" class="header-check-column">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th width="30%" pSortableColumn="name" class="header-name-column">Name<div class="{{sortClass('name')}}"></div></th>
          <th width="20%" pSortableColumn="calProviderID" class="header-provider-column">Provider ID<div class="{{sortClass('calProviderID')}}"></div></th>
          <th width="20%" pSortableColumn="pcpMemberCount" class="header-pcpmembercount-column">Member Count<div class="{{sortClass('pcpMemberCount')}}"></div></th>
          <th width="30%" pSortableColumn="groupName" class="header-group-column">Medical Group<div class="{{sortClass('groupName')}}"></div></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td width="10%" class="check-column">
            <p-tableCheckbox [value]="row" (click)="$event.stopPropagation()"></p-tableCheckbox>
          </td>
          <td width="30%" class="name-column">
            <div>{{row.name}}</div>
          </td>
          <td width="20%" class="provider-column">
            <div>{{row.calProviderID}}</div>
          </td>
          <td width="20%" class="pcpmembercount-column">
            <div>{{row.pcpMemberCount}}</div>
          </td>
          <td width="30%" class="group-column">
            <div>{{row.groupName}}</div>
          </td>          
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <div class="report-countsummary">
          <span>
            Total Selected Members : {{totalCount}}.
          </span>
          <span *ngIf="showThresholdAlert" style="color: red;">
            &nbsp;Too many members selected ({{threshold}}) MAX.
          </span>
        </div>
        <div class="report-summary">
          <button class="btn btn-primary" type="button" (click)="onBuildReport()" tabindex="2" [disabled]='!enableBuildReport || selected.length == 0 || showThresholdAlert'>Get IHA Report</button>
        </div>          
      </ng-template>      
    </p-table>
  </div>
</div>