<div class="members">
  <div class="members-lookup" *ngIf="!showFaceSheet">
    <div class="member-search-header">
      <div class="members-title">
        <h4>Member Lookup</h4>
      </div>
    </div>
    <div class="member-search">
      <app-member-search></app-member-search>
    </div>
    <div class="member-results">
      <app-member-results (memberSelected)="onSelectedMember($event)"></app-member-results>
    </div>
  </div>
  <div class="members-facesheet" *ngIf="showFaceSheet">
    <form #facesheetForm="ngForm" class="member-facesheet-header" (ngSubmit)="onSubmit(facesheetForm)">
      <div class="members-title">
        <h4>Facesheet</h4>
      </div>
      <div class="member-id-text">Member ID:</div>
      <input class="member-search-input form-control" #memberSearchInput type="text" placeholder="Search" minlength="9"
        maxlength="11" pattern=".{9,11}" required autocomplete="off" [(ngModel)]="memberId" name="memberSearchInput">
      <div class="member-facesheet-close caloptima-close" (click)="onCloseFaceSheet()">
        <i class="fa fa-times fa-facesheet-close" aria-hidden="true"></i>
      </div>
    </form>
    <div class="member-demographics">
      <app-member-demographics></app-member-demographics>
    </div>
    <div class="member-details">
      <app-member-details (isLoadingChange)=isLoadingChange($event)></app-member-details>
    </div>
  </div>
</div>
<p-toast [style]="{marginTop: '185px'}" position="top-right"></p-toast>
