import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { ConfirmationService, MessageService } from 'primeng/api';

import { CollectionService } from '../../../services/collection.service';
import { BusyService } from '../../../services/busy.service';
import { SessionService } from '../../../services/session.service';
import { CollectionListItem } from '../../../services/models/collection-list-item';
import { CollectionDetail } from '../../../services/models/collection-detail';
import { CollectionType } from '../../../services/models/collection-type';
import { ProviderDetail } from '../../../services/models/provider-detail';
import { PagerClasses } from '../../../services/models/pager-classes';
import { Messages } from '../../../app.messages';
import { ActivatedRoute } from '@angular/router';
import { ProviderTaxDetails } from '../../../services/models/provider-tax-details';
import { ProviderGroupDetails } from '../../../services/models/provider-group-details';
import { UiUtility } from 'src/app/utils/ui-utility';

@Component({
  selector: 'collection-manager-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.scss']
})
export class ProvidersComponent implements OnInit, OnDestroy {
  public collectionItem: CollectionListItem;

  public totalProviders = 0;
  public totalTins = 0;
  public totalGroups = 0;

  public providerPageNumber: number;
  public tinPageNumber: number;
  public groupPageNumber: number;

  private sortColumnProvider: string;
  private sortColumnTin: string;
  private sortColumnGroup: string;

  private sortAscendingProvider: boolean;
  private sortAscendingTin: boolean;
  private sortAscendingGroup: boolean;
  private providerClientSearch: boolean = false;

  private allProviders: ProviderDetail[];
  private allTins: ProviderTaxDetails[];
  private allGroups: ProviderGroupDetails[]

  public displayproviders: ProviderDetail[];
  public filteredproviders: ProviderDetail[];
  public displaytins: ProviderTaxDetails[];
  public displaygroups : ProviderGroupDetails[];

  public cssClasses = PagerClasses.cssClasses;
  public rowsPerPage: number;
  public providerToRemove: string;
  public selectedProvider: ProviderDetail;
  public selectedTin: ProviderTaxDetails;
  public selectedGroup : ProviderGroupDetails;
  public showProviderInfo = false;
  public collectionType: CollectionType;
  public collectionId: number;
  public tin: string;
  public groupId: string;
  public groupView:number;
  public tinView:number;
  public addEnabled: boolean;
  private collectionItemSubscription: Subscription;  
  public isNewBuildCollection: boolean;
  public get DisplaySearchProviderView(): boolean {
    return ((this.collectionType === CollectionType.MultipleTIN && this.tinView.toString() === '2') || (this.collectionType === CollectionType.MultipleGroupId && this.groupView === 2));
  }

  @ViewChild('addProviders', {static: true}) addProviders: any;

  constructor(
    private route: ActivatedRoute,
    private collectionService: CollectionService,
    private messageService: MessageService,
    private busy: BusyService,
    private confirm: ConfirmationService,
    public session: SessionService,
    private messages: Messages
  ) {
    this.collectionId = 0;
    this.rowsPerPage = 10;
    this.providerPageNumber = 1;
    this.tinPageNumber = 1;
    this.groupPageNumber = 1;
    this.sortColumnProvider = 'name';
    this.sortColumnTin = "providerTaxId";
    this.sortColumnGroup = "groupName";
    this.sortAscendingProvider = true;
    this.sortAscendingTin = true;
    this.sortAscendingGroup = true;
    this.allProviders = [];
    this.filteredproviders = [];
    this.allTins = [];
    this.allGroups = [];
    this.tinView = 1;
    this.groupView = 1;
    this.addEnabled = true;
    this.providerClientSearch = false;
  }

  ngOnInit() {
    this.isNewBuildCollection = this.route.snapshot.params['id'] && this.route.snapshot.params['id'] === 'new';
    this.collectionItemSubscription = this.collectionService.collectionListItemChanged$
      .subscribe(data => {
        this.collectionItem = data;
        if (this.collectionItem != null) {
          this.collectionType = data.collectionType;
          this.collectionId = data.id;
          this.groupId = data.groupId;
          this.tin = data.tin;
          this.tinView = 1;
          this.groupView = 1;
          this.providerClientSearch = false;
          
          if (this.collectionItem.id != null) {
            this.addEnabled = true;
            this.busy.emit(true);
            this.getProvidersTinsGroups();
          }
          else {
            if (this.collectionItem.tins.length > 0) {
              this.addEnabled = false;
            }
            this.getProvidersTinsGroupsForNew();
          }
        }
        return data;
      });
  }

  ngOnDestroy() {
    if (this.collectionItemSubscription != null) {
      this.collectionItemSubscription.unsubscribe();
    }
    this.collectionService.setCurrentCollection(null);
  }

  private isBusy(busy: boolean): void {
    this.busy.emit(busy);
  }

  private updateProviders(providers: ProviderDetail[]): void {
    if (providers == null) {
      providers = [];
    }
    this.totalProviders = providers.length;
    this.allProviders = providers;
  }

  private updateTins(tins: ProviderTaxDetails[]): void {
    if(tins == null) {
      tins = [];
    }

    this.totalTins = tins.length;
    this.allTins = tins;
  }

  private updateGroups(groups: ProviderGroupDetails[]) : void {
    if(groups == null) {
      groups = [];
    }

    this.totalGroups = groups.length;
    this.allGroups = groups;
  }

  private showProviders(providers: ProviderDetail[]): void {
    this.updateProviders(providers);
    this.onPage(1);
    this.isBusy(false);
  }

  private showTins(tins: ProviderTaxDetails[]): void {
    this.updateTins(tins);
    this.onTinPage(1);
    this.isBusy(false);
  }

  private showGroups(groups: ProviderGroupDetails[]): void {
    this.updateGroups(groups);
    this.onGroupPage(1);
    this.isBusy(false);
  }

  private getProvidersTinsGroups(): void {
    let sub = this.collectionService.getCollectionDetail(this.collectionItem.id).subscribe((detail) => {    

      this.showProviders(detail.providers.sort((a, b) => {
        return a.name.localeCompare(b.name);
      }));

      this.showTins(detail.tins.sort((a, b) => {
        return a.providerTaxId.localeCompare(b.providerTaxId);
      }));

      this.showGroups(detail.groups.sort((a, b) => {
        return a.providerGroupName.localeCompare(b.providerGroupName);
      }));

      if (sub != null) {
        sub.unsubscribe();
      }
    });
  }

  private getProvidersTinsGroupsForNew(): void {

    let keys: string[] = [];
    let type: CollectionType;

    if(this.collectionItem.collectionType == CollectionType.MultipleTIN) {
      this.collectionItem.tins.forEach(x=>{
        keys.push(x.providerTaxId);
      });   
      type = CollectionType.TIN;   
    }
    else if ( this.collectionItem.collectionType == CollectionType.MultipleGroupId) {
      this.collectionItem.groups.forEach(x=>{
        keys.push(x.providerGroupId);
      });

      type = CollectionType.Group;
    }

    if(type) {
      let sub = this.collectionService.findMultipleCollectionProviders(type, keys, null).subscribe((result)=> {
          this.showProviders(result.sort((a, b) => {
            return a.name.localeCompare(b.name);
          }));
          
          if(this.collectionItem.collectionType == CollectionType.MultipleTIN) {
            this.showTins(this.collectionItem.tins.sort((a, b) => {
              return a.providerTaxId.localeCompare(b.providerTaxId);
            }));
          }
          else if(this.collectionItem.collectionType == CollectionType.MultipleGroupId) {
            this.showGroups(this.collectionItem.groups.sort((a, b) => {
              return a.providerGroupId.localeCompare(b.providerGroupId);
            }));
          }

          if (sub != null) {
            sub.unsubscribe();
          }
        });
    }
  }

  private sortProviders(sortColumn: string, sortAscending: boolean): void {
    if (this.providerClientSearch) {
      this.filteredproviders.sort((data1, data2) => {
        return this.sortProviderLogic(data1, data2, sortColumn, sortAscending);
      });
    } else {
      this.allProviders.sort((data1, data2) => {
        return this.sortProviderLogic(data1, data2, sortColumn, sortAscending);
      });
    }
  }

  private sortTins(sortColumn: string, sortAscending: boolean): void {
    this.allTins.sort((data1, data2) => {
      let value1 = data1[sortColumn];
      let value2 = data2[sortColumn];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }

      return (sortAscending ? result : -result);
    });
  }

  private sortGroups(sortColumn: string, sortAscending: boolean): void {
    
    this.allGroups.sort((data1, data2) => {
      let value1 = data1[sortColumn];
      let value2 = data2[sortColumn];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }

      return (sortAscending ? result : -result);
    });
  }

  public get collectionValid(): boolean {
    return this.allProviders != null && this.allProviders.length > 0;
  }

  public get collectionModified(): boolean {
    return this.collectionService.isEditCollectionModified();
  }

  public get isLastProvider(): boolean {
    return this.collectionService.providers.length == 1;
  }

  public renameCollection(): void {
    this.collectionItem = this.collectionService.selectedCollectionItem;
    let sub = this.saveCollection(true).subscribe(() => {
      if (sub != null) {
        sub.unsubscribe();
      }
    });
  }

  public get canDisplayProvider() : boolean {
    if(this.collectionItem != null) {

      if(this.collectionType == CollectionType.MultipleTIN )
        return this.tinView == 2;
      else if ( this.collectionType == CollectionType.MultipleGroupId)
        return this.groupView == 2;
      else
        return true;
    } 
    else {
        return false;
    }      
  }

  public get canRemoveTinGroup() : boolean {
    return this.session.isEnterpriseAdmin;
  }

  public saveCollection(rename: boolean): Observable<number> {
    let subject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    if (this.allProviders.length === 0) {
      this.isBusy(false);
      this.messageService.add({ severity: this.messages.SeverityError, detail: this.messages.MessageNoCollectionProviders, life: this.messages.ToasterLife });
    }
    else {
      let detail: CollectionDetail = new CollectionDetail();
      detail.id = this.collectionItem.id;
      detail.name = this.collectionItem.name;
      detail.collectionType = this.collectionType;
      detail.groupId = this.groupId;
      detail.tin = this.tin;
      
      if(this.collectionType == CollectionType.MultipleTIN)
        detail.tins = this.allTins;
      else if(this.collectionType == CollectionType.MultipleGroupId)
        detail.groups = this.allGroups;

      if (!rename) {
        if(this.collectionType == CollectionType.Manual)
          detail.providers = this.allProviders;
      }
      this.isBusy(true);
      let sub = this.collectionService.createOrUpdateCollection(detail).subscribe(
        result => {
          if (typeof result === 'number') {

            if(this.collectionItem.id == 0) {
              console.log("Changing the collection Id from 0 to ", result);
              this.collectionItem.id = result;
            }

            this.collectionService.setCurrentCollection(this.collectionItem);
            subject.next(result);
            this.getProvidersTinsGroups();
          }
          else {
            this.isBusy(false);
            this.messageService.add({ severity: this.messages.SeverityError, detail: result, life: this.messages.ToasterLife });
          }
        },
        (error) => {
          this.isBusy(false);
          this.messageService.add({ severity: this.messages.SeverityError, detail: "TIN/Group ID mapped to another collection.", life: this.messages.ToasterLife });
        },  
        () => {
          if (sub != null) {
            sub.unsubscribe();
          }
        });
    }
    return subject.asObservable();
  }

  public sortClassProvider(column: string): string {
    let cls: string;
    if (column == this.sortColumnProvider) {
      cls = this.sortAscendingProvider ? "fa fa-chevron-up" : "fa fa-chevron-down";
    }
    else {
      cls = "fa fa-chevron-up";
    }
    return cls;
  }

  public sortClassTin(column: string): string {
    let cls: string;
    if (column == this.sortColumnTin) {
      cls = this.sortAscendingTin ? "fa fa-chevron-up" : "fa fa-chevron-down";
    }
    else {
      cls = "fa fa-chevron-up";
    }
    return cls;
  }

  public sortClassGroup(column: string): string {
    let cls: string;
    if (column == this.sortColumnGroup) {
      cls = this.sortAscendingGroup ? "fa fa-chevron-up" : "fa fa-chevron-down";
    }
    else {
      cls = "fa fa-chevron-up";
    }
    return cls;
  }

  public onSort(e: any): void {
    this.sortProviders(e.sortField, e.sortOrder == 1);
    this.sortAscendingProvider = e.sortOrder == 1;
    this.sortColumnProvider = e.sortField;
    this.onPage(this.providerPageNumber);
  }

  public onSortTin(e: any) : void {
    this.sortTins(e.sortField, e.sortOrder == 1);
    this.sortAscendingTin = e.sortOrder == 1;
    this.sortColumnTin = e.sortField;
    this.onTinPage(this.tinPageNumber);
  }

  public onSortGroup(e: any) : void {
    this.sortGroups(e.sortField, e.sortOrder == 1);
    this.sortAscendingGroup = e.sortOrder == 1;
    this.sortColumnGroup = e.sortField;
    this.onGroupPage(this.groupPageNumber);
  }

  public onFindManual(): void {
    this.addProviders.show();
  }

  onCollectionTypeChanged(): void {

    let ret:any = this.collectionService.changeCollectionType(this.collectionType)
    this.showProviders(ret.providers);
    this.showTins(ret.tins);
    this.showGroups(ret.groups);
  }

  public onSaveCollection(): void {

    if(this.allProviders.length == 0) {
      alert ('Please add providers');
      return;
    }
    
    let sub = this.saveCollection(false).subscribe(collectionId => {
      if (this.collectionItem.id == null || this.collectionItem.id === 0) {
        this.collectionItem.id = collectionId;
      }
      this.collectionService.refreshList();
      this.collectionService.setCurrentCollection(this.collectionItem);
      if (sub != null) {
        sub.unsubscribe();
      }
    });
  }

  public onRemoveProvider(row: ProviderDetail): void {
    this.confirm.confirm({
      message: `Are you sure you want to remove "${row.name}"?`,
      accept: () => {
        this.updateProviders(this.collectionService.removeProvider(row));
        let sub = this.saveCollection(false).subscribe(result => {
          if (sub != null) {
            sub.unsubscribe;
          }
        });
      },
      acceptLabel: this.messages.ButtonRemove,
      rejectLabel: this.messages.ButtonNo
    });
  }

  public onRemoveTin(row: ProviderTaxDetails): void {
    this.confirm.confirm({
      message: `Are you sure you want to remove TIN: "${row.providerTaxId}"?`,
      accept: () => {

        this.collectionService.findCollectionProviders(CollectionType.TIN, row.providerTaxId, null).subscribe(results => 
          {
            var ret = this.collectionService.removeTin(row, results);

            this.showTins(ret.tins);
            this.showProviders(ret.providers);
          });
      },
      acceptLabel: this.messages.ButtonRemove,
      rejectLabel: this.messages.ButtonNo
    });
  }

  public onRemoveGroup(row: ProviderGroupDetails): void {
    this.confirm.confirm({
      message: `Are you sure you want to remove Group: "${row.providerGroupId}"?`,
      accept: () => {

        this.collectionService.findCollectionProviders(CollectionType.Group, row.providerGroupId, null).subscribe(results =>
          {
            var ret = this.collectionService.removeGroup(row, results);

            this.showGroups(ret.groups);
            this.showProviders(ret.providers);
          });
      },
      acceptLabel: this.messages.ButtonRemove,
      rejectLabel: this.messages.ButtonNo
    });
  }

  public onAddTins(tins: ProviderTaxDetails[]): void {
    this.updateTins(this.collectionService.addTins(tins));
    this.sortTins(this.sortColumnTin, this.sortAscendingTin);
    this.onTinPage(this.tinPageNumber);
  }

  public onAddGroups(groups: ProviderGroupDetails[]) : void {
    this.updateGroups(this.collectionService.addGroups(groups));
    this.sortGroups(this.sortColumnGroup, this.sortAscendingGroup);
    this.onGroupPage(this.groupPageNumber);
  }

  public onAddProviders(providers: ProviderDetail[]): void {    
    this.updateProviders(this.collectionService.addProviders(providers));
    this.sortProviders(this.sortColumnProvider, this.sortAscendingProvider);
    this.onPage(this.providerPageNumber);
  }

  public onPage(page: number) {
    this.providerPageNumber = page;
    const start = (page - 1) * this.rowsPerPage;
    let end = start + this.rowsPerPage;
    if (end > this.allProviders.length) {
      end = this.allProviders.length;
    }
    // Select the subset of records to display in the grid.
    this.displayproviders =  (this.providerClientSearch) 
                                ? this.filteredproviders.slice(start, end) 
                                : this.allProviders.slice(start, end);
  }

  public onTinPage(page: number) {
    this.tinPageNumber = page; 
    const start = (page - 1) * this.rowsPerPage;
    let end = start + this.rowsPerPage;
    if (end > this.allTins.length) {
      end = this.allTins.length;
    }
    // Select the subset of records to display in the grid.
    this.displaytins = this.allTins.slice(start, end);
  }

  public onGroupPage(page: number) {
    this.groupPageNumber = page; 
    const start = (page - 1) * this.rowsPerPage;
    let end = start + this.rowsPerPage;
    if (end > this.allGroups.length) {
      end = this.allGroups.length;
    }
    // Select the subset of records to display in the grid.
    this.displaygroups = this.allGroups.slice(start, end);
  }
 
  public showProviderInfoHandler(event, row: ProviderDetail): void {
    this.selectedProvider = row;
    // call service for data object
    let sub = this.collectionService.GetProvider(row.providerID)
      .subscribe(data => {
        this.selectedProvider = data;
        // call dialog
        this.showDialog();
        if (sub != null) {
          sub.unsubscribe();
        }
        return data;
      },
        err => {
          if (sub != null) {
            sub.unsubscribe();
          }
          return null;
        });
  }

  public showDialog() {
    this.showProviderInfo = true;
  }

  public onCollectionProviderSearch(search: any) {
    if (search && (search.providerName || search.providerId || search.providerTin)) {
      if (!UiUtility.isNullUndefinedOrEmpty(search.providerName) && !UiUtility.isNullUndefinedOrEmpty(search.providerId) && !UiUtility.isNullUndefinedOrEmpty(search.providerTin)) {
        this.filteredproviders = this.allProviders
          .filter(p => p.name.toLowerCase().indexOf(search.providerName.trim().toLowerCase()) >= 0 && 
                       p.calProviderID.toLowerCase().indexOf(search.providerId.trim().toLowerCase()) >= 0 && 
                       p.taxId.toLowerCase().indexOf(search.providerTin.trim().toLowerCase()) >= 0);
      }
      else if (!UiUtility.isNullUndefinedOrEmpty(search.providerName) && !UiUtility.isNullUndefinedOrEmpty(search.providerId)) {
        this.filteredproviders = this.allProviders
          .filter(p => p.name.toLowerCase().indexOf(search.providerName.trim().toLowerCase()) >= 0 && 
                       p.calProviderID.toLowerCase().indexOf(search.providerId.trim().toLowerCase()) >= 0);
      }
      else if (!UiUtility.isNullUndefinedOrEmpty(search.providerName) && !UiUtility.isNullUndefinedOrEmpty(search.providerTin)) {
        this.filteredproviders = this.allProviders
          .filter(p => p.name.toLowerCase().indexOf(search.providerName.trim().toLowerCase()) >= 0 && 
                       p.taxId.toLowerCase().indexOf(search.providerTin.trim().toLowerCase()) >= 0);
      }
      else if (!UiUtility.isNullUndefinedOrEmpty(search.providerId) && !UiUtility.isNullUndefinedOrEmpty(search.providerTin)) {
        this.filteredproviders = this.allProviders
          .filter(p => p.calProviderID.toLowerCase().indexOf(search.providerId.trim().toLowerCase()) >= 0 && 
                       p.taxId.toLowerCase().indexOf(search.providerTin.trim().toLowerCase()) >= 0);
      }
      else if (!UiUtility.isNullUndefinedOrEmpty(search.providerId)) {
        this.filteredproviders = this.allProviders
          .filter(p => p.calProviderID.toLowerCase().indexOf(search.providerId.trim().toLowerCase()) >= 0);
      }
      else if (!UiUtility.isNullUndefinedOrEmpty(search.providerName)) {
        this.filteredproviders = this.allProviders
          .filter(p => p.name.toLowerCase().indexOf(search.providerName.trim().toLowerCase()) >= 0);
      }  
      else {
        this.filteredproviders = this.allProviders
          .filter(p => p.taxId.toLowerCase().indexOf(search.providerTin.trim().toLowerCase()) >= 0);
      }


      this.totalProviders = this.filteredproviders.length;
      this.providerClientSearch = true;
      this.onPage(1);
    }
    else {
      this.filteredproviders = [];
      this.totalProviders = this.allProviders.length;
      this.providerClientSearch = false;
      this.onPage(1);
    }
    return false;
  }

  onDialogClose(event) {
    let e = event;
    this.showProviderInfo = false;
  }

  private sortProviderLogic(data1: any, data2: any, sortColumn: string, sortAscending: boolean) {
      let value1 = data1[sortColumn];
      let value2 = data2[sortColumn];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }

      return (sortAscending ? result : -result);
  }
}
