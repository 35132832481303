import { Component, OnInit } from '@angular/core';
import { CollectionService } from '../../services/collection.service';
import { ReportsService } from '../../services/reports.service';
import { SessionService } from '../../services/session.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-reports-member-eligibility-roster',
  templateUrl: './reports-member-eligibility-roster.component.html',
  styleUrls: ['./reports-member-eligibility-roster.component.scss']
})
export class ReportsMemberEligibilityRosterComponent implements OnInit {

  constructor(
    private sessionService: SessionService, 
    private collectionService: CollectionService,
    private reportsService: ReportsService) {

  }

  ngOnInit() {
    
  }

  public onBuildReport() {

  }

  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob([buffer], { type: fileType });
    saveAs(data, fileName);
  }
}
