<p-dialog header='{{headerMessage}}' styleClass="claim-lookup-dialog" [style]="{'width': width}" [(visible)]="display" [modal]="true" (onHide)="onClose()">
  <!--
  <p-header>
    <h4><i [ngClass]="iconClass"></i> &nbsp;<span>{{headerMessage}}</span></h4>
  </p-header> 
  -->
  <div class="confirm-container">
    <div class="confirm-message" id="confirm-message">
    </div>
    <div class="confirm-close">
      <button type="button" class="btn btn-close" (click)="onClose()">
        Close
      </button>
    </div>
  </div>
</p-dialog>
