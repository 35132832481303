import { Component, OnInit, Input, Inject, AfterViewInit, SecurityContext, AfterViewChecked } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-alert-danger',
  templateUrl: './alert-danger.component.html',
  styleUrls: ['./alert-danger.component.scss']
})
export class AlertDangerComponent implements OnInit, AfterViewChecked {
  public displayAlert = false;
  public subjectDangerId: string;
  public contentDangerId: string;

  @Input() subjectMessage: string;
  @Input() contentMessage: string;
  @Input() uniqueID: string;

  constructor(@Inject(DOCUMENT) private document: Document,
  private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.displayAlert = this.subjectMessage.length > 0 || this.contentMessage.length > 0;
    this.subjectDangerId = (!this.uniqueID || this.uniqueID.trim().length === 0) ? "subject-danger" : "subject-danger-" + this.uniqueID;
    this.contentDangerId = (!this.uniqueID || this.uniqueID.trim().length === 0) ? "content-danger" : "content-danger-" + this.uniqueID;
  }

  ngAfterViewChecked(): void {
    this.setContentMessages();
  }

  public setContentMessages() {
    let elSubject = this.document.getElementById(this.subjectDangerId);
    if(elSubject) {
      if (this.subjectMessage && this.subjectMessage.trim().length > 0) {
        elSubject.innerHTML = this.subjectMessage;
        elSubject.style.display = 'inline';
      } else {
        elSubject.style.display = 'none';
      }
    }
    
    let elContent = this.document.getElementById(this.contentDangerId);
    if(elContent) {
      if(this.contentMessage && this.contentMessage.trim().length > 0) 
        elContent.innerHTML = this.sanitizer.sanitize(SecurityContext.HTML,this.contentMessage);
    }    
  }
}
