<div class="hif-provider-container">
  <div class="hif-provider-provider-container" *ngIf='!displayMembers'>
    <div class="hif-provider-provider-search">
      <app-reports-provider-search (onProviderSearch)="onProviderSearch($event)" #providerSearch></app-reports-provider-search>
    </div>
    <div class="hif-provider-provider-results">
      <p-table
          [value]="displayedProviders"
          [paginator]="true"
          [rows]="10"
          [scrollable]="true"
          [(selection)]="selected"
          scrollHeight="400px"
          [responsive]="true"
          selectionMode="multiple"
          (onSort)="onSort($event)"
          [styleClass]="'p-datatable-striped p-datatable-lg'">
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="{{col.field}}" width="{{col.width}}" class="{{col.classHeader}}" *ngFor="let col of columns">
              <div *ngIf="col.header === ''" class="hif-provider-provider-header-checkbox">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </div>
              <div *ngIf="col.header !== ''">
                {{col.header}}
                <div *ngIf="col.sort" class="{{sortClass(col.field)}}">
                </div>
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-provider>
          <tr>
            <td width="{{col.width}}" class="{{col.classContent}}" *ngFor="let col of columns">
              <div *ngIf="col.header === ''">
                <p-tableCheckbox [value]="provider" (click)="$event.stopPropagation()"></p-tableCheckbox>
              </div>
              <div *ngIf="col.header === 'Name'">
                <span><a href='javascript:void(0);' (click)="onProviderSelected(provider)">{{provider[col.field]}}</a></span>
              </div>              
              <div *ngIf="col.header === 'Provider ID' || col.header === 'TIN'">
                <span>{{provider[col.field]}}</span>
              </div>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="hif-provider-provider-footer">
      <button class="btn btn-primary" [disabled]="selected.length == 0" type="button" (click)="onDownloadList()">Download List</button>
    </div>
   </div>
   <div class="hif-provider-member-container" *ngIf='displayMembers'>
    <div class="hif-provider-member-container-header" *ngIf="selectedProvider">
      <button class="btn btn-primary btn-back" (click)="backToProvider()">
        <i class="fa fa-arrow-left" aria-hidden="true"></i>
        <span>Back</span>
      </button>
      <p class="provider-name">{{selectedProvider.name}}</p>
    </div>
    <div class="hif-provider-member-container">
      <app-hif-member #hifmemberComponent></app-hif-member>
    </div>
  </div>
</div>
