import { RosterType } from "../request-status";

export class memberRosterRequest {

    public memberRosterRequest(){
        
    }

    public collectionId: number;
    public userName: string;
    public rosterType: RosterType;
    public memberCin: string;
    public relationshipDate: Date;
    public providerIds:string[] = [];
}


export class providerMemberRosterUpdateRequest {

    public providerMemberRosterUpdateRequest(){
        
    }

    public userName: string;
    public rosterType: RosterType;
    public memberCin: string;
    public relationshipDate: Date;
    public addedProviderIds:string[] = [];
    public removedProviderIds:string[] = [];
}
