import { Injectable } from '@angular/core';
import { AppConfig } from '@caloptima/portal-foundation';
import { UIConfig } from '@caloptima/portal-ui-components';

@Injectable()
export class PortalConfig extends UIConfig {
  constructor(private portalConfig: AppConfig) {
    super(portalConfig);
    this.portalConfig.subscribe(() => {
      this.AccessTokenName = this.portalConfig.getConfig('AccessTokenName');
      this.LoginUrl = this.portalConfig.getConfig('LoginUrl');
      this.RedirectUri = this.portalConfig.getConfig('RegionRedirectUri');
      this.ClientId = this.portalConfig.getConfig('RegionClientId');
      this.Issuer = this.portalConfig.getConfig('Issuer');
      this.Oidc = this.portalConfig.getConfig('Oidc');
      this.LogoutUrl = this.portalConfig.getConfig('LogoutUrl');
      this.BaseMemberPortalUrl = this.portalConfig.getConfig('BaseMemberPortalUrl');
      this.portalConfig.loadConfiguration(this);
    });
    this.LoginLocalName = 'loginUser';
  }
  public AuthorizeUserComponent: string;
  public BaseMemberPortalUrl: string;
  public AccessTokenName: string;
  public LoginUrl: string;
  public RedirectUri: string;
  public ClientId: string;
  public Issuer: string;
  public Oidc: boolean;
  public LogoutUrl: string;
  public LoginLocalName: string;
}
