import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { MemberInfoService } from 'src/app/services/member-info.service';
import { PortalMember } from 'src/app/services/models/portal-resource';

@Component({
  selector: 'app-reports-roster-member-dialog',
  templateUrl: './reports-roster-member-dialog.component.html',
  styleUrl: './reports-roster-member-dialog.component.scss'
})
export class ReportsRosterMemberDialogComponent implements OnInit {

  @Input() member: PortalMember;
  @Output() onCancel = new EventEmitter();
  public display: boolean = true;

  constructor(private memberService: MemberInfoService) {

  }
  
  ngOnInit(): void {
    
  }

  public get caption() : string {
    if(this.member)
      return this.member.lastName + ", " + this.member.firstName + " => " + this.member.cin;
    else
      return "";
  }

  public cancelClick() {
    this.display = false;

    this.onCancel.emit(false);
  }
}
