<form class="lookup-container" #claimLookUpForm="ngForm" (ngSubmit)="findClaims(claimLookUpForm)" autocomplete="off">
  <div class="claim-number-label">
    <label for="claimNumber">{{lookupTitle}}</label>
  </div>
  <div class="claim-number" *ngIf="isType === typeClaim">
    <input class="form-control" id="claimNumber" name="claimNumber" type="text" #claimNumber="ngModel"
           [disabled]="showCustomDate" [(ngModel)]="claim" maxlength="12" pattern=".{10,12}"
           (keyup)="clearMessagesClaim(this, claimLookUpForm)">
  </div>
  <div class="claim-number" *ngIf="isType === typeReferral">
    <input class="form-control" id="referralCode" name="referralCode" type="text" #referralCode="ngModel"
           [disabled]="showCustomDate" [(ngModel)]="code" maxlength="10" pattern=".{5,10}"
           (keyup)="clearMessagesReferral(this, claimLookUpForm)">
  </div>
  <div class="claim-number" *ngIf="isType === typeADT">
    <input class="form-control" id="adtNumber" name="adtNumber" type="text" #adtNumber="ngModel"
           [disabled]="showCustomDate" [(ngModel)]="adt" maxlength="10" pattern=".{5,10}"
           (keyup)="clearMessagesADT(this, claimLookUpForm)">
  </div>  
  <div class="claim-number-message">
    <span class="text-danger small" *ngIf="showValidClaimNumberMessage">
      {{lookupError}}
    </span>
  </div>
  <div class="find-claims-button">
    <button id=btn{{isType}}FindByNum class="btn-claims btn btn-primary" type="submit" [disabled]="disableClaimsButton()">{{findButtonTitle}}</button>
  </div>
  <div class="claims-clear-button">
    <button id=btn{{isType}}ClearByNum class="btn-clear btn btn-secondary-clear" type="button"
            (click)="claimLookUpForm.reset(); clearClaimsSearch();">
      Clear
    </button>
  </div>
</form>
