<div class="collection-users-empty flex-center" *ngIf="users == null || users.length == 0">
    No users in collection
</div>
<div class="collection-users">
    <div class="collection-user-search-filters">
        <div class="filter-provider-name">
            <input class="form-control" type="text" name="txtUserName" placeholder="Enter Name/Email"  
                id="userName" [(ngModel)]="userName" maxlength="50">
        </div>
        <div class="filter-provider-status">
            <p-dropdown name="selectedStatus" id="selectedStatus" [options]="userStatuses" [(ngModel)]="selectedStatus" [optionLabel]="'label'" >
            </p-dropdown>
        </div>
        <div class="filter-provider-role">
            <p-dropdown name="selectedRole" id="selectedRole" [options]="userRoles" [(ngModel)]="selectedRole" [optionLabel]="'label'" >
            </p-dropdown>
        </div>
        <div class="search-provider-button">
            <button id="btnSearchForProvider" class="btn-provider btn btn-primary" 
                [disabled]="userName.trim().length === 0 && (!selectedStatus || selectedStatus.value.id === 0) && (!selectedRole || selectedRole.value.id === 0)" type="submit" (click)="searchUsers()">
                Search
            </button>
            <button id="btnSearchForProvider" class="btn-provider-reset btn btn-primary" type="reset" (click)="resetUserSearch()">
                Reset
            </button>
        </div>
        <div class="collection-users-controls" *ngIf="session.isEnterpriseAdmin">
            <div class="collection-users-add">
                <button title="Add user" class="btn btn-primary btn-add-user" (click)="onAddUser()">
                    <i aria-hidden="true" class="fa fa-plus"></i> 
                    Add User 
                </button>
            </div>
        </div>
    </div>    
    <div class="collection-user-list">
        <p-table #collectionUserList [value]="users" [paginator]="false" [resizableColumns]="true"
             selectionMode="single" [(selection)]="selectedUser" (onSort)="onSort($event)">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="status" width="10%" pResizableColumn>Status<div class="{{sortClass('status')}}"></div></th>
                    <th pSortableColumn="roleCode" width="10%" pResizableColumn>Role<div class="{{sortClass('userType')}}"></div></th>
                    <th pSortableColumn="name" width="25%" pResizableColumn>Name<div class="{{sortClass('name')}}"></div></th>
                    <th pSortableColumn="email" width="55%" pResizableColumn>Email<div class="{{sortClass('email')}}"></div></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
                <tr [pSelectableRow]="row">
                    <td>
                        <div class="data-icon text-center">
                            <i id="userStatus{{rowIndex}}" [ngClass]="mapStatusToIcon(row)" [title]="mapStatusToTooltip(row)"></i>
                        </div>
                    </td>
                    <td>
                        <div class="data-icon text-center">
                            <i id="userRow{{rowIndex}}" [ngClass]="mapRoleToIcon(row)" [title]="mapRoleToTooltip(row)"></i>
                        </div>
                    </td>
                    <td>
                        <div class="data-cell">
                            {{row.name}}
                        </div>
                    </td>
                    <td>
                        <div class="data-cell row">
                            <div class="col-md-9">
                                {{row.email}}
                            </div>
                            <div class="col-md-1" (click)="onEditUser(row)">
                                <i class="fa fa-pencil-square-o"></i>
                            </div>
                            <div class="col-md-1" (click)="onRemoveUser(row)">
                                <i class="fa fa-times"></i>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <app-paginator [totalRecords]="totalUsers" resultLabel="users" [pageNumber]="pageNumber" [rowsPerPage]="rowsPerPage"(onPage)="onPage($event)"></app-paginator>
            </ng-template>
        </p-table>
    </div>
</div>
<app-user-approve-decline-dialog [user]="editUser" (userUpdated)="onUserUpdated($event)" [display]="showApproveDeclineScreen"></app-user-approve-decline-dialog>
<remove-user-dialog [user]="removalUser" (userUpdated)="onUserUpdated($event)" [display]="removalUser != null"></remove-user-dialog>
<add-user-dialog [display]="showAddUser" (showStatus)="onShowAddStatus($event)" (addUserStatus)="onAddUserToCollection($event)"
  [collectionItem]="collectionItem"></add-user-dialog>
<app-user-permission-dialog
  [user]="editUser"
  [display]="showUserPermission"
  (userUpdated)="onUserPermissionUpdated($event)">
</app-user-permission-dialog>
<app-user-decline-dialog
  [user]="editUser"
  (userUpdated)="onUserDeclineUpdated($event)"
  [display]="showUserDeclineScreen">
</app-user-decline-dialog>
