import { Component, OnInit, Output } from '@angular/core';
import { SubHeaderComponent } from '../layout/sub-header/sub-header.component';
import { TrayItem } from '../services/models/tray-item';
import { PortalNavigationService } from '../services/portal-navigation.service';
import { slideInAnimation } from './route-animation';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss'],
  animations: [slideInAnimation]
})

export class PortalComponent implements OnInit {
  @Output('moduleIcon') moduleIcon: string;
  @Output('moduleName') moduleName: string;
  public activeItem: TrayItem;
  public showSidebar = true;

  constructor(private portalNavigationService: PortalNavigationService) { }

  ngOnInit() {
    this.portalNavigationService.showSidebar$.subscribe(data => {
      this.showSidebar = data;
    });
  }

  onSetDisplaySidebar($event) {

  }
}
