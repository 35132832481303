import getLength from './_getLength.js';
import isFunction from './isFunction.js';
import allKeys from './allKeys.js';

// Since the regular `Object.prototype.toString` type tests don't work for
// some types in IE 11, we use a fingerprinting heuristic instead, based
// on the methods. It's not great, but it's the best we got.
// The fingerprint method lists are defined below.
export function ie11fingerprint(methods) {
  var length = getLength(methods);
  return function (obj) {
    if (obj == null) return false;
    // `Map`, `WeakMap` and `Set` have no enumerable keys.
    var keys = allKeys(obj);
    if (getLength(keys)) return false;
    for (var i = 0; i < length; i++) {
      if (!isFunction(obj[methods[i]])) return false;
    }
    // If we are testing against `WeakMap`, we need to ensure that
    // `obj` doesn't have a `forEach` method in order to distinguish
    // it from a regular `Map`.
    return methods !== weakMapMethods || !isFunction(obj[forEachName]);
  };
}

// In the interest of compact minification, we write
// each string in the fingerprints only once.
var forEachName = 'forEach',
  hasName = 'has',
  commonInit = ['clear', 'delete'],
  mapTail = ['get', hasName, 'set'];

// `Map`, `WeakMap` and `Set` each have slightly different
// combinations of the above sublists.
export var mapMethods = commonInit.concat(forEachName, mapTail),
  weakMapMethods = commonInit.concat(mapTail),
  setMethods = ['add'].concat(commonInit, forEachName, hasName);