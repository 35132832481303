import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserOrganization } from '../../../services/models/user-organization';
import { SessionService } from '../../../services/session.service';

@Component({
  selector: 'app-user-organization-dialog',
  templateUrl: './user-organization-dialog.component.html',
  styleUrls: ['./user-organization-dialog.component.scss']
})
export class UserOrganizationDialogComponent implements OnInit {


  @Input() display:boolean = true;
  @Input() buttonText: string='Make Default';
  @Input() headerText: string='';
  @Input() contentText: string='';
  @Input() isLOA:boolean = false;
  @Input() verificationIcon: string;
  public userOrganizations: UserOrganization[] = [];
  public selectedUserOrganization: UserOrganization;
  public userorganizationlabel: string = "collectionName";
  private organizationsSubscription$: Subscription;
  @Output() onUpdateDefaultorganization: EventEmitter<UserOrganization> = new EventEmitter(null);

  constructor( private sessionService: SessionService) { }

  ngOnInit() {
    this.organizationsSubscription$ = this.sessionService.organizationsChanged$
    .subscribe(result => {
      if(result){
        this.userOrganizations.push({providerCollectionId: 0, collectionName: 'Select an Organization'});
        result.forEach(org => {
          this.userOrganizations.push({providerCollectionId: org.providerCollectionID, collectionName: org.collectionName});
        })
        this.selectedUserOrganization = this.userOrganizations[0];
      }
    });
  }

  public isOrgSelected(): boolean {
    return !(this.selectedUserOrganization && this.selectedUserOrganization.providerCollectionId > 0);
  }

  public updateDefaultOrgSelection(){
    this.onUpdateDefaultorganization.emit(this.selectedUserOrganization);
  }
}

