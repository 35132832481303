import { Component, OnInit, Input, OnChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MemberInfoService } from 'src/app/services/member-info.service';
import { Claim } from 'src/app/services/models/claim';

@Component({
  selector: 'app-member-claims',
  templateUrl: './member-claims.component.html',
  styleUrls: ['./member-claims.component.scss']
})
export class MemberClaimsComponent implements OnInit, OnChanges, OnDestroy{
  @Input() tabName: boolean;
  @Input() isLoading: boolean;
  @Output() claimsChange = new EventEmitter();

  public member$: Subscription;
  public claims: Claim[];
  public totalClaims = 0;
  public sortOptions: SelectItem[];
  public sortField: string;
  public sortOrder: number;
  public sortKey: SelectItem;

  constructor(private memberInfoService: MemberInfoService) { }

  ngOnInit() {
    this.sortField = null;
    this.sortOptions = [
      {label: 'Most recent claims', value: 'dateOfService'},
      {label: 'Provider', value: 'providerInfo.providerName'},]
    this.sortKey = this.sortOptions[0];

    this.member$ = this.memberInfoService.selectedMemberChanged$.pipe(
      map((data) => {
        this.memberInfoService.getMemberClaim(data.memeCk, data.pcpInfo.pcpId).subscribe(result => {
          this.claims = result;
          if (this.claims && this.claims.length > 0) {
            this.totalClaims = this.claims.length;
          }
          else{
            this.totalClaims = 0;
          }
          this.claimsChange.emit(true);
        }, error => {
          this.claims = [];
          this.totalClaims = 0;
          this.claimsChange.emit(true);
        });
        return data;
      })
     ).subscribe();
    }

  ngOnChanges(){
    //reset the sortField when using the member quick search
    this.sortField = null;
  }

  ngOnDestroy(){
    if (this.member$) {
      this.member$.unsubscribe();
    }
  }

  onSortChange(event){
    const value = event.value.value;
    if (value === 'dateOfService') {
      this.sortOrder = -1;
    }
    else{this.sortOrder = 1;}
    this.sortField = value;
  }

  public onSortInit(event){
    this.sortField = this.sortKey.value;
    this.sortOrder = event.sortOrder;
  }
}
