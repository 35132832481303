import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MemberRosterComponent } from '../../member-roster/member-roster.component';
import { Member } from 'src/app/services/models/member';

@Component({
  selector: 'app-member-roster-dialog',
  templateUrl: './member-roster-dialog.component.html',
  styleUrl: './member-roster-dialog.component.scss'
})
export class MemberRosterDialogComponent implements OnInit {
  
  @Input() member: Member;
  @Output() onCancel = new EventEmitter();
  @ViewChild('memberrostercomponent') memberrostercomponent: MemberRosterComponent
  
  //public caption: string = 'Member roster';
  public display: boolean = true;
  public showSuccess: boolean = false
  public showError: boolean = false;
  public successMessage: string = "Roster updated successfully.";
  public failureMessage: string = "Error occurred during save. Please try again !!!";

  ngOnInit(): void {

  }

  public get caption() : string {
    return this.member.lastName + ", " + this.member.firstName + " => " + this.member.cin + "";
  }

  public cancelDialog() {
    this.display = false;
    this.onCancel.emit();
  }

  public updateDialog() {
    this.showError = false;
    this.showSuccess = false;

    if(this.memberrostercomponent)
      this.memberrostercomponent.saveRoster();
  }

  public onSaveSuccess($event) {
    this.successMessage = $event;
    this.showSuccess = true;
  }

  public onSaveFailed($event)  {
    this.failureMessage = $event;
    this.showError = true;
  }

  public onRosterChange() {    
    this.showSuccess = false;
    this.showError = false;
  }
}
