import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Messages } from 'src/app/app.messages';
import { ProviderDetailsWithTrainingAttestation } from 'src/app/services/models/provider-detail';
import { TrainingAttestationStatus } from 'src/app/services/models/training-attestation-status';
import { ReportsService } from 'src/app/services/reports.service';
import { SessionService } from 'src/app/services/session.service';
import { UiUtility } from 'src/app/utils/ui-utility';

@Component({
  selector: 'app-attestation-provider-training-individual',
  templateUrl: './attestation-provider-training-individual.component.html',
  styleUrl: './attestation-provider-training-individual.component.scss'
})
export class AttestationProviderTrainingIndividualComponent  implements OnInit, OnDestroy {
  private userCollectionSubscription: Subscription;
  private sortColumn: string;
  private sortAscending: boolean;

  public displayProviderAttestationTraining: boolean;
  public displayProviders: ProviderDetailsWithTrainingAttestation[];
  public providers: ProviderDetailsWithTrainingAttestation[];
  public selectedProvider: ProviderDetailsWithTrainingAttestation;
  public selectedTin: string;
  public totalRecords: number;
  public displayMessage = false;
  public isEnterpriseUser = false;
  public errorMessage: string = '';

  constructor(
    private sessionService: SessionService,
    private reportService: ReportsService,
    private messages: Messages
  ) {
    this.sortColumn = 'name';
    this.sortAscending = true;
  }

  ngOnDestroy(): void {
    if (this.userCollectionSubscription != null) {
      this.userCollectionSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.isEnterpriseUser = this.sessionService.currentPermission.isEnterpriseAdmin || this.sessionService.currentPermission.isEnterpriseUser;
    if(this.isEnterpriseUser) {
       // Do nothing, let internal user to search
    }
    else {
      const currentCollectionId = this.sessionService.currentPermission.providerCollectionID;
      this.searchProviders(currentCollectionId);
    }
  }

  public sortClass(column: string): string {
    let cls: string;
    if (column == this.sortColumn) {
      cls = this.sortAscending ? "fa fa-chevron-up" : "fa fa-chevron-down";
    }
    else {
      cls = "fa fa-chevron-up";
    }
    return cls;
  }

  public onPage(e: any) {
    let s = JSON.stringify(e);
  }

  public onSort(e: any): void {
    this.providers.sort((data1, data2) => {
      let value1 = data1[e.field];
      let value2 = data2[e.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (e.order * result);
    });
    this.sortAscending = e.order == 1;
    this.sortColumn = e.field;
  }

  public onSearch(search: any) : void {
    this.selectedTin = '';

    if (search && (search.providerName || search.providerId || search.providerTin)) {
      if(this.isEnterpriseUser) {
        this.searchProviders(0, search.providerId, search.providerName, search.providerTin);
      }
      else {

        if (!UiUtility.isNullUndefinedOrEmpty(search.providerName)) {
          this.displayProviders = this.providers
            .filter(p => p.name.toLowerCase().indexOf(search.providerName.trim().toLowerCase()) >= 0);
        }
        else if (!UiUtility.isNullUndefinedOrEmpty(search.providerId)) {
          this.displayProviders = this.providers
            .filter(p => p.calProviderID.toLowerCase().indexOf(search.providerId.trim().toLowerCase()) >= 0);
        }
        else if (!UiUtility.isNullUndefinedOrEmpty(search.providerTin)) {
          this.displayProviders = this.providers
            .filter(p => p.taxId.toLowerCase().indexOf(search.providerTin.trim().toLowerCase()) >= 0);
        }        

        /*
        if (!UiUtility.isNullUndefinedOrEmpty(search.providerName) && !UiUtility.isNullUndefinedOrEmpty(search.providerId)) {
          this.displayProviders = this.providers
            .filter(p => p.name.toLowerCase().indexOf(search.providerName.trim().toLowerCase()) >= 0 || p.calProviderID.toLowerCase().indexOf(search.providerId.trim().toLowerCase()) >= 0);
        }
        else if (!UiUtility.isNullUndefinedOrEmpty(search.providerName)) {
          this.displayProviders = this.providers
            .filter(p => p.name.toLowerCase().indexOf(search.providerName.trim().toLowerCase()) >= 0);
        }
        else {
          this.displayProviders = this.providers
            .filter(p => p.calProviderID.toLowerCase().indexOf(search.providerId.trim().toLowerCase()) >= 0);
        }
        */
      }
    }
    else {
      this.displayProviders = this.providers.slice();
    }
    let param: any = {
      "field": "name",
      "order": 1
    }
    this.onSort(param);
  }

  public onProviderSelected(provider: ProviderDetailsWithTrainingAttestation, isRadioButton: boolean = false) {
    if (provider.trainingAttestationStatusDisplay === 'Submitted' && isRadioButton) {
      return; //disable radio button
    }

    this.selectedProvider = provider;
    this.displayProviderAttestationTraining = true;
  }

  public onProcessAttestation(event: any) {
    this.displayProviderAttestationTraining = false;
    if (event != null && event !== 'cancel') {
      this.selectedProvider = event;

      for (var i = 0; i < this.displayProviders.length; i++) {
        if (this.displayProviders[i].providerNPI === this.selectedProvider.providerNPI) {
          this.displayProviders[i].trainingAttestationStatus = TrainingAttestationStatus.Submitted;
          this.displayProviders[i].trainingAttestationStatusDisplay = "Submitted";
          this.displayProviders[i].attestationDate = new Date();
          this.displayProviders[i].attendees = this.selectedProvider.attendees;

          if (!this.isEnterpriseUser && this.selectedProvider.attendees && this.selectedProvider.attendees.length > 0) {
            this.displayProviders[i].attendees = "*****";
          }
        }
      }
    }
  }

  private searchProviders(collectionId: number, providerId: string = null, providerName: string = null, providerTin: string = null) {
    this.errorMessage = '';
    this.userCollectionSubscription = this.reportService.getProvidersForTrainingAttestationIndividual(collectionId, providerId, providerName, providerTin).subscribe(result=>{
      this.providers = result !== null ? result : [];
      this.totalRecords = this.providers.length;

      if(this.providers) {
        this.providers.forEach(p => {
          p.trainingAttestationStatusDisplay = this.reportService.providerTrainingStatusMapping(p.trainingAttestationStatus);
        });
        this.displayProviders = this.providers.slice();
      }

      this.displayMessage = this.providers.length === 0;
      
      let param: any = {
        "field": "name",
        "order": 1
      }
      this.onSort(param)
    }, (error) => {
      console.log(error);
      this.errorMessage = this.messages.MessageGenericError;
    });
  }
}