<p-dialog header="Rename Collection" [(visible)]="display" [modal]="true">
    <div class="form-group">
        <div class="title">
            Enter the new name for the collection:
        </div>        
        <input type="text" class="form-control" id="txtNewCollectionName" [(ngModel)]="collectionName" #collectionNameCtl="ngModel" (keyup)="onInput($event)"/>
    </div>
    <app-alert-danger *ngIf='validationError'
        [subjectMessage]="''"
        [contentMessage]="validationMessage">
    </app-alert-danger>
    <div class="renamecollection-dialog-footer">
        <button type="button" class="btn btn-primary btn-cancel" (click)="onCancel()">
            Cancel
        </button>
        <button id="btnRename" type="button" class="btn btn-primary btn-rename" (click)="onRename()">
            Rename
        </button>  
    </div>
</p-dialog>
