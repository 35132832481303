import { Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-continue-dialog',
  templateUrl: './continue-dialog.component.html',
  styleUrls: ['./continue-dialog.component.scss']
})
export class ContinueDialogComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
