
<div class="attestation-provider-training-container">
  <div class="report-search">
    <app-reports-tin-search [dialogType]="2" (onProviderSearch)="onSearch($event)"></app-reports-tin-search>
  </div>
  <div class="report-error" *ngIf="errorMessage.length">
    {{errorMessage}}
  </div>
  <div class="report-result" *ngIf="displayTins && displayTins.length">
    <p-table tableStyleClass="table-data" #providerList
    [value]="displayTins"
    [paginator]="true"
    [rows]="10"
    [scrollable]="true"
    scrollHeight="325px"
    [styleClass]="'p-datatable-striped p-datatable-lg'">
      <ng-template pTemplate="header">
        <tr>
          <th width="50px">&nbsp;</th>
          <th width="40%" pSortableColumn="providerTaxName">Name</th>
          <th width="20%" pSortableColumn="providerTaxId" class="header-provider-column">TIN</th>
          <th width="20%" pSortableColumn="trainingDueDate" class="header-provider-column">Due Date</th>
          <th width="15%" pSortableColumn="trainingAttestationStatusDisplay" class="header-provider-column">Attestation Status</th>
          <th width="20px">&nbsp;</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td width="50px" class="check-column">
            <p-radioButton [inputId]="row" [value]="row.trainingAttestationId" [(ngModel)]="selectedTin" (click)="onTinSelected(row, true)"  *ngIf="(row.providerTaxId != '' && row.trainingAttestationStatusDisplay !== 'Submitted') && !isEnterpriseUser"></p-radioButton>
          </td>
          <td width="40%">
            <div *ngIf="row.trainingAttestationStatusDisplay !== 'Submitted' && !isEnterpriseUser">{{row.providerTaxName}}</div>
            <div *ngIf="row.trainingAttestationStatusDisplay === 'Submitted' || isEnterpriseUser"><a href="javascript:void(0);" (click)="onTinSelected(row)">{{row.providerTaxName}}</a></div>
          </td>
          <td width="20%" class="provider-column">
            <div>{{row.providerTaxId}}</div>
          </td>
          <td width="20%" class="provider-column">
            <div>{{row.trainingDueDate}}</div>
          </td>
          <td width="15%" class="provider-column">
            <div *ngIf="row.trainingAttestationStatusDisplay === 'Verified' || row.trainingAttestationStatusDisplay === 'Submitted'" style="color:green">{{row.trainingAttestationStatusDisplay}}</div>
            <div *ngIf="row.trainingAttestationStatusDisplay === 'Pending'" style="color:orange">{{row.trainingAttestationStatusDisplay}}</div>            
            <div *ngIf="row.trainingAttestationStatusDisplay === 'Past Due'" style="color:red">{{row.trainingAttestationStatusDisplay}}</div>
          </td>
          <td width="20px" class="provider-column">
            <button type="button" class="view-button fa fa-search" title="View" (click)="onTinSelected(row)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table> 
  </div>
  <div class="report-message" *ngIf="displayMessage">
    <span class="no-results-message">No results found.</span>
  </div>
</div>

<app-attestation-provider-training-dialog
  *ngIf="displayProviderAttestationTraining"
  [taxDetail] = "selectedTaxDetail"
  (onAttestationSubmission)="onProcessAttestation($event)">
</app-attestation-provider-training-dialog>
