import { UserAddress, UserProfile } from '@caloptima/authentication';
import { ProviderDetail } from '../../services/models/provider-detail';
import { EmailVerificationParameters } from '../../services/models/email-verification-parameters';
import { ProviderRegistrationStatus } from './provider-registration-status';
import { UserPosition } from './user-position';

export class RegistrationState {
  public profile: UserProfile;
  public provider: ProviderDetail;
  public emailVerification: EmailVerificationParameters;
  public status: ProviderRegistrationStatus;
  public acceptedEulaVersion: number;
  public codeValid: boolean = false;
  public address: UserAddress;
  public userPosition: UserPosition;
  public providerSearchOption: string;
}
