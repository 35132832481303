import { Component, OnInit, ViewChild } from '@angular/core';
import { ProviderDetail } from '../../../services/models/provider-detail';
import { ReportType } from '../../../services/models/reportype';
import { ReportsService } from '../../../services/reports.service';
import { SessionService } from '../../../services/session.service';
import { UiUtility } from '../../../utils/ui-utility';
import { ReportsProviderSearchComponent } from '../../../app-components/reports-provider-search/reports-provider-search.component';
import { AWPMemberComponent } from '../awp-member/awp-member.component';

@Component({
  selector: 'app-awp-provider',
  templateUrl: './awp-provider.component.html',
  styleUrls: ['./awp-provider.component.scss']
  // ,
  // animations: [
  //   trigger('showMember', [
  //     state('false', style({ opacity: 1, transform: 'translateX(0%)', display: 'block' })),
  //     state('true', style({ opacity: 0, transform: 'translateX(-100%)', display: 'none' })),
  //     transition('false => true', animate('500ms ease-in')),
  //     transition('true => false', animate('500ms ease-out'))
  // ])]
})

export class AWPProviderComponent implements OnInit {
  public providers: ProviderDetail[] = [];
  public displayedProviders: ProviderDetail[] = [];
  public selectedProvider: ProviderDetail;
  public totalRecords: number;
  public rowsPerPage: number = 10;
  public pageNumber: number = 1;
  public selectedCalProviderID: string;
  public animationState: string = '';
  public displayMembers = false;

  private sortColumn: string;
  private sortAscending: boolean;

  public columns: any = [
    { field: '', header: '', width: '5%', classHeader: 'provider-reports-header', classContent: 'provider-reports-content-alt1', sort: false },
    { field: 'name', header: 'Name', width: '40%', classHeader: 'provider-reports-header', classContent: 'provider-reports-content', sort: true },
    { field: 'taxId', header: 'TIN', width: '25%', classHeader: 'provider-reports-header-alt1', classContent: 'provider-reports-content-alt1', sort: true },
    { field: 'calProviderID', header: 'Provider ID', width: '25%', classHeader: 'provider-reports-header-alt1', classContent: 'provider-reports-content-alt1', sort: true }
  ];

  @ViewChild('providerSearch') providerSearch: ReportsProviderSearchComponent;
  @ViewChild('awpmemberComponent') awpmemberComponent: AWPMemberComponent;

  constructor(
    private reportsService: ReportsService,
    private sessionService: SessionService
  ) {
  }

  ngOnInit() {
    this.initialize();
  }

  public initialize(resetComponent = false) {
    
    this.sortColumn = 'name';
    this.selectedCalProviderID = '';

    if (resetComponent) {    
      this.displayMembers = false;
      this.providers = [];
      this.displayedProviders = [];

      setTimeout(() => {
        if(this.providerSearch) {
          this.providerSearch.resetSearchFilters();
        }      
      }, 100);
    }

    if(this.sessionService.currentPermission.isEnterpriseAdmin || this.sessionService.currentPermission.isEnterpriseUser) {
      //  Do nothing.
    }
    else {
      this.reportsService.getProvidersForReportsAWP(this.sessionService.currentPermission.providerCollectionID, ReportType.AWV).subscribe(result => {
        this.providers = result;

        this.pageNumber = 1;
        this.onProviderSearch(null);

        let param: any = {
          "field": "name",
          "order": 1
        }
        this.onSort(param)
      });
    }
  }

  public sortClass(column: string): string {
    let cls: string;
    if (column == this.sortColumn) {
      cls = this.sortAscending ? "fa fa-chevron-up" : "fa fa-chevron-down";
    }
    else {
      cls = "fa fa-chevron-up";
    }
    return cls;
  }

  public onPage(e: any) {
    let s = JSON.stringify(e);
  }

  public onSort(e: any): void {
    this.providers.sort((data1, data2) => {
      let value1 = data1[e.field];
      let value2 = data2[e.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (e.order * result);
    });
    this.sortAscending = e.order == 1;
    this.sortColumn = e.field;
  }

  public onProviderSelected(provider: ProviderDetail) {
    this.selectedCalProviderID = provider.calProviderID;
    this.selectedProvider = provider;
    this.displayMembers = true;
    setTimeout(() => {
      this.awpmemberComponent.setProviderId(this.selectedProvider);  
    }, (500));    
  }

  public backToProvider() {
    this.initialize(true);
  }

  public onProviderSearch(search) {
    this.selectedCalProviderID = '';
    if (search && (search.providerName || search.providerId || search.providerTin)) {

      if(this.sessionService.currentPermission.isEnterpriseAdmin || this.sessionService.currentPermission.isEnterpriseUser) {
        this.reportsService.searchProvidersForReportsAWP(search.providerId, search.providerName, search.providerTin, ReportType.AWV).subscribe(result => {
          this.providers = result;
          this.displayedProviders = this.providers.slice();
        });
      }
      else {
        if (!UiUtility.isNullUndefinedOrEmpty(search.providerName)) {
          this.displayedProviders = this.providers
            .filter(p => p.name.toLowerCase().indexOf(search.providerName.trim().toLowerCase()) >= 0);
        }
        else if (!UiUtility.isNullUndefinedOrEmpty(search.providerId)) {
          this.displayedProviders = this.providers
            .filter(p => p.calProviderID.toLowerCase().indexOf(search.providerId.trim().toLowerCase()) >= 0);
        }
        else if (!UiUtility.isNullUndefinedOrEmpty(search.providerTin)) {
          this.displayedProviders = this.providers
            .filter(p => p.taxId.toLowerCase().indexOf(search.providerTin.trim().toLowerCase()) >= 0);
        }        
      }
    }
    else {
      this.displayedProviders = this.providers.slice();
    }
    //this.setPagedData();
  }

  private setPagedData(page: number = 0) {
    if (page === 0) {
      this.pageNumber = 1;
    }
    this.totalRecords = this.displayedProviders.length;
    const start = (this.pageNumber - 1) * this.rowsPerPage;
    let end = start + this.rowsPerPage;
    if (end > this.displayedProviders.length) {
      end = this.displayedProviders.length;
    }

    this.displayedProviders = this.displayedProviders.slice(start, end);
  }
}
