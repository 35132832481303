<p-dialog  styleClass="claim-lookup-dialog" [style]="{'width': '400px'}" [(visible)]="display" [modal]="true" (onHide)="onClose()" >
  <p-header>
    <h3><i class="fa-icon fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp; <span>{{labelHeader}}</span></h3>
  </p-header>

  <div class="ref-submission-cancel-container">
    <div class="ref-submission-cancel-message">
      You will lose all progress and have to start over again in the future.
    </div>
    <button type="button" class="btn btn-danger" (click)="onCancel()">
      <h2>DISCARD</h2>
    </button>
    <a href="javascript:void(0);" (click)="onClose()">CANCEL</a>
  </div>
</p-dialog>


