<form class="report-wellness-details">
    <div class="report-wellness-details-memberinfo">
        <div class="report-wellness-details-memberinfo-lblmemberid">
            <b>Member ID</b>
        </div>
        <div class="report-wellness-details-memberinfo-valuememberid">
            {{wellnessDetails ? wellnessDetails.memberCIN : ''}}
        </div>
        <div class="report-wellness-details-memberinfo-lblmemberdob">
            <b>Date of Birth</b>
        </div>
        <div class="report-wellness-details-memberinfo-valuememberdob">
            {{wellnessDetails ? wellnessDetails.memberDOB : ''}}
        </div>
    </div>        
    <div class="report-wellness-details-providerinfo">
        <div class="report-wellness-details-providerinfo-lbl">
            <b>Provider name</b>
        </div>
        <div class="report-wellness-details-providerinfo-name">
            {{providerDetail ? providerDetail.providerName : ''}}
        </div>
        <div class="report-wellness-details-providerinfo-lbladdress">
            <b>Address</b>
        </div>        
        <div class="report-wellness-details-providerinfo-address">
            {{getAddress}}
        </div>            
    </div>
    <div class="report-wellness-details-notes">
        <div class="report-wellness-details-notes-lbl">
            <b>If other provider, specify NPI</b>
        </div>
        <div class="report-wellness-details-notes-name">
            <input class="form-control" type="text" required="false" name="txtnotesname" id="txtnotesid" [(ngModel)]="notes"/>
        </div>          
    </div>    
    <div class="report-wellness-details-dateofservice">
        <div class="report-wellness-details-dateofservice-text">Date of Service</div>
        <!--<div class="report-wellness-details-preventivehealth-icon"><i class="fa-icon fa fa-info-circle" [title]="'TODO'"></i></div>-->
    </div>         
    <div class="report-wellness-details-dateofservicelblvalue">
        <div class="report-wellness-details-dateofservicelblvalue-dsl">
            <b>Date of Service</b><span class="required-field-star"> *</span>
        </div>
        <div class="report-wellness-details-dateofservicelblvalue-dsv">
            <input class="form-control" type="date" required="false"  name="txtdateofservicename" id="txtdateofserviceid" [ngModel]="wellnessDetails.dateOfService | date:'yyyy-MM-dd'" [value]="wellnessDetails.dateOfService | date:'yyyy-MM-dd'"  (ngModelChange)="wellnessDetails.dateOfService = $event; onChange($event)" #wellnessDetails.dateOfService="ngModel">
        </div>    
    </div>
    <div class="report-wellness-details-preventivehealth">
        <div class="report-wellness-details-preventivehealth-text">Preventative Health</div>
        <!--<div class="report-wellness-details-preventivehealth-icon"><i class="fa-icon fa fa-info-circle" [title]="'TODO'"></i></div>-->
    </div>     
    <div class="report-wellness-details-preventivehealthstoclbl">
        <div class="report-wellness-details-preventivehealthstoclbl-sc">
            <b>Screening to Consider</b>
        </div>
        <div class="report-wellness-details-preventivehealthstoclbl-dc">
            <b>Date Completed</b>
        </div>
        <div class="report-wellness-details-preventivehealthstoclbl-s">
            <b>Status</b>
        </div>
    </div>        
    <div class="report-wellness-details-preventivehealthstocvalue">      
        <div class="report-wellness-details-preventivehealthstocvalue-row" *ngFor="let screen of wellnessDetails?.screening">
            <div class="report-wellness-details-preventivehealthstocvalue-sc">
                <input class="form-control" readonly type="text" required="false"  name="txtscreenname{{screen.screening}}" id="txtscreenid{{screen.screening}}" [(ngModel)]="screen.screening">               
            </div>
            <div class="report-wellness-details-preventivehealthstocvalue-dc">
                <input class="form-control" type="date" required="false"  name="txtscreencdname{{screen.screening}}" id="txtscreencdid{{screen.screening}}" [ngModel]="screen.completionDate | date:'yyyy-MM-dd'" [value]="screen.completionDate | date:'yyyy-MM-dd'"  (ngModelChange)="screen.completionDate = $event; onChange($event)" #screen.completionDate="ngModel">
            </div>
            <div class="report-wellness-details-preventivehealthstocvalue-s">
                <p-dropdown name="txtscreensname{{screen.screening}}" id="drpdownscreensid{{screen.screening}}" [options]="screeningStatusList" [(ngModel)]="screen.status" (onChange)="onChange($event)">
                </p-dropdown>
            </div>
            <div class="report-wellness-details-preventivehealthstocvalue-star">
                <span *ngIf="screen.status === 'Completed'" class="required-field-star">*</span>
            </div>
        </div>      
    </div>                
    <div class="report-wellness-details-chroniccondition">
        <div class="report-wellness-details-chroniccondition-text">Chronic Conditions</div>
        <!--<div class="report-wellness-details-chroniccondition-icon"><i class="fa-icon fa fa-info-circle" [title]="'TODO'"></i></div>-->
    </div>       
    <div class="report-wellness-details-chronicconditionpdlbl">
        <div class="report-wellness-details-chronicconditionpdlbl-pd">
            <b>Potential Diagnosis</b>
        </div>
        <div class="report-wellness-details-chronicconditionpdlbl-dcc">
            <b>Diagnosis Code</b>
        </div>
        <div class="report-wellness-details-chronicconditionpdlbl-ss">
            <b>Status</b><span *ngIf="chronicDiagnosis && chronicDiagnosis.length" class="required-field-star"> *</span>
        </div>
    </div>                       
    <div class="report-wellness-details-chronicconditionpdvalue">      
        <div class="report-wellness-details-chronicconditionpdvalue-row" *ngFor="let diag of chronicDiagnosis">
            <div class="report-wellness-details-chronicconditionpdvalue-pd"> 
                <input class="form-control" readonly type="text"required="false"  name="txtpdname{{diag.diagCode}}" id="txtpdid{{diag.diagCode}}" [(ngModel)]="diag.description">               
            </div>
            <div class="report-wellness-details-chronicconditionpdvalue-dc">
                <input class="form-control" readonly type="text"required="false"  name="txtdcname{{diag.diagCode}}" id="txtdcname{{diag.diagCode}}" [(ngModel)]="diag.diagCode">               
            </div>
            <div class="report-wellness-details-chronicconditionpdvalue-s">
                <p-dropdown name="txtsname{{diag.diagCode}}" id="txtsid{{diag.diagCode}}" [options]="diagnosisStatusList" [(ngModel)]="diag.status" (onChange)="onChange($event)">
                </p-dropdown>
            </div>
        </div>      
    </div>            
    <div class="report-wellness-details-nonchroniccondition">        
        <div class="report-wellness-details-nonchroniccondition-text">Non-Chronic Conditions</div>
        <!--<div class="report-wellness-details-nonchroniccondition-icon"><i class="fa-icon fa fa-info-circle" [title]="'TODO'"></i></div>-->
    </div>       
    <div class="report-wellness-details-nonchronicconditionpdlbl">
        <div class="report-wellness-details-nonchronicconditionpdlbl-pd">
            <b>Potential Diagnosis</b>
        </div>
        <div class="report-wellness-details-nonchronicconditionpdlbl-dcc">
            <b>Diagnosis Code</b>
        </div>
        <div class="report-wellness-details-nonchronicconditionpdlbl-ss">
            <b>Status</b><span *ngIf="nonChronicDiagnosis && nonChronicDiagnosis.length" class="required-field-star"> *</span>
        </div>
    </div>                       
    <div class="report-wellness-details-nonchronicconditionpdvalue">      
        <div class="report-wellness-details-nonchronicconditionpdvalue-row" *ngFor="let diag of nonChronicDiagnosis">

            <div class="report-wellness-details-nonchronicconditionpdvalue-pd"> 
                <input class="form-control" readonly type="text"required="false"  name="txtnpdname{{diag.diagCode}}" id="txtnpdid{{diag.diagCode}}" [(ngModel)]="diag.description">               
            </div>
            <div class="report-wellness-details-nonchronicconditionpdvalue-dc">
                <input class="form-control" readonly type="text"required="false"  name="txtndcname{{diag.diagCode}}" id="txtndcname{{diag.diagCode}}" [(ngModel)]="diag.diagCode">               
            </div>
            <div class="report-wellness-details-nonchronicconditionpdvalue-s">
                <p-dropdown name="txtnsname{{diag.diagCode}}" id="txtnsid{{diag.diagCode}}" [options]="diagnosisStatusList" [(ngModel)]="diag.status" (onChange)="onChange($event)">
                </p-dropdown>
            </div>
        </div>      
    </div>           
    <div class="report-wellness-details-otherdiagnosis">
        <div class="report-wellness-details-otherdiagnosis-text">Other Diagnosis</div>
        <!--<div class="report-wellness-details-otherdiagnosis-icon"><i class="fa-icon fa fa-info-circle" [title]="'TODO'"></i></div>-->
    </div>       
    <div class="report-wellness-details-otherdiagnosislbl">
        <div class="report-wellness-details-otherdiagnosislbl-d">
            <b>Potential Diagnosis</b>
        </div>
        <div class="report-wellness-details-otherdiagnosislbl-dc">
            <b>Diagnosis Code</b>
        </div>
        <!-- <div class="report-wellness-details-otherdiagnosislbl-ds">
            <b>Date of Service</b>
        </div>             -->
        <div class="report-wellness-details-otherdiagnosislbl-s">
            <b>Status</b><span *ngIf="wellnessDetails?.otherDiagnosis && wellnessDetails?.otherDiagnosis.length" class="required-field-star"> *</span>  
        </div>
        <div class="report-wellness-details-otherdiagnosislbl-r">
            &nbsp;
        </div>        
    </div>                       
    <div class="report-wellness-details-otherdiagnosisvalue">      
        <div class="report-wellness-details-otherdiagnosisvalue-row" *ngFor="let diag of wellnessDetails?.otherDiagnosis">
            <div class="report-wellness-details-otherdiagnosisvalue-d">
                <input class="form-control" type="text" required="false"  name="txtoddname{{diag.index}}" id="txtoddname{{diag.index}}id" [(ngModel)]="diag.description">               
            </div>
            <div class="report-wellness-details-otherdiagnosisvalue-dc">
                <input class="form-control" maxlength="19" type="text" required="false"  name="txtoddcode{{diag.index}}" id="txtoddcode{{diag.index}}id" [(ngModel)]="diag.diagCode">               
            </div>
            <!-- <div class="report-wellness-details-otherdiagnosisvalue-ds">
                <input class="form-control" type="date"required="false"  name="txtodddos{{diag.diagCode}}" id="txtodddos{{diag.diagCode}}id" [ngModel]="diag.dateOfService | date:'yyyy-MM-dd'" [value]="diag.dateOfService | date:'yyyy-MM-dd'"  (ngModelChange)="diag.dateOfService = $event;onChange($event)" #diag.dateOfService="ngModel">
            </div>                 -->
            <div class="report-wellness-details-otherdiagnosisvalue-s">
                <p-dropdown name="txtoddstatus{{diag.index}}" id="txtoddstatus{{diag.index}}id" [options]="statusList" [(ngModel)]="diag.status" (onChange)="onChange($event)">
                </p-dropdown>
            </div>
            <div class="report-wellness-details-otherdiagnosisvalue-r">
                <span class="pull-right text-danger" (click)="removeDiagnosis(diag)"><i class="fa fa-times"></i></span>
            </div>            
        </div>      
    </div> 
    <div class="report-wellness-details-otherdiagnosticadd">
        <div class="dashed_wrapper">
            <a href="javascript:void(0)" class="dashed_hyperlink" (click)="onAddDiagnostic()">
                <div class="dashed_button">
                    Add Diagnosis &nbsp; <span class="dashed_span">&plus;</span>
                </div>
            </a>
        </div>
    </div>        
    <div class="report-wellness-details-preventivehealthaclbl">
        Additional Comment
    </div>     
    <div class="report-wellness-details-preventivehealthacvalue">
        <textarea class="form-control" type="text"required="false"  name="txtacname" id="txtacid" [(ngModel)]="additionalComments" maxlength="500" rows="2" cols="89"></textarea>
        <div class="alert-error" *ngIf="additionalComments !== null && additionalComments.length > 500">
            Must have 500 max character limit.
        </div>
        <div class="report-wellness-details-comments">
            <span class="pull-left" *ngIf="additionalComments !== null">{{additionalComments.length}} / 500 characters</span>
        </div>
    </div>    
    <add-diagnosis-dialog [existingDiagnosticCodes]="wellnessDetails?.otherDiagnosis" (onDiagnosisAdded)="diagnosisAdded($event)" (onDiagnosisCancel)="diagnosisCancelled()" *ngIf="showAddDiagnosis"></add-diagnosis-dialog>
</form>