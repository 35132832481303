import tagTester from './_tagTester.js';
import has from './_has.js';
var isArguments = tagTester('Arguments');

// Define a fallback version of the method in browsers (ahem, IE < 9), where
// there isn't any inspectable "Arguments" type.
(function () {
  if (!isArguments(arguments)) {
    isArguments = function (obj) {
      return has(obj, 'callee');
    };
  }
})();
export default isArguments;