import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-user-permission-prompt',
  templateUrl: './user-permission-prompt.component.html',
  styleUrls: ['./user-permission-prompt.component.scss']
})
export class UserPermissionPromptComponent implements OnInit {
  @Output() userPrompted = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  public onContinue() {
    this.userPrompted.emit(true);
  }
}
