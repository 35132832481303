<div class="pcpattestation-container">
    <div class="pcpattestation-container-reporttype">
      <app-attestation-type [attestationType]="'bhabareferralattestation'"></app-attestation-type>
    </div>
    <div class="pcpattestation-alert">
      <app-alert-info subjectMessage=" " uniqueID="bhabareferralattestation" contentMessage="Narrow your search using the known provider or member."></app-alert-info>
    </div>
    <div class="pcpattestation-container-main">
      <p-tabView styleClass="pcpattestation-tabview-styles" [activeIndex]="activeindex" #tabView>
        <p-tabPanel [header]="getTabName(0)">
          <ng-template pTemplate="header">
            {{getTabName(0)}}
          </ng-template>
          <ng-template pTemplate="content">
            <app-attestation-bh-aba-referrals-provider #providerComponent></app-attestation-bh-aba-referrals-provider>
          </ng-template>
        </p-tabPanel>
        <p-tabPanel *ngIf="canViewTab(1)" [header]="getTabName(1)">
          <ng-template pTemplate="header">
            {{getTabName(1)}}
          </ng-template>
          <ng-template pTemplate="content">
            <app-attestation-bh-aba-referrals-member  #memberComponent></app-attestation-bh-aba-referrals-member>
          </ng-template>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
  <app-continue-dialog key="continueDialog"></app-continue-dialog>
  