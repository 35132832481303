import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { LookupRequest } from '../../services/models/requests/lookup-request';
import moment from 'moment';
import { DropDownObject } from '../../services/models/drop-down-object.interface';
import { DateMaskDirective } from '../directives/date-mask.directive';


@Component({
  selector: 'app-lookup-date',
  templateUrl: './lookup-date.component.html',
  styleUrls: ['./lookup-date.component.scss']
})

export class LookupDateComponent implements OnInit, AfterViewInit {
  public showErrorMessage = false;
  public claimMessage = 'Please enter a valid claim number';
  public dateSpan: DropDownObject[];
  public selectedTimeSpan: DropDownObject;
  private readonly dateSpanADT:  DropDownObject[] = 
  [
    { name: '1 Day', code: '1D', id: 1, value:1 },
    { name: '1 Week', code: '1W', id: 2, value:2 },
    { name: '3 Months', code: '3M', id: 3, value: 3 },
    { name: '6 Months', code: '6M', id: 4, value: 4 },
    { name: '9 Months', code:'9M', id: 5, value: 5 },
    { name: '1 Year', code:'1Y', id: 6, value:6 },
    { name: 'Custom Date Range', code: 'CD', id:7, value:7 }
  ];
  private readonly dateSpanClaimRef: DropDownObject[] =[
    { name: '3 Months', code: '3M', id: 1, value: 1 },
    { name: '6 Months', code: '6M', id: 2, value: 2 },
    { name: '9 Months', code: '9M', id: 3, value: 3 },
    { name: '1 Year', code: '1Y', id: 4, value: 4 },
    { name: 'Custom Date Range', code: 'CD', id: 5, value: 5 }
  ];
  public dateTypelist: DropDownObject[];
  public selectedDateType: DropDownObject;
  public selectedSingleStatus: DropDownObject;
  public statusList: SelectItem[];
  public selectedStatus: Array<DropDownObject> = [];
  public showInValidCinMessage = false;
  public showValidDateMessage = false;
  public showValidClaimStatusMessage = false;
  public showValidDateRangeMessage = false;
  public showValidDatePriorMessage = false;
  public showValidAdditionalStatusMessage = false;
  public startMonth: number;
  public startDay: number;
  public startYear: number;
  public endMonth: number;
  public endDay: number;
  public endYear: number;
  public startDate: Date;
  public endDate: Date;
  public dateRange: string = null;
  public memberCin: string;
  public facilityname: string = "";
  public ieDate: DateMaskDirective;
  public isIEOrEdge = false;
  public today = new Date();
  public twoYearsAgo = moment().subtract(2, 'years').add(1, 'day').toDate();
  public dateFilterLabel: string = "Type of Date";
  public additionalStatusList: DropDownObject[];
  public selectedAdditionalStatus: DropDownObject[] = [];

  private beginValidTime = new Date(1900, 1, 1);
  private endValidTime = new Date(2099, 12, 31);
  private beginValidMomement = moment(this.beginValidTime, 'MM/DD/YYYY', true);
  private endValidMomement = moment(this.endValidTime, 'MM/DD/YYYY', true);
  public typeClaim: string = 'Claim';
  public typeReferral: string = 'Referral';
  public typeADT: string = 'ADT';
  public maxSelections: number = 2;
  public maxSubtypeSelections: number = 3;

  @Input() isType = '';
  @Input() findButtonTitle = 'Find';
  @Input() currentUser = '';
  @Input() isInternalUser = false;
  @Input() dateTypes = [];
  @Input() statusTypes = [];
  @Input() statusmultiselect = true;
  @Input() addtionalStatusTypes:SelectItem[] = [];
  @Output() request = new EventEmitter<LookupRequest>();
  @Output() clearRequest = new EventEmitter<boolean>();

  constructor() { }
  
  ngOnInit() {
    this.setInitialValues();
  }
  
  ngAfterViewInit(): void {
    this.clearClaimsSearch();
  }

  public onMultiSelectChange(event) {
    if (this.selectedStatus && this.selectedStatus.length >= 0) {
      this.showValidClaimStatusMessage = false;
    }
  }

  public onMultiSelectAddtionalChange(event) {
    if (this.selectedAdditionalStatus && this.selectedAdditionalStatus.length >= 0) {
      this.showValidAdditionalStatusMessage = false;
    }
  }

  private setInitialValues(){    
    this.isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    if (this.isAdtType()) {
      this.dateFilterLabel = "Search Type";
      this.dateSpan = this.dateSpanADT;
      
      this.additionalStatusList = this.addtionalStatusTypes.map<DropDownObject>(dt => this.mapDropDownListItems(dt));
      this.maxSubtypeSelections = this.additionalStatusList.length-1;
    }
    else {
      this.dateSpan = this.dateSpanClaimRef;
    }
    this.dateTypelist = this.dateTypes.map<DropDownObject>(dt => this.mapDropDownListItems(dt));
  }

  public clearClaimsSearch() {
    // TODO: This setTimeout(...) is a kludge because it is the message loop needs 
    // to be processed after form.reset() (Clear button) is called. This form SHOULD
    // be rewritten using rxjs Reactive forms.
    setTimeout(() => {
      // Claim/Referral/ADT: Date Range
      this.selectedTimeSpan = this.dateSpan[0];
      
      // Claim/Referral: Type of Date, ADT: Search Type
      if (this.dateTypelist[0] != null) {
        this.selectedDateType = this.dateTypelist[0];
      }
      
      // Claim/Referral/ADT: Status
      this.updateStatusList();
      if(this.statusmultiselect){
        // Claim/Referral
        this.selectedStatus = [];
        this.statusList.map((item) => this.selectedStatus.push(item.value));
      }
      else if(this.statusList[0] != null){
        // ADT
        this.selectedSingleStatus = this.statusList[0].value;
      }
    
      // ADT: Subtype (Additional Status types)
      if (this.isAdtType()) {
        this.selectedAdditionalStatus = [];
        this.additionalStatusList.map((item) => this.selectedAdditionalStatus.push(item));
      }

      this.endDate = null;
      this.startDate = null;
      this.endMonth = null;
      this.endDay = null;
      this.endYear = null;
      this.startMonth = null;
      this.startDay = null;
      this.startYear = null;

      this.showErrorMessage = false;
      this.dateRange = null;
      this.showInValidCinMessage = false;
      this.showValidClaimStatusMessage = false;
      this.showValidDateRangeMessage = false;
      this.showValidDatePriorMessage = false;
      this.showValidDateMessage = false;    
      this.showValidAdditionalStatusMessage = false;
      this.clearRequest.emit(true);
    });
  }

  public clearMessagesClaim(o, form: NgForm) {
    if (o.claim.length === 0 && (this.memberCin === null || this.memberCin.length === 0)) {
      form.resetForm();
      this.clearClaimsSearch();
    }
  }

  public clearMessagesCin(o, form: NgForm) {
    if(o && o.memberCin) {
      o.memberCin = o.memberCin.replace(/([^0-9A-Za-z]) | ((default|DEFAULT)0[1-3])/g, '');
      if (o && (!o.memberCin || o.memberCin.length === 0)) {
        this.showInValidCinMessage = false;
      }
    }
  }

  private getListOfNamesFromSelectItems(items: DropDownObject[]): string[] {
    const results: string[] = [];
    items.forEach(element => {
      results.push(element.name);
    });

    return results;
  }

  public get isCINMandatory() : boolean {
    if(this.isInternalUser) {
      if(this.selectedTimeSpan && (this.selectedTimeSpan.code !== '1W' && this.selectedTimeSpan.code !== '1D')) {
        return true;
      }
    }
      
    return false;
  }

  private getStartDate(item: DropDownObject): Date {
    switch (item.code) {
      case '1D': {
        const d = new Date();
        d.setDate(d.getDate() - 1);
        return d;
      }
      case '1W': {
        const d = new Date();
        d.setDate(d.getDate() - 7);
        return d;
      }
      case '3M': {
        const d = new Date();
        d.setMonth(d.getMonth() - 3);
        return d;
      }
      case '6M': {
        const d = new Date();
        d.setMonth(d.getMonth() - 6);
        return d;
      }
      case '9M': {
        const d = new Date();
        d.setMonth(d.getMonth() - 9);
        return d;
      }
      case '1Y': {
        const d = new Date();
        d.setFullYear(d.getFullYear() - 1);
        return d;
      }
      default: {
        const d = new Date();
        d.setMonth(d.getMonth() - 6);
        return d;
      }
    }
  }
  public onDateSelect() {
    if(this.showInValidCinMessage) {
      if(!this.isCINMandatory) {
        this.showInValidCinMessage = false;
      }
    }
  }

  public get showCustomDate(): boolean {
    return (this.selectedTimeSpan && this.selectedTimeSpan.code === 'CD');
  }

  public disableClaimsButton(): boolean {
    let result: boolean = false;
    if (this.selectedTimeSpan && this.selectedTimeSpan.value && this.selectedTimeSpan.code && this.selectedTimeSpan.code === 'CD') {
      const startDt = moment(this.startDate, 'MM/DD/YYYY', true);
      const endDt = moment(this.endDate, 'MM/DD/YYYY', true)
      const isStartDt = startDt.isValid();
      const isEndDt = endDt.isValid();

      if (!this.startDate || !this.endDate) {
        result = true;
      }
      else if (this.isIEOrEdge && (!isStartDt ||
        !isEndDt ||
        !startDt.isBetween(this.beginValidMomement, this.endValidMomement) ||
        !endDt.isBetween(this.beginValidMomement, this.endValidMomement))
      ) {
        result = true;
      }
      else if (this.startDate && this.startDate >= this.beginValidTime && this.endDate && this.endDate <= this.endValidTime) {
        result = true;
      }
    }
    return result;
  }

  public isValidDateRange(startDate: Date, endDate: Date) {
    const dateDiff = moment(this.today).diff(moment(this.startDate), 'years', true);
    if (dateDiff > 2) {
      return false;
    }

    return true;
  }

  private setDatePriorMessageStatus(): boolean {
    if (moment(this.endDate).diff(moment(this.startDate)) < 0) {
      this.showValidDatePriorMessage = true;
    }
    else {
      this.showValidDatePriorMessage = false;
    }

    return this.showValidDatePriorMessage;
  }

  public onChangeDate(event) {
    if (this.isValidDateRange(this.startDate, this.today)) {
      this.showValidDateRangeMessage = false;
    }
    if (moment(this.endDate).diff(moment(this.startDate)) > 0) {
      this.showValidDatePriorMessage = false;
    }
  }


  public findClaims(form: NgForm) {

    this.showValidDateMessage = false;
    this.showErrorMessage = false;

    let regexp = new RegExp('^([9][0-9]{7}[a-zA-Z]{1})|((default0)[1-3]{1})$', 'i');
    let test = regexp.test(this.memberCin);

    this.showInValidCinMessage = false;
    if(this.isCINMandatory) {
      if(!test) {
        this.showInValidCinMessage = true;
        return;
      }
    }
    else {
      if(this.memberCin && !test) {
        this.showInValidCinMessage = true;
        return;
      }
    }

    var statuses:string[] = [];
    if(this.statusmultiselect) {
      statuses = this.getListOfNamesFromSelectItems(this.selectedStatus);
      if (this.selectedStatus.length === 0) {
        this.showValidClaimStatusMessage = true;
        return;
      }
      else {
        this.showValidClaimStatusMessage = false;
      }
    }
    else {

      statuses.push(this.selectedSingleStatus.name);
    }

    let subtypes: string[]=[];
    if (this.isAdtType() && this.selectedAdditionalStatus && this.selectedAdditionalStatus.length < 1) {
      this.showValidAdditionalStatusMessage = true;
      return;
    }
    else{
      this.selectedAdditionalStatus.forEach(subtype => {
        subtypes.push(subtype.id.toString());
      });
    }

    let startDate = this.getStartDate(this.selectedTimeSpan);
    let endDate = new Date();
    if (this.selectedTimeSpan && this.selectedTimeSpan.code === 'CD') {

        if (!form.valid) {
            if (!form.controls['fromDate'].valid || !form.controls['toDate'].valid) {
                this.showValidDateMessage = true;
            }
            return;
        }

        if (!this.isValidDateRange(this.startDate, this.today)) {
          this.showValidDateRangeMessage = true;
          return;
        }

        if (this.setDatePriorMessageStatus() === true) {
          return;
        }
        startDate = this.startDate;
        endDate = this.endDate;
    }

    const lookupRequest: LookupRequest = new LookupRequest();
    lookupRequest.startDate = startDate;
    lookupRequest.endDate = endDate;
    lookupRequest.status = statuses;
    lookupRequest.cin = this.memberCin;
    lookupRequest.dateFilter = this.selectedDateType.code;
    lookupRequest.username = this.currentUser;
    lookupRequest.sortAscending = false;
    lookupRequest.sortColumn = this.isType === this.typeClaim ? 'dateOfService' : 'dateRequested';

    lookupRequest.adtFacilityName = this.facilityname;
    lookupRequest.adtType = this.selectedDateType.code;
    lookupRequest.adtStatus = this.selectedSingleStatus ? this.selectedSingleStatus.code : "";
    lookupRequest.adtSubtype = subtypes;

    this.request.emit(lookupRequest);
  }


  public selectDateType(event){
    if(this.isClaimType()) {
      this.updateStatusList();
    }
  }

  public updateStatusList(){
    if(this.isClaimType()) {

      this.selectedStatus = [];
      this.statusList = [];

      if(this.selectedDateType && this.selectedDateType.code.toString().toUpperCase() == "DATEPAID") {
        var item = this.statusTypes.find(x=>x.value.code == "FN")
        if(item) {
          this.statusList.push(item);
        }                
      }
      else {
        this.statusList = this.statusTypes.slice();
      }

      this.statusList.map((item) => this.selectedStatus.push(item.value));
    }
    else {
      this.statusList = this.statusTypes.slice();
    }

    this.maxSelections = this.statusList.length - 1;
  }

  public isAdtType(): boolean{
    return this.isType === this.typeADT;
  }
  public isClaimType(): boolean{
    return this.isType === this.typeClaim;
  }

 /**
  * Maps the custom type from the calling components to a DropDownObject.
  * @param item 
  * @returns DropDownObject
  */
  private mapDropDownListItems(item: any) : DropDownObject {
    return ({ 
      name: item.value.name, 
      code: item.value.code, 
      id: item.value.id, 
      value: item.value.id });
  }
}
