
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

import { AuthenticationService, UserAddress, UserProfile, AccountStatus, AuthProfile } from '@caloptima/authentication';
import { CommonService } from '@caloptima/portal-foundation';
import { RegistrationService } from '../../../services/registration-service';
import { RegistrationState } from '../../../services/models/registration-state';
import { PortalConfig } from '../../../portal-config';
import { UiUtility } from '../../../utils/ui-utility';
import { UserPosition } from '../../../services/models/user-position';

@Component({
  selector: 'registration-information',
  styleUrls: ['./information.component.scss'],
  templateUrl: './information.component.html',
  providers: [CommonService, RegistrationService]
})
export class RegistrationInformationComponent implements OnInit {
  public profile: UserProfile;
  public address: UserAddress = new UserAddress();
  public userSelectedPosition: UserPosition = new UserPosition();
  public userPositionLists: UserPosition[] = [];
  public displayOtherPositionName: boolean;
  public profileInvalid: boolean;
  public emailSendFailed: boolean;
  public alreadyRegistered: boolean;
  public pleaseWait: boolean;
  public zipMask:string = '99999'
  public phoneMask:string = '999-999-9999';
  public emailRegex = '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\\.)+[a-zA-Z]{2,}))$';
  private existingEmail: string;

  @Input('registrationState') registrationState: RegistrationState;
  @Output() registrationStateUpdated = new EventEmitter();
  @Output() next = new EventEmitter();
  @Output() busy = new EventEmitter();

  constructor(
    private authService: AuthenticationService,
    private registrationService: RegistrationService,
    public stateService: CommonService,
    private configuration: PortalConfig)
  {
    this.pleaseWait = false;
  }

  ngOnInit() {
    this.profile = this.registrationState.profile;
    if (!UiUtility.isNullOrUndefined(this.registrationState.address)) {
      this.address = this.registrationState.address;
    }
    if (!UiUtility.isNullOrUndefined(this.registrationState.userPosition)) {
      this.userSelectedPosition = this.registrationState.userPosition;
    } else {
      this.registrationState.userPosition = new UserPosition();
      this.userSelectedPosition.providerPositionID = 0;
      this.displayOtherPositionName = false;
    }
    if (this.profile == null) {
      this.profile = new UserProfile();
    }
    if (this.registrationState.codeValid) {
      this.existingEmail = this.profile.email;
    }
    this.getUserPositions();
  }

  private setBusy(busy: boolean) {
    this.pleaseWait = busy;
    this.busy.emit(busy);
  }

  private sendEmail() {
    if (!this.registrationState.codeValid) {
      this.registrationService.sendVerificationEmail(this.profile.email).subscribe({
        next: (result) => {
          this.registrationState.emailVerification = result;
          this.registrationStateUpdated.emit(this.registrationState);
          this.next.emit();
        },
        error: (err) => {
          this.emailSendFailed = true;
          this.setBusy(false);
        }
      });
    }
    else {
      this.next.emit();
      this.setBusy(false);
    }
  }

  private getAuthUser(profileUser: UserProfile, address: UserAddress = null): AuthProfile {
    const authProfile = new AuthProfile();
    authProfile.userProfile = this.profile;
    authProfile.address = this.address;
    authProfile.clientId = this.configuration.ClientId;

    return authProfile;
  }

  private registerNewUser() {
    this.registrationState.profile = this.profile;
    this.registrationState.address = this.address;
    const authProfile = this.getAuthUser(this.profile, this.address);
    this.authService.register(authProfile).subscribe({
      next: (result: any) => {
        this.profile.userID = result.registeredUserId;
        this.registrationStateUpdated.emit(this.registrationState);
        this.next.emit();
      },
      error: (err) => {
        this.setBusy(false);
      }
    });
  }

  public updateUser() {
    const authProfile = this.getAuthUser(this.profile);
    this.authService.updateUser(authProfile).subscribe({
      next: (result) => {
          this.registrationStateUpdated.emit(this.registrationState);
          this.next.emit();
      },
      error: (err) => {
        this.setBusy(false);
      }
    });
  }

  public onUserPositionChanged() {
    const pos = this.findUserPosition(this.userSelectedPosition.providerPositionID);
    this.setDisplayOtherPositionName(pos);
    this.userSelectedPosition.positionDisplayName = '';
  }

  public register() {
    // verify if return user modified email address, if so reset flag to verify email
    if (this.registrationState.codeValid && this.existingEmail && this.existingEmail != this.profile.email) {
      this.registrationState.codeValid = false;
    }
    const re = new RegExp(this.emailRegex);
    this.profileInvalid =
      (this.profile.firstName == null || this.profile.firstName.trim() === '' ||
        this.profile.lastName == null || this.profile.lastName.trim() === '' ||
        this.address.address1 == null || this.address.address1.trim() === '' ||
        this.address.city == null || this.address.city.trim() === '' ||
        this.address.state == null || this.address.state.trim() === '' ||
        this.address.zip == null || this.address.zip == '' || this.address.zip.indexOf("_") >= 0 ||
        this.profile.email == null || this.profile.email.trim() === '' ||
        this.userSelectedPosition.providerPositionID === 0 || (this.displayOtherPositionName && this.userSelectedPosition.positionDisplayName.trim() === '') ||
        this.profile.mobilePhone == null || this.profile.mobilePhone == '' || this.profile.mobilePhone.indexOf("_") > 0
        || !re.test(this.profile.email));

    if (!this.profileInvalid) {
      this.setBusy(true);
      let clientId = this.configuration.ClientId;
      this.setUserPosition();

      this.authService.findUsers(null, this.profile.email, clientId, 1, 1).subscribe((users) => {
        if (users != null && users.length > 0) {
          console.log(users[0]);
        }
        if (users == null || users.length == 0) {
          this.registerNewUser();
        }
        // If the user came back to the information page, the user ID should match
        else if (users[0].userID == this.profile.userID) {
          this.updateUser();
        }
        //step to delete and re register a previously Incomplete registration,
        //when user uses same email id for registration
        else if(users[0].accountStatus == AccountStatus.Pending && !this.profile.userID ){
          this.authService.deletePendingRegistration(clientId, this.profile.email).subscribe(() => {
            this.registerNewUser();
          },
          () => {
            this.alreadyRegistered = true;
            this.setBusy(false);
          });
        }
        // If the user refreshed the registration page, or came back to it at a later time,
        // the user may already be in the databse as registered.
        else if (users[0].firstName == this.profile.firstName &&
          users[0].lastName == this.profile.lastName &&
          users[0].mobilePhone == this.profile.mobilePhone &&
          users[0].accountStatus == AccountStatus.Pending) {
          this.profile.userID = users[0].userID;
          if (this.registrationState.profile == null) {
            // The user refreshed the page, or came back to it a later time.
            // in that case, the profile information in the registration state
            // is not valid.
            this.registrationState.profile = this.profile;
          }
          else {
            this.registrationState.profile.userID = users[0].userID;
          }
          this.updateUser();
        }
        else {
          this.alreadyRegistered = true;
          this.setBusy(false);
        }
      });
    }
  }

  private getUserPositions() {
    let currentWaitTime = 0;
    const intervalTime = 50;
    const maxWaitTime = 5000;

    var serviceBaseUrlIsReady = setInterval(() => {
      currentWaitTime = currentWaitTime + intervalTime;
      if (currentWaitTime > maxWaitTime) {
        clearInterval(serviceBaseUrlIsReady);
        return;
      }

      if (this.registrationService.baseProviderServiceUrl) {
        clearInterval(serviceBaseUrlIsReady);

        const sub$ = this.registrationService.getUserPositions().subscribe(result => {
          this.userPositionLists = result;
          const defaultSelectedPosition = new UserPosition();
          defaultSelectedPosition.providerPositionID = 0;
          defaultSelectedPosition.positionDisplayName = '--- Select ---';
          this.userPositionLists.unshift(defaultSelectedPosition);

          this.setDisplayOtherPositionName(this.findUserPosition(this.userSelectedPosition.providerPositionID));
          sub$.unsubscribe();
        });
      }
    }, intervalTime);
  }

  private setUserPosition() {
    const userPos = this.findUserPosition(this.userSelectedPosition.providerPositionID);
    this.registrationState.userPosition.providerPositionID = userPos.providerPositionID;
    this.registrationState.userPosition.positionDisplayName = userPos.positionDisplayName === 'Other' ? this.userSelectedPosition.positionDisplayName : null;
  }

  private setDisplayOtherPositionName(userPosition: UserPosition) {
    this.displayOtherPositionName = userPosition && userPosition.positionDisplayName === 'Other';
  }

  private findUserPosition(positionId: number) {
    return this.userPositionLists.find(p => p.providerPositionID === positionId);
  }
}
