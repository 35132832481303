<div class="member-medication-container" *ngIf="!isLoading">
  <div class="member-medication-message" *ngIf="!isUserAssocToMemberPcp">
    <app-member-custom-messages-nonpcp></app-member-custom-messages-nonpcp>
  </div>
  <div class="member-medication-body" *ngIf="isUserAssocToMemberPcp">
    <p-dataView [value]="medications" [sortField]="sortField" [sortOrder]="sortOrder" (onSort)="onSortInit($event)">
      <ng-template pTemplate="header">
        <div class="medication-history-header">
          <img src="./assets/images/medications-icon.png" class="medication-icon">
          <h4>{{tabName}}</h4>
          <div class="header-right">
            <div class="sort-text">Sort by: </div>
            <p-dropdown [showClear]="false" [options]="sortOptions" [(ngModel)]="sortKey" optionLabel="label" 
              (onChange)="onSortChange($event)"></p-dropdown>
          </div>
        </div>
      </ng-template>
      <ng-template let-medications pTemplate="list">
        <div class="member-medication-row" *ngFor="let row of medications; let first = first">
          <div class="fill-date">Fill Date:</div>
          <div class="fill-date-v">{{row.fillDate | date: 'MM/dd/yyyy'}}</div>
          <div class="dosage-form">Dosage form:</div>
          <div class="dosage-form-v">{{row.dosageForm}}</div>
          <div class="drug-name">Label name:</div>
          <div class="drug-name-v">
            <span class="hover-name" title="{{row.drugName}}">{{row.drugName}}</span>
          </div>
          <div class="quantity">Quantity:</div>
          <div class="quantity-v">{{row.metricQuantity}}</div>
          <div class="day-supply">Day supply:</div>
          <div class="day-supply-v">{{row.daySupply}}</div>
          <div class="prescriber-name">Prescriber name:</div>
          <div class="prescriber-name-v">{{row.prescriberName}}</div>
          <div class="prescriber-npi">Prescriber NPI:</div>
          <div class="prescriber-npi-v">{{row.npiPhysician}}</div>
          <div class="pharmacy-name">Pharmacy name:</div>
          <div class="pharmacy-name-v" #pharmacyContainer>
            <a href="javascript:void(0);" class="pharmacy-name-link" title="{{row.pharmacyAddress.address1}} {{row.pharmacyAddress.address2}} {{row.pharmacyAddress.city}} {{row.pharmacyAddress.state}} {{row.pharmacyAddress.zipCode.substring(0,5)}} {{row.pharmacyAddress.phone | phone}}"
               [pTooltip]="getPharmacyAddress(row)"
               tooltipEvent="focus"
               tooltipStyleClass="pharmacy-name-tooltip"
               [appendTo]="pharmacyContainer"
               tooltipPosition="top">
              {{row.pharmacyName}}
            </a>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <div class="member-medication-footer" *ngIf="isUserAssocToMemberPcp">
          {{totalMedications}} results
        </div>
      </ng-template>
    </p-dataView>
  </div>
</div>
