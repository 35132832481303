import { OnInit, Input, AfterViewInit, Inject, Component, AfterRenderOptions, AfterViewChecked, SecurityContext } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-alert-info',
  templateUrl: './alert-info.component.html',
  styleUrls: ['./alert-info.component.scss']
})
export class AlertInfoComponent implements OnInit, AfterViewChecked {
  public displayAlert = false;
  public subjectInfoId: string;
  public contentInfoId: string;

  @Input() subjectMessage: string;
  @Input() contentMessage: string;
  @Input() uniqueID: string;

  constructor(@Inject(DOCUMENT) private document: Document,
  private sanitizer: DomSanitizer) { }


  ngOnInit() {
    this.displayAlert = this.subjectMessage.length > 0 || this.contentMessage.length > 0;
    this.subjectInfoId = (!this.uniqueID || this.uniqueID.trim().length === 0) ? "subject-info" : "subject-info-" + this.uniqueID;
    this.contentInfoId = (!this.uniqueID || this.uniqueID.trim().length === 0) ? "content-info" : "content-info-" + this.uniqueID;

    console.log("Alert Component");
  }

  ngAfterViewChecked(): void {
    this.setContentMessages();
  }

  private setContentMessages() {
    let elSubject = this.document.getElementById(this.subjectInfoId);
    if(elSubject) {
      if (this.subjectMessage && this.subjectMessage.trim().length > 0) {
        elSubject.innerHTML = this.subjectMessage;
        elSubject.style.display = 'inline';
      } else {
        elSubject.style.display = 'none';
      }
    }
    
    let elContent = this.document.getElementById(this.contentInfoId);
    if(elContent) {
      if(this.contentMessage && this.contentMessage.trim().length > 0) 
        elContent.innerHTML = this.sanitizer.sanitize(SecurityContext.HTML,this.contentMessage);
    }
  }
}
