import { UserStatus } from '../../services/models/portal-user';
import { SelectItem } from 'primeng/api';

export class UserStatusMap {
  public static statusItems: SelectItem[] = [
    { value: UserStatus.All, label: 'All' },
    { value: UserStatus.Pending, label: 'Pending', icon: 'fa fa-hourglass-start' },
    { value: UserStatus.Incomplete, label: 'Incomplete', icon: 'fa fa-clipboard' },
    { value: UserStatus.Approved, label: 'Approved', icon: 'fa fa-check' },
    { value: UserStatus.Active, label: 'Active', icon: 'fa fa-bolt' },
    { value: UserStatus.Declined, label: 'Declined', icon: 'fa fa-times' },
    { value: UserStatus.Deactivated, label: 'Deactivated', icon: 'fa fa-user-times' },
    { value: UserStatus.Inactive, label: 'Inactive', icon: 'fa fa-user-times'},
    { value: UserStatus.AdminLocked, label: 'Admin Locked', icon: 'fa fa-lock'},
    { value: UserStatus.Reinstated, label: 'Reinstated', icon: 'fa fa-user'},
    { value: UserStatus.LOCKED, label: 'Locked', icon: 'fa fa-lock'},

  ];

  public static mapStatustoIcon(status: UserStatus): string
  {
    let cssClass = "";
    UserStatusMap.statusItems.forEach(item => {
      if (item.value == status) {
        cssClass = item.icon;
      }
    })
    return cssClass;
  }

  public static mapStatusToSelectItem(status: UserStatus): SelectItem {
    let item: SelectItem = null;
    UserStatusMap.statusItems.forEach(x => {
      if (x.value == status) {
        item = x;
      }
    });
    return item;
  }

  public static getPortalUserStatusItems(): SelectItem[] {
    return UserStatusMap.statusItems.filter (status => {
      return status.value === UserStatus.All ||
        status.value === UserStatus.Pending ||
        status.value === UserStatus.Active ||
        status.value === UserStatus.Approved ||
        status.value === UserStatus.Declined;
    });
  }
}
