export class UserProviderCollection {
  public userName: string;
  public userID: string;
  public providerCollectionID?: number;
  public userProviderCollectionXrefID?: number;
  public roleID: number;
  public lastVerified: Date;
  public lastVerifiedBy: string;
  public modifiedDate: Date;
  public modifiedBy: string;
  public userXrefEffDate: Date;
  public userXrefTermDate: Date;
}
