<p-dialog [(visible)]="display" [modal]="true" [responsive]="true"
  [style]="{width: '350px', minWidth: '200px'}" [minY]="70" [maximizable]="true"
  [baseZIndex]="10000" [modal]="true" [resizable]="false" [closable]="false"
  [showHeader]="false" [transitionOptions]="'250ms'">
    <span class="dot"></span>
    <span class="dot"></span>
    <span class="dot"></span>
    <p class="wait-message">Please wait while we process your request.
    </p>
</p-dialog>
