<div class="pcp-agreement-container">
  <div class="header-icon">
    <img src="./assets/images/circle-exclamation-icon.png" width="70" height="70">
  </div>
  <span class="header">This Role is Reserved for PCP's Only</span>
  <p class="content">By selecting this role, you agree that the assignee is a PCP.</p>
  <div class="buttons">
    <button type="button" id="btnPcpAgreeToContinue" class="btn btn-primary btn-agree" (click)="onAgree()">I AGREE</button>
  </div>
</div>
