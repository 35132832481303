import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';

import { Constants } from '../../app.constants';
import { Messages } from '../../app.messages';

@Component({
  selector: 'create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit {
  public passwordCompare: string;
  public numberChars: number;
  public symbolChars: number;
  public uppercaseChars: number;
  public lowercaseChars: number;
  public mustContainChars: number;
  public userNameMatch: boolean;
  public containsSpace: boolean;
  public passwordMinLength: boolean;
  public isReset: boolean = false;
  public displayPasswordResetHeader = false;
  public displaySuccessUpdate = true;
  public hidePasswordChecked = true;
  public passwordChangeHeader: string;
  private specialCharsRe = /[^a-zA-Z0-9]/;
  public redirectPage: string;
  @Input() public userName: string;
  @Input() public password: string;
  @Input() public passwordSet: boolean;
  @Input() public isSameAsOldPassword: boolean;
  @Input() public componentUsing: string;
  @Input() public footerTemplate: TemplateRef<any>;
  @Output() valid = new EventEmitter();
  @Output() passwordChange = new EventEmitter();

  constructor(
    private constants: Constants,
    public messages: Messages
  ) {
  }

  ngOnInit() {
    if (this.password == null || this.password.length > 0) {
      this.password = '';
    }

    this.passwordCompare = this.password;

    if (this.componentUsing === 'reset-password' || this.componentUsing === 'change-password') {
      this.passwordChangeHeader = 'Reset Password';
      this.isReset = true;
      this.redirectPage = 'dashboard.';
    } else if (this.componentUsing === 'forgot-password') {
      this.passwordChangeHeader = 'Forgot Password';
      this.isReset = false;
      this.redirectPage = 'login page.';
    } else if (this.componentUsing === 'setup-password') {
      this.passwordChangeHeader = 'Setup Password';
      this.isReset = false;
      this.redirectPage = 'login page.';
    } else if (this.componentUsing === 'profile-change-password') {
      this.passwordChangeHeader = '';
      this.isReset = true;
      this.redirectPage = 'dashboard.';
    } else {
      this.passwordChangeHeader = 'Forgot Password';
      this.isReset = true;
      this.redirectPage = 'dashboard.';
    }

    this.displaySuccessUpdate = this.componentUsing !== 'change-password';
    this.onHidePassword({ checked: false });
  }

  public validatePassword() {
    this.numberChars = 0;
    this.symbolChars = 0;
    this.uppercaseChars = 0;
    this.lowercaseChars = 0;
    this.userNameMatch = false;
    this.containsSpace = false;
    this.passwordMinLength = false;

    for (let i = 0; i < this.password.length; i++) {
      let c = this.password[i];
      if (c.toString().trim() == "") {
        this.containsSpace = true;
      }

      if (c >= 'A' && c <= 'Z' && this.uppercaseChars === 0) {
        this.uppercaseChars++;
      }
      else if (c >= 'a' && c <= 'z' && this.lowercaseChars === 0) {
        this.lowercaseChars++;
      }
      else if (c >= '0' && c <= '9' && this.numberChars === 0) {
        this.numberChars++;
      }
      else if (this.specialCharsRe.test(c) && this.symbolChars === 0 && c.toString().trim() !== "") {
        this.symbolChars++;
      }

      this.passwordMinLength = this.password.trim().length >= this.constants.PasswordMinLength;
    }
    this.mustContainChars = this.uppercaseChars + this.lowercaseChars + this.numberChars + this.symbolChars;

    let regExpMatch = new RegExp(this.userName, 'i');
    this.userNameMatch = this.password.match(regExpMatch) === null ? true : false;

    this.validateBothPasswords();
  }

  public validateBothPasswords() {
    const valid = this.passwordMinLength && 
    this.mustContainChars >= this.constants.PasswordMustContain && 
    this.password === this.passwordCompare && 
    this.userNameMatch &&
    this.containsSpace === false;
    this.valid.emit(valid);
  }

  public change(newPassword){
    this.password = newPassword;
    this.passwordChange.emit(newPassword);
  }

  public onHidePassword(e): void {
    document.getElementById('txtPassword').setAttribute('type', e.checked ? "password" : "text");
    document.getElementById('txtPasswordCompare').setAttribute('type', e.checked ? "password" : "text");
  }
}
