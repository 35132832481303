import { Validator, NG_VALIDATORS, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { Directive,  Input } from '@angular/core';
import moment from 'moment';

@Directive({
  selector: '[futureDateValidator]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: FutureDateValidatorDirective, multi: true }
  ]
})
export class FutureDateValidatorDirective implements Validator {

  public today = new Date();

  validate(c: UntypedFormControl): ValidationErrors {
    const formDate = moment(c.value, 'YYYY-MM-DD', true);
    const todaysDate = moment(this.today, 'YYYY-MM-DD', true);

    if (formDate > todaysDate) {
        return { 'isFutureDate': true };
    } else {
        return null;
    }
    return null;
  }
}
