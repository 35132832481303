<div class="member-claim-container" *ngIf="!isLoading">
  <div class="member-claim-body">
    <p-dataView [value]="claims" [sortField]="sortField" [sortOrder]="sortOrder" (onSort)="onSortInit($event)">
      <ng-template pTemplate="header">
        <div class="claim-history-header">
          <img src="./assets/images/claims-history-icon.png" class="claim-icon">
          <h4>{{tabName}}</h4>
          <div class="header-right">
            <div class="sort-text">Sort by: </div>
            <p-dropdown [options]="sortOptions" [(ngModel)]="sortKey" optionLabel="label"
              (onChange)="onSortChange($event)"></p-dropdown>
          </div>
        </div>
      </ng-template>
      <ng-template let-claims pTemplate="list">
        <div class="member-claim-row" *ngFor="let claim of claims; let first = first">
          <div class="service-date-label">
            Date of Service:
          </div>
          <div class="service-date-content">
            {{claim.dateOfService| date: 'MM/dd/yyyy'}}
          </div>
          <div class="provider-name-label">
            Provider:
          </div>
          <div class="provider-name-content">
            <span title="{{claim.providerInfo.providerName}}">
              {{claim.providerInfo.providerName}}
            </span>
          </div>
          <div class="diagnoses-header">Diagnoses:</div>
          <div class="diagnoses-scroll">
            <span *ngFor="let diagnosis of claim.diagnoses" title="{{diagnosis.diagnosisDesc}}">
              {{diagnosis.diagnosisCode}} /
              {{diagnosis.diagnosisDesc}}
            </span>
          </div>
          <div class="services-header">Services:</div>
          <div class="services-scroll">
            <span *ngFor="let procedure of claim.serviceProcedures" title="{{procedure.procedureDesc}}">
              {{procedure.procedureCode}} /
              {{procedure.procedureDesc}}
            </span>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <div class="member-claim-footer">
          {{this.totalClaims}} results
        </div>
      </ng-template>      
    </p-dataView>
  </div>
</div>
