<div class="user-position-authorized-container">
  <form name="authorizedByForm" #authorizedByForm="ngForm" (ngSubmit)="onSubmit()" autocomplete="off">
    <div class="spacer-top" *ngIf="!displayPositionSelection"></div>
    <div class="authorized-by-container-body" *ngIf="displayAuthorizedBy">
      <div class="content">
        <span class="header">
          Authorized By
        </span>
        <p>Enter the name of the admin you spoke with at the Provider office.</p>
        <div class="txtAuthorizedBy">
          <input type="text" id="authorizedByName" name="authorizedByName" placeholder="Name of contact" class="form-control"
            tabindex="1" [(ngModel)]="authorizedByName" aria-describedby="member-id-input" minlength="3" maxlength="40" autocomplete="off" (keyup)="onKeyPress()" required />
        </div>
      </div>
    </div>
    <div class="spacer-authorized-by" *ngIf="!displayPositionSelection"></div>
    <div class="position-container-body" *ngIf="displayPositionSelection">
      <span class="header">
        Select a Position for User
      </span>
      <p>You are granting a User access to one or more modules that require further justification. Please select a position that best describes the User’s job function.</p>
      <p>By clicking Save, you are attesting that the User requires access to these modules to perform their required and assigned job duties. You attest that the User is authorized to view the Protected Health Information (PHI) contained in these modules based on their role and job responsibilities within the organization.</p>
      <p>CalOptima Health reserves the right to request additional documentation (e.g. job description, policies) or justification to determine if access permissions are appropriately assigned.</p>
      <p>If you have any questions about granting access, please contact CalOptima Health Provider Relations at:  1-714-246-8600</p>
      <div>
        <table boder="1">
          <tr>
            <td>
              <div class="user-position-dropdown" *ngIf="positionSelection.length">
                <p-dropdown
                  name="selectedPosition"
                  id="selectedPosition"
                  [options]="positionSelection"
                  placeholder="Select a position"
                  [(ngModel)]="selectedPosition"
                  [disabled]="isPcpRole"
                  (onChange)="onSelectPosition()"
                  tabindex="2"
                  class="selectedPosition">
                </p-dropdown>
                <div class="alert-error"></div>
              </div>
            </td>
            <td>
              <div class="user-position-other" *ngIf="displayOtherPositionName">
                <input type="text" class="form-control" placeholder="*Position name" name="otherPositionName" tabindex="3"
                  id="otherPositionName" required maxlength="100" #positionName="ngModel" [(ngModel)]="positionOtherName" autocomplete="off"/>
                <div class="alert-error" *ngIf="positionName.invalid && positionName.touched">Please enter a position name.</div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="reason-container-body" *ngIf="displayReasonForChange">
      <span class="header">
        Reason for change
      </span>
      <p>Enter the reason for this modification.</p>
      <div class="reason-textarea">
        <textarea name="reason" [(ngModel)]="reasonForPermissionChange" rows="4" cols="63" maxlength="500"></textarea>
      </div>
    </div>
    <div class="buttons">
      <div (click)="onCancel()" tabindex="3"  class="btn btn-secondary btn-cancel">Cancel</div>
      <button tabindex="4"  class="btn btn-primary btn-save" [disabled]="saveBtnDisabled">Save</button>
    </div>
  </form>
</div>
