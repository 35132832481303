export class ProviderDetailInfo {
  public prpr_Id: string = '';
  public providerId: string = '';
  public providerName: string = '';
  public providerSpecialtyCode: string;
  public providerSpecialtyDesc: string;
  public addressInfo: ProviderAddressInfo = new ProviderAddressInfo;
}

export class ProviderAddressInfo {
  public providerAddressType: string = '';
  public providerAddressLine1: string = '';
  public providerAddressLine2: string = '';
  public providerCity: string = '';
  public providerState: string = '';
  public providerZipCode: string = '';
  public providerPhone: string = '';
  public providerFax: string = '';
}
