<div class="header">
  <div class="leftside">
    <div class="appImage">
      <a href="#">
        <img src="{{constants.LogoUrl}}" class="logo img-responsive" />
      </a>
    </div>
  </div>
  <div class="rightside">
    <div class="collection-menu" *ngIf="userOrganizations && userOrganizations.length">
      <div class="collection-title">Organization:</div>
      <p-dropdown *ngIf="userOrganizations.length"
        name="selectedUserOrganization"
        id="ddOrganization"
        class="organization"
        (onChange)="onOrganizationChanged($event)"
        [options]="userOrganizations"
        [optionLabel]="'collectionName'"
        [(ngModel)]="selectedUserOrganization">
      </p-dropdown>
    </div>
    <div class="user-menu" *ngIf="!expired">
      <a class="" role="button" id="dropdownTaskLink" 
        (click)="userTaskList.toggle($event)">
        <div class="task-container">
          <i class="fa fa-cog" aria-hidden="true"></i>
          <span class="task-count" *ngIf="users && users.length > 0">{{users.length}}</span>
        </div>
      </a>
      <p-overlayPanel id="userTaskList" styleClass="task-list-menu" (onShow)="repositionOverlayPanel('task-list-menu')" #userTaskList>
        <div class="task-list">
          <div class="task-list-header">
            <div class="task-list-icon">
              <i class="fa fa-list-ul"></i>
            </div>
            <span>Task List</span>
          </div>
          <div class="task-item-container">
            <div class="task-item" *ngFor="let user of users" (click)="onSelected(user, userTaskList, $event)">
              <i class="fa fa-external-link"></i>
              <div class="task-item-header">New user request</div>
              <div class="task-item-text">{{user.name}} | {{user.email}}</div>
              <div class="task-item-text">{{user.collectionName}}</div>
              <div class="task-item-text">Registered: {{user.createDate | date: 'MM/dd/yyyy'}}</div>
            </div>
          </div>
          <div class="no-task-list" *ngIf="!users || users.length === 0">
            <p>No task(s) found.</p>
          </div>
        </div>
      </p-overlayPanel>
    </div>
    <div class="logout-name" *ngIf="!expired">
      <a class="header-btn" href="javascript:void(0);" role="button" id="dropdownMenuLink" class="header-btn dropdown-toggle"
        (click)="myProfile.toggle($event)">
        {{name}}
      </a>
      <p-overlayPanel styleClass="user-link-menu" (onShow)="repositionOverlayPanel('user-link-menu')" #myProfile>
        <div class="user-menu-dropdown">
          <a class="dropdown-item" href="javascript:void(0);" id="lnkMyProfile" (click)="myProfileClick(myProfile, $event)" *ngIf="displayMyProfile">
            <i class="fa fa-user" aria-hidden="true" title="My Profile"></i>
            My Profile
          </a>
          <a class="dropdown-item" id="lnkLogout" (click)="logOut()">
            <i class="fa fa-sign-out" aria-hidden="true" title="Logout"></i>
            Logout
          </a>
        </div>
      </p-overlayPanel>
    </div>
  </div>
</div>
