<div class="member-search-container">
  <div class="member-search-tabs">
    <p-tabView styleClass="member-search-tabview-styles">
      <p-tabPanel styleClass="" header="Member ID">
        <div class="member-id-search">
          <form name="memberSearchIdForm" #searchIdForm="ngForm" class="container member-search-form"
            (ngSubmit)="doSearchById()" autocomplete="off">
            <div class="d-flex flex-column">
              <label class="label-super-text" for="memberIdInput">Member ID</label>
              <input type="text" id="memberIdInput" name="memberIdInput" placeholder="Enter ID" class="form-control"
                tabindex="1" #memberIdInput="ngModel" [(ngModel)]="memberId" aria-describedby="member-id-input" required
                minlength="9" maxlength="11" autocomplete="off">
              <label class="label-sub-text" for="memberIdInput">CIN, MEDS ID, or MBI accepted</label>
              <div *ngIf="memberIdInput.invalid && memberIdInput.touched" class="label-sub-text alert-error">
                <div *ngIf="memberIdInput.errors">
                  Member ID is required.
                </div>
              </div>
            </div>
            <div class="d-flex flex-column align-items-stretch">
              <button class="btn btn-primary member-search-button" type="submit" [disabled]="!searchIdForm.form.valid"
                tabindex="2">Search</button>
            </div>
            <div class="d-flex flex-column align-items-stretch">
              <button class="btn btn-outline-secondary member-clear-button"
                (click)="searchIdForm.reset(); searchClear()" tabindex="2">Clear</button>
            </div>
          </form>
        </div>
      </p-tabPanel>
      <p-tabPanel styleClass="height-tv" header="Name">
        <div class="member-id-search">
          <form name="memberSearchNameForm" #searchNameForm="ngForm" class="container member-search-form"
            (ngSubmit)="doSearchByName()" autocomplete="off">
            <div class="d-flex flex-column mb-3">
              <label class="label-super-text" for="firstNameIdInput">First</label>
              <input type="text" id="firstNameIdInput" name="firstNameIdInput" placeholder="First name"
                #firstNameIdInput="ngModel" class="form-control member-search-input" [(ngModel)]="firstName"
                aria-describedby="member-id-input" required tabindex="1" autocomplete="off">
              <div *ngIf="firstNameIdInput.invalid && (firstNameIdInput.touched)" class="label-sub-text alert-error">
                <div *ngIf="firstNameIdInput.errors">
                  Required
                </div>
              </div>
            </div>
            <div class="d-flex flex-column my-3 align-items-stretch">
              <label class="label-super-text" for="lastNameIdInput">Last</label>
              <input type="text" id="lastNameIdInput" name="lastNameIdInput" #lnIdInput="ngModel"
                placeholder="Last name" class="form-control member-search-input" [(ngModel)]="lastName"
                aria-describedby="member-id-input" required tabindex="2" autocomplete="off">
              <div *ngIf="lnIdInput.invalid && (lnIdInput.dirty || lnIdInput.touched)"
                class="label-sub-text alert-error">
                <div *ngIf="lnIdInput.errors.required">
                  Required
                </div>
              </div>
            </div>
            <div class="d-flex flex-column mt-3 align-items-stretch">
              <label class="label-super-text" for="monthInput">Date of birth</label>
              <div class="d-flex flex-row justify-content-between">
                <input type="text" id="monthInput" name="monthInput" #monthInput="ngModel" [(ngModel)]="dobMonth"
                  (keyup)="processKeyUp($event, monthInput, dayInput)" placeholder="MM" minlength="1" maxlength="2"
                  min="1" max="12" required tabindex="3" pattern="^(0?[1-9]|1[012])$" autocomplete="off"
                  class="form-control member-search-by-name-input flex-fill" aria-describedby="month-input">
                <input type="text" id="dayInput" name="dayInput" #dayInput="ngModel" placeholder="DD"
                  (keyup)="processKeyUp($event, dayInput, yearInput)" minlength="1" maxlength="2" min="1" max="31"
                  [(ngModel)]="dobDay" required tabindex="4" pattern="^((0?[1-9])|([1-2][0-9])|(3[01]))$"
                  autocomplete="off" class="form-control member-search-by-name-input flex-fill"
                  aria-describedby="day-input">
                <input type="text" id="yearInput" name="yearInput" #yearInput="ngModel" placeholder="YYYY"
                  [(ngModel)]="dobYear" minlength="4" maxlength="4" required tabindex="5"
                  pattern="^(19((1[1-9])|([0-9][0-9])))|(20((1[1-9])|([0-9][0-9])))$" autocomplete="off"
                  class="form-control member-search-year flex-fill" aria-describedby="year-input"
                  (keyup)="processKeyUp($event, yearInput, submitButton)">
              </div>
              <div class="d-flex flex-row my-0 align-items-stretch p-g" *ngIf="!searchNameForm.form.valid">
                <div class="p-g-4">
                  <div *ngIf="monthInput.invalid && (monthInput.dirty || monthInput.touched)" class="label-sub-text alert-error">
                    <div *ngIf="monthInput.errors.required">
                      Required
                    </div>
                    <div *ngIf="monthInput.errors.pattern">
                      Enter month between 01 - 12
                    </div>
                  </div>
                </div>
                <div class="p-g-4">
                  <div *ngIf="dayInput.invalid && (dayInput.dirty || dayInput.touched)" class="label-sub-text alert-error">
                    <div *ngIf="dayInput.errors.required">
                      Required
                    </div>
                    <div *ngIf="dayInput.errors.pattern">
                      Enter day between 01 - 31
                    </div>
                  </div>
                </div>
                <div class="p-g-4">
                  <div *ngIf="yearInput.invalid && (yearInput.dirty || yearInput.touched)" class="label-sub-text alert-error">
                    <div *ngIf="yearInput.errors.required">
                      Required
                    </div>
                    <div *ngIf="yearInput.errors.pattern">
                      Enter year between 1900 - {{currentYear}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column mt-3 align-items-stretch">
              <label class="label-super-text" for="cityLookup">City</label>
              <div class="d-flex flex-row justify-content-between" id="cityLookup">
                <app-city-lookup (citySelected)="onCitySelected($event)" [clear]="clearTrigger" required tabindex="6"></app-city-lookup>
              </div>
            </div>

            <div class="d-flex flex-column align-items-stretch">
              <button class="btn btn-primary member-search-button" type="submit"
                [disabled]="!searchNameForm.form.valid || citySelected===null" id="submitButton" name="submitButton"
                #submitButton tabindex="7" title="Search" value="Search">Search</button>
            </div>
            <div class="d-flex flex-column align-items-stretch">
              <button class="btn btn-outline-secondary member-clear-button"
                (click)="searchNameForm.reset(); searchClear()" tabindex="7">Clear</button>
            </div>
          </form>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
