import { Component, EventEmitter, Input, Output } from '@angular/core';
import { forkJoin } from 'rxjs';
import { MemberRosterService } from 'src/app/services/member.roster.service';
import { Member } from 'src/app/services/models/member';
import { ProviderDetail, ProviderDetailsExtend } from 'src/app/services/models/provider-detail';
import { ProviderMemberRoster } from 'src/app/services/models/provider-member-roster';
import { ReportType } from 'src/app/services/models/reportype';
import { RosterType } from 'src/app/services/models/request-status';
import { memberRosterRequest } from 'src/app/services/models/requests/provider-member-roster-request';
import { RosterItem } from 'src/app/services/models/roster-item';
import { ReportsService } from 'src/app/services/reports.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-member-roster',
  templateUrl: './member-roster.component.html',
  styleUrl: './member-roster.component.scss'
})
export class MemberRosterComponent {

  @Input() member: Member;
  @Output() onSaveSuccess = new EventEmitter(); 
  @Output() onSaveFailed = new EventEmitter(); 
  @Output() onRosterChange = new EventEmitter();

  public providers: ProviderDetailsExtend[] = [];
  public displayproviders: ProviderDetailsExtend[] = [];
  public rosterItems: RosterItem[] = [];
  public memberRosters: ProviderMemberRoster[] = [];
  public selectedrosterItem: RosterItem;
  public selectAll:boolean = false;
  public dataLoaded: boolean = false;
  private sortColumn: string;
  private sortAscending: boolean;
  public relationshipdate: Date = new Date();
  public maxDate: Date = new Date();

  constructor(private reportService: ReportsService, 
    private memberRosterService: MemberRosterService,
    private sessionService: SessionService) {
      
  }

  ngOnInit() {

    let memberCins: string[] = [];
    memberCins.push(this.member.cin);

    this.memberRosterService.getRosterItems().subscribe(result1=>{
      this.rosterItems = result1;
      this.selectedrosterItem = this.rosterItems[0];
  
      this.memberRosterService.getMemberRoster(memberCins).subscribe(result2=>{
        this.memberRosters = result2;

        if(!this.isInternalUser) {
          this.reportService.getProvidersForPCPMemberRoster(this.sessionService.currentPermission.providerCollectionID).subscribe(result3=>{
            result3.forEach(x=>{
              var temp: ProviderDetailsExtend = new ProviderDetailsExtend();
              temp.copyFrom(x);
              this.providers.push(temp);
              this.displayproviders.push(temp);
            });
            
            this.onChange(this.selectedrosterItem);
            this.dataLoaded = true;
          });
        }
        else {
          this.dataLoaded = true;
        }
      });
    });
  }

  public onSelectAll(item: boolean) {

    this.selectAll = item;

    this.displayproviders.forEach(x=>{
      if(x.disabled)
        x.selected = x.disabled;
      else
        x.selected = this.selectAll;
    });

    this.onRosterChange.emit();
  }

  public onSelect(provider: ProviderDetailsExtend) {
    this.onRosterChange.emit();
  }

  public onChange(rosterItem: RosterItem) {

    this.displayproviders = [];
    this.providers.forEach(x => {
      x.selected = false;

      var itemFound = this.memberRosters.find(y=> y.calProviderId.trim().toUpperCase() == x.calProviderID.trim().toUpperCase() 
        && y.rosterTypeID == rosterItem.rosterTypeID);
        
      x.selected = itemFound ? true : false;
      x.disabled = x.selected;

      this.displayproviders.push(x);
    });
  }

  public refreshRoster() {
    let memberCins: string[] = [];
    memberCins.push(this.member.cin);

    this.memberRosterService.getMemberRoster(memberCins).subscribe(x=>{
      this.memberRosters = x;
      this.onChange(this.selectedrosterItem);
    });
  }

  public onSort(e: any): void {
    this.providers.sort((data1, data2) => {
      let value1 = data1[e.field];
      let value2 = data2[e.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (e.order * result);
    });
    this.sortAscending = e.order == 1;
    this.sortColumn = e.field;
  }

  public get isInternalUser() : boolean {
    return this.sessionService.isEnterpriseAdmin || this.sessionService.isEnterpriseUser;
  }

  public onSearch($event) {

    var searchPn = $event.providerName;
    var searchPId = $event.providerId;
    var searchPT = $event.providerTin

    if(this.isInternalUser) {
      this.reportService.searchProvidersForPCPMemberRoster(searchPId, searchPn, searchPT).subscribe(result=>{

        this.providers = [];
        this.displayproviders = [];
        
        result.forEach(x=>{
          var temp: ProviderDetailsExtend = new ProviderDetailsExtend();
          temp.copyFrom(x);
          this.providers.push(temp);
          this.displayproviders.push(temp);
        });
        
        this.onChange(this.selectedrosterItem);
      });
    }
    else {
      this.displayproviders = [];
      this.providers.forEach(x=>{
  
          if(searchPId && searchPId.trim() != '' && x.calProviderID.trim().toUpperCase() == searchPId.trim().toUpperCase()) {
            this.displayproviders.push(x);
          } 
          else if(searchPn && searchPn.trim() != '' && x.name.trim().toUpperCase().includes(searchPn.trim().toUpperCase())) {
            this.displayproviders.push(x);
          }
          else if(searchPT && searchPT.trim() != '' && x.taxId.trim().toUpperCase() == searchPT.trim().toUpperCase()) {
            this.displayproviders.push(x);
          }          
          else if((searchPId == null || searchPId.trim() == '') &&
                    (searchPT == null || searchPT.trim() == '') &&
                    (searchPn == null || searchPn.trim() == '')){
              this.displayproviders.push(x);
          }
      });
    }
    
  }

  public saveRoster() {
    try{

      let addedProviders: string[] = [];

      this.providers.forEach(x=>{
        if(x.selected && !x.disabled)
          addedProviders.push(x.calProviderID);
      });

      var today: Date = new Date();
      var valid: boolean = true;
      var validationErrorMessage: string = "";

      if(!this.relationshipdate || this.relationshipdate > today)
      {
        validationErrorMessage = "Invalid relationship date.";
        valid = false;
      }        
      if(valid && addedProviders.length == 0) {
        validationErrorMessage = "No provider selected.";
        valid = false;        
      }

      if(valid) {
        this.memberRosterService.addMemberToProviderRoster(this.member.cin, this.selectedrosterItem.rosterTypeID, this.relationshipdate, addedProviders)
          .subscribe(x=>{        
            this.refreshRoster();
            this.onSaveSuccess.emit("Roster updated successfully.");
          },
          (error)=>{
            this.onSaveFailed.emit("Error occurred during save. Please try again !!!");  
          }
        );
      }
      else {
        this.onSaveFailed.emit(validationErrorMessage);
      }
    }
    catch(ex) {
      this.onSaveFailed.emit("Error occurred during save. Please try again !!!");
    }
  }

  public sortClass(column: string): string {
    let cls: string;
    if (column == this.sortColumn) {
      cls = this.sortAscending ? "fa fa-chevron-up" : "fa fa-chevron-down";
    }
    else {
      cls = "fa fa-chevron-up";
    }
    return cls;
  }

  public onSelectDate() {

  }
}
