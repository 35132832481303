import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PortalUIComponentsModule } from '@caloptima/portal-ui-components';
import { AngularSplitModule } from 'angular-split';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { RadioButtonModule } from 'primeng/radiobutton';

import { AppComponentsModule } from '../app-components/app-components.module';
import { CollectionManagerRoutingModule } from './collection-manager-routing.module';
import { CollectionDetailComponent } from './collection-detail/collection-detail.component';
import { TabViewModule } from 'primeng/tabview';
import { CollectionListComponent } from './collection-list/collection-list.component';
import { ProvidersComponent } from './collection-detail/providers/providers.component';
import { UsersComponent } from './collection-detail/users/users.component';
import { CollectionVerifyComponent } from './collection-verify/collection-verify.component';
import { CollectionVerifyUserComponent } from './collection-verify/collection-verify-user/collection-verify-user.component';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { CollectionDetailProvidersSearchComponent } from './collection-detail/providers/collection-detail-providers-search/collection-detail-providers-search.component';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  declarations: [
    CollectionDetailComponent,
    CollectionListComponent,
    ProvidersComponent,
    UsersComponent,
    CollectionVerifyComponent,
    CollectionVerifyUserComponent,
    CollectionDetailProvidersSearchComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    PortalUIComponentsModule,
    CollectionManagerRoutingModule,
    AppComponentsModule,
    TabViewModule,
    ToastModule,
    ConfirmDialogModule,
    DialogModule,
    TableModule,
    CheckboxModule,
    RadioButtonModule,
    AngularSplitModule,
    MessagesModule,
    MessageModule,
    TooltipModule,
    DropdownModule
  ],
  providers: [
    // { provide: CollectionService, useClass: MockCollectionService }
  ]
})
export class CollectionManagerModule { }
