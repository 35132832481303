import { PortalUser, UserType, UserRoleType } from './portal-user';
import { SelectItem } from 'primeng/api';

export class UserTypeMap {
  public static typeItems: SelectItem[] = [
    { value: UserType.EnterpriseAdmin, label: 'Enterprise Admin', icon: 'fa fa-user-plus' },
    { value: UserType.ExternalUser, label: 'External User', icon: 'fa fa-user' },
    { value: UserType.InternalUser, label: 'Internal User', icon: 'fa fa-user' },
    { value: UserType.LocalOfficeAdmin, label: 'Local Office Admin', icon: 'fa fa-user-plus' }
  ];

  public static roleTypeItems: any[] = [
    { value: UserRoleType.EnterpriseAdmin, label: 'Enterprise Admin', icon: 'fa fa-user-plus' },
    { value: UserRoleType.LocalOfficeAdmin, label: 'Local Office Admin', icon: 'fa fa-user-plus' },
    { value: UserRoleType.PCP, label: 'PCP', icon: 'fa fa-stethoscope' },
    { value: UserRoleType.EligibilityViewer, label: 'Eligibility Viewer', icon: 'fa fa-user-md' },
    { value: UserRoleType.ClaimsViewer, label: 'Claims Viewer', icon: 'fa fa-file-text' },
    { value: UserRoleType.CustomSetup, label: 'Custom Setup', icon: 'fa fa-sliders' },
    { value: UserRoleType.CustomEnt, label: 'Custom Setup', icon: 'fa fa-sliders' },
    { value: UserRoleType.CustomPrv, label: 'Custom Setup', icon: 'fa fa-sliders' },
    { value: UserRoleType.MedicalProfessional, label: 'Medical Professional', icon: 'fa fa-stethoscope' }
  ];

  public static mapUserType(isAdmin: boolean, isEnterpriseUser: boolean): UserType {
    let userType: UserType;
    if (isAdmin) {
      if (isEnterpriseUser)
        userType = UserType.EnterpriseAdmin;
      else
        userType = UserType.LocalOfficeAdmin;
    }
    else if (isEnterpriseUser)
      userType = UserType.InternalUser;
    else
      userType = UserType.ExternalUser;
    return userType;
  }

  public static mapUserTypeToIcon(userType: UserType): string {
    let cssClass = "";
    UserTypeMap.typeItems.forEach(item => {
      if (item.value == userType) {
        cssClass = item.icon;
      }
    })
    return cssClass;
  }

  public static mapUserRoleTypeToIcon(userRole: UserRoleType): string {
    let cssClass = "";
    UserTypeMap.roleTypeItems.forEach(item => {
      if (item.value === userRole) {
        cssClass = item.icon;
      }
    })
    return cssClass;
  }

  public static mapUserTypeToSelectItem(userType: UserType): SelectItem {
    let item: SelectItem = null;
    UserTypeMap.typeItems.forEach(x => {
      if (x.value == userType) {
        item = x;
      }
    });
    return item;
  }

  public static mapUserRoleTypeToSelectItem(userRole: UserRoleType): SelectItem {
    let item: any = null;
    UserTypeMap.roleTypeItems.forEach(x => {
      if (x.value === userRole) {
        item = x;
      }
    });
    return item;
  }

  public static isLOA(userRole: UserRoleType) {
    return userRole === UserRoleType.LocalOfficeAdmin;
  }

  public static isEA(userRole: UserRoleType) {
    return userRole === UserRoleType.EnterpriseAdmin;
  }

  public static isPCP(userRole: UserRoleType) {
    return userRole === UserRoleType.PCP;
  }
}
