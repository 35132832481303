<div class="pcpattestation-container">
  <div class="pcpattestation-container-reporttype">
    <app-reports-type [reportType]="'awp'"></app-reports-type>
  </div>
  <div class="pcpattestation-alert">
    <app-alert-info subjectMessage=" " uniqueID="reportsawp" contentMessage="Narrow your search using the known provider or member."></app-alert-info>
  </div>
  <div class="pcpattestation-container-main">
    <p-tabView styleClass="pcpattestation-tabview-styles" [activeIndex]="activeindex" #tabView>
      <p-tabPanel [header]="getTabName(0)">
        <ng-template pTemplate="header">
          {{getTabName(0)}}
        </ng-template>
        <ng-template pTemplate="content">
          <app-awp-provider #awpproviderComponent></app-awp-provider>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel *ngIf="canViewTab(1)" [header]="getTabName(1)">
        <ng-template pTemplate="header">
          {{getTabName(1)}}
        </ng-template>
        <ng-template pTemplate="content">
          <app-awp-member  #awpmemberComponent></app-awp-member>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
<app-continue-dialog key="continueDialog"></app-continue-dialog>
