import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AuthenticationService, UserProfile } from '@caloptima/authentication';
import { OAuthService } from 'angular-oauth2-oidc';
import { PortalConfig } from 'src/app/portal-config';
import { AttestationDetail } from 'src/app/services/models/attestation-detail';
import { AttestationStatus } from 'src/app/services/models/attestationstatus';
import { ProviderDetail } from 'src/app/services/models/provider-detail';
import { BHIMeasurementPeriod } from 'src/app/services/models/responses/bhi-measurement-period';
import { ReportsService } from 'src/app/services/reports.service';

const ATTESTATION_VERSION: string = "1.0"

@Component({
  selector: 'attestationbhi-dialog',
  templateUrl: './attestationbhi-dialog.component.html',
  styleUrls: ['./attestationbhi-dialog.component.scss']
})

export class AttestationBHIDialogComponent implements OnInit {
  @Output() submitAttestation = new EventEmitter();
  @Output() cancelAttestation = new EventEmitter();
  @Input() measurementPeriods: BHIMeasurementPeriod[];
  @Input() selectedProvider: ProviderDetail;
  public userProfile: UserProfile;
  public userEmail: string = '';
  public iagree:boolean = false;  
  public validationError: boolean = false;
  public validationMessage: string = "";

  constructor(
    private outhService: OAuthService,
    private authenticationService: AuthenticationService,
    private portalConfig: PortalConfig,
    private reportsService: ReportsService) {
      
  }
  ngOnInit() {
    
    const claims = this.outhService.getIdentityClaims();
      if (claims) {
        this.userEmail = claims['sub'];
      }

    this.authenticationService.findUsers(null, this.userEmail, this.portalConfig.ClientId,1, 1).subscribe(users=>{
      if(users != null && users.length == 1) {
        this.userProfile = users[0];
      }
    });
  }

  public get fullname(): string {
    if(this.userProfile)
      return this.userProfile.firstName + ' ' + this.userProfile.lastName;
    else
      return "";
  }

  public onSubmit() {
    let item: any = {
      'email': this.userProfile ? this.userProfile.email : this.userEmail,
      'userID': this.userProfile ? this.userProfile.userID : this.userEmail,
      'mobilePhone': this.userProfile ? this.userProfile.mobilePhone : "",
      'version': ATTESTATION_VERSION
    };
    this.onSubmitAttestation();
  }
  
  public onCancel() {
    this.cancelAttestation.emit();
  }

  public onSubmitAttestation() {

    this.validationError = false;

    var list:AttestationDetail[] = [];

    this.measurementPeriods.forEach(x=>{
      if(x.selected) {

        var attestionDetail: AttestationDetail = {
          attestationDate : new Date(),
          attestationDueDate : new Date(),
          attestationEmail : this.userProfile ? this.userProfile.email : this.userEmail,
          attestationName  : this.userProfile ? this.userProfile.userID : this.userEmail,
          attestationPhone : this.userProfile ? this.userProfile.mobilePhone : "",
          attestationStatus : AttestationStatus.Attested,
          attestationVersion : ATTESTATION_VERSION,
          collectionName : this.selectedProvider.name,
          hasChecked : true,
          pcpId : this.selectedProvider.calProviderID,
          pcpName : this.selectedProvider.name,
          pcpTIN : this.selectedProvider.taxId,        
          reportAttestationID : 0,
          reportPeriod : x.measurementPeriod,
          reportTypeCode : "BHI",
          measurementPeriodDisplay : x.measurementPeriod,     
          reportPeriodDisplay : x.measurementPeriodDisplay
        };

        list.push(attestionDetail);
      }
    });

    this.reportsService.ApplyAttestation(list).subscribe(result=> {

      let item: any = {
        'email': this.userProfile ? this.userProfile.email : this.userEmail,
        'userID': this.userProfile ? this.userProfile.userID : this.userEmail,
        'mobilePhone': this.userProfile ? this.userProfile.mobilePhone : "",
        'version': ATTESTATION_VERSION
      };
            
      this.submitAttestation.emit(item);
    },
    (error) => {
      this.validationError = true;
      this.validationMessage = "Error occurred submitting Attestation. Please try again.";
    });
  }

  public get display(): boolean {
    return true;
  }

  public set display(value: boolean) {
    this.onCancel();
  }
}
