import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProviderNetwork } from 'src/app/services/models/provider-tax-details';
import { ReportsService } from 'src/app/services/reports.service';
import { SessionService } from 'src/app/services/session.service';
import FileSaver, { saveAs } from 'file-saver';
import { LazyLoadEvent } from 'primeng/api/lazyloadevent';

@Component({
  selector: 'app-reports-pdc',
  templateUrl: './reports-pdc.component.html',
  styleUrl: './reports-pdc.component.scss'
})
export class ReportsPdcComponent implements OnInit, OnDestroy {
  public healthPlans: ProviderNetwork[];
  public displayMessage: boolean = false;
  public totalRecords: number;
  public rowsPerPage: number = 10;
  public pageNumber: number = 1;

  private sortColumn: string;
  private sortAscending: boolean;

  public columns: any = [
    { field: 'networkName', header: 'Health Network Plan Name', width: '40%', classHeader: 'header-name-column', classContent: 'name-column', sort: true },
    { field: 'networkId', header: 'Health Network Plan Code', width: '40%', classHeader: 'header-name-column', classContent: 'name-column', sort: true },
    { field: '', header: 'Action', width: '20%', classHeader: 'header-action-column', classContent: 'action-column', sort: false }
  ];
  
  constructor (
    private reportsService: ReportsService,
    private sessionService: SessionService
  ) {}

  ngOnDestroy(): void {

  }

  ngOnInit(): void {
    this.loadData();
  }

  public onGetLatest(providerNetwork: ProviderNetwork, isExcel: boolean = false) {
    this.reportsService.downloadHealthNetworkPlanPDCReport(providerNetwork.networkId, isExcel).subscribe(result=>{
      if (result) {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); 
        const day = currentDate.getDate().toString().padStart(2, '0');
      
        if (isExcel) {
          const fileName = `${providerNetwork.networkId.substring(3,5)}550${year}${month}${day}.xlsx`;
          this.saveAsFile(result, fileName, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        }
        else {
          const fileName = `${providerNetwork.networkId.substring(3,5)}550${year}${month}${day}.csv`;
          this.saveAsFile(result, fileName, 'text/csv');
        }
      }
    });
  }

  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob([buffer], { type: fileType });
    FileSaver.saveAs(data, fileName);
  }

  public onGetLatestExcel(providerNetwork: ProviderNetwork) {
    this.onGetLatest(providerNetwork, true);
  }
  
  public sortClass(column: string): string {
    let cls: string;
    if (column == this.sortColumn) {
      cls = this.sortAscending ? "fa fa-chevron-up" : "fa fa-chevron-down";
    }
    else {
      cls = "fa fa-chevron-up";
    }
    return cls;
  }

  public onPage(e: any) {
    let s = JSON.stringify(e);
  }

  public onSort(e: any): void {
    this.healthPlans.sort((data1, data2) => {
      let value1 = data1[e.field];
      let value2 = data2[e.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (e.order * result);
    });
    this.sortAscending = e.order == 1;
    this.sortColumn = e.field;
  }

  lazyLoadData(event: LazyLoadEvent) {
    if (event.sortField !== undefined) {
      this.sortAscending = event.sortOrder === 1;
      this.sortColumn = event.sortField;
      this.loadData();
    }
  }

  private loadData() {
    const currentCollectionId = this.sessionService.currentPermission.providerCollectionID;
    const healthNetworkPlanCodes = this.sessionService.currentPermission.healthNetworkPlanCodes;
    this.reportsService.getProviderHealthNetworkPlanForPDC(currentCollectionId, healthNetworkPlanCodes).subscribe(result => {
      this.healthPlans = result;
      this.totalRecords = this.healthPlans.length;
      this.displayMessage = this.healthPlans && this.totalRecords === 0;

      let param: any = {
        "field": "networkName",
        "order": 1
      }
      this.onSort(param)
    });
  }
}
