import { NgModule } from '@angular/core';
import { RouterModule, provideRoutes, Routes } from '@angular/router';
import { LogInComponent } from './login/login.component';
import { LoggedOutComponent } from './logged-out/logged-out.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SetupPasswordComponent } from './setup-password/setup-password.component';
import { ProviderRegistrationComponent } from './registration/provider-registration.component';
import { LoggedInGuard } from './common/logged-in/logged-in.guard';
import { CheckStatusComponent } from './check-status/check-status.component';
import { EulaComponent } from './registration/eula/eula.component';
import { IdentifierGuard } from '../layout/navigation/identifier-guard/identifier.guard';
import { UnsupportedBrowserComponent } from './unsupported-browser/unsupported-browser.component';

const routes: Routes = [
  { path: 'user/forgotPassword',
    component: ForgotPasswordComponent,
    canActivate: [LoggedInGuard, IdentifierGuard]
  },
  {
    path: 'user/setupPassword',
    component: SetupPasswordComponent,
    canActivate: [ IdentifierGuard]
  },
  {
    path: 'user/registration',
    component: ProviderRegistrationComponent,
    canActivate: [LoggedInGuard, IdentifierGuard]
  },
  {
    path: 'user/checkstatus',
    component: CheckStatusComponent,
    canActivate: [LoggedInGuard, IdentifierGuard]
  },
  {
    path: 'unsupportedbrowser',
    component: UnsupportedBrowserComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: 'logout',
    component: LoggedOutComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: 'login',
    component: LogInComponent,
    canActivate: [LoggedInGuard, IdentifierGuard]
  },
  {
    path: 'tos',
    component: EulaComponent
  },
  { path: 'user/login', redirectTo: '/'},
  { path: 'user/logout', redirectTo: '/'}
];

export const routedComponents = [
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class UserRoutingModule { }
