import { Component, OnInit, Output, Input } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { PortalUser } from '../../../services/models/portal-user';

@Component({
  selector: 'app-user-approve-decline-dialog',
  templateUrl: './user-approve-decline-dialog.component.html',
  styleUrls: ['./user-approve-decline-dialog.component.scss']
})
export class UserApproveDeclineDialogComponent implements OnInit {
  public userFullName: string;

  @Input() user: PortalUser;
  @Input() display: boolean;
  @Output() userUpdated = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  public onShow() {
    this.userFullName = this.user.name;
  }

  public onAccept() {
    this.userUpdated.emit({ action:'PendingApprove' });
  }

  public onDecline() {
    this.userUpdated.emit({ action:'PendingDecline' });
  }

  public onCancel() {
    this.display = false;
    this.userUpdated.emit({ action:'Cancel' });
  }
}
