import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PagerClasses } from '../../services/models/pager-classes';
import { CollectionListItem } from '../../services/models/collection-list-item';
import { CollectionService } from '../../services/collection.service';
import { UserAdminService } from '../../services/user-admin.service';
import { BusyService } from '../../services/busy.service';
import { SessionService } from '../../services/session.service';
import { Messages } from '../../app.messages';
import { Constants } from '../../app.constants';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'collection-manager',
  templateUrl: './collection-list.component.html',
  styleUrls: ['./collection-list.component.scss']
})
export class CollectionListComponent implements OnInit, OnDestroy {
  public cssClasses = PagerClasses.cssClasses;
  public gridMessages: {};
  public collectionSearchText: string;
  public totalCollections: number;
  public loading: boolean;
  public rowsPerPage: number;
  public pageNumber: number;
  public collections: CollectionListItem[];
  public showAddCollection: boolean;
  public showRenameCollection: boolean;
  public isModal: boolean;
  public selectedCollection: CollectionListItem;
  private sortAscending: boolean;
  private activeRow: CollectionListItem;
  private activeMenu: CollectionListItem;
  private newCollection: boolean;
  private updateCollections$: Subscription;
  private paramsviaState: any;
  @ViewChild('detail', {static: true}) collectionDetail: any;

  constructor(
    private route: ActivatedRoute,
    private collectionService: CollectionService,
    private adminService: UserAdminService,
    private confirmService: ConfirmationService,
    private busy: BusyService,
    public session: SessionService,
    private messages: Messages,
    private constants: Constants,
    private router: Router
  ) {
    this.gridMessages = {
      emptyMessage: 'No Collections Found',
      totalMessage: 'total'
    };
    this.collectionSearchText = null;
    this.pageNumber = 1;
    this.rowsPerPage = this.constants.DetailRowsPerPage;
    this.sortAscending = true;
    this.activeMenu = null;
    this.showAddCollection = false;
    this.showRenameCollection = false;
    this.isModal = false;
    this.newCollection = false;
    this.paramsviaState = null;

    if(this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras && this.router.getCurrentNavigation().extras.state)
      this.paramsviaState = this.router.getCurrentNavigation().extras.state;
  }

  public initializeCollections(params) {
    const id = params.id ? params.id : null;
    if (id != null ) {
      // Make the "X" close button on the detail navigate back to the user list
      this.isModal = true;
      this.collectionDetail.modal = true;
      if (id === 'new') {
        this.newCollection = true;
        this.collectionSearchText = this.collectionService.selectedCollectionItem.name;
        // The collection should already be the selectedCollectionItem because the Add Collection dialog put it there.  However,
        // at this time, the collection does not yet exist on the server.
        this.showCollections(1, true, 1, [this.collectionService.selectedCollectionItem]);
      }
      else {

        this.collectionService.findCollections(null, false, 1, 1000).subscribe(results=>
          {
            let found:boolean = false;

            if(this.session.isEnterpriseAdmin){
              found = true; 
            }
            else {
              results.items.forEach(x=>{
                if(x.id == id) {                
                  found = true;
                  return;
                }
              });
            }

            if(found) {
              // This is a request for an existing collection.  Make the Users tab selected.
              this.collectionDetail.showUsers = true;
              let collectionSub = this.collectionService.getCollectionDetail(+id).subscribe(collection => {
                this.showCollections(1, true, 1, [collection]);
                // This should trigger a change notification, and the detail will show the existing providers
                // and the user already associated with this collection.  The user that theadmin clicked
                // on will appear in the list of users, because they are pending.
                this.collectionService.setCurrentCollection(collection);
                if (collectionSub != null) {
                  collectionSub.unsubscribe();
                }
              }); 
            }
            else {
              this.router.navigate(['']);
            }
          });
      }
    }
    else {
      // If coming into the collection service with no URL parameters, then make sure
      // the selected user is not falsely picked up in the users tab.
      this.adminService.selectedUser = null;
      this.findCollections(this.pageNumber, this.sortAscending);
    }
  }
  public initializeUserAndCollection(params) {
    if (params && params.id && params.uid) {
      const collectionId = +params.id;
      const suspenseId = +params.uid;
      // call service to get portal user
      this.collectionService.getCollectionRequestUser(collectionId, suspenseId)
        .subscribe(portalUser => {
          // assign portal user to admin service selected user in Admin Service
          this.adminService.selectedUser = portalUser;
          // process initialization
          this.initializeCollections(params);
        });
    }
    else {
      this.initializeCollections(params);
    }
  }

  ngOnInit() {
    if(this.paramsviaState) {
      this.initializeUserAndCollection(this.paramsviaState);
    }    
    else {
      this.route.params.subscribe(value => {
        // If suspenseID get user and assigne to selected user
        this.initializeUserAndCollection(value);        
      });  
    }

    this.updateCollections$ = this.collectionService.updateCollections().subscribe(() => {
      // If we are building a collection from the user detail, view, we don't want to refresh
      // the list of collections, but continue to show the selected collection.
      if (!this.isModal) {
        if (this.collectionService.selectedCollectionItem == null) {
          this.collectionSearchText = null;
          this.newCollection = false;
        }
        this.findCollections(this.pageNumber, this.sortAscending);
      }
      else {
        this.isModal = false;
        this.collectionDetail.showUsers = true;
      }
    });
  }

  ngOnDestroy() {
    if (this.updateCollections$ != null) {
      this.updateCollections$.unsubscribe();
    }
  }

  private cancelBubble(e): void {
    if (e != null) {
      e.cancelBubble = true;
      e.preventDefault();
    }
  }

  private closeMenu(): void {
    this.activeMenu = null;

  }

  private showCollections(pageNumber: number, sortAscending: boolean, totalCount: number, collections: CollectionListItem[]) {
    this.collections = collections;
    this.pageNumber = pageNumber;
    this.sortAscending = sortAscending;
    this.totalCollections = totalCount;
  }

  public findCollections(pageNumber: number, sortAscending?: boolean) {
    if (sortAscending == null) {
      sortAscending = this.sortAscending;
    }
    this.busy.emit(true);
    this.checkEditModeCollection(null);
    const findCollections = this.collectionService.findCollections(this.collectionSearchText, sortAscending, pageNumber, this.rowsPerPage)
      .subscribe(items => {
        this.showCollections(pageNumber, sortAscending, items.totalItems, items.items);
        if (this.newCollection) {
          // Select the new item in the list, then set it as the current collection
          // so the users tab will show the new user.
          this.selectedCollection = items.items[0];
          this.collectionService.setCurrentCollection(items.items[0]);
          this.newCollection = false;
        }
        this.busy.emit(false);
        if (findCollections != null) {
          findCollections.unsubscribe();
        }
      });
  }

  public onSearchKeyPress(e) {
    if (e.keyCode === 13) {
      e.cancelBubble = true;
      e.preventDefault();
      e.stopImmediatePropagation();
      this.onSearch();
    }
  }

  public onSearch() {
    this.findCollections(1, this.sortAscending);
  }

  // Methods related to the collection menu
  public onCollectionRowEnter(row: CollectionListItem): void {
    this.activeRow = row;
  }

  public onCollectionRowExit(row: CollectionListItem): void {
    this.activeRow = null;
  }

  public canEnableMenu(row: CollectionListItem): boolean {
    return this.session.isEnterpriseAdmin &&
      ((this.activeRow != null && this.activeRow.id === row.id) ||
       (this.collectionService.selectedCollectionItem != null && this.collectionService.selectedCollectionItem.id === row.id));
  }

  public canShowMenu(row: CollectionListItem): boolean {
    return !this.isModal && this.activeMenu != null && this.activeMenu.id === row.id && !this.collectionService.isEditCollectionModified();
  }

  public onCollectionMenu(e, row: CollectionListItem) {
    this.activeMenu = row;
    this.cancelBubble(e);
  }

  // If the user clicks outside of the context menu for the collection, then close the context menu.
  @HostListener('document:click', ['$event'])
  public onDocumentClick(e): void {
    if (this.activeMenu != null) {
      const activeMenuEl = document.getElementsByClassName('context-menu');
      if (activeMenuEl.length > 0) {
        const x = activeMenuEl[0].getBoundingClientRect();
        if (e.clientX < x.left || e.clientX > x.right || e.clientY < x.top || e.clientY > x.bottom) {
          this.closeMenu();
        }
      }
    }
  }

  public onRenameCollection(e, row: CollectionListItem) {
    this.closeMenu();
    this.showRenameCollection = true;
    this.collectionService.setCurrentCollection(row);
    this.cancelBubble(e);
  }

  public onCollectionRenamed() {
    this.collectionDetail.renameCollection();
    this.onCancelRenameCollection();
    this.showRenameCollection = false;
  }

  public onCancelRenameCollection(): void {
    this.showRenameCollection = false;
  }

  public onAddCollection() {
    this.showAddCollection = !this.isModal;
  }

  public onCollectionAdded(): void {
    this.onCancelAddCollection();
    this.newCollection = true;
    this.selectedCollection = this.collectionService.selectedCollectionItem;
    this.showCollections(1, true, 1, [this.selectedCollection]);
    this.collectionSearchText = this.selectedCollection.name;
  }

  public onCancelAddCollection(): void {
    this.showAddCollection = false;
  }

  // Methods related to the collection grid
  public onLoad(e: any): void {
    if (!this.isModal) {
      this.findCollections(1, e.sortOrder == 1);
    }
  }

  public sortClass(): string {
    return this.sortAscending ? "fa fa-chevron-up" : "fa fa-chevron-down";
  }

  public onSelect(e) {
    if (!this.newCollection) {
      this.checkEditModeCollection(e.data);
    }
  }

  public checkEditModeCollection(collectionListItem: CollectionListItem): void {
    if (!this.collectionService.isEditCollectionModified()) {
      this.collectionService.setCurrentCollection(collectionListItem);
      this.selectedCollection = collectionListItem;
    }
    else if (collectionListItem != null) {
      this.showConfirmationDialog(collectionListItem);
    }
  }

  public showConfirmationDialog(collectionListItem: CollectionListItem): void {
    this.confirmService.confirm({
      message: this.messages.MessageCollectionModified,
      accept: () => {
        if (this.collectionDetail.compProviders != null) {
          const saveCollection = this.collectionDetail.compProviders.saveCollection(false).subscribe(collectionId => {
            if (collectionId !== 0) {
              this.collectionService.setCurrentCollection(collectionListItem);
            }
            else if (collectionListItem != null) {
              this.collectionService.setCurrentCollection(collectionListItem);
            }
            else {
              this.selectedCollection = null;
            }
            if (saveCollection != null) {
              saveCollection.unsubscribe();
            }
          });
        }
      },
      reject: () => {
        this.collectionService.setCurrentCollection(collectionListItem);
      },
      acceptLabel: this.messages.ButtonYes,
      rejectLabel: this.messages.ButtonNo
    });
  }

  public onPage(pageInfo) {
    this.findCollections(pageInfo.offset + 1, this.sortAscending);
  }

  public search() {
    this.onSearch();
  }

  public onMouseUp(event: any) {
    const searchBoxWidth = event.srcElement.clientWidth;
    const clickXOffset = event.offsetX;
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    if (isIEOrEdge && this.collectionSearchText && clickXOffset >= searchBoxWidth - 36) {
      // clear string for fist click on X instead of edit box
      this.collectionSearchText = '';
      // disregard clear functionality and clear and search.
      this.onSearch();
    }
  }
}
