
<div class="user-list-container">
  <div class="user-list-header" *ngIf="selectedCollection">
    <div class="user-list-header-detail" [ngClass]="headerClass">
      <i class="fa fa-arrow-circle-left" aria-hidden="true" (click)="onBackToCollections()"></i>
      <span class="back-icon-text" (click)="onBackToCollections()">Back</span>
      <span class="title">{{selectedCollection.name}}</span>
      <span class="last-verified-on">Last verified on {{selectedCollection.lastVerified | date: 'MM/dd/yy'}}</span>
    </div>
  </div>
  <div class="user-list-body">
    <p-table
      [value]="users"
      [paginator]="false"
      styleClass="p-datatable-striped p-datatable-lg"
      [lazy]="true">
      <ng-template pTemplate="header">
        <th pSortableColumn="{{col.sortField}}"
            width="{{col.width}}"
            class="{{col.classHeader}}"
            *ngFor="let col of columns">
          <span>{{col.header}}</span>
        </th>
      </ng-template>
      <ng-template pTemplate="body" let-user let-rowIndex="rowIndex">
        <tr>
          <td width="{{col.width}}"  *ngFor="let col of columns" class="{{col.classContent}}">
            <span class="p-column-title">{{ col.header }}</span>
            <div *ngIf="col.key === 'spacerStart' || col.key === 'spacerEnd'">
              &nbsp;
            </div>

            <div *ngIf="col.key === 'roleIcon'">
              <i id="userRow{{col.id}}" [ngClass]="mapRoleToIcon(user)" class="role-icon" [title]="mapRoleToLabel(user)"></i>
            </div>

            <div *ngIf="col.key === 'name'">
              <div class="fullName" title={{user[col.key]}}>{{user[col.key]}}</div>
              <div class="email" title={{user.email}}>{{user.email}}</div>
            </div>

            <div *ngIf="col.key === 'role'">
              <i class="fa fa-pencil-square-o edit-user" aria-hidden="true" (click)="onEdit(user)"></i>
              <span class="role-label" title="Edit User Role">{{mapRoleToLabel(user)}}</span>
            </div>

            <div *ngIf="col.key === 'verify'">
              <p-checkbox
                name="verify-{{user.userID}}"
                [(ngModel)] = "user.isVerified"
                title="Verify User"
                binary="true"
                pTooltip="Click checkbox to verify role and employment"
                [tooltipDisabled]="setVerifyToolTipDisabled(user)"
                tooltipEvent="hover"
                tooltipPosition="left"
                tooltipStyleClass="blue-theme verify-tooltip"
                (onChange)="onVerify($event, user)">
              </p-checkbox>
              <span class="verify-by">
                {{getUserLastVerifiedBy(user)}}
              </span>
            </div>

            <div *ngIf="col.key === 'remove'">
              <i class="fa fa-times remove-user" aria-hidden="true" title="Remove User" (click)="onRemove(user)"></i>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <div class="confirm-btn-tooltip" #btnComfirmTooltipCtrl style="position: relative;float:right;margin-right: 260px;"></div>
        <div class="user-list-footer">
          <span class="result-count text-muted">
            {{totalUsers}} results
          </span>
          <div class="buttons" #buttonCtrl>
            <button  type="button" id="btnSave" class="btn btn-primary btn-save"  title="Save & Close" (click)="onSave()" [disabled]="!displaySaveCloseButton">
              Save & Close
            </button>
            <button
              #confirmBtn
              id="btnConfirm"
              type="button"
              class="btn btn-primary btn-add-confirm"
              title="Confirm"
              (click)="onConfirm()"
              [disabled]="!displayConfirmButton"
              pTooltip="Click Confirm if you attest to the information as accurate and complete."
              tooltipEvent="focus"
              tooltipPosition="top"
              [appendTo]="btnComfirmTooltipCtrl"
              tooltipStyleClass="blue-theme confirm-verify-tooltip">
              Confirm
            </button>
          </div>
        </div>
      </ng-template>
    </p-table>
  </div>
</div>
<app-user-permission-dialog
  [user]="editUser"
  [display]="editUser != null"
  (userUpdated)="onUsersUpdated($event)">
</app-user-permission-dialog>
<remove-user-dialog
    [user]="removeUser"
    [display]="removeUser != null"
    (userUpdated)="onUsersUpdated($event)"
    *ngIf="removeUser != null">
</remove-user-dialog>
<app-save-changes-alert-dialog
    [display]="displaySaveChangesAlert"
    (onUpdated)="onGoBackUpdated($event)"
    *ngIf="displaySaveChangesAlert">
</app-save-changes-alert-dialog>
