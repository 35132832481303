<div class="member-referral-container" *ngIf="!isLoading">
  <div class="member-referral-body">
    <p-dataView [value]="referrals" [sortField]="sortField" [sortOrder]="sortOrder" (onSort)="onSortInit($event)">
      <ng-template pTemplate="header">
        <div class="referral-history-header">
          <img src="./assets/images/referrals-icon.png" class="referral-icon">
          <h4>{{tabName}}</h4>
          <div class="header-right">
            <div class="sort-text">Sort by: </div>
            <p-dropdown [options]="sortOptions" [(ngModel)]="sortKey" optionLabel="label"
              (onChange)="onSortChange($event)"></p-dropdown>
          </div>
        </div>
      </ng-template>
      <ng-template let-referrals pTemplate="list">
        <div class="member-referral-row" *ngFor="let ref of referrals; let first = first">
          <div class="date-requested-label">Date requested:</div>
          <div class="date-requested-content">{{ref.dateRequested | date: 'MM/dd/yyyy'}}</div>
          <div class="referral-status-label">Status:</div>
          <div class="referral-status-content">
            <img  src="./assets/images/{{ref.referralStatus.toLowerCase()}}-referral-icon.png"
                class="referral-status-icon"
                title="{{ref.referralStatus}}">
            {{ref.referralStatus}}
          </div>
          <div class="referral-code-label">Referral code:</div>
          <div class="referral-code-content">{{ref.referralId}}</div>
          <div class="referred-provider-label">Referred provider:</div>
          <div class="referred-provider-content">
            <span title="{{ref.referredProvider?.providerName}}">{{ref.referredProvider?.providerName}}</span>
          </div>
          <div class="diagnosis-header">Diagnoses:</div>
          <div class="diagnosis-scroll">
            <span *ngFor="let diagnosis of ref.referralDiagnoses" title="{{diagnosis.diagnosisDesc}}">
              {{diagnosis.diagnosisCode}} /
              {{diagnosis.diagnosisDesc}}
            </span>
          </div>
          <div class="procedure-header">
            Requested Services:
          </div>
          <div class="procedure-scroll">
            <span *ngFor="let procedure of ref.referralProcedures" title="{{procedure.procedureDesc}}">
              {{procedure.procedureCode}} /
              {{procedure.procedureDesc}}
            </span>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <div class="member-referral-footer">
          {{this.totalReferrals}} results
        </div>
      </ng-template>      
    </p-dataView>
  </div>
</div>
