import { QueryablePagedRequest } from './queryable-paged-request';

export class LookupRequest extends QueryablePagedRequest {
  claimNumber: string;
  referralCode: string;
  startDate: Date;
  endDate: Date;  
  status: string[]; //maybe number or enum
  providerId: string;
  dateFilter: string;
  username: string;
  cin: string;
  adtId: string;
  adtType: string;
  adtStatus: string;
  adtFacilityName: string;
  adtSubtype: string[];
}
