import { Injectable, EventEmitter, Provider } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { UserProfile } from '@caloptima/authentication';
import { HttpResponseHelper, AppConfig } from '@caloptima/portal-foundation';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, BehaviorSubject, Subject, of, pipe } from 'rxjs';
import { debounceTime, distinctUntilChanged, catchError, switchMap, take, publishReplay, refCount, map } from 'rxjs/operators';

import { UserTypeMap } from './models/user-type-map';
import { CollectionListItem } from './models/collection-list-item';
import { CollectionDetailRequest } from './models/requests/collection-detail-request';
import { CollectionSearchRequest } from './models/requests/collection-search-request';
import { FindCollectionProvidersRequest } from './models/requests/find-collection-providers-request';
import { CollectionType } from './models/collection-type';
import { CollectionDetail } from './models/collection-detail';
import { UserRequest } from './models/user-request';
import { ProviderDetail } from './models/provider-detail';
import { ProviderDetailInfo } from './models/provider-detail-info';
import { UserAffiliation } from './models/user-affiliation';
import { PortalConfig } from '../portal-config';
import { ProviderDetailRequest } from './models/requests/provider-detail-request';
import { CollectionUsersRequest } from './models/requests/collection-users-request';
import { PagedResponse } from './models/responses/paged-response';
import { PortalUser, UserStatus, UserType } from './models/portal-user';
import { UpdatePermissionsRequest } from './models/requests/update-permissions-request';
import { RemovalCollectionRequest } from './models/requests/removal-collection-request';
import { UserExternalRequest } from './models/requests/user-external-request';
import { Constants } from '../app.constants';
import { UserProviderCollection } from './models/requests/user-provider-collection';
import { UserCollectionPermission } from './models/user-collection-permission';
import { UiUtility } from '../utils/ui-utility';
import { ProviderGroupDetails } from './models/provider-group-details';
import { ProviderTaxDetails } from './models/provider-tax-details';
import { FindMultipleCollectionProvidersRequest } from './models/requests/find-multiple-collection-providers-request';
import { FindProviderTaxDetailsRequest } from './models/requests/find-provider-taxdetails-request';
import { FindProviderGroupDetailsRequest } from './models/requests/find-provider-groupdetails-request';
import { ProviderDetail2 } from './models/provider-detail2';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

// Service that corresponds to the collection service in the provider services.  This service
// offers the methods to support the collection manager.
@Injectable({
  providedIn: 'root'
})
export class CollectionService {
  selectedCollectionItem: CollectionListItem = null;
  private baseServiceUrl: string;
  private findCollectionsUrl: string;
  private updateProviderCollectionUrl: string;
  private sendPasswordResetUrl: string;
  private findProvidersUrl: string;
  private findProviderTaxUrl: string;
  private findProviderGroupUrl: string;
  private findProvidersUrl2: string;
  private getCollectionDetailUrl: string;
  private validateCollectionUrl: string;
  private validateCollectionRenameUrl: string;
  private createOrUpdateCollectionUrl: string;
  private getCollectionUsersUrl: string;
  private getProviderDetailed: string;
  private getProviderDetailed2: string;
  private getProviderInfoDetails: string;
  private getModulePermissionsUrl: string;
  private updateUserPermissionsUrl: string;
  private removeUserFromCollectionUrl: string;
  private declineCollectionRequestUrl: string;
  private addUserFromCollectionUrl: string;
  private getProviderUrl: string;
  private createOrUpdateProviderCollectionUser: string;
  private getCollectionRequestUserUrl: string;
  private selectedCollectionItemSubject$ = new BehaviorSubject<CollectionListItem>(this.selectedCollectionItem);
  private refreshSubject = new Subject();
  private editCollectionItem: CollectionListItem;
  private originalProviders: ProviderDetail[];
  private originalTins: ProviderTaxDetails[];
  private originalGroups: ProviderGroupDetails[];
  private editProviders: ProviderDetail[];
  private currentUser: string;

  // prevents users for calling emits on subject
  public collectionListItemChanged$ = this.selectedCollectionItemSubject$.asObservable()
    .pipe(
      debounceTime(500),
      distinctUntilChanged()
    );

  constructor(
    private http: HttpClient,
    private config: PortalConfig,
    private appConfig: AppConfig,
    private constants:Constants,
    private authService: OAuthService
  ) {
    this.baseServiceUrl = appConfig.getConfig('BaseProviderServicesApiUrl');
    if (this.baseServiceUrl == null) {
      let config$ = appConfig.subscribe(() => {
        this.baseServiceUrl = appConfig.getConfig(
          'BaseProviderServicesApiUrl'
        );
        config$.unsubscribe();
      });
    }

    const claims = this.authService.getIdentityClaims();
    if (claims) {
      this.currentUser = claims['sub'];
    }
  }

  // Resolve all of the URLs used by this service
  private checkUrls() {
    if (this.findCollectionsUrl == null) {
      const baseUrl = this.baseServiceUrl + 'api/admin/';
      const baseProviderUrl = this.baseServiceUrl + 'api/provider/';

      this.findCollectionsUrl = baseUrl + 'findCollections';
      this.updateProviderCollectionUrl = baseUrl + 'updateProviderCollection';
      this.sendPasswordResetUrl = baseUrl + 'sendResetPassword';
      this.findProvidersUrl = baseProviderUrl + 'findProviders';
      this.findProviderTaxUrl = baseUrl + 'getProviderTaxDetails';
      this.findProviderGroupUrl = baseUrl + 'getProviderGroupDetails';
      this.findProvidersUrl2 = baseProviderUrl + 'findProvidersForMultipleKey';
      this.getCollectionDetailUrl = baseUrl + 'getCollectionDetail';
      this.validateCollectionUrl = baseUrl + 'validateCollection';
      this.validateCollectionRenameUrl = baseUrl + 'validateCollectionRename';
      this.createOrUpdateCollectionUrl = baseUrl + 'createOrUpdateCollection';
      this.getProviderDetailed = baseProviderUrl + 'GetProviderInfo';
      this.getProviderDetailed2 = baseProviderUrl + 'GetProviderInfo2';
      this.getProviderInfoDetails = baseProviderUrl + 'getProviderInfoDetails'
      this.getCollectionUsersUrl = baseUrl + 'getCollectionUsers';
      this.getModulePermissionsUrl = baseUrl + 'getmodulePermissions';
      this.updateUserPermissionsUrl = baseUrl + 'updateUserPermissions';
      this.removeUserFromCollectionUrl = baseUrl + 'removeUserFromCollection';
      this.declineCollectionRequestUrl = baseUrl + 'declineCollectionRequest';
      this.addUserFromCollectionUrl = baseUrl + 'addUserToCollection';
      this.getCollectionRequestUserUrl = baseUrl + 'getCollectionRequestUser';
      this.createOrUpdateProviderCollectionUser = baseUrl + 'createOrUpdateProviderCollectionUser';
      this.getProviderUrl = baseProviderUrl + 'getProviderDetails'
    }
  }

  private mapUserAffiliationToPortalUser(item: UserAffiliation): PortalUser {
    const user: PortalUser = new PortalUser();
    user.status = UserStatus[item.accountStatus];
    user.statusDesc = item.accountStatus;
    user.name = item.name;
    user.email = item.email;
    user.internalName = item.secureAuthUserName;
    user.suspenseId = item.userSuspenseID;
    user.userID = item.userID.toString();
    user.userType = UserTypeMap.mapUserType(item.isAdminFlag, item.isEnterpriseUserFlag);
    user.userLastVerified = item.userLastVerified;
    user.userLastVerifiedBy = item.userLastVerifiedBy;
    user.userXrefEffDate = item.userXrefEffDate;
    user.userXrefTermDate = item.userXrefTermDate;
    user.providerCollectionID = item.providerCollectionID;
    user.userProviderCollectionXrefID = item.userProviderCollectionXrefID;
    user.roleID = item.roleID;
    user.roleCode = item.roleCode;
    user.roleDisplayName = item.roleDisplayName;
    user.providerPositionID = item.providerPositionID;
    user.positionDisplayName = item.positionDisplayName;
    user.providerPositionOther = item.providerPositionOther;

    return user;
  }


  private mapUserAffiliationToUser(response: PagedResponse<UserAffiliation>): PagedResponse<PortalUser> {
    const users: PagedResponse<PortalUser> = new PagedResponse<PortalUser>();
    users.totalItems = response.totalItems;
    users.items = [];
    response.items.forEach(item => {
      const user: PortalUser = this.mapUserAffiliationToPortalUser(item);
      users.items.push(user);
    });
    return users;
  }

  // Get a paged list of collections for the collection manager list.
  public findCollections(
    searchText: string,
    sortAscending: boolean,
    pageNumber: number,
    pageSize: number
  ): Observable<PagedResponse<CollectionListItem>> {
    this.checkUrls();
    try {
      const request: CollectionSearchRequest = new CollectionSearchRequest();
      request.searchText = searchText;
      request.sortAscending = sortAscending;
      request.pageNumber = pageNumber;
      request.pageSize = pageSize;
      return this.http
        .post<PagedResponse<CollectionListItem>>(this.findCollectionsUrl, request, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    } catch (ex) {
      // log error
      return null;
    }
  }

  //  Get the details of a collection, including the providers of the collection.
  public getCollectionDetail(collectionID: number): Observable<CollectionDetail> {
    this.checkUrls();
    try {
      const request: CollectionDetailRequest = new CollectionDetailRequest();
      request.collectionId = collectionID;
      return this.http
        .post<CollectionDetail>(this.getCollectionDetailUrl, request, httpOptions)
        .pipe(
          map((detail) => {
            this.originalTins = detail.tins;
            this.originalGroups = detail.groups;
            this.editProviders = null;
            if (detail != null) {
              this.originalProviders = detail.providers;
            }
            return detail;
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    } catch (ex) {
      // log error
      return null;
    }
  }

  // Update provider collection
  public updateProviderCollection(collection: CollectionListItem): Observable<object> {
    this.checkUrls();
    try {
      return this.http
        .post(this.updateProviderCollectionUrl, collection, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    }
    catch (ex) {
      // Log error
      return null;
    }
  }

  ///Search for provides that can be included in a tax ID or group collection
  public findCollectionProviders(collectionType: CollectionType, collectionKey: string, maxRows?: number): Observable<ProviderDetail[]> {
    this.checkUrls();
    try {
      const request: FindCollectionProvidersRequest = new FindCollectionProvidersRequest();
      request.collectionType = collectionType;
      request.collectionKey = collectionKey;
      request.maxRows = maxRows;
      return this.http
        .post<ProviderDetail[]>(this.findProvidersUrl, request, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    } catch (ex) {
      // log error
      return null;
    }
  }

  public findProviderTaxDetails(providerTaxId: string): Observable<ProviderTaxDetails[]> {
    this.checkUrls();
    try {
      const request: FindProviderTaxDetailsRequest = new FindProviderTaxDetailsRequest();
      request.providerTaxId = providerTaxId;

      return this.http
        .post<ProviderTaxDetails[]>(this.findProviderTaxUrl,request, httpOptions)
        .pipe(
            publishReplay(1),
            refCount(),
            catchError(error => HttpResponseHelper.handleError(error))
        );
    } catch(ex) {
      return null;
    }
  }

  public findProviderGroupDetails(providerGroupId: string): Observable<ProviderGroupDetails[]> {
    this.checkUrls();
    try {
      const request: FindProviderGroupDetailsRequest = new FindProviderGroupDetailsRequest();
      request.providerGroupId = providerGroupId;

      return this.http
        .post<ProviderGroupDetails[]>(this.findProviderGroupUrl,request, httpOptions)
        .pipe(
            publishReplay(1),
            refCount(),
            catchError(error => HttpResponseHelper.handleError(error))
        );
    } catch(ex) {
      return null;
    }
  }

  public findMultipleCollectionProviders(collectionType: CollectionType, collectionKeys: string[], maxRows?: number): Observable<ProviderDetail[]> {
    this.checkUrls();
    try {
      const request: FindMultipleCollectionProvidersRequest = new FindMultipleCollectionProvidersRequest();
      request.collectionType = collectionType;
      request.collectionKeys = collectionKeys;
      request.maxRows = maxRows;
      return this.http
        .post<ProviderDetail[]>(this.findProvidersUrl2, request, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    } catch (ex) {
      // log error
      return null;
    }
  }

  // Verify the collection name and optionally the tax ID or group prior to creating a collection
  public validateCollection(collection: CollectionListItem): Observable<string> {
    this.checkUrls();
    try {
      return this.http
        .post<string>(this.validateCollectionUrl, collection, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    } catch (ex) {
      // log error
      return null;
    }
  }

  // Verify the collection name and optionally the tax ID or group prior to creating a collection
  public validateCollectionRename(collection: CollectionListItem): Observable<string> {
    this.checkUrls();
    try {
      return this.http
        .post<string>(this.validateCollectionRenameUrl, collection, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    } catch (ex) {
      // log error
      return null;
    }
  }

  //  Create a new collection, optionally including any providers for manual collections.
  public createOrUpdateCollection(collectionDetail: CollectionDetail): Observable<number | string> {
    this.checkUrls();
    try {
      this.editCollectionItem = null;
      this.editProviders = null;
      return this.http
        .post<number>(this.createOrUpdateCollectionUrl, collectionDetail, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    } catch (ex) {
      // log error
      return null;
    }
  }

  public updateCollectionType(collectionID: number, name: string, collectionType: CollectionType): Observable<boolean> {
    return of(true);
  }

  public clearAnyPreviousCaches() {
    this.originalGroups = [];
    this.originalTins = [];
    this.originalProviders = [];
  }

  // Set the collection item that is used through the rest of the collection manager.
  public setCurrentCollection(collectionListItem: CollectionListItem) {
    this.editCollectionItem = null;
    this.editProviders = null;
    this.selectedCollectionItem = collectionListItem;
    this.selectedCollectionItemSubject$.next(collectionListItem);
  }

  // Request that a password reset be sent to the selected user
  public sendResetPassword(user: UserProfile): Observable<object> {
    this.checkUrls();
    try {
      let request: UserRequest = new UserRequest();
      request.email = user.email;
      request.secureAuthUserName = user.userID;
      return this.http
        .post(this.sendPasswordResetUrl, request, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    }
    catch (ex) {
      // Log error
      return null;
    }
  }

  public clearCurrentCollection() {
    this.selectedCollectionItemSubject$.next(null);
  }

  // Subscription to notify clients when collections have been updated
  public updateCollections(): Observable<Object> {
    return this.refreshSubject.asObservable();
  }

  //  Tell the collection list that the set of collections has changed.  This may
  // be done as a result of creating a new collection, or renaming a collection
  public refreshList(): void {
    this.refreshSubject.next(null);
  }

  // Get the current set of providers in the collection.
  public get providers(): ProviderDetail[] {
    let providers: ProviderDetail[] = this.isEditCollectionModified() ? this.editProviders : this.originalProviders;
    if (providers == null) {
      providers = [];
    }
    return providers;
  }

  public beginEditCollection(): void {
    if (this.editCollectionItem == null) {
      this.editCollectionItem = Object.assign({}, this.selectedCollectionItem);
      this.editCollectionItem.tins = this.originalTins ? this.originalTins.slice() : [];
      this.editCollectionItem.groups = this.originalGroups ? this.originalGroups.slice() : [];
      this.editProviders = this.originalProviders ? this.originalProviders.slice() : [];
    }
  }

  public changeCollectionType(collectionType: CollectionType): any {

      this.beginEditCollection();

      this.editCollectionItem.collectionType = collectionType;

      this.editCollectionItem.tins = [];
      this.editCollectionItem.groups = [];
      this.editCollectionItem.tin = null;
      this.editCollectionItem.groupId = null;
      this.editProviders = [];
      return {
        "tins":this.editCollectionItem.tins,
        "groups": this.editCollectionItem.groups,
        "providers": this.editProviders
      }
  }

  public addProviders(providers: ProviderDetail[]): ProviderDetail[] {
    this.beginEditCollection();
    providers.forEach(p => {
      let existing = this.editProviders.find(x => x.calProviderID === p.calProviderID);
      if (existing == null) {
        this.editProviders.push(p);
      }
    });
    return this.editProviders;
  }

  public addTins(newTins: ProviderTaxDetails[]): ProviderTaxDetails[] {
    this.beginEditCollection();

    newTins.forEach(p => {
      let existing:ProviderTaxDetails = this.editCollectionItem.tins.find(x=>x.providerTaxId == p.providerTaxId);
      if(existing == null) {
        this.editCollectionItem.tins.push(p);
      }
    });

    return this.editCollectionItem.tins;
  }

  public addGroups(newGroups: ProviderGroupDetails[]): ProviderGroupDetails[] {
    this.beginEditCollection();

    newGroups.forEach(p => {
      let existing:ProviderGroupDetails = this.editCollectionItem.groups.find(x=>x.providerGroupId == p.providerGroupId);
      if(existing == null) {
        this.editCollectionItem.groups.push(p);
      }
    });

    return this.editCollectionItem.groups;
  }

  public removeTin(tin: ProviderTaxDetails, tinProviders: ProviderDetail[]): any {
    this.beginEditCollection();

    const index = this.editCollectionItem.tins.findIndex(x=> x.providerTaxId === tin.providerTaxId);
    if(index > -1)
      this.editCollectionItem.tins.splice(index, 1);

    let iloop:number = 0;
    for(iloop = this.editProviders.length-1; iloop >= 0; iloop--) {

      const index = tinProviders.findIndex(x=>x.calProviderID === this.editProviders[iloop].calProviderID)
      if(index > -1)
        this.editProviders.splice(iloop, 1);
    }

    return {
      "tins": this.editCollectionItem.tins,
      "providers": this.editProviders
    }
  }

  public removeGroup(group: ProviderGroupDetails, groupProviders: ProviderDetail[]): any {
    this.beginEditCollection();

    const index = this.editCollectionItem.groups.findIndex(x=> x.providerGroupId === group.providerGroupId);
    if(index > -1)
      this.editCollectionItem.groups.splice(index, 1);

    let iloop:number = 0;
    for(iloop = this.editProviders.length-1; iloop >= 0; iloop--) {

      const index = groupProviders.findIndex(x=>x.calProviderID === this.editProviders[iloop].calProviderID)
      if(index > -1)
        this.editProviders.splice(iloop, 1);
    }

    return {
      "groups":this.editCollectionItem.groups,
      "providers": this.editProviders
    };
  }

  // Remove a provider from a manual collection, returning the remaining providers
  public removeProvider(provider: ProviderDetail): ProviderDetail[] {
    this.beginEditCollection();
    let index = this.editProviders.indexOf(provider);
    this.editProviders.splice(index, 1);
    return this.editProviders;
  }

  // Returns true if the collection has been modified.  This can be used to disable parts of
  // the user interface.
  public isEditCollectionModified(): boolean {
    let isModified = false;
    if (this.editCollectionItem != null && this.selectedCollectionItem != null) {
      isModified = this.editCollectionItem.id == null ||
        this.editCollectionItem.id == 0 ||
        this.editCollectionItem.name != this.selectedCollectionItem.name ||
        this.editCollectionItem.collectionType !== this.selectedCollectionItem.collectionType ||
        (this.editCollectionItem.collectionType === CollectionType.TIN &&
          this.editCollectionItem.tin !== this.selectedCollectionItem.tin) ||
        (this.editCollectionItem.collectionType === CollectionType.Group &&
          this.editCollectionItem.groupId !== this.selectedCollectionItem.groupId) ||
        (this.editCollectionItem.collectionType === CollectionType.MultipleTIN &&
          this.editCollectionItem.tins.length !== this.originalTins.length) ||
        (this.editCollectionItem.collectionType === CollectionType.MultipleGroupId &&
          this.editCollectionItem.groups.length !== this.originalGroups.length) ||
        (this.editProviders != null && this.originalProviders == null) ||
        (this.editProviders != null && this.originalProviders != null &&
          this.originalProviders.length !== this.editProviders.length);

      if(!isModified)
      {
        if(this.editCollectionItem.collectionType === CollectionType.MultipleGroupId) {
          this.editCollectionItem.groups.forEach (  x=> {

            var found = false;
            this.originalGroups.forEach(y => {
              if(x.providerGroupId === y.providerGroupId)
                found = true;
            });

            if(!found) {
              isModified = true;
            }
          });
        }

        if(this.editCollectionItem.collectionType === CollectionType.MultipleTIN) {
          this.editCollectionItem.tins.forEach (  x=> {

            var found = false;
            this.originalTins.forEach(y => {
              if(x.providerTaxId === y.providerTaxId)
                found = true;
            });

            if(!found) {
              isModified = true;
            }
          });
        }
      }
    }
    return isModified;
  }

  // Get the details for a selected provider.
  public GetProvider(providerId: string): Observable<ProviderDetail> {
    this.checkUrls();
    try {
      //   let request: UserRequest = new UserRequest();
      const request: ProviderDetailRequest = new ProviderDetailRequest();
      // TODO: determine where to get username - storelocal- pull serverside
      request.providerId = +providerId;
      //   request.email = email;
      return this.http
        .post<ProviderDetail>(this.getProviderDetailed, request, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    }
    catch (ex) {
      // Log error
      return null;
    }
  }

  public GetProvider2(calProviderId: string): Observable<ProviderDetail2> {
    this.checkUrls();
    try {
      const request: ProviderDetailRequest = new ProviderDetailRequest();
      request.prpId = calProviderId;
      return this.http
        .post<ProviderDetail2>(this.getProviderDetailed2, request, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    }
    catch (ex) {
      return null;
    }
  }

  // Get the details for a provider by prpr_Id.
  public GetProviderInfoDetails(prpId: string, pradtype?:string): Observable<ProviderDetailInfo> {
    this.checkUrls();
    try {
      const request: ProviderDetailRequest = new ProviderDetailRequest();
      if (UiUtility.isNullUndefinedOrEmpty(prpId)) {
        return of<ProviderDetailInfo>(new ProviderDetailInfo());
      }

      request.prpId = prpId;
      request.pradtype = pradtype ? pradtype : this.constants.AddressTypeS;
      return this.http
        .post<ProviderDetailInfo>(this.getProviderInfoDetails, request, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    }
    catch (ex) {
      // Log error
      return null;
    }
  }


  //  Get all users that are part of a collection.  This includes not just active users, but also pending and approved users.
  // Does not return declined users
  public getCollectionUsers(collection: CollectionListItem, pageNumber: number, pageSize: number, sortAscending: boolean = false): Observable<PagedResponse<PortalUser>> {
    this.checkUrls();
    try {
      //   request.email = email;
      let request: CollectionUsersRequest = new CollectionUsersRequest();
      request.pageNumber = pageNumber;
      request.pageSize = pageSize;
      request.collectionId = collection.id;
      request.sortAscending = sortAscending;
      
      return this.http
        .post<PagedResponse<UserAffiliation>>(this.getCollectionUsersUrl, request, httpOptions)
        .pipe(
          map(response => this.mapUserAffiliationToUser(response)),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    }
    catch (ex) {
      // Log error
      return null;
    }
  }

  // Add or update the user permissios for the selected user within the collection
  public updateUserPermissions( collection: CollectionListItem,
                                user: PortalUser,
                                permissions: UserCollectionPermission[],
                                roleID: number,
                                authorizedByName: string,
                                positionId: number,
                                positionOtherName: string,
                                reasonforpermissionchange:string): Observable<object> {
    this.checkUrls();
    try {
      const request: UpdatePermissionsRequest = new UpdatePermissionsRequest();
      request.collectionId = collection.id;
      request.userCollectionPermissions = permissions;
      request.approve = user.status == UserStatus.Pending;
      request.userId = +user.userID;
      request.suspenseId = user.suspenseId;
      request.userName = this.currentUser;
      request.AuthorizedName = authorizedByName;
      request.roleId = roleID;
      request.providerPositionId = positionId;
      request.providerPositionOther =  positionOtherName;
      request.notes = reasonforpermissionchange;

      if (!request.approve && !user.status) {
        request.add = true;
      }

      request.isAdmin = true;
      return this.http
        .post(this.updateUserPermissionsUrl, request, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    }
    catch (ex) {
      // Log error
      return null;
    }
  }

  // Remove a user from collecin.  The user may be a pending user or one preiously approved
  public removeUserFromCollection(collection: CollectionListItem, user: PortalUser, reason: string, separationDate: Date): Observable<object> {
    this.checkUrls();
    try {
      //   request.email = email;
      let request: RemovalCollectionRequest = new RemovalCollectionRequest();
      request.collectionId = collection.id;
      request.userName = user.internalName;
      request.suspenseId = user.suspenseId;
      request.userId = Number(user.userID);
      request.reason = reason;
      request.separationDate = separationDate;
      request.userName = this.currentUser;

      return this.http
        .post(this.removeUserFromCollectionUrl, request, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    }
    catch (ex) {
      // Log error
      return null;
    }
  }

  // Decline a request to add a pendinguser to a collection
  public declineCollectionRequest(collection: CollectionListItem, user: PortalUser, reason: string): Observable<Object> {
    this.checkUrls();
    try {
      //   request.email = email;
      let request: RemovalCollectionRequest = new RemovalCollectionRequest();
      request.collectionId = collection.id;
      request.userId = user.suspenseId;
      request.reason = reason;
      request.userName = this.currentUser;

      return this.http
        .post(this.declineCollectionRequestUrl, request, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    }
    catch (ex) {
      // Log error
      return null;
    }
  }

  public getCollectionRequestUser(collectionId: number, suspensId: number) {
    this.checkUrls();
    try {
      const request = new UserExternalRequest();
      request.providerCollectionID = collectionId;
      request.userSuspenseId = suspensId;
      return this.http.post(this.getCollectionRequestUserUrl, request, httpOptions)
        .pipe(
          map((response: UserAffiliation) => {
            const portalUser = this.mapUserAffiliationToPortalUser(response);
            return portalUser;
          })
        );
    }
    catch (ex) {
      // log error
      return null;
    }
  }



  // Update User Provider Collection, if there are inserts, will get as array of userAffiliation
  public insertOrUpdateUserProviderCollection(users: PortalUser[], confirmAction = false): Observable<UserAffiliation[]> {
    this.checkUrls();
    try {
      let request: UserProviderCollection[] = [];

      users.forEach(user => {
        let req: UserProviderCollection = new UserProviderCollection();
        req.userName = this.currentUser;
        req.userID = user.userID;
        req.providerCollectionID = user.providerCollectionID;
        req.userProviderCollectionXrefID = user.userProviderCollectionXrefID;
        req.lastVerified = confirmAction ? null : user.userLastVerified;
        req.lastVerifiedBy = confirmAction ? null : user.userLastVerifiedBy;
        req.modifiedDate = new Date();
        req.modifiedBy = this.currentUser;
        req.userXrefEffDate = user.userXrefEffDate;
        req.userXrefTermDate = user.userXrefTermDate;
        req.roleID = user.roleID;

        request.push(req);
      });

      return this.http
        .post(this.createOrUpdateProviderCollectionUser, request, httpOptions)
        .pipe(
          map((response: UserAffiliation[]) => {
            return response;
          }),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    }
    catch (ex) {
      // Log error
      return null;
    }
  }

  public getCollectionVerificationAlertCategory(collection: CollectionListItem, dueDays: number, warningDueDays: number): string {
    const warningDays = dueDays - warningDueDays;
    if (collection.daysLeftToVerify === 0) {
      return 'alert';
    }
    else if (collection.daysLeftToVerify > 0 && collection.daysLeftToVerify <= warningDays) {
      return 'warning';
    }
    else {
      return 'none';
    }
  }

  public getProvider(calProviderId: string, providerAddressType: string): Observable<ProviderDetail> {
    try {
      this.checkUrls();
      const url = this.getProviderUrl + '?calProviderId=' + calProviderId + '&providerAddressType=' + providerAddressType;

      return this.http.get<ProviderDetail>(url, httpOptions)
        .pipe(
          map(res => {
            return res;
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }  
}
