<div class="header">
  <app-user-header></app-user-header>
</div>

<app-change-password
    [componentUsing]="componentUsing"
    [emailAddress]="emailAddress"
    (submitChange)="onPasswordSubmitChange($event)">
</app-change-password>

<div class="footer-container">
  <app-user-footer></app-user-footer>
</div>
