import { QueryablePagedRequest } from './queryable-paged-request';

export class ProviderSpecialtyRequest {
  entityType: string;
  referralType: string;
}

export class ProviderSearchRequest extends QueryablePagedRequest {
  username: string;
  lobId: number;
  providerId: string[];
  referralType: string;
  providerName: string;
  providerSpecialty: string;
  memberBirthDate:string;
  memberGender: string;
  memberZipCode: string;
  providerCollectionId: number;
}
