<p-dialog [(visible)]="display" [modal]="true"  [responsive]="true" [style]="{width: '350px', minWidth: '200px'}" [closable]="false"
  [minY]="70" [maximizable]="false" [baseZIndex]="10000" [transitionOptions]="'250ms'">
  <ng-template pTemplate="header">
    <div>
      Session Timeout
    </div>
  </ng-template>
  <p>In {{displayTimeoutReminder}} minute(s), your session will expire. You will be re-directed to the logout page.  Please complete your task at this time</p>
  <ng-template pTemplate="footer">
    <button type="button" class="btn btn-primary" (click)="onContinue()" label="Continue">Ok</button>
  </ng-template>
</p-dialog>
