<div class="reports-bloodlead-report-type">
    <app-reports-type [reportType]="'dhcsbloodleadscreening'"></app-reports-type>
</div>
<div class="reports-bloodlead-container">
  <div class="report-alert">
    <app-alert-info subjectMessage="" uniqueID="reportsdhcsbls" contentMessage="Narrow your search using the known provider name or ID."></app-alert-info>
  </div>
  <div class="report-search">
    <app-reports-provider-search (onProviderSearch)="onSearch($event)"></app-reports-provider-search>
  </div>
  <div class="report-parameter">
    <p-table tableStyleClass="table-data" #providerList
    [value]="displayProviders"
    [paginator]="true"
    [rows]="10"
    [scrollable]="true"
    scrollHeight="400px"
    (onSort)="onSort($event)"
    [styleClass]="'p-datatable-striped p-datatable-lg'">
      <ng-template pTemplate="header">
        <tr>
          <th width="5%"></th>
          <th width="40%" pSortableColumn="name">Name<div class="{{sortClass('name')}}"></div></th>
          <th width="20%" pSortableColumn="calProviderID" class="header-provider-column">Provider ID<div class="{{sortClass('calProviderID')}}"></div></th>
          <th width="35%" pSortableColumn="groupName">Medical Group<div class="{{sortClass('groupName')}}"></div></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td width="5%" class="check-column">
            <p-radioButton name="providerid" [inputId]="row.calProviderID" [value]="row" [(ngModel)]="selectedCalProviderID" (click)="onProviderSelected(row)"></p-radioButton>
          </td>
          <td width="40%">
            <div>{{row.name}}</div>
          </td>
          <td width="20%" class="provider-column">
            <div>{{row.calProviderID}}</div>
          </td>
          <td width="35%">
            <div>{{row.groupName}}</div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="report-measurementlabel" *ngIf="selectedCalProviderID != ''">
    <label>Reporting Period for {{displayText}}</label>
  </div>
  <div class="report-filelist" *ngIf="selectedCalProviderID != ''">
    <p-dropdown name="providerfiles" id="providerfiles" [options]="fileList" [(ngModel)]="selectedFile">
    </p-dropdown>
    <button class="btn btn-primary" type="button" (click)="onBuildReport()" [disabled]='!enableBuildReport'>Download</button>
  </div>
</div>
