import { ReportType } from "../reportype";

export class ProviderSearchReportRequest {
    public userName: string;
    public collectionId: number;
    public providerId?: string;
    public calProviderId?:string;
    public providerTaxId?: string;
    public providerNPI: string;
    public offsetRows?: number;
    public fetchRows?: number;
    public pcpOnly?: boolean;
    public reportType: ReportType;
    public providerName: string;
    public collectionName: string;
}
