import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appAutofocus]'
})
export class AutofocusDirective implements AfterContentInit  {
  @Input() public isAutoFocused: boolean;

  constructor(private el: ElementRef) { }

  public ngAfterContentInit() {
    if (this.isAutoFocused) {
      setTimeout(() => {
        this.el.nativeElement.focus();
      }, 500);
    }
  }
}
