import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LazyLoadEvent } from 'primeng/api';
import { CollectionListItem } from '../../services/models/collection-list-item';
import { CollectionService } from '../../services/collection.service';
import { UiUtility } from '../../utils/ui-utility';
import moment from 'moment';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Constants } from '../../app.constants';
import { SessionService } from '../../services/session.service';

@Component({
  selector: 'collection-verify.component',
  templateUrl: './collection-verify.component.html',
  styleUrls: ['./collection-verify.component.scss']
})
export class CollectionVerifyComponent implements OnInit, OnDestroy {
  public collectionListSubscription$: Subscription;
  public collections: CollectionListItem[];
  public totalCollections: number;
  public columns: any[];
  public rowsPerPage: number;
  public pageNumber: number;
  public collectionSearchText: string = null;
  public showAddCollection: boolean;
public showAddCollectionButton = false;

  private sortColumn: string;
  private sortAscending: boolean;
  private resposiveSubcription$: Subscription;
  private userListVerificationDueDaysSetting:number;
  private userListVerificationWarningDaysSetting:number;
  private providerSettingsSubscription$: Subscription;

  private colletionListColumns: any = [
    { key: 'spacerStart', field: '', header: '', width: '20%', sort: false,  classHeader: 'collection-row-header', classContent: 'collection-row-content'},
    { key: 'name', field: 'name', header: 'Collection name', width: '23%', sort: true, classHeader: 'collection-row-header collection-header-name', classContent: 'collection-row-content collection-name' },
    { key: 'lastVerified', field: '', header: 'Last verified on', width: '14%', sort: false,  classHeader: 'collection-row-header', classContent: 'collection-row-content' },
    { key: 'lastVerifiedBy', field: '', header: 'Last verified by', width: '20%', sort: false,  classHeader: 'collection-row-header', classContent: 'collection-row-content' },
    { key: 'daysLeftToVerify', field: '', header: 'Days left to verify', width: '21%', sort: false,  classHeader: 'collection-row-header', classContent: 'collection-row-content' },
    { key: 'externalLink', field: '', header: '', width: '8%', sort: false,  classHeader: 'collection-row-header', classContent: 'collection-row-content externalLink'},
    { key: 'spacerEnd', field: '', header: '', width: '20%', sort: false,  classHeader: 'collection-row-header', classContent: 'collection-row-content'}
  ];

  constructor(
    private collectionService: CollectionService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private constants: Constants,
    private sessionService: SessionService ) {
    }

  ngOnInit() {
    this.rowsPerPage = 10;
    this.pageNumber = 1;
    this.sortColumn = 'name';
    this.sortAscending = true;
    this.columns = this.colletionListColumns;

    this.initCollectionListResultColumns();
    this.getProviderSettings();
  }

  ngOnDestroy() {
    if (this.collectionListSubscription$) {
      this.collectionListSubscription$.unsubscribe();
    }
    if (this.resposiveSubcription$) {
      this.resposiveSubcription$.unsubscribe();
    }
    if (this.providerSettingsSubscription$) {
      this.providerSettingsSubscription$.unsubscribe();
    }
  }

  public onSearchKeyPress(e) {
    if (e.keyCode === 13) {
      e.cancelBubble = true;
      e.preventDefault();
      e.stopImmediatePropagation();

      this.onSearchClick();
    }
  }

  public onSearchClick() {
    if (UiUtility.isNullUndefinedOrEmpty(this.collectionSearchText)) {
      this.collectionSearchText = null;
    }
    else {
      this.collectionSearchText = this.collectionSearchText.trim();
    }

    this.pageNumber = 1;
    this.loadData();
  }

  public sortClass(column: string): string {
    let cls: string;
    if (column === this.sortColumn) {
      cls = this.sortAscending ? 'fa fa-chevron-up' : 'fa fa-chevron-down';
    }
    else {
      cls = 'fa fa-chevron-up';
    }
    return cls;
  }

  public onPage(page: number) {
    this.pageNumber = page;
    this.loadData();
  }

  lazyLoadData(event: LazyLoadEvent) {
    if (event.sortField !== undefined) {
      this.sortAscending = event.sortOrder !== 1;
      this.loadData();
    }
  }

  private loadData() {
    this.collectionService.findCollections(this.collectionSearchText, this.sortAscending, this.pageNumber, this.rowsPerPage)
      .subscribe(result => {
        this.collections = result.items;
        this.totalCollections = result.totalItems;
        this.collections.forEach(c => {
          if (!c.lastVerified) {
            c.lastVerified = new Date();
          }
          const diff = moment().diff(moment(c.lastVerified, "MM-DD-YYYY"), 'days');
          c.daysLeftToVerify = this.userListVerificationDueDaysSetting - diff < 0
                               ? 0
                               : this.userListVerificationDueDaysSetting - diff;
        })
      },
      error => {
      });
  }

  public onSelectCollectionName(item: CollectionListItem) {
    this.router.navigate(['/user-admin/collections/'], {state: {id: item.id}});
  }

  public getVerifyButtonClass(item: CollectionListItem) {
    const alertCategory = this.collectionService.getCollectionVerificationAlertCategory(
                                                    item,
                                                    this.userListVerificationDueDaysSetting,
                                                    this.userListVerificationWarningDaysSetting);
    return 'verify-' + alertCategory;
  }

  public onAddCollection() {
    this.showAddCollection = true;
  }

  public onCollectionAdded(): void {
    this.onCancelAddCollection();
    this.pageNumber = 1;
    this.loadData();
  }

  public onCancelAddCollection(): void {
    this.showAddCollection = false;
  }

  public onShowCollectionDetails(item: CollectionListItem) {
    this.router.navigate(['/user-admin/collections/'], {state: {id: item.id}});
  }

  public onVerifyUser(item: CollectionListItem) {
    this.collectionService.setCurrentCollection(item);
    this.router.navigate(['/user-admin/collections/verify/user']);
  }

  private initCollectionListResultColumns() {
    this.resposiveSubcription$= this.breakpointObserver
      .observe([
        '(max-width: 1350px)',
      ])
      .subscribe((state: BreakpointState) => {
        if (state.breakpoints['(max-width: 1350px)']) {
          this.columns = this.filterColumns(this.colletionListColumns,   [ 'spacerStart', 'spacerEnd' ])
        }
        else {
          this.columns = this.filterColumns(this.colletionListColumns, [])
        }
      });
  }

  private filterColumns(columns: any[], removeColumns: any[]) {
    return columns.filter(item => {
      return removeColumns.indexOf(item.key) === -1;
    });
  }

  private getProviderSettings() {
    this.providerSettingsSubscription$ = this.sessionService.providerSettingsChanged$.subscribe(
    result => {
      const verificationDueDays = result ? result.find(p => p.settingName === this.constants.UserListVerificationDueDays) : null;
      const verificationWarningDays = result ? result.find(p => p.settingName === this.constants.UserListVerificationWarningDays) : null;
      this.userListVerificationDueDaysSetting = verificationDueDays && +verificationDueDays.settingValue > 0 ? +verificationDueDays.settingValue : this.constants.UserListVerificationDueDaysDefaultValue;
      this.userListVerificationWarningDaysSetting = verificationWarningDays && +verificationWarningDays.settingValue > 0 ? +verificationWarningDays.settingValue : this.constants.UserListVerificationWarningDaysDefaultValue;

      this.loadData();
    },
    error => {
      this.loadData();
    });
  }
}
