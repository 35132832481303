<p-dialog header="Attestation" [(visible)]="display" [modal]="true" width="600">
    <div class="bls-modal-wrapper">

        <div class="bls-modal-container">
    
            <!-- <div class="bls-modal-title">
            Delegate Attestation
            </div> -->
    
            <div class="bls-modal-table-wrapper">
            By agreeing to the terms below, I am attesting that I received and reviewed the CalOptima Health Behavioral Quality Health Measure Report.
            </div>
    
            <div class="bls-modal-section">
                <div class="bls-modal-sig-box">
                    <div class="bls-modal-sig-label">
                    Your Full Name
                    </div>
                    <div class="bls-modal-sig-wrapper">
                        <div class="bls-modal-sig">
                        <input class="bls-modal-sig-input" type="text" placeholder="{{fullname}}" readonly>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="bls-modal-section">
            <label>
                <div class="bls-modal-check-wrapper">
                    <div class="bls-modal-check-left">
                        <input type="checkbox" class="bls-modal-checkbox" [(ngModel)]="iagree">
                    </div>
                    <div class="bls-modal-agree">
                        By downloading this report I attest that I have received the CalOptima Health BHI Quality Measure Report.
                    </div>
                </div>
            </label>
            </div>
    
            <div class="bls-modal-section">
                <div class="bls-modal-button-wrapper">
                    <button type="button" class="btn btn-primary btn-cancel" (click)="onCancel()">
                        Maybe Later
                    </button>
                    <button id="btnSubmit" type="button" class="btn btn-primary btn-rename" (click)="onSubmit()" [disabled]='!iagree'>
                        Submit
                    </button>  
                </div>
            </div>
    
            <div class="bls-modal-section">
            &nbsp;
            </div>
    
        </div>
    
        <div class="bls-modal-section">
        &nbsp;
        </div>
    
    </div>
    <ng-template pTemplate="footer">
        <app-alert-danger *ngIf='validationError'
            [subjectMessage]="''"
            [contentMessage]="validationMessage">
        </app-alert-danger>
    </ng-template>    
</p-dialog>
