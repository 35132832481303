import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CollectionService } from '../../../../services/collection.service';
import { CollectionListItem } from '../../../../services/models/collection-list-item';
import { Messages } from '../../../../app.messages';
import { isNgContent } from '@angular/compiler';

const ATTESTATION_VERSION: string = "1.0"

@Component({
  selector: 'attestation-dialog',
  templateUrl: './attestation-dialog.component.html',
  styleUrls: ['./attestation-dialog.component.scss']
})

export class AttestationDialogComponent implements OnInit {
  @Input() fullname: string;
  @Output() submitAttestation = new EventEmitter();
  @Output() cancelAttestation = new EventEmitter();
  public iagree:boolean = false;  
  

  ngOnInit() {

  }

  public onSubmit() {
    this.submitAttestation.emit(ATTESTATION_VERSION);
  }

  public onCancel() {
    this.cancelAttestation.emit();
  }

  public get display(): boolean {
    return true;
  }

  public set display(value: boolean) {
    this.onCancel();
  }
}
