import { Component, OnInit, Output, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { MenuItem } from 'primeng/api';

import { PortalConfig } from '../../portal-config';
import { Constants } from '../../app.constants';
import { UserAdminService } from '../../services/user-admin.service';
import { TrayItem } from '../../services/models/tray-item';
import { SessionService } from '../../services/session.service';
import { Subscription } from 'rxjs';
import { UserIdleService } from 'angular-user-idle';
// import { AppConfig } from '@caloptima/portal-foundation';
import { takeWhile, switchMap, map, repeat } from 'rxjs/operators';
import { PortalNavigationService } from '../../services/portal-navigation.service';
import { SessionTimeoutDialogComponent } from '../../app-components/dialogs/session-timeout-dialog/session-timeout-dialog.component';
import { JwtHelperService } from '@auth0/angular-jwt';
import { OAuthService } from 'angular-oauth2-oidc';
import { TrainingAlertDialogComponent } from '../../app-components/dialogs/training-alert-dialog/training-alert-dialog.component';
import { HipaaTrainingType } from '../../services/trainings.service';
import { DefaultOrganizationRequest } from '../../services/models/requests/default-organization-request';
import { UserPermission } from '../../services/models/user-permission';
import { RegistrationService } from '../../services/registration-service';

@Component({
  selector: 'app-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.scss']
})

export class NavigatorComponent implements OnInit, OnDestroy {
  public isEnterpriseAdmin: boolean;
  public showModuleTray = true;
  @Output() moduleIcon: string;
  @Output() moduleName: string;
  private activeMenu: any;
  public activeItem: TrayItem;
  private items: TrayItem[];
  private userMenu: TrayItem[];
  private moduleNav: any;
  private onTimerStart$: Subscription;
  private pingSubscription$: Subscription;
  private onTimerStop$: Subscription;
  private sessionTimeoutTimer$: Subscription;
  private organizationsSubscription$: Subscription;
  public display: boolean;
  public countDown = 0;
  private screenHeight: number;
  private screenWidth: number;
  public sessionTimeoutReminder: number;
  private sessionTimeoutDialog: SessionTimeoutDialogComponent;
  public displaySessionTimeout = false;
  public displayUserVerification = false;
  public displayHipaaTrainingAlert = false;
  public headerText: string;
  public contentText: string;
  public buttonText: string;
  public isLOA: boolean;
  public hipaaTrainingType: HipaaTrainingType;
  public verificationIcon: string;
  public displaySelectOrganizationDialog: boolean;
  public userPermissions: UserPermission[] = [];
  public daysLeftToResetPassword: number;
  public displayPasswordResetDialog: boolean;

  @ViewChild('appTrainingAlertDialog') trainingAlertDialogComponent: TrainingAlertDialogComponent;

  constructor(
    private configuration: PortalConfig,
    // private config: AppConfig,
    private constants: Constants,
    private router: Router,
    private adminService: UserAdminService,
    private route: ActivatedRoute,
    private el: ElementRef,
    private sessionService: SessionService,
    private userIdle: UserIdleService,
    private portalNavigationService: PortalNavigationService,
    private authService: OAuthService,
    public jwtHelper: JwtHelperService = new JwtHelperService(configuration.AccessTokenName),
    private registrationService: RegistrationService,
  ) {
    this.showModuleTray = true;
    this.activeItem = null;
    this.isEnterpriseAdmin = false;
  }

  ngOnInit() {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.portalNavigationService.showSidebar$.subscribe(data => {
      this.showModuleTray = data;
    });
    this.startIdleCheck();
    document.body.addEventListener('click', ev => {
      if (this.activeMenu != null &&
        ((ev.x < this.activeMenu.offsetLeft || ev.x > this.activeMenu.offsetLeft + this.activeMenu.offsetWidth) ||
          (ev.y < this.activeMenu.offsetTop || ev.y > this.activeMenu.offsetTop + this.activeMenu.offsetHeight))) {
        this.hideMenu();
      }
    });
    this.moduleNav = this.el.nativeElement.firstChild;
    this.items = this.portalNavigationService.getNavigationItems();
    this.onTrayItemSelected(this.items[0]);
    this.sessionService.permissionsChanged().subscribe(permissions => {
      this.userMenu = this.generateMenu(this.items);
    });
    const url = this.router.url;

    this.sessionService.passwordResetWarningSubject().subscribe(
      result => {
        this.daysLeftToResetPassword = result;
    });

    this.sessionService.isPasswordResetLockedSubject().subscribe(
      result => {
        this.displayPasswordResetDialog = result;
    });

    if (localStorage.getItem('redirectUrl')) {
      let url: string = localStorage.getItem('redirectUrl') ? localStorage.getItem('redirectUrl') : '/';
      localStorage.removeItem('redirectUrl');
      url = this.configuration.RedirectUri + '#' + url;
      window.location.href = url;
      const selectedItem = this.findModuleItemFromRoute(url);
      this.onTrayItemSelected(this.items[selectedItem]);
    }

    this.setSessionTimeoutTimer();
    this.setOrganizationDisplay();
    this.displayHipaaTrainingAlert =  this.sessionService.isHipaaTrainingDue && !this.sessionService.showOrganizationsDashboardPopUp;
    this.hipaaTrainingType = this.sessionService.hasCollectionAdmin ? HipaaTrainingType.LoaTraining : HipaaTrainingType.StaffTraining;
    this.setUserValidationMessage();
    // this.setResetPasswordMessage();
  }

  setOrganizationDisplay() {
    this.displaySelectOrganizationDialog = this.sessionService.showOrganizationsDashboardPopUp;
    if (this.displaySelectOrganizationDialog) {
      this.organizationsSubscription$ = this.sessionService.organizationsChanged$
      .subscribe(result => {
        if (result) {
          this.userPermissions = result;
        }
      });
    }
  }

  UpdateDefaultorganization(defaultorgData) {
    let userId = this.userPermissions[this.userPermissions.findIndex(x=> x.providerCollectionID === defaultorgData.providerCollectionId)].userID;

    var defaultCollectionRequest :DefaultOrganizationRequest = {
      userID : userId,
      defaultProviderCollectionID: defaultorgData.providerCollectionId
    };

    this.adminService.UpdateDefaultUserProviderCollection(defaultCollectionRequest).subscribe(result => {
      this.sessionService.isDefaultCollectionJustSet = true;
      this.sessionService.start();
      this.displaySelectOrganizationDialog = false;
    });
  }

  findModuleItemFromRoute(path: string): number {
    let result = 0;
    for (let i = 0; i < this.items.length; i++) {
      if (path.includes(this.items[i].routerLink)) {
        result = i;
        break;
      }
    }
    return result;
  }

  ngOnDestroy() {
    this.unsubscribeIdle();

    if (this.organizationsSubscription$) {
      this.organizationsSubscription$.unsubscribe();
    }
  }

  private generateMenu(items: TrayItem[]): TrayItem[] {
    const menu: TrayItem[] = [];
    const isEnterpriseAdmin = this.sessionService.isEnterpriseAdmin;

    items.forEach(item => {
      if (item.permissionCodes == null) {
        menu.push(item);
      }
      else if (this.sessionService.hasPermission(item.permissionCodes) ||
              ((item.name === 'Admin' || item.name === 'Verify Collections') && this.sessionService.hasCollectionAdmin))
      {
        let childItems: TrayItem[] = null;
        const menuCopy: TrayItem = Object.assign({}, item);
        if (item.menuItems != null) {
          childItems = this.generateMenu(item.menuItems);
          if (childItems.length > 1) {
            menuCopy.menuItems = childItems;
          }
          else if (childItems.length === 1) {
            // If there is only one option available in the child menu, then just change the module
            // to use that item as the navigation link.
            menuCopy.menuItems = null;
            menuCopy.routerLink = childItems[0].routerLink;
          }
        }
        if (childItems == null || childItems.length >= 1) {
          // Make sure there is at least one navigation item the user can go to.
          menu.push(menuCopy);
        }
      }
    });
    return menu;
  }

  private hideMenu() {
    this.activeMenu.classList.add('hidden');
    this.activeMenu = null;
  }

  private onTrayItemSelected(item: TrayItem) {
    item.mouseDown = false;
    this.activeItem = item;
    this.portalNavigationService.setSelectedTrayItem(item);
  }

  private openMenu(el: any, y: number): boolean {
    let activate = true;
    if (el.nodeName === '#text') {
      el = el.parentNode;
    }
    let menu = el.getElementsByClassName('context-menu')[0];
    if (this.activeMenu != null) {
      if (this.activeMenu == menu) {
        activate = false;
      }
      this.hideMenu();
    }
    if (activate) {
      let top = (y - 95) - el.offsetTop;
      menu.classList.remove('hidden');
      const bottom = y + menu.clientHeight;
      if (bottom > window.outerHeight || bottom > (document.body.clientHeight - this.moduleNav.offsetTop)) {
        top -= menu.clientHeight;
      }
      menu.style.top = top + 'px';
      this.activeMenu = menu;
    }

    return activate;
  }

  public getMenu(): TrayItem[] {
    return this.userMenu;
  }


  public onTrayItemClick(item: TrayItem, e) {
    let cancelBubble = true;
    this.onTrayItemSelected(item);
    if (item.menuItems != null && item.menuItems.length > 0) {
      item.menuItems.forEach(menu => {
        menu.contextMenuClass = null;
      });
      cancelBubble = this.openMenu(e.currentTarget, e.clientY);
    }
    else {
      this.router.navigate([item.routerLink]);
    }
    if (item.menuItems != null && item.menuItems.length > 0 && cancelBubble) {
      e.cancelBubble = true;
      e.preventDefault();
    }
    else {
      e.cancelBubble = false;
    }
    //e.preventDefault();
  }

  public onOpenMenu(e) {
    this.openMenu(e.target.firstChild, e.clientY);
    e.cancelBubble = true;
    e.preventDefault();
  }

  public onSelect(menu: MenuItem, $event) {
    $event.cancelBubble = true;
    $event.preventDefault();
    this.hideMenu();
    this.router.navigateByUrl(menu.routerLink);
  }

  public startIdleCheck() {

    const idleSeconds = 840; // 15;
    const timeoutSeconds = 60; // 10;
    const pingSeconds = 120; // 2;

    this.userIdle.setConfigValues({
      idle: idleSeconds,
      timeout: timeoutSeconds,
      ping: pingSeconds
    });
    // create subscription array and push all these for disposing in destructor
    this.countDown = timeoutSeconds;
    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.onTimerStart$ = this.userIdle.onTimerStart().subscribe(count => {
      this.display = true;
      this.countDown = timeoutSeconds - count;
    });

    // Start watch when time is up.
    this.onTimerStop$ = this.userIdle.onTimeout().subscribe(() => {
      this.logOut();
    });

    this.pingSubscription$ = this.userIdle.ping$.subscribe(() => console.log('Ping!!!'));
  }

  public unsubscribeIdle() {
    if (this.onTimerStart$) {
      this.onTimerStart$.unsubscribe();
    }
    if (this.onTimerStop$) {
      this.onTimerStop$.unsubscribe();
    }
    if (this.pingSubscription$) {
      this.pingSubscription$.unsubscribe();
    }
    if (this.sessionTimeoutTimer$) {
      this.sessionTimeoutTimer$.unsubscribe();
    }
  }

  private setSessionTimeoutTimer() {
    if (this.authService.hasValidAccessToken()) {
      this.sessionTimeoutDialog = new SessionTimeoutDialogComponent(this.authService, this.jwtHelper, this.sessionService);
      this.sessionTimeoutTimer$ = this.getTimer$.pipe(
        takeWhile(() =>
          this.sessionTimeoutDialog.checkSessionTimeout
        ))
        .subscribe(t => {
          if (t) {
            this.displaySessionTimeout = true;
            this.sessionTimeoutReminder = t;
          }
        });
    }
  }

  getTimer$ = of(null)
    .pipe(
      switchMap(e =>
        this.sessionTimeoutDialog.refreshTimerObservable() // wait for interval, then emit
      ),
      map(t =>
        this.sessionTimeoutDialog.getSessionTimeoutReminder()  // get new time
      ),
      repeat()
    );

  public onSessionTimeoutClosed() {
    this.displaySessionTimeout = false;
  }

  public logOut() {
    this.unsubscribeIdle();
    this.sessionService.end();
  }

  public onLogOutUser($event) {
    this.logOut();
  }

  public onContinueSession($event) {
    this.userIdle.resetTimer();
  }

  onClose() {
    this.display = false;
  }

  public onStartTrainingAccepted(event) {
    if (event) {
      if (this.sessionService.isHipaaTrainingDue) {
        this.trainingAlertDialogComponent.display = false;
        if (this.sessionService.hasCollectionAdmin) {
          this.router.navigate(['/trainings/hipaa-loa']); // PRVADM
        }
        else {
          this.router.navigate(['/trainings/hipaa-staff']);
        }
      }
    }
  }

  private setUserValidationMessage() {
    this.displayUserVerification = (this.sessionService.isUserVerifiedDue || this.sessionService.isCollectionVerifiedDue || this.sessionService.isAdminLocked || this.sessionService.isDeactivated)
    && !this.sessionService.showOrganizationsDashboardPopUp;

    //locked and deactivated should take precedence over everything
    if(this.sessionService.isAdminLocked || this.sessionService.isDeactivated) {
      let lockedMessage = '';
      if (this.sessionService.isAdminLocked) {
        lockedMessage = this.sessionService.isEnterpriseUser
        ? 'Your account has been locked. Please submit a CalOptima Health helpdesk ticket to have your account reviewed.'
        : 'Your account has been locked by a CalOptima Health Admin. Please contact CalOptima Health at 714-246-8600 for assistance.';
        this.headerText = "Account Locked";
      } else {
        lockedMessage = this.sessionService.isEnterpriseUser
        ? 'Your account has been deactivated.'
        : 'Your account has been deactivated by a CalOptima Health Admin. Please contact CalOptima Health at 714-246-8600 for assistance.';
        this.headerText = "Account Deactivated";
      }

      this.contentText = lockedMessage;
      this.buttonText = "Log out";
      this.verificationIcon = "";
      this.verificationIcon= 'fa-icon fa fa-lock';
    }
    else {
      this.verificationIcon= 'fa-icon fa fa-exclamation-triangle';
      this.isLOA = this.sessionService.isAdmin;
      if(this.sessionService.isAdmin){
        this.headerText = "User Verification";
        this.contentText =
          "You must verify the users for your organization before accessing the portal.";
        this.buttonText = "Verify";
      }
      else{
        this.headerText = "Provider Office Suspended";
        this.contentText =
          "Your office has been suspended due to unverified user accounts. Please contact CalOptima Health at 714-246-8600 or your local office administrator for assistance.";
        this.buttonText = "Log out";
      }
    }
  }

  public onUserVerificationAction($event){
    if(!$event){
      this.sessionService.end();
    }
    else {
      this.displayUserVerification = false;
      this.router.navigate(['/user-admin/collections/verify']);
    }
  }
}
