import { OnInit, Injectable } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { pipe } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UiUtility } from '../utils/ui-utility';
import { OAuthService } from 'angular-oauth2-oidc';
import { SessionService } from './session.service';

@Injectable({ providedIn: 'root' })
export class RouterHistoryService {
  private _previousUrl: string;
  private _currentUrl: string;

  constructor(
    private router: Router,
    private oauth: OAuthService,
    private sessionService: SessionService
  ) {
    this._previousUrl = null;
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
        this._previousUrl = this._currentUrl;
        this._currentUrl = urlAfterRedirects;
      });
  }

  public get previousUrl(): string {
    return this._previousUrl;
  }

  public navigateToPreviousUrl(): void {
    if (this._previousUrl != null) {
      this.router.navigate([this._previousUrl]);
    }
  }

  public checkUnSupportedBrowser() {
    const unsupportedBrowser = "unsupportedbrowser";
    if (window.location.hash.indexOf(unsupportedBrowser) > -1) {
      return;
    }

    const browserName = UiUtility.getSupportedBrowserName();
    if (browserName === 'other') {
      if (this.oauth.getIdentityClaims() != null) {
        this.sessionService.end();
      }
      else {
        this.router.navigate([unsupportedBrowser]);
      }
    }
  }
}
