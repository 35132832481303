import { Component, OnInit, AfterViewInit, ElementRef, ViewChild} from '@angular/core';

import { MessageService } from 'primeng/api';


import { Member } from '../../services/models/member';
import { MemberInfoService } from '../../services/member-info.service';
import { Messages } from '../../app.messages';
import { NgForm } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss']
})
export class MemberComponent implements OnInit {

  @ViewChild('memberSearchInput') memberInput: ElementRef;
  public showFaceSheet = false;
  public memberId: string;
  navigationSubscription: Subscription;
  public isLoading: boolean;

  constructor(
    private memberInfoService: MemberInfoService,
    private messageService: MessageService,
    private messages: Messages,
    private router: Router
    ) { }

  ngOnInit() {
     this.memberInfoService.clearMemberList();
     // subscribe to the router events. Store the subscription so we can unsubscribe later.
     this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component.
      if (e instanceof NavigationEnd) {
        this.initialiseInvites();
      }
    });
  }

  initialiseInvites() {
        this.onCloseFaceSheet();
        this.memberInfoService.clearMemberList();
      }

  onCloseFaceSheet() {
    this.showFaceSheet = false;
  }

  public onSelectedMember(showFS: boolean) {
    this.showFaceSheet = true;
  }

  public getMemberById(id: string) {
    // call sets member list but only for non empty results
    this.memberInfoService.getMembersByIdQuickSearch(id).subscribe(data => {
      // if 0 then show toaster if Steve doesn't prefer the lookup screen
      if (!data || data && data.length === 0) {
        // show toaster
        this.messageService.add({
          severity: this.messages.SeverityWarn,
          summary: this.messages.MessageMembersNoResultsFound,
          life: this.messages.ToasterLife
        });
      }
      else if (data && data.length === 1) {
        this.memberInfoService.setSelectMember(data[0]);
        this.memberId = '';
        // if one return set selected member
        // else show member search
      }
      else {
        this.memberInput = null;
        this.showFaceSheet = false;
        this.memberId = '';
      }
    });
  }

  public onSubmit(facesheetForm: NgForm) {
    this.getMemberById(this.memberId);
    facesheetForm.resetForm();
  }

  public onKeyupDown(event: KeyboardEvent) {
  }

  public isLoadingChange(event){
    this.isLoading = event;
  }

}
