<div class="hif-member-data">
    <div class="hif-member-data-description">
      The Health Information Form/Member Evaluation Tool (HIF/MET) is an initial screening tool for newly enrolled Medi-Cal members. CalOptima Health shares this information with assigned PCPs so they can follow up on positive screening results during the required Initial Health Appointment and ongoing patient care.
    </div>
    <div class="hif-member-data-name">
      {{member ? member.memberName : ''}}
    </div>    
    <div class="hif-member-data-date">
      <table width="100%">
        <tr>
          <td width="10%" style="font-weight:bold">Member ID:</td>
          <td width="15%">{{member ? member.cin : ''}}</td>
          <td width="10%">&nbsp;</td>
          <td width="10%" style="font-weight:bold">Provider Id:</td>
          <td width="15%">{{member ? member.providerId : ''}}</td>
          <td width="10%">&nbsp;</td>
          <td width="15%" style="font-weight:bold">Completion Date:</td>
          <td width="15%">{{member ? (member.completionDate | date:'MM-dd-yyyy') : ''}}</td>
        </tr>
      </table>
    </div>
    <div class="hif-member-data-list">
      <p-table #hifdata
      [value]="displayHif"
      [paginator]="true"
      [rows]="20"
      [scrollable]="true"
      [style]="{'text-align': 'left', 'font-size': '12px'}"
      scrollHeight="525px">
        <ng-template pTemplate="header">
          <tr>
            <th width="70%" pSortableColumn="question">Question</th>
            <th width="30%" pSortableColumn="answer">Answer</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr>
            <td width="70%">
              <div>{{row.questionText}}</div>
            </td>
            <td width="30%">
              <div>{{row.value}}</div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="hif-member-data-footer">
      <button type="button" class="btn btn-primary btn-create" (click)="onPrintToPdf()">Download</button>
    </div>
</div>