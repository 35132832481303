import { HttpClient } from '@angular/common/http';
import { OAuthService } from "angular-oauth2-oidc";
import { PortalConfig } from "../portal-config";
import { AppConfig, HttpResponseHelper } from "@caloptima/portal-foundation";
import { Constants } from "../app.constants";
import { SessionService } from "./session.service";
import { ResourceRequest } from "./models/requests/resource-request";
import { catchError, map, Observable } from "rxjs";
import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BHABAReferralAttestationPortalResources } from "./models/bh-aba-referral-attestation-portal-resources";

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true
  };
  
  @Injectable({
    providedIn: 'root'
  })

  export class ResourcesService {
    
    private currentUser: string;
    private baseProviderReportServiceUrl: string;
    private bhabareferralinstructionUrl: string;

    constructor(
        private oAuthService: OAuthService,
        private http: HttpClient,
        private portalConfig: PortalConfig,
        private appConfig: AppConfig,
        private constants:Constants,
        private sessionService: SessionService) {
    
          const claims = this.oAuthService.getIdentityClaims();
          this.currentUser = claims['sub'];
          this.baseProviderReportServiceUrl = appConfig.getConfig('BaseProviderServicesApiUrl');
          if (this.baseProviderReportServiceUrl == null) {
            const config$ = appConfig.subscribe(() => {
              this.baseProviderReportServiceUrl = appConfig.getConfig('BaseProviderServicesApiUrl');
              this.checkUrls();
              config$.unsubscribe();
            });
          }
    }

    private checkUrls(): void {
      const baseUrl = this.baseProviderReportServiceUrl + 'api/resource/';
      if(this.bhabareferralinstructionUrl == null) {
          this.bhabareferralinstructionUrl = baseUrl + 'bhabareferralinstruction';
      }
    }

    public getBHABAReferralAttestationResources():Observable<BHABAReferralAttestationPortalResources> {      
      
      this.checkUrls();
  
      try{
        const request = new ResourceRequest();
      
        return this.http
          .post<BHABAReferralAttestationPortalResources>(this.bhabareferralinstructionUrl, request, httpOptions)
          .pipe(
            map((result) => {            
              console.log(result);
              return result;
            }),
            catchError(error => HttpResponseHelper.handleError(error))
          );
  
      }
      catch(ex) {
  
      }
    }
  }