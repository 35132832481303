
import { Component, OnInit, Provider, Inject } from '@angular/core';
import { UserProfile } from '@caloptima/authentication';
import { SelectItem } from 'primeng/api';
import { DropDownObject } from '../../services/models/drop-down-object.interface';
import { ProviderDetail } from '../../services/models/provider-detail';
import { ReportsService } from '../../services/reports.service';
import { SessionService } from '../../services/session.service';
import { saveAs } from 'file-saver';
import { DatePipe, DOCUMENT } from '@angular/common';
import { ReportType } from '../../services/models/reportype';
import { UiUtility } from '../../utils/ui-utility';

@Component({
  selector: 'app-reports-pay-for-value',
  templateUrl: './reports-pay-for-value.component.html',
  styleUrls: ['./reports-pay-for-value.component.scss']
})
export class ReportsPayForValueComponent implements OnInit {

  public userProfile: UserProfile;
  public providers:ProviderDetail[] = [];
  public displayProviders:ProviderDetail[] = [];
  public selectedCalProviderID: string;
  private sortColumn: string;
  private sortAscending: boolean;
  public totalRecords: number;
  public fileList: SelectItem[];
  public selectedFile: DropDownObject;
  public searchProviderId: string;
  public searchProviderName: string;

  constructor(
    private sessionService: SessionService,
    private reportsService: ReportsService,
    private datePipe: DatePipe,
    @Inject(DOCUMENT) private document: Document) {
      this.sortColumn = 'name';
      this.sortAscending = true;
      this.selectedFile = null;
      this.searchProviderId = '';
      this.searchProviderName = '';
      this.selectedCalProviderID = '';
  }

  ngOnInit() {

    if(this.sessionService.currentPermission.isEnterpriseAdmin || this.sessionService.currentPermission.isEnterpriseUser) {
      //  Nothing. 
    }
    else {
      this.reportsService.getProvidersForReports(this.sessionService.currentPermission.providerCollectionID, ReportType.P4V).subscribe(result=>{

        this.providers = result;
  
        this.totalRecords = this.providers.length;
  
        if(this.providers)
          this.displayProviders = this.providers.slice();
  
        let param: any = {
          "field": "name",
          "order": 1
        }
        this.onSort(param)
      });
    }
  }

  public sortClass(column: string): string {
    let cls: string;
    if (column == this.sortColumn) {
      cls = this.sortAscending ? "fa fa-chevron-up" : "fa fa-chevron-down";
    }
    else {
      cls = "fa fa-chevron-up";
    }
    return cls;
  }

  public onPage(e: any) {
    let s = JSON.stringify(e);
  }

  public onSort(e: any): void {
    this.providers.sort((data1, data2) => {
      let value1 = data1[e.field];
      let value2 = data2[e.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (e.order * result);
    });
    this.sortAscending = e.order == 1;
    this.sortColumn = e.field;
  }

  public onProviderSelected(provider: ProviderDetail): void {
    this.selectedCalProviderID = provider.calProviderID;

    this.fileList = [];
    this.selectedFile = null;

    this.reportsService.getReportFilesForProvider(this.selectedCalProviderID, ReportType.P4V).subscribe(result=>{
      result.forEach(x=>
        {
          this.fileList.push(
            {
              label: this.datePipe.transform(x.yearMonth, 'yyyy-MM'),
              value: x
            });
        });

      if(this.fileList.length > 0) {
        this.selectedFile = this.fileList[0].value;
        UiUtility.scrollDown(this.document);
      }
    });
  }

  public get displayText(): string {
    if(this.selectedCalProviderID) {

      let providerDetail: ProviderDetail = this.providers.find(x=> x.calProviderID === this.selectedCalProviderID);

      if(providerDetail) {
        let s = providerDetail.name;
        return s;
      }
      else {
        return ""
      }
    }
    else {
      return "";
    }
  }

  public get enableBuildReport() : boolean {

    return this.selectedCalProviderID !== '' && this.selectedFile !== null
  }

  public onBuildReport() : void {

    var temp:any = this.selectedFile;
    var fileName = temp.fileName;

    this.reportsService.downloadReportFileForProvider(this.selectedCalProviderID, fileName, ReportType.P4V).subscribe(result=>{
      this.saveAsFile(result, fileName + ".xlsb", 'xlsb');
    });
  }

  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob([buffer], { type: fileType });
    saveAs(data, fileName);
  }

  public onSearch(search) : void {
    this.selectedCalProviderID = '';
    this.fileList = [];
    this.selectedFile = null;

    if (search && (search.providerName || search.providerId || search.providerTin)) {
      
      if(this.sessionService.currentPermission.isEnterpriseAdmin || this.sessionService.currentPermission.isEnterpriseUser) {
          this.reportsService.searchProvidersForReports(search.providerId, search.providerName, search.providerTin, ReportType.P4V).subscribe(result => {
            this.providers = result;
            this.displayProviders = this.providers.slice();
           });
      }
      else {
        if (!UiUtility.isNullUndefinedOrEmpty(search.providerName)) {
          this.displayProviders = this.providers
            .filter(p => p.name.toLowerCase().indexOf(search.providerName.trim().toLowerCase()) >= 0);
        }
        else if (!UiUtility.isNullUndefinedOrEmpty(search.providerId)) {
          this.displayProviders = this.providers
            .filter(p => p.calProviderID.toLowerCase().indexOf(search.providerId.trim().toLowerCase()) >= 0);
        }
        else if (!UiUtility.isNullUndefinedOrEmpty(search.providerTin)) {
          this.displayProviders = this.providers
            .filter(p => p.taxId.toLowerCase().indexOf(search.providerTin.trim().toLowerCase()) >= 0);
        }   
      }
    }
    else {
      this.displayProviders = this.providers.slice();
    }
  }
}
