<p-sidebar
  [(visible)]="display" [style]="{width: '45rem'}"
  position="right" [dismissible]="false"
  [closeOnEscape]="false" [showCloseIcon]="false"
  modal="true" autoZIndex="true" baseZIndex="100000"
  (onShow)="onShow()" (onHide)="onCancel()">
  <div class="user-decline-container">
    <div class="user-decline-container-header">
      <i class="fa fa-user-times" aria-hidden="true"></i>
      <span class="title">Decline User</span>
      <span class="close-wrapper"  (click)="onCancel()">
        <i class="fa fa-times close" aria-hidden="true"></i>
      </span>
    </div>
    <div class="user-decline-container-body">
      <span class="message">
        Decline {{userFullName}}?
      </span>
      <div class="decline-reason">
        <textarea id="txtDeclineReason" [(ngModel)]="declineReason" #declineReasonTxt="ngModel" (ngModelChange)="onChange($event)" maxlength="500" required></textarea>
        <div class="decline-validation" *ngIf="!declineReason && (declineReasonTxt.touched || declineReasonTxt.dirty)">You must enter a decline reason.</div>
      </div>
    </div>
    <div class="user-decline-container-footer">
      <button type="button" class="btn btn-primary btn-decline" [disabled]="!declineReason" (click)="onDecline()">Decline</button>
      <button type="button" class="btn btn-secondary btn-cancel" (click)="onCancel()">Cancel</button>
    </div>
  </div>
</p-sidebar>
