import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';
import { Lab } from 'src/app/services/models/lab';
import { MemberInfoService } from 'src/app/services/member-info.service';

@Component({
  selector: 'app-member-labs',
  templateUrl: './member-labs.component.html',
  styleUrls: ['./member-labs.component.scss']
})
export class MemberLabsComponent implements OnInit, OnDestroy, OnChanges {

  public member$: Subscription;
  public labs: Lab[];
  public totalLabs = 0;
  public sortOptions: SelectItem[];
  public sortField: string;
  public sortOrder: number;
  public sortKey: SelectItem;
  public userNotAssociatedToMemberPcpMessage: string;
  public isUserAssocToMemberPcp: boolean;

  @Output() labsChange = new EventEmitter();
  @Input() isLoading: boolean;
  @Input() tabName: boolean;

  constructor(private memberInfoService: MemberInfoService) { }

  ngOnChanges(){
    this.sortField = null;
  }

  ngOnInit() {
    this.sortField = null;    
    this.sortOptions = [
      {label: 'Most Recent Labs', value: 'labDate'},
      {label: 'Lab description', value: 'labDescription'},]
    this.sortKey = this.sortOptions[0];

    this.initializeData();
  }

  ngOnDestroy() {
    if (this.member$) {
      this.member$.unsubscribe();
    }
  }

  public onSortChange(event){
    const value = event.value.value;
    if (value === 'labDate') {
      this.sortOrder = -1;
    }
    else{this.sortOrder = 1;}
    this.sortField = value;
  }

  public onSortInit(event){
    this.sortField = this.sortKey.value;
    this.sortOrder = event.sortOrder;
  }

  private initializeData() {
    this.member$ = this.memberInfoService.selectedMemberChanged$.pipe(
      map((data) => {
        this.memberInfoService.getMemberLabs(data.cin, data.grgrCk).subscribe(result => {
          this.isUserAssocToMemberPcp = data.isUserAssocToMemberPcp;
          this.labs = result;
          if (this.labs && this.labs.length > 0) {
            this.totalLabs = this.labs.length;
          }
          else{
            this.totalLabs = 0;
          }
          this.labsChange.emit(true);
        }, error => {
          this.totalLabs = 0;
          this.labs = [];
          this.labsChange.emit(true);
        });
        return data;
      })
    ).subscribe();
  }
}
