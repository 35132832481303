export class ServiceUtility {

  public static getFactorsChoiceDislaySequence(factorType: string) {
    switch (factorType) {
      case 'Email':
        return 1;
      case 'SMS':
        return 2;
      case 'Call':
        return 3;
      default:
        return 4;
    }
  }

}
