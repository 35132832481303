<div class="member-labs-container" *ngIf="!isLoading">
  <div class="member-labs-message" *ngIf="!isUserAssocToMemberPcp">
    <app-member-custom-messages-nonpcp></app-member-custom-messages-nonpcp>
  </div>
  <div class="member-labs-body" *ngIf="isUserAssocToMemberPcp">
    <p-dataView [value]="labs" [sortField]="sortField" [sortOrder]="sortOrder" (onSort)="onSortInit($event)">
      <ng-template pTemplate="header">
        <div class="labs-history-header">
          <img src="./assets/images/labs-icon.png" class="labs-icon">
          <h4>{{tabName}}</h4>
          <div class="header-right">
            <div class="sort-text">Sort by: </div>
            <p-dropdown [options]="sortOptions" [(ngModel)]="sortKey" optionLabel="label"
              (onChange)="onSortChange($event)"></p-dropdown>
          </div>
        </div>
      </ng-template>
      <ng-template let-labs pTemplate="list">
        <div class="member-labs-row" *ngFor="let lab of labs; let first = first">
          <div class="date-header">
            Lab date:
          </div>
          <div class="date-content">
            {{lab.labDate | date: 'MM/dd/yyyy'}}
          </div>
          <div class="description-header">
            Lab description:
          </div>
          <div class="description-content" title="{{lab.labDescription}}">
            {{lab.labDescription}}
          </div>
          <div class="result-header">
            Results:
          </div>
          <div class="result-content" title="{{lab.labResultValue}} {{lab.labResultUnits}}">
            {{lab.labResultValue}} {{lab.labResultUnits}}
          </div>
          <div class="range-header">
            Standard range:
          </div>
          <div class="range-content">
            {{lab.labResultReferenceRange}} {{lab.labResultUnits}}
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <div class="member-labs-footer" *ngIf="isUserAssocToMemberPcp">
          {{totalLabs}} results
        </div>
      </ng-template>      
    </p-dataView>
  </div>
</div>
