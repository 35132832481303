import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { PortalUser } from '../../../services/models/portal-user';
import { CollectionService } from '../../../services/collection.service';
import { BusyService } from '@caloptima/portal-foundation';

@Component({
  selector: 'app-user-decline-dialog',
  templateUrl: './user-decline-dialog.component.html',
  styleUrls: ['./user-decline-dialog.component.scss']
})
export class UserDeclineDialogComponent implements OnInit, OnDestroy {
  public userFullName: string;
  public declineReason: string;

  @Input() user: PortalUser;
  @Input() display: boolean;
  @Output() userUpdated = new EventEmitter();

  constructor(
    private collectionService: CollectionService,
    private busy: BusyService
  ) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  public onCancel() {
    this.display = false;
    this.userUpdated.emit({ action: 'Cancel' });
  }

  public onDecline() {
    const sub = this.collectionService.declineCollectionRequest(this.collectionService.selectedCollectionItem, this.user, this.declineReason)
    .subscribe(() => {
      this.busy.emit(false);
      this.userUpdated.emit({ action: 'Decline' });
      this.display = false;
      sub.unsubscribe();
    });
  }

  public onShow() {
    this.userFullName = this.user.name;
  }

  public onChange(event){
    this.declineReason = event;
  }
}
