import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { UserProfile, UserAddress, AuthProfile, AuthenticationService } from '@caloptima/authentication';
import { NgForm } from '@angular/forms';
import { PortalConfig } from '../../../portal-config';

@Component({
  selector: 'app-user-edit-dialog',
  templateUrl: './user-edit-dialog.component.html',
  styleUrls: ['./user-edit-dialog.component.scss']
})
export class UserEditDialogComponent implements OnInit {
  public zipMask: string = '99999';
  public phoneMask:string = '999-999-9999';
  public user: UserProfile;
  public userAddress: UserAddress;

  @Input() userProfile: UserProfile;
  @Input() display:boolean = false;
  @Output() userUpdated = new EventEmitter<boolean>();
  @ViewChild('userEditForm') userEditForm: NgForm;

  constructor(
    private portalConfig: PortalConfig,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.user = new UserProfile();
    this.userAddress = new UserAddress();
  }

  public onCancel() {
    this.onClose();
  }

  public onClose() {
    this.userUpdated.emit(false);
  }

  public onShow() {
    this.userEditForm.reset({
      address1: this.userProfile.address1,
      address2: this.userProfile.address2,
      city: this.userProfile.city,
      state: this.userProfile.state,
      zip: this.userProfile.zip,
      mobilePhone: this.userProfile.mobilePhone
    });
    this.user = <UserProfile>JSON.parse(JSON.stringify(this.userProfile));
    this.setUserAddress();
  }

  public saveUser() {
    if (!this.validFormControls()) {
      return;
    }

    let authProfile = new AuthProfile();
    authProfile.clientId = this.portalConfig.ClientId;
    authProfile.userProfile = this.user;
    authProfile.userProfile.password = null;
    authProfile.userProfile.knowledgeBase = null;
    authProfile.address = this.userAddress;

    this.authenticationService.updateSelectedUserProfile(authProfile).subscribe(
      () => {
        this.userUpdated.emit(true);
      },
      (error) => {
        this.userUpdated.emit(false);
      }
    )
  }

  public disabledSaveButton() {
    return  !this.userEditForm ||
            this.userEditForm.invalid ||
            (
              this.userAddress.address1 === this.user.address1 &&
              this.userAddress.address2 === this.user.address2 &&
              this.userAddress.city === this.user.city &&
              this.userAddress.state === this.user.state &&
              this.userAddress.zip === this.user.zip &&
              this.userProfile.mobilePhone === this.user.mobilePhone
            );
  }

  private setUserAddress() {
    this.userAddress = new UserAddress();
    this.userAddress.address1 = this.user.address1;
    this.userAddress.address2 = this.user.address2;
    this.userAddress.city = this.user.city;
    this.userAddress.state = this.user.state;
    this.userAddress.zip = this.user.zip;
  }

  private validFormControls() {
    let valid = true;

    if (!/^\d{5}$/g.test(this.userAddress.zip)) {
      const zipCtrl = this.userEditForm.form.controls['zip'];
      zipCtrl.setErrors( { 'invalid': true });
      zipCtrl.markAsTouched();
      valid = false;
    }

    if (!/^\d{3}\-\d{3}\-\d{4}$/g.test(this.user.mobilePhone)) {
      const mobilePhoneCtrl = this.userEditForm.form.controls['mobilePhone'];
      mobilePhoneCtrl.setErrors( { 'invalid': true });
      mobilePhoneCtrl.markAsTouched();
      valid = false;
    }

    return valid;
  }
}
