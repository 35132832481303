
import { ReportType } from "../reportype";

export class MemberSearchReportRequest {
    public userName: string;
    public collectionId: number;
    public calProviderId?:string;
    public providerTaxId:string;
    public grgr_ck:number;
    public memberId: string;
    public memberName: string;
    public reportType: ReportType;
}
