import { DatePipe, DOCUMENT } from "@angular/common";
import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { BusyService } from "../../services/busy.service";
import { ScriptQuestionAnswer } from "../../services/models/script-question-answer";
import { ReportsService } from "../../services/reports.service";
import { SessionService } from "../../services/session.service";
import { saveAs } from "file-saver";
import { MemberWithHifDownloadRequest } from "../../services/models/requests/member-with-hif-download-request";
import { ReportType } from '../../services/models/reportype';
import { ReportMemberDetails } from '../../services/models/reportmemberdetails';

@Component({
    selector: 'app-hif-member-data',
    templateUrl: './hif-member-data.component.html',
    styleUrls: ['./hif-member-data.component.scss']
  })

  export class HIFMemberDataComponent implements OnInit {

    @Input() membercin : string;

    public displayHif:ScriptQuestionAnswer[] = [];
    public displayErrorMessage = false;
    public headerErrorMessage: string;
    public contentErrorMessage: string;
    public hifFound: boolean = true;    
    public member: ReportMemberDetails;
    @Output() hifChange = new EventEmitter();

    constructor(
      private reportsService: ReportsService,
      private sessionService: SessionService,
      private datePipe: DatePipe,
      private busyService: BusyService,      
      @Inject(DOCUMENT) private document: Document) {
    }
    
    ngOnInit(): void {
      
      var collectionId = this.sessionService.currentPermission.providerCollectionID;

      this.reportsService.searchMembersForReportsHIF(ReportType.HIF, collectionId, '', this.membercin).subscribe(result=> {

        if(result.length > 0) {
          
          this.member = result[0];

          this.reportsService.getMemberHifData(this.membercin).subscribe(results=>{

            this.displayHif = results;

            if(this.displayHif.length == 0)
              this.hifFound = false;
          });
        }
        this.hifChange.emit(true);
      }, error => {
        this.hifChange.emit(true);
      });
    }    
  
    public onPrintToPdf() {
      const request:MemberWithHifDownloadRequest  = {
        memberCin: this.membercin,
        username: "",
        collectionId: this.sessionService.currentPermission.providerCollectionID,
        isExternalUser: true,
        providerIds: [],
        pageNumber: 0,
        pageSize: 0
      };
  
      this.reportsService.downloadMemberHifDetailPdf(request).subscribe(
        result => {
          const data: Blob = new Blob([result], { type: 'text/pdf' });
          saveAs(data, 'MemberHif-' + this.membercin + '.pdf');
          this.busyService.emit(false);
        },
        () => {
          this.busyService.emit(false);
          this.setErrorMessage('download');
        })
    }

    private setErrorMessage(errorType: string) {
      this.displayErrorMessage = true;
      if (errorType === 'download') {
        this.headerErrorMessage = "Failed to Download Member Hif";
        this.contentErrorMessage = "We're sorry! We are unable to process this request. Please try again later.";
      } else {
        this.headerErrorMessage = "Error";
        this.contentErrorMessage = "We're sorry! There was an error while processing this request. Please try again later.";
      }
    }
  }
