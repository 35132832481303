import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { SessionService } from '../../../services/session.service';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordGuard  {
  constructor(
    private sessionService: SessionService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let allowed: boolean = true;
    this.sessionService.isPasswordResetLockedSubject().subscribe(
      result => {
        allowed = !result;
    });
    return allowed;
  }
}
