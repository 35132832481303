import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild, AfterViewInit, Input, Provider } from '@angular/core';
import { MemberAWPStatus } from 'src/app/services/models/awp-detail';
import { BusyService, Email } from '@caloptima/portal-foundation';
import { ReportsService } from 'src/app/services/reports.service';
import { WellnessDetails2Extend, WellnessDetailsDiagnosisCaptured, WellnessDetailsDiagnosisReCaptured, WellnessDetailsOtherDiagnosis } from 'src/app/services/models/wellness-details';
import { SelectItem } from 'primeng/api';
import { ReportMemberDetails } from 'src/app/services/models/reportmemberdetails';
import { NullLogger } from '@microsoft/signalr';
import { CollectionService } from 'src/app/services/collection.service';
import { MemberInfoService } from 'src/app/services/member-info.service';
import { forkJoin, max } from 'rxjs';
import { ProviderDetail } from 'src/app/services/models/provider-detail';
import { Member } from 'src/app/services/models/member';
import { ProviderDetailInfo } from 'src/app/services/models/provider-detail-info';
import { ProviderDetail2 } from 'src/app/services/models/provider-detail2';

@Component({
  selector: 'report-wellness-details',
  templateUrl: './report-wellness-details.component.html',
  styleUrls: ['./report-wellness-details.component.scss']
})
export class ReportWellnessDetailsComponent implements OnInit, OnDestroy {
  
  @Input() selectedMemberAWPStatus: MemberAWPStatus;  
  @Input() selectedMember: ReportMemberDetails;  
  @Input() wellnessDetails: WellnessDetails2Extend;
  @Input() providerDetail: ProviderDetail2;
  @Input() memberDetail: Member;  
  @Output() onItemChange = new EventEmitter<any[]>();
  public screeningStatusList: SelectItem[] = [];
  public diagnosisStatusList: SelectItem[] = [];
  public statusList: SelectItem[] = [];
  public chronicDiagnosis: WellnessDetailsDiagnosisReCaptured[] = [];
  public nonChronicDiagnosis: WellnessDetailsDiagnosisReCaptured[] = [];
  public showAddDiagnosis:boolean = false;


  constructor(
    private busyService: BusyService) {
  }

  ngOnInit() {

    this.screeningStatusList.push(
      { label: 'Member Refused', value: 'Member Refused' },
      { label: 'Completed', value: 'Completed' },
      { label: 'Ordered/Referred', value: 'Ordered/Referred' },
      { label: 'Not Applicable', value: 'Not Applicable' },
      { label: 'Select One', value: null }
    )        
 
    this.diagnosisStatusList.push(
      { label: 'Undetermined', value: 'Undetermined' },      
      { label: 'Present', value: 'Present' },
      { label: 'Not Present', value: 'Not Present' },
      { label: 'Select One', value: null }
    )        

    this.statusList.push(
      { label: 'Undetermined', value: 'Undetermined' },
      { label: 'Present', value: 'Present' },
      { label: 'Not Present', value: 'Not Present' },
      { label: 'Select One', value: null }
    )    

    this.LoadData();
  }

  ngOnDestroy() {    
  }

  LoadData() {

    if(this.wellnessDetails) 
    {
      this.chronicDiagnosis = [];
      this.nonChronicDiagnosis = [];

      this.wellnessDetails.diagnosisReCaptured.forEach(diag=>{
        if(diag.isChronic && diag.isChronic.trim().toUpperCase() === "Y") {
          this.chronicDiagnosis.push(diag);
        }
        else{
            this.nonChronicDiagnosis.push(diag);
        }
      });            

      var index: number= 1;
      this.wellnessDetails.otherDiagnosis.forEach(x=>{
        x.index = index;
        index++;
      });
    }
  }

  public get additionalComments(): string {
    if(this.wellnessDetails && this.wellnessDetails.additionalComments)
      return this.wellnessDetails.additionalComments;
    else  
      return "";
  }

  public set additionalComments(val:string) {
    if(this.wellnessDetails)
      this.wellnessDetails.additionalComments = val;
  }

  public get notes(): string {
    if(this.wellnessDetails && this.wellnessDetails.notes)
      return this.wellnessDetails.notes;
    else  
      return "";
  }

  public set notes(val:string) {
    if(this.wellnessDetails)
      this.wellnessDetails.notes = val;
  }

  public get showData() : string{
    if(this.wellnessDetails)
      return JSON.stringify(this.wellnessDetails);
    else
      return "";
  }
  
  public diagnosisAdded($event) {

    if(this.wellnessDetails) {
      let temp: WellnessDetailsOtherDiagnosis = new WellnessDetailsOtherDiagnosis();
      temp.description = $event.name;
      temp.diagCode = $event.id;
      temp.documentId = this.wellnessDetails.documentId;
      temp.status = null;
      temp.memberCin = this.selectedMember.cin;
      this.wellnessDetails.otherDiagnosis.push(temp);

      this.onItemChange.emit();
    }

    this.showAddDiagnosis = false;
  }

  public onChange($event) {
    this.onItemChange.emit();
  }

  public get getAddress(): string {
    if(this.providerDetail)
      return this.providerDetail.address1 +", " + (this.providerDetail.address2 && this.providerDetail.address2 !== '' ? this.providerDetail.address2 + ", " : "") + this.providerDetail.city + ", " + this.providerDetail.state + ", " + this.providerDetail.zipCode;
    else
      return "";
  }

  public get dateOfService():Date {
    if(this.wellnessDetails)
      return this.wellnessDetails.dateOfService;

    return null;
  }

  public set dateOfService($value: Date) {
    if(this.wellnessDetails)
      this.wellnessDetails.dateOfService = $value;
  }

  public removeDiagnosis(diag) {
    if(diag){
      var index = this.wellnessDetails.otherDiagnosis.indexOf(diag);
      if(index >= 0) {
        this.wellnessDetails.otherDiagnosis.splice(index,1);
        this.onItemChange.emit();
      }
    }    
  }

  public diagnosisCancelled() {
    this.showAddDiagnosis = false;
  }

  public onAddDiagnostic() {

    if(this.wellnessDetails) {

      var maxIndex: number = 1;
      this.wellnessDetails.otherDiagnosis.forEach(x=>{
        if(x.index > maxIndex)
          maxIndex = x.index;
      });

      let temp: WellnessDetailsOtherDiagnosis = new WellnessDetailsOtherDiagnosis();
      temp.description = "";
      temp.diagCode = "";
      temp.documentId = this.wellnessDetails.documentId;
      temp.status = "Present";
      temp.memberCin = this.selectedMember.cin;
      temp.index = maxIndex + 1;
      this.wellnessDetails.otherDiagnosis.push(temp);

      this.onItemChange.emit();
    }

    //this.showAddDiagnosis = true;
  }
}
