<p-dialog [(visible)]="display" [style]="{width: '26rem'}" [baseZIndex]="10000" [modal]="true" [closeOnEscape]="false"
  [draggable]="false" [resizable]="false" [closable]="false">
  <p-header>
    <h1><i class="fa-icon fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp; <span>RESET PASSWORD</span></h1>
  </p-header>
  <div class="suspend-body">
    <div class="days-left">0 days left</div>
    You must reset your password before accessing the portal.
    <button type="button" class="logout-button btn btn-primary" (click)="onResetPasswordAction()">
      <h2> RESET PASSWORD </h2>
    </button>
  </div>
</p-dialog>
