
import { Component, OnInit, Provider, Inject } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { DropDownObject } from '../../../services/models/drop-down-object.interface';
import { ProviderDetail, ProviderDetailWithAttestation } from '../../../services/models/provider-detail';
import { ReportsService } from '../../../services/reports.service';
import { SessionService } from '../../../services/session.service';
import { DatePipe, DOCUMENT } from '@angular/common';
import { ReportType } from '../../../services/models/reportype';
import { AuthenticationService, UserProfile } from '@caloptima/authentication';
import { UiUtility } from '../../../utils/ui-utility';
import { AttestationDetail } from '../../../services/models/attestation-detail';
import { AttestationStatus } from '../../../services/models/attestationstatus';
import { OAuthService } from 'angular-oauth2-oidc';
import { PortalConfig } from '../../../portal-config';

@Component({
  selector: 'app-attestation-bloodlead-screening',
  templateUrl: './attestation-bloodlead-screening.component.html',
  styleUrls: ['./attestation-bloodlead-screening.component.scss']
})

export class AttestationBloodleadScreeningComponent implements OnInit {

  public userProfile: UserProfile;
  public providers:ProviderDetailWithAttestation[] = [];
  public displayProviders:ProviderDetailWithAttestation[] = [];
  public selectedProviderID: string;
  public selectedProvider: ProviderDetailWithAttestation;
  private sortColumn: string;
  private sortAscending: boolean;
  public totalRecords: number;
  public attestationList: AttestationDetail[];
  public searchProviderId: string;
  public searchProviderName: string;
  public showAttestation: boolean;
  public anyAttestationAvailableToSelect: boolean;
  private overAllCheckUnCheckValue: boolean;

  constructor(
    private sessionService: SessionService,
    private reportsService: ReportsService,
    private outhService: OAuthService,
    private datePipe: DatePipe,
    private authenticationService: AuthenticationService,
    private portalConfig: PortalConfig,
    @Inject(DOCUMENT) private document: Document) {
      this.sortColumn = 'name';
      this.sortAscending = true;
      this.searchProviderId = '';
      this.searchProviderName = '';
      this.selectedProviderID = '';
  }

  ngOnInit() {

    var userEmail: string  = "";
    const claims = this.outhService.getIdentityClaims();
      if (claims) {
        userEmail = claims['sub'];
      }

    this.authenticationService.findUsers(null, userEmail, this.portalConfig.ClientId,1, 1).subscribe(users=>{
      if(users != null && users.length == 1) {
        this.userProfile = users[0];
      }

      this.selectedProvider = null;
      this.loadProviders();
    });
  }

  public loadProviders():void {
    if(this.sessionService.currentPermission.isEnterpriseAdmin || this.sessionService.currentPermission.isEnterpriseUser) {
      //  Nothing. 
    }
    else {
      this.reportsService.getProvidersWithAttestationForReports(this.sessionService.currentPermission.providerCollectionID, this.sessionService.currentPermission.collectionName, ReportType.BLS).subscribe(result=>{

        this.providers = result.filter(x=>x.attestationStatus != AttestationStatus.NotAvailable);

        this.totalRecords = this.providers.length;

        if(this.providers)
          this.displayProviders = this.providers.slice();

        let param: any = {
          "field": "name",
          "order": 1
        }
        this.onSort(param)

        this.onProviderSelected(this.selectedProvider);
      });
    }      
  }

  public sortClass(column: string): string {
    let cls: string;
    if (column == this.sortColumn) {
      cls = this.sortAscending ? "fa fa-chevron-up" : "fa fa-chevron-down";
    }
    else {
      cls = "fa fa-chevron-up";
    }
    return cls;
  }

  public onPage(e: any) {
    let s = JSON.stringify(e);
  }

  public onSort(e: any): void {
    this.providers.sort((data1, data2) => {
      let value1 = data1[e.field];
      let value2 = data2[e.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (e.order * result);
    });
    this.sortAscending = e.order == 1;
    this.sortColumn = e.field;
  }

  public onProviderSelected(provider: ProviderDetailWithAttestation): void {
    if(!provider) return;

    this.selectedProviderID = provider.calProviderID;
    this.selectedProvider = provider;

    this.attestationList = [];
    this.anyAttestationAvailableToSelect = false;
    this.overAllCheckUnCheckValue = false;

    this.reportsService.getAttestationListForProvider(this.selectedProviderID, ReportType.BLS).subscribe(result=>{

      result.forEach(item => {
        item.reportPeriodDisplay = this.getReportPeriodDisplay(item.reportPeriod);
        item.measurementPeriodDisplay = this.getMeasurementPeriodDisplay(item.reportPeriod);

        if(item.attestationStatus == AttestationStatus.PastDue || item.attestationStatus == AttestationStatus.Pending)
          this.anyAttestationAvailableToSelect = true;
      });

      this.attestationList = result;
    });
  }

  public get displayText(): string {
    if(this.selectedProviderID) {

      let providerDetail: ProviderDetailWithAttestation = this.providers.find(x=> x.calProviderID === this.selectedProviderID);

      if(providerDetail) {
        let s = providerDetail.name;
        return s;
      }
      else {
        return ""
      }
    }
    else {
      return "";
    }
  }

  public onSearch(search) : void {
    this.selectedProviderID = '';
    this.attestationList = [];

    if (search && (search.providerName || search.providerId)) {

      if(this.sessionService.currentPermission.isEnterpriseAdmin || this.sessionService.currentPermission.isEnterpriseUser) {
          this.reportsService.searchProvidersWithAttestationForReports(search.providerId, search.providerName, search.providerTin, ReportType.BLS).subscribe(result => {

            this.providers = result.filter(x=>x.attestationStatus != AttestationStatus.NotAvailable);

            if(this.providers)
              this.displayProviders = this.providers.slice();
          });
      }
      else {
        if (!UiUtility.isNullUndefinedOrEmpty(search.providerName) && !UiUtility.isNullUndefinedOrEmpty(search.providerId)) {
          this.displayProviders = this.providers
            .filter(p => p.name.toLowerCase().indexOf(search.providerName.trim().toLowerCase()) >= 0 || p.calProviderID.toLowerCase().indexOf(search.providerId.trim().toLowerCase()) >= 0);
        }
        else if (!UiUtility.isNullUndefinedOrEmpty(search.providerName)) {
          this.displayProviders = this.providers
            .filter(p => p.name.toLowerCase().indexOf(search.providerName.trim().toLowerCase()) >= 0);
        }
        else {
          this.displayProviders = this.providers
            .filter(p => p.calProviderID.toLowerCase().indexOf(search.providerId.trim().toLowerCase()) >= 0);
        }
      }
    }
    else {
      this.displayProviders = this.providers.slice();
    }
  }  

  public attestationStatusDisplay(attestationStatus:AttestationStatus): string {
    if(attestationStatus === AttestationStatus.Attested)
        return "Up to date";
    else if(attestationStatus == AttestationStatus.Pending)
        return "Pending";
    else 
        return "Past Due";
  }

  private getReportPeriodDisplay(reportPeriod:string): string {

    var quarter: string = reportPeriod.substring(0,2);
    var year: string = reportPeriod.substring(2,6);
    var month: string = reportPeriod.substring(6,8);

    return month + ' - ' + year;
  }

  private getMeasurementPeriodDisplay(reportPeriod:string): string {

    var quarter: string = reportPeriod.substring(0,2);
    var year: string = reportPeriod.substring(2,6);
    var month: string = reportPeriod.substring(6,8);
    
    var numMonth = Number(month);
    var numYear = Number(year);

    if(numMonth == 1) {
      numMonth = 12
      numYear = numYear - 1;
    }
    else {
      numMonth = numMonth - 1;
    }
    
    month = numMonth.toString();
    year = numYear.toString();

    if(month.length == 1)
      month = "0" + month;

    return month + ' - ' + year;
  }

  public onAttest(): void {
    this.showAttestation = true;
  }

  public get enableAttest() : boolean {
    return (this.attestationList && this.attestationList.find(x=>x.hasChecked) != null);
  }

  public onSubmitAttestation(event) {
    var list:AttestationDetail[] = this.attestationList.filter(x=>x.hasChecked);
    list.forEach(attestationDetail => {
      attestationDetail.attestationName  = this.userProfile.userID;
      attestationDetail.attestationEmail = this.userProfile.email;
      attestationDetail.attestationPhone = this.userProfile.mobilePhone;
      attestationDetail.attestationVersion = event;
    });

    this.reportsService.ApplyAttestation(list).subscribe(result=> {
      this.loadProviders();
      this.showAttestation = false;
    });
  }

  public onCancelAttestation() {
    this.showAttestation = false;
  }

  public get userFullName() {
    return this.userProfile.firstName + ' ' + this.userProfile.lastName;
  }

  public get overAllCheckUnCheck() : boolean {
    return this.overAllCheckUnCheckValue;
  }

  public set overAllCheckUnCheck(value:boolean) {

    if(this.attestationList) {
      this.attestationList.forEach(item=>{
        if(item.attestationStatus == AttestationStatus.Pending || item.attestationStatus == AttestationStatus.PastDue) 
          item.hasChecked = value;
      });
    }

    this.overAllCheckUnCheckValue = value;
  }
  
  public get visibleAttest() : boolean {
    return !(this.sessionService.currentPermission.isEnterpriseAdmin || this.sessionService.currentPermission.isEnterpriseUser);
  }
}
