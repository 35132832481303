
export class UserInfo {
  public userName: string;
  public userFullName: string;
  public email: string;
  public phone: string;
  public firstName: string;
  public lastName: string;
  public deptName: string;
  public displayName: string;
  public isActive: boolean;
}
