import { Component, OnInit, Input } from '@angular/core';
import { MemberEligibility } from 'src/app/services/models/member-eligibility';

@Component({
  selector: 'app-member-eligibility-history',
  templateUrl: './member-eligibility-history.component.html',
  styleUrls: ['./member-eligibility-history.component.scss']
})
export class MemberEligibilityHistoryComponent implements OnInit {

  @Input() 
  memberEligibilityList: MemberEligibility[];

  @Input()
  totalHistories: number;

  public today = new Date().toISOString();

 

  constructor() { }

  ngOnInit() {

  }
}
