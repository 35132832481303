<app-user-header></app-user-header>
<div class="unsupport-browser-container">
  <div class="unsupport-browser-content">
    <div class="unsupport-browser-content-header">
      <span>Your browser is not supported</span>
    </div>
    <div class="unsupport-browser-content-message">
      <span>We're sorry! Provider Portal does not support this browser.</span>
      <span>Please use one of the following browsers:</span>
    </div>
    <div class="unsupport-browser-content-browsers">
      <img src="../../../assets/images/icon_edge.png" class="img_edge" width="60px" height="60px" title="Edge">
      <img src="../../../assets/images/icon_chrome.png" class="img_chrome" width="60px" height="60px" title="Chrome">
      <img src="../../../assets/images/icon_firefox.png" class="img_firefox" width="60px" height="60px" title="Firefox">
      <img src="../../../assets/images/icon_safari.png" class="img_safari" width="60px" height="60px" title="Safari">
    </div>
  </div>
</div>
