import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { UiUtility } from '../../utils/ui-utility';
import { TabView } from 'primeng/tabview';
import { ConfirmationService } from 'primeng/api';
import { PcpAttestationProviderComponent } from './pcp-attestation-provider/pcp-attestation-provider.component';
import { PcpAttestationMemberComponent } from './pcp-attestation-member/pcp-attestation-member.component';

@Component({
  selector: 'app-reports-pcp-attestation',
  templateUrl: './reports-pcp-attestation.component.html',
  styleUrls: ['./reports-pcp-attestation.component.scss']
})
export class ReportsPcpAttestationComponent implements OnInit, AfterViewInit {
  public displayConfirmContinue = false;
  public activeindex:number = 0;

  @ViewChild('tabView') tabview: TabView;
  @ViewChild('providerComponent') providerComponent: PcpAttestationProviderComponent;
  @ViewChild('memberComponent') memberComponent: PcpAttestationMemberComponent;

  private tabs: any = [
    { index: 0, id: 'pcpattestation-provider', label: 'Provider', enable: true },
    { index: 1, id: 'pcpattestation-member', label: 'Member', enable: true }
  ];

  constructor(
    private confirmationService: ConfirmationService,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.setPcpReportAttestationTabs();
  }

  public canViewTab(index: number) {
    const currentTab = this.tabs.find((tab: any) => tab.index === index);
    return currentTab ? currentTab.enable : false;
  }

  public getTabName(index: number) {
    const currentTab = this.tabs.find((tab: any) => tab.index === index);
    return currentTab ? currentTab.label : '';
  }

  public getTabIndex(name: string) {
    const currentTab = this.tabs.find((tab: any) => tab.label === name);
    return currentTab ? currentTab.index : 0;
  }

  public onTabChanged(e: any) {
    this.displayConfirmContinue = true;
    this.tabview.activeIndex = e.index === 1 ? 0 : 1;
  }

  public handleChange(selectedIndex:number, e:MouseEvent) {
    const currentIndex = this.tabview.activeIndex;
    e.stopPropagation();
    if (currentIndex == selectedIndex){
      return;
    }
    this.confirmationService.confirm({
      header: 'Are you sure?',
      message: 'Your search results will be cleared.',
      key: 'continueDialog',
      accept: () => {
        this.tabview.activeIndex = selectedIndex;
        if (selectedIndex === this.getTabIndex('Provider')) {
          this.providerComponent.initialize(true);
        }
        else if (selectedIndex === this.getTabIndex('Member')) {
          if (this.memberComponent) {
            this.memberComponent.setProviderId(null);
          }
        }
      },
      reject: () => {
        this.tabview.activeIndex = currentIndex;
      }
    });
  }

  private setPcpReportAttestationTabs() {
    const tabView = this.elementRef.nativeElement.querySelector('div.pcpattestation-tabview-styles  ul.p-tabview-nav');
    UiUtility.SetTabsWidthDynamically(tabView, 0, 250);

    const tabElements = tabView.querySelectorAll('li');
    tabElements.forEach(element => {
      const currentChild = element.firstChild;
      const tabName = (element.querySelector("a") as HTMLAnchorElement).innerText;
      const tabIndex = this.getTabIndex(tabName.trim());

      const newDiv = window.document.createElement("div");
      newDiv.addEventListener('click', this.handleChange.bind(this, tabIndex), false);
      newDiv.style.cursor = 'pointer';
      newDiv.appendChild(currentChild);

      element.firstChild.remove();
      element.appendChild(newDiv);
    });
  }

  public confirmContinue(event) {
    this.displayConfirmContinue = false;
  }
}
