import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '../../../app.constants';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit, AfterViewInit {

  constructor(
    private router: Router,
    private constants: Constants) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.redirect(this.constants.LoginUrl);
    }, 3000);
  }

  ngOnInit() {
  }

  redirect(path) {
    this.router.navigate([path]);
  }
}
