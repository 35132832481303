import { Component, OnInit, OnDestroy, ViewChild, Inject, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { PortalUser, UserStatus } from '../../../services/models/portal-user';
import { CollectionService } from '../../../services/collection.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { CollectionListItem } from '../../../services/models/collection-list-item';
import { UserTypeMap } from '../../../services/models/user-type-map';
import { Constants } from '../../../app.constants';
import { ProviderSetting } from '../../../services/models/provider-setting';
import { OAuthService } from 'angular-oauth2-oidc';
import { DOCUMENT } from '@angular/common';
import { UiUtility } from '../../../utils/ui-utility';
import { SessionService } from '../../../services/session.service';

@Component({
  selector: 'app-collection-verify-user',
  templateUrl: './collection-verify-user.component.html',
  styleUrls: ['./collection-verify-user.component.scss']
})
export class CollectionVerifyUserComponent implements OnInit, OnDestroy {
  public userListSubscription$: Subscription;
  public collectionSubscription$: Subscription;
  public users: PortalUser[];
  public totalUsers: number;
  public columns: any[];
  public isBusy = false;
  public selectedCollection: CollectionListItem;
  public headerClass: string = '';
  public editUser: PortalUser;
  public removeUser: PortalUser;
  public showAccept: boolean = false;
  public verifiedByFullName: string;
  public displayConfirmButton: boolean = false;
  public displaySaveCloseButton: boolean = false;
  public displaySaveChangesAlert: boolean = false;

  private responsiveSubcription$: Subscription;
  private userListVerificationDueDaysSetting:number;
  private userListVerificationWarningDaysSetting:number;
  private providerSettingsSubscription$: Subscription;

  private userListColumns: any = [
    { key: 'spacerStart', sortField: '', header: '', width: '20%', sort: false,  classHeader: 'user-row-header', classContent: 'user-row-content'},
    { key: 'roleIcon', sortField: '', header: '', width: '55px', sort: false, classHeader: 'user-row-header', classContent: 'user-row-content user-row-content-role-icon' },
    { key: 'name', sortField: '', header: 'Name', width: '24%', sort: false, classHeader: 'user-row-header', classContent: 'user-row-content' },
    { key: 'role', sortField: '', header: 'Role', width: '27%', sort: false,  classHeader: 'user-row-header', classContent: 'user-row-content' },
    { key: 'verify', sortField: '', header: 'Verify', width: '33%', sort: false,  classHeader: 'user-row-header', classContent: 'user-row-content' },
    { key: 'remove', sortField: '', header: 'Remove', width: '12%', sort: false,  classHeader: 'user-row-header user-row-header-remove-icon', classContent: 'user-row-content user-row-content-remove-icon'},
    { key: 'spacerEnd', sortField: '', header: '', width: '20%', sort: false,  classHeader: 'user-row-header', classContent: 'user-row-content'}
  ];

  @ViewChild('confirmBtn') confirmBtn: ElementRef;

  constructor(
    private authService: OAuthService,
    private collectionService: CollectionService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private constants: Constants,
    private sessionService: SessionService,
    @Inject(DOCUMENT) private document: Document)
  {
    this.editUser = null;
    this.removeUser = null;
    window.addEventListener('scroll', this.onScroll, true)
  }

  ngOnInit() {
    this.isBusy = true;
    this.collectionSubscription$ = this.collectionService.collectionListItemChanged$.subscribe(
      item => {
        if (!item) {
          // user cannot browse this page without collection
          this.router.navigate(['user-admin/collections/verify']);
          return;
        }

        this.selectedCollection = item;
        this.setHeaderClass();
        this.setVerifiedByLabel();
        this.initCollectionListResultColumns();
        this.isBusy = false;
        this.getProviderSettings();

      },
      error => {
        this.isBusy = false;
      }
    );
  }

  ngOnDestroy() {
    if (this.responsiveSubcription$) {
      this.responsiveSubcription$.unsubscribe();
    }
    if (this.userListSubscription$) {
      this.userListSubscription$.unsubscribe();
    }
    if (this.collectionSubscription$) {
      this.collectionSubscription$.unsubscribe();
    }
    if (this.providerSettingsSubscription$) {
      this.providerSettingsSubscription$.unsubscribe();
    }
    window.removeEventListener('scroll', this.onScroll, true)
  }

  public onVerify($event: any, user: PortalUser) {
    if ($event) {
      user.isVerified = true;
      user.userLastVerified = new Date();
      user.userLastVerifiedBy = this.verifiedByFullName;
    }
    else {
      user.isVerified = false;
      user.userLastVerified = null;
      user.userLastVerifiedBy = null;
    }
    user.hasVerificationChange = true;
    this.setVerifyTooltip();
    this.checkButtonsState();
  }

  public onSave() {
    const verifiedUsers = this.users.filter(u => {
      return u.hasVerificationChange;
    })

    const sub$ = this.collectionService.insertOrUpdateUserProviderCollection(verifiedUsers).subscribe(
      result => {
        if (result && result.length > 0) {
          // we do have inserts then update existing records userProviderCollectionXrefID
          result.forEach(a => {
            const user = this.users.find(u => {
              return (
                  u.userID.toString() === a.userID.toString() &&
                  u.providerCollectionID === a.providerCollectionID &&
                  u.userProviderCollectionXrefID === a.userProviderCollectionXrefID
              )
            });
            if (user) {
              user.userProviderCollectionXrefID = a.userProviderCollectionXrefID;
            }
          });
        }

        this.router.navigate(['user-admin/collections/verify']);
        sub$.unsubscribe();
      },
      error => {
        // log error
      }
    );
  }

  public onConfirm() {
    // save all
    const sub$ = this.collectionService.insertOrUpdateUserProviderCollection(this.users, true).subscribe(
      result => {
        // all records successfully updated then update prov collection record
        this.selectedCollection.lastVerified = new Date();
        const subUpdateProviderCollection$ = this.collectionService.updateProviderCollection(this.selectedCollection).subscribe(
          result=> {
            this.router.navigate(['user-admin/collections/verify']).then(() => {
              window.location.reload();
            });
            subUpdateProviderCollection$.unsubscribe();
          },
          err => {
            // log error
          }
        )
        sub$.unsubscribe();
      },
      error => {
        // log error
      }
    );
  }

  public onRemove(user: PortalUser) {
    this.removeUser = user;
    this.checkButtonsState();
  }

  public onEdit(user: PortalUser) {
    this.editUser = user;
  }

  public onBackToCollections() {
    if (this.users.find(u => u.hasVerificationChange)) {
      this.displaySaveChangesAlert = true;
      return;
    }
    this.router.navigate(['/user-admin/collections/verify'])
  }

  public mapRoleToIcon(user: PortalUser): string {
    if (user.status !== UserStatus.Active && user.status !== UserStatus.Approved) {
      return null;
    }
    return UserTypeMap.mapUserRoleTypeToIcon(user.roleID);
  }

  public mapRoleToLabel(user: PortalUser): string {
    if (user.status !== UserStatus.Active && user.status !== UserStatus.Approved) {
      return null;
    }

    const roleType = UserTypeMap.mapUserRoleTypeToSelectItem(user.roleID);
    return roleType ? roleType.label : '';
  }

  public onUsersUpdated(event): void {
    // handle edit and remove event
    if (event.action === 'Update') {
      const index = this.users.indexOf(this.editUser);
      this.editUser.roleID = event.userRole;
      this.editUser.providerPositionID = event.providerPositionId && this.editUser.providerPositionID !== event.providerPositionId ? event.providerPositionId : this.editUser.providerPositionID;
      this.editUser.providerPositionOther = event.providerPositionOther && this.editUser.providerPositionOther !== event.providerPositionOther ? event.providerPositionOther : this.editUser.providerPositionOther;
      this.users[index] = this.editUser;
    }
    else if (event.action === 'Remove') {
      const index = this.users.indexOf(this.removeUser);
      if (index > -1) {
        this.users.splice(index, 1);
      }
    }

    this.editUser = null;
    this.removeUser = null;
  }

  public onGoBackUpdated(event) {
    if (event === 'save') {
      // leave the page and save
      if (this.displayConfirmButton) {
        this.onConfirm();
      }
      else {
        this.onSave();
      }
    }
    else if (event === 'leave') {
      this.router.navigate(['user-admin/collections/verify']);
    }
    else {
      this.displaySaveChangesAlert = false;
    }
  }

  public setVerifyToolTipDisabled(user: PortalUser) {
    return user.isVerified;
  }

  public getUserLastVerifiedBy(user) {
    return user.isVerified ? 'Verified by ' + user.userLastVerifiedBy : '';
  }

  private onScroll(event) {
    const tooltipEl = this.document.querySelector<HTMLDivElement>('body div.blue-theme.verify-tooltip');
    if (tooltipEl) {
      tooltipEl.style.display = 'none';
    }
  }

  private loadData() {
    if (!this.selectedCollection) {
      return;
    }

    this.isBusy = true;

    this.collectionService.getCollectionDetail(this.selectedCollection.id).subscribe(result=> {

      this.selectedCollection = result;

      this.userListSubscription$ = this.collectionService.getCollectionUsers(this.selectedCollection, 1, 99999).subscribe(
        response => {
          // display only approved/active
          this.users = response.items.filter(u => {
            return u.status === UserStatus.Approved ||  u.status === UserStatus.Active;
          });

          this.users.forEach(u => {
            // reset these fields when refresh
            u.isVerified = u.userLastVerified ? true : false
            u.hasVerificationChange = false;
          })

          this.users.sort(function(a,b){
            return (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1;
          });

          this.totalUsers = this.users.length;
          this.isBusy = false;
          this.checkButtonsState();
          this.setVerifyTooltip();
        },
        error => {
          this.isBusy = false;
        }
      );
    });

  }

  private initCollectionListResultColumns() {
    this.responsiveSubcription$= this.breakpointObserver
      .observe([
        '(max-width: 1350px)',
      ])
      .subscribe((state: BreakpointState) => {
        if (state.breakpoints['(max-width: 1350px)']) {
          this.columns = this.filterColumns(this.userListColumns,   [ 'spacerStart', 'spacerEnd' ])
        }
        else {
          this.columns = this.filterColumns(this.userListColumns, [])
        }
      });
  }

  private filterColumns(columns: any[], removeColumns: any[]) {
    return columns.filter(item => {
      return removeColumns.indexOf(item.key) === -1;
    });
  }

  private setProviderSettingsForPage(settings: ProviderSetting[]) {
    const valueUserListVerificationDueDays = settings.find(s => s.settingName === this.constants.UserListVerificationDueDays);
    if (valueUserListVerificationDueDays) {
      this.userListVerificationDueDaysSetting = +valueUserListVerificationDueDays.settingValue;
    }
    const userListVerificationWarningDays = settings.find(s => s.settingName === this.constants.UserListVerificationWarningDays);
    if (userListVerificationWarningDays) {
      this.userListVerificationWarningDaysSetting = +userListVerificationWarningDays.settingValue;
    }
  }

  private setHeaderClass() {
    const alertCategory = this.collectionService.getCollectionVerificationAlertCategory(
                                                    this.selectedCollection,
                                                    this.userListVerificationDueDaysSetting,
                                                    this.userListVerificationWarningDaysSetting);
    this.headerClass = 'verify-' + alertCategory;
  }

  private setVerifiedByLabel() {
    const claims = this.authService.getIdentityClaims();
    if (!claims) {
      return null;
    }

    this.verifiedByFullName = claims['given_name'] + this.constants.SpaceString + claims['family_name'];
  }

  private checkButtonsState() {
    if (this.users.find(u => u.hasVerificationChange)) {
      this.displaySaveCloseButton = true;
    }
    else {
      this.displaySaveCloseButton = false;
    }

    if (this.users.find(u => u.userLastVerified === null)) {
      this.displayConfirmButton = false;
      setTimeout(() => {
        this.confirmBtn.nativeElement.blur();
      }, 200);
    }
    else {
      this.displayConfirmButton = true;
      this.displaySaveCloseButton = false;
      setTimeout(() => {
        this.confirmBtn.nativeElement.focus();
      }, 200);
    }
  }

  private setVerifyTooltip() {
    const nextUserToVerify = this.findNextUserToVerify();
    if (nextUserToVerify) {
      setTimeout(() => {
        const verifyUserEl = this.document.getElementsByName("verify-" + nextUserToVerify.userID);
        if (verifyUserEl && verifyUserEl[0]) {
          const rootVerifyEl = verifyUserEl[0].parentElement.parentElement.parentElement;
          const event = UiUtility.SendBrowserAgnosticEvent(rootVerifyEl, 'mouseenter');
          rootVerifyEl.dispatchEvent(event);
        }
      }, 50);
    }
  }

  private findNextUserToVerify() {
    let user: PortalUser;
    for(const u of this.users) {
      if (!u.isVerified) {
        user = u;
        break;
      }
    }
    return user;
  }

  private getProviderSettings() {
    this.providerSettingsSubscription$ = this.sessionService.providerSettingsChanged$.subscribe(
    result => {
      const verificationDueDays = result ? result.find(p => p.settingName === this.constants.UserListVerificationDueDays) : null;
      const verificationWarningDays = result ? result.find(p => p.settingName === this.constants.UserListVerificationWarningDays) : null;
      this.userListVerificationDueDaysSetting = verificationDueDays && +verificationDueDays.settingValue > 0 ? verificationDueDays.settingValue : this.constants.UserListVerificationDueDaysDefaultValue;
      this.userListVerificationWarningDaysSetting = verificationWarningDays && +verificationWarningDays.settingValue > 0 ? verificationWarningDays.settingValue : this.constants.UserListVerificationWarningDaysDefaultValue;

      this.loadData();
    },
    error => {
      this.loadData();
    });
  }
}
