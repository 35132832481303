<form class="lookup-container" #claimLookUpForm="ngForm" (ngSubmit)="findClaims(claimLookUpForm)" autocomplete="off" 
  [ngClass]="{'lookup-container-grid': !isAdtType(), 'lookup-container-adt-grid': isAdtType()}">
    <div class="timespan-label">
      <label for="timespan">Date Range</label>
    </div>
    <div class="lookup-timespan">
      <p-dropdown name="drp{{isType}}Timespan" id="drp{{isType}}Timespan" [options]="dateSpan" [(ngModel)]="selectedTimeSpan" (onChange)="onDateSelect()"
        optionLabel="name" dataKey="code">
      </p-dropdown>    
    </div>

    <div class="type-of-date-label">
      <label for="dateFilterType">{{dateFilterLabel}}</label>
    </div>
    <div class="date-filter-type">
      <p-dropdown name="drp{{isType}}DateFilterType" id="drp{{isType}}DateFilterType" [options]="dateTypelist" [(ngModel)]="selectedDateType" (onChange)="selectDateType($event)"
        optionLabel="name" dataKey="code">
      </p-dropdown>
    </div>
    <div class="from-date-label" *ngIf="showCustomDate">
      <label for="fromDate">From</label>
    </div>
    <div class="from-date" *ngIf="showCustomDate && isIEOrEdge">
      <input class="form-control input-date" type="text" name=calFromDate{{isType}} id=calFromDate{{isType}} placeholder="MM/DD/YYYY"
             #fromDateModel="ngModel" [(ngModel)]="startDate" required (change)="onChangeDate($event)"
             minlength="10" maxlength="10" 
             pattern="^((1(0|1|2)|0[1-9])\/((0|1|2)[0-9]|(3)[0,1])\/((19|20)[0-9][0-9]))">
      <div class="text-danger" *ngIf="fromDateModel.touched && fromDateModel.invalid">Please enter a valid date</div>
    </div>
    <div class="from-date" *ngIf="showCustomDate && !isIEOrEdge">
      <input class="form-control input-date" type="Date" name=calFromDate{{isType}} id=calFromDate{{isType}} placeholder="MM/DD/YYYY"
        #fromDateModel="ngModel" [(ngModel)]="startDate" required (change)="onChangeDate($event)"
        min="{{twoYearsAgo | date: 'yyyy-MM-dd'}}" max="{{today | date: 'yyyy-MM-dd'}}"
        pattern="^((0|1)\d{1})\/((0|1|2)[0-9]|(3)[0,1])\/((19|20)\d{2})|^((0|1)\d{1})((0|1|2)\d{1})((19|20)\d{2})|^((19|20)\d{2})-(1(0|1|2)|0[1-9])-((0|1|2)[0-9]|(3)[0,1])|^((19|20)\d{2})(1(0|1|2)|0[1-9])((0|1|2)[0-9]|(3)[0,1])">
    </div>
    <div class="to-date-label" *ngIf="showCustomDate">
      <label for="toDate">To</label>
    </div>
    <div class="to-date" *ngIf="showCustomDate && isIEOrEdge">
      <input class="form-control input-date" type="text" name=calToDate{{isType}} id=calToDate{{isType}} placeholder="MM/DD/YYYY"
        #toDateModel="ngModel" [(ngModel)]="endDate" required (change)="onChangeDate($event)"
        minlength="10" maxlength="10"
        pattern="^((1(0|1|2)|0[1-9])\/((0|1|2)[0-9]|(3)[0,1])\/((19|20)[0-9][0-9]))">
      <div class="text-danger" *ngIf="toDateModel.touched && toDateModel.invalid">Please enter a valid date</div>
    </div>
    <div class="to-date" *ngIf="showCustomDate && !isIEOrEdge">
      <input class="form-control input-date" type="date" name=calToDate{{isType}} id=calToDate{{isType}} placeholder="MM/DD/YYYY"
             #toDateModel="ngModel" [(ngModel)]="endDate" required (change)="onChangeDate($event)"
             min="{{twoYearsAgo | date: 'yyyy-MM-dd'}}" max="{{today | date: 'yyyy-MM-dd'}}"
             pattern="^((0|1)\d{1})\/((0|1|2)[0-9]|(3)[0,1])\/((19|20)\d{2})|^((0|1)\d{1})((0|1|2)\d{1})((19|20)\d{2})|^((19|20)\d{2})-(1(0|1|2)|0[1-9])-((0|1|2)[0-9]|(3)[0,1])|^((19|20)\d{2})(1(0|1|2)|0[1-9])((0|1|2)[0-9]|(3)[0,1])">
    </div>
    <div class="date-range-errormessage">
      <div class="alert alert-danger" *ngIf="showValidDateRangeMessage">
        Select a date no earlier than {{twoYearsAgo | date: 'MM/dd/yyyy'}}
      </div>
    </div>
    <div class="date-prior-errormessage">
      <div class="alert alert-danger" *ngIf="showValidDatePriorMessage">
        From date must precede To date
      </div>
    </div>
    <div class="date-valid-errormessage">
      <div class="alert alert-danger" *ngIf="showValidDateMessage">
        Please enter a valid date range
      </div>
    </div>

    <div class="status-label">
      <label for="status">Status</label>
    </div>
    <div class="status" *ngIf="statusmultiselect">
      <p-multiSelect name=drp{{isType}}Status id=drp{{isType}}Status [options]="statusList" [filter]="false" [showToggleAll]="false" maxSelectedLabels="{{maxSelections}}"
        [(ngModel)]="selectedStatus" selectedItemsLabel="All Statuses ({0})" (onChange)="onMultiSelectChange($event)"
        [style]="{'width':'100%', 'height': '100%', 'border-bottom':'1px solid #e5e5e5'}">
      </p-multiSelect>
    </div>
    <div class="status" *ngIf="!statusmultiselect">
      <p-dropdown name=drp{{isType}}status id=drp{{isType}}status [options]="statusList" [(ngModel)]="selectedSingleStatus">
      </p-dropdown>
    </div>
    <div class="claim-status-errormessage">
      <div class="alert alert-danger" *ngIf="showValidClaimStatusMessage">
        You must select at least one status
      </div>
    </div>
    <div class="additional-status-label" *ngIf="isAdtType()">
      <label for="additional-status">Subtype</label>
    </div>
    <div class="additional-status" *ngIf="isAdtType()">
      <p-multiSelect name="drp{{isType}}Subtype" id="drp{{isType}}Subtype" [style]="{'width':'100%', 'height': '100%', 'border-bottom':'1px soli d #e5e5e5'}"
        [(ngModel)]="selectedAdditionalStatus" [options]="additionalStatusList" selectedItemsLabel="All Subtypes ({0})"
        [filter]="false" [showToggleAll]="false" maxSelectedLabels="{{maxSubtypeSelections}}"
        (onChange)="onMultiSelectAddtionalChange($event)"
        optionLabel="name" dataKey="code">
      </p-multiSelect>
    </div>
    <div class="adt-subtype-errormessage">
      <div class="alert alert-danger" *ngIf="showValidAdditionalStatusMessage">
        Please select at least one Subtype
      </div>
    </div>
    <div class="cin-label">
      <span *ngIf="isCINMandatory">*</span>
      <label for="cin">CIN</label>
    </div>
    <div class="cin-input-enclosure">
      <input class="cin-input form-control" type="text" name=txtbx{{isType}}Cin id=txtbx{{isType}}Cin #cinModel="ngModel"
        [(ngModel)]="memberCin" maxlength="9" pattern="^([0-9]{8}[a-zA-Z]{1})|(((default|DEFAULT)0)[1-3]{1})$"
        (keyup)="clearMessagesCin(this, claimLookUpForm)" [required]="isCINMandatory">
      <span class="text-danger" *ngIf="showInValidCinMessage">Please enter a valid CIN number</span>
    </div>
    <div class="facility-label" *ngIf="isAdtType()">
      <label for="fcl">Facility</label>
    </div>
    <div class="facility-input-enclosure" *ngIf="isAdtType()">
      <input class="facility-input form-control" type="text" name=txtbx{{isType}}Facility id=txtbx{{isType}}Facility #facilityModel="ngModel"
        [(ngModel)]="facilityname" maxlength="100">
      <div class="filter-facility-sub-name">Name or Facility ID</div>
    </div>

    <div class="find-claims-button">
      <button id=btn{{isType}}FindByDate class="btn-claims btn btn-primary" type="submit"
        [disabled]="disableClaimsButton()">
        {{findButtonTitle}}
      </button>
    </div>
    <div class="claims-clear-button">
      <button id=btn{{isType}}ClearByDate class="btn-clear btn btn-secondary-clear" type="button"
        (click)="claimLookUpForm.reset(); clearClaimsSearch();">
        Clear
      </button>
    </div>
</form>
