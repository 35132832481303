

import { Component, OnInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService, LoginOptions } from 'angular-oauth2-oidc';
import { LoggingService, HTTPStatus, AppConfig } from '@caloptima/portal-foundation';
import { AuthHelper } from '@caloptima/authentication';
import { BusyService } from './services/busy.service';
import { SessionService } from './services/session.service';
import { RouterHistoryService } from './services/router-history.service';
import { PortalConfig } from './portal-config';
import { Constants } from './app.constants';
import { PortalNavigationService } from './services/portal-navigation.service';
import { VersionCheckerService } from './services/version-checker.service';
//declare var $: any;

@Component({
  selector: 'app-providerPortal',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterContentChecked {
  title = 'Provider Portal Works!';

  private timer;
  showSessionExpiryDialogue: boolean = false;
  timeLeftForSessionToExpire: any;
  public isBusy: boolean = true;
  public isHttpBusy: boolean = true;
  public showSidebar = true;
  public isLoggedIn = false;
  public displayhBrowserRefresDialog = false;

  constructor(
    private router: Router,
    private routerHistory: RouterHistoryService,
    private oauth: OAuthService,
    private configuration: PortalConfig,
    private config: AppConfig,
    private constants: Constants,
    private authHelper: AuthHelper,
    private logger: LoggingService,
    private httpStatus: HTTPStatus,
    private cdRef: ChangeDetectorRef,
    private busyService: BusyService,
    private sessionService: SessionService,
    private portalNavigationService: PortalNavigationService,
    private versionCheckerService: VersionCheckerService,
    private routerHistoryService: RouterHistoryService
  ) {
    this.httpStatus.getHttpStatus().subscribe((status: boolean) => { this.isHttpBusy = status;});
  }

  private tryLogin(hash: string) {
    if (!this.oauth.hasValidAccessToken() && !this.authHelper.tokenNotExpired()) {
      const options: LoginOptions = {};
      // Check to see if an access token was passed in on the URL.  If it is, it
      // is probably an access token for a CalOptima user through single sign on.
      if (hash != null && hash.length > 13 && hash.substr(0, 13) === '#access_token') {
        const pairs = hash.split('&');
        options.customHashFragment = hash;
        for (var i = 0; i < pairs.length; i++) {
          if (pairs[i].substr(0, 6) === 'state=') {
            // The OAuthService needs the nonce in the session, because it
            // really wants to compare the once against what is in the token.
            sessionStorage.setItem('nonce', pairs[i].substr(6));
          }
        }
      }
      this.oauth.tryLogin(options)
        .then(() => {
          this.sessionService.start();
          this.routerHistoryService.checkUnSupportedBrowser();
        })
        .catch(() => {
          // If we get an error and we still don't have a valid token
          // refresh the page
          if (!this.oauth.hasValidAccessToken()) {
            window.location.reload();
          }
        });
    }
    else {
      this.sessionService.start();
      this.routerHistoryService.checkUnSupportedBrowser();
    }
  }

  ngOnInit() {
    this.sessionService.isSessionTerminated().subscribe(
      result => {
        const hasToken = this.oauth.hasValidAccessToken();
        this.isLoggedIn = !result;
        return result;
    });
    this.portalNavigationService.showSidebar$.subscribe(data => {
      this.showSidebar = data;
    });
    const hash = location.hash;
    let loginUrlLocal = this.constants.LoginUrl;
    if (hash.substr(0, 13) === '#access_token') {
      location.hash = '';
      loginUrlLocal = '';
    }
    try {
      this.cdRef.detectChanges();
      this.config.subscribe((config => {
        this.oauth.configure({
          clientId: this.configuration.ClientId,
          redirectUri: this.configuration.RedirectUri,
          scope: this.constants.Scope,
          issuer: this.configuration.Issuer,
          oidc: this.constants.Oidc,
          loginUrl: loginUrlLocal,
          logoutUrl: this.configuration.LogoutUrl,
        });
        this.tryLogin(hash);
        this.versionCheckerService.initVersionCheck(this.configuration.RedirectUri + 'version.json');
      }));
    } catch (ex) {
      this.logger.logError(ex);
      throw ex;
    }

    this.busyService.isBusy().subscribe(busy => {
      this.isBusy = busy;
    });

    this.versionCheckerService.refreshCacheChanged$.subscribe(
      result => {
        this.displayhBrowserRefresDialog = result;
    });
  }

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }

  public logOut() {
    this.isBusy = true;
    // this.oauth.logOut();
    // sessionStorage.removeItem(this.configuration.SessionStateKey);
    this.sessionService.end();
    window.location.href = this.configuration.SessionTimeOutUrl;
    this.isBusy = false;

  }

  getTimeLeftForSessionToExpire(timeLeftForSessionToExpire: string) {
    this.timeLeftForSessionToExpire = timeLeftForSessionToExpire;
  }

  public get name() {
    const claims = this.oauth.getIdentityClaims();
    if (!claims) {
      return null;
    }

    return claims['given_name'];
  }

  public get expired() {
    return !this.oauth.hasValidAccessToken();
  }

  public onRefreshUpdated(event) {
    this.displayhBrowserRefresDialog = false;
    if (event) {
      window.location.reload();
    }
  }
}
