import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { CodeService } from 'src/app/services/code.service';
import { WellnessDetailsOtherDiagnosis } from 'src/app/services/models/wellness-details';

@Component({
  selector: 'add-diagnosis-dialog',
  templateUrl: './add-diagnosis-dialog.component.html',
  styleUrls: ['./add-diagnosis-dialog.component.scss']
})
export class AddDiagnosisDialogComponent implements OnInit, OnDestroy {
  @Output() onDiagnosisAdded = new EventEmitter<any>();
  @Output() onDiagnosisCancel = new EventEmitter();
  @Input() existingDiagnosticCodes: WellnessDetailsOtherDiagnosis[];

  public diagnosissearchcode: string = "";
  public selectedDiagnosis: any;
  public statusList: SelectItem[] = [];
  public error: boolean = false;
  public existError: boolean = false;

  constructor(private codeService: CodeService
  ) {
  }

  ngOnInit() {
      
  }

  onSearch(){

    this.error = false;
    this.existError = false;
    this.codeService.getDiagnosticsCodes(this.diagnosissearchcode)
      .subscribe(result => {
        this.statusList = [];

        if (result.length > 0) {
          result.forEach(item => {
            this.statusList.push({
              label: item.diagnosisCode + ' - ' + item.diagnosisDesc, value: { id: item.diagnosisCode, name: item.diagnosisDesc, disabled: item.requiresPriorAuth, original: item }
            });
          });
        } else {
          this.error = true;
        }
      })
  }

  ngOnDestroy() {
  }
  
  public get display(): boolean {
    return true;
  }

  public set display(value: boolean) {
    this.onCancel();
  }

  public onCreate(): void {
    if(this.selectedDiagnosis)
    {
      var itemx: any = this.selectedDiagnosis;
      if(this.existingDiagnosticCodes) {
        var item = this.existingDiagnosticCodes.find(x=>x.diagCode.trim().toLowerCase() === itemx.id.trim().toLowerCase())
        if(item)
          this.existError = true;
        else
        this.onDiagnosisAdded.emit(this.selectedDiagnosis);  
      }
      else{
        this.onDiagnosisAdded.emit(this.selectedDiagnosis);
      }
    }      
  }

  onKeyPress(e) {
    let key = e.keyCode;
    if (key === 13) {
      this.onSearch();
    }
  }

  public get canEnableSearch(): boolean {
    return (this.diagnosissearchcode.trim().length > 2);
  }

  public get canEnableAdd(): boolean {
    return (this.statusList.length > 0 && this.selectedDiagnosis != null);
  }
  
  public onCancel(): void {
    this.onDiagnosisCancel.emit();
  }
}
