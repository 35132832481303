import { Injectable, Inject, OnDestroy } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';

import { Observable, config, of } from 'rxjs';
import { switchMap, map, publishReplay, refCount, catchError, mergeMap } from 'rxjs/operators';

import { EmailRequest } from './models/email-request';
import { EmailVerificationParameters } from './models/email-verification-parameters';
import { UserRequest } from './models/user-request';
import { ContentItem } from './models/content-item';
import { VerificationRequest } from './models/verification-request';
import { ProviderDetail } from './models/provider-detail';
import { PagedResponse } from './models/responses/paged-response';
import { ProviderSearchRequest } from './models/requests/provider-search-request';
import { RegisterProviderRequest } from './models/register-provider-request';
import { UserStatus } from './models/portal-user';
import { HttpResponseHelper, AppConfig } from '@caloptima/portal-foundation';
import { FindUsersRequest, UserProfile, AuthenticationService } from '@caloptima/authentication';
import { UserPosition } from './models/user-position';
import { Subscription } from 'rxjs';
import { UiUtility } from '../utils/ui-utility';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })
export class RegistrationService {
  public baseProviderServiceUrl: string;
  private sendEmailUrl: string;
  private sendCompletionEmailUrl: string;
  private sendUserAddedToCollectionEmailUrl: string;
  private verifyCodeUrl: string;
  private getEndUserLicenseUrl: string;
  private acceptEndUserLicenseUrl: string;
  private findProvidersByNameUrl: string;
  private findProvidersByTinUrl: string;
  private findProvidersByGroupUrl: string;
  private findProvidersByGroupTinUrl: string;
  private findUserStatusUrl: string;
  private registerProviderUrl: string;
  private resetPasswordUrl: string;
  private userPositionsUrl: string;
  private config$: Subscription;

  constructor(private http: HttpClient, private configuration: AppConfig, private authService: AuthenticationService) {
    this.baseProviderServiceUrl = configuration.getConfig('BaseProviderServicesApiUrl');
    if (this.baseProviderServiceUrl == null) {
      this.config$ = configuration.subscribe(() => {
        this.baseProviderServiceUrl = configuration.getConfig('BaseProviderServicesApiUrl');
        this.checkUrls();
        this.config$.unsubscribe();
      });
    }
  }

  ngOnDestroy() {
    if (this.config$ != null) {
      this.config$.unsubscribe();
    }
  }
  private checkUrls() {
    if (UiUtility.isNullUndefinedOrEmpty(this.baseProviderServiceUrl) != null) {
      const baseUrl = this.baseProviderServiceUrl + 'api/registration/';
      this.sendEmailUrl = baseUrl + 'sendVerificationEmail';
      this.sendCompletionEmailUrl = baseUrl + 'sendCompletionEmail';
      this.sendUserAddedToCollectionEmailUrl = baseUrl + 'sendUserAddedToCollectionEmail';
      this.verifyCodeUrl = baseUrl + 'verifyCode';
      this.getEndUserLicenseUrl = baseUrl + 'getEndUserLicense';
      this.acceptEndUserLicenseUrl = baseUrl + 'acceptEndUserLicense';
      this.findProvidersByNameUrl = baseUrl + 'findProvidersByName';
      this.findProvidersByTinUrl = baseUrl + 'findProvidersByTin';
      this.findProvidersByGroupUrl = baseUrl + 'findProvidersByGroup';
      this.findProvidersByGroupTinUrl = baseUrl + 'findProvidersByGroupTin';
      this.registerProviderUrl = baseUrl + 'registerProvider';
      this.resetPasswordUrl = baseUrl + 'resetPassword';
      this.findUserStatusUrl = baseUrl + 'findUsers';
      this.userPositionsUrl = baseUrl + 'getUserPositions';
    }
  }

  public generateCode(digits: number = 6): string {
    const minCode = Math.pow(10, digits - 1);
    const codeMultiplier = Math.pow(10, digits);
    let code: number = Math.random() * codeMultiplier;
    // Make sure the code is at least 6 digits.
    while (code < minCode) {
      code = Math.random() * codeMultiplier;
    }
    return Math.floor(code).toString();
  }

  public sendVerificationEmail(recipient: string): Observable<EmailVerificationParameters> {
    this.checkUrls();
    try {
      const request: EmailRequest = new EmailRequest();
      request.recipient = recipient;
      return this.http
        .post<EmailVerificationParameters>(this.sendEmailUrl, request, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    } catch (ex) {
      // log error
      return null;
    }
  }

  public sendCompletionEmail(email: string, name: string): Observable<object> {
    this.checkUrls();
    try {
      const request: EmailRequest = new EmailRequest();
      request.recipient = email;
      request.name = name;
      return this.http
        .post(this.sendCompletionEmailUrl, request, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    } catch (ex) {
      // log error
      return null;
    }
  }

  public sendUserAddedToCollectionEmail(email: string, name: string): Observable<object> {
    this.checkUrls();
    try {
      const request: EmailRequest = new EmailRequest();
      request.recipient = email;
      request.name = name;
      return this.http
        .post(this.sendUserAddedToCollectionEmailUrl, request, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    } catch (ex) {
      // log error
      return null;
    }
  }

  public verifyCode(email: string, code: string): Observable<object> {
    this.checkUrls();
    try {
      const request: VerificationRequest = new VerificationRequest();
      request.email = email;
      request.code = code;
      return this.http
        .post<object>(this.verifyCodeUrl, request, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    } catch (ex) {
      // log error
      return null;
    }
  }

  public getEndUserLicense(email: string, userID: string): Observable<ContentItem> {

    try {
      this.checkUrls();
      const request: UserRequest = new UserRequest();
      request.email = email;
      request.secureAuthUserName = userID;
      //      this.configuration.pipe(
      // this.baseProviderServiceUrl = config.getConfig('BaseProviderServicesApiUrl');
      if (UiUtility.isNullUndefinedOrEmpty(this.baseProviderServiceUrl)) {
        return this.configuration.pipe(
          mergeMap((config) => {
            this.baseProviderServiceUrl = config.getConfig('BaseProviderServicesApiUrl');
            this.checkUrls();
            return this.http.post<ContentItem>(this.getEndUserLicenseUrl, request, httpOptions);
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error)
          ));
      } else {

        return this.http.post<ContentItem>(this.getEndUserLicenseUrl, request, httpOptions).pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error)
          ));
      }
    } catch (ex) {
      // log error
      return null;
    }
  }

  public acceptEndUserLicense(userID: string, eulaVersion: number): Observable<object> {
    this.checkUrls();
    try {
      const request: UserRequest = new UserRequest();
      request.secureAuthUserName = userID;
      request.version = eulaVersion;
      return this.http
        .post<ContentItem>(this.acceptEndUserLicenseUrl, request, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    } catch (ex) {
      // log error
      return null;
    }
  }

  public findProvidersByName(
    firstName: string,
    lastName: string,
    pageNumber?: number,
    pageSize?: number
  ): Observable<PagedResponse<ProviderDetail>> {
    this.checkUrls();
    try {
      const request: ProviderSearchRequest = new ProviderSearchRequest();
      request.lastName = lastName;
      request.firstName = firstName;
      request.pageNumber = pageNumber;
      request.pageSize = pageSize;
      return this.http
        .post<PagedResponse<ProviderDetail>>(
          this.findProvidersByNameUrl,
          request,
          httpOptions
        )
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    } catch (ex) {
      // log error
      return null;
    }
  }

  public findProvidersByTin(
    providerTin: string,
    pageNumber?: number,
    pageSize?: number
  ): Observable<PagedResponse<ProviderDetail>> {
    this.checkUrls();
    try {
      const request: ProviderSearchRequest = new ProviderSearchRequest();
      request.tin = providerTin;
      request.pageNumber = pageNumber;
      request.pageSize = pageSize;
      return this.http
        .post<PagedResponse<ProviderDetail>>(
          this.findProvidersByTinUrl,
          request,
          httpOptions
        )
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    } catch (ex) {
      // log error
      return null;
    }
  }

  public findProvidersByGroup(
    groupName: string,
    pageNumber?: number,
    pageSize?: number
  ): Observable<PagedResponse<ProviderDetail>> {
    this.checkUrls();
    try {
      const request: ProviderSearchRequest = new ProviderSearchRequest();
      request.groupName = groupName;
      request.pageNumber = pageNumber;
      request.pageSize = pageSize;
      return this.http
        .post<PagedResponse<ProviderDetail>>(
          this.findProvidersByGroupUrl,
          request,
          httpOptions
        )
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    } catch (ex) {
      // log error
      return null;
    }
  }

  public findProvidersByGroupTin(
    groupTin: string,
    pageNumber?: number,
    pageSize?: number
  ): Observable<PagedResponse<ProviderDetail>> {
    this.checkUrls();
    try {
      const request: ProviderSearchRequest = new ProviderSearchRequest();
      request.tin = groupTin;
      request.pageNumber = pageNumber;
      request.pageSize = pageSize;
      return this.http
        .post<PagedResponse<ProviderDetail>>(
          this.findProvidersByGroupTinUrl,
          request,
          httpOptions
        )
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    } catch (ex) {
      // log error
      return null;
    }
  }

  public registerProvider(
    userName: string,
    calProviderID: string,
    collectionID: number,
    providerAddressType: string,
    providerPositionID: number,
    providerPositionOther: string
  ): Observable<object> {
    this.checkUrls();
    try {
      const request: RegisterProviderRequest = new RegisterProviderRequest();
      request.userName = userName;
      request.calProviderID = calProviderID;
      request.collectionID = collectionID;
      request.providerAddressType = providerAddressType;
      request.providerPositionID = providerPositionID;
      request.providerPositionOther = providerPositionOther;

      return this.http
        .post<object>(
          this.registerProviderUrl,
          request,
          httpOptions
        )
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    } catch (ex) {
      // log error
      return null;
    }
  }

  public resetPassword(recipient: string): Observable<EmailVerificationParameters> {
    this.checkUrls();
    try {
      const request: EmailRequest = new EmailRequest();
      request.recipient = recipient;
      return this.http
        .post<EmailVerificationParameters>(this.resetPasswordUrl, request, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    } catch (ex) {
      // log error
      return null;
    }
  }

  public getSetupPasswordUrl(profile: UserProfile) {
    this.checkUrls();
    const basePath = this.configuration.getConfig('RegionRedirectUri');
    const fullpath = basePath + '#/user/setupPassword?user=' + profile.userID;
    return fullpath;
  }

  public findUserStatusResetPassword(email: string): Observable<UserProfile> {
    this.checkUrls();
    const findUserRequest: FindUsersRequest = new FindUsersRequest();
    findUserRequest.email = email;
    findUserRequest.pageNumber = 1;
    findUserRequest.pageSize = 1;
    findUserRequest.clientId = this.configuration.getConfig('ClientId');
    return this.authService.findUserAndResetLock(null, email, this.configuration.getConfig('ClientId'), 1, 1)
      .pipe(
        switchMap(
          (res: any) => {
            if (res && res.length > 0) {
              const status = res[0];
              return of(status);
            }
            return of(null);
          }
        )
      );
  }

  public findUserStatus(email: string): Observable<UserProfile> {
    this.checkUrls();
    const findUserRequest: FindUsersRequest = new FindUsersRequest();
    findUserRequest.email = email;
    findUserRequest.pageNumber = 1;
    findUserRequest.pageSize = 1;
    findUserRequest.clientId = this.configuration.getConfig('ClientId');
    return this.authService.findUsers(null, email, this.configuration.getConfig('ClientId'), 1, 1)
      .pipe(
        switchMap(
          (res: any) => {
            if (res && res.length > 0) {
              const status = res[0];
              return of(status);
            }
            return of(null);
          }
        )
      );
  }

  public getUserPositions(positionCode: string = null): Observable<UserPosition[]> {
    try {
      this.checkUrls();
      return this.http.post<UserPosition[]>(this.userPositionsUrl + '?positionCode=', null, httpOptions)
        .pipe(
          map(data => {
            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }
}
