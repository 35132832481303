import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CollectionService } from '../../../services/collection.service';
import { CollectionListItem } from '../../../services/models/collection-list-item';
import { Messages } from '../../../app.messages';
import { isNgContent } from '@angular/compiler';

@Component({
  selector: 'rename-collection-dialog',
  templateUrl: './rename-collection-dialog.component.html',
  styleUrls: ['./rename-collection-dialog.component.scss']
})
export class RenameCollectionDialogComponent implements OnInit {
  @Output() collectionRename = new EventEmitter();
  @Output() cancelRename = new EventEmitter();
  public collectionName: string;
  public validationError:boolean;
  public validationMessage:string;

  constructor(
    private collectionService: CollectionService,
    private messageService: MessageService,
    private messages: Messages
  ) { }

  ngOnInit() {
    if (this.collectionService != null && this.collectionService.selectedCollectionItem != null)
      this.collectionName = this.collectionService.selectedCollectionItem.name;

      this.validationError = false;
      this.validationMessage = "";
  }

  public get display(): boolean {
    return true;
  }

  public set display(value: boolean) {
    this.onCancel();
  }

  public onInput(event: any) {
    this.validationError = false;
    this.validationMessage = "";
  }

  public onRename() {
    let item: CollectionListItem = this.collectionService.selectedCollectionItem;
    let oldCollectionName = item.name;
    item.name = this.collectionName;
    this.collectionService.validateCollectionRename(item).subscribe(
      message => {
        
        if (message != null) {
          item.name = oldCollectionName;
          this.messageService.add({ severity: this.messages.SeverityError, detail: message, life: this.messages.ToasterLife});
          this.collectionService.setCurrentCollection(null);
        }
        else {
          this.collectionService.setCurrentCollection(item);

          this.collectionService.updateProviderCollection(item).subscribe(
            next => {
              this.collectionRename.emit();
            },
            error => {
              item.name = oldCollectionName;
              this.validationError = true;
              this.validationMessage = "Unable to rename the collection.";
            }
          )
        }
      },
      error => {
        item.name = oldCollectionName;
        this.validationError = true;
        this.validationMessage = "Collection name already exists.";
      }
    );
  }

  public onCancel() {
    this.cancelRename.emit();
  }
}
