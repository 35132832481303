
import { Component, OnInit, Input, Output, TemplateRef, EventEmitter, AfterViewInit, ViewChild, Inject, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { RegistrationState } from '../../services/models/registration-state';
import { RegistrationService } from '../../services/registration-service';
import { ProviderDetail } from '../../services/models/provider-detail';
import { ProviderType } from '../../services/models/provider-type';
import { PagedResponse } from '../../services/models/responses/paged-response';
import { Messages } from '../../app.messages';
import { UiUtility } from '../../utils/ui-utility';
import { NgForm } from '@angular/forms';
import { BreakpointState, BreakpointObserver } from '@angular/cdk/layout';
import { Tab } from '../../services/models/tab';
import { DOCUMENT } from '@angular/common';
import { LazyLoadEvent } from 'primeng/api';

@Component({
  selector: 'provider-search',
  templateUrl: './provider-search.component.html',
  styleUrls: ['./provider-search.component.scss']
})
export class ProviderSearchComponent implements OnInit, OnDestroy, AfterViewInit {
  public date: Date = new Date();
  public firstNameInput: string;
  public lastNameInput: string;
  public practitionerTINInput: string;
  public groupNameInput: string;
  public groupTINInput: string;
  public totalResults: number;
  public pageCount: number;
  public pageResult: ProviderDetail[] = [];
  public pageResultAll: ProviderDetail[] = [];
  public selectedProvider: ProviderDetail;
  public searchResults: PagedResponse<ProviderDetail>;
  public validationError: string = null;
  public maxResults: number;
  public tabIndex: number = 0;
  public practitionerSearchSelected: string = "0";
  public groupSearchSelected: string = "2";
  public optionPractitionerSearchByName: any;
  public optionPractitionerSearchByTIN: any;
  public optionGroupSearchByName: any;
  public optionGroupSearchByTIN: any;

  public rowsPerPage: number = 10;
  public pageNumber: number = 1;
  private responsiveSubcription$: Subscription;
  private currentTab: Tab;

  public userSearched: boolean = false;

  private tabs: Tab[] = [
    { index: 0, id: 'practitioner-search', label: 'Practitioner Search', enable: true },
    { index: 1, id: 'group-search', label: 'Group / Facility Search', enable: true }
  ];
  private searchOptions: any = [
    { id: '0', name: 'PractitionerName', label: 'Full Name', enable: true, description: 'Search provider by practitioner full name' },
    { id: '1', name: 'PractitionerTIN', label: 'Tax Identification Number', enable: false, description: 'Search provider by practitioner TIN' },
    { id: '2', name: 'GroupName', label: 'Group / Facility Name', enable: false, description: 'Search provider by group name' },
    { id: '3', name: 'GroupTIN', label: 'Tax Identification Number', enable: false, description: 'Search provider by group TIN' }
  ];

  @Input('registrationState') registrationState: RegistrationState;
  @Input() public footerTemplate: TemplateRef<any>;
  @Input() public componentUsing: string;
  @Output() providerSelected = new EventEmitter<ProviderDetail>();
  @Output() providerSearchUpdated = new EventEmitter<boolean>();

  @Output() next = new EventEmitter();
  @Output() busy = new EventEmitter();
  @ViewChild('practionerForm') practionerForm: NgForm;
  @ViewChild('groupForm') groupForm: NgForm;

  constructor(
    private registrationService: RegistrationService,
    public messages: Messages,
    private breakpointObserver: BreakpointObserver,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.pageCount = 0;
  }

  ngOnInit() {
    if (this.registrationState.provider == null) {
      this.clearAll();
      this.setSearchOptions();

      return;
    }
    if (this.registrationState.provider.providerType !== 0) {
      // Change the tabpanel to group if the provider type is not practitioner.
      this.tabIndex = 1;
      this.pageResultAll = [this.registrationState.provider];
      this.selectedProvider = this.registrationState.provider;

      this.setClientPagedData()
    }

    this.setSearchOptions();
    this.practitionerSearchSelected = this.getSearchOptionsSelected(this.registrationState.providerSearchOption).id;
    this.groupSearchSelected = this.getSearchOptionsSelected(this.registrationState.providerSearchOption).id;

  }

  ngOnDestroy(): void {
    if (this.responsiveSubcription$ != null) {
      this.responsiveSubcription$.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.detectWindowResize();
    this.currentTab = UiUtility.getTabByIndex(0, this.tabs);
  }

  private processResponse(result: ProviderDetail[]) {
    if (result && result.length)  {
      for (let provider of result) {
        provider.name = this.formatName(provider);
        provider.addressFull = this.formatAddress(provider.address);
      }
    }
    this.onPage(1);
    this.busy.emit(false);
  }

  private processError(err) {
    this.busy.emit(false);
    this.validationError = this.messages.ProviderSearchError + err;
  }

  public onSelectProvider(provider: ProviderDetail) {
    this.selectedProvider = provider;
    this.registrationState.providerSearchOption = this.searchOptions.find(o => o.enable).name;
    this.providerSelected.emit(provider);
  }

  public lazyLoadData(event: LazyLoadEvent) {
    if (event.first !== undefined) {
      this.setClientPagedData();
    }
  }

  private formatAddress(address: any) {
    if (address == null) {
      return '';
    }
    let zipCode = address.zipCode;
    if (zipCode && zipCode.length > 5) {
      zipCode = zipCode.substr(0, 5) + '-' + zipCode.substr(5)
    } else {
      zipCode = '';
    }
    let formattedAddress: string = address.address1;
    if (formattedAddress && address.address2 != null && address.address2 != '') {
      formattedAddress = formattedAddress + ', ' + address.address2;
    }
    return formattedAddress + ', ' + address.city + ', ' + address.state + ' ' + zipCode;
  }

  private formatName(provider: ProviderDetail) {
    let name: string = provider.name;
    if (provider.providerType === ProviderType.Practicioner) {
      if (provider.groupName != null && provider.groupName !== '') {
        name = name + ' / ' + provider.groupName;
      }
    }
    return name;
  }

  public getProviderIcon(providerType: ProviderType) {
    let icon: string;
    switch (providerType) {
      case ProviderType.Practicioner:
        icon = 'user-icon';
        break;
      case ProviderType.Group:
        icon = 'user-group-icon';
        break;
      case ProviderType.Facility:
        icon = 'building-icon';
        break;
      default:
        icon = 'disabled-circle-icon';
        break;
    }
    return icon;
  }

  public searchByPractitioner() {
    this.userSearched = false;
    this.setValidationErrors();

    if (!this.validationError) {
      this.validationError = null;
      this.busy.emit(true);
      if (this.optionPractitionerSearchByName.enable) {
        this.registrationService.findProvidersByName(this.firstNameInput, this.lastNameInput, 1, this.maxResults).subscribe(
          response => {
            if (response) {
              this.pageResultAll = response.items;
              this.processResponse(this.pageResultAll);
            }
          },
          err => {
            this.processError(err);
          },
          () => { this.userSearched = true;}
        )
      } else {
        this.registrationService.findProvidersByTin(this.practitionerTINInput, 1, this.maxResults).subscribe(
          response => {
            if (response) {
              this.pageResultAll = response.items;
              this.processResponse(this.pageResultAll);
            }
          },
          err => {
            this.processError(err);
          },
          () => { this.userSearched = true;}
        )
      }
    }
  }

  public searchByGroup() {
    this.userSearched = false;
    this.setValidationErrors();

    if (!this.validationError) {
      this.validationError = null;
      this.busy.emit(true);

      if (this.optionGroupSearchByName.enable) {
        this.registrationService.findProvidersByGroup(this.groupNameInput, 1, this.maxResults).subscribe(
          response => {
            if (response) {
              this.pageResultAll = response.items;
              this.processResponse(this.pageResultAll);
            }
          },
          err => {
            this.processError(err);
          },
          () => { this.userSearched = true;}
        )
      } else {
        this.registrationService.findProvidersByGroupTin(this.groupTINInput, 1, this.maxResults).subscribe(
          response => {
            if (response) {
              this.pageResultAll = response.items;
              this.processResponse(this.pageResultAll);
            }
          },
          err => {
            this.processError(err);
          },
          () => { this.userSearched = true;}
        )
      }
    }
  }

  public onTabChanged(e, name: string = null) {
    this.currentTab = UiUtility.getTabByName(e ? e.originalEvent.target.innerText : name, this.tabs);
    if (this.currentTab.id === 'practitioner-search') {
      this.optionPractitionerSearchByName.enable = true;
      this.optionPractitionerSearchByTIN.enable = false;
      this.practitionerSearchSelected = this.optionPractitionerSearchByName.id;
      this.setSearchOptionsEnabled(this.optionPractitionerSearchByName.id);
    } else if (this.currentTab.id === 'group-search') {
      this.optionGroupSearchByName.enable = true;
      this.optionGroupSearchByTIN.enable = false;
      this.groupSearchSelected = this.optionGroupSearchByName.id;
      this.setSearchOptionsEnabled(this.optionGroupSearchByName.id);
    }

    this.providerSearchUpdated.emit(true);
    this.resetForm();
  }

  public getTabName(index: number) {
    return UiUtility.getTabByIndex(index, this.tabs).label;
  }

  public onSelectPractitionerSearch(id: number) {
    this.optionPractitionerSearchByName.enable = id === this.optionPractitionerSearchByName.id;
    this.optionPractitionerSearchByTIN.enable = id === this.optionPractitionerSearchByTIN.id;
    this.setSearchOptionsEnabled(id);
    this.providerSearchUpdated.emit(true);
    this.resetForm();
  }

  public onSelectGroupSearch(id: number) {
    this.optionGroupSearchByName.enable = id === this.optionGroupSearchByName.id;
    this.optionGroupSearchByTIN.enable = id === this.optionGroupSearchByTIN.id;
    this.setSearchOptionsEnabled(id);
    this.providerSearchUpdated.emit(true);
    this.resetForm();
  }

  public clearAll() {
    this.userSearched = false;
    this.validationError = null;
    this.firstNameInput = '';
    this.lastNameInput = '';
    this.practitionerTINInput = '';
    this.groupNameInput = '';
    this.groupTINInput = '';
    this.pageCount = 0;
    this.totalResults = 0;
    this.pageResult = null;
    this.searchResults = null;
    this.selectedProvider = null;
    this.registrationState.provider = null;
    this.providerSearchUpdated.emit(true);
  }

  public resetForm() {
    this.clearAll();
    this.clearForms();
  }

  public onPage(page: number) {
    this.pageNumber = page;
    this.selectedProvider = null;
    this.setClientPagedData();
  }

  private setClientPagedData() {
    this.totalResults = this.pageResultAll.length;
    const start = (this.pageNumber - 1) * this.rowsPerPage;
    let end = start + this.rowsPerPage;
    if (end > this.pageResultAll.length) {
      end = this.pageResultAll.length;
    }

    this.pageResult = this.pageResultAll.slice(start, end);
  }

  private detectWindowResize() {
    this.responsiveSubcription$ = this.breakpointObserver
    .observe(['(max-width: 1250px)'])
    .subscribe((state: BreakpointState) => {
      const tabView = window.document.querySelector('div.provider-search-tabview  ul.p-tabview-nav');
      if (state.breakpoints['(max-width: 1250px)'] || this.componentUsing === 'my-profile-addcollection') {
        UiUtility.SetTabsWidthDynamically(tabView, 45);
      } else {
        UiUtility.SetTabsWidthDynamically(tabView, 30);
      }
    });
  }

  private clearForms() {
    setTimeout(() => {
      if (this.practionerForm.invalid) {
        this.practionerForm.form.markAsUntouched();
        this.practionerForm.form.markAsPristine();
      }
      if (this.groupForm.invalid) {
        this.groupForm.form.markAsUntouched();
        this.groupForm.form.markAsPristine();
      }
    }, 25)
  }

  private setSearchOptions() {
    if (this.registrationState.providerSearchOption &&  this.registrationState.providerSearchOption !== '') {
      this.searchOptions.forEach(o => {
        o.enable = o.name === this.registrationState.providerSearchOption
      })
    }
    this.optionPractitionerSearchByName = this.getSearchOption('PractitionerName');
    this.optionPractitionerSearchByTIN = this.getSearchOption('PractitionerTIN');
    this.optionGroupSearchByName = this.getSearchOption('GroupName');
    this.optionGroupSearchByTIN = this.getSearchOption('GroupTIN');
  }

  private getSearchOption(name: string) {
    const option = this.searchOptions.find(o => o.name === name);
    return option;
  }

  private setValidationErrors() {
    this.validationError = null;
    this.setActiveTab();

    if (this.currentTab.id.toLowerCase() === 'practitioner-search') {
      if (this.optionPractitionerSearchByName.enable) {
        if (this.firstNameInput == null || this.firstNameInput == '' || this.lastNameInput == null || this.lastNameInput == '') {
          this.validationError = this.messages.ProviderNameError;
        }
      } else {
        // by TIN
        if (this.practitionerTINInput == null || this.practitionerTINInput === '') {
          this.validationError = this.messages.ProviderTINError;
        }
      }
    } else if (this.currentTab.id.toLowerCase() === 'group-search') {
      if (this.optionGroupSearchByName.enable) {
        if (this.groupNameInput == null || this.groupNameInput === '') {
          this.validationError = this.messages.ProviderGroupNameError;
        }
      } else {
        // by TIN
        if (this.groupTINInput == null || this.groupTINInput === '') {
          this.validationError = this.messages.ProviderTINError;
        }
      }
    }
  }

  private setActiveTab() {                                                                  
    const tabEl = this.document.querySelector<HTMLSpanElement>('div.provider-search-input .p-tabview-nav li.p-highlight > a > span') as HTMLSpanElement;
    if (tabEl) {
      this.currentTab = UiUtility.getTabByName(tabEl.innerText, this.tabs);
    }
  }

  private setSearchOptionsEnabled(id: number) {
    this.searchOptions.forEach(o => {
      o.enable = o.id === id;
    });
  }

  private getSearchOptionsSelected(name: string) {
    return name ? this.searchOptions.find(o => o.name === name) : this.optionPractitionerSearchByName;
  }

  public onNext() {
    this.next.emit();
  }
}
