import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { PagerClasses } from '../../services/models/pager-classes';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnChanges {
  @Input() public totalRecords: number;
  @Input() public resultLabel: string = "results";
  @Input() public rowsPerPage: number = 10;
  @Input() public pageNumber: number = 1;
  @Output() public onPage = new EventEmitter<number>();
  private _pageCount: number = null;
  public cssClasses = PagerClasses.cssClasses;

  constructor() {
  }

  ngOnInit() {
  }

  // TODO: do we need this section? if we do, use different type instead of SimpleChanges
  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes["totalRecords"] && changes["totalRecords"]["currentValue"] != null) {
      this._pageCount = Math.ceil(this.totalRecords / this.rowsPerPage);
    }
  }

  public gotoPage(page: number) {
    this.onPage.emit(page);
    this.pageNumber = page;
  }

  public get pageCount(): number {
    return this._pageCount;
  }
}
