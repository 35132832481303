
import { Component, OnInit, OnDestroy } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';

import { AuthenticationService, AuthenticationFactor, AuthenticationServiceStatus, AuthHelper } from '@caloptima/authentication';
import { Constants } from '../../app.constants';
import { PortalConfig } from '../../portal-config';
import { Messages } from '../../app.messages';
import { SessionService } from '../../services/session.service';
import { UserService } from '../../services/user.service';
import { Subscription, timer, Observable } from 'rxjs';
import { AuthenticationFactorChoice } from '../../services/models/authentication-factor-choice.model';
import { LoginErrorModel, ErrorStatusModel } from '../../services/models/login.model';
import { VerifyIdentityModel } from '../../services/models/verify-identity.model';
import { ServiceUtility } from '../../services/service-utility';

@Component({
  selector: 'app-login',
  styleUrls: ['login.component.scss'],
  templateUrl: './login.component.html'
})
export class LogInComponent implements OnInit, OnDestroy {
  private selectedFactor: AuthenticationFactor;
  public answerQuestions: boolean;
  private requiresPasscode: boolean;
  public isBusy: boolean;
  public userName: string;
  public password: string;
  public rememberThisDevice = false;
  public passcode: string;
  public choices: AuthenticationFactorChoice[];
  public answers: string[];
  public choiceValue: string;
  public questions: AuthenticationFactor[];
  public passwordMissing: boolean;
  public userNameMissing: boolean;
  public emailSubscription$: Subscription;
  private minutesRemaining: number;
  private timer: Observable<number>;
  public codeExpired: boolean;
  public expirationTimeRemaining: string;
  private timerSubscription: Subscription;
  public codeValid: boolean = false;
  public loginError:LoginErrorModel;
  public errorStatus:ErrorStatusModel;
  public selectedFactorSubscription$: Subscription;
  public answerQuestionsSubscription$: Subscription;
  public passcodeSubscription$: Subscription;
  private backToLoginStatusCodes: number[] = [10, 412];
  public verifyIdentityModel: VerifyIdentityModel = new VerifyIdentityModel();

  constructor(private authService: AuthenticationService,
              private authHelper: AuthHelper,
              private oauth: OAuthService,
              private router: Router,
              private constants: Constants,
              private configuration: PortalConfig,
              public messages: Messages,
              private session: SessionService,
              private oAuthService: OAuthService,
              private userService: UserService,
              private sessionService: SessionService) {
  }

  ngOnInit() {

    this.clearLoginErrorMessage();
    this.verifyIdentityModel.TwoFactorOptionsDescription = 'To protect your account, we need to verify your identity. You can receive a temporary code or answer security questions below.';
    this.emailSubscription$ = this.userService.emailSubject$.subscribe(
      email => {this.userName = email; }
    );

    this.selectedFactorSubscription$ = this.userService.selectedFactorSubject$.subscribe(
      selectedFactor => {this.selectedFactor = selectedFactor; }
    );

    this.answerQuestionsSubscription$ = this.userService.answerQuestionsSubject$.subscribe(
      answerQuestions => {this.answerQuestions = answerQuestions; }
    );

    this.passcodeSubscription$ = this.userService.selectedFactorPasscodeSubject$.subscribe(
      passcode => {
        this.passcode = passcode;
      }
    );
  }

  ngOnDestroy() {
    if (this.emailSubscription$) {
      this.userService.clearEmail();
    }
    if (this.selectedFactorSubscription$) {
      this.userService.clearSelectedFactor();
      this.selectedFactorSubscription$.unsubscribe();
    }
    if (this.answerQuestionsSubscription$) {
      this.userService.clearAnswerQuestion();
      this.answerQuestionsSubscription$.unsubscribe();
    }
    if (this.passcodeSubscription$) {
      this.passcodeSubscription$.unsubscribe();
    }
    if (this.timerSubscription != null) {
      this.timerSubscription.unsubscribe();
    }
  }

  private getToken() {
    this.oauth.createAndSaveNonce().then(nonce => {
      const scope = this.constants.Scope.split(' ');
      const getToken$ = this.authService.getToken(this.configuration.ClientId, this.configuration.RedirectUri, scope, nonce, false)
        .subscribe((resp) => {
          const loginSub$ = this.authHelper.oauthLogin(resp)
            .subscribe(() => {
              this.session.start();
              this.questions = null;
              this.choices = null;
              if (loginSub$ != null) {
                loginSub$.unsubscribe();
              }
            },
              (err) => {
                // log error
              },
              () => {
              });
          if (getToken$ != null) {
            getToken$.unsubscribe();
          }
        });
    });
  }

  public setLoginErrorMessage(statusCode: number = null, statusMessage: string = null){
    this.errorStatus = new ErrorStatusModel(statusCode, statusMessage);
    this.userService.setErrorMessage(this.errorStatus);
    if(this.backToLoginStatusCodes.indexOf(statusCode)>-1){
      this.backToLogin();
    }
  }

  public clearLoginErrorMessage(){
    this.errorStatus = null;
    this.userService.clearErrorMessage();
  }

  public DoLogin() {
    this.clearLoginErrorMessage();
    this.userNameMissing = this.userName == null || this.userName === '';
    this.passwordMissing = this.password == null || this.password === '';

    if (!this.userNameMissing && !this.passwordMissing && !this.isBusy) {
      try {
        this.isBusy = true;
        const doesRememberThisDeviceExists = localStorage.getItem(this.configuration.LoginLocalName + btoa(this.userName)) ? true : false;
        this.setLoginLocalName();
        const sub = this.authService.loginUser(
          this.userName,
          this.password,
          this.configuration.ClientId,
          this.rememberThisDevice).subscribe({
          next: (res) => {
            if (res.requiresTwoFactor || !doesRememberThisDeviceExists) {
              this.getFactors();
            } else {
              this.getToken();
            }
            if (sub != null) {
              sub.unsubscribe();
            }
          },
          error: (err) => {
            if (err.status && err.status === 400 && err.error.Message === 'User is deactivated by Caloptima Admin') {
              this.setLoginErrorMessage(11);
            }
            else if (err.status && err.status === 400 && err.error.Message === 'User is being reviewed by CalOptima')
            {
              this.setLoginErrorMessage(12);
            }
            else if(err.status && err.status === 400 && err.error.Message === 'User is in pending state')
            {
              this.setLoginErrorMessage(14);  // 14 - Account not found.
            }
            else if(err.status && err.status === 401)
            {
              this.setLoginErrorMessage(14);  //  14 - Account not found.
            }
            else
            {
              // .net 4.5.2 doesn't support Locked statuscode (423)
              // using ExpectationFailed (417)
              this.setLoginErrorMessage(err.status, err.error);
            }
            this.isBusy = false;
          },
          complete: () => {
          }
        });
      } catch (ex) {
        this.setLoginErrorMessage(ex.status, ex.error);
        this.isBusy = false;
      }
    }
  }

  public emailHandler() {
    this.setRememberThisDevice();
  }

  private getFactors() {
    this.isBusy = true;

    this.authService.getFactors().subscribe({
      next: (res) => {
        if (res == null) {
          this.getToken();
        } else {

          this.choices = [];
          this.questions = [];
          this.answers = [];

          this.isBusy = false;
          for (let i = 0; i < res.length; i++) {
            if (res[i].factorType != 'Question') {

              this.choices.push(new AuthenticationFactorChoice(
                res[i].factorType,
                res[i].factorID,
                res[i].value,
                ServiceUtility.getFactorsChoiceDislaySequence(res[i].factorType)));
            } else {
              this.questions.push(res[i]);
              this.answers.push(null);
            }
          }
          this.choices.sort((a, b) => {
            return a.sequence - b.sequence;
          });
        }
      },
      error: (err) => {
        this.isBusy = false;
        this.setLoginErrorMessage();
      },
      complete: () => {
      }
    });
  }

  public canShowPasscode(): boolean {
    return this.selectedFactor != null && this.requiresPasscode;
  }

  public clearFactors(): void {
    this.choices = null;
    this.questions = null;
  }

  public hasFactors(): boolean {
    return this.choices != null || this.questions != null;
  }

  public DoFactors() {
    this.isBusy = true;
    const responses: AuthenticationFactor[] = [];
    if (this.selectedFactor != null) {
      responses.push({ factorType: this.selectedFactor.factorType, factorID: this.selectedFactor.factorID, value: this.passcode });
    } else if (this.answerQuestions) {
      for (let i = 0; i < this.questions.length; i++) {
        responses.push({ factorType: 'Question', factorID: this.questions[i].factorID, value: this.answers[i] });
      }
    }
    this.clearLoginErrorMessage();
    const sub = this.authService.authenticateFactors(responses).subscribe((resp) => {
      this.clearLoginErrorMessage();

      if (resp.status == AuthenticationServiceStatus.NoError) {
        this.getToken();
      } else if (resp.status == AuthenticationServiceStatus.OnetimePasscodeRequired) {
        this.userService.clearPasscode();
        this.requiresPasscode = true;
      } else if (resp.status == AuthenticationServiceStatus.NotallFactorsAuthenticated) {
        this.setLoginErrorMessage(resp.status)
        this.userService.clearPasscode();
      }
      this.isBusy = false;
      this.startTimer();
      if (sub != null) {
        sub.unsubscribe();
      }
    }, (error) => {
      this.isBusy = false;
      this.userService.clearPasscode();
      window.scroll(0, 0);
      if (error.status && error.status === 400 && error.error ==='One or more factors could not be validated' && this.hasFactors()) {
        this.setLoginErrorMessage(10);
        this.backToLogin();
      }
      this.setLoginErrorMessage(error.status, error.error);
    });
  }

  public reSendCode() {
    this.getFactors();
    this.DoFactors();
  }

  public backToVerification() {
    this.userService.clearSelectedFactor();
    this.requiresPasscode = false;
    this.answerQuestions = false;
    this.clearLoginErrorMessage();
    this.codeExpired=false;
    if(this.timerSubscription){
      this.timerSubscription.unsubscribe();
    }
    this.getFactors();
  }

  private backToLogin(){
    this.clearFactors();
    this.userService.clearSelectedFactor();
    this.userService.clearAnswerQuestion();
    this.requiresPasscode = false;
    this.password=null;
  }

  private setRememberThisDevice() {
    const loginLocalname: string = window.localStorage.getItem(this.configuration.LoginLocalName + btoa(this.userName));
    this.rememberThisDevice = loginLocalname && atob(loginLocalname) === this.userName ? true : false;
  }

  private setLoginLocalName() {
    if (this.rememberThisDevice) {
      localStorage.setItem(this.configuration.LoginLocalName + btoa(this.userName), btoa(this.userName));
    } else {
      localStorage.removeItem(this.configuration.LoginLocalName + btoa(this.userName));
    }
  }

  startTimer() {
    if (this.timerSubscription != null) {
      this.timerSubscription.unsubscribe();
    }
    this.minutesRemaining = 15;
    this.expirationTimeRemaining = this.minutesRemaining + ':00' + this.messages.MinutesLabel;
    var secondsRemaining = this.minutesRemaining * 60;
    this.timerSubscription = timer(1000, 1000).subscribe(t => {
      if (--secondsRemaining > 0) {
        this.codeExpired = false;
        var label = secondsRemaining < 60 ? this.messages.SecondsLabel : this.messages.MinutesLabel;
        var secondsPart = (secondsRemaining % 60).toString();
        if (secondsPart.length == 1) {
          secondsPart = '0' + secondsPart;
        }
        this.expirationTimeRemaining = Math.floor(secondsRemaining / 60) + ':' + secondsPart + label
      }
      else {
        this.timerSubscription.unsubscribe();
        this.setLoginErrorMessage(10);
      }
    });
  }


}
