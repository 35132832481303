import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import moment from 'moment';

import { HttpResponseHelper, AppConfig } from '@caloptima/portal-foundation';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, BehaviorSubject, Subject, of, pipe } from 'rxjs';
import { Member } from './models/member';
import { PortalConfig } from '../portal-config';
import { MemberInfoRequest } from './models/requests/member-info-request';
import { distinctUntilChanged, switchMap, map, catchError } from 'rxjs/operators';
import { MemberRequest } from './models/member-request';
import { MemberEligibility } from './models/member-eligibility';
import { MemberReferral } from './models/member-referral';
import { Medication } from './models/medication';
import { Claim } from './models/claim';
import { Lab } from './models/lab';
import { Condition } from './models/condition';
import { SessionService } from '../services/session.service';
import { RosterItem } from './models/roster-item';
import { memberRosterRequest, providerMemberRosterUpdateRequest } from './models/requests/provider-member-roster-request';
import { RosterType } from './models/request-status';
import { ProviderMemberRoster } from './models/provider-member-roster';
import { PortalMember } from './models/portal-resource';
import { ProviderDetail } from './models/provider-detail';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})

export class MemberRosterService {
  private baseProviderMemberServiceUrl: string;
  private getRosterItemsUrl: string;
  private addMemberToProviderRosterUrl: string;
  private removeMemberFromProviderRosterUrl: string;
  private updateMemberProviderRosterUrl: string;
  private getProviderMemberRosterUrl: string;
  private getMemberRosterUrl: string;
  private isInternalUser: boolean;
  private currentUser: string;

  constructor(
    private authService: OAuthService,
    private sessionService: SessionService,
    private http: HttpClient,
    private portalConfig: PortalConfig,
    private appConfig: AppConfig,
    private datePipe: DatePipe) {

    this.baseProviderMemberServiceUrl = appConfig.getConfig('BaseProviderServicesApiUrl');
    if (this.baseProviderMemberServiceUrl == null) {
      let config$ = appConfig.subscribe(() => {
        this.baseProviderMemberServiceUrl = appConfig.getConfig('BaseProviderServicesApiUrl');
        this.checkUrls();
        config$.unsubscribe();
      });
    }

    this.isInternalUser = this.sessionService.isEnterpriseAdmin || this.sessionService.isEnterpriseUser;
    const claims = this.authService.getIdentityClaims();
    if (claims) {
      this.currentUser = claims['sub'];
    }

  }

  private checkUrls(): void {
    if (this.getRosterItemsUrl == null) {
      const baseUrl = this.baseProviderMemberServiceUrl + 'api/provider/';
      this.getRosterItemsUrl = baseUrl + 'getRosterItems';
      this.addMemberToProviderRosterUrl = baseUrl + 'addMemberToProviderRoster';
      this.removeMemberFromProviderRosterUrl = baseUrl + 'removeMemberFromProviderRoster';
      this.updateMemberProviderRosterUrl = baseUrl + 'updateMemberProviderRoster';
      this.getProviderMemberRosterUrl = baseUrl + 'getProviderMemberRoster';
      this.getMemberRosterUrl = baseUrl + 'getMemberRoster';
    }
  }

  public getRosterItems(): Observable<RosterItem[]> {
    try {
      this.checkUrls();
      return this.http.get<RosterItem[]>(this.getRosterItemsUrl, httpOptions)
        .pipe(
          map(res => {
            return res;
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public addMemberToProviderRoster(memberCin: string, rosterType: RosterType, relationshipDate: Date, providers: string[]): Observable<number> {
    try {
      
      this.checkUrls();

      const request = new memberRosterRequest();
      request.userName = this.currentUser;
      request.memberCin = memberCin;
      request.providerIds = providers;
      request.rosterType = rosterType;
      request.relationshipDate = relationshipDate;

      return this.http.post<number>(this.addMemberToProviderRosterUrl, request, httpOptions)
        .pipe(
          map(res => {
            return res;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public removeMemberFromProviderRoster(memberCin: string, rosterType: RosterType, relationshipDate: Date, providers: string[]): Observable<number> {
    try {

      this.checkUrls();

      const request = new memberRosterRequest();
      request.userName = this.currentUser;
      request.memberCin = memberCin;
      request.providerIds = providers;
      request.rosterType = rosterType;
      request.relationshipDate = relationshipDate;

      return this.http.post<number>(this.removeMemberFromProviderRosterUrl, request, httpOptions)
        .pipe(
          map(res => {
            return res;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public updateMemberProviderRoster(memberCin: string, rosterType: RosterType, relationshipDate: Date, addedProviders: string[], removedProviders: string[]): Observable<number> {
    try {

      this.checkUrls();

      const request = new providerMemberRosterUpdateRequest();
      request.userName = this.currentUser;
      request.memberCin = memberCin;
      request.addedProviderIds = addedProviders;
      request.removedProviderIds = removedProviders;
      request.rosterType = rosterType;
      request.relationshipDate = relationshipDate;

      return this.http.post<number>(this.updateMemberProviderRosterUrl, request, httpOptions)
        .pipe(
          map(res => {
            return res;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public getMemberRoster(memberCin: string[]): Observable<ProviderMemberRoster[]> {

    this.checkUrls();

    return this.http.post<ProviderMemberRoster[]>(this.getMemberRosterUrl, memberCin, httpOptions)
    .pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return HttpResponseHelper.handleError(error);
      })
    );
  }

  public getProviderMemberRoster(calProviderId: string, rosterType: RosterType): Observable<PortalMember[]> {
    
    this.checkUrls();

    const request = new memberRosterRequest();
    request.userName = this.currentUser;
    request.providerIds.push(calProviderId);
    request.rosterType = rosterType;

    return this.http.post<PortalMember[]>(this.getProviderMemberRosterUrl, request, httpOptions)
    .pipe(
      map(res => {

        res.forEach(x=>{
          x.memberName = x.firstName + " " + x.lastName;
        })

        return res;
      }),
      catchError(error => {
        return HttpResponseHelper.handleError(error);
      })
    );
  }
}
