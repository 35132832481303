
import { Component, OnInit, Provider, Inject } from '@angular/core';
import { UserProfile } from '@caloptima/authentication';
import { SelectItem } from 'primeng/api';
import { DropDownObject } from '../../services/models/drop-down-object.interface';
import { ReportsService } from '../../services/reports.service';
import { SessionService } from '../../services/session.service';
import { saveAs } from 'file-saver';
import { DatePipe, DOCUMENT } from '@angular/common';
import { ReportType } from '../../services/models/reportype';
import { UiUtility } from '../../utils/ui-utility';

@Component({
  selector: 'app-reports-pay-for-value-manual',
  templateUrl: './reports-pay-for-value-manual.component.html',
  styleUrls: ['./reports-pay-for-value-manual.component.scss']
})
export class ReportsPayForValueManualComponent implements OnInit {

  public userProfile: UserProfile;
  public totalRecords: number;
  public fileList: SelectItem[];
  public selectedFile: DropDownObject;

  constructor(
    private sessionService: SessionService,
    private reportsService: ReportsService,
    private datePipe: DatePipe,
    @Inject(DOCUMENT) private document: Document) {
      this.selectedFile = null;
  }

  ngOnInit() {

    this.onShowPeriod();
  }



  public onShowPeriod(): void {
    this.fileList = [];
    this.selectedFile = null;

    this.reportsService.getReportFilesForProvider("ALL", ReportType.P4V_Manual).subscribe(result=>{
      result.forEach(x=>
        {
          this.fileList.push(
            {
              label: this.datePipe.transform(x.yearMonth, 'yyyy'),
              value: x
            });
        });

      if(this.fileList.length > 0) {
        this.selectedFile = this.fileList[0].value;
        UiUtility.scrollDown(this.document);
      }
    });
  }

  public get enableBuildReport() : boolean {

    return this.selectedFile !== null
  }

  public onBuildReport() : void {

    var temp:any = this.selectedFile;
    var fileName = temp.fileName;

    this.reportsService.downloadReportFileForProvider("ALL", fileName, ReportType.P4V_Manual).subscribe(result=>{
      this.saveAsFile(result, fileName + ".pdf", 'pdf');
    });
  }

  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob([buffer], { type: fileType });
    saveAs(data, fileName);
  }
}
