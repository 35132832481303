import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MemberComponent } from './member/member.component';
import { ClearMemberDataGuard } from './guards/clear-member-data.guard';

const memberRoutes: Routes = [
  {
    path: 'search',
    component: MemberComponent,
    canDeactivate: [ClearMemberDataGuard],
    data: {label: 'Member'}
  }
];


export const routedComponents = [
  MemberComponent
];

@NgModule({
  imports: [RouterModule.forChild(memberRoutes)],
  exports: [RouterModule]
})
export class MemberLookupRoutingModule { }

