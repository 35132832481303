<div class="control-row" *ngIf="collectionItem != null && session.isEnterpriseAdmin">
    <div class="collection-type">       
        <select class="form-control" name="collectionType" id="ddlCollectionType" title="Collection Type" [(ngModel)]="collectionType" #collectionTypeList="ngModel" (change)="onCollectionTypeChanged()">
            <option value="4" selected>TIN</option>
            <option value="5">Group</option>
            <!--<option value="3">Manual</option>-->
        </select>   
    </div>
    <button title="Add user" class="btn btn-primary btn-add-user" (click)="onFindManual()" *ngIf="(collectionType == 3 || collectionType == 4 || collectionType == 5)">
        <i aria-hidden="true" class="fa fa-plus"></i> 
        Add 
    </button>
    <div class="collection-save" *ngIf="collectionType == 3 || collectionType == 4 || collectionType == 5">
        <button type="button" class="form-control btn btn-primary" (click)="onSaveCollection()" [disabled]="!collectionModified || !collectionValid" >Save</button>
    </div>
</div>
<div class="grid-options">
    <div *ngIf="collectionType == 4" class="search-options">
        <div class="custom-radio">
            <p-radioButton name="AllTinProviders" id="tin_all"  value="1" label="All TINs" [(ngModel)]="tinView"></p-radioButton>
        </div>
        <div class="custom-radio">
            <p-radioButton name="AllTinProviders" id="tin_providers"  value="2" label="Associated Providers" [(ngModel)]="tinView"></p-radioButton>
        </div>     
        <app-collection-detail-providers-search *ngIf="DisplaySearchProviderView" (onCollectionProviderSearch)="onCollectionProviderSearch($event)" ></app-collection-detail-providers-search>
    </div>
</div>
<div class="grid-row" *ngIf="collectionType == 4 && tinView == 1">
    All TINs
    <p-table 
        #tinList 
        [value]="displaytins" 
        [paginator]="false" 
        [resizableColumns]="true"
        selectionMode="single"
        [(selection)]="selectedTin"
        [lazy]="true"
        (onLazyLoad)="onSortTin($event)">
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="providerTaxName" width="50%" pResizableColumn>Name<div class="{{sortClassTin('providerTaxName')}}"></div></th>
                <th pSortableColumn="providerTaxId" width="50%" pResizableColumn>TIN<div class="{{sortClassTin('providerTaxId')}}"></div></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
            <tr [pSelectableRow]="row">
                <td>
                    <div class="provider-info">
                        {{row.providerTaxName}}
                    </div>
                </td>
                <td>
                    <div class="provider-id-column">
                        <div class="provider-id">
                            {{row.providerTaxId}}
                        </div>
                        <div class="provider-remove" *ngIf='canRemoveTinGroup && addEnabled'>
                            <a (click)="onRemoveTin(row)">Remove</a>
                        </div>
                    </div>
                </td>                                
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <app-paginator [totalRecords]="totalTins" resultLabel="TINs" [pageNumber]="tinPageNumber" [rowsPerPage]="rowsPerPage" (onPage)="onTinPage($event)" ></app-paginator>
        </ng-template>
    </p-table>
    <div><p>&nbsp;</p></div>
</div>
<div class="grid-options">
    <div *ngIf="collectionType == 5" class="search-options">
        <div class="custom-radio">
            <p-radioButton name="AllGroupProviders" id="group_all"  value="1" label="All Groups" [(ngModel)]="groupView"></p-radioButton>
        </div>
        <div class="custom-radio">
            <p-radioButton name="AllGroupProviders" id="group_providers"  value="2" label="Associated Providers" [(ngModel)]="groupView"></p-radioButton>
        </div>
        <app-collection-detail-providers-search (onCollectionProviderSearch)="onCollectionProviderSearch($event)" *ngIf="DisplaySearchProviderView"></app-collection-detail-providers-search>
    </div>
</div>
<div class="grid-row" *ngIf="collectionType == 5 && groupView == 1">
    All Groups
    <p-table #groupList [value]="displaygroups" [paginator]="false" [resizableColumns]="true"
             selectionMode="single" [(selection)]="selectedGroup" [lazy]="true" (onLazyLoad)="onSortGroup($event)">
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="groupName" width="50%" pResizableColumn>Group Name<div class="{{sortClassGroup('groupName')}}"></div></th>
                <th pSortableColumn="groupId" width="50%" pResizableColumn>Group ID<div class="{{sortClassGroup('groupId')}}"></div></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
            <tr [pSelectableRow]="row">
                <td>
                    <div class="provider-info">
                        {{row.providerGroupName}}
                    </div>
                </td>
                <td>
                    <div class="provider-id-column">
                        <div class="provider-id">
                            {{row.providerGroupId}}
                        </div>
                        <div class="provider-remove" *ngIf='canRemoveTinGroup'>
                            <a (click)="onRemoveGroup(row)">Remove</a>
                        </div>
                    </div>
                </td>                
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <app-paginator [totalRecords]="totalGroups" resultLabel="Groups" [pageNumber]="groupPageNumber" [rowsPerPage]="rowsPerPage" (onPage)="onGroupPage($event)" ></app-paginator>
        </ng-template>
    </p-table>
    <div><p>&nbsp;</p></div>
</div>
<div class="grid-row" *ngIf="canDisplayProvider">
    All providers
    <p-table #providerList [value]="displayproviders" [paginator]="false" [resizableColumns]="true"
             selectionMode="single" [(selection)]="selectedProvider" [lazy]="true" (onLazyLoad)="onSort($event)">
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="name" width="33%" pResizableColumn>Name<div class="{{sortClassProvider('name')}}"></div></th>
                <th pSortableColumn="taxId" width="16%" pResizableColumn>TIN<div class="{{sortClassProvider('taxId')}}"></div></th>
                <th pSortableColumn="groupId" width="16%" pResizableColumn>Group ID<div class="{{sortClassProvider('groupId')}}"></div></th>
                <th pSortableColumn="calProviderID" width="33%" pResizableColumn>Provider ID<div class="{{sortClassProvider('calProviderID')}}"></div></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
            <tr [pSelectableRow]="row">
                <td>
                    <div class="provider-info">
                        <i id="id-{{rowIndex}}" class="fa fa-info-circle" (click)="showProviderInfoHandler($event, row)"></i>
                        {{row.name}}
                    </div>
                </td>
                <td>
                    <div class="provider-info">
                        {{row.taxId}}
                    </div>
                </td>
                <td>
                    <div class="provider-info">
                        {{row.groupId}}
                    </div>
                </td>
                <td>
                    <div class="provider-id-column">
                        <div class="provider-id">
                            {{row.calProviderID}}
                        </div>
                        <div class="provider-remove" *ngIf="collectionType == 3 && !isLastProvider">
                            <a (click)="onRemoveProvider(row)">Remove</a>
                        </div>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <app-paginator [totalRecords]="totalProviders" resultLabel="provider(s)" [pageNumber]="providerPageNumber" [rowsPerPage]="rowsPerPage" (onPage)="onPage($event)" ></app-paginator>
        </ng-template>
    </p-table>
</div>
<add-provider-dialog #addProviders 
    [collectionTypeInput]='collectionType' 
    [collectionId]='collectionId' 
    (addProviders)="onAddProviders($event)" 
    (addTins)="onAddTins($event)" 
    (addGroups)="onAddGroups($event)">
</add-provider-dialog>
<app-provider-information-dialog 
    (displayChange)="onDialogClose($event)" 
    [display]="showProviderInfo" 
    [providerInfo]="selectedProvider">
</app-provider-information-dialog>
