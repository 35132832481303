import { Component, OnInit, Output, EventEmitter, Input, TemplateRef, OnDestroy } from '@angular/core';
import { AuthenticationFactorChoice } from '../../services/models/authentication-factor-choice.model';
import { AuthenticationService, AuthenticationFactor } from '@caloptima/authentication';
import { ErrorStatusModel } from '../../services/models/login.model';
import { Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';
import { VerifyIdentityModel } from '../../services/models/verify-identity.model';

@Component({
  selector: 'verify-identity',
  templateUrl: './verify-identity.component.html',
  styleUrls: ['./verify-identity.component.scss']
})
export class VerifyIdentityComponent implements OnInit, OnDestroy {
  isBusy: boolean = false;
  private selectedFactor: AuthenticationFactor = null;
  public answerQuestions: boolean;
  public verifyFormStyle: any;

  @Input() footerTemplate: TemplateRef<any>;
  @Input() questions: AuthenticationFactor[];
  @Input() answers: string[];
  @Input() choices: AuthenticationFactorChoice[];
  @Input() errorStatus: ErrorStatusModel;
  @Input() verifyIdentityModel: VerifyIdentityModel = new VerifyIdentityModel();
  @Input() componentUsing: string;
  @Output() secondFactorComplete = new EventEmitter();

  public answerQuestionsSubscription$: Subscription;
  public errorSubscription$: Subscription;

  constructor(private authService: AuthenticationService, private userService: UserService) { }

  ngOnInit() {
    this.answerQuestionsSubscription$ = this.userService.answerQuestionsSubject$.subscribe(
      answerQuestions => {this.answerQuestions = answerQuestions; }
    );

    this.errorSubscription$ = this.userService.errorMessageSubject$.subscribe(
      errorObject => {this.errorStatus = errorObject; }
    );
    this.verifyFormStyle = (this.componentUsing === 'profile-change-password' || this.componentUsing === 'profile-security-question') ? { 'width': '100%' } : { 'width': '32%' };
  }

  ngOnDestroy() {
    if (this.answerQuestionsSubscription$) {
      this.userService.clearAnswerQuestion();
    }
  }

  emitDoFactors() {
    this.secondFactorComplete.emit();
  }

  public onSelectChoice(factor: AuthenticationFactor) {
    this.selectedFactor = factor;
    this.userService.setSelectedFactor(this.selectedFactor);
    this.answerQuestions = false;
  }

  public onAnswerQuestions() {
    this.userService.setAnswerQuestion();
    this.selectedFactor = null;
    this.userService.clearSelectedFactor();
  }

  public hasChoice(): boolean {
    return this.answerQuestions || this.selectedFactor != null;
  }
}
