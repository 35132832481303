import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ProviderDetail } from '../../../services/models/provider-detail';
import { PhonePipe } from '@caloptima/portal-ui-components';

@Component({
  selector: 'app-provider-information-dialog',
  templateUrl: './provider-information-dialog.component.html',
  styleUrls: ['./provider-information-dialog.component.scss']
})

export class ProviderInformationDialogComponent implements OnInit {
  // public showDialog = false;
  @Input() providerInfo: ProviderDetail;
  @Input() display: boolean = false;
  @Output() displayChange = new EventEmitter();
  @Input() appendId: string;
  public id: string;
  constructor() { }

  ngOnInit() {
  }


  onClose() {
    this.displayChange.emit(this.providerInfo);
  }

  // Work against memory leak if component is destroyed
  ngOnDestroy() {
    this.displayChange.unsubscribe();
  }
}




