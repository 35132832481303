<div class="roster">
    <div class="roster-back">
      <div class="roster-back-btn">
        <button type="button" class="btn btn-primary btn-create" (click)="onBack()">
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
          Back
        </button>  
      </div>
      <div class="roster-back-hdr">
        {{selectedProviderName}}
      </div>      
    </div>
    <div class="roster-type">
      <div class="roster-type-label">
        Roster Type
      </div>
      <div class="roster-type-input">
        <p-dropdown name="rostertypename" id="rostertypeid" [options]="rosterItems" optionLabel="rosterTypeDesc" [(ngModel)]="selectedrosterItem" (onChange)="onChange($event)">
        </p-dropdown>
      </div>
    </div>    
    <div class="roster-search">
        <app-reports-member-search (onMemberSearch)="onMemberSearch($event)" #memberSearch></app-reports-member-search>
    </div>
    <div class="roster-result">
        <p-table
        [value]="displayMembers"
        [paginator]="true"
        [rows]="10"
        [scrollable]="true"
        scrollHeight="400px"
        (onSort)="onSort($event)"
        [styleClass]="'p-datatable-striped p-datatable-lg'"
        >
        <ng-template pTemplate="header">
          <tr>
            <th width="25%" pSortableColumn="memberName">Name<div class="{{sortClass('memberName')}}"></div></th>
            <th width="25%" pSortableColumn="cin">Member ID<div class="{{sortClass('cin')}}"></div></th>
            <th width="25%" pSortableColumn="rosterEffDate">Start Date<div class="{{sortClass('rosterEffDate')}}"></div></th>
            <th width="25%">Action</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr>
            <td width="25%">
              <div>{{row.memberName}}</div>
            </td>
            <td width="25%">
              <div>{{row.cin}}</div>
            </td>           
            <td width="25%">
              <div>{{row.rosterEffDate}}</div>
            </td>             
            <td width="25%">
              <app-reports-roster-menu [tag]="row" [menuItems]="menuItems" (itemclicked)="itemclick($event)"></app-reports-roster-menu>              
            </td>            
          </tr>
        </ng-template>
        </p-table>
    </div>   
    <div class="roster-message">
      <div *ngIf="showSuccess">
        <app-alert-success uniqueID='success' [subjectMessage]="'Success.'" [contentMessage]="successmessage"></app-alert-success>
      </div>        
      <div *ngIf="showError">
        <app-alert-danger uniqueID='fail' [subjectMessage]="'Error.'" [contentMessage]="errormessage"></app-alert-danger>
      </div> 
    </div> 
</div>
<app-reports-roster-member-dialog [member]="selectedMember" *ngIf="showDialog" (onCancel)="cancelMemberDialog()"></app-reports-roster-member-dialog>
<app-choose-date-dialog *ngIf="showDatePickerDialog" [caption]="'Date'" [label]="'Relationship end Date'" (onContinue)="continueDateDialog($event)" (onCancel)="cancelDateDialog()"></app-choose-date-dialog>
