export class BHABAReferralAttestationSearchRequest {
    public providerIds : string[] = [];
    public memberCin: string;
    public referrlId: string;
}

export class BHABAReferralSearchRequest2 {
    public calProviderId: string
    public memberCin: string;
    public referrlId: string;
}
