
export class InternalUserRequest{
  public userName: string;
  public searchText: string;
  public roleFilter: string
  public statusFilter: string;
  public pageNumber: number;
  public pageSize: number;
  public sortBy: string;
  public sortDir: string;
  public SecureAuthUserName: string;
  public ExcludePortalUsers: boolean;
  public ExcludeInactiveUsers: boolean;
}
