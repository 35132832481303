import { TrainingAttestationStatus } from "./training-attestation-status";

export class ProviderTaxDetails {
    public providerTaxId:string;
    public providerTaxName:string;
    public providerCollectionId: number;
    public affiliatedProviderCount: number;
}

export class ProviderTaxNetwork
{
    public providerTaxId: string;
    public networkId: string;
    public networkName: string;
    public affiliationEffDate?: Date;
    public affiliationTermDate?: Date;
    public rowNum: number;
}

export class ProviderNetwork
{
    public networkId: string;
    public networkName: string;
}

export class ProviderTaxDetailsWithTrainingAttestation extends ProviderTaxDetails {
    public trainingAttestationId: string;
    public trainingAttestationStatus: TrainingAttestationStatus;
    public trainingAttestationStatusDisplay: string;
    public trainingDueDate?: Date;
    public attestationDate?: Date;
    public attendees: string;
    public trainingCompleted: string;
}
