export class MemberWithHifDownloadRequest {
    
    constructor(){
        this.providerIds = [];
    }

    public username: string;
    public collectionId: number;
    public memberCin: string;
    public isExternalUser: boolean;
    public providerIds: string[];
    public pageNumber: number;
    public pageSize: number;
}
  