  <form name="challenges" class="container login-verification-form" #factorsForm="ngForm" (ngSubmit)="emitDoFactors()" autocomplete="off" [ngStyle]="verifyFormStyle">
    <div class="align-center controlspacer" data-label="h2" >
      <h1>Verify Your Identity</h1>
    </div>
    <div class="align-center controlspacer">
      {{verifyIdentityModel.TwoFactorOptionsDescription}}
    </div>
    <login-errrors [errorStatus]="errorStatus" *ngIf="errorStatus!==null"></login-errrors>
    <div class="controlspacer">
      <h4>How would you like to verify your identity?</h4>
    </div>
    <div class="choices-container">
      <div *ngFor="let factor of choices">
        <div [ngSwitch]="factor.factorType">
          <div class="custom-control custom-radio controlspacer">
            <input class="custom-control-input" type="radio" name="factorChoice" id="rdoFactor{{factor.factorType}}" (click)="onSelectChoice(factor)" />
            <label *ngSwitchCase="'Call'"  class="custom-control-label" for="rdoFactor{{factor.factorType}}" (click)="onSelectChoice(factor)">Call <strong>{{factor.value}}</strong></label>
            <label *ngSwitchCase="'SMS'" class="custom-control-label" for="rdoFactor{{factor.factorType}}" (click)="onSelectChoice(factor)">Text <strong>{{factor.value}}</strong></label>
            <label *ngSwitchCase="'Email'" class="custom-control-label"  for="rdoFactor{{factor.factorType}}" (click)="onSelectChoice(factor)">Email <strong>{{factor.value}}</strong></label>
          </div>
        </div>
      </div>
      <div *ngIf="questions != null">
        <div class="custom-control custom-radio controlspacer">
          <input class="custom-control-input" type="radio" name="factorChoice" id="rdoFactorChoice"
            (click)="onAnswerQuestions()" />
          <label class="custom-control-label" for="rdoFactorChoice">Answer Security Questions</label>
        </div>
        <div class="questions-container" *ngIf="answerQuestions">
          <div *ngFor="let question of questions; let i = index;">
            <div class="custom-control">
              <p>{{question.value}}</p>
            </div>
            <div class="custom-control">
              <input type="text" name="{{question.factorID}}" [(ngModel)]="answers[i]" autocomplete="off" required
                class="form-control" appAutofocus [isAutoFocused]="i == 0 ? 1 : 0" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="footerTemplate==null" class="align-center controlspacer">
      <button class="btn btn-primary-registration btn-send" type="submit" id="btnContinueFactors"
        [disabled]="!factorsForm.form.valid || !hasChoice()">
        <span>CONTINUE</span>
      </button>
    </div>
    <div *ngIf="footerTemplate==null && componentUsing !== 'profile-change-password' && componentUsing !== 'profile-security-question'" class="align-center">
      <a href="/">Cancel</a>
    </div>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </form>
