<ng-template #footer>
    <div class="row">
      <div class="col-md-12">
        <button type="submit" class="btn btn-primary-registration next-button-first" id="btNext" tabindex="2"
          [disabled]="isInvalid()">Next</button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <a class="" href="/#/login">Cancel</a>
      </div>
    </div>
</ng-template>

<div class="change-password">
    <div class="auto-margins" *ngIf="stepNumber === 1">
      <div class="container wizard">
        <form id="frmResetPasswordemail" (ngSubmit)="onNext()" #emailForm="ngForm" autocomplete="off"
          class="forgot-formpage">
          <div class="row">
            <div class="col-md-12 text-center">
              <h1 *ngIf="!isReset">Forgot Password?</h1>
              <h1 *ngIf="isReset">Reset Password</h1>
              <h5>Enter your CalOptima Health Provider account email address.</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="alert-message alert-danger" *ngIf="notRegistered">
                <i class="fa fa-ban"></i>
                No account exists for that email address.
              </div>
              <div class="alert-message alert-warning" *ngIf="notSetup">
                <i class="fa fa-exclamation-triangle"></i>
                Cannot reset password. Please contact (714) 246-8600.
              </div>
              <div class="alert-message alert-success" *ngIf="isApproved">
                <i class="fa fa-check-circle"></i>
                Your account is approved and is ready to be setup.
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="txtEmail"> Email address</label>
            <input type="email" placeholder="Email Address" class="form-control" name="txtEmail" id="txtEmail"
              #txtEmailAddress="ngModel" [(ngModel)]="emailAddress" (ngModelChange)="onEmailChange()" required
              autocomplete="off" [disabled]="isApproved" />
            <div class="alert-error" *ngIf="txtEmailAddress.touched && txtEmailAddress.invalid">Please enter a valid
              email.</div>
          </div>

          <div class="form-group">
            <button class="btn btn-primary-registration next-button-first" type="submit" id="btnNext"
              [disabled]="isInvalid()" *ngIf="!isApproved">NEXT</button>
            <button class="btn btn-primary-registration setup-button" (click)="onSetup()" id="btnNext"
              *ngIf="isApproved">SET UP ACCOUNT</button>
          </div>

          <div class="row" *ngIf="componentUsing === 'forgot-password'">
            <div class="col-md-12 text-center">
              <a class="" href="/#/login">Back to Login</a>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="verify-identity">
        <verify-identity *ngIf="stepNumber == 2"
          (secondFactorComplete)="DoFactors()"
          [questions]="null"
          [verifyIdentityModel]="verifyIdentityModel"
          [choices]="choices"
          [answers]=""
          [errorStatus]="errorStatus">
        </verify-identity>

        <verify-identity-passcode *ngIf="stepNumber == 3"
          (secondFactorPassCodeEntered)="DoFactors()"
          (validPasscode)="validPasscode($event)"
          [expirationTimeRemaining]="expirationTimeRemaining"
          [errorStatus]="errorStatus"
          [codeExpired]="codeExpired"
          (resendCodeEmitted)="reSendCode()"
          (backToVerificationEmitted)="backToVerification()">
        </verify-identity-passcode>
    </div>

    <div class="create-password auto-margins">
      <create-password *ngIf="stepNumber === 4"
        [footerTemplate]="save"
        [passwordSet]="passwordSet"
        [isSameAsOldPassword]="isSameAsOldPassword"
        [userName]="userProfile.email"
        [componentUsing]="componentUsing"
        (valid)="onPasswordValid($event)"
        (passwordChange)="onPasswordChange($event)">
        <ng-template #save>
          <div class="row">
            <div class="col-md-12 col-12">
              <div class="pull-right">
                <button type="submit" class="next-button btn btn-primary-registration" id="btNext" tabindex="2"
                  [disabled]="isInvalid()" (click)="onSave()">Save</button>
              </div>
            </div>
          </div>
        </ng-template>
      </create-password>
    </div>
  </div>
