import { Component, OnInit, Input } from '@angular/core';
import { MemberEligibility } from '../../../../services/models/member-eligibility';
import { MemberStatusMap } from '../../../../services/models/member-status-map';
import moment from 'moment';
import { MemberInfoService } from '../../../../services/member-info.service';


@Component({
  selector: 'app-member-details-eligibility',
  templateUrl: './member-details-eligibility.component.html',
  styleUrls: ['./member-details-eligibility.component.scss']
})
export class MemberDetailsEligibilityComponent implements OnInit {

  @Input()
  memberEligibility: MemberEligibility;

  @Input()
  memberEligibilityList: MemberEligibility[];

  @Input()
  totalHistories: number;

  eligibilityDateRange: string;

  constructor(private memberInfoService: MemberInfoService) { }

  public display: boolean = false;

  ngOnInit() {
    this.eligibilityDateRange = this.getDateRange(
      this.memberEligibility.healthNetworkPlanEffectiveDate,
      this.memberEligibility.healthNetworkPlanTermDate
    );
  }

  public getEndDate(endDate: Date): string {
    let result = 'Current';
    const today = moment(new Date());

    if (today.diff(endDate) > 0) {
      result = this.memberInfoService.getStringDate(endDate, '/');
    }

    return result;
  }

  public getDateRange(startDate: Date, endDate: Date): string {
    let result: string;
    if (!startDate && !endDate) {
      return 'Not Available';
    }
    result = `${this.memberInfoService.getStringDate(startDate, '/')}  -  ${this.getEndDate(endDate)}`;

    return result;
  }

  public showDialog() {
    this.display = true;
  }

  // Get the class(es) to display for the status icon
  public mapCheckStatusToIcon(): string {
    if (this.memberEligibility && this.memberEligibility.eligibilityStatus >= 0) {
      return MemberStatusMap.mapCheckStatustoIcon(this.memberEligibility.eligibilityStatus);
    }

    return '';
  }

  // Get the string to display for the tooltip (title) for the user hovers over the status icon
  public mapCheckStatusToTooltip(): string {
    if (this.memberEligibility && this.memberEligibility.eligibilityStatus >= 0) {
      return MemberStatusMap.mapCheckStatusToSelectItem(this.memberEligibility.eligibilityStatus).label;
    }

    return '';
  }
}
