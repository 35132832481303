import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-copyright',
  standalone: true,
  imports: [],
  templateUrl: './footer-copyright.component.html',
  styleUrl: './footer-copyright.component.scss'
})
export class FooterCopyrightComponent implements OnInit {
  public copyrightYear: string = "2024";
  public copyrightText: string = "";
  ngOnInit() {
    this.copyrightYear = new Date().getFullYear().toString();
    this.copyrightText = `© ${this.copyrightYear} CalOptima Health, A Public Agency - All Rights Reserved`;
  }
}
