import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { CollectionService } from '../../../../services/collection.service';
import { FileUpload } from 'primeng/fileupload';
import { WellnessDetails, WellnessDetails2Extend } from 'src/app/services/models/wellness-details';
import { MemberAWPStatus } from 'src/app/services/models/awp-detail';
import { BusyService } from '@caloptima/portal-foundation';
import { HttpEventType } from '@angular/common/http';
import { FileService } from '../../../../services/file.service';
import { WellnessSearchData } from '../../../../services/models/wellness-attachment';
import { UiUtility } from '../../../../utils/ui-utility';
import { saveAs } from 'file-saver';
import { ReportMemberDetails } from 'src/app/services/models/reportmemberdetails';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'report-wellness-attachment-dialog',
  templateUrl: './report-wellness-attachment-dialog.component.html',
  styleUrls: ['./report-wellness-attachment-dialog.component.scss']
})
export class ReportWellnessAttachmentDialogComponent implements OnInit, OnDestroy {

  public attachments1: any[] = [];
  public attachments2: any[] = [];
  public maxUploadFilesSupported1 = 5;
  public maxUploadFilesSupported2 = 5;
  public hasUploadError1: boolean;
  public hasUploadError2: boolean;
  public currentView = 1;
  private totalFilesSize: number = 100000000;
  private maxFileNameLength = 128;
  public attachmentProgress: number = null;
  public attachmentError: boolean = false;
  public editComment: boolean = true;
  
  @Input() public selectedMemberAWPStatus: MemberAWPStatus;  
  @Input() public selectedMember:ReportMemberDetails;
  @Output() cancelWellness = new EventEmitter();
  @Output() applyWellness = new EventEmitter();
  private wellnessFileList: WellnessSearchData[];
  @ViewChild('fileUpload1') fileUpload1: FileUpload;
  @ViewChild('fileUpload2') fileUpload2: FileUpload;
  
  constructor(
    private collectionService: CollectionService,
    private busyService: BusyService,
    private fileService: FileService,
    private reportService: ReportsService) {
  }

  ngOnInit() {

    this.fileService.getWellnessAttachment(this.selectedMember.cin, this.selectedMember.providerId, this.selectedMember.providerTaxId, this.selectedMemberAWPStatus.period).subscribe(results=>{

      this.wellnessFileList = results;

      results.forEach(x=>{
        let content = "1";
        let data = new Blob([content], { type: 'application/txt' });
        let arrayOfBlob:Blob[] = [];
        arrayOfBlob.push(data);
        let tempFile = new File(arrayOfBlob, x.originalFileName);

        if(x.isWellnessForm)
          this.attachments1.push(tempFile);
        else
          this.attachments2.push(tempFile);
      });

      if(this.selectedMemberAWPStatus && this.selectedMemberAWPStatus.notes && this.selectedMemberAWPStatus.notes.length > 0) {
          this.editComment = false;
      }
      
      if(this.fileUpload1)
        this.fileUpload1.files = this.attachments1;
    });
  }

  ngOnDestroy() {    
  }

  ngAfterViewInit() {
  }

  public get display():boolean {
    return true;
  }

  public get caption(): string {
    if(this.currentView === 1)
      return "Upload AWV Form";
    else
      return "Supporting Documents (optional)";
  }

  public set display(value:boolean) {
    this.onCancel();
  }

  public onCancel() {
    this.cancelWellness.emit();
  }

  public onContinue() {
    if(this.currentView == 1) {

      this.currentView = 2;

      setTimeout(() => {
        if(this.fileUpload2) {
          this.fileUpload2.files = this.attachments2;
        }
      }, 500);
    }
    else {

      var wellnessDetails = new WellnessDetails();
      wellnessDetails.grgr_ck = this.selectedMember.grgr_ck;
      wellnessDetails.memberCIN = this.selectedMember.cin;
      wellnessDetails.pcpId = this.selectedMember.providerId;
      wellnessDetails.providerId = this.selectedMember.providerId;
      wellnessDetails.pcpTIN = this.selectedMember.providerTaxId;
      wellnessDetails.wellnessYear = this.selectedMemberAWPStatus.period;
      wellnessDetails.notes = this.selectedMemberAWPStatus.notes;
      wellnessDetails.documentId = this.selectedMemberAWPStatus.documentId;
      wellnessDetails.completionDate = this.attachments1.length > 0 ? new Date() : null;
      this.selectedMemberAWPStatus.status = this.attachments1.length > 0 ? "Completed" : this.selectedMemberAWPStatus.status;

      this.reportService.ApplyWellness(wellnessDetails).subscribe(results=> {
        var formData:FormData = this.getFormData();
        if(formData){
          this.fileService.uploadWellnessAttachments(formData).subscribe(x=>{
            this.busyService.emit(false);
            this.applyWellness.emit(); 
          },
          (error)=>{
            this.busyService.emit(false);  
          });
        }
        else {
          this.busyService.emit(false);
          this.applyWellness.emit();           
        }
      },
      (error)=>{
        this.busyService.emit(false);
      });
    }
  }

  public get submitText() {
    if(this.currentView === 1) {
      return "Continue";
    }
    else {
      return "Submit";
    } 
  }

  public onSelect1(event) {
    this.hasUploadError1 = this.onSelect(event, this.fileUpload1, this.attachments1, this.maxUploadFilesSupported1);
    this.attachments1 = [...this.fileUpload1.files];
  }

  public onSelect2(event) {
    this.hasUploadError2 = this.onSelect(event, this.fileUpload2, this.attachments1, this.maxUploadFilesSupported2);
    this.attachments2 = [...this.fileUpload2.files];
  }

  public onDownload(filename:string) {
    var temp = this.wellnessFileList.find(x=>x.originalFileName.trim().toUpperCase() == filename.trim().toUpperCase())
    if(temp){
      this.fileService.getWellnessAttachmentFile(temp.wellnessAttachmentID, temp.originalFileName).subscribe(result =>{
        const bytesArray = UiUtility.convertBase64ToArrayBuffer(result.fileStreamData);
        this.saveAsFile(bytesArray, result.originalFileName, result.fileContentType);
      });
    }
  }

  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob([buffer], { type: fileType });
    saveAs(data, fileName);
  }

  public onSelect(event, fileUpload: FileUpload, attachments:any[], maxUploadFilesSupported: number) : boolean {
    let totalSize = 0;
    let filesRemove = [];
    let hasUploadError: boolean = false;
    let validate = false;
    let index = 0;
    let validFiles = 0;
    let removeOnError = false;
    let addedFiles: File[] = [];
    let duplicateAdded: boolean = false;
    let moreThanMaxfiles = false;
    
    let newFiles: File[] = Array.from(event.files);

    for (let file of fileUpload.files) {

      validate = true;
      removeOnError = true;      

      if (this.findDuplicateInCurrentList(file)) {
        fileUpload.msgs.push({ detail: 'Duplicate file', severity: "error", summary: file.name });
        validate = false;
        duplicateAdded = true;
      }

      if (validate && this.findDuplicateInOtherList(file)) {
        fileUpload.msgs.push({ detail: 'Duplicate file', severity: "error", summary: file.name });
        validate = false;
        duplicateAdded = true;
      }

      if(validate && addedFiles.find(x=>this.getFileName(x.name).trim().toUpperCase() === this.getFileName(file.name).trim().toUpperCase())) {
        fileUpload.msgs.push({ detail: 'Duplicate file', severity: "error", summary: file.name });
        validate = false;
        removeOnError = false;
        duplicateAdded = true;
      }

      if (validate && file.name.length > this.maxFileNameLength) {
        fileUpload.msgs.push({ detail: 'File name is too long, cannot exceed ' + this.maxFileNameLength + ' chars', severity: "error", summary: file.name })
        validate = false;
      }

      if (validate && /[>:"?*`[\]\\#&]/g.test(file.name)) {
        fileUpload.msgs.push({ detail: 'File name cannot contain special characters - >:"/\|?*&#', severity: "error", summary: file.name })
        validate = false;
      }      

      if(validate && (totalSize + file.size) > this.totalFilesSize){
        fileUpload.msgs.push({ detail: '', severity: "error", summary: 'Cannot upload files, total size cannot exceed 100 MB' });
        validate = false; 
      }

      if(validate && (validFiles + 1) > maxUploadFilesSupported) {
        if(!moreThanMaxfiles) {
          fileUpload.msgs.push({ detail: '', severity: "error", summary: 'Cannot upload more than ' + maxUploadFilesSupported + ' files' });
        }
                
        validate = false;
        moreThanMaxfiles = true;
      }

      if (validate) {
        totalSize = (totalSize + file.size);
        validFiles++;
        addedFiles.push(file);
      }
      else {
        if(removeOnError){
          filesRemove.push(index);
        }
        
        hasUploadError = true;
      }

      index++;
    }

    for (let i = filesRemove.length - 1; i >= 0; i--) {
      fileUpload.remove(event, filesRemove[i]);
    }    

    if (!hasUploadError && fileUpload.files.length > 0) {
      fileUpload.msgs.push({ detail: '', severity: "success", summary: 'Success!  Your file(s) loaded successfully.' });
    }

    return hasUploadError;
  }

  public onRemove1(event: Event, file: any) {
    const index: number = this.attachments1.indexOf(file);
    this.fileUpload1.remove(event, index);
    this.attachments1 = [...this.fileUpload1.files];
    this.hasUploadError1 = false;
  }

  public onRemove2(event: Event, file: any) {
    const index: number = this.attachments2.indexOf(file);
    this.fileUpload2.remove(event, index);
    this.attachments2 = [...this.fileUpload2.files];
    this.hasUploadError2 = false;
  }

  private findDuplicate(file: any, newFiles: any[]): boolean {

    return Array.from(newFiles).filter((newFile) => {
      let newFileName:string = this.getFileName(newFile.name);
      let fileName:string = this.getFileName(file.name);
      return newFileName.trim().toUpperCase() === fileName.trim().toUpperCase();
    }).length > 0;
  }

  private findDuplicateInOtherList(newFile): boolean {

      let newFileName:string = this.getFileName(newFile.name);

      let attachments = this.currentView == 1 ? this.attachments2 : this.attachments1;

      var item = attachments.find(x=>this.getFileName(x.name).trim().toUpperCase() === newFileName.trim().toUpperCase());
      if(item) {
        return true;
      }
      else{
        return false;
      }
  }

  private findDuplicateInCurrentList(newFile): boolean {

    let newFileName:string = this.getFileName(newFile.name);

    let attachments = this.currentView == 1 ? this.attachments1 : this.attachments2;

    var item = attachments.find(x=>this.getFileName(x.name).trim().toUpperCase() === newFileName.trim().toUpperCase());
    if(item && item != newFile) {      
      return true;
    }
    else{
      return false;
    }
}

  private getFileName(fileName: string): string {
    //return fileName.replace(' ', '+');
    return fileName;
  }

  public fileSize(originalFileName:string): string {

    var item = this.wellnessFileList.find(x=> x.originalFileName.trim().toUpperCase() === originalFileName.trim().toUpperCase());
    if(item) {
      return this.formatSize(item.fileSize);
    }
    else {
      var item1 = this.attachments1.find(x=>x.name.trim().toUpperCase() === originalFileName.trim().toUpperCase());

      if(!item1)
        item1 = this.attachments2.find(x=>x.name.trim().toUpperCase() === originalFileName.trim().toUpperCase());    

      if(item1)
        return this.formatSize(item1.size);
    }

    return "";
  }

  public formatSize(bytes) : string {
    if (bytes == 0) {
        return '0 B';
    }
    var k = 1000,
        dm = 1,
        sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  public fileType(name: string) : string {
    let fileType = name.split('.').pop().toLowerCase();

    if (['jpg', 'jpeg', 'png', 'bmp', 'tif'].indexOf(fileType) > -1) {
        return 'img';
    }
    else if (['doc', 'docx'].indexOf(fileType) > -1) {
        return 'docx';
    }
    else if (['csv', 'xls', 'xlsx'].indexOf(fileType) > -1) {
        return 'csv';
    }
    else if (['htm', 'xps', 'rtf', 'html', 'msg', 'txt'].indexOf(fileType) > -1) {
        return 'file';
    }
    else if (fileType === 'pdf') {
        return 'pdf';
    }
    else {
        return '';
    }
  }

  public doesExists(fileName:string): boolean {
    var temp = this.wellnessFileList.find(x=>this.getFileName(x.originalFileName).trim().toUpperCase() === this.getFileName(fileName).trim().toUpperCase())
    return temp ? true : false;
  }

  public get enableSubmit(): boolean {
    let currentYear: Date = new Date();
    let returnValue:boolean = true;
    
    if(this.currentView == 1) {
      returnValue = this.attachments1.length > 0 || this.attachments2.length > 0
    }
    else if(this.currentView == 2) {
      if(this.selectedMemberAWPStatus && this.selectedMemberAWPStatus.period < currentYear.getFullYear() - 1) {
        returnValue = false;
      }
      else if(this.selectedMemberAWPStatus && this.selectedMemberAWPStatus.period == currentYear.getFullYear() - 1 && currentYear.getMonth() > 0) {
        returnValue = false;
      }
    }

    return returnValue;
  }

  public get enableUpload(): boolean {
    let currentYear: Date = new Date();
    let returnValue:boolean = true;
    
    if(this.selectedMemberAWPStatus && this.selectedMemberAWPStatus.period < currentYear.getFullYear() - 1) {
      returnValue = false;
    }
    else if(this.selectedMemberAWPStatus && this.selectedMemberAWPStatus.period == currentYear.getFullYear() - 1 && currentYear.getMonth() > 0) {
      returnValue = false;
    }
    
    return returnValue;
  }

  public getFormData():FormData {
    this.busyService.emit(true);
    let wellnessFormFiles = '';
    let fileToUpload: File[] = [];
    let wellnessFormAttached: boolean = false;

    this.attachmentError = false;

    this.attachments1.forEach(element => {
      if(!this.doesExists(element.name)) {
        fileToUpload.push(element);
        wellnessFormFiles += this.getFileName(element.name) + "|";
      }
    });    

    this.attachments2.forEach(element => {
      if(!this.doesExists(element.name)) {
        fileToUpload.push(element);
      }
    });
  
    if(fileToUpload.length > 0) {

      const formData = new FormData();
      let totalSize = 0;
      Array.from(fileToUpload)
        .map((file, index) => {
          totalSize += file.size;
          return formData.append('file' + index, file, file.name);
        });
  
      
      formData.append('memberId', this.selectedMember.cin);
      formData.append('providerid', this.selectedMember.providerId);
      formData.append('providertaxid', this.selectedMember.providerTaxId);
      formData.append('wellnessyear', this.selectedMemberAWPStatus.period.toString());

      if(wellnessFormFiles != '')
        formData.append('wellnessFiles', wellnessFormFiles);
      
      return formData;
    }

    return null;
  }  
}
