import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BusyService {
  private busy: boolean;
  private subject: BehaviorSubject<boolean>;

  constructor() {
    this.busy = false;
    this.subject = new BehaviorSubject(false);
  }

  public emit(busy: boolean): void {
    this.busy = busy;
    this.subject.next(busy);
  }

  public isBusy(): Observable<boolean> {
    return this.subject.asObservable();
  }
}
