<p-dataView [value]="memberEligibilityList" [style]="{maxHeight: '550px'}">
  <ng-template let-memberEligibilityList pTemplate="list" >
    <div class="eligibility-history" *ngFor="let history of memberEligibilityList; let first = first">
      <div class="history-icon">
        <img src="./assets/images/building-icon-blue.png" class="hospital-icon">
      </div>
      <div class="history-content">
        <div class="health-network">
          <div class= "health-network-name">
            <h4>{{history.healthNetworkPlanDesc}}</h4>
          </div>
        </div>
        <div class= "health-network-id text-muted">
          HN ID: <span class="health-network-planCode">{{history.healthNetworkPlanCode}}</span>
        </div>
        <div class="eligibility-date">
          <div class="health-date-oneline" *ngIf="history.healthNetworkPlanEffectiveDate != null">Eligibility date: {{history.healthNetworkPlanEffectiveDate| date: 'MM/dd/yyyy'}} -&nbsp; </div>
          <div class="health-date-oneline" *ngIf="history.healthNetworkPlanTermDate > this.today"> Current</div>
          <div class="health-date-oneline" *ngIf="!(history.healthNetworkPlanTermDate > this.today)">{{history.healthNetworkPlanTermDate| date: 'MM/dd/yyyy'}}</div>
        </div>
        <div class= "history-details">
            <!-- pcp container -->
            <div class="pcp-header-id">PCP ID:</div>
            <div class="pcp-header-name">PCP Name:</div>
            <div class="pcp-header-date">PCP Date:</div>
            <div class="pcp-content-id">
              <span>{{history.pcpId}}</span>
            </div>
            <div class="pcp-content-name">
              <span title="{{history.pcpName}}">{{history.pcpName}}</span>
            </div>
            <div class="pcp-content-date">
              <div class= "pcp-date-oneline" *ngIf="history.pcpEffectiveDate != null">{{history.pcpEffectiveDate| date: 'MM/dd/yyyy'}} -&nbsp; </div>
              <div class= "pcp-date-oneline" *ngIf="history.pcpTermDate > this.today"> Current</div>
              <div class= "pcp-date-oneline" *ngIf="!(history.pcpTermDate > this.today)"> {{history.pcpTermDate| date: 'MM/dd/yyyy'}}</div>
            </div>
            <!-- medicaid container -->
            <div class="medicaid-header-id">AID Code:</div>
            <div class="medicaid-header-name">AID Category:</div>
            <div class="medicaid-header-date">AID Code Date:</div>
            <div class="medicaid-content-id">
              <span>{{history.aidCode}}</span>
            </div>
            <div class="medicaid-content-name">
              <span title="{{history.medicaidDesc}}">{{history.medicaidDesc}}</span>
            </div>
            <div class="medicaid-content-date">
              <div class= "aid-date-oneline" *ngIf="history.medicaidEffectiveDate != null">{{history.medicaidEffectiveDate| date: 'MM/dd/yyyy'}} -&nbsp; </div>
              <div class= "aid-date-oneline" *ngIf="history.medicaidTermDate > this.today"> Current</div>
              <div class= "aid-date-oneline" *ngIf="!(history.medicaidTermDate > this.today)"> {{history.medicaidTermDate| date: 'MM/dd/yyyy'}}</div>
            </div>
          </div>
      </div>
    </div>
  </ng-template>
</p-dataView>
<div class="footer-results">
  {{this.totalHistories}} results
</div>
