
<p-dialog [(visible)]="display" [style]="{width: '35rem'}" [baseZIndex]="10000" [modal]="true" [closeOnEscape]="false"
  [draggable]="false" [resizable]="false" [closable]="false">
  <p-header>
    <h1>
      <i class="fa-icon fa fa-exclamation-triangle" aria-hidden="true"></i>
      <span class="training-header-label">Training</span>
    </h1>
  </p-header>
  <div class="training-alert-body">
    <i class="fa fa-user-md fa-5x" aria-hidden="true"></i>
    <h1 class="header">Welcome to</h1>
    <h1 class="header portal">Provider Portal User Training</h1>
    <p class="message">You must complete a short training program before accessing the portal.</p>
    <div class="buttons">
      <button type="button" class="btn-start btn btn-primary" (click)="onStart()">
        <h2>START</h2>
      </button>
    </div>
  </div>
</p-dialog>
