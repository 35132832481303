import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ToolbarModule } from 'primeng/toolbar';

import { PortalRoutingModule } from './portal-routing.module';
import { PortalComponent } from './portal.component';

import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { FooterComponent } from '../layout/footer/footer.component';
import { AppComponentsModule } from '../app-components/app-components.module';
import { CollectionManagerModule } from '../collection-manager/collection-manager.module';
import { FooterCopyrightComponent } from "../layout/footer/footer-copyright/footer-copyright.component";

@NgModule({
  declarations: [
    PortalComponent,
    FooterComponent,
    ResetPasswordComponent,
  ],
  imports: [
    CommonModule,
    PortalRoutingModule,
    AppComponentsModule,
    CollectionManagerModule,
    ToolbarModule,
    FooterCopyrightComponent
]
})
export class PortalModule { }
