import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { PagerClasses } from '../../../services/models/pager-classes';
import { UserTypeMap } from '../../../services/models/user-type-map';
import { UserStatusMap } from '../../../services/models/user-status-map';
import { PortalUser, UserType, UserStatus } from '../../../services/models/portal-user';
import { CollectionListItem } from '../../../services/models/collection-list-item';
import { CollectionService } from '../../../services/collection.service';
import { UserAdminService } from '../../../services/user-admin.service';
import { SessionService } from '../../../services/session.service';
import { Constants } from '../../../app.constants';
import { Messages } from '../../../app.messages';
import { UiUtility } from 'src/app/utils/ui-utility';

@Component({
  selector: 'collection-manager-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {
  public totalUsers = 0;
  public pageNumber: number;
  public rowsPerPage: number;
  public users: PortalUser[];
  public cssClasses = PagerClasses.cssClasses;
  public editUser: PortalUser;
  public removalUser: PortalUser;
  public showAddUser = false;
  public showAccept = false;
  public showApproveDeclineScreen = false;
  public showUserPermission = false;
  public showUserDeclineScreen = false;
  public selectedUser: PortalUser;
  public collectionItem: CollectionListItem;
  public results: [];
  public userName: string = '';
  public selectedStatus: any;
  public userStatuses: SelectItem[] = [];
  public selectedRole: any;
  public userRoles: SelectItem[] = [];

  private sortColumn: string;
  private sortAscending: boolean;
  private allUsers: PortalUser[];
  private filteredUsers: PortalUser[];
  private collectionServiceSubscription: Subscription;
  private clientUserSearch: boolean = false;

  constructor(
    private adminService: UserAdminService,
    private collectionService: CollectionService,
    private confirm: ConfirmationService,
    private messageService: MessageService,
    public session: SessionService,
    private constants: Constants,
    private messages: Messages
  ) {
    this.rowsPerPage = constants.DetailRowsPerPage;
    this.users = [];
    this.editUser = null;
    this.pageNumber = 1;
    this.sortColumn = 'status';
    this.sortAscending = true;
  }

  ngOnInit() {
    this.collectionServiceSubscription = this.collectionService.collectionListItemChanged$
      .subscribe(collection => {
        this.pageNumber = 1;
        this.findUsers(this.pageNumber, true);
        this.collectionItem = collection;
        const user = this.adminService.selectedUser;
        if (user != null && collection != null && collection.id != null && collection.id != 0) {
          if (user.providerCollectionID != null) {
            this.adminService.selectedUser.providerCollectionID = collection.id;
          }
          this.showUsers(1, 1, [user]);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.collectionServiceSubscription) {
      this.collectionServiceSubscription.unsubscribe();
    }
  }

  private showUsers(pageNumber: number, totalUsers: number, users: PortalUser[]): void {
    this.clientUserSearch = false;
    this.pageNumber = pageNumber;
    this.totalUsers = totalUsers;
    this.allUsers = users;
    if (users != null) {
      this.onPage(pageNumber);
    }
  }

  // Get the class(es) to display for the status icon
  public mapStatusToIcon(user: PortalUser): string {
    return UserStatusMap.mapStatustoIcon(user.status);
  }

  // Get the class(es) to display for the role icon
  public mapRoleToIcon(user: PortalUser): string {
    if (user.status !== UserStatus.Active && user.status !== UserStatus.Approved) {
      return null;
    }
    return UserTypeMap.mapUserRoleTypeToIcon(user.roleID);
  }

  // Get the string to display for the tooltip (title) for the user hovers over the status icon
  public mapStatusToTooltip(user: PortalUser): string {
    return UserStatusMap.mapStatusToSelectItem(user.status).label;
  }

  // Get the string to display the tooltip (title) when the user hovers over the role
  public mapRoleToTooltip(user: PortalUser): string {
    if (user.status !== UserStatus.Active && user.status !== UserStatus.Approved) {
      return null;
    }

    const roleType = UserTypeMap.mapUserRoleTypeToSelectItem(user.roleID);
    return roleType ? roleType.label : '';
  }

  // Show the users in the collection.
  public findUsers(pageNumber: number, initialLoad: boolean = false): void {
    const collection: CollectionListItem = this.collectionService.selectedCollectionItem;
    if (collection != null && collection.id != null && collection.id != 0) {
      let sub = this.collectionService.getCollectionUsers(collection, 1, 99999, true).subscribe(response => {
        this.showUsers(pageNumber, response.totalItems, response.items);
        const selected = this.adminService.selectedUser;
        if (selected != null) {
          const selectedItem = response.items.find(x => x.email === selected.email);
          if (selectedItem != null) {
            this.selectedUser = selectedItem;
          }
        }
        if (initialLoad) {
          const user = this.adminService.selectedUser;
          if (user != null) {
            let mappedUser = this.allUsers.find(u => u.suspenseId === user.suspenseId);
            if (!mappedUser) {
              mappedUser = this.allUsers.find(u => u.userID === user.userID);
            }
            this.editUser = mappedUser;
            this.onEditUser(mappedUser);
            this.adminService.selectedUser = null; // reset user selection after 
            this.selectedUser = null;
          }
        }
        this.setUserSelections();

        if (sub != null) {
          sub.unsubscribe();
        }
      });
    }
    else {
      this.showUsers(1, 0, null);
    }
  }

  public sortClass(column: string): string {
    let cls: string;
    if (column == this.sortColumn) {
      cls = this.sortAscending ? "fa fa-chevron-up" : "fa fa-chevron-down";
    }
    else {
      cls = "fa fa-chevron-up";
    }
    return cls;
  }

  public onSort(e: any): void {
    if (this.clientUserSearch) {
      this.filteredUsers.sort((data1, data2) => {
        return this.sortLogic(data1, data2, e);
      });
    }
    else {
      this.allUsers.sort((data1, data2) => {
        return this.sortLogic(data1, data2, e);
      });
    }

    this.sortAscending = e.order == 1;
    this.sortColumn = e.field;
    this.onPage(this.pageNumber);
  }

  public onPage(page: number): void {
    this.pageNumber = page;
    const start = (page - 1) * this.rowsPerPage;
    let end = start + this.rowsPerPage;

    // Select the subset of users to display in the grid
    if (this.clientUserSearch) {
      if (end > this.filteredUsers.length) {
        end = this.filteredUsers.length;
      }
      this.users = this.filteredUsers.slice(start, end);
    }
    else {
      if (end > this.allUsers.length) {
        end = this.allUsers.length;
      }
      this.users = this.allUsers.slice(start, end);
    }

  }

  // Event sent by child dialogs to update the list of users of the collection.
  public onUserUpdated(event): void {
    this.clientUserSearch = false;

    // set to check Pending status because edituser.status hasn't been updated yet
    // TODO: make editable user reactive or use two-way binding to update local
    //       then change userstatus.approved since update should match. Will still
    //       need flag
    if (event.action === 'PendingApprove') {
      this.showApproveDeclineScreen = false;
      this.showUserPermission = true;
      return;
    }
    else if (event.action === 'PendingDecline') {
      this.showApproveDeclineScreen = false;
      this.showUserDeclineScreen = true;
      return;
    }

    if (this.editUser != null && this.editUser.status === UserStatus.Pending && event.action === 'Approve') {
      this.messageService.add({ severity: this.messages.SeveritySuccess, detail: this.messages.MessageUserApproved, life: this.messages.ToasterLife });
    }

    this.removalUser = null;
    this.showApproveDeclineScreen = false;

    if (event.action === 'Remove') {
      this.pageNumber = 1;
    }
    if (event && event.action !== 'Cancel') {
      this.findUsers(this.pageNumber);
    }
  }

  public onUserPermissionUpdated(event): void {
    this.clientUserSearch = false;
    this.showUserPermission = false;
    if (event && event.action !== 'Cancel') {
      this.findUsers(this.pageNumber);
    }
  }

  public onUserDeclineUpdated(event): void {
    this.clientUserSearch = false;
    this.showUserDeclineScreen = false;
    if (event && event.action === 'Decline') {
      this.findUsers(this.pageNumber);
    }
  }

  public onAddUser(): void {
    this.showAddUser = true;
  }

  // Set the user to be edited that will be input to the edit user dialog.
  public onEditUser(user: PortalUser) {
    this.editUser = user;
    if (user.status === UserStatus.Pending) {
      this.showApproveDeclineScreen = true; // enable accept/decline dialog
    } else {
      this.showUserPermission = true; // enable user permission slider only
    }
  }

  // Set the user to be deleted that will be input to the remove user dialog.
  public onRemoveUser(user: PortalUser) {
    this.removalUser = user;
  }

  public onShowAddStatus(status: boolean) {
    this.clientUserSearch = false;
    this.showAddUser = status;
  }

  public onAddUserToCollection(addUser: PortalUser) {
    this.clientUserSearch = false;
    // ensure add user dialog closed
    this.showAddUser = false;
    this.showUserPermission = true;
    // Show editbox
    this.editUser = addUser;
    this.findUsers(this.pageNumber);
  }

  public searchUsers() {
    if (this.userName.trim().length > 0 || (this.selectedStatus && this.selectedStatus.value.id !== 0) || (this.selectedRole && this.selectedRole.value.id !== 0)) {
        // filter users
        this.filteredUsers = this.allUsers
                      .filter(p => (p.name.toLowerCase().indexOf(this.userName.trim().toLowerCase()) >= 0 ||  
                                   p.email.toLowerCase().indexOf(this.userName.trim().toLowerCase()) >= 0) &&
                                   (this.selectedStatus.value.id === 0 || this.selectedStatus.value.id === p.status) &&
                                   (this.selectedRole.value.id === 0 || this.selectedRole.value.code === p.roleCode));
        this.totalUsers = this.filteredUsers.length;
        this.clientUserSearch = true;
        this.onPage(1);
    }
    else {
      // reset users
      this.filteredUsers = [];
      this.pageNumber = 1;
      this.clientUserSearch = false;
      this.showUsers(1, this.allUsers.length, this.allUsers);
      let param: any = {
        "field": "name",
        "order": 0
      }
      this.onSort(param);
      this.sortClass(param.name);
    }
  }

  public resetUserSearch() {
    this.userName = '';
    this.sortAscending = true;
    this.selectedUser = null;
    this.selectedRole = null;
    this.selectedStatus = null;
    this.setDefaultSearchOption();
    this.searchUsers();
  }

  private sortLogic(data1: any, data2: any, e: any) {
    let value1 = data1[e.field];
    let value2 = data2[e.field];
    let result = null;

    if (value1 == null && value2 != null)
      result = -1;
    else if (value1 != null && value2 == null)
      result = 1;
    else if (value1 == null && value2 == null)
      result = 0;
    else if (typeof value1 === 'string' && typeof value2 === 'string')
      result = value1.localeCompare(value2);
    else
      result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

    return (e.order * result);
  }

  private setUserSelections() {
    let statusesArray: any[] = [];
    let rolesArray: any[] = [];

    statusesArray.push({
      label: 'All Statuses',
      value: { id: 0, name: 'All Statuses', code:'All Statuses' }
    });
    rolesArray.push({
      label: 'All Roles',
      value: { id: 0, name: 'All Roles', code:'All Roles' }
    });

    this.allUsers.forEach(u => {
      if (!UiUtility.isNullUndefinedOrEmpty(u.statusDesc) && statusesArray.find(s => s.label.toLowerCase() == u.statusDesc.toLowerCase()) == null) {
        statusesArray.push({
          label: u.statusDesc,
          value: {
            id: u.status,
            name: u.statusDesc,
            code: u.statusDesc
          }
        });
      }
      if (!UiUtility.isNullUndefinedOrEmpty(u.roleDisplayName) && rolesArray.find(r => r.label.toLowerCase() === u.roleDisplayName.toLowerCase()) == null) {
        rolesArray.push({
          label: u.roleDisplayName,
          value: {
            id: u.roleID,
            name: u.roleDisplayName,
            code: u.roleCode
          }
        });
      }
    });
    this.userStatuses = statusesArray;
    this.userRoles = rolesArray;
    this.setDefaultSearchOption();
  }

  private setDefaultSearchOption() {
    this.selectedRole = this.userRoles.find(r => r.value.id === 0);
    this.selectedStatus =  this.userStatuses.find(r => r.value.id === 0);
  }
}
