import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MemberComponent } from '../member/member.component';
import { MemberInfoService } from '../../services/member-info.service';

@Injectable({
  providedIn: 'root'
})
export class ClearMemberDataGuard  {

  constructor(private memberInfoService: MemberInfoService) {
  }

  canDeactivate(
    component: MemberComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot): boolean {

    if (currentState.url !== nextState.url) {
      // call clear on member component or import service and clear results and selected item
      this.memberInfoService.clearMemberList();
    }

    return true;
  }

}
