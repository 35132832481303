import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-user-pcp-agreement',
  templateUrl: './user-pcp-agreement.component.html',
  styleUrls: ['./user-pcp-agreement.component.scss']
})
export class UserPcpAgreementComponent implements OnInit {
  @Output() userAgreed = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  public onAgree() {
    this.userAgreed.emit(true);
  }
}
