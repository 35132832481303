export class MemberInfoRequest {
  public memberId: string;
  public firstName: string;
  public lastName: string;
  public dateOfBirth: Date;
  public memeCk: number;
  public grgrCk: number;
  public dateOfService: Date;
  public isInternalUser: boolean;
  public userName: string;
  public pcpId: string;
  public city: string;
  public providerCollectionId: number;
  public context: number;
}
