<p-dialog class="edit-user" header="Edit {{this.userFullName}}" [(visible)]="display" [modal]="true"
(onHide)="onCancel()"  (onShow)="onShow()" styleClass="approve-decline" [style]="{width: '27rem'}">
  <div class="approve-decline-container">
    <p>Do you want to Accept or Decline this user?</p>
  </div>
  <p-footer class="footer-container">
    <button type="button" class="btn btn-primary btn-accept-user" (click)="onAccept()">Accept</button>
    <button type="button" class="btn btn-secondary btn-decline-user" (click)="onDecline()">Decline</button>
  </p-footer>
</p-dialog>
