<div class="reports-payforvalue-report-type">
    <app-reports-type [reportType]="'payforvaluemanual'"></app-reports-type>
</div>
<div class="reports-payforvalue-container">
  <div class="report-measurementlabel">
    <label>Measurement Period</label>
  </div>
  <div class="report-filelist" *ngIf="fileList && fileList.length > 0">
    <p-dropdown name="providerfiles" id="providerfiles" [options]="fileList" [(ngModel)]="selectedFile">
    </p-dropdown>
    <button class="btn btn-primary" type="button" (click)="onBuildReport()" [disabled]='!enableBuildReport'>Download</button>
  </div>
  <div class="report-filelist" *ngIf="!fileList || fileList.length == 0">
    <div>No measurement period files available to download.</div>
  </div>  
</div>

