import cb from './_cb.js';
import getLength from './_getLength.js';

// Internal function to generate `_.findIndex` and `_.findLastIndex`.
export default function createPredicateIndexFinder(dir) {
  return function (array, predicate, context) {
    predicate = cb(predicate, context);
    var length = getLength(array);
    var index = dir > 0 ? 0 : length - 1;
    for (; index >= 0 && index < length; index += dir) {
      if (predicate(array[index], index, array)) return index;
    }
    return -1;
  };
}