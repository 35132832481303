<p-sidebar [(visible)]="display" [style]="{width: '45rem'}" position="right" [dismissible]="false"
  [closeOnEscape]="false" [showCloseIcon]="false" modal="true" autoZIndex="true"
  (onHide)="onCancel()" (onShow)="onShow()">
  <div class="user-permission-container">
    <div class="user-permission-container-body" *ngIf="!displayPositionSelection && !displayAuthorizedBy">
      <div *ngIf="isInternalUserAdd" class="internal-lookup-container">
        <div class="internal-username">Enter user name:</div>
        <div class="internal-user-search">
          <p-autoComplete type="search" [(ngModel)]="selectedUserInfo" [suggestions]="users" field="displayName"
            [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" (completeMethod)="search$.next($event)"
            (onSelect)="onSelectInternalUser($event)" [emptyMessage]="'No Results Found'" [forceSelection]="true" [delay]="250"
            [autofocus]="true" [minLength]="2">
            <ng-template let-selectedUserInfo pTemplate="item">
              <div class="p-helper-clearfix">
                {{selectedUserInfo.displayName}} ({{selectedUserInfo.email}})
              </div>
            </ng-template>
          </p-autoComplete>
        </div>
      </div>
      <div class="user-permission-container-header" [ngClass]="userHeaderClass">
        <i class="role-icon" [ngClass]="userRoleClass" *ngIf="isInternalUser"></i>
        <div class="fullName">{{this.userName}}</div>
        <div class="email">{{this.userEmail}}</div>
      </div>
      <div class="user-permission-action-container" *ngIf="isInternalUserEdit">
        <span class="action-label">Status:</span>
        <div class="ddAccountStatusSelection" *ngIf="accountStatusSelection.length">
          <p-dropdown
          name="accountStatusSelected"
          [options]="accountStatusSelection"
          [(ngModel)]="accountStatusSelected"
          (onChange)="onActionStatusSelected()"
          class="accountStatusSelected">
        </p-dropdown>
        </div>
      </div>
      <div *ngIf="!isInternalUser" class="header-detail">
        <span class="authorized-label">Authorized by :</span>
        <span class="authorized-data">{{authorizedNameDisplay}}</span>
        <span class="authorized-date-label">Date :</span>
        <span class="authorized-date-data">{{authorizedDateDisplay | date : 'MM/dd/yy'}}</span>
        <span class="position-label">Position :</span>
        <span class="position-data">{{userPositionNameDisplay}}</span>
      </div>
      <table class="role-main" *ngIf="!(displayDeactivateSection || displayLockSection)">
        <tr class="tblHead">
          <th class="role-header-label-left">User Role</th>
          <th class="role-header-label-right">Security Settings</th>
        </tr>
        <tr class="tblBody">
          <td class="role-list-container">
            <div *ngFor="let role of userCollectionRoles" class="role-row" id="role-row-{{role.roleID}}"
              (click)="onClickRole(role)">
              <i class="fa {{mapRoleToIcon(role.roleID)}}" aria-hidden="true"></i>
              <span title="{{role.roleDisplayName}}">{{role.roleDisplayName}}</span>
            </div>
          </td>
          <td class="permission-list-container">
            <div class="" *ngIf="!displayPcpAgreement && !displayUserPrompt">
              <div class="permission-row" *ngFor="let permission of userRolePermissionTemplatesDisplay">
                <i class="fa fa-check" aria-hidden="true"
                  *ngIf="permission.isDefaultPermission && !permission.isEditAllowed"></i>
                <input type="hidden" name="permission-row-{{permission.userPermissionID}}"
                  [(ngModel)]="permission.hasAccess"
                  *ngIf="permission.isDefaultPermission && !permission.isEditAllowed">
                <p-checkbox name="permission-row-{{permission.userPermissionID}}" [(ngModel)]="permission.hasAccess"
                  (onChange)="onPermissionChanged($event, permission)" title="{{permission.permissionDisplayName}}" binary="true" *ngIf="permission.isEditAllowed">
                </p-checkbox>
                <img src="./assets/images/{{mapPermissionToIcon(permission.permissionCode)}}"
                  class="permission-icon" />
                <span title="{{permission.permissionDisplayName}}">{{permission.permissionDisplayName}}</span>
              </div>
            </div>
            <app-user-pcp-agreement (userAgreed)="userAgreePcpOnly($event)" *ngIf="displayPcpAgreement && !displayUserPrompt">
            </app-user-pcp-agreement>
            <app-user-permission-prompt (userPrompted)="userPromptToContinue($event)" *ngIf="displayUserPrompt">
            </app-user-permission-prompt>
          </td>
        </tr>
      </table>
      <div class="user-deactivate-container-body" *ngIf="displayDeactivateSection">
        <span class="message">You must enter a deactivate reason below to continue.</span>
        <div class="deactivate-reason">
          <textarea id="txtDeactivate" [(ngModel)]="deactivateReason" #deactivateReasonTxt="ngModel" maxlength="500" (ngModelChange)="onDeactivateReasonChange()" required></textarea>
          <div class="deactivate-validation" *ngIf="!deactivateReason && (deactivateReasonTxt.touched || deactivateReasonTxt.dirty)">You must enter a deactivate reason.</div>
        </div>
      </div>
      <div class="user-lock-container-body" *ngIf="displayLockSection">
        <span class="message">You must enter a lock reason below to continue.</span>
        <div class="lock-reason">
          <textarea id="txtLock" [(ngModel)]="lockReason" #lockReasonTxt="ngModel" maxlength="500" (ngModelChange)="onLockReasonChange()" required></textarea>
          <div class="lock-validation" *ngIf="!lockReason && (lockReasonTxt.touched || lockReasonTxt.dirty)">You must enter a lock reason.</div>
        </div>
      </div>
    </div>
    <div class="user-position-container-body" *ngIf="displayPositionSelection || displayAuthorizedBy">
      <app-user-position-and-authorized-dialog [displayAuthorizedBy]="displayAuthorizedBy"
        [displayPositionSelection]="displayPositionSelection" [isPcpRole]="isPcpRole(roleSelected)"
        [providerPositionId]="this.user.providerPositionID" [providerPositionOther]="this.user.providerPositionOther"
        [user]="this.user" 
        (onPositionSelected)="onPositionSelected($event)">
      </app-user-position-and-authorized-dialog>
    </div>
    <div class="user-permission-container-footer" *ngIf="!displayPositionSelection && !displayAuthorizedBy">
      <button type="button" class="btn btn-primary btn-cancel" (click)="onCancel()" label="Cancel">Cancel</button>
      <button type="button" class="btn btn-primary btn-save" [disabled]="saveBtnDisabled" (click)="onSave()"
        label="Add">{{txtSave}}</button>
    </div>
  </div>
</p-sidebar>
