import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-idle-timeout-dialog',
  templateUrl: './idle-timeout-dialog.component.html',
  styleUrls: ['./idle-timeout-dialog.component.scss']
})
export class IdleTimeoutDialogComponent implements OnInit {

  @Input() display: boolean;
  @Input() countdown: number;
  @Output() logOutUser = new EventEmitter<boolean>();
  @Output() continueSession = new EventEmitter<boolean>();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit() {
  }

  public onLogOutUser() {
    this.logOutUser.emit(true);
    this.onClose.emit(this.display);
  }

  public onContinueSession() {
    this.continueSession.emit(true);
    this.onClose.emit(this.display);
  }

  onHide(e: any) {
    this.continueSession.emit(true);
    this.onClose.emit(this.display);
  }
}
