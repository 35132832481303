<p-dialog class="add-user" [(visible)]="display" (onHide)="onClose()" [modal]="true">
  <p-header>
    Add user to {{collectionItem != null ? collectionItem.name : "collection"}}
  </p-header>
  <div class="form-group">
    <div class="row">
      <div class="col-md-12 left-align">
        Enter name and click Search.
      </div>
    </div>
    <div class="row">
      <div class="col-md-10">
        <input type="text" class="form-control" id="txtUserName" (keydown)="onKeyDown ($event)"
          [(ngModel)]="userName" #userNameTxt="ngModel" />
      </div>
      <div class="col-md-2">
        <button type="button" class="btn btn-outline-search" id="btnUserSearch"
          label="Search" [disabled]="canSearch() ? null: true" (click)="onSearch()">
          Search
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="isTableReady">
      <p-table #addUserTable [value]="users" [paginator]="false">
          <ng-template pTemplate="header">
              <tr>
                  <th class="name-column">Name</th>
                  <th class="email-column">Email</th>
                  <th class="add-column"></th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row>
              <tr>
                  <td class="name-column">
                      <div class="cell-class" [ngClass]="{'disabled': disableRow(row)}">{{row.name}}</div>
                  </td>
                  <td class="email-column">
                      <div class="cell-class" [ngClass]="{'disabled': disableRow(row)}">{{row.email}}</div>
                  </td>
                  <td class="add-column">
                      <button type="button" class="btn btn-outline-search" (click)="onAdd(row)" [disabled]="disableRow(row)">Add</button>
                  </td>
              </tr>
          </ng-template>
      </p-table>
  </div>

</p-dialog>
