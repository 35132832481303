<div class="awp-provider-container">
    <div class="awp-provider-provider-container" *ngIf='!displayMembers'>
      <div class="awp-provider-provider-search">
        <app-reports-provider-search (onProviderSearch)="onProviderSearch($event)" #providerSearch></app-reports-provider-search>
      </div>
      <div class="awp-provider-provider-results">
        <p-table
            [value]="displayedProviders"
            [paginator]="true"
            [rows]="10"
            [scrollable]="true"
            scrollHeight="450px"
            selectionMode="single"
            (onSort)="onSort($event)"
            [styleClass]="'p-datatable-striped p-datatable-lg'"
            >
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="{{col.field}}" width="{{col.width}}" class="{{col.classHeader}}" *ngFor="let col of columns">
                <span class="p-column-title">{{col.header}}</span>
                <div *ngIf="col.sort" class="{{sortClass(col.field)}}">
                </div>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-provider>
            <tr>
              <td width="{{col.width}}" class="{{col.classContent}}" *ngFor="let col of columns">
                <div *ngIf="col.header === ''">
                  <p-radioButton name='providerid' id='{{provider.calProviderID}}' value='{{provider.calProviderID}}' [(ngModel)]="selectedCalProviderID" (click)="onProviderSelected(provider)"></p-radioButton>
                </div>
                <div *ngIf="col.header === 'Name' || col.header === 'Provider ID' || col.header === 'TIN'">
                  <div>
                    <span>{{provider[col.field]}}</span>
                  </div>
                </div>
            </tr>
          </ng-template>
        </p-table>
      </div>
     </div>
     <div class="awp-provider-member-container" *ngIf='displayMembers'>
      <div class="awp-provider-member-container-header" *ngIf="selectedProvider">
        <button class="btn btn-primary btn-back" (click)="backToProvider()">
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
          <span>Back</span>
        </button>
        <p class="provider-name">{{selectedProvider.name}}</p>
      </div>
      <div class="awp-provider-member-container">
        <app-attestation-bh-aba-referrals-member #awpmemberComponent></app-attestation-bh-aba-referrals-member>
      </div>
    </div>
  </div>
  