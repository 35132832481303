import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, Inject  } from '@angular/core';
import { PortalUser, UserStatus, UserType } from '../../../services/models/portal-user';
import { BusyService } from '../../../services/busy.service';
import { UserAdminService } from '../../../services/user-admin.service';
import { Subscription } from 'rxjs';
import { UserCollectionRole } from '../../../services/models/user-collection-role';
import { DOCUMENT } from '@angular/common';
import { UserRolePermissionTemplate } from '../../../services/models/user-role-permission-template';
import { CollectionService } from '../../../services/collection.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { Constants } from '../../../app.constants';
import { SessionService } from '../../../services/session.service';
import { UserCollectionPermission } from '../../../services/models/user-collection-permission';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { query } from '@angular/animations';
import { UserInfo } from '../../../services/models/user-info';
import { UserTypeMap } from '../../../services/models/user-type-map';
import { SelectItem } from 'primeng/api';
import { UserPermissionTypeMap } from '../../../services/models/user-permission-type-map';
import { UiUtility } from '../../../utils/ui-utility';

@Component({
  selector: 'app-user-permission-dialog',
  templateUrl: './user-permission-dialog.component.html',
  styleUrls: ['./user-permission-dialog.component.scss']
})
export class UserPermissionDialogComponent implements OnInit, OnDestroy {
  private userRoleAndPermissions$: Subscription;
  private userPositionId: number;
  private userPositionOther: string;
  private reasonForPermissionChange:string;
  private roleSelected: UserCollectionRole;
  private authorizedName: string;
  private accountActionStatus = [
    { label: 'Active', value: { id: UserStatus.Active, name: 'Active', code:  UserStatus[UserStatus.Active] } },
    { label: 'Admin Locked', value: { id: UserStatus.AdminLocked, name: 'Admin Locked', code: UserStatus[UserStatus.AdminLocked] } },
    { label: 'Deactivated', value: { id: UserStatus.Deactivated, name: 'Deactivated', code: UserStatus[UserStatus.Deactivated] } }
  ];

  public displayPcpAgreement = false;
  public displayUserPrompt = false;
  public displayPositionSelection = false;
  public displayAuthorizedBy = false;
  public saveBtnDisabled = false;
  public userPermissions: UserCollectionPermission[] = [];
  public userName: string;
  public userEmail: string;
  public txtSave: string;
  public userCollectionRoles: UserCollectionRole[] = [];
  public defaultRole: UserCollectionRole;
  public userRolePermissionTemplates: UserRolePermissionTemplate[];
  public userRolePermissionTemplatesDisplay: UserRolePermissionTemplate[];
  public authorizedNameDisplay: string;
  public authorizedDateDisplay: Date;
  public userPositionNameDisplay: string;
  public committedByUserName: string;
  public isSelectedPcpRole: boolean;
  public searchText: string;
  public search$ = new Subject<any>();
  public selectedUserInfo: UserInfo = {firstName: '', lastName: '', email: '', userFullName: '', userName:'', phone:'', deptName: '', displayName: '', isActive: null};
  public users: UserInfo[];
  public userHeaderClass: string;
  public userRoleClass: string;
  public deactivateReason: string;
  public displayDeactivateSection = false;
  public lockReason: string;
  public displayLockSection = false;
  public accountStatusSelected: any;
  public isInternalUser: boolean;
  public accountStatusSelection: SelectItem[] = [];

  @Input() user: PortalUser;
  @Input() display: boolean;
  @Input() isInternalUserAdd: boolean = false;
  @Input() isInternalUserEdit: boolean = false;
  @Output() userUpdated = new EventEmitter();

  constructor(
    private busy: BusyService,
    private authService: OAuthService,
    private userAdminService: UserAdminService,
    private collectionService: CollectionService,
    private sessionService: SessionService,
    private constants: Constants,
    @Inject(DOCUMENT) private document: Document
  ) {

  }

  ngOnInit() {
    this.search$.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      switchMap( event => {
        return this.userAdminService.searchInternalUser(event.query);
      })
    ).subscribe( results => {
      this.users = results.map<UserInfo>( value => {
        value.displayName = value.firstName + ' ' + value.lastName ;

        return value;
      });
    });

    this.initialize();
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  private initialize() {
    if (!this.user)
      return;

    this.setInternalUser();
    this.setCommittedByUserName();
    this.setButtonText();
    this.setUserHeaderClass();
  }

  private clearSubscriptions() {
    if (this.search$) {
      this.search$.unsubscribe();
    }
    if (this.userRoleAndPermissions$) {
      this.userRoleAndPermissions$.unsubscribe();
    }
  }

  private resetUserPermissionForm() {
    this.searchText = '';
    this.users = <UserInfo[]>[];
    this.userCollectionRoles = <UserCollectionRole[]>[];
    this.selectedUserInfo = {firstName: '', lastName: '', email: '', userFullName: '', userName:'', phone:'', deptName: '', displayName: '', isActive: null};
    this.userRolePermissionTemplatesDisplay = null;
    this.defaultRole = null;
    this.userName = null;
    this.userEmail = null;
    this.userHeaderClass = '';
    this.isInternalUserEdit = false;
    this.isInternalUserAdd = false;
    this.isInternalUser = false;
    this.userRoleClass = '';
    this.accountStatusSelected = null;
    this.accountStatusSelection = [];
    this.lockReason = '';
    this.deactivateReason = '';
    this.displayDeactivateSection = false;
    this.displayLockSection = false;
    this.saveBtnDisabled = false;
  }

  public onSave(): void {
    if (this.sessionService.isEnterpriseAdmin && !this.isInternalUser) {
      // Manually entered an Authorized name
      this.displayAuthorizedBy = true;
    }
    else {
      // LOA as authorized by
      this.setAuthorizedByUserName();
    }

    if (this.hasPositionRequired()) {
      this.displayPositionSelection = true;
    }

    if (this.displayPositionSelection || this.displayAuthorizedBy) {
      return;
    }

    this.saveRoleAndPermission();
  }

  public onCancel(): void {
    this.resetUserPermissionForm();
    this.userUpdated.emit({ action:'Cancel' });
  }

  public onShow() {
    this.initialize();
    if (this.user && this.user.userID) {
      this.userRoleAndPermissions$ = this.userAdminService.getUserRoleAndPermissions(this.user.providerCollectionID, this.user.userID, this.isInternalUser)
      .subscribe(result => {
        if (result) {
          result.userRolePermissionTemplateData.forEach(permission => {
            if (!this.userCollectionRoles.find(r => r.roleID === permission.roleID)) {
              let userRole = new UserCollectionRole();
              userRole.roleDisplayName = permission.roleDisplayName;
              userRole.roleID = permission.roleID;
              userRole.userID = +this.user.userID;
              this.userCollectionRoles.push(userRole);
            }
          })
          this.userRolePermissionTemplates = result.userRolePermissionTemplateData;
          this.defaultRole = result.userCollectionRoles[0];
          this.userPositionNameDisplay = this.getUserPositionNameDisplay(this.defaultRole, this.user);
          this.authorizedNameDisplay = this.defaultRole.authorizedName;
          this.authorizedDateDisplay = this.defaultRole.authorizedDate;
          this.roleSelected = this.defaultRole;

          this.reloadPermissionView(this.defaultRole);
          setTimeout(() => { this.onClickRole(this.roleSelected);  }, 25);
        }
      });
      this.userName = this.user.name;
      this.userEmail = this.user.email;
    }
  }

  onSelectInternalUser(user: UserInfo) {
    this.user.userID = "0";
    this.user.providerCollectionID = null;
    this.user.internalName = user.userName;
    this.user.email = user.email;
    this.user.name = user.displayName;
    this.isInternalUserAdd = true;
    this.user.status = UserStatus.Active;
    this.onShow();
  }

  public onClickRole(role: UserCollectionRole) {
    const selectedRole = this.document.getElementById("role-row-" + role.roleID);
    if (selectedRole) {
      const prevSelected = this.document.querySelector<HTMLDivElement>('.role-list-container .role-row.selected');
      if (prevSelected) {
        prevSelected.setAttribute('class', 'role-row');
      }
      selectedRole.setAttribute('class', 'role-row selected');

      this.displayPcpAgreement = this.isPcpRole(role) && role.roleID !== this.defaultRole.roleID;
      this.displayUserPrompt = this.isSameUser(role) && !(UserTypeMap.isLOA(role.roleID) || UserTypeMap.isEA(role.roleID));
    
      this.setSaveBtnDisabled();
      this.reloadPermissionView(role);
      this.roleSelected = role;
    }
  }

  public onPermissionChanged($event: any, permission: UserRolePermissionTemplate) {
    this.setReferralPermission($event, permission);
  }

  public userAgreePcpOnly(event) {
    this.displayPcpAgreement = false;
    this.saveBtnDisabled = false;
  }

  public userPromptToContinue(event) {
    this.displayUserPrompt = false;
    this.setSaveBtnDisabled();
  }

  public onPositionSelected(selectedPosition) {
    let isValid = true;

    if (this.displayPositionSelection) {
      this.userPositionId = selectedPosition ? selectedPosition.selectedPosition.id : 0;
      this.userPositionOther = selectedPosition ? selectedPosition.positionOtherName : null;
      isValid = this.userPositionId > 0;
    }
    if (isValid && this.displayAuthorizedBy) {
      this.authorizedName = selectedPosition ? selectedPosition.authorizedByName : '';
      isValid = this.authorizedName.length ? true : false;
    }
    this.reasonForPermissionChange = selectedPosition ? selectedPosition.reasonforpermissionchange : '';
    
    this.displayPositionSelection = false;
    this.displayAuthorizedBy = false;

    if (isValid) {
      this.saveRoleAndPermission();
    }
    else {
      setTimeout(() => { this.onClickRole(this.roleSelected);  }, 25);
    }
  }

  public onActionStatusSelected() {
    this.setUserHeaderClass(this.accountStatusSelected.code);
    if (this.accountStatusSelected.id === UserStatus.Deactivated) {
      if (this.user.status !== UserStatus.Deactivated) {
        this.displayDeactivateSection = true;
        this.deactivateReason = '';
      }
      this.saveBtnDisabled = true;
      this.displayLockSection = false;
    }
    else if (this.accountStatusSelected.id === UserStatus.AdminLocked){
      if (this.user.status !== UserStatus.AdminLocked) {
        this.displayLockSection = true;
        this.lockReason = '';
      }
      this.saveBtnDisabled = true;
      this.displayDeactivateSection = false;
    }
    else {
      this.displayDeactivateSection = false;
      this.displayLockSection = false;
      this.saveBtnDisabled = false;
      this.lockReason = '';
      this.deactivateReason = '';
    }
    setTimeout(() => { this.onClickRole(this.roleSelected);  }, 25);
  }

  public onDeactivateReasonChange() {
    if (this.deactivateReason && this.deactivateReason.length)
      this.saveBtnDisabled = false;
    else
      this.saveBtnDisabled = true;
  }

  public onLockReasonChange() {
    if (this.lockReason && this.lockReason.length)
      this.saveBtnDisabled = false;
    else
      this.saveBtnDisabled = true;
  }

  public getSelectedRoleCss(roleID: number) {
    const selectedRoleEl = this.document.getElementById("role-row-" + roleID);
    if (selectedRoleEl) {
      const prevSelectedEl = this.document.querySelector<HTMLDivElement>('.role-list-container .role-row.selected');
      if (prevSelectedEl) {
        prevSelectedEl.setAttribute('class', 'role-row');
      }
    }

    if (roleID === this.roleSelected.roleID) {
      return 'role-row selected';
    }
    else {
      return 'role-row';
    }
  }

  public mapRoleToIcon(roleID: number): string {
    const role = UserTypeMap.mapUserRoleTypeToIcon(roleID);
    return role;
  }

  public mapPermissionToIcon(permissionCode: string): string {
    const icon = UserPermissionTypeMap.mapPermissionTypeToIcon(permissionCode);
    return icon;
 }

  public mapRoleToLabel(user: PortalUser): string {
    if (!user || (user.status !== UserStatus.Active && user.status !== UserStatus.Approved)) {
      return null;
    }

    const roleType = UserTypeMap.mapUserRoleTypeToSelectItem(user.roleID);
    return roleType ? roleType.label : '';
  }

  public isPcpRole(role: UserCollectionRole) {
    return UserTypeMap.isPCP(role.roleID);
  }

  private isSameUser(role: UserCollectionRole) {
    const currentUserID = this.sessionService.currentPermission.userID;
    return currentUserID === role.userID;
  }

  private saveRoleAndPermission() {
    this.userPermissions = [];
    this.userRolePermissionTemplatesDisplay.forEach(p => {
      if (p.hasAccess) {
        this.userPermissions.push({
          userPermissionID: p.userPermissionID,
          userID: +this.user.userID,
          providerCollectionID: this.collectionService.selectedCollectionItem ? this.collectionService.selectedCollectionItem.id : 0
        })
      }
    });

    if (this.isInternalUser) {
      const actionStatus = this.isInternalUserAdd ? this.user.status : this.accountStatusSelected.id;
      let notesText = '';

      if (this.isInternalUserEdit) {
        this.selectedUserInfo = new UserInfo();
        this.selectedUserInfo.email = this.user.email;
        this.selectedUserInfo.userName = this.user.internalName;
        if (actionStatus === UserStatus.AdminLocked)
          notesText = this.lockReason;
        else if (actionStatus === UserStatus.Deactivated) {
          notesText = this.deactivateReason;
        }
      }

      const sub = this.userAdminService.updateInternalUserPermissions(
        actionStatus,
        this.selectedUserInfo,
        this.userPermissions,
        this.roleSelected.roleID,
        this.isInternalUserAdd,
        notesText
      ).subscribe(() => {
          this.userUpdated.emit({
            action:'Update',
            userRole: this.roleSelected.roleID,
            userStatus: actionStatus
          });
          this.busy.emit(false);
        },
        (error) => {
          this.userPermissions = [];
          this.authorizedName = '';
          this.userPositionId = 0;
          this.selectedUserInfo = null;
          this.searchText = null;
          this.busy.emit(false);
        },
        () => {
          sub.unsubscribe();
          this.busy.emit(false);
        })
    }
    else {
      const sub = this.collectionService.updateUserPermissions( this.collectionService.selectedCollectionItem,
                                                                this.user,
                                                                this.userPermissions,
                                                                this.roleSelected.roleID,
                                                                this.authorizedName,
                                                                this.userPositionId,
                                                                this.userPositionOther,
                                                                this.reasonForPermissionChange)
      .subscribe(() => {
        sub.unsubscribe();
        this.busy.emit(false);
        if (this.user.status === UserStatus.Pending){
          this.userUpdated.emit({ action:'Approve' });
        }
        else {
          this.userUpdated.emit({
            action:'Update',
            userRole: this.roleSelected.roleID,
            providerPositionId: this.userPositionId,
            providerPositionOther: this.userPositionOther
          });
        }
      },
      (error) => {
        //reset all data when getting error
        this.userPermissions = [];
        this.authorizedName = '';
        this.userPositionId = 0;
      });
    }
  }

  private reloadPermissionView(role: UserCollectionRole) {
      this.userRolePermissionTemplatesDisplay = this.userRolePermissionTemplates.filter(p => p.roleID === role.roleID);
      this.userRolePermissionTemplatesDisplay.filter(p => p.isEditAllowed && p.roleID !== this.defaultRole.roleID).forEach(p => p.hasAccess = false);
  }

  private setCommittedByUserName() {
    const claims = this.authService.getIdentityClaims();
    if (!claims) {
      return null;
    }

    this.committedByUserName = claims['sub'];
  }

  private setAuthorizedByUserName() {
    const claims = this.authService.getIdentityClaims();
    if (!claims) {
      return null;
    }

    this.authorizedName = claims['given_name'] + this.constants.SpaceString + claims['family_name'];
  }

  private hasPositionRequired(): boolean {
    return this.userRolePermissionTemplatesDisplay.findIndex(p => p.isPositionRequired && p.hasAccess) >= 0;
  }

  private setButtonText() {
    this.txtSave = this.isInternalUserAdd
      ? 'Add User'
      : this.sessionService.isEnterpriseAdmin && !this.isInternalUser ? 'Next' : 'Save';
  }

  private setUserHeaderClass(status: string = '') {
    if (status === '' && this.user.status) {
      status = UserStatus[this.user.status].toLowerCase();
    }
    if (this.isInternalUserAdd) {
      this.userHeaderClass = 'user-header-internal-add display-no-role';
    }
    else if (this.isInternalUserEdit) {
      this.userHeaderClass = 'user-header-internal-' + (status ? status.toLowerCase() : 'add');
      if (!this.user.roleID) {
        this.userHeaderClass = this.userHeaderClass + ' display-no-role';
      }
    }
    else {
      this.userHeaderClass = 'user-header-external display-no-role';
    }

    if (this.isInternalUser)
      this.userRoleClass = UserTypeMap.mapUserRoleTypeToIcon(this.user.roleID);
  }

  private setInternalUser() {
    this.isInternalUser = this.isInternalUserAdd || this.isInternalUserEdit;
    if (this.isInternalUserEdit) {
      this.accountStatusSelection = [];
      for (let item of this.accountActionStatus) {
        if (this.user.status === UserStatus.Deactivated && item.value.id === UserStatus.AdminLocked) {
          continue;
        }
        this.accountStatusSelection.push({
          label: item.label, value: { id: item.value.id, name: item.value.name, code: item.value.code, original: item }
        });
      }

      const statusSelected = this.accountActionStatus.find(a => a.value.code.toLowerCase() === UserStatus[this.user.status].toLowerCase());
      this.accountStatusSelected = {
        'id': statusSelected.value.id,
        'name': statusSelected.value.name,
        'code': statusSelected.value.code,
        'original': statusSelected
      }
    }
  }

  private setSaveBtnDisabled() {
    this.saveBtnDisabled = (this.displayPcpAgreement || this.displayUserPrompt) ||
                        ((this.user.status === UserStatus.Deactivated && this.accountStatusSelected.id === UserStatus.Deactivated)
                        || (this.user.status === UserStatus.AdminLocked && this.accountStatusSelected.id === UserStatus.AdminLocked));
  }

  private setReferralPermission(checked: boolean, permission: UserRolePermissionTemplate) {
    if (permission.permissionCode === 'PRVRER' && !checked) {
      const perm = this.userRolePermissionTemplatesDisplay.find(p => p.permissionCode === 'PRVREM');
      if (perm)
        perm.hasAccess = false;
    } else if (permission.permissionCode === 'PRVREM' && checked) {
      const perm = this.userRolePermissionTemplatesDisplay.find(p => p.permissionCode === 'PRVRER');
      if (perm)
        perm.hasAccess = true;
    }
  }

  private getUserPositionNameDisplay(role: UserCollectionRole, user: PortalUser) {
    return role.positionDisplayName && role.positionDisplayName !== 'Other'
      ? role.positionDisplayName
      : (!UiUtility.isNullUndefinedOrEmpty(this.user.providerPositionOther) ? this.user.providerPositionOther : this.user.positionDisplayName);
  }
}
