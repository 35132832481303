<p-dialog header="Delegate Attestation" [(visible)]="display" [modal]="true" width="600">
    <div class="bls-modal-wrapper">

        <div class="bls-modal-container">
    
            <!-- <div class="bls-modal-title">
            Delegate Attestation
            </div> -->
    
            <div class="bls-modal-intro">
            The DHCS release All Plan Letter (APL) 20-016 (Revised): Blood Lead Screening of Young Children.  This APL requires providers to screen children in Medicaid for elevated blood lead levels as part of required prevention services offered through Early and Periodic Screening, Diagnostic, and Treatment (EPSDT) program.
            </div>
    
            <div class="bls-modal-table-wrapper">
            <table border="0" cellspacing="0" width="100%" class="bls-modal-table-data">
            <tr><td>
                    By agreeing to the terms below, I am attesting that I:
            </td></tr>
            <tr><td>
                    <b>1) Received and reviewed the CalOptima Health Lead Screening Performance quarterly report,</b> and
            </td></tr>
            <tr><td>
                    <b>2) Will comply with APL 20-016 to provide oral or written anticipatory guidance</b> to the parent(s) or guardian(s) of a child member that at a minimum includes information that children are at resk for lead poisoning and can be harmed by exposure to lead.
            </td></tr>
            <tr><td>
                    <b>3) Will comply with APL 20-016 reporting requirements</b> for submitting claims and encounters using standard formats and proper CPT coding.
            </td></tr>
            <tr><td>
                    <b>4) Will document reason(s) for not performing the blood lead screening</b> in the child member's medical record.  In cases where screening consent has been withheld, a signed statement of voluntary refusal must be obtained and documented in the child member's medical record.  Records of voluntary refusal must be provided to CalOptima Health upon request.
            </td></tr>
            <tr><td>
                    <b>5) Will follow California Lead Poisoning Prevention Branch minimum standards of care</b> for conducting blood lead screenings, interpreting blood lead levels, and determining appropriate follow-up.
            </td></tr>
            </table>
            </div>
    
            <div class="bls-modal-section">
                <div class="bls-modal-sig-box">
                    <div class="bls-modal-sig-label">
                    Your Full Name
                    </div>
                    <div class="bls-modal-sig-wrapper">
                        <div class="bls-modal-sig">
                        <input class="bls-modal-sig-input" type="text" placeholder="{{fullname}}" readonly>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="bls-modal-section">
            <label>
                <div class="bls-modal-check-wrapper">
                    <div class="bls-modal-check-left">
                        <input type="checkbox" class="bls-modal-checkbox" [(ngModel)]="iagree">
                    </div>
                    <div class="bls-modal-agree">
                        I agree to the terms of this attestation
                    </div>
                </div>
            </label>
            </div>
    
            <div class="bls-modal-section">
                <div class="bls-modal-button-wrapper">
                    <button type="button" class="btn btn-primary btn-cancel" (click)="onCancel()">
                        Maybe Later
                    </button>
                    <button id="btnSubmit" type="button" class="btn btn-primary btn-rename" (click)="onSubmit()" [disabled]='!iagree'>
                        Submit
                    </button>  
                </div>
            </div>
    
            <div class="bls-modal-section">
            &nbsp;
            </div>
    
        </div>
    
        <div class="bls-modal-section">
        &nbsp;
        </div>
    
    </div>
</p-dialog>
