import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-reports-provider-search',
  templateUrl: './reports-provider-search.component.html',
  styleUrls: ['./reports-provider-search.component.scss']
})
export class ReportsProviderSearchComponent implements OnInit {
  public providerName: string = '';
  public providerId: string = '';
  public providerTin: string = '';
  public nameLabel: string = '';
  public idLabel: string = '';
  public tinLabel: string = '';
  public searchLabel: string = '';
  @Input() dialogType: number;
  @Output() onProviderSearch: EventEmitter<any> = new EventEmitter(null);

  constructor() { }

  ngOnInit() {
    this.initializeLabel();
  }

  public findProviders() {
    var pname = this.providerName == '' ? null : this.providerName;
    var pid = this.providerId == '' ? null : this.providerId;
    var ptin = this.providerTin == '' ? null : this.providerTin;

    this.onProviderSearch.emit({ providerName: pname, providerId: pid, providerTin: ptin });
  }

  public resetSearchFilters() {
    this.providerName = '';
    this.providerId = '';
    this.providerTin = '';
  }

  public initializeLabel() {
    if(this.dialogType && this.dialogType == 2)
      this.nameLabel = "Tax Name or Office Name";
    else
      this.nameLabel = "Provider Name";    

    if(this.dialogType && this.dialogType == 2)
      this.idLabel = "TIN";
    else
      this.idLabel = "Provider ID";

    this.tinLabel = "Provider TIN";
    this.searchLabel = "Search for Provider";
  }

  public get requiredName() : boolean {
    if(this.dialogType == 2)
      return false;
    else
      return true;
  }

  public get disableProviderName(): boolean {
    return (this.providerId.trim() != '' || this.providerTin != '')      
  }
  public get disableProviderId(): boolean {
    return (this.providerName.trim() != '' || this.providerTin != '')      
  }  
  public get disableProviderTin(): boolean {
    return (this.providerName.trim() != '' || this.providerId != '')      
  } 
  
  public setLabel(name: string, id: string, tin:string, search: string ){
    this.nameLabel = name;
    this.idLabel = id;
    this.tinLabel = tin;
    this.searchLabel = search;
  }
}
