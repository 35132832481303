import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TabView } from 'primeng/tabview';
import { Subscription } from 'rxjs';
import { SessionService } from 'src/app/services/session.service';
import { UserAdminService } from 'src/app/services/user-admin.service';
import { UiUtility } from 'src/app/utils/ui-utility';

@Component({
  selector: 'app-attestation-provider-training',
  templateUrl: './attestation-provider-training.component.html',
  styleUrl: './attestation-provider-training.component.scss'
})
export class AttestationProviderTrainingComponent implements OnInit {
  private userCollectionSubscription: Subscription;
  public activeindex:number = 0;

  private tabs: any = [
    { index: 0, id: 'training-attestation-individual', label: 'Initial', enable: true },
    { index: 1, id: 'training-attestation-annual', label: 'Annual', enable: true }
  ];

  @ViewChild('tabView') tabview: TabView;
  
  constructor(
    private sessionService: SessionService,
    private userAdminService: UserAdminService,
    private elementRef: ElementRef
  ) {}
  
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.setPcpReportAttestationTabs();
  }

  public canViewTab(index: number) {
    const currentTab = this.tabs.find((tab: any) => tab.index === index);
    return currentTab ? currentTab.enable : false;
  }

  public getTabName(index: number) {
    const currentTab = this.tabs.find((tab: any) => tab.index === index);
    return currentTab ? currentTab.label : '';
  }

  public getTabIndex(name: string) {
    const currentTab = this.tabs.find((tab: any) => tab.label === name);
    return currentTab ? currentTab.index : 0;
  }

  public onTabChanged(e: any) {
    this.tabview.activeIndex = e.index === 1 ? 0 : 1;
  }

  public handleChange(selectedIndex:number, e:MouseEvent) {
    const currentIndex = this.tabview.activeIndex;
    e.stopPropagation();
    if (currentIndex == selectedIndex){
      return;
    }
  }

  private setPcpReportAttestationTabs() {
    const tabView = this.elementRef.nativeElement.querySelector('div.training-attestation-tabview-styles  ul.p-tabview-nav');
    UiUtility.SetTabsWidthDynamically(tabView, 0, 250);

    const tabElements = tabView.querySelectorAll('li');
    tabElements.forEach(element => {
      const currentChild = element.firstChild;
      const tabElement = (element.querySelector("a") as HTMLAnchorElement);
      if (tabElement) {
        const tabName = tabElement.innerText;
        const tabIndex = this.getTabIndex(tabName.trim());
  
        const newDiv = window.document.createElement("div");
        newDiv.addEventListener('click', this.handleChange.bind(this, tabIndex), false);
        newDiv.style.cursor = 'pointer';
        newDiv.appendChild(currentChild);
  
        element.firstChild.remove();
        element.appendChild(newDiv);
      }
    });
  }
}
