import { Component, OnInit, ViewChild, ElementRef, Renderer2, OnDestroy, AfterViewInit, Directive  } from '@angular/core';
import { UiUtility } from '../../utils/ui-utility';
import { MemberInfoService } from '../../services/member-info.service';
import { Member } from '../../services/models/member';
import { Subscription } from 'rxjs';
import { ControlContainer, NgForm } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';


@Component({
  selector: 'app-member-search',
  templateUrl: './member-search.component.html',
  styleUrls: ['./member-search.component.scss']
})
export class MemberSearchComponent implements OnInit,OnDestroy,AfterViewInit {

  public clearTrigger: boolean = false;
  public memberId: string;
  public firstName: string;
  public lastName: string;
  public dobMonth: number;
  public dobDay: number;
  public dobYear: number;
  public members: Member[];
  public currentYear: number;
  public citySelected: string = null;
  private memberServiceSubscription$: Subscription;
  navigationSubscription: Subscription;

  @ViewChild('submitButton') submitBtn: ElementRef;
  @ViewChild('searchIdForm') searchIdForm: NgForm;
  @ViewChild('searchNameForm') searchNameForm: NgForm;


  constructor(
    private memberService: MemberInfoService,
    private renderer: Renderer2,
    private router: Router) {}

  ngOnInit(){
    // subscribe to the router events. Store the subscription so we can unsubscribe later.
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component.
      if (e instanceof NavigationEnd) {
        this.initialiseInvites();
      }
    });
  }

  ngAfterViewInit(): void {
    this.setMemberSearchTabWidth();
  }

  ngOnDestroy(): void {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  initialiseInvites() {
    this.clearSearchByIdData();
    this.clearSearchByNameData();
    this.searchClear();
  }

  public doSearchById() {
    this.clearSearchByNameData();
    if (!this.memberId) {
      return;
    }
    this.memberServiceSubscription$ = this.memberService.getMembersById(this.memberId)
      .subscribe(
        result => {
          this.members = result;
          this.memberServiceSubscription$.unsubscribe();
          return result;
        },
        error => {
        }
      );
  }

  public doSearchByName() {
    this.clearSearchByIdData();
    if (!this.firstName || !this.lastName) {
      return;
    }
    // Month is zero based -- need to remove one for .NET/SQL date objects
    const dateOfBirth = new Date(+this.dobYear, +this.dobMonth - 1, +this.dobDay);
    this.memberService.getMembersByName(
      this.firstName,
      this.lastName,
      dateOfBirth,
      this.citySelected)
      .subscribe(result => {
        this.members = result;
      },
      error => {
      }
      );
  }

  public clearSearchByIdData() {
    this.searchIdForm.reset();
  }

  public clearSearchByNameData() {
    this.searchNameForm.reset();
  }

  public searchClear() {
    this.memberService.clearMemberList();
    this.clearTrigger = !this.clearTrigger;
    this.citySelected = null;
  }

  processKeyUp($event, current, next) {
    {
     var today = new Date();
     this.currentYear = today.getFullYear();
     const inputValue: number = Number(current.model);

     if (current.name === 'monthInput' && (inputValue >= 1 && inputValue <= 12)
        && (current.model.length === 2))  {
        const input = this.renderer.selectRootElement('#dayInput');
        input.focus();
      }
      else if (current.name === 'dayInput' && (inputValue >= 1 && inputValue <= 31)
        && (current.model.length === 2)) {
        this.renderer.selectRootElement('#yearInput').focus();
      }
      else if (current.name === 'yearInput' && (inputValue >= 1900
        && inputValue  <= this.currentYear)) {
        const sbutton = this.renderer.selectRootElement('#submitButton', true);
        sbutton.focus();

      }
    }
  }

  private setMemberSearchTabWidth() {
      const tabView = window.document.querySelector('div.member-search-tabview-styles ul.p-tabview-nav');
      UiUtility.SetTabsWidthDynamically(tabView, 50);
  }
  public onCitySelected(city: string) {
    this.citySelected = city;
  }
}


// @Directive({
//   selector: '[provide-parent-form]',
//   providers: [
//       {
//           provide: ControlContainer,
//           // tslint:disable-next-line: object-literal-shorthand
//           useFactory: (form: NgForm) => form,
//           deps: [NgForm]
//       }
//     ]
//   })
//   export class ProvideParentForm {}
