export class WellnessDetails {
    
    public grgr_ck: number;
    public wellnessYear: number;
    public pcpTIN: string;
    public memberCIN: string;
    public completionDate? : Date;
    public documentId: number;
    public pcpId : string;
    public pcpName: string;
    public memberName: string;
    public memberDOB: Date;
    public age: number;
    public healthNetworkPlanCode: string;
    public collectioName: string;
    public providerId: string;
    public providerName: string;
    public providerNPI: string;
    public address1: string;
    public address2: string;
    public address3: string;
    public city: string;
    public state: string;
    public zip: string;
    public groupId: string;
    public groupName: string;
    public groupTin: string;
    public groupNpi: string;
    public pymm: number;
    public cymm: number;
    public memMediCode: string;
    public cdpsModel: string;
    public capturedDiag: number;
    public recapturedDiag: number;
    public emClaims: number;
    public inpClaims: number;
    public totalClaims: number;
    public created: Date;
    public createdBy: string;
    public modified: Date;
    public modifiedBy: string;
    public notes: string;
    public medsId: string;
    public mbi: string;
}

export class WellnessDetails2 {
    public grgr_ck: number;
    public wellnessYear: number;
    public pcpTIN: string;
    public memberCIN: string;
    public completionDate? : Date;
    public documentId: number;
    public pcpId : string;
    public memberName: string;
    public memberDOB: Date;
    public age: number;
    public pymm: number;
    public cymm: number;
    public memMediCode: string;
    public cdpsModel: string;
    public capturedDiag: number;
    public recapturedDiag: number;
    public emClaims: number;
    public inpClaims: number;
    public totalClaims: number;
    public created: Date;
    public createdBy: string;
    public modified: Date;
    public modifiedBy: string;
    public notes: string;
    public medsId: string;
    public mbi: string;    
    public usedDocument:boolean;
    public additionalComments: string;    
    public dateOfService: Date;
}

export class WellnessDetails2Extend extends WellnessDetails2 {
    public diagnosisCaptured: WellnessDetailsDiagnosisCaptured[];
    public diagnosisReCaptured: WellnessDetailsDiagnosisReCaptured[];
    public screening: WellnessDetailsScreening[];
    public otherDiagnosis: WellnessDetailsOtherDiagnosis[];
}

export class WellnessDetailsDiagnosisCaptured {
    public documentId: number;
    public cin: string;
    public grgr_ck: number;
    public fromDt: Date;
    public diagID: number;
    public diagCode: string;
    public description: string;
    public isChronic: string;
    public suppress: string;
    public cdpsModel: string;
    public diagGroup: string;
    public severity: string;
    public lastCapturedDt: Date;
    public lastUpdatedDate: Date;
    public created: Date;
    public createdBy: string;
    public modified: Date;
    public modifiedBy: string;
    public status: string;
}

export class WellnessDetailsDiagnosisReCaptured extends  WellnessDetailsDiagnosisCaptured {
    public diagCaptured: string;
    public diagGroupSeverityCaptured: string;
    public lastYear: string;
    public twoYears: string;
}

export class WellnessDetailsScreening {
    public documentId: number;
    public memberCin: string;
    public screening: string;
    public lastUpdatedDate: Date;
    public created: Date;
    public createdBy: string;
    public modified: Date;
    public modifiedBy: string;
    public status: string;
    public completionDate: Date;
}

export class WellnessDetailsOtherDiagnosis {
    public documentId: number;
    public memberCin: string;
    public diagCode: string;
    public description: string;
    public created: Date;
    public createdBy: string;
    public modified: Date;
    public modifiedBy: string;
    public status: string;
    public dateOfService: Date;
    public index: number;
}
