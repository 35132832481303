<p-dialog header="Add Collection" [(visible)]="display" [modal]="true" (onHide)="onCancel()">
    <form name="frmAddCollection" id="frmAddCollection" (ngSubmit)="onCreate()">
        <div class="form-group">
            <div class="message">Enter the name for the new collection:</div>
            <input type="text" class="form-control" id="txtNewCollection" name="txtCollectionName" [(ngModel)]="collectionName" #collectionNameTxt="ngModel" />
        </div>
        <app-alert-danger *ngIf='validationError'
            [subjectMessage]="''"
            [contentMessage]="validationMessage">
        </app-alert-danger>
    </form>
    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-primary btn-create" (click)="onCreate()" [disabled]="collectionName.length === 0">Ok</button>
        <button type="button" class="btn btn-secondary btn-cancel" (click)="onCancel()">Cancel</button>
    </ng-template>
</p-dialog>
