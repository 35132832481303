import { Component, ViewChild } from '@angular/core';
import { ReportsRosterProviderComponent } from './reports-roster-provider/reports-roster-provider.component';
import { ReportsRosterMemberComponent } from './reports-roster-member/reports-roster-member.component';
import { ProviderDetail } from 'src/app/services/models/provider-detail';

@Component({
  selector: 'app-reports-provider-member-roster',
  templateUrl: './reports-provider-member-roster.component.html',
  styleUrl: './reports-provider-member-roster.component.scss'
})
export class ReportsProviderMemberRosterComponent {
  @ViewChild('providercomponent') providerComponent: ReportsRosterProviderComponent;
  @ViewChild('membercomponent') memberComponent: ReportsRosterMemberComponent;

  public displayProvider: boolean = true;
  public displayMember: boolean = false; 

  constructor() {

  }

  public onProviderSelected(val: ProviderDetail)  {
    

    this.displayProvider = false;
    this.displayMember = true;

    setTimeout(()=>{
      this.memberComponent.setProvider(val);
    }, 100);
  }

  public onBack() {
    this.displayMember =false;
    this.displayProvider = true;
  }
}
