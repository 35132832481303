// import { Component, OnInit } from '@angular/core';
import { EmailVerificationParameters } from '../services/models/email-verification-parameters';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import {Constants} from '../app.constants';
import { AuthenticationService, AuthenticationFactor, AuthenticationServiceStatus, AuthHelper } from '@caloptima/authentication';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  public componentUsing: string;
  public emailAddress: string;
  constructor(
      private oAuthService: OAuthService,
      private constants: Constants,
      private router: Router) { }

  ngOnInit() {
    const user = this.oAuthService.getIdentityClaims();
    const emailLiteral = 'email';
    if (user != null) {
      this.emailAddress = user[emailLiteral];
    }

    this.componentUsing = 'reset-password';
  }

    onPasswordSubmitChange(passwordChange): void {
    if (passwordChange.isSuccess) {
      window.location.href = '/';
    }
  }
}
