import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { PortalUser } from '../../../services/models/portal-user';
import { DialogModule } from 'primeng/dialog';
import { CollectionListItem } from '../../../services/models/collection-list-item';
import { UserAdminService } from '../../../services/user-admin.service';
import { UserExternalRequest } from '../../../services/models/requests/user-external-request';

@Component({
  selector: 'add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss']
})

export class AddUserDialogComponent implements OnInit {
  public userName: string;
  public user: PortalUser = null;
  public users: PortalUser[];
  public pageSize = 4;
  public pageNumber = 0;
  public totalNumberUsers = 0;
  public isTableReady = false;
  private isLoading: boolean = false;
  public selectedMessage = 'Message';
  @ViewChild('addUserTable') addUserTable;
  @Input() display: false;
  @Input() collectionItem: CollectionListItem;
  @Output() addUserStatus = new EventEmitter<PortalUser>();
  @Output() showStatus = new EventEmitter<boolean>();

  constructor(private userAdminService: UserAdminService) {
  }

  ngOnInit() {

  }

  public onClear() {
    this.userName = '';
    this.users = null;
    this.pageNumber = 0;
    this.isTableReady = false;
  }

  public onClose() {
    this.onClear();
    this.display = false;
    this.showStatus.emit(false);
  }

  public onAdd(row: PortalUser) {
    this.display = false;
    if (row) {
      this.user = row;
      // set collection ID
      this.user.providerCollectionID = this.collectionItem.id;
      // edit user picks up this user
      this.addUserStatus.emit(this.user);
    }
    this.showStatus.emit(false);
  }

  public onSearch(): void {
    this.setPage({ offset: 0 });
  }

  public onKeyDown(e): void {
    if (e.keyCode === 13 && this.canSearch()) {
      this.onSearch();
    }
  }

  public setPage(pageInfo) {
    this.isLoading = true;
    this.pageNumber = pageInfo.offset;
    const userRequest = new UserExternalRequest();
    userRequest.searchText = this.userName;
    userRequest.providerCollectionID = this.collectionItem.id;
    userRequest.pageNumber = this.pageNumber + 1;  // 1 based offeset SQL/NGX
    userRequest.pageSize = this.pageSize;
    this.userAdminService.getProviderUsers(userRequest)
    .subscribe(userData => {
      if (userData && userData.items) {
        // set to users
        this.users = userData.items;
        // set total number of users totalNumberUsers
        this.totalNumberUsers = userData.totalItems;
        this.isTableReady = true;
      }
      this.isLoading = false;
    });
  }

  public canSearch(): boolean {
    if (this.userName && this.userName.length > 1) {
     return true;
    }
    return false;
  }

  public checkSelectable(row, column, value) {
    const portalUser: PortalUser = row;
    return portalUser.isInCollection;
  }

  public disableRow(row) {
    const portalUser: PortalUser = row;
    const result = portalUser.isInCollection ? true : null;
    return result;
  }
}
