import { ReportType } from "../reportype";

export class ReportFilesForProviderRequest {

  constructor() {
    this.providerIds = [];
  }

  public providerIds: string[];
  public userName: string;
  public collectionId: number;
  public reportType: ReportType;
}
