import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PortalComponent } from './portal/portal.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: async (): Promise<typeof import("../app/portal/portal.module").PortalModule> => {
      const m = await import('./portal/portal.module');
      return m.PortalModule;
    }
  },
  {
    path: 'user',
    loadChildren: async (): Promise<typeof import("../app/user/user.module").UserModule> => {
      const m = await import('./user/user.module');
      return m.UserModule;
    }
  },
  { 
    path: '**', component: PortalComponent
  }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        // enableTracing: true,
        useHash: true,
        onSameUrlNavigation: 'reload'        
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
