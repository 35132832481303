
import { Component, OnInit,ViewChild, Inject } from '@angular/core';
import { UserProfile } from '@caloptima/authentication';
import { SelectItem } from 'primeng/api';
import { DropDownObject } from '../../../services/models/drop-down-object.interface';
import { ReportsService } from '../../../services/reports.service';
import { SessionService } from '../../../services/session.service';
import { saveAs } from 'file-saver';
import { DatePipe, DOCUMENT } from '@angular/common';
import { ReportType } from '../../../services/models/reportype';
import { ReportsMemberSearchComponent } from '../../../app-components/reports-member-search/reports-member-search.component';
import { UiUtility } from '../../../utils/ui-utility';
import { ReportMemberDetails } from '../../../services/models/reportmemberdetails';

@Component({
  selector: 'app-pcp-attestation-member',
  templateUrl: './pcp-attestation-member.component.html',
  styleUrls: ['./pcp-attestation-member.component.scss']
})
export class PcpAttestationMemberComponent implements OnInit {

  public userProfile: UserProfile;
  public members:ReportMemberDetails[] = [];
  public displayMembers:ReportMemberDetails[] = [];
  public selectedCin: string;
  private sortColumn: string;
  private sortAscending: boolean;
  public totalRecords: number;
  public fileList: SelectItem[];
  public selectedFile: DropDownObject;
  public searchCin: string;
  public searchName: string;
  public collectionId: number;
  public calProviderId: string;

  @ViewChild('memberSearch') memberSearch: ReportsMemberSearchComponent;

  constructor(
    private reportsService: ReportsService,
    private sessionService: SessionService,
    private datePipe: DatePipe,
    @Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit() {
    this.collectionId = this.sessionService.currentPermission.providerCollectionID;

    this.initialize();
  }

  public setProviderId(providerId: string) {
    this.calProviderId = providerId;
    this.initialize(true);
  }

  public initialize(resetComponent = false) {
    this.sortColumn = 'memberName';
    this.sortAscending = true;
    this.selectedFile = null;
    this.searchCin = '';
    this.searchName = '';
    this.selectedCin = '';
    this.displayMembers = [];

    if (resetComponent) {
      this.memberSearch.resetSearchFilters();
    }

    if(!UiUtility.isNullUndefinedOrEmpty(this.calProviderId)) {
      this.reportsService.getMembersForReports(this.collectionId, this.calProviderId, ReportType.PCPDocAttest).subscribe(result=>{

        this.members = result;

        this.totalRecords = this.members.length;

        if(this.members)
          this.displayMembers = this.members.slice();

        let param: any = {
          "field": "memberName",
          "order": 1
        }
        this.onSort(param)
      });
    }
  }

  public sortClass(column: string): string {
    let cls: string;
    if (column == this.sortColumn) {
      cls = this.sortAscending ? "fa fa-chevron-up" : "fa fa-chevron-down";
    }
    else {
      cls = "fa fa-chevron-up";
    }
    return cls;
  }

  public onPage(e: any) {
    let s = JSON.stringify(e);
  }

  public onSort(e: any): void {
    this.displayMembers.sort((data1, data2) => {
      let value1 = data1[e.field];
      let value2 = data2[e.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (e.order * result);
    });
    this.sortAscending = e.order == 1;
    this.sortColumn = e.field;
  }

  public onMemberSelected(member: ReportMemberDetails): void {
    this.selectedCin = member.cin;    
    this.fileList = [];
    this.selectedFile = null;

    const providerId = UiUtility.isNullUndefinedOrEmpty(this.calProviderId) ? member.providerId : this.calProviderId;

    this.reportsService.getReportFilesForMember(providerId, this.selectedCin, ReportType.PCPDocAttest).subscribe(result=>{
      result.forEach(x=>
        {
          this.fileList.push(
            {
              label: this.datePipe.transform(x.yearMonth, 'yyyy-MM'),
              value: x
            });
        });

      if(this.fileList.length > 0) {
        this.selectedFile = this.fileList[0].value;

        UiUtility.scrollDown(this.document);
      }
    });
  }

  public get displayText(): string {
    if(this.selectedCin) {

      let memeberDetail: ReportMemberDetails = this.members.find(x=> x.cin === this.selectedCin);

      if(memeberDetail) {
        let s = memeberDetail.memberName;
        return s;
      }
      else {
        return ""
      }
    }
    else {
      return "";
    }
  }

  public get enableBuildReport() : boolean {

    return this.selectedCin !== '' && this.selectedFile !== null
  }

  public onBuildReport() : void {

    var temp:any = this.selectedFile;
    var fileName = temp.fileName;

    this.reportsService.downloadReportFileForMember(this.calProviderId, this.selectedCin, fileName, ReportType.PCPDocAttest).subscribe(result=>{
      this.saveAsFile(result, fileName + ".pdf", 'pdf');
    });
  }

  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob([buffer], { type: fileType });
    saveAs(data, fileName);
  }

  // public onKeyPress(e:any) : void {
  //   if (e.keyCode === 13) {
  //     this.onMemberSearch();
  //   }
  // }

  public onMemberSearch(search) : void {

    this.selectedCin = '';
    this.fileList = [];
    this.selectedFile = null;

    if(!UiUtility.isNullUndefinedOrEmpty(this.calProviderId)) {
      if(search.memberName === '' && search.memberId === '') {
        this.displayMembers = this.members.slice();
      }
      else {
        this.displayMembers = [];
        this.members.forEach(x => {

          let include: boolean = false;

          if(search.memberName !== '') {
            include = x.memberName.trim().toUpperCase().includes(search.memberName.trim().toUpperCase());
          }
          if(!include && search.memberId !== '') {
            include = x.cin == search.memberId;
          }
          if(!include && search.memberId !== '') {
            include = x.medsId == search.memberId;
          }          
          if(!include && search.memberId !== '') {
            include = x.mbi == search.memberId;
          }                    
          if(include){
            this.displayMembers.push(x);
          }
        });
      }
    }
    else {

      this.reportsService.searchMembersForReports(ReportType.PCPDocAttest, this.collectionId, search.memberName, search.memberId).subscribe(result=>{

        this.members = result;

        this.totalRecords = this.members.length;

        if(this.members)
          this.displayMembers = this.members.slice();

          let param: any = {
            "field": "memberName",
            "order": 1
          }
          this.onSort(param)
      });

    }
  }

}
