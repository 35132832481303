import { Component, OnInit, Input, Output, EventEmitter, Inject, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit, AfterViewInit {

  @Input() headerMessage: string;
  @Input() contentMessage: string;
  @Input() type: string;
  @Input() width: string;

  iconClass: string = 'fa fa-exclamation-triangle danger-color';

  @Output() confirmClose = new EventEmitter<boolean>();

  public get display(): boolean {
    return true;
  }

  public set display(value: boolean) {
    this.onClose();
  }

  constructor(@Inject(DOCUMENT) private document: Document) { }

  ngAfterViewInit(): void {
    this.setContentMessages();
  }

  ngOnInit() {

    if (this.type === 'danger') {
      this.iconClass = 'fa fa-exclamation-triangle danger-color';
    }
    else if (this.type === 'info') {
      this.iconClass = 'fa fa-info-circle info-color';
    }
    else if (this.type === 'success') {
      this.iconClass = 'fa fa-check-circle success-color';
    }
    else if (this.type === 'warning') {
      this.iconClass = 'fa fa-exclamation-triangle warning-color';
    }

    if (!this.width) {
      this.width = "400px";
    }
  }

  public onClose() {
    this.confirmClose.emit(false);
  }

  private setContentMessages() {
    this.document.getElementById('confirm-message').innerHTML = this.contentMessage
  }

}
