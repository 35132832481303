import { Injectable } from '@angular/core';
import { AppConfig } from '@caloptima/portal-foundation';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class Constants {
  private calOptimaOrgUrlSubject$ = new BehaviorSubject<string>(null);
  private CalOptimaOrgUrl: string;

  public EmptyString: string = '';
  public SpaceString: string = ' ';
  public LoginUrl: string = 'login';
  public LogoutUrl: string = 'logout';
  public UserProfileUrl: string = 'user/profile';
  public ResetPasswordUrl: string = 'resetpassword';
  public Scope: string = 'openid profile email offline_access gallerymanagement';
  public AuthorizeUserComponent: string = 'login';
  public Oidc: boolean = true;
  //public LogoUrl: string = './assets/images/calop-square-logo.png';
  public LogoUrl: string = './assets/images/CalOptima Logo - Provider Portal Reverse.png';
  public PortalUrl: string = './assets/images/provider-portal-icon.png';
  public ReferralAuthorizationRequestFormUrl = "/ForProviders/ClaimsAndEligibility/PriorAuthorizations.aspx";
  public PortalAccessAgreementPath = "/en/WebsitePrivacyPolicy.aspx";
  public PortalAccessPolicyPath = "/~/media/Files/ProviderPortal/508/2021-08_IS1600Policy_508.ashx";
  public PortalUserAgreement = "/~/media/Files/ProviderPortal/2021_ProviderPortal_UserAgreement.ashx";
  public static MemberToBeRedirectedText = "MEMBER TO BE REDIRECTED : ";
  public PortalAccessAgreementUrl = "";
  public PortalAccessPolicyUrl = "";

  public UserTypeEnterpriseAdmin: string = 'Enterprise Admin';
  public UserTypeEnterpriseUser: string = 'Internal User';
  public UserTypeLocalAdmin: string = 'Local Office Admin';
  public UserTypeExternal: string = 'External';
  public TokenExpirationWarningInSeconds: number = 10;
  public DetailRowsPerPage: number = 10;
  public ListRowsPerPage: number = 20;
  public PasswordMinLength: number = 8;
  public PasswordMustContain: number = 4;
  public AddressTypeR: string = 'R01';
  public AddressTypeS: string = 'S01';
  public ReportMaxRows: number = 100000000;
  public stringSeparator: string = '; '

  //provider settings
  public UserListVerificationDueDays: string = 'UserListVerificationDueDays';
  public UserListVerificationWarningDays: string = 'UserListVerificationWarningDays';
  public UserListVerificationDueDaysDefaultValue: number = 180;
  public UserListVerificationWarningDaysDefaultValue: number = 165;

  public CalOptimaOrgUrlSubject(): Observable<string> {
    return this.calOptimaOrgUrlSubject$.asObservable();
  }

  constructor(private appConfig: AppConfig) {
     this.appConfig.subscribe(config => {
       this.CalOptimaOrgUrl = config.getConfig('CaloptimaBaseUrl');
       this.calOptimaOrgUrlSubject$.next(this.CalOptimaOrgUrl);
    });
  }
}
