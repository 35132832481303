import { MemberEligibilityStatus } from './member-eligibility-status';
import { Address } from '@caloptima/portal-foundation';

export class MemberEligibility {
  cin: string;
  firstName: string;
  lastName: string;
  homePhone: string;
  mobilePhone: string;
  email: string;
  dateOfBirth: Date;
  gender: string;
  writtenLanguage: string;
  spokenLanguage: string;
  // change update server side
  lineOfBusinessDesc: string;
  // change update server side
  healthNetworkPlanCode: string;
  healthNetworkPlanDesc: string;
  healthNetworkEligibilityFlag: string;
  healthNetworkPlanEffectiveDate: Date;
  healthNetworkPlanTermDate: Date;
  otherInsuranceId: string;
  otherInsuranceName: string;
  otherInsuranceEffDate: Date;
  otherInsuranceTermDate: Date;
  // may not need if calculated on server "eligibilityStatus"
  shareOfCost: string;
  pcpId: string;
  // change update server side
  pcpName: string;
  // change update server side
  pcpEffDate: Date;
  PcpTermDate: Date;
  aidCode: string;
  medicaidDesc: string;
  medicaidTermDate: string;
  medicaidCatergoryId: string;
  medicaidCatergoryName: string;
  ccsInd: boolean;
  ccsEffectiveDate: Date;
  ccsTermDate: Date;
  // {Status Icon} – you will have to derive based on HealthNetworkEligibilityFlag/HealthNetworkEligibilityTermDate AND MemberShareOfCost
  eligibilityStatus: MemberEligibilityStatus;
  address: Address;
}
