import { Component, OnInit } from '@angular/core';
import { UiUtility } from '../../utils/ui-utility';

@Component({
  selector: 'app-unsupported-browser',
  templateUrl: './unsupported-browser.component.html',
  styleUrls: ['./unsupported-browser.component.scss']
})
export class UnsupportedBrowserComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    var browser = UiUtility.getSupportedBrowserName();
    if (browser !== 'other') {
      window.location.href = "/";
    }
  }
}
