import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-user-verification-dialog',
  templateUrl: './user-verification-dialog.component.html',
  styleUrls: ['./user-verification-dialog.component.scss']
})
export class UserVerificationDialogComponent implements OnInit {

  @Input() display:boolean = true;
  @Input() buttonText: string='';
  @Input() headerText: string='';
  @Input() contentText: string='';
  @Input() isLOA:boolean = false;
  @Input() verificationIcon: string;

  @Output() onUserVerificationAction: EventEmitter<boolean> = new EventEmitter<false>();
  constructor() { }

  ngOnInit() {
  }

  public onVerificationAction(){
    this.onUserVerificationAction.emit(this.isLOA);
  }

}
