<p-dialog #dlgAddProviders class="add-provider" 
header="{{header}}" 
[(visible)]="display" 
[modal]="true" 
[resizable]="true"
[style]="{width: '800px', minWidth: '300px'}"
(onHide)="onCancel()">
  <div class="form-group header-data">
    <div class="row">
      <div class="col-md-12 left-align">
        {{searchLabel}}
      </div>
    </div>
    <div class="row">
      <div class="col-md-10">
        <input type="text" class="form-control" id="txtProviderName" (keydown)="onKeyDown ($event)"
          [(ngModel)]="providerName" #providerNameTxt="ngModel" maxlength="{{searchLength}}" pattern="{{searchPattern}}"/>
      </div>
      <div class="col-md-2">
        <button type="button" class="btn btn-outline-search" id="btnProviderSearch" (click)="onSearch()" label="Search"
          [disabled]="!canEnableSearch">Search</button>
      </div>
    </div>
  </div>
  <div *ngIf='collectionType === 3'>
    <p-table class="table-data" #addProviderList [value]="providers" styleClass="add-provider-grid" [paginator]="false"
      selectionMode="multiple" [(selection)]="selected" [scrollable]="true" scrollHeight="200px">
      <ng-template pTemplate="header">
        <tr>
          <th class="check-column">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th class="name-column">Name</th>
          <th class="tax-column">TIN</th>
          <th class="group-column">Group ID</th>
          <th class="provider-column">Provider ID</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr [pSelectableRow]="row" *ngIf="row.collectionID == null || row.collectionID == collectionId">
          <td class="check-column">
            <p-tableCheckbox [value]="row" (click)="$event.stopPropagation()"></p-tableCheckbox>
          </td>
          <td class="name-column">
            <div class="cell-class">{{row.name}}</div>
          </td>
          <td class="tax-column">
            <div class="cell-class">{{row.taxId}}</div>
          </td>
          <td class="group-column">
            <div class="cell-class">{{row.groupId}}</div>
          </td>
          <td class="provider-column">
            <div class="cell-class">{{row.calProviderID}}</div>
          </td>
        </tr>
        <tr *ngIf="row.collectionID != null && row.collectionID != collectionId">
          <td class="check-column">
          </td>
          <td class="name-column">
            <div class="cell-class disabled">{{row.name}}</div>
          </td>
          <td class="tax-column">
            <div class="cell-class disabled">{{row.taxId}}</div>
          </td>
          <td class="group-column">
            <div class="cell-class disabled">{{row.groupId}}</div>
          </td>
          <td class="provider-column">
            <div class="cell-class disabled">{{row.calProviderID}}</div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div *ngIf='collectionType === 1 || collectionType === 5'>
    <p-table class="table-data" #addGroups [value]="providerGroups" styleClass="add-provider-grid" [paginator]="false"
      selectionMode="multiple" [(selection)]="selectedProviderGroups" [scrollable]="true" scrollHeight="315px">
      <ng-template pTemplate="header">
        <tr>
          <th class="check-column">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th class="name-column">Group Name</th>
          <th class="tax-column">Group Id</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr [pSelectableRow]="row" *ngIf="row.providerCollectionId == null || row.providerCollectionId == 0 || row.providerCollectionId == collectionId">
          <td class="check-column">
            <p-tableCheckbox [value]="row" (click)="$event.stopPropagation()"></p-tableCheckbox>
          </td>
          <td class="name-column">
            <div class="cell-class">{{row.providerGroupName}}</div>
          </td>
          <td class="tax-column">
            <div class="cell-class">{{row.providerGroupId}}</div>
          </td>
        </tr>
        <tr *ngIf="row.providerCollectionId != null && row.providerCollectionId != 0 && row.providerCollectionId != collectionId">
          <td class="check-column">
          </td>
          <td class="name-column">
            <div class="cell-class disabled">{{row.providerGroupId}}</div>
          </td>
          <td class="tax-column">
            <div class="cell-class disabled">{{row.providerGroupName}}</div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div *ngIf='collectionType === 2 || collectionType === 4'>
    <p-table class="table-data" #addTaxes [value]="providerTaxes" styleClass="add-provider-grid" [paginator]="false"
      selectionMode="multiple" [(selection)]="selectedProviderTaxes" [scrollable]="true" scrollHeight="315px">
      <ng-template pTemplate="header">
        <tr>
          <th class="check-column">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th class="name-column">Name</th>
          <th class="tax-column">TIN</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr [pSelectableRow]="row" *ngIf="row.providerCollectionId == null || row.providerCollectionId == 0 || row.providerCollectionId == collectionId">
          <td class="check-column">
            <p-tableCheckbox [value]="row" (click)="$event.stopPropagation()"></p-tableCheckbox>
          </td>
          <td class="name-column">
            <div class="cell-class">{{row.providerTaxName}}</div>
          </td>
          <td class="tax-column">
            <div class="cell-class">{{row.providerTaxId}}</div>
          </td>
        </tr>
        <tr *ngIf="row.providerCollectionId != null && row.providerCollectionId != 0 && row.providerCollectionId != collectionId">
          <td class="check-column">
          </td>
          <td class="name-column">
            <div class="cell-class disabled">{{row.providerTaxName}}</div>
          </td>
          <td class="tax-column">
            <div class="cell-class disabled">{{row.providerTaxId}}</div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>  
  <div>
    <app-alert-danger *ngIf='validationError'
    [subjectMessage]="''"
    [contentMessage]="validationMessage">
    </app-alert-danger>  
  </div>
  <p-footer class="add-provider-footer">
    <button type="button" class="btn btn-primary" [disabled]="!canEnableAdd" (click)="onAddProviders()"
      label="Add">Add</button>
  </p-footer>
</p-dialog>
