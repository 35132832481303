import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { PhonePipe } from '@caloptima/portal-ui-components';

import { MemberInfoService } from '../../services/member-info.service';
import { Member } from '../../services/models/member';
import { MemberStatusMap } from '../../services/models/member-status-map';


@Component({
  selector: 'app-member-demographics',
  templateUrl: './member-demographics.component.html',
  styleUrls: ['./member-demographics.component.scss'],
})
export class MemberDemographicsComponent implements OnInit, OnDestroy {
  public selectedMember$: Subscription;
  public member: Member;
  public dateOfBirth: string;

  constructor(private memberService: MemberInfoService) { }

  ngOnInit() {
    // TODO: refactor to use subscription directly with async keyword
    this.selectedMember$ = this.memberService.selectedMemberChanged$.subscribe(data => {
      this.member = data;
      this.dateOfBirth = this.memberService.getMemberDateAndAge(this.member.dateOfBirth, '/');
    });
  }

  ngOnDestroy() {
    if (this.selectedMember$) {
      this.selectedMember$.unsubscribe();
    }
  }

  // Get the class(es) to display for the status icon
  public mapStatusToIcon(): string {
    if (this.member) {
      return MemberStatusMap.mapStatustoIcon(this.member.eligibilityStatus);
    }

    return '';
  }

  // Get the string to display for the tooltip (title) for the user hovers over the status icon
  public mapStatusToTooltip(): string {
    if (this.member) {
      return MemberStatusMap.mapStatusToSelectItem(this.member.eligibilityStatus).label;
    }

    return '';
  }

}
