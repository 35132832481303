import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-collection-detail-providers-search',
  templateUrl: './collection-detail-providers-search.component.html',
  styleUrl: './collection-detail-providers-search.component.scss'
})
export class CollectionDetailProvidersSearchComponent {
  public providerName: string = '';
  public providerId: string = '';
  public providerTin: string = '';

  @Output() onCollectionProviderSearch: EventEmitter<any> = new EventEmitter(null);

  constructor() {}
  
  public searchProviders() {
    this.onCollectionProviderSearch.emit({ providerId: this.providerId, providerName: this.providerName, providerTin: this.providerTin });
  }

  public resetProviderSearch() {
    this.providerId = '';
    this.providerName = '';
    this.providerTin = '';

    this.onCollectionProviderSearch.emit({ providerId: '', providerName: '', providerTin: '' });
  }
}
