import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import moment from 'moment';

import { HttpResponseHelper, AppConfig } from '@caloptima/portal-foundation';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, BehaviorSubject, Subject, of, pipe } from 'rxjs';
import { Member } from './models/member';
import { PortalConfig } from '../portal-config';
import { MemberInfoRequest } from './models/requests/member-info-request';
import { distinctUntilChanged, switchMap, map, catchError } from 'rxjs/operators';
import { MemberRequest } from './models/member-request';
import { MemberEligibility } from './models/member-eligibility';
import { MemberReferral } from './models/member-referral';
import { Medication } from './models/medication';
import { Claim } from './models/claim';
import { Lab } from './models/lab';
import { Condition } from './models/condition';
import { SessionService } from '../services/session.service';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})

export class MemberInfoService {
  private baseProviderMemberServiceUrl: string;
  private getMembersByIdServiceUrl: string;
  private getMemberInfoByIdServiceUrl: string;
  private getMembersByNameServiceUrl: string;
  private getMemberEligibilityUrl: string;
  private getMemberEligibilityHistoryUrl: string;
  private getMemberReferralUrl: string;
  private getMemberClaimUrl: string;
  private getMemberMedicationsUrl: string;
  private getMemberLabsUrl: string;
  private getMemberConditionsUrl: string;
  private selectedMember: Member = null;
  private isInternalUser: boolean;
  private currentUser: string;
  private selectedMemberSubject$ = new BehaviorSubject<Member>(this.selectedMember);
  public selectedMemberChanged$ = this.selectedMemberSubject$.asObservable()
    .pipe(
      distinctUntilChanged()
    );
  private memberRequest: MemberRequest = { members: [], statusMessage: 'Search For Member' };
  private memberListSubject$ = new BehaviorSubject<MemberRequest>(this.memberRequest);
  public memberListChanged$ = this.memberListSubject$.asObservable()
    .pipe(
      distinctUntilChanged()
    );

  constructor(
    private authService: OAuthService,
    private sessionService: SessionService,
    private http: HttpClient,
    private portalConfig: PortalConfig,
    private appConfig: AppConfig,
    private datePipe: DatePipe) {

    this.baseProviderMemberServiceUrl = appConfig.getConfig('BaseProviderServicesApiUrl');
    if (this.baseProviderMemberServiceUrl == null) {
      let config$ = appConfig.subscribe(() => {
        this.baseProviderMemberServiceUrl = appConfig.getConfig('BaseProviderServicesApiUrl');
        this.checkUrls();
        config$.unsubscribe();
      });
    }

    this.isInternalUser = this.sessionService.isEnterpriseAdmin || this.sessionService.isEnterpriseUser;
    const claims = this.authService.getIdentityClaims();
    if (claims) {
      this.currentUser = claims['sub'];
    }

  }

  public genderDisplayForMember(member: Member) {
    if (member.gender === 'M') {
      member.genderDisplay = 'Male';
    }
    if (member.gender === 'F') {
      member.genderDisplay = 'Female';
    }
    return member;
  }

  public genderDisplay(memberList: Member[]) {

    for (let member of memberList) {
      this.genderDisplayForMember(member);
    }

    return memberList;
  }

  private transformDate(date: Date, delimiter: string = null) {
    if (!delimiter) {
      delimiter = '.';
    }
    return this.datePipe.transform(date, `MM${delimiter}dd${delimiter}yyyy`);
  }

  public getAge(date) {
    return moment().diff(date, 'years');
  }

  public getMemberDateAndAge(birthDate: Date, delimiter: string = null): string {
    const age = this.getAge(birthDate);
    const result = `${this.transformDate(birthDate, delimiter)} (Age ${age})`;

    return result;
  }

  public getStringDate(date: Date, delimiter: string = null): string {
    return this.transformDate(date, delimiter);
  }

  public clearSelectedMember() {
    this.selectedMember = null;
    this.selectedMemberSubject$.next(this.selectedMember);
  }

  public setSelectMember(member: Member) {
    this.selectedMember = member;
    this.selectedMemberSubject$.next(member);
  }

  private checkUrls(): void {
    if (this.getMembersByIdServiceUrl == null) {
      const baseUrl = this.baseProviderMemberServiceUrl + 'api/member/';
      const claimBaseUrl = this.baseProviderMemberServiceUrl + 'api/claim/';
      const referralBaseUrl = this.baseProviderMemberServiceUrl + 'api/referral/';
      this.getMembersByIdServiceUrl = baseUrl + 'getMembersById';
      this.getMemberInfoByIdServiceUrl = baseUrl + 'getMemberInfoById';
      this.getMembersByNameServiceUrl = baseUrl + 'getMembersByName';
      this.getMemberEligibilityUrl = baseUrl + 'getMemberEligibility';
      this.getMemberEligibilityHistoryUrl = baseUrl + 'getMemberEligibilityHistory';
      this.getMemberReferralUrl = referralBaseUrl + 'getMemberReferral';
      this.getMemberClaimUrl = claimBaseUrl + 'getMemberClaim';
      this.getMemberMedicationsUrl = baseUrl + 'getMemberMedications';
      this.getMemberLabsUrl = baseUrl + 'getMemberLabs';
      this.getMemberConditionsUrl = baseUrl + 'getMemberConditions';
    }
  }

  public setMemberList(request: MemberRequest): void {
    this.memberRequest = request;
    this.memberListSubject$.next(request);
  }

  public clearMemberList(message: string = null) {
    const request: MemberRequest = { members: [], statusMessage: message ? message : 'Search For Member' };
    this.setMemberList(request);
  }

  public getMemberEligibility(memeCk: number): Observable<MemberEligibility> {
    try {
      const request = new MemberInfoRequest();
      request.userName = this.currentUser;
      request.memeCk = memeCk;

      return this.http.post<MemberEligibility>(this.getMemberEligibilityUrl, request, httpOptions)
        .pipe(
          map(res => {
            return res;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public getMemberEligibilityHistory(memeCk: number): Observable<MemberEligibility[]> {
    try {
      const request = new MemberInfoRequest();
      request.userName = this.currentUser;
      request.memeCk = memeCk;

      return this.http.post<MemberEligibility[]>(this.getMemberEligibilityHistoryUrl, request, httpOptions)
        .pipe(
          map(res => {
            return res;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public getMemberReferral(cin: string, grgrCk: number, pcpId: string): Observable<MemberReferral[]> {
    try {
      const request = new MemberInfoRequest();
      request.memberId = cin;
      request.grgrCk = grgrCk;
      request.isInternalUser = this.isInternalUser;
      request.userName = this.currentUser;
      request.pcpId = pcpId;
      request.providerCollectionId = this.sessionService.currentPermission.providerCollectionID;

      return this.http.post<MemberReferral[]>(this.getMemberReferralUrl, request, httpOptions)
        .pipe(
          map(res => {
            return res;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public getMemberClaim(memeCk: number, pcpId: string): Observable<Claim[]> {
    try {
      const request = new MemberInfoRequest();
      request.memeCk = memeCk;
      request.isInternalUser = this.isInternalUser;
      request.userName = this.currentUser;
      request.pcpId = pcpId;
      request.providerCollectionId = this.sessionService.currentPermission.providerCollectionID;

      return this.http.post<Claim[]>(this.getMemberClaimUrl, request, httpOptions)
        .pipe(
          map(data => {
            const sortedData = data.sort((a, b): number => {
              if (a.dateOfService < b.dateOfService) {
                return 1;
              }
              if (a.dateOfService > b.dateOfService) {
                return -1;
              }
              return 0;
            });
            return sortedData;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public getMembersByIdQuickSearch(memberId: string): Observable<Member[]> {
    try {
      this.checkUrls();
      const request = new MemberInfoRequest();
      request.memberId = memberId;
      request.isInternalUser = this.isInternalUser;
      request.userName = this.currentUser;
      request.providerCollectionId = this.sessionService.currentPermission.providerCollectionID;

      // call webapi for list of members
      return this.http
        .post<Member[]>(this.getMembersByIdServiceUrl, request, httpOptions)
        .pipe(
          map(res => {
            const memberRequest = new MemberRequest();
            memberRequest.members = res;
            if (memberRequest.members.length > 0) {
              this.genderDisplay(memberRequest.members);
              this.clearMemberList();
              this.setMemberList(memberRequest);
            }
            return memberRequest.members;
          }),
          catchError(error => {
            // capture exception for too many results translate to proper exception message
            if (error && error.status && error.status === 416) {
              this.clearMemberList('Too many members found.  Please refine your search.')
              return of(error);
            }
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public getMembersById(memberId: string): Observable<Member[]> {
    try {
      this.clearMemberList();
      this.checkUrls();
      const request = new MemberInfoRequest();
      request.memberId = memberId;
      request.isInternalUser = this.isInternalUser;
      request.userName = this.currentUser;
      request.providerCollectionId = this.sessionService.currentPermission.providerCollectionID;

      // call webapi for list of members
      return this.http
        .post<Member[]>(this.getMembersByIdServiceUrl, request, httpOptions)
        .pipe(
          map(res => {
            const memberRequest = new MemberRequest();
            memberRequest.members = res;
            if (memberRequest.members.length <= 0) {
              memberRequest.statusMessage = 'No Results Found';
            }
            else {
              memberRequest.statusMessage = '';
              this.genderDisplay(memberRequest.members);
            }

            this.setMemberList(memberRequest);
            return res;
          }),
          catchError(error => {
            // capture exception for too many results translate to proper exception message
            if (error && error.status && error.status === 416) {
              this.clearMemberList('Too many members found.  Please refine your search.')
              return of(error);
            }
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public getMembersByName(firstName: string, lastName: string, dateOfBirth: Date, city: string): Observable<Array<Member>> {
    try {
      this.clearMemberList();
      this.checkUrls();
      const request: MemberInfoRequest = {
        memberId: '',
        firstName,
        lastName,
        dateOfBirth,
        memeCk: 0,
        grgrCk: 0,
        dateOfService: null,
        isInternalUser: this.isInternalUser,
        userName : this.currentUser,
        pcpId: null,
        city: city,
        providerCollectionId: this.sessionService.currentPermission.providerCollectionID,
        context: 0
      };

      // call webapi for list of members
      return this.http
        .post<Member[]>(this.getMembersByNameServiceUrl, request, httpOptions)
        .pipe(
          map(res => {
            const memberRequest = new MemberRequest();
            memberRequest.members = res;
            if (memberRequest.members.length <= 0) {
              memberRequest.statusMessage = 'No Results Found';
            }
            else {
              memberRequest.statusMessage = '';
              this.genderDisplay(memberRequest.members);
            }

            this.setMemberList(memberRequest);
            return res;
          }),
          catchError(error => {
            // capture exception for too many results translate to proper exception message
            if (error && error.status && error.status === 416) {
              this.clearMemberList('Too many members found.  Please refine your search.')
              return of(error);
            }
            return HttpResponseHelper.handleError(error);
          })
        );
      // capture exception for too many results translate to proper exception message
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public getMemberLabs(memberId: string, grgrCk: number): Observable<Lab[]> {
    try {
      const request = new MemberInfoRequest();
      request.memberId = memberId;
      request.grgrCk = grgrCk;
      request.userName = this.currentUser;
      request.isInternalUser = this.isInternalUser;
      request.providerCollectionId = this.sessionService.currentPermission.providerCollectionID;

      return this.http.post<Lab[]>(this.getMemberLabsUrl, request, httpOptions)
        .pipe(
          map(res => {
            return res;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public getMemberConditions(memberId: string, grgrCk: number): Observable<Condition[]> {
    try {
      const request = new MemberInfoRequest();
      request.memberId = memberId;
      request.grgrCk = grgrCk;
      request.userName = this.currentUser;
      request.isInternalUser = this.isInternalUser;
      request.providerCollectionId = this.sessionService.currentPermission.providerCollectionID;

      return this.http.post<Condition[]>(this.getMemberConditionsUrl, request, httpOptions)
        .pipe(
          map(res => {
            return res;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public getMemberMedications(memberId: string, grgrCk: number): Observable<Medication[]> {
    try {
      const request = new MemberInfoRequest();
      request.grgrCk = grgrCk;
      request.memberId = memberId;
      request.userName = this.currentUser;
      request.isInternalUser = this.isInternalUser;
      request.providerCollectionId = this.sessionService.currentPermission.providerCollectionID;

      return this.http
        .post<Medication[]>(this.getMemberMedicationsUrl, request, httpOptions)
        .pipe(
          map(data => {
            if (data != null) {
              const sortedData = data.sort((a, b): number => {
                if (a.fillDate < b.fillDate) {
                  return 1;
                }
                if (a.fillDate > b.fillDate) {
                  return -1;
                }
                return 0;
              });

              return sortedData;
          }
            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    } catch (ex) {
      // log error
      throw ex;
    }
  }

  public getMemberInfoById(memberId: string, dateofService: Date, grgrck: number, memberRequestContext: number = 0): Observable<Member[]> {
    try {
      this.checkUrls();
      const request = new MemberInfoRequest();
      request.memberId = memberId;
      request.dateOfService = dateofService;
      request.grgrCk = grgrck;
      request.isInternalUser = this.isInternalUser;
      request.userName = this.currentUser;
      request.providerCollectionId = this.sessionService.currentPermission.providerCollectionID;
      request.context = memberRequestContext;

      return this.http
        .post<Member[]>(this.getMemberInfoByIdServiceUrl, request, httpOptions)
        .pipe(
          map(res => {
            return res;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      throw ex;
    }
  }

  public getGenderText(gender: string): string {
    if (gender === 'M') {
      return 'Male';
    }
    else if (gender === 'F') {
      return 'Female';
    }
    return 'N/A';
  }
}
