<p-dialog styleClass="claim-lookup-dialog" [style]="{'width': '680px'}" [(visible)]="display" [modal]="true" (onHide)="onClose()">
  <p-header>
    <h3><i class="fa-icon fa fa-info-circle" aria-hidden="true"></i>&nbsp; <span>{{headerLabel}}</span></h3>
  </p-header>

  <div class="ref-submission-cancel-container">
    <div class="ref-submission-cancel-subject">
      {{headerContent}}
    </div>
    <div class="ref-submission-cancel-message">
      {{mainContent}}
    </div>
    <button type="button" class="btn btn-continue" (click)="onContinue()">
      <h2>{{continueContent}}</h2>
    </button>
    <a href="javascript:void(0);" (click)="onClose()" *ngIf="displayCancel">Cancel</a>
  </div>
</p-dialog>
