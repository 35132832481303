<form name="challenges" class="container login-verification-form" (ngSubmit)="emitDoFactors()" #factorsForm="ngForm" autocomplete="off" [ngStyle]="verifyPasscodeFormStyle">
  <div class="align-center controlspacer" data-label="h2">
    <h1>Verify Your Identity</h1>
  </div>
  <div class="align-center">
    To protect your account, we need to verify your identity. Please enter the passcode that was sent to
    <b>{{choiceValue}}</b> below.
  </div>
  <br/>
  <login-errrors [errorStatus]="errorStatus" *ngIf="errorStatus!==null"></login-errrors>
  <app-alert-success *ngIf="showPasscodeResentMessage"
    [subjectMessage]="subjectAlertMessage"
    [contentMessage]="contentAlertMessage">
  </app-alert-success>
  <div class="container login-verification-passcode controlspacer">
    <div class="custom-spacer text-center passcodelabel">
      <label>{{passcodeText()}}</label>
    </div>
    <div class="custom-spacer flex-center">
      <input type="text" name="selectValue" [readonly]="codeExpired" id="txtPasscode" class="passcode form-control"
        [(ngModel)]="passcode" (input)="emitValidPassCode(factorsForm.form.valid)" maxlength="6" pattern="\d{6}" required />
    </div>
    <div class="code-expires text-center">
      <div class="expiring-in">Code expires in {{expirationTimeRemaining}}</div>
    </div>
  </div>
  <div class="code-resend text-center controlspacer marginBottom">
    <a [routerLink]="" (click)="reSendCode()">Didn't receive Code? Resend It.</a>
  </div>

  <div *ngIf="!footerTemplate" class="controlspacer">
    <button class="btn btn-primary-registration btn-send" type="submit" id="btnContinueFactors"
      [disabled]="!factorsForm.form.valid && hasChoice()"><span>VERIFY</span>
    </button>
  </div>
  <div *ngIf="!footerTemplate" class="text-center">
    <a [routerLink]="" (click)="backToVerification()">Back to verification methods</a>
  </div>
  <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
</form>
