import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { UserProfile } from '@caloptima/authentication';
import { MenuItem } from 'primeng/api';
import { MemberRosterService } from 'src/app/services/member.roster.service';
import { PortalMember } from 'src/app/services/models/portal-resource';
import { ProviderDetail } from 'src/app/services/models/provider-detail';
import { RosterType } from 'src/app/services/models/request-status';
import { RosterItem } from 'src/app/services/models/roster-item';
import { Menu } from 'primeng/menu';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { PortalMenuItem } from '../../shared/portal-menu-item';
import { MemberInfoService } from 'src/app/services/member-info.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-reports-roster-member',
  templateUrl: './reports-roster-member.component.html',
  styleUrl: './reports-roster-member.component.scss'
})
export class ReportsRosterMemberComponent implements OnInit {

  public userProfile: UserProfile;
  public members:PortalMember[] = [];
  public displayMembers:PortalMember[] = [];
  public selectedMember: PortalMember;
  private sortColumn: string;
  private sortAscending: boolean;
  public searchCin: string;
  public searchName: string;
  private selectedProvider: ProviderDetail;
  public rosterItems: RosterItem[];
  public selectedrosterItem: RosterItem;
  public menuItems: PortalMenuItem[] = [];
  public showDialog: boolean = false;
  public showDatePickerDialog: boolean = false;
  public errormessage: string = "";
  public successmessage: string = "";
  public showSuccess: boolean = false;
  public showError: boolean = false;

  @Output() back = new EventEmitter();

  constructor(private memberRosterService: MemberRosterService,
    private memberService: MemberInfoService,
    private sessionService: SessionService,
    @Inject(DOCUMENT) private document: Document) {
    
  }
  ngOnInit(): void {

    var itemExists = this.sessionService.currentPermission.userPermissionCodes.find(x=>x.trim().toLocaleUpperCase() === "PRVBRM");

    if(itemExists || this.sessionService.isInternalUser) {
      this.menuItems = [
        {
          key: 'view',
          label: 'View Member Details',
          icon: ''
        },
        {
          key: 'delete',
          label: 'Remove from Roster',
          icon: ''
        }      
      ];
    }
    else {
      this.menuItems = [
        {
          key: 'view',
          label: 'View Member Details',
          icon: ''
        }
      ];
    }
    

    this.memberRosterService.getRosterItems().subscribe(x=>{
      this.rosterItems = x;
      this.selectedrosterItem = x[0];

      this.onChange(this.selectedrosterItem);
    });
  }

  public onMemberSearch(search) : void {
    if(search.memberId && search.memberId.trim() != '') {
      this.displayMembers = this.members.filter(x=> x.cin.trim().toUpperCase() === search.memberId.trim().toUpperCase());
    }
    else {
      this.displayMembers = this.members.slice();
    }    
  }

  public get selectedProviderName() : string {
    if(this.selectedProvider)
      return this.selectedProvider.name;
    else
      return "null";
  }

  public initialize(resetComponent = false) {
    this.sortColumn = 'memberName';
    this.sortAscending = true;
    this.searchCin = '';
    this.searchName = '';
    this.selectedMember = null;
    this.displayMembers = [];
    
  }

  public sortClass(column: string): string {
    let cls: string;
    if (column == this.sortColumn) {
      cls = this.sortAscending ? "fa fa-chevron-up" : "fa fa-chevron-down";
    }
    else {
      cls = "fa fa-chevron-up";
    }
    return cls;
  }

  public onPage(e: any) {
    let s = JSON.stringify(e);
  }

  public onSort(e: any): void {    
    this.displayMembers.sort((data1, data2) => {
      let value1 = data1[e.field];
      let value2 = data2[e.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (e.order * result);
    });
    this.sortAscending = e.order == 1;
    this.sortColumn = e.field;
  }

  public setProvider(provider: ProviderDetail) {
    if(provider) {
      this.selectedProvider = provider;

      if(this.selectedrosterItem) {
        this.memberRosterService.getProviderMemberRoster(provider.calProviderID, this.selectedrosterItem.rosterTypeID).subscribe(res=>{
          this.members = res;
          this.displayMembers = res.slice();
        });
      }
    }
  }

  public deleteMember(member: PortalMember) {
    this.selectedMember = member;
    this.showDatePickerDialog = true;    
  }

  public onChange(rosterItem: RosterItem) {
    this.resetMessage();
    this.setProvider(this.selectedProvider);
  }

  public onBack() {
    this.resetMessage();
    this.back.emit();
  }

  public itemclick($event) {
    var itemx = $event;
    var portalmember: PortalMember = itemx.tag;
    var menuItem: PortalMenuItem = itemx.item;

    if(menuItem.key.trim().toUpperCase() === "DELETE") {
      this.resetMessage();
      this.deleteMember(portalmember);
    }
    else if(menuItem.key.trim().toUpperCase() === "VIEW") {
      this.resetMessage();
      this.showDialog = true;
      this.selectedMember = portalmember; 

      setTimeout(()=>{
        this.memberService.getMembersById(portalmember.cin).subscribe(x=>{
          this.memberService.setSelectMember(x[0]);
        });
      }, 100);
    }
  }

  public cancelMemberDialog() {
    this.showDialog = false;
  }

  public cancelDateDialog() {
    this.showDatePickerDialog = false;
  }

  public resetMessage() {
    this.showError = false;
    this.showSuccess = false;
    this.errormessage = "";
    this.successmessage = "";
  }

  public continueDateDialog($event) {
    var relationshipDate: Date = $event;
    var providerIds : string[] = [];
    providerIds.push(this.selectedProvider.calProviderID);
        
    this.memberRosterService.removeMemberFromProviderRoster(this.selectedMember.cin, this.selectedrosterItem.rosterTypeID, relationshipDate, providerIds)
    .subscribe(x=>{
      this.setProvider(this.selectedProvider);
      this.successmessage = "Memeber removed from roster successfully.";
      this.showSuccess = true;
    },
    (error)=>{
      this.successmessage = "Failed to remove member from the roster.";
      this.showError = true;
    });
  }
}
