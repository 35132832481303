<p-dialog
[(visible)]="display" [style]="{width: '38rem'}"
[modal]="true" [closeOnEscape]="false" [draggable]="false" [resizable]="false"  [closable]="true"
header="Change Password" class="user-change-password-dialog"
(onShow)="onShow()" (onHide)="onClose()">
  <div class="change-password-container">
    <div class="change-password-header">
    </div>
    <div class="change-password-body">
      <div class="verify-email" *ngIf="stepNumber === 1 || stepNumber === 2">
        <verify-identity *ngIf="stepNumber == 1"
          (secondFactorComplete)="DoFactors()"
          [questions]="null"
          [componentUsing] = "componentUsing"
          [verifyIdentityModel]="verifyIdentityModel"
          [choices]="choices"
          [answers]=""
          [errorStatus]="errorStatus">
        </verify-identity>

        <verify-identity-passcode *ngIf="stepNumber === 2"
          (secondFactorPassCodeEntered)="DoFactors()"
          (validPasscode)="validPasscode($event)"
          [expirationTimeRemaining]="expirationTimeRemaining"
          [componentUsing] = "componentUsing"
          [errorStatus]="errorStatus"
          [codeExpired]="codeExpired"
          (resendCodeEmitted)="reSendCode()"
          (backToVerificationEmitted)="backToVerification()">
        </verify-identity-passcode>
      </div>
      <div class="create-password" *ngIf="stepNumber === 3">
        <create-password
          (valid)="onPasswordValid($event)"
          (passwordChange)="onPasswordChanged($event)"
          [password]=""
          [userName]="userProfile.email"
          [footerTemplate]="changePasswordFooter"
          [componentUsing]="componentUsing"
          [isSameAsOldPassword]="isSameAsOldPassword">
          <ng-template #changePasswordFooter>
          </ng-template>
        </create-password>
      </div>
    </div>
    <div class="change-password-actions" *ngIf="stepNumber === 3">
      <div class="edit-user-footer">
        <button type="button" class="btn btn-primary btn-cancel" (click)="onCancel()">Cancel</button>
        <button type="button" class="btn btn-primary btn-save" [disabled]="!validPassword()" (click)="onSavePassword()">Save</button>
      </div>
    </div>
  </div>
</p-dialog>
