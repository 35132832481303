<app-user-header></app-user-header>
<ng-template #footer>
    <div class="row">
        <div class="col-md-12 col-12" class="provider-registration-buttons">
            <div class="pull-right">
                <button class="back-button btn btn-secondary-registration" id="btnBack" tabindex="3" (click)="onPrevious()">Back</button>
                <button type="submit" class="next-button btn btn-primary-registration" id="btnNext" tabindex="2" [disabled]="isInvalid()">Next
                <span class="fa fa-chevron-right"></span></button>
            </div>
        </div>
    </div>
</ng-template>
<div class="registration-frame" *ngIf="stepNumber < 8">
    <div class="container">
        <div class="progress-row progress-label">
            <div class="progress-column">
                Information
            </div>
            <div class="progress-column">
                Verify
            </div>
            <div class="progress-column">
                Terms
            </div>
            <div class="progress-column">
                Provider
            </div>
            <div class="progress-column">
                Submit
            </div>
        </div>
        <div class="progress-row progress-background">
            <div class="progress-column">
                <div>
                    <div class="progress-left" [ngClass]="{active: stepNumber >= 1}"></div>
                    <div class="progress-indicator" [ngClass]="{active: stepNumber >= 1}">
                        <div class="progress-check fa fa-check" *ngIf="stepNumber > 1"></div>
                    </div>
                    <div class="progress-right" [ngClass]="{active: stepNumber >= 2}"></div>
                </div>
            </div>
            <div class="progress-column">
                <div>
                    <div class="progress-left" [ngClass]="{active: stepNumber >= 2}"></div>
                    <div class="progress-indicator" [ngClass]="{active: stepNumber >= 2}">
                        <div class="progress-check fa fa-check" *ngIf="codeValid"></div>
                    </div>
                    <div class="progress-right" [ngClass]="{active: stepNumber >= 4}"></div>
                </div>
            </div>
            <div class="progress-column">
                <div>
                    <div class="progress-left" [ngClass]="{active: stepNumber >= 4}"></div>
                    <div class="progress-indicator" [ngClass]="{active: stepNumber >= 4}">
                        <div class="progress-check fa fa-check" *ngIf="stepNumber > 4"></div>
                    </div>
                    <div class="progress-right" [ngClass]="{active: stepNumber >= 5}"></div>
                </div>
            </div>
            <div class="progress-column">
                <div>
                    <div class="progress-left" [ngClass]="{active: stepNumber >= 5}"></div>
                    <div class="progress-indicator" [ngClass]="{active: stepNumber >= 5}">
                        <div class="progress-check fa fa-check" *ngIf="stepNumber > 5"></div>
                    </div>
                    <div class="progress-right" [ngClass]="{active: stepNumber >= 6}"></div>
                </div>
            </div>
            <div class="progress-column">
                <div>
                    <div class="progress-left" [ngClass]="{active: stepNumber >= 6}"></div>
                    <div class="progress-indicator" [ngClass]="{active: stepNumber >= 6}">
                        <div class="progress-check fa fa-check" *ngIf="stepNumber > 6"></div>
                    </div>
                    <div class="progress-right"></div>
                </div>
            </div>
        </div>
    </div>

    <registration-information *ngIf="stepNumber == 1" [registrationState]="registrationState" (busy)="onBusy($event)"
        (registrationStateUpdated)="onRegistrationStateUpdated($event)" (next)="onNext()"></registration-information>
        
    <verify-identity *ngIf="stepNumber == 2" (secondFactorComplete)="DoFactors()"  [questions]="null" [verifyIdentityModel]="verifyIdentityModel"
    [choices]="choices" [answers]="" [errorStatus]="errorStatus" [footerTemplate]="footer"></verify-identity>

    <verify-identity-passcode *ngIf="stepNumber == 3" (secondFactorPassCodeEntered)="DoFactors()" (validPasscode)="validPasscode($event)"
    [expirationTimeRemaining]="expirationTimeRemaining" [errorStatus]="errorStatus"  [codeExpired]="codeExpired"
    (resendCodeEmitted)="reSendCode()" [footerTemplate]="footer"></verify-identity-passcode>

    <end-user-license *ngIf="stepNumber == 4" [registrationState]="registrationState" [footerTemplate]="footer" (next)="onNext()"
                        (accept)="onEulaAccepted($event)"></end-user-license>
    
    <provider-search *ngIf="stepNumber == 5" [componentUsing]="'provider-registration'" [registrationState]="registrationState" [footerTemplate]="footer"
    (providerSelected)="onProviderSelected($event)" (busy)="onBusy($event)" (next)="onNext()"></provider-search>

    <submit *ngIf="stepNumber == 6" [registrationState]="registrationState" (busy)="onBusy()" (previous)="onPrevious()" (next)="onNext()"></submit>

</div>
<div *ngIf="stepNumber == 7">
    <app-thank-you></app-thank-you>
</div>

<div class="footer-container">
  <app-user-footer></app-user-footer>
</div>
