
<div class="pcp-attestation-container">
   <div class="pcp-attestation-provider-container" [@showMember]="displayMembers">
    <div class="pcp-attestation-provider-search">
      <app-reports-provider-search (onProviderSearch)="onProviderSearch($event)" #providerSearch></app-reports-provider-search>
    </div>
    <div class="pcp-attestation-provider-results">
      <p-table
          [value]="displayedProviders"
          [paginator]="true"
          [rows]="10"
          [scrollable]="true"
          scrollHeight="400px"
          selectionMode="single"
          (onSort)="onSort($event)"
          [styleClass]="'p-datatable-striped p-datatable-lg'"
          >
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="{{col.field}}" width="{{col.width}}" class="{{col.classHeader}}" *ngFor="let col of columns">
              {{col.header}}
              <div *ngIf="col.sort" class="{{sortClass(col.field)}}">
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-provider>
          <tr>
            <td width="{{col.width}}" class="{{col.classContent}}" *ngFor="let col of columns">
              <div *ngIf="col.header === ''">
                <p-radioButton name="providerid" [inputId]="provider.calProviderID" [value]="provider.calProviderID" [(ngModel)]="selectedCalProviderID" (click)="onProviderSelected(provider)"></p-radioButton>
              </div>
              <div *ngIf="col.header === 'Name' || col.header === 'Provider ID' || col.header === 'TIN'">
                <div>
                  <span>{{provider[col.field]}}</span>
                </div>
              </div>
          </tr>
        </ng-template>
      </p-table>
    </div>
   </div>
   <div class="pcp-attestation-member-container" [@showMember]="!displayMembers">
    <div class="pcp-attestation-member-container-header" *ngIf="selectedProvider">
      <button class="btn btn-primary btn-back" (click)="backToProvider()">
        <i class="fa fa-arrow-left" aria-hidden="true"></i>
        <span>Back</span>
      </button>
      <p class="provider-name">{{selectedProvider.name}}</p>
    </div>
    <div class="pcp-attestation-member-container">
      <app-pcp-attestation-member #memberComponent></app-pcp-attestation-member>
    </div>
  </div>
</div>
