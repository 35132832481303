import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'primeng/api';

import { RouterHistoryService } from '../../services/router-history.service';
import { CollectionService } from './../../services/collection.service';
import { CollectionListItem } from '../../services/models/collection-list-item';
import { Messages } from '../../app.messages';

@Component({
  selector: 'app-collection-detail',
  templateUrl: './collection-detail.component.html',
  styleUrls: ['./collection-detail.component.scss'],
})

export class CollectionDetailComponent implements OnInit, OnDestroy {
  public collectionName: string;
  public collectionItem: CollectionListItem;
  private collectionItemSubscription: Subscription;
  private isModal: boolean;
  private activeTabIndex: number;
  @ViewChild('compProviders') compProviders: any;
  @ViewChild('collectionTabs', {static: true}) collectionTabs: any;

  constructor(
    private routerHistory: RouterHistoryService,
    private collectionService: CollectionService,
    private confirmService: ConfirmationService,
    public messages: Messages
  ) {
    this.collectionName = messages.MesssgeNoCollection;
    this.activeTabIndex = 0;
  }

  ngOnInit() {
    this.collectionItemSubscription = this.collectionService.collectionListItemChanged$
      .subscribe(data => {
        this.collectionItem = data;
        if (data != null && data.name) {
          this.collectionName = data.name;
        }
        else {
          this.collectionName = this.messages.MesssgeNoCollection;
        }
        return data;
      });
  }

  ngOnDestroy() {
    this.collectionItemSubscription.unsubscribe();
  }

  private close(): void {
    if (this.isModal) {
      this.routerHistory.navigateToPreviousUrl();
    }
    else {
      this.collectionService.setCurrentCollection(null);
      this.collectionService.refreshList();
    }
  }

  public isNullOrUndefined(value) {
    if (value == null) {
      return true;
    }

    if (value === null) {
      return true;
    }

    if (typeof value === 'undefined') {
      return true;
    }

    return false;
}

  public onClose(): void {
    if (this.isNullOrUndefined(this.compProviders) ||
      !this.collectionService.isEditCollectionModified() ) {
        this.close();
    }
    else {
      this.confirmService.confirm({
        message: this.messages.MessageCollectionModified,
        accept: () => {
          if (this.compProviders != null) {
            let sub = this.compProviders.saveCollection(false).subscribe(result => {
              this.close();
              sub.unsubscribe();
            });
          }
        },
        reject: () => {
          this.close();
        },
        acceptLabel: this.messages.ButtonYes,
        rejectLabel: this.messages.ButtonNo
      })
    }
  }

  public renameCollection(): void {
    if(this.compProviders != null){
      let sub = this.compProviders.saveCollection(true).subscribe(result => {
        sub.unsubscribe();
      })
    }
  }

  public set modal(value: boolean) {
    this.isModal = value;
  }

  public get modal(): boolean {
    return this.isModal;
  }

  public set showUsers(value: boolean) {
    this.activeTabIndex = value ? 1 : 0;
  }
}
