<div class="reports-hif-member">
  <div class="report-search">
    <app-reports-member-search (onMemberSearch)="onMemberSearch($event)" #memberSearch></app-reports-member-search>
  </div>
  <div class="report-parameter">
    <p-table #providerList
    [value]="displayMembers"
    [paginator]="true"
    [rows]="10"
    [scrollable]="true"
    scrollHeight="400px"
    (onSort)="onSort($event)"
    [styleClass]="'p-datatable-striped p-datatable-lg'">
      <ng-template pTemplate="header">
        <tr>
          <th width="40%" pSortableColumn="memberName">Name<div class="{{sortClass('memberName')}}"></div></th>
          <th width="20%" pSortableColumn="cin">Member ID<div class="{{sortClass('cin')}}"></div></th>
          <th width="35%" pSortableColumn="providerid">Provider ID<div class="{{sortClass('providerid')}}"></div></th>
          <th width="35%" pSortableColumn="status">Health Information</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td width="40%">
            <div>{{row.memberName}}</div>
          </td>
          <td width="20%">
            <div>{{row.cin}}</div>
          </td>
          <td width="20%">
            <div>{{row.providerId}}</div>
          </td>
          <td width="20%">
            <div><a href='javascript:void(0);' (click)="viewhif(row)">View</a></div>
          </td>          
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="report-measurementlabel">
    <button class="btn btn-primary" type="button" (click)="onDownloadList()">Download List</button>
  </div>
</div>
<hif-member-data-dialog #attachment *ngIf="showHif" 
  [membercin]='selectedMember.cin' (cancelMemberHifData)="onCancelMemberHifData()">
</hif-member-data-dialog>
