<div class="roster">
    <div class="roster-type">
        <!-- <app-reports-type [reportType]="'roster'"></app-reports-type> -->
    </div>
    <div class="roster-provider" *ngIf="displayProvider">
        <app-reports-roster-provider (selectedprovider)="onProviderSelected($event)" #providercomponent></app-reports-roster-provider>
    </div>
    <div class="roster-member">
        <app-reports-roster-member (back)="onBack()" #membercomponent *ngIf="displayMember"></app-reports-roster-member>
    </div>    
</div>