<div class="setup-password">
  <div class="header">
      <app-user-header></app-user-header>
  </div>
  <div class ="body">
    <div class="header-spacer">
      <div *ngIf="accountStatus != 6 && (accountStatus < 2 || accountStatus > 4)">
          <div class="flex-center">
              <div class="row">
                  <h1>Invalid User</h1>
              </div>
              <div class="row">
                  The requested user status does not currently allow the password to be set up.
              </div>
          </div>
      </div>
      <div *ngIf="accountStatus == 4 || accountStatus == 6">
        <div class="flex-center">
          <div class="row">
            <h1>Your Account was Activated</h1>
          </div>
          <div class="row">
            Your account is already active. Please log in to access the Provider Portal.
          </div>
          <div class="row">
            <a href="#/login">
              <button class="btn btn-primary-registration btn-lg login-button" id="btnLogin">Log in to Provider Portal</button>
            </a>
          </div>
        </div>
      </div>
      <div *ngIf="accountStatus == 3 || accountStatus == 2" >
        <div>
          <verify-identity *ngIf="stepNumber == 1"
            (secondFactorComplete)="DoFactors()"
            [questions]="null"
            [verifyIdentityModel]="verifyIdentityModel"
            [choices]="choices"
            [answers]=""
            [errorStatus]="errorStatus">
          </verify-identity>

          <verify-identity-passcode *ngIf="stepNumber == 2"
            (secondFactorPassCodeEntered)="DoFactors()"
            (validPasscode)="validPasscode($event)"
            [expirationTimeRemaining]="expirationTimeRemaining"
            [errorStatus]="errorStatus"
            [codeExpired]="codeExpired"
            (resendCodeEmitted)="reSendCode()"
            (backToVerificationEmitted)="backToVerification()">
          </verify-identity-passcode>
        </div>
        <div *ngIf="stepNumber == 3">
          <div class="container wizard">
            <div class="row">
              <div class="col-md-12">
                <create-password (valid)="passwordValid($event)" (passwordChange)="passwordChange($event)" [password]="password"
                [userName]="userProfile.email" [footerTemplate]="navigation" [componentUsing]="componentUsing"  [isSameAsOldPassword]="isSameAsOldPassword" >
                  <ng-template #navigation>
                    <div class="row">
                      <div class="col-md-12 col-xs-12"></div>
                      <div class="col-md-12 col-xs-12">
                        <button class="next-button btn btn-primary-registration" type="submit" id="btnNext" [disabled]="isInvalid()" (click)="onNext()">Next</button>
                      </div>
                    </div>
                  </ng-template>
                </create-password>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="stepNumber === 4">
          <div class="container wizard">
            <div class="row">
              <div class="col-md-12">
                <create-security-questions (valid)="securityQuestionsValid($event)" [footerTemplate]="securityQuestions" [userProfile]="userProfile">
                  <ng-template #securityQuestions>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="action-container">
                          <div class="action-buttons">
                            <button type="submit" class="next-button btn btn-primary-registration" id="btNext" tabindex="6" (click)="onNext()" [disabled]="isInvalid()">Submit</button>
                            <button type="button" class="back-button btn btn-secondary-registration" id="btnBack" tabindex="7" (click)="onPrevious()">Back</button>
                          </div>
                          <div class="action-success" [ngClass]="{'fade-in': passwordSet}">
                            <h1>Success!</h1>
                            You will be routed to the login page.
                          </div>

                        </div>
                      </div>
                    </div>
                  </ng-template>
                </create-security-questions>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-container">
      <app-user-footer></app-user-footer>
  </div>
</div>
