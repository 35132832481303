import { NgModule } from '@angular/core';
import { RouterModule, provideRoutes, Routes } from '@angular/router';
import { CollectionDetailComponent } from './collection-detail/collection-detail.component';
import { CollectionListComponent } from './collection-list/collection-list.component';
import { AuthGuard } from '@caloptima/authentication';
import { UrlGuard } from '../layout/navigation/url-guard/url.guard';
import { CollectionVerifyComponent } from './collection-verify/collection-verify.component';
import { CollectionVerifyUserComponent } from './collection-verify/collection-verify-user/collection-verify-user.component';

const routes: Routes = [
  {
    path: 'verify',
    component: CollectionVerifyComponent,
    canActivate: [AuthGuard],
    data: { label: 'Collection Manager', permissionCodes: ['PRVADM', 'ENTADM'] }
  },
  {
    path: 'verify/user',
    component: CollectionVerifyUserComponent,
    canActivate: [AuthGuard],
    data: { label: 'Collection Manager', permissionCodes: ['PRVADM', 'ENTADM'] }
  },
  {
    path: '',
    component: CollectionListComponent,
    canActivate: [AuthGuard],
    data: {label: 'Collection Manager', permissionCodes: ['PRVADM', 'ENTADM'] }
  },
  {
    path: ':id',
    component: CollectionListComponent,
    canActivate: [AuthGuard],
    data: {label: 'Collection Manager', permissionCodes: ['PRVADM', 'ENTADM'] }
  },
  {
    path: ':id/:uid',
    component: CollectionListComponent,
    canActivate: [AuthGuard],
    data: {label: 'Collection Manager',  permissionCodes: ['PRVADM', 'ENTADM'] }
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class CollectionManagerRoutingModule { }
