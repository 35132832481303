import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-password-reset-dialog',
  templateUrl: './password-reset-dialog.component.html',
  styleUrls: ['./password-reset-dialog.component.scss']
})

export class PasswordResetDialogComponent implements OnInit {

  @Input() display:boolean = true;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  public onResetPasswordAction(): void {
    this.display = false;
    this.router.navigate(['/resetpassword']);
  }

}
