import { ReportType } from "../reportype";

export class ReportFilesForMemberRequest {

  constructor() {
    this.memberids = [];
  }

  public grgr_ck: number;
  public providerTin: string;
  public providerId: string;
  public memberids: string[];
  public userName: string;  
  public reportType: ReportType;
}


export class ReportFilesForTaxRequest {

  constructor() {
    this.taxId = '';
  }

  public userName: string;
  public taxId: string;
  public reportType: ReportType;
}
