<div class="status-container">
  <div class="header">
    <app-user-header></app-user-header>
  </div>
  <div class="check-status" *ngIf="stepNumber == 0">
    <div class="check-status-container">
      <form class="check-status-container-view" (ngSubmit)="checkStatus(checkStatusForm)" #checkStatusForm="ngForm" autocomplete="off">
        <div class="status-header">
          <h1>{{statusHeader || "Already Registered?"}}</h1>
        </div>
        <div class="status-subheader">
          <h4>
            {{ statusSubheader || "Enter email address below to see what's happening with your account"}}
          </h4>
        </div>
        <div class="error-message-container"  *ngIf="showErrorMessage">
          <div class="error-message" [ngClass]="statusItem.styleClass">
            <i class="error-message-icon" [ngClass]="statusItem.icon" aria-hidden="true"></i>
            <span class="error-message-text" >{{statusItem.message}}</span>
          </div>
        </div>
        <div class="status-email">
          <label class="email-label" for="checkStatusEmail">Email Address</label>
          <div>
            <input class="email-input form-control" type="email" id="checkStatusEmail" name="checkStatusEmail"
              [(ngModel)]="checkStatusEmail" #email="ngModel" email tabindex="1" (keyup)="processKeyUp($event, checkStatusForm)"
              pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" required autofocus>
          </div>
          <div class="alert-error" *ngIf="!email.valid && email.touched">Please enter a valid email address.</div>
        </div>
        <div class="status-button">
          <div class="text-center ">
            <button class="btn btn-primary-registration btn-lg login-button" id="btnLogin" tabindex="4" tabindex="2"
              [disabled]="checkStatusEmail===null || checkStatusEmail.length===0">{{buttonText}}</button>
          </div>
        </div>
        <div class="status-back-link" *ngIf="!isActiveAccount">
          <a class="status-back-link" routerLink="/login" tabindex="3">Back to Login</a>
        </div>
      </form>
    </div>
  </div>
  <div class="check-status">
    <div>
      <verify-identity *ngIf="stepNumber == 1"
        (secondFactorComplete)="DoFactors()"
        [questions]="null"
        [verifyIdentityModel]="verifyIdentityModel"
        [choices]="choices"
        [answers]=""
        [errorStatus]="errorStatus">
      </verify-identity>

      <verify-identity-passcode *ngIf="stepNumber == 2"
        (secondFactorPassCodeEntered)="DoFactors()"
        (validPasscode)="validPasscode($event)"
        [expirationTimeRemaining]="expirationTimeRemaining"
        [errorStatus]="errorStatus"
        [codeExpired]="codeExpired"
        (resendCodeEmitted)="reSendCode()"
        (backToVerificationEmitted)="backToVerification()">
      </verify-identity-passcode>
    </div>
  </div>
  <div class="footer-container">
    <app-user-footer></app-user-footer>
  </div>
</div>
