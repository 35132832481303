import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { PortalConfig } from '../portal-config';
import { AppConfig, HttpResponseHelper } from '@caloptima/portal-foundation';
import { PortalCode } from './models/portal-code';
import { CodeRequest } from './models/requests/code-request';
import { QuestionnaireRequest } from './models/requests/questionnaire-request';
import { ProcedureCodeClaimRequest } from './models/requests/procedure-codeclaim-request';
import { DiagnosisCode } from './models/diagnosis-code';
import { ProcedureCode, ReferralProcedureStatus } from './models/procedure-code';
import { Questionnaire, QuestionnaireQuestion } from './models/questionnaire';
import { map, catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ProviderSetting } from './models/provider-setting';
import { ProviderSettingRequest } from './models/requests/provider-setting-request';
import { BehaviorSubject, Observable, pipe } from 'rxjs';
import { UserRole } from './models/user-role-list';
import { UserPosition } from './models/user-position';
import { ProviderSpecialtyRequest } from './models/requests/referring-provider-request';
import { ProviderSpecialty } from './models/responses/referral-submission-referring-provider-response';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class CodeService {
  private baseProviderMemberServiceUrl: string;
  private portalCodeServiceUrl: string;
  private diagnosisCodeServiceUrl: string;
  private procedureCodeServiceUrl: string;
  private procedureQuestionnaireServiceUrl: string;
  private procedureClaimHistoryServiceUrl: string;
  private providerSettingsServiceUrl: string;
  private getInternalUserRolesListUrl: string;
  private getProviderSpecialtiesUrl: string;
  private getProviderSpecialtiesByReferralTypeUrl: string;
  private userPositionsUrl: string;
  private currentUser: string;

  private providerSettingListSubject$ = new BehaviorSubject<ProviderSetting[]>(null);
  public providerSettingListChanged$ = this.providerSettingListSubject$.asObservable()
  .pipe(
    debounceTime(500),
    distinctUntilChanged()
  );

  private userRoles: UserRole[] = null;
  private userRolesSubject$ = new BehaviorSubject<UserRole[]>(this.userRoles);

  public userRolesChanged$ = this.userRolesSubject$.asObservable()
  .pipe(
    debounceTime(500),
    distinctUntilChanged()
  );

  constructor(
    private authService: OAuthService,
    private http: HttpClient,
    private portalConfig: PortalConfig,
    private appConfig: AppConfig) {
    this.baseProviderMemberServiceUrl = appConfig.getConfig('BaseProviderServicesApiUrl');
    if (this.baseProviderMemberServiceUrl == null) {
      const config$ = appConfig.subscribe(() => {
        this.baseProviderMemberServiceUrl = appConfig.getConfig('BaseProviderServicesApiUrl');
        this.checkUrls();
        config$.unsubscribe();
      });
    }

    const claims = this.authService.getIdentityClaims();
    if (claims) {
      this.currentUser = claims['sub'];
    }
  }

  private checkUrls(): void {
    if (this.portalCodeServiceUrl == null) {
      const baseUrl = this.baseProviderMemberServiceUrl + 'api/code/';
      this.portalCodeServiceUrl = baseUrl + 'portal';
      this.diagnosisCodeServiceUrl = baseUrl + 'diagnosis';
      this.procedureCodeServiceUrl = baseUrl + 'procedure';
      this.providerSettingsServiceUrl = baseUrl + 'settings';
      this.procedureQuestionnaireServiceUrl = baseUrl + 'procedurequestionnaire';
      this.procedureClaimHistoryServiceUrl = baseUrl + 'procedurecodeclaimhistory';
      this.getInternalUserRolesListUrl =  baseUrl + 'getInternalUserRoles';
      this.userPositionsUrl  = baseUrl + 'getUserPositions';
      this.getProviderSpecialtiesUrl = baseUrl + 'getProviderSpecialties';
      this.getProviderSpecialtiesByReferralTypeUrl = baseUrl + 'getProviderSpecialtiesByReferralType';
    }
  }

  public getReferralTypeCodes(codeValue: string = null): Observable<PortalCode[]> {
    try {
      this.checkUrls();
      const codeRequest = new CodeRequest();
      codeRequest.codeEntity = "RTYP";
      codeRequest.codeValue = codeValue;
      codeRequest.username = this.currentUser;

      return this.http.post<PortalCode[]>(this.portalCodeServiceUrl, codeRequest, httpOptions)
        .pipe(
          map(data => {
            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public getReferralPriorityCodes(codeValue: string = null): Observable<PortalCode[]> {
    try {
      this.checkUrls();
      const codeRequest = new CodeRequest();
      codeRequest.codeEntity = "PRTY";
      codeRequest.codeValue = codeValue;
      codeRequest.username = this.currentUser;

      return this.http.post<PortalCode[]>(this.portalCodeServiceUrl, codeRequest, httpOptions)
        .pipe(
          map(data => {
            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public getPlaceOfServiceCodes(codeValue: string = null): Observable<PortalCode[]> {
    try {
      this.checkUrls();
      const codeRequest = new CodeRequest();
      codeRequest.codeEntity = "POS";
      codeRequest.codeValue = codeValue;
      codeRequest.username = this.currentUser;

      return this.http.post<PortalCode[]>(this.portalCodeServiceUrl, codeRequest, httpOptions)
        .pipe(
          map(data => {
            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }


  public getDiagnosticsCodes(searchText: string = null): Observable<DiagnosisCode[]> {
    try {
      this.checkUrls();
      const codeRequest = new CodeRequest();
      codeRequest.searchText = searchText;

      return this.http.post<DiagnosisCode[]>(this.diagnosisCodeServiceUrl, codeRequest, httpOptions)
        .pipe(
          map(data => {
            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public getProcedureCodes(searchText: string = null, providerTIN: string, grgrCk: number = null): Observable<ProcedureCode[]> {
    try {
      this.checkUrls();
      const codeRequest = new CodeRequest();
      codeRequest.searchText = searchText;
      codeRequest.entityId = providerTIN;
      codeRequest.grgrCk = grgrCk;

      return this.http.post<ProcedureCode[]>(this.procedureCodeServiceUrl, codeRequest, httpOptions)
        .pipe(
          map(data => {
            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public getProcedureQuestionnaires(request: QuestionnaireRequest): Observable<Questionnaire> {
    try {
      this.checkUrls();

      return this.http.post<Questionnaire>(this.procedureQuestionnaireServiceUrl, request, httpOptions)
        .pipe(
          map(data => {
            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }


  public getProcedureClaimHistory(request: ProcedureCodeClaimRequest): Observable<ReferralProcedureStatus> {
    try {
      this.checkUrls();

      return this.http.post<ReferralProcedureStatus>(this.procedureClaimHistoryServiceUrl, request, httpOptions)
        .pipe(
          map(data => {
            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public getProviderSettings(settingKeyName: string = null): Observable<ProviderSetting[]> {
    try {
      this.checkUrls();
      const settingRequest = new ProviderSettingRequest();
      settingRequest.userName = this.currentUser;
      settingRequest.settingsName = settingKeyName;

      return this.http.post<ProviderSetting[]>(this.providerSettingsServiceUrl, settingRequest, httpOptions)
        .pipe(
          map(data => {
            if (settingKeyName === null) {
              this.setProviderSettings(data);
            }
            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public getUserPositions(positionCode: string = null): Observable<UserPosition[]> {
    try {
      this.checkUrls();

      return this.http.post<UserPosition[]>(this.userPositionsUrl + '?positionCode=', null, httpOptions)
        .pipe(
          map(data => {
            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public getProviderSpecialties(entityType: string):Observable<any>{
    try {
      this.checkUrls();
      const request = new ProviderSpecialtyRequest();
      request.entityType = entityType;

      return this.http.post<ProviderSpecialty[]>(this.getProviderSpecialtiesUrl, request, httpOptions)
        .pipe(
          map(result => {
            return result;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public getProviderSpecialtiesByReferralType(referralType: string):Observable<any>{
    try {
      this.checkUrls();
      const request = new ProviderSpecialtyRequest();
      request.referralType = referralType;

      return this.http.post<ProviderSpecialty[]>(this.getProviderSpecialtiesByReferralTypeUrl, request, httpOptions)
        .pipe(
          map(result => {
            return result;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public setProviderSettings(providerSettings: ProviderSetting[]) {
    this.providerSettingListSubject$.next(providerSettings);
  }

  public clearProviderSettings() {
    this.providerSettingListSubject$.next(null);
  }

  public getInternalUserRolesList(roleCode: string = null): Observable<UserRole[]> {
    console.log('entered');
    try {
      this.checkUrls();

      const roleRequest = {roleCode: roleCode};
      return this.http.post<UserRole[]>(this.getInternalUserRolesListUrl, roleRequest, httpOptions)
        .pipe(
          map(data => {
            this.setuserRoles(data);
            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public setuserRoles(userRoles: UserRole[]) {
    this.userRoles = userRoles;
    this.userRolesSubject$.next(userRoles);
  }
}
