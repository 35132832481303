<div class="container">
  <div class="provider-search-container">
    <div class="provider-search-header text-center">
      <h1 id="providerSearchTitle">Provider Search</h1>
      <div class="provider-search-header-info">
        <app-alert-info
          [uniqueID] = "'provider-search'"
          [subjectMessage]="''"
          [contentMessage]="'CalOptima Health will select your provider once we review and finalize your registration.'">
        </app-alert-info>
      </div>
    </div>
    <div class="provider-help pull-right">
      <i class="fa fa-question-circle-o provider-help-icon"
        title="{{messages.ProviderSearchHelpTooltip}}"
        pTooltip="{{messages.ProviderSearchHelpTooltip}}"
        tooltipPosition="left"
        tooltipStyleClass="blue-theme provider-help-tooltip"
        [escape]="false">
      </i>
    </div>
    <div class="provider-search-body">
      <div class="provider-search-input">
        <p-tabView (onChange)="onTabChanged($event)" [activeIndex]="tabIndex"  styleClass="provider-search-tabview">
          <p-tabPanel
            [header]="getTabName(0)"
            id="tabPractitioner"
            tooltip="{{messages.ProviderSearchPractitionerTabTooltip}}"
            tooltipPosition="bottom"
            rightIcon="fa fa-info-circle"
            tooltipStyleClass="blue-theme tabPractitioner-tooltip">
            <form name="frmPracticionerSearch" class="form-inline" (ngSubmit)="searchByPractitioner()" #practionerForm="ngForm" autocomplete="off">
              <div class="search-practioner-input">
                <div class="search-header">
                  Enter Practitioner Full Name or TIN and click Search.
                </div>
                <div class="search-options">
                  <div class="custom-radio">
                    <p-radioButton name="practitionerSearchOption" id="practitionerSearchByName" tabindex="2"
                      value="{{optionPractitionerSearchByName?.id}}" label="{{optionPractitionerSearchByName?.label}}"
                      [(ngModel)]="practitionerSearchSelected" (onClick)="onSelectPractitionerSearch(optionPractitionerSearchByName?.id)">
                    </p-radioButton>
                  </div>
                  <div class="custom-radio">
                    <p-radioButton name="practitionerSearchOption" id="practitionerSearchByTIN" tabindex="3"
                      value="{{optionPractitionerSearchByTIN?.id}}" label="{{optionPractitionerSearchByTIN?.label}}"
                      [(ngModel)]="practitionerSearchSelected" (onClick)="onSelectPractitionerSearch(optionPractitionerSearchByTIN?.id)">
                    </p-radioButton>
                  </div>
                </div>
                <div class="search-by-practitioner-name" *ngIf="optionPractitionerSearchByName?.enable">
                  <input type="text" class="form-control" placeholder="*First name" name="firstName" id="txtFirstName" tabindex="4" required #firstName="ngModel" [(ngModel)]="firstNameInput" autocomplete="off"/>
                  <input type="text" class="form-control" placeholder="*Last name" name="lastName" id="txtLastName" tabindex="5" required #lastName="ngModel" [(ngModel)]="lastNameInput" autocomplete="off"/>
                  <div class="alert-error" *ngIf="firstName.invalid && firstName.touched && lastName.invalid && lastName.touched">
                    {{messages.ProviderNameError}}
                  </div>
                </div>
                <div class="search-by-practitioner-tin" *ngIf="optionPractitionerSearchByTIN?.enable">
                  <input type="text" class="form-control" placeholder="*TIN" name="practitionerTIN" id="txtPractitionerTIN" tabindex="4" required #providerTIN="ngModel"
                    maxlength="9" pattern="\d{9}" [(ngModel)]="practitionerTINInput" autocomplete="off"/>
                  <div class="alert-error" *ngIf="providerTIN.invalid && (providerTIN.dirty || providerTIN.touched)">
                    <div *ngIf="providerTIN.errors?.required">
                      {{messages.ProviderTINError}}
                    </div>
                    <div *ngIf="providerTIN.errors?.pattern">
                      {{messages.ProviderTINFormatError}}
                    </div>
                  </div>
                </div>
                <div class="search-buttons">
                  <button type="submit" class="btn btn-primary search-button" id="btnFindPractitioner" value="Search" tabindex="6" [disabled]="practionerForm.invalid">
                    Search
                  </button>
                  <button type="button" class="btn btn-outline-secondary clear-button" id="btnClearPractitioner" value="Clear" tabindex="7" (click)="resetForm()">
                    Clear
                  </button>
                </div>
              </div>
            </form>
          </p-tabPanel>
          <p-tabPanel
            [header]="getTabName(1)"
            id="tabGroup"
            tooltip="{{messages.ProviderSearchGroupTabTooltip}}"
            rightIcon="fa fa-info-circle"
            tooltipPosition="bottom"
            tooltipStyleClass="blue-theme tabGroup-tooltip">
            <form name="frmGroupSearch" class="form-inline" (ngSubmit)="searchByGroup()" #groupForm="ngForm" autocomplete="off">
              <div class="search-group-input">
                <div class="search-header">
                  Enter a Group, Facility Name, or TIN and click Search.
                </div>
                <div class="search-options">
                  <div class="custom-radio">
                    <p-radioButton name="groupSearchOption" id="groupSearchByName" tabindex="2"
                      value="{{optionGroupSearchByName?.id}}" label="{{optionGroupSearchByName?.label}}"
                      [(ngModel)]="groupSearchSelected" (onClick)="onSelectGroupSearch(optionGroupSearchByName?.id)">
                    </p-radioButton>
                  </div>
                  <div class="custom-radio">
                    <p-radioButton name="groupSearchOption" id="groupSearchByTIN" tabindex="3"
                      value="{{optionGroupSearchByTIN?.id}}" label="{{optionGroupSearchByTIN?.label}}"
                      [(ngModel)]="groupSearchSelected" (onClick)="onSelectGroupSearch(optionGroupSearchByTIN?.id)">
                    </p-radioButton>
                  </div>
                </div>
                <div class="search-by-group-name" *ngIf="optionGroupSearchByName?.enable">
                  <input type="text" class="form-control" placeholder="*Group / Facility Name" name="groupSearch" id="txtGroupName"
                    tabindex="4" required #groupName="ngModel" [(ngModel)]="groupNameInput" autocomplete="off" />
                  <div class="alert-error" *ngIf="groupName.invalid && groupName.touched">
                    {{messages.ProviderGroupNameError}}
                  </div>
                </div>
                <div class="search-by-group-tin" *ngIf="optionGroupSearchByTIN?.enable">
                  <input type="text" class="form-control" placeholder="*TIN" name="groupSearch" id="txtGroupTIN"
                    maxlength="9" pattern="\d{9}" tabindex="4" required #groupTIN="ngModel" [(ngModel)]="groupTINInput" autocomplete="off" />
                  <div class="alert-error" *ngIf="groupTIN.invalid && (groupTIN.dirty || groupTIN.touched)">
                    <div *ngIf="groupTIN.errors?.required">
                      {{messages.ProviderTINError}}
                    </div>
                    <div *ngIf="groupTIN.errors?.pattern">
                      {{messages.ProviderTINFormatError}}
                    </div>
                  </div>
                </div>
                <div class="search-buttons">
                  <button type="submit" class="btn btn-primary search-button" id="btnFindGroup" value="Search" tabindex="5" [disabled]="groupForm.invalid">
                    Search
                  </button>
                  <button  type="button" class="btn btn-outline-secondary clear-button" id="btnClearGroup" value="Clear" tabindex="6" (click)="resetForm()">
                    Clear
                  </button>
                </div>
              </div>
            </form>
          </p-tabPanel>
          <div class="search-message">
            <p class="text-warning" *ngIf="searchResults != null && searchResults.totalItems > searchResults.items.length">
              Your search returned too many results.  Please refine your search.
            </p>
            <p class="text-warning" *ngIf="searchResults != null && searchResults.totalItems == 0">
              Your search returned 0 results.
            </p>
            <p class="text-success" *ngIf="searchResults != null && searchResults.totalItems > 0">
              Your search returned {{searchResults.totalItems}} results.
            </p>
            <p class="text-danger" *ngIf="validationError != null">
              {{validationError}}
            </p>
          </div>
        </p-tabView>
      </div>
      <div id="searchResultGrd" class="provider-search-results" *ngIf="pageResult && pageResult.length">
        <p-table 
          [value]="pageResult" 
          [paginator]="false" 
          [scrollable]="true" 
          scrollHeight="255px"
          [(selection)]="selectedProvider"
          tableStyleClass="referring-provider-table" 
          selectionMode="single"
        >
          <ng-template pTemplate="body" let-provider let-rowIndex="rowIndex">
            <tr [pSelectableRow]="provider" (click)="onSelectProvider(provider)" class="search-results-row">
              <td class="provider-select">
                <input name="calProviderID-{{rowIndex}}" type="hidden" value="{{provider.calProviderID}}">
                <p-radioButton name="providerID" inputId="provider-{{rowIndex}}" [value]="provider" [(ngModel)]="selectedProvider" (click)="onSelectProvider(provider)"></p-radioButton>
              </td>
              <td class="provider-type">
                <img class="provider-type-img" src="../../../../assets/images/{{getProviderIcon(provider.providerType)}}.png" />
              </td>
              <td class="provider-detail">
                <div class="provider-name">{{provider.name}}</div>
                <div class="provider-address">{{provider.addressFull}}</div>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="summary" >
            <app-paginator [totalRecords]="totalResults" resultLabel="results" [rowsPerPage]="rowsPerPage" [pageNumber]="pageNumber" (onPage)="onPage($event)"></app-paginator>
          </ng-template>
        </p-table>
      </div>
      <div class="search-results-empty-row" *ngIf="!pageResult || pageResult.length < 1">
        <div *ngIf="userSearched">
          Your search returned 0 results.
        </div>
        <div *ngIf="!userSearched">
          Enter search criteria.
        </div>
      </div>
    </div>
    <div class="provider-search-footer">
      <form name="frmNext" (ngSubmit)="onNext()" #nextForm="ngForm">
        <ng-template *ngTemplateOutlet="footerTemplate"></ng-template>
      </form>
    </div>
  </div>
</div>
