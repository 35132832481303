
<div class="create-security-questions">
  <div class="flex-left header">
    <div class="row" *ngIf="displayHeader">
        <h1>Security Questions</h1>
    </div>
    <div class="row">
        <h5>Answer the security questions below.</h5>
    </div>
  </div>
  <div class="form-group">
      <form name="frmSecurityQuestions" #securityQuestionsForm="ngForm" autocomplete="off">
        <div class="row">
          <div class="col-md-12 question">
            <label>Question 1</label>
            <select name="Question1" id="ddlQuestion1" class="form-control" tabindex="1" [(ngModel)]="questions[0]" (change)="onChange()" #ddlQuestion1="ngModel">
              <option value="What city were you born in?">What city were you born in?</option>
              <option [disabled]="questions[1] == &quot;What is your spouse's mother's maiden name?&quot;" value="What is your spouse's mother's maiden name?">What is your spouse's mother's maiden name?</option>
              <option [disabled]="questions[1] == &quot;What is the first name of your favorite childhood friend?&quot;" value="What is the first name of your favorite childhood friend?">What is the first name of your favorite childhood friend?</option>
              <option [disabled]="questions[1] == &quot;What is your maternal grandmother's first name?&quot;" value="What is your maternal grandmother's first name?">What is your maternal grandmother's first name?</option>
              <option [disabled]="questions[1] == &quot;What was your favorite place to visit as a child?&quot;" value="What was your favorite place to visit as a child?">What was your favorite place to visit as a child?</option>
              <option [disabled]="questions[1] == &quot;In what city did you meet your spouse/significant other?&quot;" value="In what city did you meet your spouse/significant other?">In what city did you meet your spouse/significant other?</option>
              <option [disabled]="questions[1] == &quot;What is the furthest city you have traveled before you were 18?&quot;" value="What is the furthest city you have traveled before you were 18?">What is the furthest city you have traveled before you were 18?</option>
              <option [disabled]="questions[1] == &quot;What is your favorite hobby?&quot;" value="What is your favorite hobby?">What is your favorite hobby?</option>
              <option [disabled]="questions[1] == &quot;What are the last 4 digits of your driver's license number?&quot;" value="What are the last 4 digits of your driver's license number?">What are the last 4 digits of your driver's license number?</option>
              <option [disabled]="questions[1] == &quot;Where did you go on your honeymoon?&quot;" value="Where did you go on your honeymoon?">Where did you go on your honeymoon?</option>
            </select>
          </div>

          <div class="col-md-12 answer">
            <label>Answer 1</label>
            <input type="text" class="form-control" required id="txtAnswer1" tabindex="2" name="Answer1"
                   [(ngModel)]="answers[0]" #txtAnswer1="ngModel" (keyup)="onChange()" autocomplete="off" />
            <div class="alert-error" *ngIf="txtAnswer1.touched && txtAnswer1.invalid">Cannot be left blank.</div>
          </div>


          <div class="col-md-12 question">
            <label>Question 2</label>
            <select name="Question2" id="ddlQuestion2" class="form-control" tabindex="3" [(ngModel)]="questions[1]" (change)="onChange()" #ddlQuestion1="ngModel">
              <option value="What was your favorite childhood game?">What was your favorite childhood game?</option>
              <option [disabled]="questions[0] == &quot;What is your spouse's mother's maiden name?&quot;" value="What is your spouse's mother's maiden name?">What is your spouse's mother's maiden name?</option>
              <option [disabled]="questions[0] == &quot;What is the first name of your favorite childhood friend?&quot;" value="What is the first name of your favorite childhood friend?">What is the first name of your favorite childhood friend?</option>
              <option [disabled]="questions[0] == &quot;What is your maternal grandmother's first name?&quot;" value="What is your maternal grandmother's first name?">What is your maternal grandmother's first name?</option>
              <option [disabled]="questions[0] == &quot;What was your favorite place to visit as a child?&quot;" value="What was your favorite place to visit as a child?">What was your favorite place to visit as a child?</option>
              <option [disabled]="questions[0] == &quot;In what city did you meet your spouse/significant other?&quot;" value="In what city did you meet your spouse/significant other?">In what city did you meet your spouse/significant other?</option>
              <option [disabled]="questions[0] == &quot;What is the furthest city you have traveled before you were 18?&quot;" value="What is the furthest city you have traveled before you were 18?">What is the furthest city you have traveled before you were 18?</option>
              <option [disabled]="questions[0] == &quot;What is your favorite hobby?&quot;" value="What is your favorite hobby?">What is your favorite hobby?</option>
              <option [disabled]="questions[0] == &quot;What are the last 4 digits of your driver's license number?&quot;" value="What are the last 4 digits of your driver's license number?">What are the last 4 digits of your driver's license number?</option>
              <option [disabled]="questions[0] == &quot;Where did you go on your honeymoon?&quot;" value="Where did you go on your honeymoon?">Where did you go on your honeymoon?</option>
            </select>
          </div>

          <div class="col-md-12 answer">
            <label>Answer 2</label>
            <input type="text" class="form-control" required id="txtAnswer2" tabindex="4" name="Answer2"
                   [(ngModel)]="answers[1]" #txtAnswer2="ngModel" (keyup)="onChange()" autocomplete="off" />
            <div class="alert-error" *ngIf="txtAnswer2.touched && txtAnswer2.invalid">Cannot be left blank.</div>
          </div>
        </div>
        <div class="row">
            <div class="col-md-12 show-answer-container">
                <p-checkbox name="chkHideAnswers" tabindex="5" [(ngModel)]="hideAnswersChecked" binary="true" label="Hide Answers" (onChange)="onHideAnswers($event)"></p-checkbox>
            </div>
        </div>
        <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
      </form>
  </div>
</div>
