<div class="reports-pcp-attestation-member">
  <div class="report-search">
    <app-reports-member-search (onMemberSearch)="onMemberSearch($event)" #memberSearch></app-reports-member-search>
  </div>
  <div class="report-parameter">
    <p-table #providerList
    [value]="displayMembers"
    [paginator]="true"
    [rows]="10"
    [scrollable]="true"
    scrollHeight="400px"
    (onSort)="onSort($event)"
    [styleClass]="'p-datatable-striped p-datatable-lg'"
    >
      <ng-template pTemplate="header">
        <tr>
          <th width="5%"></th>
          <th width="40%" pSortableColumn="memberName">Name<div class="{{sortClass('memberName')}}"></div></th>
          <th width="20%" pSortableColumn="cin">Member ID<div class="{{sortClass('cin')}}"></div></th>
          <th width="35%" pSortableColumn="providerid">Provider ID<div class="{{sortClass('providerid')}}"></div></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td width="5%" class="check-column">
            <p-radioButton name="cin" [inputId]="row.cin" [value]="row.cin" [(ngModel)]="selectedCin" (click)="onMemberSelected(row)"></p-radioButton>
          </td>
          <td width="40%">
            <div>{{row.memberName}}</div>
          </td>
          <td width="20%">
            <div>{{row.cin}}</div>
          </td>
          <td width="35%">
            <div>{{row.providerId}}</div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="report-measurementlabel" *ngIf="selectedCin != ''">
    <label>Measurement Period for {{displayText}}</label>
  </div>
  <div class="report-filelist" *ngIf="selectedCin != '' && fileList && fileList.length > 0">
    <p-dropdown name="providerfiles" id="providerfiles" [options]="fileList" [(ngModel)]="selectedFile">
    </p-dropdown>
    <button class="btn btn-primary" type="button" (click)="onBuildReport()" [disabled]='!enableBuildReport'>Download</button>
  </div>
</div>
