import { ReportType } from "../reportype";
import { ReportFilesForMemberRequest } from "./reportfiles-formember-request";
import { wellnessSurveyAnswer } from "./wellnessSurveyQuestionAnswer";


export class wellnessRequest
{
    public reportType:ReportType;
    public grgr_ck:number;
    public wellnessYear:number;
    public pcpTin:string;
    public memberCin:string;
    public pcpId:string;
}

export class WellnessSurveyAnswers extends wellnessRequest {

    public surveyAnswers: wellnessSurveyAnswer[] = [];
  
  }
  