import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { TrainingsService, HipaaTrainingType } from '../../../services/trainings.service';
import { Subscription } from 'rxjs';
import { BusyService } from '@caloptima/portal-foundation';

@Component({
  selector: 'app-training-alert-dialog',
  templateUrl: './training-alert-dialog.component.html',
  styleUrls: ['./training-alert-dialog.component.scss']
})
export class TrainingAlertDialogComponent implements OnInit, OnDestroy {
  public isBusy = false;
  private trainingContentSub$: Subscription;

  @Input() display:boolean = false;
  @Input() trainingType: HipaaTrainingType = HipaaTrainingType.LoaTraining;
  @Output() onStartTraining = new EventEmitter<boolean>();

  constructor(
    private trainingService: TrainingsService,
    private busyService: BusyService
  ) { }

  ngOnDestroy(): void {
    if (this.trainingContentSub$) {
      this.trainingContentSub$.unsubscribe();
    }
  }

  ngOnInit() {
  }

  public onStart() {
    this.busyService.emit(true);
    this.trainingContentSub$ = this.trainingService.getHipaaTrainingSiteCoreContents(this.trainingType)
    .subscribe(
      trainings => {
        // early training loads
        this.busyService.emit(false);
        this.onStartTraining.emit(true);
      },
      error => {
        this.busyService.emit(false);
      }
    );
  }
}
