export class UserCollectionRole {
  public userProviderCollectionXrefID: number;
  public providerCollectionID: number;
  public userID: number;
  public providerPositionID: number;
  public isAdminFlag: boolean;
  public roleID: number;
  public roleDisplayName: string;
  public authorizedName: string;
  public authorizedDate: Date;
  public positionDisplayName: string;
}
