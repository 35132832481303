
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from '@caloptima/portal-foundation';
import {
  AuthenticationService,
  AuthenticationFactor,
  AuthenticationServiceStatus,
  UserProfile,
  AccountStatus,
  KnowledgeBase,
  AuthenticationConfig } from '@caloptima/authentication';

import { BusyService } from '../../services/busy.service';
import { PortalConfig } from '../../portal-config';
import { Constants } from '../../app.constants';
import { Messages } from '../../app.messages';
import { UserService } from '../../services/user.service';
import { RegistrationService } from '../../services/registration-service';
import { Subscription, Observable, timer } from 'rxjs';
import { VerifyIdentityModel } from '../../services/models/verify-identity.model';
import { AuthenticationFactorChoice } from '../../services/models/authentication-factor-choice.model';
import { ErrorStatusModel } from '../../services/models/login.model';
import { ServiceUtility } from '../../services/service-utility';

@Component({
  selector: 'setup-password',
  templateUrl: './setup-password.component.html',
  styleUrls: ['./setup-password.component.scss']
})
export class SetupPasswordComponent implements OnInit, OnDestroy {
  public accountStatus: AccountStatus;
  public stepNumber: number;
  public passwordSet: boolean;
  public isPasswordValid: boolean;
  public userProfile: UserProfile;
  public componentUsing: string;
  public kb: KnowledgeBase[];
  public isSameAsOldPassword: boolean;
  public isApproved: boolean;
  public isActive: boolean;
  public isLocked: boolean;
  public verificationCode: string;
  public passcode: string;
  public codeExpired: boolean
  public expirationTimeRemaining: string;
  public verifyIdentityModel: VerifyIdentityModel = new VerifyIdentityModel();
  public choices: AuthenticationFactorChoice[];
  public answers: string[];

  private password: string;
  private selectedFactor: AuthenticationFactor;
  private requiresPasscode: boolean;
  private isValidPasscodeFormat: boolean;
  private codeValid: boolean;
  private timerSubscription$: Subscription;
  private selectedFactorSubscription$: Subscription;
  private passcodeSubscription$: Subscription;
  private minutesRemaining: number;
  private timer: Observable<number>;
  private errorStatus: ErrorStatusModel;

  constructor(
    private authService: AuthenticationService,
    private registrationService: RegistrationService,
    private authConfig: AuthenticationConfig,
    private busy: BusyService,
    private router: Router,
    private route: ActivatedRoute,
    private config: PortalConfig,
    private appConfig: AppConfig,
    private constants: Constants,
    public messages: Messages,
    private userService: UserService
  ) {
    this.stepNumber = 1;
    this.passwordSet = false;
    this.isSameAsOldPassword = false;
    this.codeValid = false;
  }

  ngOnInit() {
    this.componentUsing = 'setup-password';
    this.verifyIdentityModel.TwoFactorOptionsDescription = 'To protect your account, we need to verify your identity. You will receive a temporary code using the delivery method you select below.';

    // Navigate from other components having the clientId
    if (this.config.ClientId != undefined) {
      this.readParameters();
    } else {
      // Direct click from an url
      this.appConfig.subscribe(() => {
        this.readParameters();
      });
    }

    this.selectedFactorSubscription$ = this.userService.selectedFactorSubject$.subscribe(
      selectedFactor => {this.selectedFactor = selectedFactor; }
    );

    this.passcodeSubscription$ = this.userService.selectedFactorPasscodeSubject$.subscribe(
      passcode => {
        this.passcode = passcode;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.selectedFactorSubscription$ != null) {
      this.selectedFactorSubscription$.unsubscribe();
    }
    if (this.passcodeSubscription$ != null) {
      this.passcodeSubscription$.unsubscribe();
    }
    if (this.timerSubscription$ != null) {
      this.timerSubscription$.unsubscribe();
    }
  }

  public readParameters() {
    this.route.queryParams.subscribe(value => {
      if (value != null) {
        const user = value['user'];
        if (user != null) {
          this.findUser(user);
        }
      }
    });
  }

  public findUser(user: string): void {
    this.busy.emit(true);
    const sub = this.authService.findUsers(null, user, this.config.ClientId, 1, 1).subscribe(users => {

      if (users != null && users.length === 1) {
        this.userProfile = users[0];
        this.accountStatus = this.userProfile.accountStatus;
      } else {
        this.accountStatus = null;
      }

      this.isApproved = this.userProfile.accountStatus === AccountStatus.PasswordSetupNeeded;
      if (this.isApproved) {
          this.getFactors();
      }
      if (sub != null) {
        sub.unsubscribe();
      }

      this.busy.emit(false);
    });
  }

  public isInvalid(): boolean {
    let invalid: boolean = false;
    if (this.stepNumber === 1 && this.accountStatus === AccountStatus.Locked  && !this.selectedFactor) {
      return invalid;
    }
    else if (this.stepNumber === 2 && !this.isValidPasscodeFormat && !this.codeExpired) {
      return invalid;
    }
    if (this.stepNumber === 3 &&
      (this.accountStatus < AccountStatus.PasswordExpired ||
        this.accountStatus > AccountStatus.PasswordSetupNeeded ||
        !this.isPasswordValid)) {
      invalid = true;
    } else if (this.stepNumber === 4 && this.kb == null) {
      invalid = true;
    }
    return invalid;
  }

  public onPrevious(): void {
    this.stepNumber--;
    this.isPasswordValid = false;
  }

  public onNext(): void {
    this.userProfile.password = this.password;
    this.userProfile.knowledgeBase = this.kb;
    if ((this.accountStatus === AccountStatus.PasswordSetupNeeded || this.accountStatus === AccountStatus.PasswordExpired)
      && this.stepNumber === 4) {
      this.userProfile.accountStatus = AccountStatus.Active;
      this.passwordSet = true;
      this.isSameAsOldPassword = false;
      this.busy.emit(true);
      this.authService.updateUser ({
        clientId: this.config.ClientId,
        userProfile: this.userProfile,
        address: null,
        verificationCode: this.verificationCode
      })
      .subscribe(() => {
        this.userService.setEmail(this.userProfile.email);
        setTimeout(() => {
          this.router.navigateByUrl('login');
        }, 3000);
        this.busy.emit(false);
      },
      error => {
        if(error.error  && error.error ==="Your password cannot be reset at this time, please notify an administrator."){
          this.isSameAsOldPassword = true;
          this.passwordSet = false;
          this.stepNumber--;
        }
        this.busy.emit(false);
      },
      () => {
        this.busy.emit(false);
      });
    }
    else if (this.accountStatus === AccountStatus.Locked && this.stepNumber === 4) {
      this.authService.resetPassword(this.password, this.config.ClientId)
        .subscribe((res) => {
          this.userService.setEmail(this.userProfile.email);
          setTimeout(() => {
            this.router.navigateByUrl('login');
          }, 3000);
          this.busy.emit(false);
        },
        error => {
          this.busy.emit(false);
        },
        () => {
          this.busy.emit(false);
        });
    }
    else {
      this.stepNumber++;
    }
  }

  public DoFactors(resendCode = false) {
    this.busy.emit(true);
    const responses: AuthenticationFactor[] = [];

    if (this.selectedFactor != null) {
      responses.push({ factorType: this.selectedFactor.factorType, factorID: this.selectedFactor.factorID, value: this.passcode });
    }

    const sub = this.authService.authenticateFactors(responses).subscribe((resp) => {
      if (resp.status === AuthenticationServiceStatus.NoError) {
        this.codeValid = true;
        this.onNext();
      } else if (resp.status === AuthenticationServiceStatus.OnetimePasscodeRequired) {
        this.userService.clearPasscode();
        if (resendCode) {
          this.clearErrorMessage()
        }
        else {
          this.onNext();
        }
      } else if (resp.status === AuthenticationServiceStatus.NotallFactorsAuthenticated) {
        this.setErrorMessage(resp.status);
        this.userService.clearPasscode();
      }
      this.busy.emit(false);
      this.startTimer();
      if (sub != null) {
        sub.unsubscribe();
      }
    }, (error) => {
      this.setErrorMessage(error.status, error.error);
      this.busy.emit(false);
      this.userService.clearPasscode();
    });
  }

  public getFactors() {
    this.busy.emit(true);
    this.authService.getFactors().subscribe({
      next: (response)  => {
        this.choices = [];
        this.answers = [];

        for (let i = 0; i < response.length; i++) {
          if (response[i].factorType !== 'Question') {
            this.choices.push(new AuthenticationFactorChoice(
              response[i].factorType,
              response[i].factorID,
              response[i].value,
              ServiceUtility.getFactorsChoiceDislaySequence(response[i].factorType)));
          }
        }
        this.choices.sort((a, b) => {
          return a.sequence - b.sequence;
        });
        this.busy.emit(false);
      },
      error: (err) => {
        this.busy.emit(false);
      }
    });
  }

  startTimer() {
    if (this.timerSubscription$ != null) {
      this.timerSubscription$.unsubscribe();
    }
    this.minutesRemaining = 15;
    this.expirationTimeRemaining = this.minutesRemaining + ':00' + this.messages.MinutesLabel;
    var secondsRemaining = this.minutesRemaining * 60;
    this.timerSubscription$ = timer(1000, 1000).subscribe(t => {
      if (--secondsRemaining > 0) {
        this.codeExpired = false;
        var label = secondsRemaining < 60 ? this.messages.SecondsLabel : this.messages.MinutesLabel;
        var secondsPart = (secondsRemaining % 60).toString();
        if (secondsPart.length == 1) {
          secondsPart = '0' + secondsPart;
        }
        this.expirationTimeRemaining = Math.floor(secondsRemaining / 60) + ':' + secondsPart + label;
      }
      else {
        this.codeExpired = true;
        this.timerSubscription$.unsubscribe();
        this.setErrorMessage(13);
      }
    });
  }

  public reSendCode() {
    this.getFactors();
    this.DoFactors(true);
  }

  public backToVerification() {
    this.userService.clearSelectedFactor();
    this.requiresPasscode = false;
    this.codeExpired = false;
    this.stepNumber--;
    this.getFactors();
  }

  public validPasscode(validPasscode: boolean) {
    this.isValidPasscodeFormat = validPasscode;
  }

  private setErrorMessage(statusCode: number = null, statusMessage: string = null) {
    this.errorStatus = new ErrorStatusModel(statusCode, statusMessage);
    this.userService.setErrorMessage(this.errorStatus);
  }

  private clearErrorMessage() {
    this.errorStatus = null;
    this.userService.clearErrorMessage();
  }

  public passwordValid(event): void {
    this.isPasswordValid = event;
  }

  public passwordChange(event): void {
    this.password = event;
  }

  public securityQuestionsValid(knowledgeBase: KnowledgeBase[]): void {
    this.kb = knowledgeBase;
  }
}
