import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button-toggle',
  templateUrl: './button-toggle.component.html',
  styleUrls: ['./button-toggle.component.scss']
})
export class ButtonToggleComponent implements OnInit {
  public firstButtonSelected = true;

  @Input() toggleButton1Name = 'Button1';
  @Input() toggleButton2Name = 'Button2';
  @Output() button1Selected = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  firstButton() {
    this.firstButtonSelected = true;
    this.button1Selected.emit(this.firstButtonSelected);
  }

  secondButton() {
    this.firstButtonSelected = false;
    this.button1Selected.emit(this.firstButtonSelected);
  }

}
