<div class="module-nav" *ngIf="showModuleTray">
  <div class="module-container">
    <div class="module-tray">
      <div *ngFor="let item of getMenu(); let i=index;" class="module-item" id="div{{item.name}}Module"
        [ngClass]="{selected: activeItem == item, down: item.mouseDown}" (click)="onTrayItemClick(item, $event)">
        <div class="module-icon" title="{{item.name}}">
          <img src="{{item.upImage}}" class="icon-up" id="img{{item.name}}Up" *ngIf="activeItem !== item" />
          <img src="{{item.selectedImage}}" class="icon-selected" id="img{{item.name}}Selected"
            *ngIf="activeItem === item" />
        </div>
        <div class="module-name" >
          <span class="module-title">{{item.name}}</span>
          <div class="module-menu fa fa-caret-down" id="div{{item.name}}Dropdown"
            *ngIf="item.menuItems != null && item.menuItems.length > 0">
          </div>
          <div class="context-menu hidden {{item.contextMenuClass}}">
            <div *ngFor="let menu of item.menuItems" class="context-menu-item {{menu.contextMenuClass}}" (click)="onSelect(menu, $event)">
              <span>{{menu.name}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-idle-timeout-dialog #idleDialog [(display)]="display" (logOutUser)="onLogOutUser($event)"
  (continueSession)="onContinueSession($event)" [countdown]="countDown" (onClose)="onClose()">
</app-idle-timeout-dialog>
<app-session-timeout-dialog
  [(display)]="displaySessionTimeout" (onClose)="onSessionTimeoutClosed()" [displayTimeoutReminder]="sessionTimeoutReminder">
</app-session-timeout-dialog>
<app-user-verification-dialog [display]="displayUserVerification && !displayHipaaTrainingAlert" [headerText]="headerText"
[contentText]="contentText" [buttonText]="buttonText" [isLOA]="isLOA" [verificationIcon]="verificationIcon" (onUserVerificationAction)="onUserVerificationAction($event)">
</app-user-verification-dialog>
<app-training-alert-dialog #appTrainingAlertDialog [display]="displayHipaaTrainingAlert" [trainingType]="hipaaTrainingType"
  (onStartTraining)="onStartTrainingAccepted($event)">
</app-training-alert-dialog>
<app-user-organization-dialog [display]="displaySelectOrganizationDialog" (onUpdateDefaultorganization)="UpdateDefaultorganization($event)">
</app-user-organization-dialog>
<app-password-reset-dialog [display]="displayPasswordResetDialog">
</app-password-reset-dialog>
