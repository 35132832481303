import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-referral-attachment-dialog',
  templateUrl: './referral-attachment-dialog.component.html',
  styleUrls: ['./referral-attachment-dialog.component.scss']
})
export class ReferralAttachmentDialogComponent implements OnInit {

  public headerLabel: string = "No Clinical Attachments";
  public headerContent: string = "You Did Not Choose Any Clinical Attachments";
  public mainContent: string = " Supplying CalOptima Health with more clinical information, gives you the best opportunity for faster response times.";
  public continueContent: string = "Continue without attachments";
  public displayCancel: boolean = true;

  @Input() displayType: string = "referral"; //default to referral
  @Output() referralAttachCancellation = new EventEmitter<boolean>();

  public get display(): boolean {
    return true;
  }

  public set display(value: boolean) {
    this.onClose();
  }

  constructor() { }

  ngOnInit() {
    if (this.displayType === 'adt') {
      this.headerLabel = "No Documents";
      this.headerContent = "You Did Not Choose Any Document";
      this.mainContent = "At least one document upload is required.";
      this.continueContent = "Close";
      this.displayCancel = false;
    }
  }

  public onContinue() {
    this.referralAttachCancellation.emit(false);
  }

  public onClose() {
    this.referralAttachCancellation.emit(true);
  }
}
