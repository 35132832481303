
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UiUtility } from '../../../utils/ui-utility';
import { SessionService } from 'src/app/services/session.service';
import { ReportsService } from 'src/app/services/reports.service';
import { AppConfig } from '@caloptima/portal-foundation';

@Component({
  selector: 'app-attestation-type',
  templateUrl: './attestation-type.component.html',
  styleUrls: ['./attestation-type.component.scss']
})
export class AttestationTypeComponent implements OnInit{
  public showProviderTrainingAttestation: boolean = false;

  private paramsviaState: any = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private reportsService: ReportsService,
    private appConfig: AppConfig
  ) { 
    if(this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras && this.router.getCurrentNavigation().extras.state)
      this.paramsviaState = this.router.getCurrentNavigation().extras.state;
  }
  
  @Input() attestationType: string;

  ngOnInit() {
    if(this.paramsviaState) {
      this.extractStateParam(this.paramsviaState);
    }    
    else {
      this.route.params.subscribe(value => {
        // If suspenseID get user and assigne to selected user
        this.extractStateParam(value);        
      });  
    }

    if (this.appConfig.getConfig('EnableProviderAttestation').trim().toLowerCase() === "false") {
      this.showProviderTrainingAttestation = false;
      return;
    }

    if (this.attestationType === 'providertrainingattestation') {
      this.showProviderTrainingAttestation = true;
    }
    else {
      if (this.sessionService.isAdmin && !this.showProviderTrainingAttestation) {
        this.reportsService.accessProviderTrainingAttestation(this.sessionService.currentPermission.providerCollectionID).subscribe(result => {
          this.showProviderTrainingAttestation = result;
        });
      }
      else {
        this.showProviderTrainingAttestation = this.sessionService.isEnterpriseAdmin || this.sessionService.isEnterpriseUser;
      }
    }
  }

  public onChange() {
    this.router.navigate(['/reports/' + this.attestationType]);
  }

  public onContinue(){
    
  }

  private extractStateParam(params) {
    if (params && params.rname) {
      const attestationType = params.rname;
      if (!UiUtility.isNullOrUndefined(attestationType)) {
        this.attestationType = attestationType;
        this.onChange();
      }
    }
  }
}
