<p-dialog [(visible)]="display" [modal]="true" [responsive]="true" 
  [minY]="70" [maximizable]="false" [baseZIndex]="10000" (onHide)="onClose()" appendTo="body">
  <p-header>
    <span>{{providerInfo?.name}}</span>
  </p-header>
  <div class="" *ngIf="providerInfo">
    <div class="my-row">
      <span class="my-col1">Address:</span> <span class="my-col2">{{providerInfo.address?.address1}}</span>
    </div>
    <div class="my-row">
      <span class="my-col1">City:</span> <span class="my-col2">{{providerInfo.address?.city}}</span>
    </div>
    <div class="my-row">
      <span class="my-col1">State:</span> <span class="my-col2">{{providerInfo.address?.state}}</span>
    </div>
    <div class="my-row">
      <span class="my-col1">Zip:</span> <span class="my-col2">{{providerInfo.address?.zipCode}}</span>
    </div>
    <div class="my-row">
      <span class="my-col1">Phone:</span> <span class="my-col2">{{providerInfo.phoneNumber | phone}}</span>
    </div>
  </div>
</p-dialog>
