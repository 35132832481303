<div class="roster">
    <div class="roster-type">
        <div class="roster-type-label">
            Roster Type
        </div>
        <div class="roster-type-input">
            <p-dropdown name="rostertypename" id="rostertypeid" [options]="rosterItems" optionLabel="rosterTypeDesc" [(ngModel)]="selectedrosterItem" autoWidth="false" [style]="{'width':'100%'}" (onChange)="onChange($event)">
            </p-dropdown>           
        </div>
        <div class="roster-date-label">
            Relationship Date
        </div>
        <div class="roster-date-input">
            <p-calendar 
                appendTo="body" 
                [style]="{'width':'100%'}" 
                [inputStyle]="{'width':'100%'}" 
                [(ngModel)]="relationshipdate" 
                [showIcon]="true" 
                [showOnFocus]="false" 
                inputId="buttondisplay"
                [maxDate]="maxDate"
                (onSelect)="onSelectDate()"
            />
        </div>
    </div>
    <div class="roster-search">
        <app-reports-provider-search (onProviderSearch)="onSearch($event)"></app-reports-provider-search>
    </div>
    <div class="roster-result" *ngIf="dataLoaded">
        <!--scrollHeight="450px"            -->
        <p-table
            [value]="displayproviders"
            [paginator]="true"
            [rows]="8"
            [scrollable]="true"
            [responsive]="true"            
            (onSort)="onSort($event)"
            [styleClass]="'p-datatable-striped p-datatable-lg'">
            <ng-template pTemplate="header">
                <tr>
                    <th>
                        <p-checkbox name="selectallname" [binary]="true" [(ngModel)]="selectAll" (onChange)="onSelectAll(selectAll)"></p-checkbox>
                    </th>
                    <th pSortableColumn="name">Name<div class="{{sortClass('name')}}"></div></th>
                    <th pSortableColumn="calProviderID">Provider Id<div class="{{sortClass('calProviderID')}}"></div></th>
                    <th pSortableColumn="taxId">TIN<div class="{{sortClass('taxId')}}"></div></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row>
                <tr [pSelectableRow]="row">
                    <td>
                        <p-checkbox name="row.calProviderID" [binary]="true" [(ngModel)]="row.selected" [disabled]="row.disabled" (onChange)="onSelect(row)"></p-checkbox>
                    </td>
                    <td>
                        {{row.name}}
                    </td>
                    <td>
                        {{row.calProviderID}}
                    </td>
                    <td>
                        {{row.taxId}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>