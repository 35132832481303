export class ProviderClaimsDisputeRequest {
    public username: string;
    public claimNumber: string;
    public appealId: string;
    public memberId: string;
    public providerCollectionId: number;    
    public tin: string;
    public startDate: Date;
    public endDate: Date;
    public appealStatus: string;

}