import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { City } from '../app-components/interfaces/city.interface';
import { PortalConfig } from '../portal-config';
import { SessionService } from './session.service';
import { HttpResponseHelper, AppConfig } from '@caloptima/portal-foundation';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class CityService {
  private baseProviderServiceUrl: string = null;
  private getCitiesUrl: string = null;
  private cities: City[] = null;
  private citiesSubject$ = new BehaviorSubject<City[]>(this.cities);
  public cities$ = this.citiesSubject$.asObservable().pipe(distinctUntilChanged());

  constructor(
    private authService: OAuthService,
    private sessionService: SessionService,
    private http: HttpClient,
    private portalConfig: PortalConfig,
    private appConfig: AppConfig,
  ) {

    this.baseProviderServiceUrl = appConfig.getConfig('BaseProviderServicesApiUrl');
    if (this.baseProviderServiceUrl == null) {
      let config$ = appConfig.subscribe(() => {
        this.baseProviderServiceUrl = appConfig.getConfig('BaseProviderServicesApiUrl');
        this.checkUrls();
        this.getCities().subscribe();
        config$.unsubscribe();
      });
    }
    else {
      this.getCities().subscribe();
    }
  }

  private checkUrls(): void {
    if (this.getCitiesUrl == null) {
      const baseUrl = this.baseProviderServiceUrl + 'api/member/';
      this.getCitiesUrl = baseUrl + 'getCities';
    }
  }

  public getCities(cityName: string = ''): Observable<City[]> {
    try {
      this.checkUrls();
      // Call city service in portal services
      // Call service on subscribe set cities subject and call next
      return this.http.get<City[]>(this.getCitiesUrl, httpOptions)
        .pipe(
          map(res => {
            if (res) {
              this.cities = res;
              this.citiesSubject$.next(this.cities);
            }
            return res;
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

}
