import { ProviderGroupDetails } from './provider-group-details';
import { ProviderTaxDetails } from './provider-tax-details';
import { CollectionType } from './collection-type';

export class CollectionListItem {
  constructor() {
    this.tins = [];
    this.groups = [];
    this.groupName = '';
    this.tinName = '';
  }

  public id: number;
  public name?: string;
  public collectionType?: CollectionType;
  public tin?: string;
  public tinName:string;
  public groupId?: string;
  public groupName: string;
  public tins : ProviderTaxDetails[];
  public groups : ProviderGroupDetails[];
  public serviceTypeID: number;
  public serviceTypeDesc: string;
  public isAdmin?: boolean;
  public collectionEffDate?: Date;
  public collectionTermDate?: Date;
  public collectionTypeDesc?: string;
  public collectionTypeKeyValue?: string;
  public modifiedDate?: Date;
  public modifiedBy?: string;
  public lastVerified?: Date;
  public lastVerifiedBy?: string;
  public daysLeftToVerify: number;
  public forVerifyCount: number;
}
