import { HttpEventType } from '@angular/common/http';
import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter, NgModule, VERSION, AfterViewInit } from '@angular/core';
import { BusyService } from '@caloptima/portal-foundation';
import { FileUpload } from 'primeng/fileupload';
import { FileService } from 'src/app/services/file.service';
import { MemberAWPStatus } from 'src/app/services/models/awp-detail';
import { ReportMemberDetails } from 'src/app/services/models/reportmemberdetails';
import { WellnessSearchData } from 'src/app/services/models/wellness-attachment';
import { UiUtility } from 'src/app/utils/ui-utility';
import { saveAs } from 'file-saver';

@Component({
  selector: 'report-wellness-attachments',
  templateUrl: './report-wellness-attachments.component.html',
  styleUrls: ['./report-wellness-attachments.component.scss']
})
export class ReportWellnessAttachmentsComponent implements OnInit, OnDestroy {
  
  @Input() selectedMemberAWPStatus: MemberAWPStatus;  
  @Input() selectedMember: ReportMemberDetails;  
  @Input() wellnessFileList: WellnessSearchData[];
  @Output() onFileAdded = new EventEmitter<any[]>();
  @Output() onFileRemoved = new EventEmitter<any[]>();
  @ViewChild('fileUpload2') fileUpload2: FileUpload;
  
  public attachmentProgress: number = null;
  public attachments2: any[] = [];
  public hasUploadError2: boolean;
  public maxUploadFilesSupported2 = 5;
  private totalFilesSize: number = 100000000;
  private maxFileNameLength = 128;
  public attachmentError: boolean = false;
  
  
  constructor(
    private busyService: BusyService,
    private fileService: FileService
    ) 
  {
  }

  ngOnInit() {

  }


  ngOnDestroy() {    
  }

  public setAttachments(attachments: any[]) {
    this.attachments2 = attachments;
    setTimeout(()=>{
      if(this.fileUpload2)
        this.fileUpload2.files = this.attachments2;
    },1000);
  }

  public onSelect(event)  {
    let totalSize = 0;
    let filesRemove = [];
    let hasUploadError: boolean = false;
    let validate = false;
    let index = 0;
    let validFiles = 0;
    let removeOnError = false;
    let addedFiles: File[] = [];
    let moreThanMaxfiles = false;  

    for (let file of this.fileUpload2.files) {
      validate = true;
      removeOnError = true;      

      if (this.findDuplicateInCurrentList(file)) {
        this.fileUpload2.msgs.push({ detail: 'Duplicate file', severity: "error", summary: file.name });
        validate = false;
      }

      if (validate && this.wellnessFileList.find(x=>x.originalFileName.trim().toUpperCase() === file.name.trim().toUpperCase() && x.isWellnessForm)) {
        this.fileUpload2.msgs.push({ detail: 'Supporting document file name cannot be same as Wellness form file name.', severity: "error", summary: file.name });
        validate = false;
      }      

      if(validate && addedFiles.find(x=>this.getFileName(x.name).trim().toUpperCase() === this.getFileName(file.name).trim().toUpperCase())) {
        this.fileUpload2.msgs.push({ detail: 'Duplicate file', severity: "error", summary: file.name });
        validate = false;
        removeOnError = false;
      }

      if (validate && file.name.length > this.maxFileNameLength) {
        this.fileUpload2.msgs.push({ detail: 'File name is too long, cannot exceed ' + this.maxFileNameLength + ' chars', severity: "error", summary: file.name })
        validate = false;
      }

      if (validate && /[>:"?*`[\]\\#&]/g.test(file.name)) {
        this.fileUpload2.msgs.push({ detail: 'File name cannot contain special characters - >:"/\|?*&#', severity: "error", summary: file.name })
        validate = false;
      }      

      if(validate && (totalSize + file.size) > this.totalFilesSize){
        this.fileUpload2.msgs.push({ detail: '', severity: "error", summary: 'Cannot upload files, total size cannot exceed 100 MB' });
        validate = false; 
      }

      if(validate && (validFiles + 1) > this.maxUploadFilesSupported2) {
        if(!moreThanMaxfiles) {
          this.fileUpload2.msgs.push({ detail: '', severity: "error", summary: 'Cannot upload more than ' + this.maxUploadFilesSupported2 + ' files' });
        }
                
        validate = false;
        moreThanMaxfiles = true;
      }

      if (validate) {
        totalSize = (totalSize + file.size);
        validFiles++;
        addedFiles.push(file);
      }
      else {
        if(removeOnError){
          filesRemove.push(index);
        }
        
        hasUploadError = true;
      }

      index++;
    }

    for (let i = filesRemove.length - 1; i >= 0; i--) {
      this.fileUpload2.remove(event, filesRemove[i]);
    }    

    if (!hasUploadError && this.fileUpload2.files.length > 0) {
      this.fileUpload2.msgs.push({ detail: '', severity: "success", summary: 'Success!  Your file(s) loaded successfully.' });
    }

    this.attachments2 = [...this.fileUpload2.files];    

    this.onFileAdded.emit(this.attachments2);
  }

  public get enableUpload(): boolean {
    /*
    let currentYear: Date = new Date();
    let returnValue:boolean = true;
    
    if(this.selectedMemberAWPStatus && this.selectedMemberAWPStatus.period < currentYear.getFullYear() - 1) {
      returnValue = false;
    }
    else if(this.selectedMemberAWPStatus && this.selectedMemberAWPStatus.period == currentYear.getFullYear() - 1 && currentYear.getMonth() > 0) {
      returnValue = false;
    }
    
    return returnValue;
    */
    return true;
  }

  public onRemove2(event: Event, file: any) {
    const index: number = this.attachments2.indexOf(file);
    this.fileUpload2.remove(event, index);
    this.attachments2 = [...this.fileUpload2.files];
    this.hasUploadError2 = false;

    this.onFileRemoved.emit(this.attachments2);
  }

  public fileUploadDateTime(fileName: string) : string {
    var item = this.wellnessFileList.find(x=>x.originalFileName.trim().toUpperCase() === fileName.trim().toUpperCase());
    return (item && item.created) ? item.created.toString() : "";
  }

  private findDuplicateInCurrentList(newFile): boolean {

    let newFileName:string = this.getFileName(newFile.name);

    var item = this.attachments2.find(x=>this.getFileName(x.name).trim().toUpperCase() === newFileName.trim().toUpperCase());
    if(item && item != newFile) {      
      return true;
    }
    else{
      return false;
    }
  }

  private getFileName(fileName: string): string {
    return fileName;
  }

  public doesExists(fileName:string): boolean {
    var temp = this.wellnessFileList.find(x=>this.getFileName(x.originalFileName).trim().toUpperCase() === this.getFileName(fileName).trim().toUpperCase())
    return temp ? true : false;
  }

  public fileSize(originalFileName:string): string {

    var item = this.wellnessFileList.find(x=> x.originalFileName.trim().toUpperCase() === originalFileName.trim().toUpperCase());
    if(item) {
      return this.formatSize(item.fileSize);
    }
    else {
      var item1 = this.attachments2.find(x=>x.name.trim().toUpperCase() === originalFileName.trim().toUpperCase());

      if(!item1)
        item1 = this.attachments2.find(x=>x.name.trim().toUpperCase() === originalFileName.trim().toUpperCase());    

      if(item1)
        return this.formatSize(item1.size);
    }

    return "";
  }

  public formatSize(bytes) : string {
    if (bytes == 0) {
        return '0 B';
    }
    var k = 1000,
        dm = 1,
        sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  public fileType(name: string) : string {
    let fileType = name.split('.').pop().toLowerCase();

    if (['jpg', 'jpeg', 'png', 'bmp', 'tif'].indexOf(fileType) > -1) {
        return 'img';
    }
    else if (['doc', 'docx'].indexOf(fileType) > -1) {
        return 'docx';
    }
    else if (['csv', 'xls', 'xlsx'].indexOf(fileType) > -1) {
        return 'csv';
    }
    else if (['htm', 'xps', 'rtf', 'html', 'msg', 'txt'].indexOf(fileType) > -1) {
        return 'file';
    }
    else if (fileType === 'pdf') {
        return 'pdf';
    }
    else {
        return '';
    }
  }

  public onDownload(filename:string) {
    var temp = this.wellnessFileList.find(x=>x.originalFileName.trim().toUpperCase() == filename.trim().toUpperCase())
    if(temp){
      this.fileService.getWellnessAttachmentFile(temp.wellnessAttachmentID, temp.originalFileName).subscribe(result =>{
        const bytesArray = UiUtility.convertBase64ToArrayBuffer(result.fileStreamData);
        this.saveAsFile(bytesArray, result.originalFileName, result.fileContentType);
      });
    }
  }

  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob([buffer], { type: fileType });
    saveAs(data, fileName);
  }
}
