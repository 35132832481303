import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsHomeComponent } from './reports-home/reports-home.component';
import { routedComponents, ReportsRoutingModule } from './reports-routing';
import { ReportsTypeComponent } from './reports-type/reports-type.component';
import { ReportsPcpMemberRosterComponent } from './reports-pcp-member-roster/reports-pcp-member-roster.component';
import { ReportsPcpMemberIHAComponent } from './reports-pcp-member-iha/reports-pcp-member-iha.component';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { FileUploadModule } from 'primeng/fileupload';
import { PanelModule } from 'primeng/panel';
import { FormsModule } from '@angular/forms';
import { ReportsMemberEligibilityRosterComponent } from './reports-member-eligibility-roster/reports-member-eligibility-roster.component';
import { ReportsPayForValueComponent } from './reports-pay-for-value/reports-pay-for-value.component';
import { AppComponentsModule } from '../app-components/app-components.module';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { ReportsBloodleadScreeningComponent } from './reports-bloodlead-screening/reports-bloodlead-screening.component';
import { ReportsPcpAttestationComponent } from './reports-pcp-attestation/reports-pcp-attestation.component';
import { TabViewModule } from 'primeng/tabview';
import { PcpAttestationProviderComponent } from './reports-pcp-attestation/pcp-attestation-provider/pcp-attestation-provider.component';
import { PcpAttestationMemberComponent } from './reports-pcp-attestation/pcp-attestation-member/pcp-attestation-member.component';
import { ReportsMemberSearchComponent } from '../app-components/reports-member-search/reports-member-search.component';
import { ReportsDHCSBloodleadScreeningComponent } from './reports-dhcs-bloodlead-screening/reports-dhcs-bloodlead-screening.component';
import { ReportsPayForValueManualComponent } from './reports-pay-for-value-manual/reports-pay-for-value-manual.component';
import { AttestationBloodleadScreeningComponent } from './attestation/attestation-bloodlead-screening/attestation-bloodlead-screening.component';
import { AttestationHomeComponent } from './attestation/attestation-home/attestation-home.component';
import { AttestationTypeComponent } from './attestation/attestation-type/attestation-type.component';
import { CheckboxModule } from 'primeng/checkbox';
import { AttestationDialogComponent } from './attestation/attestation-bloodlead-screening/attestation-dialog/attestation-dialog.component';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ReportsBHPayForValueComponent } from './reports-BH-pay-for-value/reports-bh-pay-for-value.component';
import { ReportsTinSearchComponent } from '../app-components/reports-tin-search/reports-tin-search.component';
import { ReportsAWPComponent } from './reports-awp/reports-awp.component';
import { AWPProviderComponent } from './reports-awp/awp-provider/awp-provider.component';
import { AWPMemberComponent } from './reports-awp/awp-member/awp-member.component';
import { ReportsHIFComponent } from './reports-hif/reports-hif.component';
import { HIFProviderComponent } from './reports-hif/hif-provider/hif-provider.component';
import { HIFMemberComponent } from './reports-hif/hif-member/hif-member.component';
import { ReportsBHIComponent } from './reports-bhi/reports-bhi.component';
import { AttestationBHIDialogComponent } from './attestation/attestation-bhi/attestationbhi-dialog.component';
import { ReportsBHABAReferralsComponent } from './reports-bhaba-referrals/reports-bhaba-referrals.component';
import { AttestationProviderTrainingComponent } from './attestation/attestation-provider-training/attestation-provider-training.component';
import { AttestationProviderTrainingDialogComponent } from './attestation/attestation-provider-training/attestation-provider-training-dialog/attestation-provider-training-dialog.component';
import { AttestationProviderTrainingAnnualComponent } from './attestation/attestation-provider-training/attestation-provider-training-annual/attestation-provider-training-annual.component';
import { AttestationProviderTrainingIndividualComponent } from './attestation/attestation-provider-training/attestation-provider-training-individual/attestation-provider-training-individual.component';
import { AttestationBhAbaReferralsProviderComponent } from './attestation/attestation-bh-aba-referrals/attestation-bh-aba-referrals-provider/attestation-bh-aba-referrals-provider.component';
import { AttestationBhAbaReferralsMemberComponent } from './attestation/attestation-bh-aba-referrals/attestation-bh-aba-referrals-member/attestation-bh-aba-referrals-member.component';
import { AttestationBhAbaReferralsComponent } from './attestation/attestation-bh-aba-referrals/attestation-bh-aba-referrals.component';
import { AttestationBhAbaReferralsDialogComponent } from './attestation/attestation-bh-aba-referrals/attestation-bh-aba-referrals-dialog/attestation-bh-aba-referrals-dialog.component';
import { ReportWellnessAttachmentDialogComponent } from './reports-awp/awp-dialogs/report-wellness-attachment-dialog/report-wellness-attachment-dialog.component';
import { ReportWellnessSurveyComponent } from './reports-awp/awp-components/report-wellness-survey/report-wellness-survey.component';
import { ReportWellnessAttachmentsComponent } from './reports-awp/awp-components/report-wellness-attachments/report-wellness-attachments.component';
import { ReportWellnessDetailsDialogComponent } from './reports-awp/awp-dialogs/report-wellness-details-dialog/report-wellness-details-dialog.component';
import { ReportWellnessDetailsComponent } from './reports-awp/awp-components/report-wellness-details/report-wellness-details.component';
import { ReportsPdcComponent } from './reports-pdc/reports-pdc.component';
import { ReportsProviderMemberRosterComponent } from './reports-provider-member-roster/reports-provider-member-roster.component';
import { ReportsRosterProviderComponent } from './reports-provider-member-roster/reports-roster-provider/reports-roster-provider.component';
import { ReportsRosterMemberComponent } from './reports-provider-member-roster/reports-roster-member/reports-roster-member.component';
import { ReportsRosterMenuComponent } from './reports-provider-member-roster/reports-roster-menu/reports-roster-menu.component';
import { ReportsRosterMemberDialogComponent } from './reports-provider-member-roster/reports-roster-member-dialog/reports-roster-member-dialog.component';
import { CalendarModule } from 'primeng/calendar';


@NgModule({
  declarations: [
    routedComponents,
    ReportsHomeComponent,
    ReportsTypeComponent,
    ReportsPcpMemberRosterComponent,
    ReportsPcpMemberIHAComponent,
    ReportsBHABAReferralsComponent,
    ReportsBHIComponent,
    ReportsMemberEligibilityRosterComponent,
    ReportsPayForValueComponent,
    ReportsBloodleadScreeningComponent,
    ReportsPcpAttestationComponent,
    PcpAttestationProviderComponent,
    PcpAttestationMemberComponent,
    ReportsTinSearchComponent,
    ReportsMemberSearchComponent,
    ReportsDHCSBloodleadScreeningComponent,
    ReportsPayForValueManualComponent,
    AttestationBloodleadScreeningComponent,
    AttestationProviderTrainingComponent,
    AttestationProviderTrainingDialogComponent,
    AttestationProviderTrainingAnnualComponent,
    AttestationProviderTrainingIndividualComponent,
    AttestationHomeComponent,
    AttestationTypeComponent,
    AttestationDialogComponent,
    AttestationBHIDialogComponent,
    ReportsBHPayForValueComponent,
    ReportsAWPComponent,
    AWPProviderComponent,
    AWPMemberComponent,
    ReportsHIFComponent,
    HIFProviderComponent,
    HIFMemberComponent,
    ReportsPdcComponent,
    ReportWellnessDetailsComponent,
    ReportWellnessAttachmentsComponent,
    ReportWellnessDetailsDialogComponent,
    ReportWellnessSurveyComponent,
    ReportWellnessAttachmentDialogComponent,
    AttestationBhAbaReferralsComponent,
    AttestationBhAbaReferralsProviderComponent,
    AttestationBhAbaReferralsMemberComponent,  
    AttestationBhAbaReferralsDialogComponent,
    ReportsProviderMemberRosterComponent,
    ReportsRosterProviderComponent,
    ReportsRosterMemberComponent,
    ReportsRosterMenuComponent,
    ReportsRosterMemberDialogComponent
  ],
  imports: [
    CommonModule,
    ReportsRoutingModule,
    AppComponentsModule,
    ToastModule,
    TableModule,
    FormsModule,
    PanelModule,
    TooltipModule,
    DropdownModule,
    RadioButtonModule,
    TabViewModule,
    CheckboxModule,
    DialogModule,
    ConfirmDialogModule,
    FileUploadModule,
    DialogModule,
    CalendarModule
  ]
})
export class ReportsModule { }
