<div class="report-type-container">
    <div class="report-type-header">
      <h4>Attestation Type:</h4>
    </div>
    <div class="report-type-selection">
      <select class="form-control" name="reportType" id="ddlreportType" [(ngModel)]="attestationType" (change)="onChange()">
        <option value="attestationhome">Select Attestation Type</option>
        <option value="attestationbloodleadscreening">Blood Lead Screening</option>
        <option *ngIf="showProviderTrainingAttestation" value="providertrainingattestation">Provider Training Attestation</option>
        <option value="bhabareferralattestation">BH ABA P4V Attestation</option>
      </select>
    </div>
  </div>

