import { Component, OnInit, ViewChildren, AfterViewInit, OnDestroy } from '@angular/core';
import { RegistrationService } from '../../services/registration-service';
import { UserStatus } from '../../services/models/portal-user';
import {
  AuthenticationService,
  AuthenticationFactor,
  AuthenticationServiceStatus,
  UserProfile,
  AccountStatus,
  KnowledgeBase,
  AuthenticationConfig } from '@caloptima/authentication';

import { EmailVerificationParameters } from '../../services/models/email-verification-parameters';
import { BusyService } from '@caloptima/portal-foundation';
import { UserService } from '../../services/user.service';
import { Observable, of, Subscription, timer } from 'rxjs';
import { Router, ActivatedRoute, provideRoutes } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UiUtility } from '../../utils/ui-utility';
import { ErrorStatusModel } from '../../services/models/login.model';
import { VerifyIdentityModel } from '../../services/models/verify-identity.model';
import { AuthenticationFactorChoice } from '../../services/models/authentication-factor-choice.model';
import { Messages } from '../../app.messages';
import { ServiceUtility } from '../../services/service-utility';


export class UserStatusItem {
  message?: string;
  value: number;
  styleClass?: string;
  icon?: string;
  title?: string;
  buttonUrl?: string;
  disabled?: boolean;
}

export class UserStatusMap {
  public static userTypeItems: UserStatusItem[] = [
    {
      value: null,
      message: 'No account exists for that email address.',
      icon: 'fa fa-ban',
      styleClass: 'alert alert-danger',
      title: 'REGISTER AS NEW USER',
      buttonUrl: '/user/registration'
    },
    {
      value: AccountStatus.PasswordSetupNeeded,
      message: 'Your account is approved and is ready to be setup.',
      icon: 'fa fa-check-circle',
      styleClass: 'alert alert-success',
      title: 'SET UP ACCOUNT',
      buttonUrl: ''
    },
    {
      value: AccountStatus.Active,
      message: 'Your account was activated already.',
      icon: 'fa fa-check-circle',
      styleClass: 'alert alert-success',
      title: 'LOGIN',
      buttonUrl: '/login'
    },
    {
      value: AccountStatus.PasswordExpired,
      message: 'Your account has been locked. Please reset your password.',
      icon: 'fa fa-exclamation-triangle',
      styleClass: 'alert alert-warning',
      title: 'RESET PASSWORD',
      buttonUrl: ''
    },
    {
      value: AccountStatus.Pending,
      message: 'Account cannot be accessed. Call 714-246-8600 for info.',
      icon: 'fa fa-exclamation-triangle',
      styleClass: 'alert alert-warning',
      title: 'BACK',
      buttonUrl: '/back'
    },
    {
      value: AccountStatus.Submitted,
      message: 'Account cannot be accessed. Call 714-246-8600 for info.',
      icon: 'fa fa-exclamation-triangle',
      styleClass: 'alert alert-warning',
      title: 'BACK',
      buttonUrl: '/back'
    },
    {
      value: AccountStatus.Deactivated,
      message: 'Account cannot be accessed. Call 714-246-8600 for info.',
      icon: 'fa fa-exclamation-triangle',
      styleClass: 'alert alert-warning',
      title: 'BACK',
      buttonUrl: '/back'
    },
    {
      value: AccountStatus.Inactive,
      message: 'Account cannot be accessed. Call 714-246-8600 for info.',
      icon: 'fa fa-exclamation-triangle',
      styleClass: 'alert alert-warning',
      title: 'BACK',
      buttonUrl: '/back'
    }
  ];
}

@Component({
  selector: 'app-check-status',
  templateUrl: './check-status.component.html',
  styleUrls: ['./check-status.component.scss']
})
export class CheckStatusComponent implements OnInit, OnDestroy {
  public checkStatusEmail: string = null;
  public errorMessage = 'An email address must be entered';
  public showErrorMessage: boolean;
  public emailMissing: boolean;
  public statusItem = new UserStatusItem();
  public buttonText = 'CHECK STATUS';
  public buttonUrl = '';
  public componentUsing = 'check-status';
  public showIdentityPasscode = false;
  public isActiveAccount = false;
  public statusSubheader: string;
  public statusHeader: string;
  public passcode: string;
  public codeExpired: boolean
  public expirationTimeRemaining: string;
  public verifyIdentityModel: VerifyIdentityModel = new VerifyIdentityModel();
  public choices: AuthenticationFactorChoice[];
  public answers: string[];
  public kb: KnowledgeBase[];
  public stepNumber: number = 0;

  private isValidPasscodeFormat: boolean;
  private selectedFactor: AuthenticationFactor;
  private timerSubscription$: Subscription;
  private selectedFactorSubscription$: Subscription;
  private passcodeSubscription$: Subscription;
  private minutesRemaining: number;
  private timer: Observable<number>;
  private errorStatus: ErrorStatusModel;

  private accountStatus: string;
  private lockedEmailAccount: string;

  constructor(
    private userService: UserService,
    private authService: AuthenticationService,
    private registrationService: RegistrationService,
    private busy: BusyService,
    private router: Router,
    private route: ActivatedRoute,
    private messages: Messages,
  ) {}

  ngOnInit() {
    this.processLockedUser();
    this.setMessageTypes(this.accountStatus);

    this.componentUsing = 'check-status';
    this.verifyIdentityModel.TwoFactorOptionsDescription = 'To protect your account, we need to verify your identity. You will receive a temporary code using the delivery method you select below.';

    this.selectedFactorSubscription$ = this.userService.selectedFactorSubject$.subscribe(
      selectedFactor => {this.selectedFactor = selectedFactor; }
    );

    this.passcodeSubscription$ = this.userService.selectedFactorPasscodeSubject$.subscribe(
      passcode => {
        this.passcode = passcode;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.selectedFactorSubscription$ != null) {
      this.selectedFactorSubscription$.unsubscribe();
    }
    if (this.passcodeSubscription$ != null) {
      this.passcodeSubscription$.unsubscribe();
    }
    if (this.timerSubscription$ != null) {
      this.timerSubscription$.unsubscribe();
    }
  }

  public setMessageTypes(accountStatus) {

    if (accountStatus === 'locked') {
      this.statusHeader = 'Locked Account?';
      this.statusSubheader = 'Enter email address below to verify account and start password reset process';
      this.buttonText = 'Validate Account';
    }
    else {
    this.statusHeader = 'Already Registered?';
    this.statusSubheader = 'Enter email address below to see what\'s happening with your account';
    }
  }
  public setMessage() {
    this.errorMessage = this.statusItem.message;
    this.showErrorMessage = true;

    this.buttonUrl = this.statusItem.buttonUrl;
    this.buttonText = this.statusItem.title;
  }

  public validPasscode(validPasscode: boolean) {
    this.isValidPasscodeFormat = validPasscode;
  }

  public onBusy(busy: boolean) {
    this.busy.emit(busy);
  }

  public onNext() {
    this.stepNumber++;
  }

  public showError() {
    this.statusItem = UserStatusMap.userTypeItems[1];
    this.setMessage();
  }

  public setStatusMessage(profile: UserProfile) {
    if (profile === null) {
      this.statusItem = UserStatusMap.userTypeItems[0];
    }
    else {
      this.statusItem = UserStatusMap.userTypeItems.find(userType => userType.value === profile.accountStatus);
    }

    if (profile.accountStatus === AccountStatus.Locked) {
      this.accountStatus = 'locked';
      this.buttonUrl = '';
      this.checkStatus(null);
      return;
    }

    if (this.statusItem.value === UserStatus.Approved ||
        this.statusItem.value === AccountStatus.PasswordExpired) {
      this.statusItem.buttonUrl = this.registrationService.getSetupPasswordUrl(profile);
    }
    if (this.statusItem.value === UserStatus.Active) {
      this.isActiveAccount = true;
    }
    if (this.statusItem.value === UserStatus.Approved ||
      this.statusItem.value === UserStatus.Active ||
      this.statusItem.value === UserStatus.Pending ||
      this.statusItem.value === UserStatus.Declined ||
      this.statusItem.value === UserStatus.Inactive) {
      this.verifyUser();
    }
    else  {
      this.setMessage();
    }
  }

  public checkStatus(form: NgForm) {
    if (this.accountStatus === 'locked' && UiUtility.isNullUndefinedOrEmpty(this.buttonUrl) === true) {
      if (form) {
        form.control.markAsDirty();
        form.control.markAsTouched();
      }
      this.onBusy(true);
      this.registrationService.findUserStatusResetPassword(this.checkStatusEmail)
        .subscribe(profile => {
          if (profile.accountStatus === AccountStatus.Locked) {
            this.checkStatusEmail = profile ? profile.email : this.checkStatusEmail;
            profile.accountStatus = AccountStatus.PasswordExpired;
            this.setStatusMessage(profile);
            this.onBusy(false);
          }
          else {
            this.router.navigate(['/user/checkstatus']);
          }
        },
        error => {
          this.onBusy(false);
        });
    }
    else if (this.buttonUrl === '') {
      form.control.markAsDirty();
      form.control.markAsTouched();
      this.registrationService.findUserStatus(this.checkStatusEmail)
        .subscribe(profile => {
            this.checkStatusEmail = profile ? profile.email : this.checkStatusEmail;
            this.setStatusMessage(profile);
        },
        error => {
        });
    }
    else {
      // route user to new page
      // check if https and if so use redirect
      if (this.buttonUrl.includes('https://') || this.buttonUrl.includes('http://')) {
        window.location.href = this.buttonUrl;
      }
      else {
        if (this.buttonUrl.includes('login')) {
          window.localStorage.setItem('loginUser', this.checkStatusEmail);
        }
        if (this.buttonUrl.includes('/back')) {
          this.router.navigate(['login']);
        }
        else {
          this.router.navigate([this.buttonUrl]);
        }
        this.buttonUrl = '';
      }
    }
  }

  public processKeyUp($event: KeyboardEvent, form: NgForm ) {
    if ($event.keyCode === 8 && this.checkStatusEmail === '' && form && form.dirty) {
      form.resetForm();
      this.router.navigate(['/user/checkstatus']);
      this.router.routeReuseStrategy.shouldReuseRoute = (): boolean => {
        return false;
      };
    }
  }

  private processLockedUser() {
    this.accountStatus = this.route.snapshot.queryParamMap.get('account');
    if (this.accountStatus === 'locked') {
      this.lockedEmailAccount = this.route.snapshot.queryParamMap.get('email');
      if (!UiUtility.isNullUndefinedOrEmpty(this.lockedEmailAccount)) {
        this.checkStatusEmail = this.lockedEmailAccount;
        this.checkStatus(null);
      }
    }
  }

  private verifyUser() {
    this.getFactors();
    this.onNext();
  }

  public backToVerification() {
    this.userService.clearSelectedFactor();
    this.codeExpired = false;
    this.stepNumber--;
    this.getFactors();
  }

  public reSendCode() {
    this.getFactors();
    this.DoFactors(true);
  }

  public DoFactors(resendCode = false) {
    this.busy.emit(true);
    const responses: AuthenticationFactor[] = [];

    if (this.selectedFactor != null) {
      responses.push({ factorType: this.selectedFactor.factorType, factorID: this.selectedFactor.factorID, value: this.passcode });
    }

    const sub = this.authService.authenticateFactors(responses).subscribe((resp) => {
      if (resp.status === AuthenticationServiceStatus.NoError) {
        this.stepNumber = 0;
        this.setMessage();
        //this.checkStatus(null);
      } else if (resp.status === AuthenticationServiceStatus.OnetimePasscodeRequired) {
        this.userService.clearPasscode();
        if (resendCode) {
          this.clearErrorMessage()
        }
        else {
          this.onNext();
        }
      } else if (resp.status === AuthenticationServiceStatus.NotallFactorsAuthenticated) {
        this.setErrorMessage(resp.status);
        this.userService.clearPasscode();
      }
      this.busy.emit(false);
      this.startTimer();
      if (sub != null) {
        sub.unsubscribe();
      }
    }, (error) => {
      this.setErrorMessage(error.status, error.error);
      this.busy.emit(false);
      this.userService.clearPasscode();
    });
  }

  public getFactors() {
    this.busy.emit(true);
    this.authService.getFactors().subscribe({
      next: (response)  => {
        this.choices = [];
        this.answers = [];

        for (let i = 0; i < response.length; i++) {
          if (response[i].factorType !== 'Question') {
            this.choices.push(new AuthenticationFactorChoice(
              response[i].factorType,
              response[i].factorID,
              response[i].value,
              ServiceUtility.getFactorsChoiceDislaySequence(response[i].factorType)));
          }
        }
        this.choices.sort((a, b) => {
          return a.sequence - b.sequence;
        });
        this.busy.emit(false);
      },
      error: (err) => {
        this.busy.emit(false);
      }
    });
  }

  startTimer() {
    if (this.timerSubscription$ != null) {
      this.timerSubscription$.unsubscribe();
    }
    this.minutesRemaining = 15;
    this.expirationTimeRemaining = this.minutesRemaining + ':00' + this.messages.MinutesLabel;
    var secondsRemaining = this.minutesRemaining * 60;
    this.timerSubscription$ = timer(1000, 1000).subscribe(t => {
      if (--secondsRemaining > 0) {
        this.codeExpired = false;
        var label = secondsRemaining < 60 ? this.messages.SecondsLabel : this.messages.MinutesLabel;
        var secondsPart = (secondsRemaining % 60).toString();
        if (secondsPart.length == 1) {
          secondsPart = '0' + secondsPart;
        }
        this.expirationTimeRemaining = Math.floor(secondsRemaining / 60) + ':' + secondsPart + label;
      }
      else {
        this.codeExpired = true;
        this.timerSubscription$.unsubscribe();
        this.setErrorMessage(13);
      }
    });
  }

  private setErrorMessage(statusCode: number = null, statusMessage: string = null) {
    this.errorStatus = new ErrorStatusModel(statusCode, statusMessage);
    this.userService.setErrorMessage(this.errorStatus);
  }

  private clearErrorMessage() {
    this.errorStatus = null;
    this.userService.clearErrorMessage();
  }
}
