<div class="demographics">
  <div class="heading"></div>
  <div class="status-icon">
    <i class="user-eligibility-icon"  [ngClass]="mapStatusToIcon()" [title]="mapStatusToTooltip()"></i>
  </div>
  <div class="firstname">{{member.firstName}}</div>
  <div class="lastname">{{member.lastName}}</div>
  <div class="member-id">CIN: {{member.cin || 'not found'}}</div>
  <div class="member-mbi">MBI: {{member.mbi || 'None'}}</div>
  <div class="demographic-separator">
    <div class="separator"></div>
  </div>
  <div class="address-icon">
    <i class="fa fa-map-marker" aria-hidden="true"></i>
  </div>
  <div class="address-line1">
    <span [ngClass]="{'restricted-message' : !member.isUserAssocToMemberPcp }">
      {{member.address.address1}}
    </span>
 </div>
  <div class="address-line2" *ngIf="member.address.address2">
    <span [ngClass]="{'restricted-message' : !member.isUserAssocToMemberPcp }">
      {{member.address.address2}}
    </span>
  </div>
  <div [ngClass]="{'address-line3': member.address.address2, 'address-line2': !member.address.address2}">
    {{member.address.city}}, {{member.address.state}} {{member.address.zipCode.substring(0,5)}}
  </div>
  <div class="mobile-icon">
    <i class="fa fa-mobile" aria-hidden="true"></i>
  </div>
  <div class="mobile-number">
    <span [ngClass]="{'restricted-message' : !member.isUserAssocToMemberPcp }">
      {{member.phone | phone}}
    </span>
  </div>
  <div class="email-icon">
    <i class="fa fa-envelope-o" aria-hidden="true"></i>
  </div>
  <div class="email">
    <span [ngClass]="{'restricted-message' : !member.isUserAssocToMemberPcp }">
      {{member.email || 'NA' }}
    </span>
  </div>
  <div class="comment-icon">
    <i class="fa fa-comment-o" aria-hidden="true"></i>
  </div>
  <div class="language" *ngIf="member.spokenLanguage || member.writtenLanguage">{{member.spokenLanguage}}/{{member.writtenLanguage}}</div>
  <div class="spoken-written">Spoken/Written</div>
  <div class="gender-icon">
    <i class="fa fa-transgender" aria-hidden="true"></i>
  </div>
  <div class="gender">{{member.genderDisplay}}</div>
  <div class="birthdate-icon">
    <i class="fa fa-birthday-cake" aria-hidden="true"></i>
  </div>
  <div class="birthdate">{{dateOfBirth}}</div>
  <div class="member-pcp-error" *ngIf="!member.isUserAssocToMemberPcp">
    <div class="member-pcp-error-spacer restricted-message">
      * Restricted to PCP's only
    </div>
  </div>
</div>
