export class BHABAReferralAttestation {
    public id: number;
    public calProviderId: string;
    public memberCIN: string;
    public referralId: string;
    public startDate: Date;
    public endDate: Date;
    public servicesAuthorized: number;
    public assignedUnits: number;
    public assessmentGoals: number;
    public assessmentGoalsCompleted: number;
    public comment: string;
    public reason: string;
    public assessmentGoalsRate: number;
    public attestationBy: string;
    public attestationDate: Date;
    public createdBy: string;
    public created: Date;
    public modifiedBy: string;
    public modified: Date;
    public memberFirstName: string;
    public memberLastName: string;
    public status: string;

}