<p-dialog [(visible)]="display" [style]="{width: '26rem'}" [baseZIndex]="10000" [modal]="true" [closeOnEscape]="false"
  [draggable]="false" [resizable]="false" [closable]="false">
  <p-header>
    <h1><i [ngClass]="verificationIcon" aria-hidden="true"></i>&nbsp; <span>{{headerText}}</span></h1>
  </p-header>
  <div class="suspend-body">
    <div class="days-left" *ngIf="isLOA">0 days left</div>
    {{contentText}}
    <button type="button" class="logout-button btn btn-primary" (click)="onVerificationAction()">
      <h2> {{buttonText | uppercase}} </h2>
    </button>
  </div>
</p-dialog>
