import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { DataViewModule } from 'primeng/dataview';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { MemberLookupRoutingModule, routedComponents } from './member-lookup-routing.module';
import { MemberSearchComponent } from './member-search/member-search.component';
import { MemberComponent } from './member/member.component';
import { MemberResultsComponent } from './member-results/member-results.component';
import { AppComponentsModule } from '../app-components/app-components.module';
import { MemberDemographicsComponent } from './member-demographics/member-demographics.component';
import { PortalUIComponentsModule } from '@caloptima/portal-ui-components';


@NgModule({
  declarations: [
    routedComponents,
    MemberSearchComponent,
    MemberComponent,
    MemberResultsComponent,
    MemberDemographicsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MemberLookupRoutingModule,
    AppComponentsModule,
    PortalUIComponentsModule,
    TabViewModule,
    TableModule,
    TooltipModule,
    DataViewModule,
    DropdownModule,
    DialogModule,
    ToastModule,
    TableModule
  ],
  providers: [
    DatePipe
  ]
})

export class MemberLookupModule { }
