<p-dialog header="Add Diagnosis" 
        [(visible)]="display" 
        [modal]="true" 
        (onHide)="onCancel()" 
        [closeOnEscape]="true"
        [draggable]="false"
        [style]="{width: '900px', height:'465px'}">
    <div class="adddiagnosis">
        <div class="adddiagnosis-lbldiagnsiscode">Search by Code or Name</div>            
        <div class="adddiagnosis-txtdiagnosiscode">
            <input class="form-control" type="text"required="false"  name="txtdiagnosiscode" id="txtdiagnosiscode" [(ngModel)]="diagnosissearchcode" (keypress)="onKeyPress($event)">
        </div>
        <div class="adddiagnosis-btndiagnosissearch">
            <button type="button" class="btn btn-primary btn-create" (click)="onSearch()" [disabled]="!canEnableSearch">Search</button>
        </div>
        <div class="adddiagnosis-error" *ngIf="error">
            <app-alert-danger [uniqueID]="nodiagerror" [subjectMessage]="''" [contentMessage]="'Unable to find diagnosis code'"></app-alert-danger>
        </div>
        <div *ngIf="statusList && statusList.length" class="adddiagnosis-lbldiagnosisname">Name</div>            
        <div *ngIf="statusList && statusList.length" class="adddiagnosis-dropdowndiagnosisname">
            <p-dropdown 
                name="txtdiagnosisname" 
                id="txtdiagnosisname" 
                [options]="statusList" 
                [(ngModel)]="selectedDiagnosis"
                placeholder="Choose a Diagnosis" >
            </p-dropdown>
        </div>
        <div class="adddiagnosis-error1" *ngIf="existError">
            <app-alert-danger [uniqueID]="existserror" [subjectMessage]="''" [contentMessage]="'This diagnosis is already added to the list.'"></app-alert-danger>
        </div>            
    </div>
    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-primary btn-create" (click)="onCreate()" [disabled]="!canEnableAdd">Add</button>
        <button type="button" class="btn btn-secondary btn-cancel" (click)="onCancel()">Cancel</button>
    </ng-template>
</p-dialog>
