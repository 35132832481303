<p-dialog header="{{caption}}" [(visible)]="display" [modal]="true" (onHide)="onCancel()" width="800" [draggable]="false">
    <div *ngIf="currentView === 1">
        <div>
            Upload the signed document and then continue on to upload any supporting documents you may have.
        </div>
        <p-fileUpload #fileUpload1 class="prime-file-upload" chooseLabel="Choose File"
            (onSelect)="onSelect1($event)"
            multiple="multiple"
            accept=".pdf,.jpg,.jpeg,.png,.doc,.docx,.txt,.csv,.xls,.xlsx,.bmp,.tif,.xps,.rtf,.msg"
            maxFileSize="25000000"
            fileLimit="{{maxUploadFilesSupported1}}"
            [disabled]="!enableUpload"
            [showUploadButton]="false"
            [showCancelButton]="false">
            <ng-template pTemplate="content">
                <div *ngIf="attachments1.length === 0">
                    <div class="referral-submission-attach-summary">
                        <div class="referral-submission-attach-summary-icon">
                            <span><i class="fa fa-cloud-upload"></i></span>
                        </div>
                        <div class="referral-submission-attach-summary-content">
                            There are 0 attachments for this members.
                        </div>
                    </div>
                </div>
                <ul *ngIf="attachments1.length" class="report-wellness-attach-list">
                    <li *ngFor="let file of attachments1">
                        <div [ngSwitch]="fileType(file.name)" style="display: inline;">
                            <div *ngSwitchCase="'pdf'" class="file-type">
                                <span class="report-wellness-attach-body-icon"> <i class="fa fa-file-pdf-o"></i></span>
                            </div>
                            <div *ngSwitchCase="'docx'" class="file-type">
                                <span class="report-wellness-attach-body-icon"> <i class="fa fa-file-word-o"></i></span>
                            </div>
                            <div *ngSwitchCase="'csv'" class="file-type">
                                <span class="report-wellness-attach-body-icon"> <i class="fa fa-file-excel-o"></i></span>
                            </div>
                            <div *ngSwitchCase="'file'" class="file-type">
                                <span class="report-wellness-attach-body-icon"> <i class="fa fa fa-file-text-o"></i></span>
                            </div>
                            <div *ngSwitchCase="'img'">
                                <span class="report-wellness-attach-body-icon"> <i class="fa fa-file-image-o"></i></span>
                            </div>
                            <div *ngSwitchDefault class="file-type">
                                <span class="report-wellness-attach-body-icon"> <i class="fa fa fa-file-text-o"></i></span>
                            </div>
                        </div>
                        <div *ngIf="doesExists(file.name)" class="file-name">
                            <a href='javascript:void(0);' (click)="onDownload(file.name)">{{file.name}}</a>
                        </div>
                        <div *ngIf="!doesExists(file.name)" class="file-name">
                            {{file.name}}
                        </div>   
                        <div class="file-size">
                            {{fileSize(file.name)}}
                        </div>
                        <div *ngIf="!doesExists(file.name)" class="file-action">
                            <button type="button" icon="fa fa-times" pButton (click)="onRemove1(event, file)"></button>
                        </div>
                    </li>
                </ul>
            </ng-template>
        </p-fileUpload>  
        <div *ngIf="editComment">
            <p><b>Notes or Comments</b></p>
            <textarea id="txtNotes" [(ngModel)]="selectedMemberAWPStatus.notes" #notesTxt="ngModel" maxlength="500" rows="4" cols="89"></textarea>
        </div>     
        <div *ngIf="!editComment">
            <p><b>Notes or Comments</b></p>
            <textarea id="txtNotes" [(ngModel)]="selectedMemberAWPStatus.notes" #notesTxt="ngModel" maxlength="500" rows="4" cols="89" readonly></textarea>
        </div>     
        <div class="referral-submission-attach-body-footer">
            Upload up to {{maxUploadFilesSupported1}} files. Max file size 25MB.
        </div>        
    </div>
    <div *ngIf="currentView === 2">
        <div>
            Do you have any supporting documents? Acceptable documents include *.pdf, *.docx, *.xlsx, and images files (5 items total)
        </div>            
        <p-fileUpload #fileUpload2 class="prime-file-upload" chooseLabel="Choose File"
        (onSelect)="onSelect2($event)"
        multiple="multiple"
        accept=".pdf,.jpg,.jpeg,.png,.doc,.docx,.txt,.csv,.xls,.xlsx,.bmp,.tif,.xps,.rtf,.msg"
        maxFileSize="25000000"
        fileLimit="{{maxUploadFilesSupported2}}"
        [disabled]="!enableUpload"
        [showUploadButton]="false"
        [showCancelButton]="false">
            <ng-template pTemplate="content">
                <div *ngIf="attachments2.length === 0">
                    <div class="referral-submission-attach-summary">
                        <div class="referral-submission-attach-summary-icon">
                            <span><i class="fa fa-cloud-upload"></i></span>
                        </div>
                        <div class="referral-submission-attach-summary-content">
                            There are 0 supporting documents for this member.
                        </div>
                    </div>
                </div>
                <ul *ngIf="attachments2.length" class="report-wellness-attach-list">
                    <li *ngFor="let file of attachments2">
                        <div [ngSwitch]="fileType(file.name)" style="display: inline;">
                            <div *ngSwitchCase="'pdf'" class="file-type">
                                <span class="report-wellness-attach-body-icon"> <i class="fa fa-file-pdf-o"></i></span>
                            </div>
                            <div *ngSwitchCase="'docx'" class="file-type">
                                <span class="report-wellness-attach-body-icon"> <i class="fa fa-file-word-o"></i></span>
                            </div>
                            <div *ngSwitchCase="'csv'" class="file-type">
                                <span class="report-wellness-attach-body-icon"> <i class="fa fa-file-excel-o"></i></span>
                            </div>
                            <div *ngSwitchCase="'file'" class="file-type">
                                <span class="report-wellness-attach-body-icon"> <i class="fa fa fa-file-text-o"></i></span>
                            </div>
                            <div *ngSwitchCase="'img'" class="file-type">
                                <span class="report-wellness-attach-body-icon"> <i class="fa fa-file-image-o"></i></span>
                            </div>
                            <div *ngSwitchDefault class="file-type">
                                <span class="report-wellness-attach-body-icon"> <i class="fa fa fa-file-text-o"></i></span>
                            </div>
                        </div>
                        <div *ngIf="doesExists(file.name)" class="file-name">
                            <a href='javascript:void(0);' (click)="onDownload(file.name)">{{file.name}}</a>
                        </div>
                        <div *ngIf="!doesExists(file.name)" class="file-name">
                            {{file.name}}
                        </div>                
                        <div  class="file-size">
                            {{fileSize(file.name)}}
                        </div>
                        <div *ngIf="!doesExists(file.name)" class="file-action">
                            <button type="button" icon="fa fa-times" pButton (click)="onRemove2(event, file)"></button>
                        </div>
                    </li>
                </ul>
            </ng-template>
        </p-fileUpload>        
        <div class="report-wellness-attach-body-footer">
            Upload up to {{maxUploadFilesSupported2}} files. Max file size 25MB.
        </div>
        <div class="report-wellness-attach-body-footer" *ngIf="attachmentError">
            <app-alert-danger
                [subjectMessage]="''"
                [contentMessage]="'Error in submitting annual wellness files. Please try to re-submit.'">
            </app-alert-danger>
        </div>        
    </div>
    <p-footer>
        <button type="button" class="btn btn-primary btn-create" [disabled]="!enableSubmit" (click)="onContinue()">{{submitText}}</button>
        <button type="button" class="btn btn-secondary btn-cancel" (click)="onCancel()">Cancel</button>        
    </p-footer>
</p-dialog>
