import { Component, OnInit, Input } from '@angular/core';

import { Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';
import { HTTPStatusCode } from '../../services/models/HTTPStatusCode';
import { LoginErrorModel, ErrorStatusModel } from '../../services/models/login.model';

@Component({
  selector: 'login-errrors',
  templateUrl: './login-errrors.component.html',
  styleUrls: ['./login-errrors.component.scss']
})
export class LoginErrrorsComponent implements OnInit {

  public loginError:LoginErrorModel;
  public errorSubscription$: Subscription;

  @Input() errorStatus:ErrorStatusModel;

  private httpStatusCode: HTTPStatusCode;

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.errorSubscription$ = this.userService.errorMessageSubject$.subscribe(
      errorObject => {errorObject ? this.setLoginErrorMessage(errorObject) : this.clearLoginErrorMessage(); }
    );
  }

  ngOnDestroy() {
    if (this.errorSubscription$) {
      this.userService.clearErrorMessage();
    }
  }

  public clearLoginErrorMessage() {
    this.loginError = null;
  }

  public setLoginErrorMessage(errorStatus:ErrorStatusModel): void {
    if (errorStatus.statusCode ){
      switch (errorStatus.statusCode) {
        case 417:
          this.loginError = new LoginErrorModel(
            errorStatus.statusMessage && errorStatus.statusMessage.indexOf("Dormant Account") >= 0? "Your account has been disabled due to no activity. "
              : "Your account has been locked. ", "Please call 714-246-8600 for assistance or ", "reset your password." );
          break;
        case HTTPStatusCode.RangeNotSatisfiable:
          this.loginError = new LoginErrorModel('Your passcode has been disabled. ', 'Click on \'Back to verification methods\' to request a new passcode', '' )
            break;
        case HTTPStatusCode.PreconditionFailed:
          this.loginError =  new LoginErrorModel('Your account has been temporarily locked. ',  'Please try again after 15 minutes', '' );
          break;
        case HTTPStatusCode.NotAcceptable:
          this.loginError = new LoginErrorModel('Invalid Code. ',  'Too many attempts will result in your passcode being disabled.', '' );
          break;
        case 10:
          this.loginError = new LoginErrorModel('Your session has timed out. ',  'Please login again.', '' );
          break;
        case 11:
          this.loginError = new LoginErrorModel('Your account has been deactivated by CalOptima Health. ',  'Please contact CalOptima Health at 714-246-8600 for further assistance.', '' );
          break;
        case 12:
          this.loginError = new LoginErrorModel('Login Failed! ',  'Your registration is currently being reviewed by CalOptima Health. Please allow up to 5-10 business days for CalOptima Health to verify your registration.', '' );
          break;
        case 13:
          this.loginError = new LoginErrorModel('Your passcode has been disabled. ', 'Click Back to request a new passcode', '');
          break;
        case 14:
          this.loginError = new LoginErrorModel('Login Failed. ', 'Account Not Found. Please register for a user account.', '');
          break;
        default:
          this.loginError = new LoginErrorModel("Your credentials are incorrect. ", "Too many attempts will result in your account being locked.","" );
          break;
      }
    }
    else
    {
      this.loginError = new LoginErrorModel('Your credentials are incorrect. ',  'Too many attempts will result in your account being locked.', '' );
    }
  }

}
