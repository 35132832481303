<div class="member-conditions-container" *ngIf="!isLoading">
  <div class="member-conditions-message" *ngIf="!isUserAssocToMemberPcp">
    <app-member-custom-messages-nonpcp></app-member-custom-messages-nonpcp>
  </div>
  <div class="member-conditions-body" *ngIf="isUserAssocToMemberPcp">
    <p-dataView [value]="conditions" [sortField]="sortField" [sortOrder]="sortOrder" (onSort)="onSortInit()">
      <ng-template pTemplate="header">
        <div class="condition-history-header">
          <img src="./assets/images/condition-history-icon.png" class="condition-icon">
          <h4>{{tabName}}</h4>
          <div class="header-right">
            <div class="sort-text">Sort by: </div>
            <p-dropdown [options]="sortOptions" [(ngModel)]="sortKey" optionLabel="label"
              (onChange)="onSortChange($event)"></p-dropdown>
          </div>
        </div>
      </ng-template>
      <ng-template let-conditions pTemplate="list">
          <div class="member-conditions-row" *ngFor="let condition of conditions; let first = first">
            <div class="recent-date-header">
              Most recent encounter:
            </div>
            <div class="recent-date-content">
              {{condition.latestDiagnosisDate | date:'MM/dd/yyyy'}}
            </div>
            <div class="original-date-header">
              Original encounter:
            </div>
            <div class="original-date-content">
              {{condition.diagnosisDate | date:'MM/dd/yyyy'}}
            </div>
            <div class="description-header">
              ICD code description:
            </div>
            <div class="description-content" title="{{condition.healthDiagnosis.diagnosisDesc}}">
              {{condition.healthDiagnosis.diagnosisCode}} / {{condition.healthDiagnosis.diagnosisDesc}}
            </div>
            <div class="original-physician-header">
              Original physician:
            </div>
            <div class="original-physician-content" title="{{condition.serviceProvider.providerName}}">
              {{condition.serviceProvider.providerName}}
            </div>
            <div class="recent-physician-header">
              Most recent physician:
            </div>
            <div class="recent-physician-content" title="{{condition.serviceProvider.providerName}}">
              {{condition.latestServiceProvider.providerName}}
            </div>
          </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <div class="member-condition-footer">
          {{totalConditions}} results
        </div>
      </ng-template>      
    </p-dataView>
  </div>
</div>
